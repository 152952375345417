import { Box, SvgIcon } from "@mui/material";
import {
  SphereTooltip,
  SphereTooltipProps,
} from "@components/common/sphere-tooltip";
import QuestionMark from "@assets/icons/Question_mark.svg?react";
import { ElementType } from "react";

/** Size for the help icon that activates the tooltip */
const ICON_SIZE = "16px";

interface Props {
  /** Component that will show when icon is hovered */
  text: React.ReactNode;

  /**
   * Optional prop to change the icon to show
   *
   * @default QuestionMark A question mark icon
   */
  icon?: ElementType;

  /**
   * Optional size for the icon
   *
   * @default "16px"
   */
  size?: string;

  /** Optional props to override the MUI Tooltip element. */
  tooltipProps?: SphereTooltipProps["tooltipProps"];
}

/** Renders an icon that shows a tooltip text when hovered */
export function SphereTooltipIcon({
  text,
  icon = QuestionMark,
  size = ICON_SIZE,
  tooltipProps,
}: Props): JSX.Element {
  return (
    <Box component="div">
      <SphereTooltip
        title={text}
        // It should not wait to enter because on hover the user did want to get more info
        enterDelay={0}
        boxProps={{
          component: "div",
          sx: {
            display: "flex",
            alignItems: "center",
          },
        }}
        tooltipProps={tooltipProps}
      >
        <SvgIcon
          component={icon}
          inheritViewBox={true}
          sx={{
            width: size,
            height: size,
          }}
        />
      </SphereTooltip>
    </Box>
  );
}
