import { ErrorWithTitle } from "@context-providers/error-boundary/error-types";
import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { SanitizedHtmlTag } from "@components/common/sanitized-html-tag";
import { useAppDispatch } from "@store/store-helper";
import { removeOne } from "@store/errors/errors-slice";
import { useToast } from "@hooks/use-toast";

interface Props {
  /** The error to be show in toast */
  error: ErrorWithTitle;

  /** Handler to close the toast */
  onClose(): void;
}

/**
 * Displays an error message in a toast on the bottom left of the screen.
 * This component needs to call some hooks (useToast, useAppDispatch). It should either be a React component or a hook.
 * As it is called inside ErrorBoundary class component, converting it to hook will not work.
 * So, it needs to return JSX.Element and an empty fragment as the simplest option
 */
export function ErrorToast({ error, onClose }: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const { showToast } = useToast();

  function onCloseHandler(): void {
    dispatch(removeOne(error.id));
    onClose();
  }

  showToast({
    message: error.title,
    description: (
      <SanitizedHtmlTag markup={getErrorDisplayMarkup(error.error)} />
    ),
    type: "error",
    onClose: onCloseHandler,
  });

  // eslint-disable-next-line react/jsx-no-useless-fragment -- Should return <> to satisfy JSX.Element return type
  return <></>;
}
