import { useGetBulkDialogContent } from "@components/common/faro-table/bulk-dialog/bulk-dialog-helper";
import { BulkDialogs } from "@components/common/faro-table/bulk-dialog/bulk-dialog-types";
import {
  bulkActionResultsSelector,
  numberOfUpdatingItemsSelector,
} from "@store/table/table-selector";
import { useAppSelector } from "@store/store-helper";
import { nounPluralize } from "@utils/data-display";

/** Determines the content of bulk dialog and bulk success dialog for changing project management */
export function useChangeProjectManagementBulkDialogContent(): BulkDialogs {
  const numberOfUpdatingItems = useAppSelector(numberOfUpdatingItemsSelector);
  const { numberOfSuccess, numberOfErrors } = useAppSelector(
    bulkActionResultsSelector
  );

  const bulkDialogContent = useGetBulkDialogContent({
    initial: {
      title: "Change management for these projects?",
      description:
        "When you confirm this action, the selected projects will be moved to the new group and manager",
    },

    failed: {
      title: `Couldn't change the management of ${numberOfErrors} out of ${numberOfUpdatingItems} projects`,
      description:
        "The management of these projects couldn't be changed, check the details and try again.",
    },
    confirmButtonText: "change",
    entityType: "project",
  });

  const successDialogContent = {
    title: `${nounPluralize({
      counter: numberOfSuccess,
      word: "project",
    })} changed management.`,
    description:
      "All the management of the selected projects was successfully changed.",
  };

  return { bulkDialogContent, successDialogContent };
}
