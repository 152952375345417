import {
  GridComparatorFn,
  GridSortCellParams,
  GridSortModel,
} from "@mui/x-data-grid-pro";

/**
 * Wrapper around the mui grid table comparator function with the extra feature to send undefined values to the
 * bottom of the sorted list.
 *
 * @param comparator Comparator function
 */
export function faroTableComparator<T>(
  v1: T,
  v2: T,
  cellParams1: GridSortCellParams<T>,
  cellParams2: GridSortCellParams<T>,
  comparator: GridComparatorFn<T>
): number {
  // Early exit if both values are not defined
  if (!v1 && !v2) {
    return 0;
  }

  // Check if one value is not defined and send it to the end of the sort comparison
  if (!v1 || !v2) {
    const sortModel: GridSortModel = cellParams1.api.getSortModel();
    const sortColumn = sortModel.find((sm) => sm.field === cellParams1.field);

    if (sortColumn && sortColumn.sort === "desc") {
      return !v1 ? -1 : 1;
    } else {
      return !v1 ? 1 : -1;
    }
  }

  return comparator(v1, v2, cellParams1, cellParams2);
}
