import { GroupsTable } from "@components/table/groups/groups-table";
import { GroupHeaders } from "@components/table/groups/groups-table-utils";
import { GroupTypes } from "@custom-types/group-types";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { GroupsPageBulkActions } from "@pages/groups/groups-page-bulk-actions";

interface Props extends BaseCompanyIdProps {
  /** List of groups to show in table */
  groups: GroupTypes[];

  /** Flag whether it is loading some content  */
  isLoading: boolean;
}

/** Content to show for groups table */
export function GroupsPageTable({
  companyId,
  groups,
  isLoading,
}: Props): JSX.Element {
  const buttonComponents = GroupsPageBulkActions({
    groups,
  });

  return (
    <GroupsTable
      companyId={companyId}
      groups={groups}
      isLoading={isLoading}
      requiredColumns={[
        GroupHeaders.thumbnail,
        GroupHeaders.name,
        GroupHeaders.managers,
        GroupHeaders.projects,
        GroupHeaders.createdDate,
        GroupHeaders.options,
      ]}
      bulkActionButtons={buttonComponents}
    />
  );
}
