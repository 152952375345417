import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { dataViewModeSelector } from "@store/ui/ui-selector";
import {
  DataViewMode,
  setGroupsDataViewMode,
  setProjectsDataViewMode,
} from "@store/ui/ui-slice";
import ListView from "@assets/icons/List_View.svg?react";
import TilesView from "@assets/icons/Tiles_View.svg?react";
import { sphereColors } from "@styles/common-colors";
import { DataViewEvents } from "@utils/track-event/track-event-list";
import { Box } from "@mui/material";
import { DataDisplayAction } from "@components/common/data-display-action";
import { useTrackEvent } from "@utils/track-event/use-track-event";

interface Props {
  /** The type of the subject that the data is going to be displayed */
  subjectType: "project" | "group";
}

/**
 * Show the main actions which changes the way some type of data is displayed
 */
export function DataDisplayActions({ subjectType }: Props): JSX.Element {
  const { projectsView, groupsView } = useAppSelector(dataViewModeSelector);
  const { trackEvent } = useTrackEvent();
  const dispatch = useAppDispatch();

  /**
   * Whether the data view is active or not depending on the current view mode,
   * currently either groups or projects
   */
  function isDataViewActive(dataView: DataViewMode): boolean {
    if (subjectType === "project") {
      return projectsView === dataView;
    }
    if (subjectType === "group") {
      return groupsView === dataView;
    }
    return false;
  }

  /**
   * Toggles the data view mode between list and cards, depending on the current view mode,
   * currently either groups or projects
   */
  function toggleDataViewMode(): void {
    let newDataView: DataViewMode | undefined;

    if (subjectType === "project") {
      newDataView =
        projectsView === DataViewMode.cards
          ? DataViewMode.list
          : DataViewMode.cards;
      dispatch(setProjectsDataViewMode(newDataView));
    }
    if (subjectType === "group") {
      newDataView =
        groupsView === DataViewMode.cards
          ? DataViewMode.list
          : DataViewMode.cards;
      dispatch(setGroupsDataViewMode(newDataView));
    }

    if (!newDataView) {
      return;
    }

    trackEvent({
      name:
        newDataView === DataViewMode.cards
          ? DataViewEvents.selectCardStyle
          : DataViewEvents.selectListStyle,
    });
  }

  return (
    <Box
      component="div"
      sx={{
        backgroundColor: sphereColors.gray100,
        borderRadius: "4px",
        width: "70px",
        height: "36px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingX: "3px",
        marginRight: "12px",
      }}
      onClick={toggleDataViewMode}
    >
      <DataDisplayAction
        isActive={isDataViewActive(DataViewMode.cards)}
        component={TilesView}
      />
      <DataDisplayAction
        isActive={isDataViewActive(DataViewMode.list)}
        component={ListView}
      />
    </Box>
  );
}
