import { useCoreApiClient } from "@api/use-core-api-client";
import { MemberTableSubject } from "@components/common/faro-table/faro-table-types";
import { FaroTextButton } from "@components/common/faro-text-button";
import { MemberTypes } from "@custom-types/member-types";
import {
  BaseCompanyIdProps,
  BaseProjectIdProps,
} from "@custom-types/sdb-company-types";
import { isApiError } from "@custom-types/type-guards";
import { useToast } from "@hooks/use-toast";
import {
  removeOneAfterFetching,
  removeOneManually,
  setBulkActionName,
} from "@store/table/table-slice";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { isMemberActive } from "@utils/user-utils";
import { BulkActionEvents } from "@utils/track-event/track-event-list";
import { tableTypeSelector } from "@store/table/table-selector";
import { useMemo } from "react";
import { useTrackEvent } from "@utils/track-event/use-track-event";

interface Props extends BaseCompanyIdProps, Partial<BaseProjectIdProps> {
  /** The entities that are selected */
  selectedEntities: MemberTypes[];

  /** The context of the table */
  subjectType: MemberTableSubject;
}

/**
 * Resend invitation in bulk to the unregistered members of:
 * projects when project is available and component rendered under the team tab of project overview,
 */
export function BulkResendInvitation({
  selectedEntities,
  companyId,
  projectId,
  subjectType,
}: Props): JSX.Element | null {
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const { showToast } = useToast();
  const { trackEvent } = useTrackEvent();
  const tableType = useAppSelector(tableTypeSelector);

  /** Returns appropriate tooltip text for resend invitation button */
  const tooltipText = useMemo(() => {
    switch (subjectType) {
      case "workspace":
      case "group":
        return "Resend invitation to workspace";

      case "project":
        return "Resend invitation to project";

      default:
        return undefined;
    }
  }, [subjectType]);

  async function onResendInvitationClick(): Promise<void> {
    const bulkActionResults: { success: number; failed: number } = {
      success: 0,
      failed: 0,
    };

    if (tableType) {
      trackEvent({
        name: BulkActionEvents.bulkResendInvitation,
        props: {
          dataType: tableType,
          numberOfEntities: selectedEntities.length,
        },
      });
    }

    dispatch(setBulkActionName("resendInvitation"));

    /** Collects all promises to resend invitations so that we can wait for all of them only once */
    const resendInvitationPromises = selectedEntities.map((member) => {
      if (isMemberActive(member)) {
        dispatch(removeOneManually(member.identity));
        return "skip";
      }
      if (projectId && subjectType === "project") {
        // No need to be an asyncThunk as we don't need to update the store
        return coreApiClient.V3.SDB.resendProjectMembershipInvite({
          companyId,
          projectId,
          email: member.email,
        }).then(() => {
          dispatch(removeOneAfterFetching(member.identity));
        });
      } else if (subjectType === "workspace" || subjectType === "group") {
        // No need to be an asyncThunk as we don't need to update the store
        return coreApiClient.V3.SDB.resendCompanyMembershipInvite({
          companyId,
          identity: member.email,
        }).then(() => {
          dispatch(removeOneAfterFetching(member.identity));
        });
      }
      return undefined;
    });

    await Promise.allSettled(resendInvitationPromises).then((results) => {
      results.forEach((result) => {
        if (result.status === "fulfilled" && result.value !== "skip") {
          bulkActionResults.success++;
        } else if (result.status === "rejected") {
          bulkActionResults.failed++;
          const error = result.reason;
          if (isApiError(error)) {
            showToast({
              message: isApiError(error) ? error.message : "Unknown error",
              type: "error",
            });
          }
        }
      });
    });

    if (bulkActionResults.failed === 0) {
      showToast({
        message: "Invitations sent",
        type: "success",
      });
    } else if (bulkActionResults.success > 0) {
      showToast({
        message: `${bulkActionResults.success} of ${
          bulkActionResults.success + bulkActionResults.failed
        } invitations sent`,
        type: "info",
      });
    }

    dispatch(setBulkActionName(null));
  }

  return (
    <FaroTextButton
      onClick={onResendInvitationClick}
      tooltipText={tooltipText}
      sx={{ fontWeight: 600 }}
    >
      Resend invitation
    </FaroTextButton>
  );
}
