import { useLocalization } from "@hooks/use-localization";
import { LanguageSelectorEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { Localization } from "@utils/localization";
import { LanguageSelectOption } from "@faro-lotv/flat-ui";
import { isLanguageObject } from "@custom-types/type-guards";

/**
 * A simple Language menu used in the user-menu.
 * When opened the user can chose between the available languages
 * and select to which one the Dashboard should be translated to.
 */
export function LanguageSelector(): JSX.Element {
  const { availableLanguages, selectedLanguage } = useLocalization();
  const { trackEvent } = useTrackEvent();

  const languages = availableLanguages.filter(isLanguageObject);

  /** Sets the language to the given language object and tracks the interaction */
  function setLanguage(language: LocalizeJS.Context.LanguageObject): void {
    trackEvent({
      name: LanguageSelectorEvents.changeLanguage,
      props: { language: language.code },
    });

    Localization.setLanguage(language.code);
  }

  function onChange(languageCode: string): void {
    const languageObject = availableLanguages.find(
      ({ code }) => code === languageCode
    );

    if (languageObject) {
      setLanguage(languageObject);
    }
  }

  return (
    <LanguageSelectOption
      selectedLanguage={selectedLanguage}
      languages={languages}
      onLanguageChange={onChange}
    />
  );
}
