import {
  isCumulativeStat,
  isDifferentialStat,
  isProjectSheetsStat,
  isProjectSpheresStat,
  isProjectSqftStat,
  isProjectStat,
  isStatPair,
} from "@custom-types/analytics-type-guards";
import {
  AnalyticsChartData,
  ChartLegend,
  ChartMonth,
  ChartTitle,
  ChartType,
  StatPair,
  StatPairsBase,
} from "@custom-types/analytics-types";
import { DashboardAPITypes } from "@stellar/api-logic";

/**
 * Gets analytics stats from backend and returns a stat pair object
 * suitable for rendering stacked charts
 *
 * @param stats fetched from the backend
 * @returns StatPairs object ready for stacked charts
 */
export function getBaseStatPairs(
  stats: DashboardAPITypes.ICompanyStatistic[]
): StatPairsBase {
  // Default base stat pairs
  const statPairs: StatPairsBase = {
    [ChartType.project]: {
      name: ChartType.project,
      title: ChartTitle.project,
    },
    [ChartType.projectSqft]: {
      name: ChartType.projectSqft,
      title: ChartTitle.projectSqft,
    },
    [ChartType.projectSpheres]: {
      name: ChartType.projectSpheres,
      title: ChartTitle.projectSpheres,
    },
    [ChartType.projectSheets]: {
      name: ChartType.projectSheets,
      title: ChartTitle.projectSheets,
    },
  };

  stats.forEach((stat) => {
    // Handle project stats
    if (isProjectStat(stat)) {
      if (isCumulativeStat(stat)) {
        statPairs["project-count"].cumulative = stat;
      }

      if (isDifferentialStat(stat)) {
        statPairs["project-count"].differential = stat;
      }
    }

    // Handles sqft stats
    if (isProjectSqftStat(stat)) {
      if (isCumulativeStat(stat)) {
        statPairs["project-sqft"].cumulative = stat;
      }

      if (isDifferentialStat(stat)) {
        statPairs["project-sqft"].differential = stat;
      }
    }

    // Handles 360 photos stats
    if (isProjectSpheresStat(stat)) {
      if (isCumulativeStat(stat)) {
        statPairs["project-spheres"].cumulative = stat;
      }

      if (isDifferentialStat(stat)) {
        statPairs["project-spheres"].differential = stat;
      }
    }

    // Handles sheets stats
    if (isProjectSheetsStat(stat)) {
      if (isCumulativeStat(stat)) {
        statPairs["project-sheets"].cumulative = stat;
      }

      if (isDifferentialStat(stat)) {
        statPairs["project-sheets"].differential = stat;
      }
    }
  });

  return statPairs;
}

/**
 * Gets and object with stat pairs and returns and array with only
 * valid stat pairs. A valid stat pair has both cumulative and differential stats
 * defined.
 */
export function getStatPairs(statPairs: StatPairsBase): StatPair[] {
  const statPairsArray = Object.values(statPairs);
  const validStatPairs: StatPair[] = [];

  statPairsArray.forEach((statPair) => {
    // Validate stat pairs: only allow stat pairs where both cumulative and differential exist.
    if (isStatPair(statPair)) {
      validStatPairs.push(statPair);
    }
  });

  return validStatPairs;
}

interface GetExistingDataSetProps {
  /** Data set for the total (cumulative) count */
  totalDataSet: number[];

  /** Data set for the new (differential) count */
  newDataSet: number[];
}

/**
 * Returns the `existing` dataset from the passed total a new datasets
 */
export function getExistingDataSet({
  totalDataSet,
  newDataSet,
}: GetExistingDataSetProps): number[] {
  const existingDataSet: number[] = [];

  // Early exit with empty array if data sets have different size
  if (totalDataSet.length !== newDataSet.length) {
    return existingDataSet;
  }

  for (let i = 0; i < totalDataSet.length; i++) {
    existingDataSet.push(totalDataSet[i] - newDataSet[i]);
  }

  return existingDataSet;
}

interface GetTimeFramedDataSetProps<T> {
  /** The time frame in number of months */
  timeFrame: number;

  /** Data set */
  dataSet: T[];
}

export function getTimeFramedDataSet<T>({
  timeFrame,
  dataSet,
}: GetTimeFramedDataSetProps<T>): T[] {
  const lastPointIndex = dataSet.length;
  const firstPointIndex = lastPointIndex - timeFrame;

  // Return full dataset if the index of the first point is negative
  // It means that the time frame is greater than the data set size
  if (firstPointIndex < 0) {
    return dataSet;
  }

  return dataSet.slice(firstPointIndex, lastPointIndex);
}

interface GetChartDataProps {
  /** The time frame in number of months */
  timeFrame: number;

  /** Stat pair */
  statPair: StatPair;
}

/**
 * Returns a chart data object used to render a chart.
 * Returns null if the cumulative (total) and differential (new) datasets length does not match
 *
 * @param timeFrame selected time frame
 * @param statPair Stat pair object
 */
export function getChartData({
  timeFrame,
  statPair,
}: GetChartDataProps): AnalyticsChartData | null {
  // Check if chart should be rendered or not
  // If cumulative and new differential length does not match then it means something is wrong
  // and it is better to not attempt to display the chart
  if (statPair.cumulative.data.length !== statPair.differential.data.length) {
    return null;
  }

  // Get labels
  const labels = statPair.cumulative.data.map((dataPoint) => {
    return dataPoint.label;
  });
  const timeFramedLabels = getTimeFramedDataSet({
    timeFrame,
    dataSet: labels,
  });

  // Get total data set
  const totalDataSet = statPair.cumulative.data.map((dataPoint) => {
    return dataPoint.values[0];
  });
  const timeFramedTotalDataSet = getTimeFramedDataSet({
    timeFrame,
    dataSet: totalDataSet,
  });

  // Get new data set
  const newDataSet = statPair.differential.data.map((dataPoint) => {
    return dataPoint.values[0];
  });
  const timeFramedNewDataSet = getTimeFramedDataSet({
    timeFrame,
    dataSet: newDataSet,
  });

  // Get existing data set
  const existingDataSet = getExistingDataSet({
    totalDataSet,
    newDataSet,
  });
  const timeFramedExistingDataSet = getTimeFramedDataSet({
    timeFrame,
    dataSet: existingDataSet,
  });

  return {
    name: statPair.name,
    title: statPair.title,
    labels: timeFramedLabels,
    datasets: {
      total: timeFramedTotalDataSet,
      new: timeFramedNewDataSet,
      existing: timeFramedExistingDataSet,
    },
  };
}

interface GetChartsDataProps {
  /** The time frame in number of months */
  timeFrame: number;

  /** Stats object received from backend */
  stats: DashboardAPITypes.ICompanyStatistic[] | null;
}

/**
 * Returns an array of chart data that can be used to render multiple charts
 *
 * @param stats object taken from the backend response.
 */
export function getChartsData({
  timeFrame,
  stats,
}: GetChartsDataProps): AnalyticsChartData[] {
  const chartsData: AnalyticsChartData[] = [];

  if (!stats || !stats.length) {
    return chartsData;
  }

  const statPairs = getBaseStatPairs(stats);

  const validStatPairs = getStatPairs(statPairs);

  validStatPairs.forEach((statPair) => {
    const chartData = getChartData({
      timeFrame,
      statPair,
    });

    if (chartData) {
      chartsData.push(chartData);
    }
  });

  return chartsData;
}

interface GetChartLabelProps {
  /** The raw data labels */
  labels: string[];

  /** The index of the label to format */
  index: number;
}

/**
 * Returns a single date label as an array of length 2: month, year
 *
 * @param labels the raw labels array
 * @param index index of the label to format
 */
export function getChartLabel({ labels, index }: GetChartLabelProps): string[] {
  const defaultLabel = ["", ""];

  if (!labels.length) {
    return defaultLabel;
  }

  const label = labels[index];

  if (!label) {
    return defaultLabel;
  }

  // Data labels coming from backend always have this format: "Jan'22"
  const monthAndYear = label.split("'");
  const month = monthAndYear[0];
  const year = monthAndYear[1];

  // Only display month if:
  // It has a valid month format
  const validMonthValues = Object.values(ChartMonth);
  const shouldDisplayMonth = validMonthValues.includes(month as ChartMonth);

  // Only display the year if:
  // It has valid year format and
  // It is the first label or the label is for January months
  const isValidYear = /^\d{2}$/.test(year);
  const isFirstLabel = index === 0;
  const isJanuary = month === ChartMonth.jan;
  const shouldDisplayYear = isValidYear && (isFirstLabel || isJanuary);

  // Format year from 2-digit to 4-digit
  // Assume that all possible years date back from 2000
  const formattedYear = `20${year}`;

  const chartLabel = [
    shouldDisplayMonth ? month : "",
    shouldDisplayYear ? formattedYear : "",
  ];

  return chartLabel;
}

/** Returns the legend text for the existing dataset */
export function getChartLegendExisting(chartName: ChartType): ChartLegend {
  switch (chartName) {
    case ChartType.project:
      return ChartLegend.projectExisting;
    case ChartType.projectSqft:
      return ChartLegend.projectSqftExisting;
    case ChartType.projectSpheres:
      return ChartLegend.projectSpheresExisting;
    case ChartType.projectSheets:
      return ChartLegend.projectSheetsExisting;
    default:
      return ChartLegend.defaultExisting;
  }
}

/** Returns the legend text for the new dataset */
export function getChartLegendNew(chartName: ChartType): ChartLegend {
  switch (chartName) {
    case ChartType.project:
      return ChartLegend.projectNew;
    case ChartType.projectSqft:
      return ChartLegend.projectSqftNew;
    case ChartType.projectSpheres:
      return ChartLegend.projectSpheresNew;
    case ChartType.projectSheets:
      return ChartLegend.projectSheetsNew;
    default:
      return ChartLegend.defaultNew;
  }
}
