import { Divider, SxProps } from "@mui/material";
import { sphereColors } from "@styles/common-colors";

interface Props {
  /** General properties of styling that is passed through sx to keep the components same as MUI component */
  sx?: SxProps;
}

/**
 * Gets a simple divider with the correct style.
 *
 * @returns React menu divider component.
 */
export function SphereDivider({ sx }: Props): JSX.Element {
  return (
    <Divider
      sx={{
        margin: 0,
        borderColor: sphereColors.gray100,
        ...sx,
      }}
    />
  );
}
