import { useAppParams } from "@router/router-helper";
import { NotFoundPage } from "@pages/not-found-page";
import { MainWorkspaceSettings } from "@pages/settings/workspace-settings/main-workspace-settings";
import { TabsWithRoutes } from "@components/common/tabs-with-routes";
import { CircularProgress } from "@faro-lotv/flat-ui";
import { useAppSelector } from "@store/store-helper";
import { SettingsTabs } from "@router/route-params";
import { AccessControlSettings } from "@pages/settings/access-control-settings/access-control-settings";
import { isBetaTestingEnabledSelector } from "@store/ui/ui-selector";
import { selectedSdbCompanySelector } from "@store/sdb-company/sdb-company-selector";
import { SPHERE_LEGACY_MIGRATED_TAG } from "@utils/sdb-company-utils";
import { OpenTabEvents } from "@utils/track-event/track-event-list";

/** Content in the Workspace Settings page */
export function WorkspaceSettingsPage(): JSX.Element {
  const { settingsTab } = useAppParams();
  const { companyId } = useAppParams();
  const isBetaTestingEnabled = useAppSelector(isBetaTestingEnabledSelector);
  const selectedSdbCompany = useAppSelector(selectedSdbCompanySelector);

  const shouldShowAccessControlTab =
    isBetaTestingEnabled &&
    !selectedSdbCompany?.tags.includes(SPHERE_LEGACY_MIGRATED_TAG);

  if (
    !companyId ||
    !settingsTab ||
    !Object.values(SettingsTabs).includes(settingsTab) ||
    (settingsTab === SettingsTabs.accessControl && !shouldShowAccessControlTab)
  ) {
    return <NotFoundPage />;
  }

  const settingsTabs = [
    {
      label: "General",
      route: SettingsTabs.general,
      content: () => <MainWorkspaceSettings />,
      loadingContent: <CircularProgress />,
    },
  ];

  if (shouldShowAccessControlTab) {
    settingsTabs.push({
      label: "Access Control",
      route: SettingsTabs.accessControl,
      content: () => <AccessControlSettings />,
      loadingContent: <CircularProgress />,
    });
  }

  return (
    <TabsWithRoutes
      dataTestId="WORKSPACE_SETTINGS_CONTAINER"
      selectedTab={settingsTab}
      requiredAttribute={false}
      trackingEventPageName={OpenTabEvents.openWorkspaceSettingsTab}
      tabs={settingsTabs}
    />
  );
}
