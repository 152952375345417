/**
 * Whether current password string is non-empty.
 */
export function isValidCurrentPassword(password: string): boolean {
  return password.length > 0;
}

/**
 * Whether repeat new password string is valid.
 */
export function isValidRepeatNewPassword(
  repeatPassword: string,
  newPassword: string
): boolean {
  // Return true if there is nothing to compare the repeat password against.
  if (!newPassword) {
    return true;
  }

  return repeatPassword === newPassword;
}
