import { sphereColors } from "@styles/common-colors";
import { Box, SxProps } from "@mui/material";
import {
  DEFAULT_TEXT_FONT_SIZE,
  DISABLED_OPACITY,
} from "@styles/common-styles";

interface Props {
  /**
   * The title that will be shown on the left side of the component.
   */
  title: string;

  /**
   * Whether the field that this label component field is used it is required.
   * If true, it adds a blue asterisk next to the title.
   */
  isRequired?: boolean;

  /**
   * Whether the field it is disabled.
   * If true, it shows the text as opaque.
   */
  isDisabled?: boolean;

  /** Optional component to show on the right side of the label */
  rightSideComponent?: React.ReactNode;

  /** General properties of styling that is passed through sx to the component wrapper */
  sx?: SxProps;
}

/**
 * Renders a label that can be used inside dialogs.
 * The component take the full width of the parent and on the left side places a label.
 */
export function SphereLabel({
  title,
  isRequired = false,
  isDisabled = false,
  rightSideComponent,
  sx,
}: Props): JSX.Element {
  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: DEFAULT_TEXT_FONT_SIZE,
        fontWeight: 800,
        marginBottom: "4px",
        letterSpacing: "normal",
        ...sx,
      }}
    >
      {/* Left side of the label */}
      <Box
        component="div"
        sx={{
          opacity: isDisabled ? DISABLED_OPACITY : 1,
          mr: "10px",
        }}
      >
        <Box component="span">{title}</Box>
        {/* Optional blue asterisk */}
        {isRequired && (
          <Box
            component="span"
            sx={{
              color: sphereColors.blue500,
              padding: "3px",
            }}
          >
            *
          </Box>
        )}
      </Box>

      {/* Right side component */}
      {rightSideComponent}
    </Box>
  );
}
