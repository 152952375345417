import DateAdapter from "@date-io/luxon";
import { useLocalization } from "@hooks/use-localization";
import { DateTime } from "luxon";
import { useCallback, useMemo } from "react";

/** The format options for the date */
export type FormatDate = (
  date: string | number | Date,
  formatOptions?: Intl.DateTimeFormatOptions
) => string;

interface UseDateTime {
  /** The function to format and translate dates */
  formatDate: FormatDate;
}

interface FormatAndTranslateDate {
  /** The date to format and translate */
  date: string | number | Date;

  /** The language code to use for the translation */
  languageCode: string;

  /** The format options */
  formatOptions?: Intl.DateTimeFormatOptions;
}

/**
 * Hook to format and translate dates
 *
 * @returns The formatDate function that can be used to format and translate dates
 */
export function useDateTime(): UseDateTime {
  const { selectedLanguage } = useLocalization();

  const luxonAdapter = useMemo(
    () => new DateAdapter({ locale: selectedLanguage }),
    [selectedLanguage]
  );

  const formatAndTranslateDate = useCallback(
    ({
      date,
      languageCode,
      formatOptions = DateTime.DATE_FULL,
    }: FormatAndTranslateDate): string => {
      const luxonDate = luxonAdapter.date(new Date(date));

      return luxonDate
        ? luxonDate.setLocale(languageCode).toLocaleString(formatOptions)
        : "";
    },
    [luxonAdapter]
  );

  const formatDate: UseDateTime["formatDate"] = useCallback(
    (date, formatOptions) =>
      formatAndTranslateDate({
        date,
        languageCode: selectedLanguage,
        formatOptions,
      }),
    [formatAndTranslateDate, selectedLanguage]
  );

  return { formatDate };
}
