import {
  projectMarkupStatusText,
  markupStatusColor,
  MarkupStatusColorReturn,
} from "@pages/project-details/project-markups/status/markup-status-types";
import { generateGUID } from "@faro-lotv/foundation";
import {
  GUID,
  IElementDropDownMarkupField,
  IElementMarkup,
  IElementType,
  IElementTypeHint,
} from "@faro-lotv/ielement-types";
import {
  Mutation,
  createMutationAddMarkupField,
  createMutationDeleteElement,
  createMutationDropDownMarkupField,
  isMarkupStatus,
} from "@faro-lotv/service-wires";
import { AllMarkupStatus } from "@pages/project-details/project-markups/status/markup-status-types";
import {
  isMarkupStatusKnown,
  isMarkupStatusChipOptions,
} from "@custom-types/type-guards";
import { StatusOptions } from "@faro-lotv/flat-ui";

/** Gets the markup status value from the markup status received from backend */
export function getMarkupStatusValue(value?: string | null): AllMarkupStatus {
  return isMarkupStatusKnown(value) ? value : "unclassified";
}

/** Returns corresponding label based on the status */
export function getMarkupStatusLabel(status?: string | null): string {
  return isMarkupStatusKnown(status)
    ? projectMarkupStatusText[status]
    : projectMarkupStatusText.unclassified;
}

/** Returns corresponding color based on the status */
export function getMarkupStatusColor(
  status?: string | null
): MarkupStatusColorReturn {
  return isMarkupStatusKnown(status)
    ? markupStatusColor[status]
    : markupStatusColor.unclassified;
}

/** returns that status based in the enum from flat ui */
export function getMarkupStatusChip(status?: string | null): StatusOptions {
  return isMarkupStatusChipOptions(status)
    ? status
    : StatusOptions.unclassified;
}

/**
 * Create mutation for editing the markup status
 * TODO: Remove this function because it is copied from lotv-monorepo as it is not available in the public API.
 * https://faro01.atlassian.net/browse/ST-1787
 *
 * @param markupElement to edit
 * @param newStatus to apply
 * @param previousValueId if a value was already present
 * @param statusTemplateId to reference in the new UserDirectoryMarkupField node
 * @returns the proper mutation to update the Markup to the new value
 */
export function createEditStatusMutation(
  markupElement: IElementMarkup,
  newStatus: string | undefined,
  previousValueId: GUID | undefined,
  statusTemplateId: GUID
): Mutation[] {
  if (newStatus) {
    if (!isMarkupStatus(newStatus)) {
      throw new Error("The new status should be one of the valid statuses");
    }

    // Edit previous value if it exists
    if (previousValueId) {
      return [createMutationDropDownMarkupField(previousValueId, newStatus)];
    }

    // Create a new node if it did not exists
    return [
      createMutationAddMarkupField<IElementDropDownMarkupField>(
        markupElement.id,
        {
          id: generateGUID(),
          childrenIds: null,
          name: "Status",
          parentId: markupElement.id,
          rootId: markupElement.rootId,
          templateId: statusTemplateId,
          type: IElementType.dropDownMarkupField,
          typeHint: IElementTypeHint.markupIssueStatus,
          value: newStatus,
        }
      ),
    ];
  } else if (previousValueId) {
    // Remove the value node if not needed anymore
    return [createMutationDeleteElement(previousValueId)];
  }
  return [];
}
