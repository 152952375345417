import { DashboardAPITypes } from "@stellar/api-logic";

/** All available analytics stat names */
export const statName: { [key in DashboardAPITypes.StatisticName]: key } = {
  /* eslint-disable @typescript-eslint/naming-convention -- named by backend */
  "project-count": "project-count",
  "project-count-abs": "project-count-abs",
  "project-sqft": "project-sqft",
  "project-sqft-abs": "project-sqft-abs",
  "project-spheres": "project-spheres",
  "project-spheres-abs": "project-spheres-abs",
  "project-sheets": "project-sheets",
  "project-sheets-abs": "project-sheets-abs",
  /* eslint-enable @typescript-eslint/naming-convention */
};

/** All available analytics stat data generation types */
export const dataGenerationType: {
  [key in DashboardAPITypes.ChartDataGeneration]: key;
} = {
  cumulative: "cumulative",
  differential: "differential",
};
