import { Box } from "@mui/material";
import HappyFace from "@assets/icons/new/happy-face_44px.svg?react";
import RegularFace from "@assets/icons/new/regular-face_44px.svg?react";
import UnhappyFace from "@assets/icons/new/unhappy-face_44px.svg?react";
import { RateElement, RateElementProps } from "@components/common/rate-element";
import { MAX_RATING } from "@utils/email-utils";

interface Props {
  /** The initial value of the rate */
  value: number | null;

  /** Callback when the rate element is clicked */
  onChange: (value: number | null) => void;
}

/** The possible values for the rate */
enum RateValue {
  Unhappy = "NOT GOOD!",
  Okay = "OKAY",
  Great = "GREAT!",
}

/** The options for the rate element */
const RATE_OPTIONS: Omit<RateElementProps, "onClick">[] = [
  {
    label: RateValue.Unhappy,
    value: 1,
    iconComponent: UnhappyFace,
  },
  {
    sx: { marginX: "12px" },
    label: RateValue.Okay,
    value: 3,
    iconComponent: RegularFace,
  },
  {
    label: `${RateValue.Great}!`,
    value: MAX_RATING,
    iconComponent: HappyFace,
  },
];

/**
 * Contains a rate component with three rate elements.
 */
export function RateComponent({ value, onChange }: Props): JSX.Element {
  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {RATE_OPTIONS.map((rateOption) => (
        <RateElement
          key={rateOption.value}
          sx={rateOption.sx}
          label={rateOption.label}
          value={rateOption.value}
          iconComponent={rateOption.iconComponent}
          selectedValue={value}
          onClick={onChange}
        />
      ))}
    </Box>
  );
}
