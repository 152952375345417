import { FilterChipComponents } from "@components/common/faro-table/faro-table-filter/faro-table-filter-types";
import { useMediaQueryList } from "@hooks/use-media-query";
import { FilterToolbarLargeScreen } from "@components/common/faro-table/faro-table-filter/filter-toolbar-large-screen";
import { FilterToolbarSmallScreen } from "@components/common/faro-table/faro-table-filter/filter-toolbar-small-screen";
import { Fragment } from "react";

interface Props {
  /** filter chips shown in table action bar */
  filterChips: FilterChipComponents;
}

/** Toolbar to show for filter chips for both small and large screens */
export function FaroTableFilter({ filterChips }: Props): JSX.Element {
  const { isScreenXsAndSmall } = useMediaQueryList();

  /** All the filter chips that are not null as array */
  const filterChipComponents: JSX.Element[] = Object.values(filterChips)
    .filter((filterChip) => filterChip !== null)
    .map((filterChip, index) => <Fragment key={index}>{filterChip}</Fragment>);

  if (isScreenXsAndSmall) {
    return (
      <FilterToolbarSmallScreen filterChipComponents={filterChipComponents} />
    );
  }
  return (
    <FilterToolbarLargeScreen filterChipComponents={filterChipComponents} />
  );
}
