import { Box, Typography } from "@mui/material";
import { SphereAvatar } from "@components/header/sphere-avatar";
import { withEllipsis } from "@styles/common-styles";
import { BlockedClasses } from "@utils/localization";
import { EDecimalToHex, sphereColors } from "@styles/common-colors";
import AutosuggestHighlightMatch from "autosuggest-highlight/match";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";
import { useMemo } from "react";
import CheckIcon from "@assets/icons/new/check_18px.svg?react";
import { addTransparency } from "@utils/ui-utils";

export interface UserItem {
  /**
   * The title for the first row of the user item, ideally shows the
   * user's full name or the email if there is no name.
   */
  title: string;

  /**
   * Optionally shows a row below the title, ideally it shows the
   * user's email address, if the name is shown in the title
   */
  subtitle?: string;

  /**
   * Optional user initials to be shown in the avatar.
   */
  userInitials?: string;

  /**
   * Optional url for an image to be shown in the avatar.
   */
  userAvatarImg?: string;
}

interface Props {
  /** Optionally you can directly pass the item to be rendered. */
  item: UserItem;

  /**
   * Optional disabled message to show in the item.
   * This property only shows the message itself but does not make the item disabled,
   * that should be handled in parent component, most likely a "li" element.
   */
  disabledMessage?: string | null;

  /** Input value typed on the input field */
  inputValue?: string;

  /**
   * Determines whether the item is active (selected).
   * When set to true, an indicator (such as an icon) will be displayed
   * to indicate that the item is currently selected or active.
   */
  isActive?: boolean;
}

/** Width for the user avatar */
const AVATAR_WIDTH = "50px";

/** Class name to highlight part of string */
const HIGHLIGHT_CLASS = "text_highlight";

export function UserListItem({
  item,
  disabledMessage,
  inputValue,
  isActive,
}: Props): JSX.Element {
  const highlightedTitle = useMemo(() => {
    /** Early return if there is no input value entered */
    if (!inputValue) {
      return item.title;
    }

    // Finding the match parts
    const matches = AutosuggestHighlightMatch(item.title, inputValue);
    const parts = AutosuggestHighlightParse(item.title, matches);

    return (
      <span>
        {parts.map((part, index) => (
          <span className={part.highlight ? HIGHLIGHT_CLASS : ""} key={index}>
            {part.text}
          </span>
        ))}
      </span>
    );
  }, [item.title, inputValue]);

  const highlightedSubTitle = useMemo(() => {
    /** Early return if there is no input value entered */
    if (!inputValue || !item.subtitle) {
      return item.subtitle;
    }

    // Finding the match parts
    const matches = AutosuggestHighlightMatch(item.subtitle, inputValue);
    const parts = AutosuggestHighlightParse(item.subtitle, matches);

    return (
      <span>
        {parts.map((part, index) => (
          <span className={part.highlight ? HIGHLIGHT_CLASS : ""} key={index}>
            {part.text}
          </span>
        ))}
      </span>
    );
  }, [item.subtitle, inputValue]);

  return (
    <Box
      component="div"
      display="flex"
      alignItems="center"
      padding="5px 5px"
      width="100%"
      gap={1}
      sx={{
        background: isActive
          ? addTransparency({
              color: sphereColors.gray500,
              alpha: EDecimalToHex.thirtyEight,
            })
          : "",
        "&:hover": {
          background: sphereColors.gray100,
          cursor: isActive ? "initial" : "pointer",
        },
      }}
    >
      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box component="div" sx={{ width: AVATAR_WIDTH }}>
          <SphereAvatar
            size={"small"}
            initials={item.userInitials ?? ""}
            src={item.userAvatarImg}
          />
        </Box>
        <Box
          component="div"
          sx={{
            // If there is a disabled message we have two columns, otherwise we have one.
            width: disabledMessage
              ? `calc(50% - ${AVATAR_WIDTH} / 2)`
              : `calc(100% - ${AVATAR_WIDTH})`,
          }}
        >
          <Box
            className={BlockedClasses.noLocalize}
            component="div"
            sx={{
              width: "100%",
              color: isActive ? sphereColors.blue500 : sphereColors.gray800,
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              [`& .${HIGHLIGHT_CLASS}`]: {
                fontWeight: "bold",
              },
            }}
          >
            <Typography
              component={"var"}
              sx={{
                flex: 1,
                fontSize: "14px",
                lineHeight: "20px",
                fontWeight: 600,
                ...withEllipsis,
              }}
            >
              {highlightedTitle}
            </Typography>

            {isActive && <CheckIcon />}
          </Box>

          {item.subtitle && (
            <Box
              className={BlockedClasses.noLocalize}
              component="div"
              sx={{
                ...withEllipsis,
                width: "100%",
                color: sphereColors.gray500,
                fontSize: "12px",
                [`& .${HIGHLIGHT_CLASS}`]: {
                  fontWeight: "bold",
                },
              }}
            >
              {highlightedSubTitle}
            </Box>
          )}
        </Box>
        {disabledMessage && (
          <Box
            component="div"
            sx={{
              width: `calc(50% - ${AVATAR_WIDTH} / 2)`,
              color: sphereColors.gray500,
              fontSize: "12px",
              paddingLeft: "20px",
              textAlign: "end",
            }}
          >
            {disabledMessage}
          </Box>
        )}
      </Box>
    </Box>
  );
}
