import { Box, Link } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { PropsWithChildren } from "react";
import { contactSupport } from "@utils/email-utils";
import { ContactSupportLocations } from "@custom-types/types";
import { useTrackEvent } from "@utils/track-event/use-track-event";

interface Props extends PropsWithChildren {
  /** The title to be shown on top */
  title: string;

  /** Src to the image to show as an icon */
  iconImg: string;

  /** Flag whether the contact support button should be shown */
  shouldShowSupportBtn?: boolean;
}

/**
 * Defines the base page for an error, showing for example a
 * 404 page or an access denied page.
 */
export function ErrorPageBase({
  title,
  children,
  iconImg,
  shouldShowSupportBtn = false,
}: Props): JSX.Element {
  const { trackEvent } = useTrackEvent();

  return (
    <Box
      component="div"
      sx={{
        textAlign: "center",
        width: "100%",
      }}
      data-testid="error-page"
    >
      {/* Icon */}
      <Box component="div">
        <Box
          component="img"
          src={iconImg}
          sx={{
            width: "100%",
            maxWidth: "565px",
          }}
        />
      </Box>

      {/* Title */}
      <Box
        component="div"
        sx={{
          color: sphereColors.gray600,
          fontSize: "38px",
          fontWeight: "600",
          marginTop: "45px",
          marginBottom: "36px",
        }}
      >
        {title}
      </Box>

      {/* Subtitle */}
      <Box
        component="div"
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          component="div"
          sx={{
            color: sphereColors.gray600,
            fontSize: "20px",
            maxWidth: "880px",
          }}
        >
          {children}
          {shouldShowSupportBtn && (
            <Link
              onClick={() =>
                contactSupport({
                  location: ContactSupportLocations.selectWorkspacePage,
                  trackEvent,
                })
              }
              sx={{
                cursor: "pointer",
                color: sphereColors.blue500,
              }}
            >
              .
            </Link>
          )}
        </Box>
      </Box>
    </Box>
  );
}
