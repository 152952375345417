import { getRegistrationApiClient } from "@api/registration-api/registration-api-utils";
import { FaroButtonSpinner } from "@components/common/button/faro-button-spinner";
import { useDialog } from "@components/common/dialog/dialog-provider";
import { FaroTextButton } from "@components/common/faro-text-button";
import { useDataManagementContext } from "@context-providers/data-management/data-management-context";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useToast } from "@hooks/use-toast";
import { Typography } from "@mui/material";
import { hasRawScansProcessingSelector } from "@store/capture-tree/capture-tree-selectors";
import { useAppSelector } from "@store/store-helper";
import { currentUserSelector } from "@store/user/user-selector";
import { sphereColors } from "@styles/common-colors";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useCallback, useState } from "react";

/** Renders a button that triggers the data preparation workflow */
export function PrepareDataButton(): JSX.Element {
  const { projectId } = useDataManagementContext();
  const { trackAsyncEvent } = useTrackEvent();
  const { createDialog } = useDialog();
  const { showToast } = useToast();
  const { handleErrorWithToast } = useErrorContext();
  const currentUser = useAppSelector(currentUserSelector);
  const isRawDataProcessing = useAppSelector(hasRawScansProcessingSelector);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onClick = useCallback(async () => {
    let shouldPrepareData = true;

    if (isRawDataProcessing) {
      shouldPrepareData = await createDialog(
        {
          title: "Prepare data",
        },
        <>
          <Typography
            sx={{
              marginBottom: "24px",
            }}
          >
            Some scans are still processing. If you continue they won't be
            included in the data preparation process.
          </Typography>
          <Typography>Do you want to continue?</Typography>
        </>
      );
    }

    if (shouldPrepareData && projectId && currentUser && currentUser.id) {
      trackAsyncEvent({
        name: DataManagementEvents.triggerCaptureTreeRegistration,
      });
      setIsLoading(true);

      try {
        const registrationApiClient = getRegistrationApiClient({
          projectId,
          userId: currentUser.id,
        });

        await registrationApiClient.startCaptureTreeRegistration({});

        showToast({
          message: "Multi cloud registration started successfully",
          type: "success",
        });
      } catch (error) {
        handleErrorWithToast({
          id: `startCaptureTreeRegistration-${Date.now().toString()}`,
          title: "Error starting a multi cloud registration",
          error,
        });
      }

      setIsLoading(false);
    }
  }, [
    createDialog,
    currentUser,
    handleErrorWithToast,
    isRawDataProcessing,
    projectId,
    showToast,
    trackAsyncEvent,
  ]);

  return (
    <FaroTextButton
      onClick={onClick}
      tooltipText="Prepare data"
      size="large"
      isDisabled={isLoading}
      dataTestId="prepare-data-button"
    >
      Prepare data
      <FaroButtonSpinner
        shouldHide={!isLoading}
        loadingIndicatorColor={sphereColors.blue600}
        loadingTrackColor={sphereColors.gray100}
      />
    </FaroTextButton>
  );
}
