import { SxProps } from "@mui/material";
import { SphereLabel } from "@components/common/sphere-label";
import { SphereTooltipIcon } from "@components/common/sphere-tooltip-icon";

interface Props {
  /**
   * The title that will be shown on the left side of the component.
   */
  title: string;

  /**
   * Optional component to be shown inside the tooltip.
   * If not provided the help icon won't be shown.
   */
  help?: React.ReactNode;

  /**
   * Whether the field that this label component field is used it is required.
   * If true, it adds a blue asterisk next to the title.
   */
  isRequired?: boolean;

  /**
   * Whether the field it is disabled.
   * If true, it shows the text as opaque.
   */
  isDisabled?: boolean;

  /** General properties of styling that is passed through sx to the component wrapper */
  sx?: SxProps;
}

/**
 * Renders a label that can be used inside dialogs.
 * The component take the full width of the parent and on the left side
 * places a label. If provided, places on the right side a help icon
 * with a tooltip showing some help text.
 */
export function LabelWithHelp({
  title,
  help,
  isRequired = false,
  isDisabled = false,
  sx,
}: Props): JSX.Element {
  return (
    <SphereLabel
      title={title}
      isRequired={isRequired}
      isDisabled={isDisabled}
      rightSideComponent={help ? <SphereTooltipIcon text={help} /> : undefined}
      sx={sx}
    />
  );
}
