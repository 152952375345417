import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { updateSearchbar } from "@store/ui/ui-slice";
import { searchSelector } from "@store/ui/ui-selector";
import { useSearchPlaceholder } from "@hooks/route-match/use-search-placeholder";
import { useLocation } from "react-router";
import { useEffect } from "react";
import { SearchTextField } from "@components/common/search-text-field";

export function Searchbar(): JSX.Element {
  const dispatch = useAppDispatch();
  const placeholder = useSearchPlaceholder();
  const { pathname } = useLocation();

  const { searchText } = useAppSelector(searchSelector);

  // Reset the searchText when the location changes
  useEffect(() => {
    dispatch(updateSearchbar({ searchText: "" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only reset the searchText when the location changes
  }, [pathname]);

  return (
    <SearchTextField
      placeholder={placeholder}
      searchText={searchText}
      onClearClicked={() => dispatch(updateSearchbar({ searchText: "" }))}
      onChangeSearch={(searchText) => dispatch(updateSearchbar({ searchText }))}
    />
  );
}
