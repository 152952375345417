import { userInfoHeight } from "@components/user-info/user-info";
import { Typography, Grid } from "@mui/material";
import { sphereColors } from "@styles/common-colors";

/** Content to show when user does not belong to any group or project */
export function MemberProfileEmptyPage(): JSX.Element {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        height: userInfoHeight,
        backgroundColor: sphereColors.gray50,
        width: "100%",
      }}
    >
      <Grid item sx={{ mb: "30px", px: "30px" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            color: sphereColors.gray600,
          }}
        >
          Member is not connected to any projects or groups
        </Typography>
      </Grid>
    </Grid>
  );
}
