import { Box, Typography } from "@mui/material";
import { FaroIconButton } from "@components/common/faro-icon-button";
import CloseIcon from "@assets/icons/new/close_24px.svg?react";
import { withEllipsis } from "@styles/common-styles";
import { PointCloud } from "@custom-types/point-cloud-types";
import { PointCloudActions } from "@pages/project-details/project-data/point-clouds/point-cloud-actions";

interface Props {
  /** The markup selected for the side panel */
  pointCloud: PointCloud;

  /** Close side panel when we click in the close button */
  closeSidePanel: () => void;
}

/**
 * Displays the header for a point cloud side panel, including the name of the
 * selected point cloud and a close button to close the side panel.
 *
 * @param {Props} props - The props for the PointCloudSidePanelHeader component.
 * @returns {JSX.Element} A JSX element representing the PointCloudSidePanelHeader component.
 */
export function PointCloudSidePanelHeader({
  pointCloud,
  closeSidePanel,
}: Props): JSX.Element {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          fontWeight: "700",
          ...withEllipsis,
        }}
        data-testid="point-cloud-side-panel-header-name"
        aria-label={"Point cloud name"}
      >
        <var>{pointCloud?.name}</var>
      </Typography>

      <Box sx={{ display: "flex", gap: "8px" }}>
        {pointCloud.sphereViewerUrl && (
          <PointCloudActions
            sphereViewerUrl={pointCloud.sphereViewerUrl}
            isDividerVisible={false}
          />
        )}

        <FaroIconButton
          onClick={closeSidePanel}
          component={CloseIcon}
          buttonSize={"30px"}
          dataTestId="point-cloud-side-panel-close-button"
        />
      </Box>
    </Box>
  );
}
