import { Box, Button, ButtonProps, Menu } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { PropsWithChildren, useState } from "react";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import DownArrow from "@assets/icons/generic-down_l.svg?react";

interface Props {
  /** The text to show for the button */
  buttonTitle: string;

  /** True when the button should be disabled */
  isDisabled?: boolean;

  /** Text to show as tooltip */
  tooltipText?: string;

  /** Optional sx properties to override the button style. */
  buttonSx?: ButtonProps["sx"];
}

/** Renders text button having a menu opened as a dropdown */
export function FaroTextMenuButton({
  buttonTitle,
  tooltipText,
  isDisabled,
  children,
  buttonSx,
}: PropsWithChildren<Props>): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  return (
    <SphereTooltip title={tooltipText} shouldShowTooltip={!isMenuOpen}>
      <>
        <Button
          disabled={isDisabled}
          aria-controls={isMenuOpen ? "faro-text-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={!!isMenuOpen}
          variant="text"
          disableElevation
          onClick={(event) => setAnchorEl(event.currentTarget)}
          endIcon={<DownArrow />}
          sx={{
            fontSize: "12px",
            textTransform: "capitalize",
            color: sphereColors.blue500,
            "&:hover": {
              backgroundColor: sphereColors.gray200,
            },
            "&:focus": {
              backgroundColor: sphereColors.gray200,
            },
            ...buttonSx,
          }}
        >
          {buttonTitle}
        </Button>
        <Menu
          id="faro-text-menu"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={() => setAnchorEl(null)}
        >
          <Box component="div" onClick={() => setAnchorEl(null)}>
            {children}
          </Box>
        </Menu>
      </>
    </SphereTooltip>
  );
}
