import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { PickersCalendarHeaderProps } from "@mui/x-date-pickers-pro";
import { DateTime } from "luxon";
import { FaroIconButton } from "@components/common/faro-icon-button";
import DateAdapter from "@date-io/luxon";

/** Content to show above the calendar view, i.e. month with next, previous month buttons */
export function FaroDatePickerHeader({
  currentMonth,
  onMonthChange,
}: PickersCalendarHeaderProps<DateTime>): JSX.Element {
  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: "8px 16px",
        alignItems: "center",
      }}
    >
      <Stack spacing={1} direction="row">
        <FaroIconButton
          component={ChevronLeft}
          onClick={() =>
            onMonthChange(
              new DateAdapter().addMonths(currentMonth, -1),
              "right"
            )
          }
        />
      </Stack>
      <Typography variant="body2">
        {currentMonth.toFormat("MMMM yyyy")}
      </Typography>
      <Stack spacing={1} direction="row">
        <FaroIconButton
          component={ChevronRight}
          onClick={() =>
            onMonthChange(new DateAdapter().addMonths(currentMonth, 1), "left")
          }
        />
      </Stack>
    </Box>
  );
}
