import { Box, CircularProgress } from "@mui/material";
import { sphereColors } from "@styles/common-colors";

interface Props {
  /** Whether the circular progress should be hidden. Default false */
  shouldHide?: boolean;

  /** Color for the loading indicator */
  loadingIndicatorColor?: string;

  /** Color for the loading track (the remaining part for the progress) */
  loadingTrackColor?: string;
}

/** Size (height and width) of the circular progress in pixels */
const CIRCULAR_PROGRESS_SIZE = "18px";

/** Thickness for the circular progress, it is unitless and refers to CircularProgress thickness */
const CIRCULAR_PROGRESS_THICKNESS = 5;

/** Component that shows a spinner next to the text in a button */
export function FaroButtonSpinner({
  shouldHide = false,
  loadingIndicatorColor = sphereColors.blue500,
  loadingTrackColor = sphereColors.pureWhite,
}: Props): JSX.Element | null {
  if (shouldHide) {
    return null;
  }

  return (
    <Box
      data-testid="faro-button-spinner"
      component="div"
      position="relative"
      sx={{
        marginLeft: "16px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <CircularProgress
        variant="determinate"
        value={100}
        // Color for the track
        sx={{ color: loadingTrackColor }}
        size={CIRCULAR_PROGRESS_SIZE}
        thickness={CIRCULAR_PROGRESS_THICKNESS}
        role="determinate-progress"
      />
      <CircularProgress
        variant="indeterminate"
        sx={{
          // Color for the loading indicator
          color: loadingIndicatorColor,
          position: "absolute",
          left: 0,
        }}
        size={CIRCULAR_PROGRESS_SIZE}
        thickness={CIRCULAR_PROGRESS_THICKNESS}
        role="indeterminate-progress"
      />
    </Box>
  );
}
