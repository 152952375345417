import {
  InputAdornment,
  // I can not use the FaroTextField because it is using the Sphere Tooltip all the
  // time and in this case is not necessary
  // eslint-disable-next-line no-restricted-imports
  TextField,
} from "@mui/material";
import SearchIcon from "@assets/icons/new/search_24px.svg?react";
import GenericClose from "@assets/icons/generic-close_l.svg?react";
import { ReactSetStateFunction } from "@custom-types/types";
import { sphereColors } from "@styles/common-colors";
import { DEFAULT_INPUT_FONT_SIZE } from "@styles/common-styles";
import { FaroIconButton } from "@components/common/faro-icon-button";

interface Props {
  /** The text in the filter search */
  searchText: string;

  /** Handles the text in the filter search */
  setSearchText: ReactSetStateFunction<string>;

  /** Handler when user presses escape key */
  onEscape?: () => void;
}

// TODO: Refactor to cover MarkupAssigneeEditable search box: https://faro01.atlassian.net/browse/ST-2032
/**
 * A small component that shows a search box
 * This is copied from MarkupAssigneeEditable component with some changes.
 */
export function FilterChipSearch({
  searchText,
  setSearchText,
  onEscape,
}: Props): JSX.Element {
  return (
    <TextField
      sx={{ width: "100%" }}
      variant="standard"
      placeholder="Search"
      autoComplete="off"
      onChange={(event) => setSearchText(event.target.value)}
      autoFocus
      onKeyDown={(event) => {
        if (event.key === "Escape") {
          onEscape?.();
        }
        event.stopPropagation();
      }}
      value={searchText}
      aria-label="Search"
      InputProps={{
        sx: {
          m: "0 8px",
          p: "8px",
          fontSize: DEFAULT_INPUT_FONT_SIZE,
          lineHeight: "20px",
          color: sphereColors.gray800,
          "&:before": {
            borderBottom: `1px solid ${sphereColors.gray200}`,
            height: "36px",
          },
          "&:hover:before": {
            borderBottom: `1px solid ${sphereColors.blue400} !important`,
          },
          "&:after": {
            borderBottom: `1px solid ${sphereColors.blue400}`,
          },
          input: {
            fontSynthesis: "initial",
            "&::placeholder": {
              fontStyle: "italic",
            },
          },
        },
        endAdornment: (
          <InputAdornment position="end">
            {searchText.length > 0 && (
              <FaroIconButton
                component={GenericClose}
                onClick={() => setSearchText("")}
                iconSize={"20px"}
                buttonSize={"26px"}
                iconButtonProps={{
                  sx: {
                    mr: "4px",
                  },
                }}
              />
            )}
            <SearchIcon width={"18px"} height={"18px"} />
          </InputAdornment>
        ),
      }}
    />
  );
}
