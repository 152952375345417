import { isIElementMarkupWithTypeHint } from "@custom-types/i-elements-type-guards";
import {
  IElement,
  IElementType,
  IElementTypeHint,
  isIElementSection,
} from "@faro-lotv/ielement-types";
import { getAncestor } from "@utils/i-elements-utils";
import { ProjectApi } from "@api/project-api/project-api";

interface BaseProjectApiProps {
  /** Project API client */
  projectApiClient: ProjectApi;
}

interface BaseElementsProps extends BaseProjectApiProps {
  /** List of elements */
  elements: IElement[];
}

/**
 * Fetches all the markup annotations with their ancestor elements (all the way up to the root element)
 *
 * @returns fetched elements
 */
export async function fetchMarkupsAndAncestors(
  projectApiClient: ProjectApi
): Promise<IElement[]> {
  const elements = await projectApiClient.getAllIElements({
    // Get only IElement entities of type `Markup` and its 4 children: assignee, status, due date and image
    typeHints: [
      IElementTypeHint.markup,
      IElementTypeHint.markupAssigneeId,
      IElementTypeHint.markupIssueStatus,
      IElementTypeHint.markupIssueDueDate,
      IElementTypeHint.markupIssueImage,
    ],
  });

  return elements;
}

/**
 * Fetches the (markup) integrations and their child Img2d element
 *
 * @returns fetched integrations and child Img2ds
 */
export async function fetchIntegrations({
  projectApiClient,
  elements,
}: BaseElementsProps): Promise<IElement[]> {
  const sectionIds = getMarkupSectionIds(elements);

  const markupProcoreObservationElementsPromise =
    projectApiClient.getAllIElements({
      type: IElementType.markupProcoreObservation,
      ancestorIds: sectionIds,
    });
  const markupProcoreRfiElementsPromise = projectApiClient.getAllIElements({
    type: IElementType.markupProcoreRfi,
    ancestorIds: sectionIds,
  });
  const markupBim360ElementsPromise = projectApiClient.getAllIElements({
    type: IElementType.markupBim360,
    ancestorIds: sectionIds,
  });

  const [
    markupProcoreObservationElements,
    markupProcoreRfiElements,
    markupBim360Elements,
  ] = await Promise.all([
    markupProcoreObservationElementsPromise,
    markupProcoreRfiElementsPromise,
    markupBim360ElementsPromise,
  ]);

  const integrations = [
    ...markupProcoreObservationElements,
    ...markupProcoreRfiElements,
    ...markupBim360Elements,
  ];

  // Fetch the child Img2d element of each integration
  const img2ds = await fetchImg2ds({
    projectApiClient,
    elements: integrations,
  });

  return [...integrations, ...img2ds];
}

/**
 * Fetches the child Img2d element of the passed elements
 *
 * @returns fetched Img2d elements
 */
export async function fetchImg2ds({
  projectApiClient,
  elements,
}: BaseElementsProps): Promise<IElement[]> {
  const elementIds = elements.map((element) => element.id);

  const img2ds = await projectApiClient.getAllIElements({
    type: IElementType.img2d,
    ancestorIds: elementIds,
  });

  return img2ds;
}

/**
 * Fetches the panoramic elements (panos) associated to the markup elements
 *
 * @returns fetched panos
 */
export async function fetchPanos({
  projectApiClient,
  elements,
}: BaseElementsProps): Promise<IElement[]> {
  const sectionIds = getMarkupSectionIds(elements);

  const img360ElementsPromise = projectApiClient.getAllIElements({
    type: IElementType.img360,
    ancestorIds: sectionIds,
  });
  const imgCubeElementsPromise = projectApiClient.getAllIElements({
    type: IElementType.imgCube,
    ancestorIds: sectionIds,
  });

  const [img360Elements, imgCubeElements] = await Promise.all([
    img360ElementsPromise,
    imgCubeElementsPromise,
  ]);

  return [...img360Elements, ...imgCubeElements];
}

/**
 * Gets the IDs of the parent sections of all markups
 *
 * @param elements Array of section IDs
 */
function getMarkupSectionIds(elements: IElement[]): string[] {
  const markups = elements.filter(isIElementMarkupWithTypeHint);

  // Get first section ancestors of the markups
  const sectionIds: string[] = [];
  markups.forEach((markup) => {
    const section = getAncestor({
      elements,
      element: markup,
      predicate: isIElementSection,
    });

    if (section && sectionIds.indexOf(section.id) === -1) {
      sectionIds.push(section.id);
    }
  });

  return sectionIds;
}

/**
 * Fetches all the attachments
 *
 * @returns fetched elements
 */
export async function fetchMarkupAttachments(
  projectApiClient: ProjectApi
): Promise<IElement[]> {
  return await projectApiClient.getAllIElements({
    type: IElementType.attachment,
  });
}

/**
 * Fetches all the attachment groups necessary for:
 * - Determining the group to which an existing attachment belongs.
 * - Identifying the appropriate group when adding a new attachment.
 *
 * @param projectApiClient - An instance of ProjectApi used to fetch elements from the project.
 * @returns A promise that resolves to an array of fetched elements.
 */
export async function fetchMarkupAttachmentGroups(
  projectApiClient: ProjectApi
): Promise<IElement[]> {
  return await projectApiClient.getAllIElements({
    type: IElementType.group,
    typeHints: [IElementTypeHint.attachments],
  });
}

/**
 * Fetches all markup templates:
 * - First it fetches the parent template element
 * - Secondly it fetches the children of the parent template element
 *
 * @param projectApiClient ProjectApi client instance
 * @returns fetched elements
 */
export async function fetchMarkupTemplates(
  projectApiClient: ProjectApi
): Promise<IElement[]> {
  const templates = await projectApiClient.getAllIElements({
    type: IElementType.group,
    typeHints: [IElementTypeHint.markup],
  });

  const templatesElementIds = templates.map((element) => element.id);
  const descendants = await projectApiClient.getAllIElements({
    ancestorIds: templatesElementIds,
  });

  return [...templates, ...descendants];
}

/**
 * Fetches all the markup branches: markup elements together with their ancestors and descendants:
 * - First fetches the markups elements
 * - Secondly fetches the ancestors and descendants of the markups
 *
 * @returns fetched elements
 */
export async function fetchMarkupBranches(
  projectApiClient: ProjectApi
): Promise<IElement[]> {
  const markups = await projectApiClient.getAllIElements({
    type: IElementType.markup,
  });

  const markupIds = markups.map((markup) => markup.id);
  const markupAncestorsPromise = projectApiClient.getAllIElements({
    descendantIds: markupIds,
  });
  const markupDescendantsPromise = projectApiClient.getAllIElements({
    ancestorIds: markupIds,
  });
  const [markupAncestors, markupDescendants] = await Promise.all([
    markupAncestorsPromise,
    markupDescendantsPromise,
  ]);

  const elements = [...markups, ...markupAncestors, ...markupDescendants];
  return elements;
}
