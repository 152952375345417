import { useCoreApiClient } from "@api/use-core-api-client";
import { MembersTable } from "@components/table/members/members-table";
import { MemberHeaders } from "@components/table/members/members-table-utils";
import { Box } from "@mui/material";
import { useAppParams } from "@router/router-helper";
import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import {
  isFetchingSnapshotsMembersSelector,
  snapshotsMembersSelector,
} from "@store/snapshots/snapshots-selector";
import { fetchSnapshotMembers } from "@store/snapshots/snapshots-slice";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { colorConst } from "@styles/common-colors";
import { DEFAULT_INPUT_FONT_SIZE } from "@styles/common-styles";
import { getPrettyName, isMemberActive } from "@utils/user-utils";
import { useEffect, useMemo } from "react";

interface Props {
  /** The snapshot */
  snapshot: CoreAPITypes.IProjectSnapshot;
}

/** Defines the order in which roles should be sorted, always manager at first */
const ROLE_ORDER = [
  CoreAPITypes.EUserProjectRole.owner,
  CoreAPITypes.EUserProjectRole.admin,
  CoreAPITypes.EUserProjectRole.editor,
  CoreAPITypes.EUserProjectRole.viewer,
];

/** Sorts the member first by role, then by status and finally by name or email */
function sortSnapshotMembers(
  a: SphereDashboardAPITypes.IProjectMemberBase,
  b: SphereDashboardAPITypes.IProjectMemberBase
): number {
  // First sort by roles
  const roleComparison =
    ROLE_ORDER.indexOf(a.role) - ROLE_ORDER.indexOf(b.role);
  if (roleComparison !== 0) {
    return roleComparison;
  }

  // If roles are the same, compare isMemberActive
  if (isMemberActive(a) !== isMemberActive(b)) {
    // Show first the active members
    return isMemberActive(a) ? -1 : 1;
  }

  // If isMemberActive is the same, compare names or emails
  if (isMemberActive(a)) {
    // If member is active compare by display name
    return getPrettyName(a).localeCompare(getPrettyName(b));
  } else {
    // If member is pending compare by email
    return a.email.toLowerCase().localeCompare(b.email.toLowerCase());
  }
}

/** Renders a table with all the members of a snapshot */
export function SnapshotMembers({ snapshot }: Props): JSX.Element {
  const { companyId } = useAppParams();
  const coreApiClient = useCoreApiClient();
  const dispatch = useAppDispatch();
  const members = useAppSelector(snapshotsMembersSelector(snapshot.id));
  const isFetchingSnapshotMembers = useAppSelector(
    isFetchingSnapshotsMembersSelector(snapshot.id)
  );

  const isMembersTableEmpty =
    !isFetchingSnapshotMembers && members.length === 0;

  // Fetch snapshot members
  useEffect(() => {
    if (companyId) {
      dispatch(
        fetchSnapshotMembers({
          coreApiClient,
          companyId,
          snapshotId: snapshot.id,
        })
      );
    }
  }, [companyId, coreApiClient, dispatch, snapshot]);

  /** Sorts the member first by role, then by status and finally by name or email */
  const sortedMembers = useMemo(() => {
    return [...members].sort(sortSnapshotMembers);
  }, [members]);

  return (
    <Box component="div">
      {companyId && (
        <MembersTable
          companyId={companyId}
          members={sortedMembers}
          tableSubject="snapshot"
          snapshot={snapshot}
          isLoading={isFetchingSnapshotMembers}
          requiredColumns={[
            MemberHeaders.avatar,
            MemberHeaders.user,
            MemberHeaders.email,
            MemberHeaders.status,
            MemberHeaders.options,
          ]}
          shouldHideCheckbox={true}
        />
      )}

      {isMembersTableEmpty && (
        <Box
          component="div"
          sx={{
            fontSize: DEFAULT_INPUT_FONT_SIZE,
            color: colorConst.normalFont,
            marginTop: "20px",
          }}
        >
          No members to display
        </Box>
      )}
    </Box>
  );
}
