/**
 * @returns The network online status taken from the navigator interface
 * If the navigator interface is not defined then it defaults to "true"
 */
export function isInitialNetworkStatusOnline(): boolean {
  try {
    const isOnline = navigator.onLine;

    return typeof isOnline === "boolean" ? isOnline : true;
  } catch (_) {
    return true;
  }
}
