import {
  BaseCompanyIdProps,
  BaseProjectIdProps,
} from "@custom-types/sdb-company-types";
import { ProjectApi } from "@api/project-api/project-api";
import { Dictionary, EntityId, EntityState } from "@reduxjs/toolkit";
import { ApiClient } from "@stellar/api-logic";
import { ProgressApiClient } from "@api/progress-api/progress-api-client";

/**
 * Base props for most createAsyncThunks that connect to the CoreAPI backend.
 */
export interface BaseCoreApiClientProps {
  /** The core-api client */
  coreApiClient: ApiClient;
}

/**
 * Base props for most createAsyncThunks that connect to the ProjectAPI backend.
 */
export interface BaseProjectApiClientProps {
  /** The ProjectApi client */
  projectApiClient: ProjectApi;
}

/**
 * Base props for most createAsyncThunks that connect to the ProgressAPI backend.
 */
export interface BaseProgressApiClient {
  /** The ProgressApi client */
  progressApiClient: ProgressApiClient;
}

export type CoreApiWithCompanyIdProps = BaseCoreApiClientProps &
  BaseCompanyIdProps;

export type CoreApiWithCompanyIdProjectIdProps = CoreApiWithCompanyIdProps &
  BaseProjectIdProps;

export type CoreApiWithProjectIdProps = BaseCoreApiClientProps &
  BaseProjectIdProps;

/**
 * Defines the base state for all Redux stores that contain one entity adapter,
 * which serves to store a map with the main entity type for that particular store.
 *
 * @see https://redux-toolkit.js.org/api/createEntityAdapter
 */
export interface BaseEntityState<T> extends EntityState<T> {
  /**
   * Contains the ids of all entities.
   * The name of this property cannot be modified due to a naming convention in createEntityAdapter.
   */
  ids: EntityId[];

  /**
   * Contains a dictionary with all entities using the id as key and the entity object as value.
   * The name of this property cannot be modified due to a naming convention in createEntityAdapter.
   */
  entities: Dictionary<T>;
}

/** Status of the action to fetch data from the backend */
export enum FetchingStatus {
  uninitialized = "uninitialized",
  pending = "pending",
  succeeded = "succeeded",
  rejected = "rejected",
}
