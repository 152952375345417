import { Link } from "@mui/material";
import { sphereColors } from "@styles/common-colors";

interface FaroLinkProps {
  /** The title or text content of the link. */
  title: string;

  /** The URL or destination of the link. */
  link: string;
}

/**
 * @returns a link
 */
export function FaroLink({ title, link }: FaroLinkProps): JSX.Element {
  return (
    <Link
      href={link}
      target="_blank"
      color={sphereColors.blue500}
      data-testid="faro-link"
      underline="always"
      sx={{
        fontSize: "14px",
        fontWeight: "600",
        textDecorationColor: sphereColors.blue500,
      }}
    >
      {title}
    </Link>
  );
}
