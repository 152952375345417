import { PageSectionProps } from "@components/page/page-section";
import {
  fetchingSdbCompanyFlagsSelector,
  isSphereXGExclusiveWorkspaceSelector,
} from "@store/sdb-company/sdb-company-selector";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { DefaultPlatformSelector } from "@pages/settings/workspace-settings/default-platform-selector";
import { fetchingUserFlagsSelector } from "@store/user/user-selector";
import { useEffect, useMemo } from "react";
import { Skeleton } from "@mui/material";
import { useCoreApiClient } from "@api/use-core-api-client";
import { fetchCompanyCommunicationSettings } from "@store/sdb-company/sdb-company-slice";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";

/** Custom hook that returns the default platform settings section */
export function useDefaultPlatformSection(): PageSectionProps {
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const { isFetchingCompanyCommunicationSettings } = useAppSelector(
    fetchingSdbCompanyFlagsSelector
  );
  const { isFetchingCurrentUser } = useAppSelector(fetchingUserFlagsSelector);
  const { isFetchingSelectedCompanyContext } = useAppSelector(
    fetchingSdbCompanyFlagsSelector
  );
  const isSphereXGExclusiveWorkspace = useAppSelector(
    isSphereXGExclusiveWorkspaceSelector
  );
  const { canEditDefaultPlatform } = useHasUserValidRoleCompanyLevel();

  // Whether the section should be shown if:
  // the current user has permission to view and edit this setting. Only company EAs have access
  const shouldShowSection = useMemo(() => {
    // Don't show section while the required data to check the user permission is being being fetched,
    // or if the workspace is Sphere XG exclusive
    if (
      isSphereXGExclusiveWorkspace ||
      isFetchingCurrentUser ||
      isFetchingSelectedCompanyContext
    ) {
      return false;
    }

    return canEditDefaultPlatform;
  }, [
    canEditDefaultPlatform,
    isFetchingCurrentUser,
    isFetchingSelectedCompanyContext,
    isSphereXGExclusiveWorkspace,
  ]);

  // Fetch the company communication settings
  // They are first fetch when loading the app, but if the request failed there we can
  // attempt to fetch them here too. If they were already fetched the re-fetching is skipped in the slice.
  useEffect(() => {
    dispatch(
      fetchCompanyCommunicationSettings({
        coreApiClient,
      })
    );
  }, [coreApiClient, dispatch]);

  return {
    title: "Default Application",
    subtitle:
      "Select which application you want to use as the default to open your projects.",
    isHidden: !shouldShowSection,
    isLoading: isFetchingCompanyCommunicationSettings,
    items: [
      {
        shouldContentTakeFullWidth: true,
        content: <DefaultPlatformSelector />,
        customSkeleton: (
          <Skeleton variant="rectangular" height={"100%"} width={"100%"} />
        ),
      },
    ],
  };
}
