import { EmptyPage } from "@components/common/empty-page/empty-page";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { usePointClouds } from "@hooks/project-data/use-point-clouds";
import NoDataIcon from "@assets/icons/new/no_data.svg?react";
import { TABS_CONTENT_PADDING } from "@utils/ui-utils";
import { Grid } from "@mui/material";
import { PointCloudsTable } from "@pages/project-details/project-data/point-clouds/point-clouds-table";
import { useMembersUtils } from "@hooks/use-member-utils";
import { useMemo } from "react";

/** Renders the point clouds section of the project data tab */
export function PointClouds({ projectId }: BaseProjectIdProps): JSX.Element {
  const { pointClouds, isLoading } = usePointClouds({ projectId });
  const {
    companyMembers,
    isFetchingCompanyMembers,
    projectMembers,
    isFetchingProjectMembers,
  } = useMembersUtils();

  /** Flag that is set to true while all the data required to show the component is not yet ready */
  const isLoadingData = useMemo(() => {
    return isLoading || isFetchingProjectMembers || isFetchingCompanyMembers;
  }, [isLoading, isFetchingCompanyMembers, isFetchingProjectMembers]);

  /**
   * The content to be shown. It can be:
   * - A generic empty page if the points clouds have been fetched and the project has no point clouds
   * - The point clouds table if the project has point clouds
   */
  const content =
    pointClouds.length === 0 && !isLoading ? (
      <EmptyPage
        title="No point clouds"
        subtitle="No point clouds to show for this project."
        // TODO: Replace with proper noPointCloudsIcon
        icon={NoDataIcon}
      />
    ) : (
      <PointCloudsTable
        projectId={projectId}
        pointClouds={pointClouds}
        isLoading={isLoadingData}
        companyMembers={companyMembers}
        projectMembers={projectMembers}
      />
    );

  return (
    <Grid
      data-testid="project-data-point-clouds-container"
      container
      sx={{
        mt: TABS_CONTENT_PADDING,
        width: "100%",
      }}
    >
      <Grid item width="100%">
        {content}
      </Grid>
    </Grid>
  );
}
