import { useCoreApiClient } from "@api/use-core-api-client";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  fetchCompanyBrandingSettings,
  updateCompanyBrandingSettings,
} from "@store/sdb-company/sdb-company-slice";
import { companyBrandingSettingsSelector } from "@store/sdb-company/sdb-company-selector";
import { APITypes } from "@stellar/api-logic";
import { WorkspaceEvents } from "@utils/track-event/track-event-list";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useTrackEvent } from "@utils/track-event/use-track-event";

/** Default empty branding settings if not available for users  */
const DefaultBrandingSettings: APITypes.BrandingSettings = {
  logo: {
    url: "",
    target: "",
    // eslint-disable-next-line @typescript-eslint/naming-convention -- defined in the backend
    showHoloBuilderLogo: true,
  },
};

interface UseBrandingSettings {
  /** Branding settings of the company */
  brandingSettings: APITypes.BrandingSettings;

  /** Flag to check if the company fetched correctly branding settings */
  hasBrandingSettings: boolean;

  /** Fetches the branding settings of the company */
  fetchBrandingSettings(): Promise<void>;

  /** Triggers when branding settings needs to be updated */
  onChangeBrandingSettings(
    value: string | boolean,
    attribute: "url" | "target" | "showHoloBuilderLogo"
  ): Promise<string>;
}

/** Returns branding settings of the company */
export function useBrandingSettings(): UseBrandingSettings {
  const coreApiClient = useCoreApiClient();
  const dispatch = useAppDispatch();
  const { handleErrorWithToast } = useErrorContext();
  const { trackEvent } = useTrackEvent();

  const brandingSettings = useAppSelector(companyBrandingSettingsSelector);
  const hasBrandingSettings = Boolean(brandingSettings);

  const companyBrandingSettings = brandingSettings?.logo
    ? brandingSettings
    : DefaultBrandingSettings;

  /** Dispatches the action to fetch the company settings from the backend */
  async function fetchBrandingSettings(): Promise<void> {
    await dispatch(fetchCompanyBrandingSettings({ coreApiClient }));
  }

  /**
   * Trigger updating the branding settings when the value has been changed
   *
   * @param value New value which should set
   * @param attribute Individual filed name of brand setting. E.g: target- Workspace URL, url - Logo URL
   * @returns The new value
   */
  async function onChangeBrandingSettings(
    value: string,
    attribute: "url" | "target" | "showHoloBuilderLogo"
  ): Promise<string> {
    if (!brandingSettings) {
      return value;
    }

    try {
      trackEvent({
        name: WorkspaceEvents.updateSettings,
        props: { attribute },
      });

      await dispatch(
        updateCompanyBrandingSettings({
          coreApiClient,
          payload: {
            ...companyBrandingSettings,
            logo: { ...companyBrandingSettings.logo, [attribute]: value },
          },
        })
      );
    } catch (error) {
      handleErrorWithToast({
        id: `changeCompanyBrandingSettings-${Date.now().toString()}`,
        title: "Sorry! Unable to change the value",
        error,
      });

      return value;
    }
    return value;
  }

  return {
    fetchBrandingSettings,
    brandingSettings: companyBrandingSettings,
    hasBrandingSettings,
    onChangeBrandingSettings,
  };
}
