import { Typography } from "@mui/material";
import { ErrorWithTitle } from "@context-providers/error-boundary/error-types";
import {
  FaroDialog,
  SPACE_ELEMENTS_OF_MODAL,
} from "@components/common/dialog/faro-dialog";
import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { SanitizedHtmlTag } from "@components/common/sanitized-html-tag";
import { useAppDispatch } from "@store/store-helper";
import { removeOne } from "@store/errors/errors-slice";

interface Props {
  /** The error to be show in dialog */
  error: ErrorWithTitle;

  /** Handler to close the dialog */
  onClose(): void;
}

/**
 * Displays an error message in a Dialog with a close button.
 */
export function ErrorDialog({ error, onClose }: Props): JSX.Element {
  const dispatch = useAppDispatch();

  function onCloseHandler(): void {
    dispatch(removeOne(error.id));
    onClose();
  }

  return (
    <FaroDialog
      key={error.id}
      open={true}
      onClose={onCloseHandler}
      title="An unexpected error occurred"
      iconInTitle={
        <WarningAmberIcon
          sx={{
            marginRight: "10px",
          }}
        />
      }
      closeText="OK"
    >
      <>
        <Typography variant="h6" mt={SPACE_ELEMENTS_OF_MODAL}>
          {error.title}
        </Typography>

        <Typography component="div" gutterBottom>
          <b>Developer Error Message:</b>
          <SanitizedHtmlTag markup={getErrorDisplayMarkup(error.error)} />
        </Typography>
      </>
    </FaroDialog>
  );
}
