import { AnalyticsChartData } from "@custom-types/analytics-types";
import { EDecimalToHex, colorConst, sphereColors } from "@styles/common-colors";
import { ChartData, ChartOptions } from "chart.js";
import { DEFAULT_FONT_FAMILY } from "@styles/common-styles";
import { addTransparency } from "@utils/ui-utils";
import {
  getChartLabel,
  getChartLegendExisting,
  getChartLegendNew,
} from "@utils/analytics-utils";

interface GetChartJSOptionsProps {
  /** Chart data */
  data: AnalyticsChartData;

  /** Chart aspect ratio value */
  aspectRatio: number;

  /** Whether the chart title should be shown. Set to true by default. */
  shouldShowTitle?: boolean;
}

/** Returns the Chart JS options */
export function getChartJsOptions({
  data,
  aspectRatio,
  shouldShowTitle = true,
}: GetChartJSOptionsProps): ChartOptions<"bar"> {
  /* eslint-disable @typescript-eslint/naming-convention -- naming given by external package */

  return {
    responsive: true,
    aspectRatio: aspectRatio,
    maintainAspectRatio: true,
    interaction: {
      // Required in order to get the `total count` value in the tooltip
      intersect: false,
      mode: "index",
    },
    plugins: {
      // Title
      title: {
        display: shouldShowTitle,
        text: data.title,
        align: "start",
        font: {
          family: DEFAULT_FONT_FAMILY,
          size: 16,
          weight: 600,
        },
        color: colorConst.normalFont,
      },
      // Datasets legends (new ..., existing...)
      legend: {
        labels: {
          font: {
            family: DEFAULT_FONT_FAMILY,
            size: 12,
          },
          color: sphereColors.gray500,
          usePointStyle: true,
          boxWidth: 8,
          boxHeight: 8,
        },
        align: "end",
        // Reversing the legends order will place the "new" legend first
        reverse: true,
      },
      // Tooltip
      tooltip: {
        // Container
        backgroundColor: sphereColors.gray950,
        padding: 12,
        cornerRadius: 4,
        // Total count
        titleColor: sphereColors.gray100,
        titleFont: {
          family: DEFAULT_FONT_FAMILY,
          size: 12,
        },
        titleMarginBottom: 8,
        // Datasets
        bodyColor: sphereColors.gray100,
        bodyFont: {
          family: DEFAULT_FONT_FAMILY,
          size: 12,
        },
        bodySpacing: 6,
        // Label (date)
        footerColor: sphereColors.gray100,
        footerFont: {
          family: DEFAULT_FONT_FAMILY,
          size: 12,
        },
        footerMarginTop: 20,
        // Datasets icon
        usePointStyle: true,
        boxWidth: 10,
        boxHeight: 10,
        boxPadding: 12,
        callbacks: {
          // Show the total count in the title section
          title: (tooltipItems) => {
            let sum = 0;
            tooltipItems.forEach((item) => {
              sum += item.parsed.y;
            });
            return `Total: ${sum.toString()}`;
          },
          // Show the label (date) in the footer section
          footer: (tooltipItems) => {
            let label = "";
            tooltipItems.forEach((item) => {
              // Format label from "Mmm'yy" to "Mmm yyyy"
              const monthAndYear = item.label.split("'");
              const month = monthAndYear[0];
              const year = monthAndYear[1];
              const formattedYear = `20${year}`;
              label = `${month} ${formattedYear}`;
            });
            return label;
          },
        },
      },
    },
    // Axes
    scales: {
      x: {
        stacked: true,
        grid: {
          color: sphereColors.gray300,
          // Setting grid line width to zero will hide the grid lines but still show the ticks
          lineWidth: 0,
          tickWidth: 1,
          tickLength: 5,
        },
        border: {
          color: sphereColors.gray800,
          width: 2,
        },
        ticks: {
          major: {
            enabled: true,
          },
          color(ctx) {
            let tickColor = sphereColors.gray600;

            // Get hovered bar (active elements)
            const elements = ctx.chart.getActiveElements();
            // Get tick index
            const tickIndex = ctx.index;
            // If active bar index matches the tick index change font color
            if (elements.length && tickIndex === elements[0].index) {
              tickColor = sphereColors.blue500;
            }

            return tickColor;
          },
          font: {
            family: DEFAULT_FONT_FAMILY,
            size: 10,
          },
          // Takes care of formatting data labels to be visible in 2 rows
          callback: (tickValue, index, ticks) => {
            return getChartLabel({
              labels: data.labels,
              index,
            });
          },
        },
      },
      y: {
        stacked: true,
        grid: {
          color: sphereColors.gray300,
          tickLength: 13,
        },
        border: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 4,
          color: sphereColors.gray600,
          font: {
            family: DEFAULT_FONT_FAMILY,
            size: 10,
          },
          format: {
            // Automatically reduce large values (millions, thousands) to a compact notation (M, m)
            notation: "compact",
            // Add commas to large numbers
            useGrouping: true,
          },
          // Setting precision to zero will only generate tick steps with integers
          precision: 0,
        },
      },
    },
    // Bars
    datasets: {
      bar: {
        // Set 1px gap between bars
        barPercentage: 0.99,
        categoryPercentage: 1,
      },
    },
    onHover: (event, elements, chart) => {
      // Update chart when hovering over bars so the color of the label text changes
      if (elements.length) {
        chart.update();
      }
    },
  };

  /* eslint-enable @typescript-eslint/naming-convention */
}

interface GetChartJsDataProps {
  /** Data to display as chart */
  data: AnalyticsChartData;
}

/** Returns the Chart JS data */
export function getChartJsData({
  data,
}: GetChartJsDataProps): ChartData<"bar"> {
  return {
    labels: data.labels,
    datasets: [
      {
        label: getChartLegendExisting(data.name),
        data: data.datasets.existing,
        backgroundColor: addTransparency({
          color: sphereColors.blue400,
          alpha: EDecimalToHex.twoHundredFour,
        }),
        hoverBackgroundColor: addTransparency({
          color: sphereColors.blue400,
          alpha: EDecimalToHex.hundredFiftyThree,
        }),
      },
      {
        label: getChartLegendNew(data.name),
        data: data.datasets.new,
        backgroundColor: addTransparency({
          color: sphereColors.blue400,
          alpha: EDecimalToHex.hundredTwo,
        }),
        hoverBackgroundColor: addTransparency({
          color: sphereColors.blue400,
          alpha: EDecimalToHex.seventySeven,
        }),
      },
    ],
  };
}
