import { Box } from "@mui/material";
import { CSSProperties, FunctionComponent } from "react";

interface AddOnsExtensionIconProps {
  /** Icon from extensions */
  icon: FunctionComponent;

  /** Add style */
  sx?: CSSProperties;
}

export function AddOnsExtensionIcon({
  icon,
  sx,
}: AddOnsExtensionIconProps): JSX.Element {
  const IconComponent:
    | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    | undefined = icon;

  return (
    <Box data-testid="add-ons-extensions-icon" sx={sx}>
      <IconComponent style={sx} />
    </Box>
  );
}
