import { TabsWithRoutes } from "@components/common/tabs-with-routes";
import { AnalyticsTabs } from "@router/route-params";
import { useAppParams } from "@router/router-helper";
import { WorkspaceAnalytics } from "@pages/analytics/workspace-analytics";
import { NotFoundPage } from "@pages/not-found-page";
import { RequiredRoleCompanyLevelName } from "@utils/access-control/company/company-access-control-types";
import { ProjectsAnalytics } from "@pages/analytics/projects-analytics";
import { MembersAnalytics } from "@pages/analytics/members-analytics";
import { GroupsAnalytics } from "@pages/analytics/groups-analytics";
import { useAnalytics } from "@hooks/analytics/use-analytics";
import { OpenTabEvents } from "@utils/track-event/track-event-list";
import { runtimeConfig } from "@src/runtime-config";

/** Whether premium analytics are not available for the current environment */
const isPremiumAnalyticsNotAvailable =
  runtimeConfig.appEnv === "eu-dev" ||
  runtimeConfig.appEnv === "com-staging" ||
  runtimeConfig.appEnv === "eu-staging";

/** Renders the contents in analytics page */
export function AnalyticsPage(): JSX.Element {
  const { analyticsTabs } = useAppParams();
  useAnalytics();

  if (
    analyticsTabs === undefined ||
    !Object.values(AnalyticsTabs).includes(analyticsTabs)
  ) {
    return <NotFoundPage />;
  }

  return (
    <TabsWithRoutes
      selectedTab={analyticsTabs}
      dataTestId="ANALYTICS_CONTAINER"
      requiredAttribute={false}
      trackingEventPageName={OpenTabEvents.openAnalyticsTab}
      tabs={[
        {
          label: "Workspace",
          route: AnalyticsTabs.workspace,
          content: () => <WorkspaceAnalytics />,
          loadingContent: <WorkspaceAnalytics />,
          requiredRoleCompanyLevel:
            RequiredRoleCompanyLevelName.canViewAnalytics,
        },
        {
          label: "Projects",
          route: AnalyticsTabs.projects,
          content: () => <ProjectsAnalytics />,
          loadingContent: <ProjectsAnalytics />,
          requiredRoleCompanyLevel:
            RequiredRoleCompanyLevelName.canViewPremiumAnalytics,
          isHidden: isPremiumAnalyticsNotAvailable,
        },
        {
          label: "Members",
          route: AnalyticsTabs.members,
          content: () => <MembersAnalytics />,
          loadingContent: <MembersAnalytics />,
          requiredRoleCompanyLevel:
            RequiredRoleCompanyLevelName.canViewPremiumAnalytics,
          isHidden: isPremiumAnalyticsNotAvailable,
        },
        {
          label: "Groups",
          route: AnalyticsTabs.groups,
          content: () => <GroupsAnalytics />,
          loadingContent: <GroupsAnalytics />,
          requiredRoleCompanyLevel:
            RequiredRoleCompanyLevelName.canViewPremiumAnalytics,
          isHidden: isPremiumAnalyticsNotAvailable,
        },
      ]}
    />
  );
}
