import { Checkbox, FormControlLabel } from "@mui/material";
import { sphereColors } from "@styles/common-colors";

interface Props {
  /** True if the checkbox should be marked as checked */
  isChecked: boolean;

  /** Callback when the checkbox will be clicked */
  onChange(): void;

  /** Text that displayed with the checkbox */
  label?: string;
}

/** Renders checkbox with label */
export function FaroCheckbox({
  isChecked,
  label,
  onChange,
}: Props): JSX.Element {
  return (
    <FormControlLabel
      control={
        <Checkbox
          size="small"
          disableRipple
          disableFocusRipple
          disableTouchRipple
          checked={isChecked}
          onChange={onChange}
        />
      }
      sx={{
        "& .MuiFormControlLabel-label": {
          color: sphereColors.gray800,
        },
      }}
      label={label}
    />
  );
}
