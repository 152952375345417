import {
  BaseProjectIdProps,
  BaseUserIdProps,
} from "@custom-types/sdb-company-types";
import {
  CoreApiTokenManager,
  RegistrationApiClient,
} from "@faro-lotv/service-wires";
import { runtimeConfig } from "@src/runtime-config";

/** Dashboard identifier to pass the registration api */
export const CLIENT_ID = `sphere-dashboard/${runtimeConfig.appVersion}`;

/**
 * Returns an instance of the registration api client.
 *
 * @returns RegistrationApiClient instance
 */
export function getRegistrationApiClient({
  projectId,
  userId,
}: BaseProjectIdProps & BaseUserIdProps): RegistrationApiClient {
  const coreApiURL = new URL(runtimeConfig.urls.apiBaseUrl);
  const tokenManager = new CoreApiTokenManager(
    coreApiURL,
    projectId.toString(),
    CLIENT_ID
  );
  // Use "bind()" method to create an instance getToken that, when called, uses the passed token manager arguments:
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_objects/Function/bind
  const tokenProvider = tokenManager.getToken.bind(tokenManager);

  return new RegistrationApiClient({
    projectId: projectId.toString(),
    userId,
    tokenProvider,
    baseUrl: runtimeConfig.urls.registrationApiUrl,
    clientId: CLIENT_ID,
  });
}
