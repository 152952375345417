import {
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
  KeyboardEvent,
} from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import {
  getPrettyName,
  getUserInitials,
  isMemberActive,
} from "@utils/user-utils";
import {
  UserItem,
  UserListItem,
} from "@components/common/members-autocomplete/user-list-item";
import SelectorArrow from "@assets/icons/selector_arrow.svg?react";
import {
  MemberAutocompleteSelection,
  SelectedMember,
} from "@components/common/member-autocomplete/member-autocomplete-selection";
import { isValidEmail } from "@utils/member-utils";
import { Box } from "@mui/material";
import { DEFAULT_FONT_FAMILY_ITALIC } from "@styles/common-styles";
import { sphereColors } from "@styles/common-colors";

/** Re-export type so that we can import SelectedMember from this component.  */
export type { SelectedMember } from "@components/common/member-autocomplete/member-autocomplete-selection";

/**
 * Defines how are the provided options internally mapped to something that can
 * be understood by MUI Autocomplete.
 */
interface InternalOption {
  /** This property is required by MUI Autocomplete. */
  label: string;

  /** Keeps the actual value of the item, in this case the selected member's identity. */
  value: string;

  /** If the selected member is an object, we keep the original member object. */
  originalItem: SphereDashboardAPITypes.IGroupMemberDetails | null;
}

interface Props {
  /** Array filled with the existing members that can be selected.  */
  members: SphereDashboardAPITypes.IGroupMemberDetails[];

  /** Sets the selected member. This component can handle only one member. */
  selectedMember: SelectedMember;

  /**
   * Defines the placeholder to be shown in the component when no members have
   * been selected and the input field is empty.
   */
  placeholder: string;

  /** Optional property whether the member selection should be disabled. */
  isDisabled?: boolean;

  /** Triggered every time the user selects an existing member or types a new valid email. */
  onOptionChange: (newSelectedOption: SelectedMember) => void;
}

/**
 * Defines the maximum items to be displayed in the autocomplete menu as suggestions.
 * The number should be low enough to fit them all at once in the screen.
 */
const MAX_ITEMS_TO_DISPLAY = 5;

/**
 * Maps a member from the store to an object that can be understood by MUI Autocomplete.
 */
function mapMemberToOption(
  user: SphereDashboardAPITypes.IGroupMemberDetails
): InternalOption {
  return {
    label: getPrettyName(user),
    value: user.identity,
    originalItem: user,
  };
}

/**
 * Maps an option that is understood by MUI autocomplete to an object tha
 * it is understood by the UserListItem component to display a single user.
 */
function mapOptionToUserItem(option: InternalOption): UserItem {
  if (!option.originalItem || !isMemberActive(option.originalItem)) {
    return {
      title: option.value,
      userInitials: getUserInitials({
        name: option.value,
      }),
    };
  }
  return {
    title: getPrettyName(option.originalItem),
    subtitle: option.originalItem.email,
    userInitials: getUserInitials(option.originalItem),
    userAvatarImg: option.originalItem.thumbnailUrl,
  };
}

/**
 * This component shows an autocomplete selection that let's the user to
 * select one member from a list, and optionally type a valid email.
 * The component is generic enough that can be used for any purpose regarding
 * selecting a single member. For selecting multiple members see MembersAutocomplete.
 */
export function MemberAutocomplete({
  members,
  selectedMember,
  placeholder,
  isDisabled = false,
  onOptionChange,
}: Props): JSX.Element {
  /** Stores the current text inside the text input. Does not necessarily match a valid email or valid user. */
  const [inputValue, setInputValue] = useState<string>("");

  /** Flag whether the autocomplete component is focused because the user clicked on it. */
  const [isAutocompleteFocused, setIsAutocompleteFocused] =
    useState<boolean>(false);

  /** Keeps the current members that can be selected. */
  const [currentOptions, setCurrentOptions] = useState<InternalOption[]>(
    members.map(mapMemberToOption)
  );

  /** Ref to store a reference to the Autocomplete input element */
  const inputRef = useRef<HTMLInputElement | null>(null);

  /**
   * The autocomplete alternates between showing a text field where the user can type
   * some text to search for users or add a new email, and a selection component that
   * shows in a nicer UI the selected user.
   * This flag returns whether the text field should be used.
   */
  const shouldUseTextField: boolean = useMemo(() => {
    return !selectedMember && isAutocompleteFocused && !isDisabled;
  }, [selectedMember, isAutocompleteFocused, isDisabled]);

  /** Checks whether the user typed a valid email and that email is not included in the member's list */
  const isValidNewEmail = useMemo(() => {
    if (!isValidEmail(inputValue)) {
      return false;
    }
    const inputLower = inputValue.toLowerCase();
    return !members?.some((user) => user.email.toLowerCase() === inputLower);
  }, [inputValue, members]);

  /**
   * Reacts to changes in the members to be displayed in the selection,
   * and maps them to the array that can be understood by MUI Autocomplete.
   */
  useEffect(() => {
    setCurrentOptions(members.map(mapMemberToOption));
  }, [members]);

  /**
   * Reacts to changes whenever isAutocompleteFocused changes and
   * forces a focus in text field whenever isAutocompleteFocused is true.
   */
  useEffect(() => {
    if (isAutocompleteFocused && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, isAutocompleteFocused]);

  /**
   * Adds an user email to the options array if the user typed a valid email.
   *
   * @param inputText The email to be validated and conditionally added.
   */
  function addUserIfValidEmail(inputText: string): void {
    if (!isValidEmail(inputText)) {
      return;
    }
    const newOption: InternalOption = {
      label: inputText,
      value: inputText,
      originalItem: null,
    };
    const newOptions: InternalOption[] = [...currentOptions, newOption];
    setCurrentOptions(newOptions);
    // Notify the parent of the new selected option
    onOptionChange(newOption.value);
  }

  /**
   * Finds whether the member's list include a member's email.
   *
   * @param inputValue Text to search for, it will be transformed to lower case for the search.
   * @returns Either a member from the list or undefined.
   */
  function findUser(inputValue: string): InternalOption | undefined {
    const inputValueLower = inputValue.toLowerCase();
    // Search if the email already exists in the options list
    return currentOptions.find(
      (option) => option.originalItem?.email.toLowerCase() === inputValueLower
    );
  }

  /**
   * Triggered when the user types a single key.
   * We use it to either add a new options if that key was enter.
   */
  function handleKeyPress(event: KeyboardEvent<HTMLDivElement>): void {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      const inputValueTrimmed = inputValue.trim().toLowerCase();

      const existingOption = findUser(inputValueTrimmed);
      // If the user email exists in the list, select that user.
      if (existingOption) {
        // Notify the parent of the existing selected option
        onOptionChange(existingOption.originalItem);
        setInputValue("");
      } else {
        // Otherwise, if it does not exists in the list, select the email and add it to the options array.
        addUserIfValidEmail(inputValueTrimmed);
      }
    }
  }

  /**
   * Removes the provided option from the options list.
   */
  function removeEmailsFromOptions(): void {
    setCurrentOptions(currentOptions.filter((option) => option.originalItem));
  }

  /**
   * Triggered when the component looses its focus.
   * It is used to use the email that the user typed if it is valid.
   */
  function onBlur(): void {
    setIsAutocompleteFocused(false);
    if (inputValue) {
      const inputValueTrimmed = inputValue.trim().toLowerCase();
      const existingOption = findUser(inputValueTrimmed);
      if (!existingOption) {
        addUserIfValidEmail(inputValueTrimmed);
      }
    }
  }

  /**
   * Triggered every time a new item has been selected.
   */
  function onChange(
    event: SyntheticEvent,
    newValue: InternalOption | null
  ): void {
    inputRef?.current?.blur();
    // Notify the parent of the selected option
    if (newValue && typeof newValue === "object") {
      onOptionChange(newValue.originalItem);
    } else {
      onOptionChange(newValue ?? null);
    }
  }

  /**
   * Adds a focus to the autocomplete text field.
   */
  function focusAutocomplete(): void {
    inputRef?.current?.focus();
    setIsAutocompleteFocused(true);
  }

  return (
    <>
      <Autocomplete
        options={currentOptions}
        openOnFocus={true}
        filterOptions={(options, state) => {
          const lowerInputValue = state.inputValue.toLowerCase();
          const filteredOptions = options.filter(
            (option) =>
              option.label.toLowerCase().includes(lowerInputValue) ||
              option.originalItem?.email
                .toLowerCase()
                ?.includes(lowerInputValue)
          );
          // Limit the number of displayed options (e.g., to the first 5 matches)
          return filteredOptions.slice(0, MAX_ITEMS_TO_DISPLAY);
        }}
        noOptionsText={
          <Box
            component="div"
            sx={{
              fontSize: "12px",
              color: sphereColors.gray800,
              opacity: 1,
              textDecoration: "none",
            }}
          >
            {isValidNewEmail ? (
              <Box
                component="div"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  // !: Do not use ...withEllipsis
                  // because we don't need whiteSpace: "nowrap" in order to
                  // break the line in two rows.
                  wordBreak: "break-word",
                  lineHeight: "24px",
                  // These properties allow to show an ellipsis on two lines
                  // Unfortunately it is only allowed in webkit because there's
                  // no native support from CSS for this, only for one line.
                  display: "-webkit-box",
                  WebkitLineClamp: "3",
                  WebkitBoxOrient: "vertical",
                }}
              >
                Type enter to invite{" "}
                <Box
                  component="span"
                  sx={{ fontFamily: DEFAULT_FONT_FAMILY_ITALIC }}
                >
                  <var>{inputValue}</var>
                </Box>{" "}
                to the workspace.
              </Box>
            ) : (
              <>
                No members found. Please type a valid email to invite a new
                member to the workspace
              </>
            )}
          </Box>
        }
        componentsProps={{
          popper: {
            // Makes sure the menu items are shown at the bottom of the text field
            placement: "bottom",
          },
        }}
        getOptionLabel={(option) => option.label}
        sx={{
          "& .MuiFormControl-root": {
            paddingTop: "0px",
          },
        }}
        renderInput={(params) => (
          <>
            {/* When an item is selected, do not show a text field, but rather a component
            that is optimized to better show the selection */}
            <MemberAutocompleteSelection
              isHidden={shouldUseTextField}
              selectedMember={selectedMember}
              isDisabled={isDisabled}
              placeholder={placeholder}
              onClick={() => {
                onOptionChange(null);
                removeEmailsFromOptions();
                focusAutocomplete();
              }}
            />

            {/* If nothing was selected and it is not disabled show a text field so that the user
            can type to search for members */}
            <TextField
              // This is just a boilerplate property
              {...params}
              inputRef={inputRef}
              placeholder={placeholder}
              InputProps={{
                // This is just a boilerplate property
                ...params.InputProps,
                endAdornment: <SelectorArrow />,
              }}
              sx={{
                display: shouldUseTextField ? "initial" : "none",
                height: "60px",
                paddingY: "10px",
                "&.MuiFormControl-root .MuiInputBase-root.MuiOutlinedInput-root":
                  {
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  },
                "& .MuiInputBase-input": {
                  height: "27px",
                  fontSize: "14px",
                },
              }}
            />
          </>
        )}
        inputValue={inputValue}
        onInputChange={(e, value) => setInputValue(value)}
        onKeyDown={handleKeyPress}
        value={null}
        onBlur={onBlur}
        onChange={onChange}
        renderOption={(props, option, state) => {
          return (
            <li {...props} key={`listItem-${state.index}`}>
              <UserListItem
                item={mapOptionToUserItem(option)}
                inputValue={state.inputValue}
              />
            </li>
          );
        }}
      />

      {/* If the selected member is a string it means that a new user will be invited by email */}
      {typeof selectedMember === "string" && (
        <Box
          component="div"
          sx={{
            fontSize: "12px",
            color: sphereColors.gray750,
            marginLeft: "4px",
            marginTop: "2px",
          }}
        >
          Inviting members by email will also invite them to the workspace
        </Box>
      )}
    </>
  );
}
