import { useCoreApiClient } from "@api/use-core-api-client";
import { FaroTextField } from "@components/common/faro-text-field/faro-text-field";
import { LabelWithHelp } from "@components/common/label-with-help";
import { ProjectHeaders } from "@components/table/projects/projects-table-utils";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useToast } from "@hooks/use-toast";
// eslint-disable-next-line no-restricted-imports -- custom dropdown needed
import { Box, FormControl, MenuItem, Select, SxProps } from "@mui/material";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { updateProjectDetails } from "@store/projects/projects-slice-thunk";
import { useAppDispatch } from "@store/store-helper";
import { EDecimalToHex, colorConst, sphereColors } from "@styles/common-colors";
import { DEFAULT_INPUT_FONT_SIZE } from "@styles/common-styles";
import { ChangeProjectDetailsEvents } from "@utils/track-event/track-event-list";
import DownArrow from "@assets/icons/new/arrow-down_21px.svg?react";
import GenericCheck from "@assets/icons/generic-check_l.svg?react";
import { addTransparency } from "@utils/ui-utils";
import { CSSProperties } from "react";
import { useMediaQueryList } from "@hooks/use-media-query";
import {
  AccessLevelDisplayNames,
  BaseProjectProps,
} from "@custom-types/project-types";
import { useTrackEvent } from "@utils/track-event/use-track-event";

/** Access level descriptions */
enum AccessLevelLDescription {
  private = "The project can only be seen by members who are logged in and invited.",
  unlisted = "The project can be seen by everyone with the link.",
}

/** Interface for a project access level item */
interface ProjectAccessLevelItem {
  /** Display label */
  label: string;

  /** Access level description */
  description: string;

  /** Access level value */
  value: SphereDashboardAPITypes.EAccessLevel;
}

/** Project access levels array */
const PROJECT_ACCESS_LEVELS: ProjectAccessLevelItem[] = [
  {
    label: AccessLevelDisplayNames.private,
    description: AccessLevelLDescription.private,
    value: SphereDashboardAPITypes.EAccessLevel.private,
  },
  {
    label: AccessLevelDisplayNames.unlisted,
    description: AccessLevelLDescription.unlisted,
    value: SphereDashboardAPITypes.EAccessLevel.unlisted,
  },
];

/** Access level tooltip component */
const PROJECT_ACCESS_LEVEL_TOOLTIP = (
  <Box component="div">
    <Box component="div" mb="20px">
      There are two types of access levels:
    </Box>
    <Box component="div" mb="20px">
      {`${AccessLevelDisplayNames.private} - ${AccessLevelLDescription.private}`}
    </Box>
    <Box component="div">
      {`${AccessLevelDisplayNames.unlisted} - ${AccessLevelLDescription.unlisted}`}
    </Box>
  </Box>
);

/** Returns the label of the current value to render in the select component */
function getRenderValue(value: SphereDashboardAPITypes.EAccessLevel): string {
  return AccessLevelDisplayNames[value];
}

/** Component width */
const ACCESS_LEVEL_COMPONENT_WIDTH: CSSProperties["width"] = "225px";

interface Props extends BaseProjectProps {
  /** Whether the project access level is editable or not */
  isAccessLevelEditable: boolean;

  /** Optional style props to pass to the parent component */
  sx?: SxProps;
}

/**
 * Project access level selection component
 * If the user has permission to change the access level it renders a custom dropdown,
 * otherwise it shows the access level as readonly text.
 */
export function ProjectAccessLevel({
  isAccessLevelEditable,
  project,
  sx,
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const { handleErrorWithToast } = useErrorContext();
  const { showToast } = useToast();
  const { isExtraSmall } = useMediaQueryList();
  const { trackEvent } = useTrackEvent();

  async function onAccessLevelChange(
    value: SphereDashboardAPITypes.EAccessLevel
  ): Promise<void> {
    trackEvent({
      name: ChangeProjectDetailsEvents.changeProjectAccessLevel,
      props: { accessLevel: value },
    });

    try {
      await dispatch(
        updateProjectDetails({
          coreApiClient,
          payload: { accessLevel: value },
        })
      );

      showToast({
        message: "Project access level changed.",
        type: "success",
      });
    } catch (error) {
      // Handle error directly here in order to show specific info about the field that failed: access level
      // Otherwise the error will be handled by the error slice, but that will show a generic message without details
      handleErrorWithToast({
        id: `updateProjectDetails-accessLevel-${Date.now().toString()}`,
        title: "Could not change project access level",
        error,
      });
    }
  }

  return (
    <FormControl
      sx={{
        width: "100%",
        ...sx,
      }}
    >
      <LabelWithHelp
        title={ProjectHeaders.accessLevel}
        help={PROJECT_ACCESS_LEVEL_TOOLTIP}
        sx={{
          color: sphereColors.gray500,
          fontWeight: "normal",
          width: "fit-content",
          fontSize: "11px",
        }}
      />
      {isAccessLevelEditable ? (
        <Select
          value={project.accessLevel}
          renderValue={getRenderValue}
          onChange={(event) =>
            onAccessLevelChange(
              event.target.value as SphereDashboardAPITypes.EAccessLevel
            )
          }
          variant="standard"
          IconComponent={DownArrow}
          MenuProps={{
            PaperProps: {
              sx: {
                padding: "0px 8px",
                width: ACCESS_LEVEL_COMPONENT_WIDTH,
                "& .MuiList-root": {
                  // Fix bottom padding, since each menu item has a bottom margin of 2px
                  paddingBottom: "6px",
                },
              },
            },
          }}
          sx={{
            color: sphereColors.gray800,
            fontSize: DEFAULT_INPUT_FONT_SIZE,
            // For extra-small screens show the component in full width
            width: isExtraSmall ? "100%" : ACCESS_LEVEL_COMPONENT_WIDTH,
            textOverflow: "ellipsis",

            // Input style
            "&.MuiInput-underline": {
              // Adjust icon style
              "& .MuiSelect-icon": {
                top: "5px",
                width: "16px",
              },

              // Not focused
              "&:not(.Mui-focused)": {
                // Hide the underline and icon by default
                "&:before": {
                  border: "none",
                },
                "& .MuiSelect-icon": {
                  display: "none",
                },

                // Hover style. Show underline and icon
                "&:hover": {
                  boxShadow: `0px 1px 0px ${sphereColors.gray400}`,

                  "& .MuiSelect-icon": {
                    display: "block",
                  },
                },

                // Remove underline that has a transition animation when select loses focus
                "&.MuiInput-underline:after": {
                  borderBottom: "none",
                },
              },

              // Focused
              "&.Mui-focused": {
                "&.MuiInput-underline:after": {
                  borderBottom: "2px solid",
                  borderBottomColor: sphereColors.blue500,
                },

                // Remove gray background color after closing dropdown but input keeps focus
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input:focus": {
                  backgroundColor: "inherit",
                },
              },
            },
          }}
        >
          {PROJECT_ACCESS_LEVELS.map((accessLevel, index) => (
            <MenuItem
              key={index}
              value={accessLevel.value}
              sx={{
                display: "block",
                padding: "12px",
                mb: "2px",
                whiteSpace: "normal",
                "&:hover": {
                  backgroundColor: addTransparency({
                    color: sphereColors.gray500,
                    alpha: EDecimalToHex.twentySix,
                  }),

                  // Set menu item label color to blue500 on hover
                  "& .projectAccessLevelItem-label": {
                    color: sphereColors.blue500,
                  },
                },
                "&.Mui-selected": {
                  backgroundColor: addTransparency({
                    color: sphereColors.gray500,
                    alpha: EDecimalToHex.thirtyEight,
                  }),

                  "&:hover": {
                    backgroundColor: addTransparency({
                      color: sphereColors.gray500,
                      alpha: EDecimalToHex.thirtyEight,
                    }),
                  },

                  // Overrides default style for ".Mui-focusVisible" class
                  "&.Mui-focusVisible": {
                    backgroundColor: addTransparency({
                      color: sphereColors.gray500,
                      alpha: EDecimalToHex.thirtyEight,
                    }),
                  },
                },
              }}
            >
              {/* Title and icon */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: "9px",
                }}
              >
                <Box
                  className="projectAccessLevelItem-label"
                  sx={{
                    color:
                      accessLevel.value === project.accessLevel
                        ? sphereColors.blue500
                        : colorConst.normalFont,
                    fontSize: DEFAULT_INPUT_FONT_SIZE,
                    fontWeight: "600",
                  }}
                >
                  {accessLevel.label}
                </Box>

                {accessLevel.value === project.accessLevel && (
                  <GenericCheck
                    width="21px"
                    height="21px"
                    fill={sphereColors.blue500}
                  />
                )}
              </Box>

              {/* Description */}
              <Box
                sx={{
                  color: sphereColors.gray600,
                  fontSize: "12px",
                }}
              >
                {accessLevel.description}
              </Box>
            </MenuItem>
          ))}
        </Select>
      ) : (
        <FaroTextField
          initialValue={getRenderValue(project.accessLevel)}
          sx={{
            width: ACCESS_LEVEL_COMPONENT_WIDTH,
          }}
        />
      )}
    </FormControl>
  );
}
