import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { IElement } from "@faro-lotv/ielement-types";
import {
  EntityAdapter,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { APITypes, CoreAPITypes } from "@stellar/api-logic";
import { AppDispatch, RootState } from "@store/store-helper";
import { BaseEntityState } from "@store/store-types";
import { projectIdSelector } from "@store/slide-nodes/slide-nodes-selector";
import {
  downloadSlideNodes,
  getSlideNodesUrls,
} from "@store/slide-nodes/slide-nodes-slice-utils";

interface SlideNodesState extends BaseEntityState<CoreAPITypes.IArSlideJson> {
  /** ID of the project that the stored slide nodes belong to */
  projectId?: APITypes.ProjectId;
}

export const slideNodesAdapter: EntityAdapter<CoreAPITypes.IArSlideJson> =
  createEntityAdapter({
    selectId: (slideNode) => slideNode.sId,
  });

const initialState: SlideNodesState = {
  ...slideNodesAdapter.getInitialState(),
};

interface FetchSlideNodes extends BaseProjectIdProps {
  /** Fetched slide nodes */
  slideNodes: CoreAPITypes.IArSlideJson[];
}

interface FetchSlideNodesProps extends BaseProjectIdProps {
  /** IElement entities to extract the slide nodes from */
  elements: IElement[];
}

export const fetchSlideNodes = createAsyncThunk<
  FetchSlideNodes,
  FetchSlideNodesProps,
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>(
  "slideNodes/fetchSlideNodes",
  async ({ projectId, elements }, { getState, dispatch }) => {
    // we only want to store entities of a single project.
    // Whenever the project changes this line we need to clear the store slice before fetching again
    const storedProjectId = projectIdSelector(getState());
    if (storedProjectId && storedProjectId !== projectId) {
      dispatch(resetSlideNodesState());
    }

    const slideNodesUrls = getSlideNodesUrls(elements);
    const promises = slideNodesUrls.map((url) => downloadSlideNodes(url));
    // Since each promise returns an array of slide nodes we need to flat the result into a single array.
    // Each promise handles any error/exception that might be thrown. The reason is that failing to download
    // the slides nodes is not critical for the app. We won't show a toast or log to Sentry if any promise fails.
    const slideNodes = (await Promise.all(promises)).flat();

    return { projectId, slideNodes };
  }
);

const slideNodesSlice = createSlice({
  name: "slideNodes",
  initialState,
  reducers: {
    upsertMany: slideNodesAdapter.upsertMany,
    resetSlideNodesState: () => initialState,
  },

  extraReducers(builder) {
    builder.addCase(fetchSlideNodes.fulfilled, (state, action) => {
      state.projectId = action.payload.projectId;
      slideNodesAdapter.upsertMany(state, action.payload.slideNodes);
    });
  },
});

export const { upsertMany, resetSlideNodesState } = slideNodesSlice.actions;

export const slideNodesReducer = slideNodesSlice.reducer;
