import {
  statName,
  dataGenerationType,
} from "@src/constants/analytics-constants";
import { DashboardAPITypes } from "@stellar/api-logic";

/** Analytics stat of type project count */
export interface ProjectStat extends DashboardAPITypes.ICompanyStatistic {
  /** Stat name identifier */
  name:
    | (typeof statName)["project-count"]
    | (typeof statName)["project-count-abs"];
}

/** Analytics stat of type project sqft */
export interface ProjectSqftStat extends DashboardAPITypes.ICompanyStatistic {
  /** Stat name identifier */
  name:
    | (typeof statName)["project-sqft"]
    | (typeof statName)["project-sqft-abs"];
}

/** Analytics stat of type project 360 images */
export interface ProjectSpheresStat
  extends DashboardAPITypes.ICompanyStatistic {
  /** Stat name identifier */
  name:
    | (typeof statName)["project-spheres"]
    | (typeof statName)["project-spheres-abs"];
}

/** Analytics stat of type project sheets */
export interface ProjectSheetsStat extends DashboardAPITypes.ICompanyStatistic {
  /** Stat name identifier */
  name:
    | (typeof statName)["project-sheets"]
    | (typeof statName)["project-sheets-abs"];
}

/** Analytics stat of data type cumulative (absolute) */
export interface CumulativeStat extends DashboardAPITypes.ICompanyStatistic {
  /** Stat data generation type */
  ["data-generation"]: typeof dataGenerationType.cumulative;
}

/** Analytics stat of data type differential */
export interface DifferentialStat extends DashboardAPITypes.ICompanyStatistic {
  /** Stat data generation type */
  ["data-generation"]: typeof dataGenerationType.differential;
}

/** List of chart titles */
export enum ChartTitle {
  project = "Total projects over time",
  projectSqft = "Total sqFt over time",
  projectSpheres = "Total 360º images over time",
  projectSheets = "Total sheets over time",
}

/** List of chart dataset legends */
export enum ChartLegend {
  projectNew = "New projects",
  projectExisting = "Existing projects",
  projectSqftNew = "Newly allocated sqft",
  projectSqftExisting = "Used sqft",
  projectSpheresNew = "New 360º images",
  projectSpheresExisting = "Existing 360º images",
  projectSheetsNew = "New sheets",
  projectSheetsExisting = "Existing sheets",
  defaultNew = "New",
  defaultExisting = "Existing",
}

/** List of chart types */
export enum ChartType {
  project = "project-count",
  projectSqft = "project-sqft",
  projectSpheres = "project-spheres",
  projectSheets = "project-sheets",
}

/** Project chart types  */
export type ProjectChartType =
  | ChartType.projectSpheres
  | ChartType.projectSheets;

/** Valid month strings for data labels */
export enum ChartMonth {
  jan = "Jan",
  feb = "Feb",
  mar = "Mar",
  apr = "Apr",
  may = "May",
  jun = "Jun",
  jul = "Jul",
  aug = "Aug",
  sep = "Sep",
  oct = "Oct",
  nov = "Nov",
  dec = "Dec",
}

/** Interface for a pairs of stats used to process the backend stats */
export interface StatPairBase {
  /** Chart type */
  name: ChartType;

  /** Chart display title */
  title: ChartTitle;

  /** Cumulative stat */
  cumulative?: CumulativeStat;

  /** Differential stat */
  differential?: DifferentialStat;
}

/** Interface for valid stat pairs where both cumulative and differential stats are defined */
export interface StatPair extends StatPairBase {
  /** Cumulative stat */
  cumulative: CumulativeStat;

  /** Differential stat */
  differential: DifferentialStat;
}

/** Record of stat pairs */
export type StatPairsBase = Record<ChartType, StatPairBase>;

/** Sets of chart data  */
export interface DataSets {
  /** Dataset for the stats total entries (existing + new) */
  total: number[];

  /** Dataset for the stats new entries */
  new: number[];

  /** Dataset for the stats existing entries */
  existing: number[];
}

/** Interface for the chart data */
export interface AnalyticsChartData {
  /** Chart type */
  name: ChartType;

  /** Chart title that will be displayed */
  title: string;

  /** X axis labels to display */
  labels: string[];

  /** Stacked datasets */
  datasets: DataSets;
}
