import { Box, Typography } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { BaseProjectProps } from "@custom-types/project-types";
import { AccessLevelDisplayNames } from "@custom-types/project-types";
import {
  ACTION_BTN_SIZE,
  ProjectActions,
} from "@components/common/project-actions";

/**
 * Bottom section of the card that shows the access level and the project actions.
 */
export function ProjectCardBottom({ project }: BaseProjectProps): JSX.Element {
  return (
    <Box
      component="div"
      sx={{
        paddingTop: 0,
        paddingBottom: 0,
        display: "flex",
        paddingX: "14px",
        alignItems: "center",
        justifyContent: "space-between",
        height: ACTION_BTN_SIZE,
      }}
    >
      {/* Show access level on the left side */}
      <Box component="div">
        <Typography
          sx={{
            color: sphereColors.gray700,
            fontSize: "11px",
          }}
        >
          {AccessLevelDisplayNames[project.accessLevel]}
        </Typography>
      </Box>

      {/* Show actions on the right side */}
      <ProjectActions project={project} contextType="project-tiles-view" />
    </Box>
  );
}
