import { SourcePointCloudInfo } from "@custom-types/point-cloud-types";
import { Box } from "@mui/material";

interface Props {
  /** Display name of the merged point cloud */
  name: string;

  /** Information about the source point clouds used to generate the merged point cloud */
  sourcePointCloudsInfo: SourcePointCloudInfo[];
}

/** Tooltip that lists the names of the source point clouds used to generate the merged point cloud */
export function MergedPointCloudDetailsTooltip({
  name,
  sourcePointCloudsInfo,
}: Props): JSX.Element {
  return (
    <Box data-testid="point-cloud-table-merged-details-tooltip">
      <Box
        sx={{
          marginBottom: "20px",
        }}
      >
        <Box
          component={"var"}
          sx={{
            fontWeight: "bold",
          }}
        >
          {name}
        </Box>
        <Box component={"span"}>
          {" is a merged point cloud generated from:"}
        </Box>
      </Box>
      {sourcePointCloudsInfo.map((sourcePointCloudInfo, index) => (
        <Box
          key={index}
          component={"var"}
          sx={{
            display: "block",
          }}
        >
          {"- "}
          {sourcePointCloudInfo.name}
        </Box>
      ))}
    </Box>
  );
}
