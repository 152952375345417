import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import {
  CaptureTreeEntity,
  RegistrationRevision,
} from "@faro-lotv/service-wires";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseProjectApiClientProps } from "@store/store-types";

/** Fetches the list of capture tree entities for the current main revision of the selected project */
export const fetchCaptureTreeForMainRevision = createAsyncThunk<
  CaptureTreeEntity[],
  BaseProjectApiClientProps
>(
  "captureTree/fetchCaptureTreeForMainRevision",
  async ({ projectApiClient }) => {
    try {
      return await projectApiClient.getCaptureTreeForMainRevision();
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/** Fetches the list of all registration revisions of the selected project */
export const fetchAllRegistrationRevisions = createAsyncThunk<
  RegistrationRevision[],
  BaseProjectApiClientProps
>("captureTree/fetchAllRegistrationRevisions", async ({ projectApiClient }) => {
  try {
    return await projectApiClient.getAllRegistrationRevisions();
  } catch (error) {
    throw new Error(getErrorDisplayMarkup(error));
  }
});
