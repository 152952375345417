import { SdbBackgroundTask } from "@custom-types/sdb-background-tasks-types";
import { RootState } from "@store/store-helper";
import { sdbBackgroundTasksAdapter } from "@store/sdb-background-tasks/sdb-background-tasks-slice";
import { EntityId, createSelector } from "@reduxjs/toolkit";
import {
  getTasksContext,
  sortSdbBackgroundTasks,
} from "@utils/sdb-background-tasks-utils";
import { FetchingStatus } from "@store/store-types";

/**
 * @returns all sdbBackgroundTasks entities
 */
export const sdbBackgroundTasksSelector: (
  state: RootState
) => SdbBackgroundTask[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const tasks = sdbBackgroundTasksAdapter
      .getSelectors()
      .selectAll(state.sdbBackgroundTasks);

    const tasksWithContext = getTasksContext({
      tasks,
      state,
    });

    return sortSdbBackgroundTasks(tasksWithContext);
  }
);

/**
 * @returns true if there is at least one SdbBackgroundTask running that has progress data
 */
export const areSdbBackgroundTasksRunningSelector: (
  state: RootState
) => boolean = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const backgroundTasks = sdbBackgroundTasksAdapter
      .getSelectors()
      .selectAll(state.sdbBackgroundTasks);
    const runningBackgroundTasks = backgroundTasks.filter(
      (backgroundTask) => backgroundTask.progress !== undefined
    );

    return runningBackgroundTasks.length > 0;
  }
);

/**
 * @returns the before page token for the background tasks or null if not known yet
 */
export const sdbBackgroundTaskBeforePageSelector: (
  state: RootState
) => EntityId | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => state.sdbBackgroundTasks.pagination.before
);

/**
 * @returns the after page token for the background tasks or null if not known yet
 */
export const sdbBackgroundTaskAfterPageSelector: (
  state: RootState
) => EntityId | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => state.sdbBackgroundTasks.pagination.after
);

/**
 * @returns the total count of the background tasks or null if not known yet
 */
export const totalCountSelector: (state: RootState) => number | null =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => state.sdbBackgroundTasks.pagination.totalCount
  );

/**
 * @returns the fetching status of the background tasks
 */
export const backgroundTasksFetchingStatusSelector: (
  state: RootState
) => FetchingStatus = createSelector(
  (state: RootState) => state,
  (state: RootState) =>
    state.sdbBackgroundTasks.fetching.backgroundTasksFetchingStatus
);
