/** All the possible headers for groups table. */
export enum GroupHeaders {
  /** Group thumbnail image */
  thumbnail = "Thumbnail",

  /** Group name */
  name = "Name",

  /** Number of group managers */
  managers = "Managers",

  /** Number of active projects */
  projects = "Active projects",

  /** Group created date */
  createdDate = "Created Date",

  /** Any other possible options */
  options = "Options",
}
