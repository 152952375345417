import CircularProgress, {
  CircularProgressProps,
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { sphereColors } from "@styles/common-colors";

interface Props extends CircularProgressProps {
  /** Progress value to show inside the spinner */
  value?: number;

  /** Text to show under the spinner */
  label?: string;
}

/** Size (height and width) of the circular progress in pixels */
const CIRCULAR_PROGRESS_SIZE = "60px";

/** Thickness for the circular progress, it is unitless and refers to CircularProgress thickness */
const CIRCULAR_PROGRESS_THICKNESS = 4;

/** Render a circular progress spinner with progress value if provided */
export function SphereCircularProgress(props: Props): JSX.Element {
  return (
    <Box>
      <Box
        sx={{
          position: "relative",
          height: CIRCULAR_PROGRESS_SIZE,
          width: CIRCULAR_PROGRESS_SIZE,
        }}
      >
        <CircularProgress
          variant="determinate"
          sx={{
            color: sphereColors.gray200,
            position: "absolute",
            left: 0,
          }}
          size={CIRCULAR_PROGRESS_SIZE}
          thickness={CIRCULAR_PROGRESS_THICKNESS}
          {...props}
          value={100}
        />
        <CircularProgress
          variant={props.value ? "determinate" : "indeterminate"}
          sx={{
            color: sphereColors.blue500,
            position: "absolute",
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
          size={CIRCULAR_PROGRESS_SIZE}
          thickness={CIRCULAR_PROGRESS_THICKNESS}
          {...props}
        />
        {typeof props.value !== "undefined" && (
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="caption"
              component="div"
              color={sphereColors.gray800}
            >{`${Math.round(props.value)}%`}</Typography>
          </Box>
        )}
      </Box>

      {props.label && (
        <Typography
          variant="caption"
          component="div"
          color={sphereColors.gray800}
          sx={{ paddingTop: "10px" }}
        >
          {props.label}
        </Typography>
      )}
    </Box>
  );
}
