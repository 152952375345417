import { Box } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import {
  DragEvent,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useIsUserDragging } from "@hooks/use-is-user-dragging";
import { REMOVE_WORKSPACE_LOGO } from "@components/common/sphere-dropzone/dropzone-elements";
import { BrowserUtils } from "@stellar/web-core";

interface Props {
  /** True if file can be dropped */
  canBeDropped: boolean;

  /** Callback executed when files are dropped */
  onDrop(event: DragEvent<HTMLElement>): void;

  /** Callback executed when the drop area is clicked */
  onClick?(): void;

  /** True if logo uploading is in progress */
  isLoading: boolean;

  /** True if multiple file should be handled */
  shouldAllowMultiUpload: boolean;
}

/** Handles dragging effect and file drop */
export function DropHandler({
  children,
  canBeDropped,
  onDrop,
  onClick,
  isLoading,
  shouldAllowMultiUpload,
}: PropsWithChildren<Props>): JSX.Element {
  const isAppDragging = useIsUserDragging();

  /** Check if the current element is receiving dragging events */
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [itemsCount, setItemsCount] = useState<number>(0);

  const onDragEnterAndOver = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      const items = event.dataTransfer.items.length;
      setItemsCount(items);

      /**
       * Sets the appropriate drag-and-drop effect and allowed actions based on specified conditions.
       * This logic is used to allow users to add file in Safari where the dataTransfer event may not
       * work as expected.
       */
      if (
        canBeDropped &&
        (BrowserUtils.isSafari() || items === 1 || shouldAllowMultiUpload)
      ) {
        event.dataTransfer.dropEffect = "copy";
        event.dataTransfer.effectAllowed = "all";
      } else {
        event.dataTransfer.dropEffect = "none";
        event.dataTransfer.effectAllowed = "none";
      }

      event.stopPropagation();
      setIsDragging(true);
    },
    [canBeDropped, shouldAllowMultiUpload]
  );

  const onDragLeave = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    setItemsCount(0);
  }, []);

  const onFileDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      onDragLeave(event);
      onDrop(event);
    },
    [onDrop, onDragLeave]
  );

  const borderStyle = useMemo(() => {
    if (!shouldAllowMultiUpload && itemsCount > 1) {
      return `2px solid ${sphereColors.red500}`;
    }
    if (isDragging) {
      return `2px solid ${sphereColors.blue500}`;
    }
    if (isAppDragging) {
      return `2px dashed ${sphereColors.blue400}`;
    }

    return `2px dashed ${sphereColors.gray200}`;
  }, [shouldAllowMultiUpload, itemsCount, isDragging, isAppDragging]);

  return (
    <Box
      component="div"
      onDrop={onFileDrop}
      onDragEnter={onDragEnterAndOver}
      onDragOver={onDragEnterAndOver}
      onDragLeave={onDragLeave}
      onClick={onClick}
      sx={{
        width: "100%",
        height: "250px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        fontSize: "16px",
        fontWeight: 600,
        border: borderStyle,
        textAlign: "center",
        position: "relative",
        flexDirection: "column",
        cursor: isLoading ? "default" : "pointer",
        borderRadius: "4px",
        ...(!isLoading && {
          "&:hover": {
            border: `2px solid ${sphereColors.blue500}`,
            [`& .${REMOVE_WORKSPACE_LOGO}`]: {
              // Show the remove workspace logo button (which is hidden by default), when hovering over the dropzone
              visibility: "visible",
            },
          },
        }),
      }}
    >
      {children}
    </Box>
  );
}
