import { Box } from "@mui/material";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { LoadingScreen } from "@faro-lotv/flat-ui";

type LoadingSpinnerContextData = {
  /** Whether the loading spinner is showing */
  isLoadingSpinnerShowing: boolean;

  /** Sets the loading spinner to show or hide */
  setLoadingSpinner(isEnabled: boolean): void;
};

/** Context that provides the LoadingSpinnerProvider with function to show the loading spinner from anywhere */
const LoadingSpinnerContext = createContext<
  LoadingSpinnerContextData | undefined
>(undefined);

/**
 * @returns A provider that allows components to show the app loading spinner from anywhere
 *
 * The App loading spinner cover the whole screen.
 */
export function LoadingSpinnerProvider({
  children,
}: PropsWithChildren): JSX.Element | null {
  const [isLoadingSpinnerShowing, setIsLoadingSpinnerShowing] =
    useState<boolean>(false);

  const setLoadingSpinner = useCallback((isEnabled: boolean) => {
    setIsLoadingSpinnerShowing(isEnabled);
  }, []);

  return (
    <LoadingSpinnerContext.Provider
      value={{ isLoadingSpinnerShowing, setLoadingSpinner }}
    >
      {isLoadingSpinnerShowing && (
        <Box
          component="div"
          display="grid"
          role="loadingScreen"
          sx={{
            zIndex: "modal",
            position: "fixed",
            top: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            placeItems: "center",
            backgroundColor: "white",
          }}
        >
          <LoadingScreen />
        </Box>
      )}

      {children}
    </LoadingSpinnerContext.Provider>
  );
}

export function useLoadingSpinner(): LoadingSpinnerContextData {
  const context = useContext(LoadingSpinnerContext);

  if (!context) {
    throw Error(
      "useLoadingSpinner must be used inside the LoadingSpinnerProvider"
    );
  }

  return context;
}
