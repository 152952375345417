import { IReactChildrenOnly } from "@custom-types/types";
import { Box } from "@mui/material";

/**
 * A container for the tiles, for example the projects and groups tiles. It makes sure that the tiles are
 * aligned properly.
 */
export function TilesContainer({ children }: IReactChildrenOnly): JSX.Element {
  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        flexWrap: "wrap",
        columnGap: "20px",
      }}
    >
      {children}
    </Box>
  );
}
