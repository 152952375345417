import { ExportAsCSV } from "@utils/export-as-csv";
import { ProjectArchivingState, SdbProject } from "@custom-types/project-types";
import { TableType } from "@utils/track-event/track-event-list";
import { downloadProjectsDataAsCSV } from "@utils/csv-data-export";
import { ProjectTableSubject } from "@components/common/faro-table/faro-table-types";
import { useDateTime } from "@hooks/use-date-time";

interface Props {
  /** Recognizes on which subject the table is being used */
  subjectType: ProjectTableSubject;

  /** The name of the context type */
  entityName: string;

  /** List of selected groups in table */
  selectedProjects: SdbProject[];

  /** The archiving status of the project */
  projectStatus?: ProjectArchivingState;

  /** True if some entities are selected */
  areEntitiesSelected: boolean;
}

/** Button to export selected members as CSV */
export function ExportProjectsAsCSV({
  subjectType,
  entityName,
  selectedProjects,
  projectStatus,
  areEntitiesSelected,
}: Props): JSX.Element | null {
  const { formatDate } = useDateTime();

  return (
    <ExportAsCSV
      trackingProps={{
        dataType: exportedTableType(subjectType, projectStatus),
        numberOfEntities: selectedProjects.length,
        areEntitiesSelected,
      }}
      csvExportHandler={() =>
        downloadProjectsDataAsCSV({
          fileName: generateFileName(subjectType, entityName, projectStatus),
          projects: selectedProjects,
          formatDate,
        })
      }
      toastMessage={{
        success: "Successfully exported projects as CSV.",
        error: "Cannot export projects. Please try again.",
      }}
      buttonTooltipText="Export projects"
    />
  );
}

/** Returns the file name for the CSV file */
function generateFileName(
  subjectType: ProjectTableSubject,
  entityName: string,
  projectStatus?: ProjectArchivingState
): string {
  switch (subjectType) {
    case "workspace":
      return `Company ${entityName} - ${projectStatus} project list.csv`;

    case "group":
      return `Group ${entityName} - project list.csv`;
  }
}

/** Returns the exported table type */
function exportedTableType(
  subjectType: ProjectTableSubject,
  projectStatus?: ProjectArchivingState
): TableType {
  switch (subjectType) {
    case "workspace":
      return projectStatus === ProjectArchivingState.active
        ? TableType.activeProjects
        : TableType.archiveProjects;

    case "group":
      return TableType.groupProjects;
  }
}
