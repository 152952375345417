import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/store-helper";

/**
 * @returns True if the point clouds ielements have been fetched from the backend
 */
export const hasFetchedPointCloudsIElementsSelector: (
  state: RootState
) => boolean = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.sdbIElements.hasFetchedPointCloudsIElements;
  }
);

/**
 * @returns True if the markups have been fetched from the backend
 */
export const hasFetchedMarkupsIElementsSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.sdbIElements.hasFetchedMarkupsIElements;
    }
  );
