import { useCallback, useEffect, useMemo } from "react";
import { BroadcastChannelProps } from "@custom-types/types";

/** Auth broadcast channel definition */
export const AuthBroadcastChannel: BroadcastChannelProps = {
  name: "sphereDashboardAuth",
  messages: {
    logoutMessage: "sphere-dashboard-logout",
  },
};

interface Props {
  /** Callback function to invoke when a logout message is received from another tab */
  logoutCallback(): void;
}

/** Custom hook that handles an auth BroadcastChannel to share messages between browser tabs */
export function useAuthBroadcastChannel({
  logoutCallback,
}: Props): BroadcastChannel | undefined {
  const broadcastChannel = useMemo(() => {
    /**
     * BroadcastChannel browser feature might not be supported by older versions of some browsers:
     * https://www.lambdatest.com/web-technologies/broadcastchannel
     * Return `undefined` if the BroadcastChannel class is undefined
     */
    if (typeof BroadcastChannel === "undefined") {
      return undefined;
    }

    return new BroadcastChannel(AuthBroadcastChannel.name);
  }, []);

  // Memoize logout callback to ensure it does not trigger unnecessary re-renders.
  const memoizedLogoutCallback = useCallback(() => {
    logoutCallback();
  }, [logoutCallback]);

  // Event listener for the `logout` message coming from other browser tabs
  useEffect(() => {
    // Early exit if BroadcastChannel object is undefined
    if (!broadcastChannel) {
      return;
    }

    function onBroadcastChannelMessage(message: MessageEvent<string>): void {
      if (message.data === AuthBroadcastChannel.messages.logoutMessage) {
        memoizedLogoutCallback();
      }
    }

    broadcastChannel.addEventListener("message", onBroadcastChannelMessage);

    return () =>
      broadcastChannel.removeEventListener(
        "message",
        onBroadcastChannelMessage
      );
  }, [broadcastChannel, memoizedLogoutCallback]);

  return broadcastChannel;
}
