import { ApiResponseError } from "@faro-lotv/service-wires";

/**
 * @returns True iff the passed API response error is a project API mutation error
 *
 * @param error Error of type ApiResponseError
 */
export function isProjectApiMutationError(error: ApiResponseError): boolean {
  return error.body !== undefined && Array.isArray(error.body.data);
}

/**
 * @returns True iff the passed API response error is a project API error
 *
 * @param error Error of type ApiResponseError
 */
export function isBaseProjectAPIError(error: ApiResponseError): boolean {
  return (
    "body" in error &&
    typeof error.body === "object" &&
    "error" in error.body &&
    typeof error.body.error === "string"
  );
}
