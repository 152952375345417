import { RouteParams } from "@router/route-params";

/** Validates that the passed value has valid URL format */
export function isValidUrl(value: unknown): value is string {
  if (typeof value !== "string") {
    return false;
  }

  try {
    new URL(value);
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Returns whether the passed host is a numbered environment.
 *
 * @param host E.g. "dev1.sphere.dev.holobuilder.com" or "sphere.holobuilder.com"
 * @returns True if the host is a numbered environment, e.g. "dev1.sphere.dev.holobuilder.com"
 */
export function isHostNumberedEnv(host: string): boolean {
  return /^dev\d{1,2}/.test(host);
}

/**
 * Returns the numbered environment from the passed host.
 *
 * @param host E.g. "dev1.sphere.dev.holobuilder.com" or "sphere.holobuilder.com"
 * @returns The numbered environment if the host is a numbered environment, e.g. "dev1"
 */
export function getNumberedEnv(host: string): string | null {
  const numberedEnv = host.match(/^dev\d{1,2}/);
  return numberedEnv?.length ? numberedEnv[0] : null;
}

export function addNumberedEnv(url: string, numberedEnv: string): string {
  const urlObj = new URL(url);

  // Add numbered environment only to sphere.*.holobuilder.com or sphere.*.eu URLs
  if (urlObj.hostname.match(/sphere.*\.holobuilder\.(com|eu)/)) {
    const parts = urlObj.hostname.split(".");
    parts[0] = `${numberedEnv}.${parts[0]}`;
    urlObj.hostname = parts.join(".");
  }

  return urlObj.toString();
}

/** Gets the URL for the init route of the Dashboard 2.0 API. */
export function getDashboard20InitApiUrl(
  dashboard20Api: string,
  numberedEnv: string | null
): string {
  let baseApi = dashboard20Api;
  if (numberedEnv) {
    baseApi = addNumberedEnv(`${dashboard20Api}`, numberedEnv);
  }
  return `${baseApi}/init`;
}

/**
 * Returns the pathname from the URL with the ids replaced by the key names.
 *
 * @param url A URL with ids in the path, e.g. /project/1234
 * @param appParams An object with the key names and their corresponding ids, e.g. { projectId: 1234 }
 * @returns The masked pathname, e.g. /project/:projectId
 */
export function getPathnameFromUrl(
  originalPathname: string,
  appParams: Partial<RouteParams>
): string {
  let pathname = originalPathname;
  let key: keyof typeof appParams;
  for (key in appParams) {
    // Replace all ids in the path with the key name
    // e.g. /project/1234 -> /project/:projectId
    if (key in appParams && key.endsWith("Id")) {
      pathname = pathname.replace(`${appParams[key]}`, `:${key}`);
    }
  }

  return pathname;
}
