import {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
  MouseEvent,
} from "react";

interface AddOnsContextType {
  /**
   * The current anchor element associated with the add-ons context.
   * This property holds either `null` or an `HTMLElement` representing the anchor element.
   */
  anchorEl: null | HTMLElement;

  /**
   * This function is responsible for handling mouse events and updating the anchor element state accordingly.
   *
   * @param event The `MouseEvent` triggering the anchor state update.
   */
  updateAnchorState: (event: MouseEvent<HTMLElement>) => void;
}

// Create the context for the add ons
const AddOnsContext = createContext<AddOnsContextType | undefined>(undefined);

export function AddonsProvider({
  children,
}: PropsWithChildren): JSX.Element | null {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  /**
   * Updates the anchor element state based on a mouse event.
   * This function toggles the `anchorEl` state between `null` and the current target of the provided mouse event.
   *
   * @param event The `MouseEvent` triggering the anchor state update.
   */
  function updateAnchorState(event: MouseEvent<HTMLElement>): void {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  }

  return (
    <AddOnsContext.Provider value={{ anchorEl, updateAnchorState }}>
      {children}
    </AddOnsContext.Provider>
  );
}

// Hook to use the project tabs context
export function useAddonsContext(): AddOnsContextType {
  const context = useContext(AddOnsContext);

  if (!context) {
    throw new Error("useAddonsContext must be used within a AddonsProvider");
  }

  return context;
}
