import { useAppSelector } from "@store/store-helper";
import { hasInProgressUploadTasksSelector } from "@store/upload-tasks/upload-tasks-selector";
import { useBeforeUnload } from "react-router-dom";

/**
 * @returns the logic to prevent the browser tab from closing if there are any task in-progress.
 */
export function useHandleWindowClose(): void {
  const hasInProgressUploadTask = useAppSelector(
    hasInProgressUploadTasksSelector
  );

  useBeforeUnload((ev) => {
    if (hasInProgressUploadTask) {
      ev.preventDefault();
      // All browsers prevent custom messages, so we just have to pass an empty string here to get the dialog to appear.
      ev.returnValue = "";
    }
  });

  return;
}
