import { EmptyPage } from "@components/common/empty-page/empty-page";
import { Box } from "@mui/material";
import {
  fetchingStatusAllRegistrationRevisionsSelector,
  hasFetchedAllRegistrationRevisionsSelector,
  preparedRegistrationRevisionsSelector,
} from "@store/capture-tree/capture-tree-selectors";
import { useAppSelector } from "@store/store-helper";
import { FetchingStatus } from "@store/store-types";
import { sphereColors } from "@styles/common-colors";
import { useMemo } from "react";
import NoDataIcon from "@assets/icons/new/no_data.svg?react";
import FailedIcon from "@assets/icons/failed_32px.svg?react";
import { PreparedDataTable } from "@pages/project-details/project-data-management/prepared-data/prepared-data-table";
import { PROJECT_DATA_MANAGEMENT_LABELS } from "@src/constants/project-data-management-constants";

/** Renders prepared data section of the data management page */
export function PreparedData(): JSX.Element {
  const preparedRegistrationRevisions = useAppSelector(
    preparedRegistrationRevisionsSelector
  );
  const fetchingStatus = useAppSelector(
    fetchingStatusAllRegistrationRevisionsSelector
  );
  const hasFetchedAllRegistrationRevisions = useAppSelector(
    hasFetchedAllRegistrationRevisionsSelector
  );

  const isFetchingForTheFirstTime = useMemo(() => {
    return (
      !hasFetchedAllRegistrationRevisions &&
      fetchingStatus === FetchingStatus.pending
    );
  }, [fetchingStatus, hasFetchedAllRegistrationRevisions]);

  const shouldShowEmptyPage = useMemo(() => {
    return (
      preparedRegistrationRevisions.length === 0 && !isFetchingForTheFirstTime
    );
  }, [preparedRegistrationRevisions.length, isFetchingForTheFirstTime]);

  const hasFailedToFetchCaptureTree = useMemo(() => {
    return fetchingStatus === FetchingStatus.rejected;
  }, [fetchingStatus]);

  return (
    <Box
      data-testid="prepared-data-content"
      sx={{
        marginTop: "14px",
        border: `1px solid ${sphereColors.gray200}`,
        borderRadius: 0,
      }}
    >
      {shouldShowEmptyPage ? (
        <EmptyPage
          title={hasFailedToFetchCaptureTree ? "Error" : "No prepared data"}
          subtitle={
            hasFailedToFetchCaptureTree
              ? "Failed to fetch the prepared data. Please reload the page and try again."
              : "No prepared data to show for this project."
          }
          icon={hasFailedToFetchCaptureTree ? FailedIcon : NoDataIcon}
        />
      ) : (
        <Box
          sx={{
            background: "white",
          }}
        >
          <Box
            sx={{
              height: "64px",
              paddingX: "30px",
              borderBottom: `1px solid ${sphereColors.gray200}`,
              fontWeight: "bold",
              alignContent: "center",
            }}
          >
            {PROJECT_DATA_MANAGEMENT_LABELS.preparedData}
          </Box>
          <Box
            sx={{
              paddingX: "30px",
              paddingBottom: "30px",
            }}
          >
            <PreparedDataTable
              preparedData={preparedRegistrationRevisions}
              isFetchingForTheFirstTime={isFetchingForTheFirstTime}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
