import { FaroButtonContained } from "@components/common/faro-button-contained";
import { Box } from "@mui/material";

interface Props {
  /** Flag whether the button should show as loading */
  isLoading: boolean;

  /** Text to be shown in the button */
  buttonText?: string;

  /** Flag whether the button should be hidden */
  shouldHideLoadMoreButton?: boolean;

  /** Callback when the button is clicked */
  onClick: () => Promise<void>;
}

/**
 * The button to load more elements, for example in a table. This component takes care
 * of hiding the button and add the correct margin when it is shown.
 */
export function LoadMoreButton({
  isLoading,
  buttonText = "Load more",
  shouldHideLoadMoreButton = false,
  onClick,
}: Props): JSX.Element | null {
  // If there's no next element it means we should hide the button because
  // there are no more elements to be loaded.
  if (shouldHideLoadMoreButton) {
    return null;
  }

  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        justifyContent: "center",
        mt: "50px",
      }}
    >
      <FaroButtonContained isLoading={isLoading} onClick={onClick}>
        {buttonText}
      </FaroButtonContained>
    </Box>
  );
}
