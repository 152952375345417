// keyCombinationsMap.ts
import { QueryParams } from "@router/route-params";

export interface KeyCombination {
  /** True if it is required that alt key was pressed */
  hasAltKey?: boolean;

  /** True if it should not work in production */
  shouldSkipProd?: boolean;

  /** True if only internal FARO users should use it in prod */
  isOnlyForInternalUsersInProd?: boolean;

  /** The action to take when this combination is pressed */
  action: (setQueryParam: (key: QueryParams, value: string) => void) => void;
}

/**
 *  "Alt+KeyA" - combination to active the alpha feature flag
 *  "Alt+KeyB" - combination to active the enable feature flag
 *  "Alt+KeyN" - combination to disable all the feature flag
 */
export const keyCombinationsMap: Map<string, KeyCombination> = new Map([
  [
    "Alt+KeyA",
    {
      hasAltKey: true,
      shouldSkipProd: false,
      isOnlyForInternalUsersInProd: true,
      action: (setQueryParam) => setQueryParam(QueryParams.enableAlpha, "true"),
    },
  ],
  [
    "Alt+KeyB",
    {
      hasAltKey: true,
      shouldSkipProd: false,
      isOnlyForInternalUsersInProd: true,
      action: (setQueryParam) => setQueryParam(QueryParams.enableBeta, "true"),
    },
  ],
  [
    "Alt+KeyN",
    {
      hasAltKey: true,
      shouldSkipProd: false,
      action: (setQueryParam) => {
        const search = window.location.search ?? "";

        // Only set params to false if they are included in the URL
        // This way it is harder for users to accidentally find them
        if (search.includes(QueryParams.enableAlpha)) {
          setQueryParam(QueryParams.enableAlpha, "false");
        }
        if (search.includes(QueryParams.enableBeta)) {
          setQueryParam(QueryParams.enableBeta, "false");
        }
      },
    },
  ],
]);
