import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { SHOW_ON_HOVER_CLASS } from "@utils/ui-utils";
import { Box } from "@mui/material";
import {
  markupAssigneeComparator,
  markupSceneComparator,
  markupSheetComparator,
  markupStatusComparator,
  markupTagsComparator,
  ProjectMarkupsTableHeaders,
} from "@pages/project-details/project-markups/project-markups-table-utils";
import { Markup } from "@custom-types/project-markups-types";
import { MarkupNameAndDetails } from "@pages/project-details/project-markups/markup-name-and-details";
import { MarkupAssignee } from "@pages/project-details/project-markups/markup-assignee";
import { MarkupActions } from "@pages/project-details/project-markups/markup-actions";
import { MarkupIntegration } from "@pages/project-details/project-markups/markup-integration";
import { MarkupLocation } from "@pages/project-details/project-markups/markup-location";
import { MarkupDueDate } from "@pages/project-details/project-markups/due-date/markup-due-date";
import { MarkupStatusDropdown } from "@pages/project-details/project-markups/status/markup-status-dropdown";
import { MarkupTags } from "@pages/project-details/project-markups/markup-tags";
import { MarkupAttachments } from "@pages/project-details/project-markups/markup-attachments";
import { getMarkupStatusValue } from "@pages/project-details/project-markups/status/markup-status-utils";
import { getMarkupLocationValue } from "@pages/project-details/project-markups/markup-location-utils";
import { getIntegrationName } from "@pages/project-details/project-markups/markup-integration-utils";
import { MemberTypes } from "@custom-types/member-types";
import { SphereDashboardAPITypes } from "@stellar/api-logic";

interface Props {
  /** List of company members */
  companyMembers: MemberTypes[];

  /** List of project members */
  projectMembers: SphereDashboardAPITypes.IProjectMemberBase[];
}

/** Return all the possible columns for Project markups table */
export function getProjectMarkupsColumns({
  companyMembers,
  projectMembers,
}: Props): Record<ProjectMarkupsTableHeaders, GridColDef> {
  return {
    [ProjectMarkupsTableHeaders.name]: {
      field: ProjectMarkupsTableHeaders.name,
      minWidth: 230,
      flex: 1.3,
      renderCell: (params: GridRenderCellParams<{ entity: Markup }>) => {
        return <MarkupNameAndDetails markup={params.row.entity} />;
      },
      valueGetter: (_, row: { entity: Markup }) => row.entity.name,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
    },

    [ProjectMarkupsTableHeaders.assignee]: {
      field: ProjectMarkupsTableHeaders.assignee,
      minWidth: 180,
      flex: 1,
      renderCell: (params: GridRenderCellParams<{ entity: Markup }>) => {
        return <MarkupAssignee markup={params.row.entity} />;
      },
      valueGetter: (_, row: { entity: Markup }) =>
        row.entity.assignee?.values?.[0],

      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        markupAssigneeComparator(cp1, cp2, companyMembers, projectMembers),
    },

    [ProjectMarkupsTableHeaders.dueDate]: {
      field: ProjectMarkupsTableHeaders.dueDate,
      minWidth: 140,
      flex: 0.5,
      type: "date",
      renderCell: (params: GridRenderCellParams<{ entity: Markup }>) => {
        return <MarkupDueDate markup={params.row.entity} />;
      },
      valueGetter: (_, row: { entity: Markup }) => {
        return row.entity.dueDate?.value
          ? new Date(row.entity.dueDate.value)
          : undefined;
      },

      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
    },

    [ProjectMarkupsTableHeaders.status]: {
      field: ProjectMarkupsTableHeaders.status,
      minWidth: 140,
      flex: 0.5,
      renderCell: (params: GridRenderCellParams<{ entity: Markup }>) => {
        return <MarkupStatusDropdown markup={params.row.entity} />;
      },
      valueGetter: (_, row: { entity: Markup }) =>
        getMarkupStatusValue(row.entity.status?.value),

      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: markupStatusComparator,
    },

    [ProjectMarkupsTableHeaders.location]: {
      field: ProjectMarkupsTableHeaders.location,
      minWidth: 180,
      flex: 1,
      renderCell: (params: GridRenderCellParams<{ entity: Markup }>) => {
        return <MarkupLocation markup={params.row.entity} />;
      },
      valueGetter: (_, row: { entity: Markup }) =>
        getMarkupLocationValue({ markup: row.entity }),
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
    },

    // This column is hidden by default (set in ProjectMarkupsTable).It is added to apply filters for sheets
    // If it needs to be shown later, the sort logics should be updated to sort based on name and not ID
    [ProjectMarkupsTableHeaders.sheet]: {
      field: ProjectMarkupsTableHeaders.sheet,
      minWidth: 180,
      flex: 1,
      renderCell: (params: GridRenderCellParams<{ entity: Markup }>) => {
        return params.row.entity.areaSection?.name;
      },
      valueGetter: (_, row: { entity: Markup }) => {
        if (row.entity.areaSection) {
          return row.entity.areaSection.id;
        }

        // If no tags exist, we add null so that we can use isEmpty for filtering
        return null;
      },

      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) => markupSheetComparator(cp1, cp2),
    },

    // This column is hidden by default (set in ProjectMarkupsTable). It is added to apply filters for scenes
    // If it needs to be shown later, the sort logics should be updated to sort based on name and not ID
    [ProjectMarkupsTableHeaders.scene]: {
      field: ProjectMarkupsTableHeaders.scene,
      minWidth: 180,
      flex: 1,
      renderCell: (params: GridRenderCellParams<{ entity: Markup }>) => {
        return params.row.entity.section?.name;
      },
      valueGetter: (_, row: { entity: Markup }) => {
        if (row.entity.section) {
          return row.entity.section.id;
        }

        // If no tags exist, we add null so that we can use isEmpty for filtering
        return null;
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) => markupSceneComparator(cp1, cp2),
    },

    [ProjectMarkupsTableHeaders.tags]: {
      field: ProjectMarkupsTableHeaders.tags,
      minWidth: 180,
      flex: 1,

      renderCell: (params: GridRenderCellParams<{ entity: Markup }>) => {
        return <MarkupTags markup={params.row.entity} />;
      },

      valueGetter: (_, row: { entity: Markup }) => {
        if (row.entity.labels?.length) {
          return row.entity.labels.map((label) => label.id).join(", ");
        }

        // If no tags exist, we add null so that we can use isEmpty for filtering
        return "";
      },

      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) => markupTagsComparator(cp1, cp2),
    },

    [ProjectMarkupsTableHeaders.syncWith]: {
      field: ProjectMarkupsTableHeaders.syncWith,
      minWidth: 60,
      flex: 0.4,
      renderCell: (params: GridRenderCellParams<{ entity: Markup }>) => {
        return (
          <MarkupIntegration
            externalMarkup={params.row.entity.externalMarkup}
          />
        );
      },
      valueGetter: (_, row: { entity: Markup }) =>
        getIntegrationName(row.entity.externalMarkup),

      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
    },

    [ProjectMarkupsTableHeaders.attachments]: {
      field: ProjectMarkupsTableHeaders.attachments,
      headerAlign: "left",
      minWidth: 180,
      flex: 1,
      type: "number",
      align: "left",
      renderCell: (params: GridRenderCellParams<{ entity: Markup }>) => {
        return <MarkupAttachments markup={params.row.entity} />;
      },
      valueGetter: (_, row: { entity: Markup }) =>
        row.entity.attachments.length,

      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
    },

    [ProjectMarkupsTableHeaders.options]: {
      field: ProjectMarkupsTableHeaders.options,
      type: "actions",
      align: "right",
      width: 90,
      renderCell: (params: GridRenderCellParams<{ entity: Markup }>) => {
        return (
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              // This will be overridden by parent component using SHOW_ON_HOVER_CLASS
              visibility: "hidden",
            }}
            className={SHOW_ON_HOVER_CLASS}
          >
            <MarkupActions markup={params.row.entity} />
          </Box>
        );
      },
    },
  };
}
