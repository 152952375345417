import { FaroSwitch } from "@components/common/faro-switch";
import { Box } from "@mui/material";

interface Props {
  /** True if a loader should show for the switch */
  isLoading: boolean;

  /** True if the switch is checked */
  isChecked: boolean;

  /** Function to set the checked state of the switch */
  setIsChecked: (isChecked: boolean) => void;
}

/** Renders a switch with a loader with some styling specific for the settings page */
export function SettingSwitch({
  isLoading,
  isChecked,
  setIsChecked,
}: Props): JSX.Element {
  return (
    <Box component="div" width="100%" display="flex" justifyContent="end">
      <Box component="div" width="50px" display="flex">
        <FaroSwitch
          disabled={false}
          isLoading={isLoading}
          checked={isChecked}
          onChange={(_, isChecked) => {
            setIsChecked(isChecked);
          }}
        />
      </Box>
    </Box>
  );
}
