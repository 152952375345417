import { Box } from "@mui/material";
import { sphereColors, colorConst } from "@styles/common-colors";

/**
 * An orange tag to show that something is new.
 */
export function NewTag(): JSX.Element {
  return (
    <Box
      component="div"
      sx={{
        backgroundColor: colorConst.newFeatureIndicator,
        border: "none",
        color: sphereColors.pureWhite,
        height: "16px",
        width: "36px",
        borderRadius: "9px",
        textTransform: "uppercase",
        fontSize: "10px",
        fontWeight: "bold",
        textAlign: "center",
      }}
    >
      New
    </Box>
  );
}
