import { RouteParams } from "@router/route-params";
import { useAppParams } from "@router/router-helper";
import {
  // eslint-disable-next-line no-restricted-imports -- The only place needed to define useTrackEvent
  Analytics,
  ILogAsyncEventParams,
  ILogEventParams,
} from "@stellar/web-core";
import { selectedProjectSelector } from "@store/projects/projects-selector";
import { useAppSelector } from "@store/store-helper";
import { currentUserSelector } from "@store/user/user-selector";
import { useLocation } from "react-router-dom";
import { shouldPreventTracking } from "@utils/track-event/track-event-list";

/** All the ids available in the app */
const allEntityIds: Array<keyof RouteParams> = [
  "companyId",
  "projectId",
  "memberId",
  "groupId",
];

export interface UseTrackEvent {
  /** Function that calls to track an event */
  trackEvent({ name, props }: ILogEventParams): void;

  /** Function that calls to track an event async */
  trackAsyncEvent({
    name,
    props,
    shouldWaitForInitialization,
  }: ILogAsyncEventParams): Promise<void>;
}

interface DefaultProps {
  workspaceRole?: string;
  projectRole?: string;
}

/** Track events with additional default properties that might be changed over the course */
export function useTrackEvent(): UseTrackEvent {
  const { pathname } = useLocation();
  const params = useAppParams();
  const currentUser = useAppSelector(currentUserSelector);
  const selectedProject = useAppSelector(selectedProjectSelector);
  const { projectId } = useAppParams();

  if (shouldPreventTracking) {
    return {
      trackEvent: () => {
        return;
      },
      trackAsyncEvent: async () => {
        return;
      },
    };
  }

  /**
   * Gets the props for the event
   */
  function getProps(props: ILogEventParams["props"]): ILogEventParams["props"] {
    const defaultProps: DefaultProps = {};
    if (currentUser && currentUser.role) {
      defaultProps.workspaceRole = currentUser.role.toString();
    }

    if (
      projectId &&
      selectedProject &&
      "role" in selectedProject &&
      selectedProject.role
    ) {
      defaultProps.projectRole = selectedProject.role.toString();
    }

    const allProps: ILogEventParams["props"] = {
      ...props,
      ...defaultProps,
      path: createPathForTracking(pathname, params),
    };

    return allProps;
  }

  function trackEvent({ name, props }: ILogEventParams): void {
    // We need to wait until Amplitude is initialized before tracking events.
    // This is an issue as we see many errors in Sentry, with the error Amplitude is not ready.
    // To do so and not to change code in web-core as other repos are using it,
    // we put it in a timeout so that it can be scheduled asynchronously
    setTimeout(async () => {
      await Analytics.waitUntilAmplitudeIsReady();
      Analytics.track({
        name,
        props: getProps(props),
      });
    });
  }

  async function trackAsyncEvent({
    name,
    props,
    shouldWaitForInitialization,
  }: ILogAsyncEventParams): Promise<void> {
    await Analytics.trackAsync({
      name,
      props: getProps(props),
      shouldWaitForInitialization,
    });
  }

  return { trackEvent, trackAsyncEvent };
}

/**
 * Creates the path parameter for tracking by replacing the actual entityIds with its name
 * For example the ID of company changes to "companyId" in the pathname
 * encodeURIComponent is used to replaces the encoded dynamicId with staticId in the pathname.
 * If memberId is foo@bar in the pathname it becomes foo%40bar and need to be encoded to be replaced with memberId
 */
export function createPathForTracking(
  originalPathname: string,
  params: Readonly<Partial<RouteParams>>
): string {
  try {
    let replacedPath = originalPathname;
    for (const [paramName, paramValue] of Object.entries(params)) {
      if (allEntityIds.includes(paramName as keyof RouteParams)) {
        // Replaces the encoded dynamicId with staticId in the pathname.
        // This is used as some characters are encoded in the pathname, e.g. foo@bar -> foo%40bar
        replacedPath = replacedPath.replace(
          encodeURIComponent(paramValue),
          paramName
        );
      }
    }
    return replacedPath;
  } catch (error) {
    // If there is an error in the function, log the error and return an empty string
    // Therefore the app doesn't crash and no sensitive data like project id is sent to the analytic service
    // eslint-disable-next-line no-console
    console.error("Error in createPathForTracking", error);
    return "";
  }
}
