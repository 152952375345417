import { Box, SvgIcon } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { getSvgColoredIconCss } from "@styles/common-styles";
import { ElementType } from "react";

interface Props {
  /** The text to show on the right side */
  text: string;

  /** The icon to show on the left side */
  icon: ElementType;
}

/**
 * Shows a single line in the user more info menu.
 * For example used to show the role of the user.
 */
export function UserMoreInfoMenuItem({ text, icon }: Props): JSX.Element {
  return (
    <Box
      component="div"
      sx={{
        height: "40px",
        display: "flex",
        alignItems: "center",
      }}
    >
      {/* Wrapper for icon */}
      <Box
        component="div"
        sx={{
          width: "42px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SvgIcon
          inheritViewBox
          component={icon}
          sx={{
            ...getSvgColoredIconCss(sphereColors.gray600),
            height: "18px",
            width: "18px",
          }}
        />
      </Box>

      <Box
        component="div"
        sx={{
          fontSize: "12px",
          color: sphereColors.gray600,
        }}
      >
        {text}
      </Box>
    </Box>
  );
}
