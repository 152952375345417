import { Box } from "@mui/material";
import { PropsWithChildren } from "react";

/**
 * Container for the action buttons to take care of the spacing.
 */
export function ActionButtonContainer({
  children,
}: PropsWithChildren): JSX.Element {
  return (
    <Box
      component="div"
      sx={{
        mx: "1.5px",
      }}
    >
      {children}
    </Box>
  );
}
