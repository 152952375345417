import { Box } from "@mui/material";
import { withEllipsis } from "@styles/common-styles";
import { DASH } from "@utils/ui-utils";

interface Props {
  /** Progress value that represents the completion of the background task */
  taskProgress?: string;
}

/** Renders the progress value of the background task */
export function BackgroundTaskProgress({ taskProgress }: Props): JSX.Element {
  const progress = taskProgress ? taskProgress : DASH;

  return (
    <Box
      sx={{
        ...withEllipsis,
      }}
    >
      {progress}
    </Box>
  );
}
