import { isMarkupOverdue } from "@utils/markups-utils";
import { useMemo, useState } from "react";
import { useUpdateDueDate } from "@pages/project-details/project-markups/due-date/markup-due-date-utils";
import { sphereColors } from "@styles/common-colors";
import { FaroTextButtonDatePicker } from "@components/common/faro-text-button-date-picker/faro-text-button-date-picker";
import { useMarkupContext } from "@context-providers/markup/markup-context";
import { MouseEvent } from "react";
import {
  BaseMarkupColumnProps,
  Markup,
} from "@custom-types/project-markups-types";
import { useAppSelector } from "@store/store-helper";
import { markupsTemplateIdsSelector } from "@store/markups/markups-selector";
import { assert } from "@faro-lotv/foundation";
import { cloneDeep, findIndex } from "lodash";
import { IElementDateTimeMarkupField } from "@faro-lotv/ielement-types";
import { isMutationDateTimeMarkupField } from "@pages/project-details/project-markups/markup-guards";
import { currentUserSelector } from "@store/user/user-selector";

/** Renders the markup due date */
export function MarkupDueDate({
  markup,
  isSidePanelOpen = false,
}: BaseMarkupColumnProps): JSX.Element {
  const templateIds = useAppSelector(markupsTemplateIdsSelector);
  const currentUser = useAppSelector(currentUserSelector);

  assert(templateIds, "Expected project to have an advanced markup template");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {
    projectId,
    markups,
    setIsMarkupUpdating,
    updateSelectedMarkupId,
    hasPermissionToEditMarkup,
    updateMarkups,
  } = useMarkupContext();
  const { onDueDateChange, removeDueDate } = useUpdateDueDate({ projectId });

  const markupDate = useMemo(() => {
    return markup.dueDate ? new Date(markup.dueDate.value) : null;
  }, [markup.dueDate]);

  /** Update markups on context */
  function processMarkupChanges(newMarkups: Markup[]): void {
    updateMarkups(newMarkups);

    setIsLoading(false);
    setIsMarkupUpdating(false);
  }

  /** Call the appropriate function depending whether the date is removed or changed */
  async function onDateConfirmed(updatedDate: Date | null): Promise<void> {
    if (!templateIds) {
      return;
    }

    closeDueDate();
    setIsLoading(true);
    setIsMarkupUpdating(true);

    const newMarkups = cloneDeep(markups);
    const markupIndex = findIndex(newMarkups, { id: markup.id });

    if (updatedDate) {
      const mutation = await onDueDateChange(
        updatedDate,
        markup,
        templateIds.dueDateTemplateId
      );

      if (!mutation || !currentUser) {
        return;
      }

      const currentDueDate = newMarkups[markupIndex].dueDate;

      if ("newElement" in mutation) {
        const newElement = mutation.newElement;

        const newItem: IElementDateTimeMarkupField = {
          ...newElement,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          root_Id: newElement.rootId,
          createdBy: currentUser.identity,
          createdAt: mutation.createdAt,
          modifiedBy: currentUser.identity,
          modifiedAt: mutation.createdAt,
        };

        newMarkups[markupIndex].dueDate = newItem;

        processMarkupChanges(newMarkups);

        return;
      }

      if (!currentDueDate) {
        throw new Error("Due date is required but was undefined.");
      }

      if (isMutationDateTimeMarkupField(mutation)) {
        const newDueDate: IElementDateTimeMarkupField = {
          ...currentDueDate,
          value: mutation.dateTime,
        };

        newMarkups[markupIndex].dueDate = newDueDate;

        processMarkupChanges(newMarkups);
      }
    } else {
      await removeDueDate(markup);

      newMarkups[markupIndex].dueDate = undefined;
      processMarkupChanges(newMarkups);
    }
  }

  /**
   * It will close the date picker and remove the selected markup id
   */
  function closeDueDate(): void {
    setAnchorEl(null);
    updateSelectedMarkupId(undefined);
  }

  /**
   * Updates the anchor element and sets the selected markup ID.
   *
   * @param event - The mouse event triggered by clicking the button.
   */
  function updateAnchorEl(event: MouseEvent<HTMLButtonElement>): void {
    setAnchorEl(event.currentTarget);
    updateSelectedMarkupId(markup.id);
  }

  return (
    <FaroTextButtonDatePicker
      isDisabled={!hasPermissionToEditMarkup}
      isLoading={isLoading}
      isClearable={true}
      anchorEl={anchorEl}
      closeDatePicker={closeDueDate}
      onDateConfirmed={onDateConfirmed}
      updateAnchorEl={updateAnchorEl}
      value={markupDate}
      buttonSx={{
        color: isMarkupOverdue(markup) ? sphereColors.red600 : "inherit",
        fontWeight: isMarkupOverdue(markup) ? "600" : "normal",
        pl: isSidePanelOpen ? "1px" : "8px",
        pr: "8px",
      }}
    />
  );
}
