import { isProjectDataType } from "@custom-types/project-data-type-guards";
import { ProjectDataType } from "@custom-types/project-data-types";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { Box } from "@mui/material";
import { PointClouds } from "@pages/project-details/project-data/point-clouds/point-clouds";
import { QueryParams } from "@router/route-params";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ProjectDataBar } from "@pages/project-details/project-data/project-data-bar";
import ComingSoonIcon from "@assets/icons/new/coming-soon_60px.svg?react";
import { EmptyPage } from "@components/common/empty-page/empty-page";

/** Renders the project data tab */
export function ProjectData({ projectId }: BaseProjectIdProps): JSX.Element {
  const [searchParams] = useSearchParams();
  const dataTypeParam = searchParams.get(QueryParams.type);

  /** Initial data type to display */
  const initialDataType = isProjectDataType(dataTypeParam)
    ? dataTypeParam
    : ProjectDataType.pointClouds;

  /** Selected data type state */
  const [selectedDataType, setSelectedDataType] =
    useState<ProjectDataType>(initialDataType);

  /** Object with each content to display for a given data type */
  const dataContent: { [key in ProjectDataType]: JSX.Element } = {
    [ProjectDataType.pointClouds]: <PointClouds projectId={projectId} />,
    [ProjectDataType.sheets]: (
      // TODO: Show lists of sheets, https://faro01.atlassian.net/browse/ST-1607
      <EmptyPage
        title="Coming soon"
        subtitle="This page is still under development and will be available soon"
        icon={ComingSoonIcon}
      />
    ),
    [ProjectDataType.video]: (
      // TODO: Show lists of videos, https://faro01.atlassian.net/browse/ST-1439
      <EmptyPage
        title="Coming soon"
        subtitle="This page is still under development and will be available soon"
        icon={ComingSoonIcon}
      />
    ),
  };

  return (
    <Box
      data-testid="project-data-container"
      sx={{
        marginTop: "14px",
      }}
    >
      <ProjectDataBar
        selectedDataType={selectedDataType}
        setSelectedDataType={setSelectedDataType}
      />
      {dataContent[selectedDataType]}
    </Box>
  );
}
