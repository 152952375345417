import { useGetBulkDialogContent } from "@components/common/faro-table/bulk-dialog/bulk-dialog-helper";
import { BulkDialogs } from "@components/common/faro-table/bulk-dialog/bulk-dialog-types";
import { ProjectArchivingState } from "@custom-types/project-types";
import {
  bulkActionResultsSelector,
  numberOfUpdatingItemsSelector,
} from "@store/table/table-selector";
import { useAppSelector } from "@store/store-helper";
import { nounPluralize } from "@utils/data-display";

/** Determines the content of bulk dialog and bulk success dialog for changing project status */
export function useChangeProjectStatusBulkDialogContent(
  /** The archiving status of the project */
  projectStatus: ProjectArchivingState
): BulkDialogs {
  const numberOfUpdatingItems = useAppSelector(numberOfUpdatingItemsSelector);
  const { numberOfSuccess, numberOfErrors } = useAppSelector(
    bulkActionResultsSelector
  );

  const bulkDialogContent = useGetBulkDialogContent({
    initial: {
      title: `${
        projectStatus === ProjectArchivingState.archived
          ? "Unarchive"
          : "Archive"
      } these projects?`,
      description:
        projectStatus === ProjectArchivingState.archived
          ? "Unarchiving these projects will make them accessible to users with appropriate permissions"
          : "Archiving these projects will exclude them from some users, only members with permissions can access them",
    },

    failed: {
      title: `Couldn't ${
        projectStatus === ProjectArchivingState.archived
          ? "unarchive"
          : "archive"
      } ${numberOfErrors} of ${numberOfUpdatingItems} projects`,
      description:
        "The status of these projects couldn't be changed, check the details and try again.",
    },
    confirmButtonText:
      projectStatus === ProjectArchivingState.archived
        ? "unarchive"
        : "archive",
    entityType: "project",
  });

  let successDialogContent = {
    title: `${nounPluralize({
      counter: numberOfSuccess,
      word: "project",
    })} archived`,
    description:
      "All selected projects have been archived and you can unarchive them at any time",
  };

  if (projectStatus === ProjectArchivingState.archived) {
    successDialogContent = {
      title: `${nounPluralize({
        counter: numberOfSuccess,
        word: "project",
      })} unarchived`,
      description:
        "All selected projects have been unarchived and you can archive them at any time",
    };
  }

  return { bulkDialogContent, successDialogContent };
}
