import { Box } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { FaroChip } from "@faro-lotv/flat-ui";

interface AddOnsExtensionFooterProps {
  /** Date from extensions */
  hasNewChip: boolean;

  /** Add-on is active or not */
  isActive?: boolean;
}

/** Renders the extension footer component */
export function AddOnsExtensionFooter({
  hasNewChip,
  isActive,
}: AddOnsExtensionFooterProps): JSX.Element {
  return (
    <Box sx={{ display: "flex", gap: "8px", mt: "36px" }}>
      {hasNewChip && (
        <FaroChip
          color={sphereColors.pureWhite}
          backgroundColor={sphereColors.orange500}
          label="NEW"
        />
      )}
      {isActive && (
        <FaroChip
          color={sphereColors.blue500}
          backgroundColor={sphereColors.blue100}
          label="ACTIVE"
        />
      )}
    </Box>
  );
}
