import { CoreAPITypes } from "@stellar/api-logic";
import {
  splashScreenList,
  SplashScreenSdbTopic,
} from "@components/splash-screen/splash-screen-data";
import { ElementType } from "react";
import { SdbCompany } from "@custom-types/sdb-company-types";

/** The content for a single splash screen */
export interface SplashScreenContent {
  /** The title of the splash screen */
  title: string;

  /** The description of the splash screen */
  description: React.ReactNode;

  /** Properties of the image added in splash screen */
  image: {
    /**
     * The image src of the splash screen
     * It is either a string (for *.png) or an ElementType (for *.svg)
     */
    src: string | ElementType;

    /** The alternative text conveying what the image is */
    altText: string;
  };
}

/** The props for the splash screen */
export interface SplashScreenProps {
  /** The topic of the splash screen */
  topic: SplashScreenSdbTopic;

  /** Minimum role of company to see the splash screen */
  allowedRoles: CoreAPITypes.EUserCompanyRole[];

  /** The content for the splash screen */
  content: SplashScreenContent[];

  /**
   * Callback to check if the splashscreen should be excluded from the provided company.
   * Provides the company as a parameter and if it returns true, the splash screen will be excluded.
   */
  shouldExclude?: (company: SdbCompany | null) => boolean;
}

/** All the possible view status of a splash screen topic */
export type SplashScreenViewStatus =
  /** When user has not seen the splash screen yet */
  | "unseen"

  /** When user has skipped the splash screen by closing it */
  | "skipped"

  /** When user has completed the splash screen */
  | "completed";

interface ShouldShowSplashScreenPayload {
  /** The name of the splashscreen */
  topic: SplashScreenSdbTopic;

  /** The company role for the user */
  userRole: CoreAPITypes.EUserCompanyRole;

  /** The current selected company */
  company: SdbCompany | null;
}

/** Determine whether to show the splash screen to user or not */
export function shouldShowSplashScreen({
  topic,
  userRole,
  company,
}: ShouldShowSplashScreenPayload): boolean {
  const splashScreen = splashScreenList[topic];
  if (splashScreen.shouldExclude && splashScreen.shouldExclude(company)) {
    return false;
  }
  return splashScreen.allowedRoles.includes(userRole);
}
