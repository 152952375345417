import { useNavigate } from "react-router";
import {
  generateGroupDetailsRoute,
  generateProjectDetailsRoute,
  generateProjectsRoute,
  generateMemberProfileRoute,
  generateGroupsRoute,
} from "@router/router-helper";
import { APITypes } from "@stellar/api-logic";
import { ProjectArchivingState } from "@custom-types/project-types";
import {
  BaseCompanyIdProps,
  BaseGroupIdProps,
  BaseProjectIdProps,
} from "@custom-types/sdb-company-types";
import { MemberTabs, QueryParams } from "@router/route-params";
import { useLocation } from "react-router-dom";

interface NavigateToProjects extends BaseCompanyIdProps {
  /** The archiving state of the selected project */
  projectStatus?: ProjectArchivingState;
}

type NavigateToProjectDetail = BaseCompanyIdProps & BaseProjectIdProps;

type NavigateToGroupDetails = BaseCompanyIdProps & BaseGroupIdProps;

interface NavigateToMemberProfile extends BaseCompanyIdProps {
  /** The ID of the selected member */
  memberId: APITypes.UserId;

  /** The tab to be selected in the member profile page */
  memberTabs?: MemberTabs;
}

interface SetUrlParamProps {
  /** They name of the query parameter to be set */
  key: QueryParams;

  /** The value of the query parameter to be set */
  value: string | null;
}

export interface UseAppNavigation {
  /** navigates to the root of the app which is the workspace selection page. */
  navigateToRoot(): void;

  /** Navigates to the projects */
  navigateToProjects({ companyId, projectStatus }: NavigateToProjects): void;

  /** navigates to the details of a single project */
  navigateToProjectDetail({
    companyId,
    projectId,
  }: NavigateToProjectDetail): void;

  /** navigates to the groups page */
  navigateToGroups({ companyId }: BaseCompanyIdProps): void;

  /** navigates to the details of a single group */
  navigateToGroupDetails({ companyId, groupId }: NavigateToGroupDetails): void;

  /** navigates to the details of a single member */
  navigateToMemberProfile({
    companyId,
    memberId,
  }: NavigateToMemberProfile): void;

  /** Sets a query parameter in the URL */
  setUrlParam({ key, value }: SetUrlParamProps): void;

  /** Gets a query parameter from the URL */
  getQueryParam(key: QueryParams): string | null;
}

/**
 * A hook that gathers all the navigation and routing in the app
 */
export function useAppNavigation(): UseAppNavigation {
  const navigate = useNavigate();
  const location = useLocation();

  function navigateToRoot(): void {
    navigate("/");
  }

  function navigateToProjects({
    companyId,
    projectStatus,
  }: NavigateToProjects): void {
    navigate(generateProjectsRoute(companyId, projectStatus));
  }

  function navigateToProjectDetail({
    companyId,
    projectId,
  }: NavigateToProjectDetail): void {
    navigate(generateProjectDetailsRoute(companyId, projectId));
  }

  function navigateToGroups({ companyId }: BaseCompanyIdProps): void {
    navigate(generateGroupsRoute(companyId));
  }

  function navigateToGroupDetails({
    companyId,
    groupId,
  }: NavigateToGroupDetails): void {
    navigate(generateGroupDetailsRoute(companyId, groupId));
  }

  function navigateToMemberProfile({
    companyId,
    memberId,
    memberTabs,
  }: NavigateToMemberProfile): void {
    navigate(generateMemberProfileRoute(companyId, memberId, memberTabs));
  }

  /**
   * Sets a query parameter in the URL
   */
  function setUrlParam({ key, value }: SetUrlParamProps): void {
    const searchParams = new URLSearchParams(location.search);
    if (value === null) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, value);
    }

    navigate(`${location.pathname}?${searchParams.toString()}`);
  }

  /**
   * Returns the value of a query parameter from the URL
   */
  function getQueryParam(key: QueryParams): string | null {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(key);
  }

  return {
    navigateToRoot,
    navigateToProjects,
    navigateToProjectDetail,
    navigateToGroups,
    navigateToGroupDetails,
    navigateToMemberProfile,
    setUrlParam,
    getQueryParam,
  };
}
