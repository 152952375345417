import { DataDisplayActions } from "@components/common/data-display-actions";
import { TabsWithRoutes } from "@components/common/tabs-with-routes";
import { AuthenticatedRoute } from "@router/authenticated-route";
import { useAppParams } from "@router/router-helper";
import { ProjectArchivingState } from "@custom-types/project-types";
import { NotFoundPage } from "@pages/not-found-page";
import { ProjectsOverview } from "@pages/projects/projects-overview";
import { CreateProjectDialog } from "@pages/projects/create-project-dialog";
import {
  fetchingProjectsFlagsSelector,
  projectsSelector,
  selectedProjectSelector,
} from "@store/projects/projects-selector";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { OpenTabEvents } from "@utils/track-event/track-event-list";
import { isProjectDemo } from "@utils/project-utils";
import { ProjectsOverviewDemoEmptyPage } from "@components/common/empty-page/projects-overview-demo-empty-page";
import { useEffect, useMemo } from "react";
import { resetProjectsState } from "@store/projects/projects-slice";
import { fetchProjects } from "@store/projects/projects-slice-thunk";
import { useCoreApiClient } from "@api/use-core-api-client";
import { isProjectArchivingState } from "@custom-types/type-guards";
import { APITypes } from "@stellar/api-logic";
import { clearStore } from "@faro-lotv/project-source";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";
import { selectedCompanyAvailableFeatureSelector } from "@store/sdb-company/sdb-company-selector";

/**
 * Gets the content to be shown in the active projects tab, depending on the loading state
 */
function activeProjectsContent(options: {
  shouldForceLoading: boolean;
}): JSX.Element {
  return (
    <AuthenticatedRoute key={"active-projects"}>
      <ProjectsOverview
        projectArchivingState={ProjectArchivingState.active}
        shouldForceLoading={options.shouldForceLoading}
      />
    </AuthenticatedRoute>
  );
}

/**
 * Gets the content to be shown in the archived projects tab, depending on the loading state
 */
function archivedProjectsContent(options: {
  shouldForceLoading: boolean;
}): JSX.Element {
  return (
    <AuthenticatedRoute key={"archived-projects"}>
      <ProjectsOverview
        projectArchivingState={ProjectArchivingState.archived}
        shouldForceLoading={options.shouldForceLoading}
      />
    </AuthenticatedRoute>
  );
}

/**
 * Content in the projects page
 */
export function ProjectsPage(): JSX.Element {
  const { projectStatus, companyId } = useAppParams();
  const selectedProject = useAppSelector(selectedProjectSelector);
  const { isFetchingProjects } = useAppSelector(fetchingProjectsFlagsSelector);
  const projects = useAppSelector(projectsSelector);
  const { canCreateProjects } = useHasUserValidRoleCompanyLevel();
  const coreApiClient = useCoreApiClient();
  const dispatch = useAppDispatch();
  const hasCreateProjectsFeature = useAppSelector(
    selectedCompanyAvailableFeatureSelector(
      APITypes.EUserSubscriptionRole.projectCreate
    )
  );

  /** Flag whether the user has the permission to create projects and the subscription has the feature enabled */
  const shouldCreateProjects = useMemo(() => {
    return canCreateProjects && hasCreateProjectsFeature;
  }, [canCreateProjects, hasCreateProjectsFeature]);

  /**
   * This useEffect is used to reset the state whenever the user leaves a project.
   * When we chose a project and then chose another. The selectedProject field contained the old selected
   * project and only after a few re-renders did it have the correct project ID.
   */
  useEffect(() => {
    dispatch(resetProjectsState());
    dispatch(clearStore());
  }, [dispatch]);

  const shouldShowDemoEmptyPage = useMemo(() => {
    if (!isFetchingProjects && projects.length === 1) {
      const project = projects[0];

      return (
        // Only show the "Demo empty page" screen if the user is in the active tab and the demo project is active
        projectStatus === ProjectArchivingState.active &&
        isProjectDemo(project) &&
        project.archivingState === APITypes.ArchivingState.UNARCHIVED
      );
    }

    return false;
  }, [isFetchingProjects, projectStatus, projects]);

  // Fetches both active and archived projects
  useEffect(() => {
    // Validate the project status route param before triggering the requests
    // this will also make that each change in the project status value will fetch projects again
    if (companyId && isProjectArchivingState(projectStatus)) {
      // Separate requests are done so we can show 30 projects for each archiving status.
      dispatch(
        fetchProjects({
          coreApiClient,
          companyId,
          projectArchivingState: ProjectArchivingState.active,
        })
      );
      dispatch(
        fetchProjects({
          coreApiClient,
          companyId,
          projectArchivingState: ProjectArchivingState.archived,
        })
      );
    }
  }, [companyId, coreApiClient, dispatch, projectStatus]);

  if (!isProjectArchivingState(projectStatus) || companyId === undefined) {
    return <NotFoundPage />;
  }

  if (shouldShowDemoEmptyPage) {
    return (
      <ProjectsOverviewDemoEmptyPage
        demoProject={projects[0]}
        companyId={companyId}
      />
    );
  }

  return (
    <TabsWithRoutes
      selectedTab={projectStatus}
      dataTestId="PROJECTS_CONTAINER"
      selectedProject={selectedProject}
      requiredAttribute={false}
      trackingEventPageName={OpenTabEvents.openProjectsTab}
      tabs={[
        {
          label: "Active",
          route: ProjectArchivingState.active,
          content: () => activeProjectsContent({ shouldForceLoading: false }),
          loadingContent: activeProjectsContent({ shouldForceLoading: true }),
          actionButtons: (
            <>
              <DataDisplayActions subjectType="project" />
              {/* Create new project button */}
              {shouldCreateProjects && <CreateProjectDialog />}
            </>
          ),
        },
        {
          label: "Archived",
          route: ProjectArchivingState.archived,
          content: () => archivedProjectsContent({ shouldForceLoading: false }),
          loadingContent: archivedProjectsContent({ shouldForceLoading: true }),
          actionButtons: (
            <>
              <DataDisplayActions subjectType="project" />
              {/* Create new project button */}
              {shouldCreateProjects && <CreateProjectDialog />}
            </>
          ),
        },
      ]}
    />
  );
}
