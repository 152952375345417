import { PageSection, PageSectionProps } from "@components/page/page-section";
import { useMediaQueryList } from "@hooks/use-media-query";
import { Grid } from "@mui/material";

interface Props {
  /** The page section props */
  pageSectionProps: PageSectionProps[];

  /**
   * Whether the page is loading
   *
   * @default false
   */
  isLoading?: boolean;

  /** The data-test-ID used for integration testing */
  dataTestId?: string;
}

/** The base settings page component that handles very basic styling and UI for the given PageSectionProps */
export function BaseSettingsPage({
  pageSectionProps,
  isLoading = false,
  dataTestId,
}: Props): JSX.Element {
  const { isScreenXsAndSmall } = useMediaQueryList();

  return (
    <Grid data-testid={dataTestId} mt="45px">
      {pageSectionProps.map((sectionProps, index) => (
        <PageSection
          key={`settings-section-wrapper-${index}`}
          isLoading={isLoading}
          isScreenXsAndSmall={isScreenXsAndSmall}
          isTopSection={index === 0}
          isLastSection={index === pageSectionProps.length - 1}
          {...sectionProps}
        />
      ))}
    </Grid>
  );
}
