import { Box, Skeleton } from "@mui/material";
import { DASH } from "@utils/ui-utils";
import { useMemo, useState } from "react";
import Plus from "@assets/icons/Plus.svg?react";
import { ProjectDescriptionModal } from "@pages/project-details/project-overview/project-description-modal";
import { sphereColors } from "@styles/common-colors";
import { FaroIconButton } from "@components/common/faro-icon-button";
import EditIcon from "@assets/icons/Edit.svg?react";
import { SdbProject } from "@custom-types/project-types";
import { LabelWithHelp } from "@components/common/label-with-help";

const MAX_DESCRIPTION_LENGTH = 185;

interface Props {
  /** Whether the project is editable or not */
  isProjectEditable: boolean;

  /** The project to display in the ProjectOverview */
  project?: SdbProject | null;

  /** Whether the project is still loading and skeleton should be shown instead */
  isLoading: boolean;
}

/** CSS class for the div that contains the edit button that it is hidden by default. */
const EDIT_ICON_WRAPPER_CLASS = "editIcon";

/**
 * Shows the project description field
 * It is displayed below the secondary project details
 */
export function ProjectDescription({
  project,
  isProjectEditable,
  isLoading,
}: Props): JSX.Element {
  const [shouldShowDialog, setShouldShowDialog] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  /**
   * Text to be shown in the description field.
   * Either a label to "Add a description" or the description
   * cut to only show the maximum allowed characters.
   */
  const descriptionText: string = useMemo(() => {
    if (
      isProjectEditable &&
      (project?.description === DASH || !project?.description)
    ) {
      return "Add description";
    }
    return project?.description?.slice(0, MAX_DESCRIPTION_LENGTH) ?? DASH;
  }, [project?.description, isProjectEditable]);

  /**
   * Flag whether the description already reached the maximum characters allowed
   * to be shown therefore it is cut in the UI
   */
  const hasDescriptionMaxLength: boolean = useMemo(() => {
    return (project?.description ?? "").length > MAX_DESCRIPTION_LENGTH;
  }, [project?.description]);

  if (isLoading || !project) {
    return <Skeleton variant="rectangular" height="46px" />;
  }

  return (
    <>
      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box component="div" width="100%">
          <LabelWithHelp
            title="Description"
            sx={{
              color: sphereColors.gray500,
              fontWeight: "normal",
              fontSize: "11px",
            }}
          />
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              wordBreak: "break-word",
              "&:hover": {
                [`& .${EDIT_ICON_WRAPPER_CLASS}`]: {
                  visibility: "visible",
                },
              },
            }}
          >
            <Box
              component="div"
              onMouseOver={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={() => {
                // If the project is not editable and the length of the description still fits
                // in the box, do not show the modal dialog.
                if (!isProjectEditable && !hasDescriptionMaxLength) {
                  return;
                }
                (project.description || isProjectEditable) &&
                  setShouldShowDialog(true);
              }}
              sx={{
                // If the project is not editable and the length of the description still fits
                // in the box, do not show the cursor as pointer because the modal dialog won't be open.
                cursor:
                  isProjectEditable || hasDescriptionMaxLength
                    ? "pointer"
                    : "text",
                color: sphereColors.gray800,
                fontSize: "14px",
                textAlign: "justify",
                width: "100%",
              }}
            >
              <var>{descriptionText}</var>
              {hasDescriptionMaxLength && (
                <Box
                  component="span"
                  sx={{
                    textTransform: "none",
                    textDecoration: isHovered ? "underline" : "none",
                    paddingLeft: "5px",
                    fontWeight: "bold",
                    color: sphereColors.blue500,
                  }}
                  onClick={() => setShouldShowDialog(true)}
                >
                  Show more
                </Box>
              )}
            </Box>
            {project.description !== DASH && isProjectEditable && (
              <Box
                component="div"
                className={EDIT_ICON_WRAPPER_CLASS}
                sx={{
                  // This property will be overridden by the parent component on hover
                  visibility: "hidden",
                  marginLeft: "10px",
                }}
              >
                <FaroIconButton
                  component={EditIcon}
                  buttonSize="24px"
                  iconSize="16px"
                  color={sphereColors.gray400}
                  onClick={() => setShouldShowDialog(true)}
                />
              </Box>
            )}
          </Box>
        </Box>
        {project.description === DASH && isProjectEditable && (
          <Box component="div">
            <FaroIconButton
              color={isHovered ? sphereColors.blue500 : undefined}
              iconButtonProps={{
                sx: {
                  // Color the background when there's a hover on other sides of the description.
                  backgroundColor: isHovered ? sphereColors.gray100 : "none",
                },
              }}
              component={Plus}
              onClick={() => setShouldShowDialog(true)}
            />
          </Box>
        )}
      </Box>

      <ProjectDescriptionModal
        project={project}
        isProjectEditable={isProjectEditable}
        shouldShowDialog={shouldShowDialog}
        setShouldShowDialog={setShouldShowDialog}
      />
    </>
  );
}
