import { IReactChildrenOnly } from "@custom-types/types";
import { Box } from "@mui/material";

/** Creates a very simple container to place the footer content inside */
export function FaroTableFooterContainer({
  children,
}: IReactChildrenOnly): JSX.Element {
  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        marginTop: "20px",
        fontSize: "12px",
      }}
    >
      {children}
    </Box>
  );
}
