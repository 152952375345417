import { ReactNode } from "react";
import { validate as isValidUUID } from "uuid";

/**
 * Capitalizes the first letter of a string.
 *
 * @param input The string to have its first letter capitalized.
 * @returns New string with first letter in locale upper case.
 */
export function capitalizeFirstLetter(
  input: string | undefined | null
): string {
  if (!input) {
    // Return empty string if input is empty
    return "";
  }

  const firstLetter = input[0].toLocaleUpperCase();
  const remainingLetters = input.slice(1);

  return firstLetter + remainingLetters;
}

/**
 * Removes all extra spaces from a string to have maximum one space and trims the text.
 * E.g. From " Test   Method " the three spaces are combined and trimmed into "Test Method"
 *
 * @param str The string to remove its spaces.
 * @returns New string with removed spaces.
 */
export function removeExtraSpaces(str: string | undefined | null): string {
  return (str ?? "").replace(/ +(?= )/g, "").trim();
}

interface ShortenTextProps {
  /**
   * Value to be shortened.
   */
  value?: string | undefined | null;
  /**
   * Maximum characters that the string should have including the ellipsis.
   */
  maxChars: number;
}

/**
 * Shortens the text for an specific string and adds ellipsis at the end if needed.
 */
export function shortenText({ value, maxChars }: ShortenTextProps): string {
  if (value === undefined || value === null) {
    return "";
  }
  if (value.length <= maxChars) {
    return value;
  }
  const ellipsis = "...";
  if (maxChars <= ellipsis.length) {
    return ellipsis.slice(0, maxChars);
  }
  const end = maxChars - ellipsis.length;
  return `${value.slice(0, end)}${ellipsis}`;
}

/**
 * Highlights a subsection of some text, by adding the <strong> tag.
 * The search it is case insensitive, but the result is kept like the original.
 * E.g.
 * Text: "Hello World" Search "wo" -> "Hello <strong>Wo</strong>rld"
 *
 * @param mainString String to look for text.
 * @param searchSubstring Search string that will be use as case insensitive.
 * @returns A react node that can be rendered to show the text
 */
export function highlightOccurrences(
  mainString: string,
  searchSubstring: string
): ReactNode[] {
  if (!mainString || !searchSubstring) {
    return [mainString];
  }
  const lowerMainString = mainString.toLowerCase();
  const lowerSearchSubstring = searchSubstring.toLowerCase();

  // Avoid iterating over every character if it does not contain the search text anyway.
  if (lowerMainString.indexOf(lowerSearchSubstring) === -1) {
    return [mainString];
  }

  const finish: ReactNode[] = [];
  for (let index = 0; index <= mainString.length; index++) {
    const foundIndex = lowerMainString.indexOf(lowerSearchSubstring, index);
    if (foundIndex === index) {
      finish.push(
        <strong>
          {mainString.slice(index, index + searchSubstring.length)}
        </strong>
      );
      index += searchSubstring.length - 1;
    } else {
      finish.push(mainString[index]);
    }
  }
  return finish;
}

/**
 * @returns True iff the passed value is a valid MongoDB ObjectId: IDs of 24 bytes(digits) hex-string:
 * https://mongodb.github.io/node-mongodb-native/api-bson-generated/objectid.html#objectid-isvalid
 * @param value string to validate
 */
export function isValidMongoDBObjectId(value: string): boolean {
  const regex = /^[0-9a-fA-F]{24}$/;
  return regex.test(value);
}

/**
 * @returns True iff the passed value is a valid HoloBuilder Legacy ID: IDs of only digits of at least 9 digits length
 * @param value string to validate
 */
export function isValidHBLegacyId(value: string): boolean {
  const regex = /^\d{9,}$/;
  return regex.test(value);
}

/**
 * @returns True iff the passed value is a valid project or company ID.
 * These are the valid project or company ID formats:
 * - UUID
 * - MongoDB ObjectID
 * - Custom HB Legacy ID format
 * @param value string to validate
 */
export function isValidProjectOrCompanyId(value: string): boolean {
  return (
    isValidUUID(value) ||
    isValidMongoDBObjectId(value) ||
    isValidHBLegacyId(value)
  );
}
