import { QueryParams } from "@router/route-params";
import { useAppDispatch } from "@store/store-helper";
import { setIsHideMigratedWorkspacesEnabled } from "@store/ui/ui-slice";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

/**
 * Hook to get the workspace params and set them on the UI.
 */
export function useWorkspaceParams(): void {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const hideMigratedWorkspaces = searchParams.get(
    QueryParams.hideMigratedWorkspaces
  );

  /**
   * Checks if users want to hide migrated workspaces
   * This is normally used internally
   * TODO: Once https://faro01.atlassian.net/browse/ST-1652 is implemented we can remove this.
   */
  useEffect(() => {
    if (!hideMigratedWorkspaces) {
      return;
    }
    dispatch(
      setIsHideMigratedWorkspacesEnabled(
        hideMigratedWorkspaces.toLowerCase() === "true"
      )
    );
  }, [dispatch, hideMigratedWorkspaces]);
}
