import {
  ExternalMarkupIElement,
  IElementAttachment,
  IElementDateTimeMarkupField,
  IElementDropDownMarkupField,
  IElementImageCommand,
  IElementImg2d,
  IElementImg360,
  IElementImgCube,
  IElementMarkup,
  IElementModel3D,
  IElementSection,
  IElementUserDirectoryMarkupField,
} from "@faro-lotv/ielement-types";
import { IElementAreaSection } from "@custom-types/i-elements-types";
/** Interface for a Sphere Dashboard markup entity */
export interface Markup extends IElementMarkup {
  /** The assignee IElement of the markup */
  assignee?: IElementUserDirectoryMarkupField;

  /** The issue due date IElement of the markup */
  dueDate?: IElementDateTimeMarkupField;

  /** The status IElement of the markup */
  status?: IElementDropDownMarkupField;

  /** The 2D image IElement of the markup */
  image?: IElementImg2d | IElementImageCommand;

  /** Parent Model3d IElement of the markup */
  model3d?: IElementModel3D;

  /** Parent section IElement of the markup */
  section?: IElementSection;

  /** Parent area section (floor) IElement of the markup */
  areaSection?: IElementAreaSection;

  /** Panoramic Element related to the markup */
  pano?: IElementImg360 | IElementImgCube;

  /** Associated external markup element (Procore or Bim360) */
  externalMarkup?: ExternalMarkupIElement;

  /** URL to open/share the markup in Sphere Viewer */
  sphereViewerUrl?: string;

  /** URL to open/share the markup in HB WebEditor */
  webEditorUrl?: string;

  /** List of attachments */
  attachments: IElementAttachment[];
}

/** Base props for a Markup entity */
export interface BaseMarkupProps {
  /** Markup entity */
  markup: Markup;
}

/** Base props for the markup table column */
export interface BaseMarkupColumnProps extends BaseMarkupProps {
  /** Check this component is visible in the side panel */
  isSidePanelOpen?: boolean;
}

/** Display text for each markup external integration */
export enum MarkupIntegrationLabel {
  procoreRfi = "Procore RFI",
  procoreObservation = "Procore Observation",
  bim360 = "BIM 360 Issue",
}

/** Markups KPI data */
export interface MarkupsKPIs {
  /** Total number of markups */
  total: number;

  /** Number of markups with status "To Do" */
  toDo: number;

  /** Number of markups with status "In Progress" */
  inProgress: number;

  /** Number of markups with status "In Review" */
  inReview: number;

  /** Number of markups with status "Resolved" */
  resolved: number;

  /** Number of markups without status value */
  unclassified: number;

  /** Number of markups where the "Due date" field is defined and its value is overdue */
  overdue: number;
}
