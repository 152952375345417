import { SphereDashboardAPITypes } from "@stellar/api-logic";

/** Defines the minimum length for the group name */
export const MIN_GROUP_NAME_LENGTH = 1;

/** Returns true if a group should have a demo label. */
export function isGroupDemo(
  group: SphereDashboardAPITypes.ICompanyGroup
): boolean {
  return group.name === "Demo";
}
