import { checkboxClasses, createTheme } from "@mui/material";
import {
  DEFAULT_FONT_FAMILY,
  DEFAULT_TEXT_FONT_SIZE,
  PAGE_BREAKPOINTS,
  textfieldSx,
  inputPlaceholderItalicSx,
} from "@styles/common-styles";
import { sphereColors } from "src/styles/common-colors";

/**
 * Overrides the default Mui theme
 */
export const mainTheme = createTheme({
  /**
   * Sphere viewer is using the Material UI palettes. In short, if we don't have a defined color in the theme,
   * the colors will all appear unconfigured because in the viewer they are directly looking
   * for the color of the UI material. I am using the colors that we are using normally.
   */
  palette: {
    primary: {
      main: sphereColors.blue500,
    },
    secondary: {
      main: sphereColors.gray800,
    },
  },

  typography: {
    fontFamily: DEFAULT_FONT_FAMILY,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: DEFAULT_FONT_FAMILY,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            ...inputPlaceholderItalicSx,
          }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            ...textfieldSx,
          }),
      },
    },

    // Overwrites the default MUI Checkbox styles
    MuiCheckbox: {
      styleOverrides: {
        root: {
          [`&.${checkboxClasses.checked}, &.${checkboxClasses.indeterminate}`]:
            {
              color: sphereColors.blue500,
            },
          "&:hover": {
            color: sphereColors.blue500,
          },
        },
      },
    },

    // Overwrites the default MUI Divider styles
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: sphereColors.gray200,
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          fontweight: "normal",
          fontSize: DEFAULT_TEXT_FONT_SIZE,
          color: sphereColors.gray800,
        },
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginTop: "10px",
          "& .MuiFormControlLabel-label": {
            fontSize: DEFAULT_TEXT_FONT_SIZE,
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: sphereColors.gray50,
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: PAGE_BREAKPOINTS.xs,
      sm: PAGE_BREAKPOINTS.sm,
      md: PAGE_BREAKPOINTS.md,
      lg: PAGE_BREAKPOINTS.lg,
      xl: PAGE_BREAKPOINTS.xl,
    },
  },
});
