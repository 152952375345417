import { Box, Menu } from "@mui/material";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import {
  FaroIconButton,
  FaroIconButtonProps,
} from "@components/common/faro-icon-button";

interface Props {
  /** Properties for the icon button */
  buttonProps: FaroIconButtonProps;

  /** Optional text to show as tooltip for the icon button */
  tooltipText?: string;

  /** Callback when the menu open state changes */
  onIsMenuOpenChanged?: (isMenuOpen: boolean) => void;

  /** Whether the menu is open */
  isMenuOpen: boolean;

  /** Callback to set the menu open state */
  setIsMenuOpen: (isMenuOpen: boolean) => void;
}

/**
 * Renders an icon button that can have a menu.
 */
export function SphereIconButtonMenu({
  buttonProps,
  tooltipText,
  onIsMenuOpenChanged,
  children,
  isMenuOpen,
  setIsMenuOpen,
}: PropsWithChildren<Props>): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (!isMenuOpen) {
      closeMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- If we add closeMenu it will cause an infinite loop
  }, [isMenuOpen, onIsMenuOpenChanged]);

  /**
   * Opens the menu by setting the anchor element, and
   * triggers the callback to notify the parent component.
   */
  function openMenu(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    onIsMenuOpenChanged?.(true);
    setIsMenuOpen(true);
  }

  /**
   * Closes the menu by setting the anchor element to null, and
   * triggers the callback to notify the parent component.
   */
  function closeMenu(
    // We need object because onClose defines it as {}
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent> | object
  ): void {
    if (event && "stopPropagation" in event) {
      event.stopPropagation();
    }
    if (event && "preventDefault" in event) {
      event.preventDefault();
    }
    setAnchorEl(null);
    onIsMenuOpenChanged?.(false);
    setIsMenuOpen(false);
  }

  return (
    <SphereTooltip title={tooltipText} shouldShowTooltip={!isMenuOpen}>
      <>
        <FaroIconButton
          {...buttonProps}
          aria-controls={isMenuOpen ? "faro-text-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={!!isMenuOpen}
          onClick={(event) => openMenu(event)}
        />
        <Menu
          id="faro-text-menu"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={closeMenu}
        >
          <Box component="div" onClick={closeMenu}>
            {children}
          </Box>
        </Menu>
      </>
    </SphereTooltip>
  );
}
