import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { fetchProjectIElements } from "@store/i-elements/i-elements-slice";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { useEffect } from "react";
import {
  fetchIntegrations,
  fetchMarkupAttachmentGroups,
  fetchMarkupAttachments,
  fetchMarkupBranches,
  fetchMarkupTemplates,
  fetchPanos,
} from "@pages/project-details/project-markups/project-markups-utils";
import { fetchSlideNodes } from "@store/slide-nodes/slide-nodes-slice";
import { markupsSelector } from "@store/markups/markups-selector";
import { Markup } from "@custom-types/project-markups-types";
import { selectIsProjectLoading } from "@store/i-elements/i-elements-selectors";
import { hasFetchedMarkupsIElementsSelector } from "@store/sdb-i-elements/sdb-i-elements-selectors";
import { setHasFetchedMarkupsIElements } from "@store/sdb-i-elements/sdb-i-elements-slice";

interface UseProjectMarkupsReturn {
  /** The array of fetched markup entities */
  markups: Markup[];

  /** Whether the project markups are being fetched from the backend */
  isFetchingMarkups: boolean;
}

/**
 * Custom hook that takes care of fetching all markup elements and other associated elements
 *
 * Since a project can have thousands of elements, fetching all of them is inefficient.
 * We will fetch only the elements we need to keep the store as lightweight as possible.
 */
export function useProjectMarkups({
  projectId,
}: BaseProjectIdProps): UseProjectMarkupsReturn {
  const dispatch = useAppDispatch();
  const projectApiClient = useProjectApiClient({
    projectId,
  });
  const markups = useAppSelector(markupsSelector);

  const isFetchingMarkups = useAppSelector(selectIsProjectLoading);
  const hasFetchedMarkupsIElements = useAppSelector(
    hasFetchedMarkupsIElementsSelector
  );

  useEffect(() => {
    if (hasFetchedMarkupsIElements) {
      return;
    }

    dispatch(
      fetchProjectIElements({
        fetcher: async () => {
          /** Fetch markup branches */
          const elements = await fetchMarkupBranches(projectApiClient);

          if (!elements.length) {
            dispatch(setHasFetchedMarkupsIElements(true));
            return [];
          }

          /**
           * Fetch additional elements required that are not part of the markup branches:
           * - panoramic (panos), integration (Procore & Bim360), markup attachment
          // and markup attachment group elements associated to the markups
           * - Also download all slide nodes associated to markups. They are used to form the markup WebEditor link
           */
          const [
            panos,
            integrations,
            templates,
            attachments,
            attachmentGroups,
          ] = await Promise.all([
            fetchPanos({ projectApiClient, elements }),
            fetchIntegrations({ projectApiClient, elements }),
            fetchMarkupAttachments(projectApiClient),
            fetchMarkupAttachmentGroups(projectApiClient),
            fetchMarkupTemplates(projectApiClient),
            dispatch(fetchSlideNodes({ projectId, elements })).unwrap(),
          ]);

          dispatch(setHasFetchedMarkupsIElements(true));

          return [
            ...elements,
            ...templates,
            ...panos,
            ...integrations,
            ...attachments,
            ...attachmentGroups,
          ];
        },
      })
    );
  }, [dispatch, projectApiClient, projectId, hasFetchedMarkupsIElements]);

  return {
    markups,
    isFetchingMarkups,
  };
}
