/** All the possible headers for the member group projects table */
export enum MemberGroupProjectsHeaders {
  /** Project thumbnail image */
  thumbnail = "Thumbnail",

  /** Project name */
  name = "Name",

  /** Project role */
  role = "Project role",

  /** Any other possible options */
  options = "Options",
}
