import { SphereTooltip } from "@components/common/sphere-tooltip";
import { SxProps } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { withEllipsis } from "@styles/common-styles";
import { useMemo } from "react";

interface Props {
  /** Text to display */
  text?: string;

  /** Style to pass to the component */
  sx?: SxProps;

  /** Class name to pass to the component */
  className?: string;
}

/**
 * Renders a simple table text cell with a tooltip.
 * If the passed text string is empty it then shows a dash char and disables the tooltip
 */
export function FaroTableTextCell({ text, sx, className }: Props): JSX.Element {
  const isTextDefined = useMemo(() => {
    return typeof text === "string" && text?.length > 0;
  }, [text]);

  const label = useMemo(() => {
    return text && text.length > 0 ? text : "-";
  }, [text]);

  return (
    <SphereTooltip
      title={<var>{text}</var>}
      component="var"
      shouldShowTooltip={isTextDefined}
      boxProps={{
        className,
        sx: {
          ...withEllipsis,
          color: sphereColors.gray800,
          ...sx,
        },
      }}
    >
      {label}
    </SphereTooltip>
  );
}
