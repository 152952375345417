import { createContext, useContext } from "react";

/** Type for the data provided by the network connect context provider */
type NetworkConnection = {
  /** Whether the application is connected to the network */
  isOnline: boolean;
};

/** Network connection context definition */
export const NetworkConnectionContext = createContext<
  NetworkConnection | undefined
>(undefined);

/**
 * Custom hook to access the network connection data.
 *
 * @throws {Error} when this custom hook is not used inside NetworkConnectionProvider
 */
export function useNetworkConnectionContext(): NetworkConnection {
  const context = useContext(NetworkConnectionContext);

  if (!context) {
    throw Error(
      "useNetworkConnectionContext() must be used inside the NetworkConnectionProvider component"
    );
  }

  return context;
}
