import { IReactChildrenOnly } from "@custom-types/types";
import { Badge } from "@mui/material";
import { colorConst } from "@styles/common-colors";

/**
 * An orange badge with a dot to show that something is new.
 */
export function NewBadge({ children }: IReactChildrenOnly): JSX.Element {
  return (
    <Badge
      variant="dot"
      color="primary"
      sx={{
        height: "24px",
        width: "24px",
        "& .MuiBadge-dot": {
          backgroundColor: colorConst.newFeatureIndicator,
          height: "10px",
          width: "10px",
          borderRadius: "50%",
        },
      }}
    >
      {children}
    </Badge>
  );
}
