import { isProjectArchivingState } from "@custom-types/type-guards";
import { useAppNavigation } from "@hooks/use-app-navigation";
import { NotFoundPage } from "@pages/not-found-page";
import { ProjectsPage } from "@pages/projects/projects-page";
import { useAppParams } from "@router/router-helper";
import { isValidProjectOrCompanyId } from "@utils/string-utils";
import { useEffect } from "react";

/**
 * Simple component used to validate the route param from the routes ".../projects/:param".
 *
 * We have the problem that the route param after projects route "/projects/:param"
 * can either be "active", "archived", a valid project ID or some other string.
 * That's why here we have to validate first if the param is a valid project status.
 * If it's a valid status then we allow access the projects page. If not we validate if
 * the param is a valid project ID, if it is we navigate to the project details route.
 * Finally if the param is not a valid status or project ID we render the "Not found" page.
 *
 * Idea taken from the official documentation regarding param validation:
 * https://reactrouter.com/en/main/start/faq#what-happened-to-regexp-routes-paths
 */
export function ValidateProjectStatus(): JSX.Element | undefined {
  const { navigateToProjectDetail } = useAppNavigation();
  const { projectStatus, companyId } = useAppParams();

  /** Handles the automatic navigation to the project details route */
  useEffect(() => {
    if (
      companyId &&
      projectStatus &&
      !isProjectArchivingState(projectStatus) &&
      isValidProjectOrCompanyId(projectStatus)
    ) {
      navigateToProjectDetail({
        companyId,
        projectId: projectStatus,
      });
    }
  }, [companyId, navigateToProjectDetail, projectStatus]);

  if (isProjectArchivingState(projectStatus)) {
    return <ProjectsPage />;
  }

  if (
    projectStatus &&
    !isProjectArchivingState(projectStatus) &&
    !isValidProjectOrCompanyId(projectStatus)
  ) {
    return <NotFoundPage />;
  }
}
