import { Box, Typography } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { ReactElement, isValidElement } from "react";

interface MarkupSidePanelInfoItemProps {
  /** The title to display for this info item. */
  title: string;

  /**
   * The value associated with the info item, which can be a string or a React element.
   * If not provided, a default value of "-" will be displayed.
   */
  value?: string | ReactElement;
}

/**
 * Displays an information item in the markup side panel with a title and value.
 *
 * @param {MarkupSidePanelInfoItemProps} props - The props for the info item component.
 * @param {string} props.title - The title to display for this info item.
 * @param {string | ReactElement} [props.value] - The value associated with the info item,
 * which can be a string or a React element. If not provided, a default value of "-" will be displayed.
 * @returns {JSX.Element} JSX element representing the markup side panel info item.
 */
export function MarkupSidePanelInfoItem({
  title,
  value,
}: MarkupSidePanelInfoItemProps): JSX.Element {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "20px",
        height: "28px",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          fontWeight: "700",
          fontSize: "12px",
          color: sphereColors.gray700,
          width: "73px",
          letterSpacing: "normal",
        }}
        data-testid={"markup-side-panel-info-item-title"}
      >
        {title}
      </Typography>

      <Box
        data-testid={"markup-side-panel-info-item-value"}
        sx={{ fontSize: "12px", color: sphereColors.gray800, width: "100%" }}
      >
        {isValidElement(value) ? value : value || "-"}
      </Box>
    </Box>
  );
}
