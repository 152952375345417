import { Dispatch, SetStateAction, useCallback } from "react";
import { SidePanel } from "@components/sidepanel/sidepanel";
import { sphereColors } from "@styles/common-colors";
import { useMediaQuery, useTheme } from "@mui/material";
import { StickySidePanelContainer } from "@components/sidepanel/sticky-sidepanel-container";
import { PointCloud } from "@custom-types/point-cloud-types";
import { PointCloudSidePanelHeader } from "@pages/project-details/project-data/point-clouds/sidepanel/point-cloud-sidepanel-header";
import { PointCloudSidePanelInfo } from "@pages/project-details/project-data/point-clouds/sidepanel/point-cloud-sidepanel-info";

interface Props {
  /** Boolean flag indicating whether the point cloud side panel is open or closed */
  isDataSidePanelOpen: boolean;

  /** Setter function to update the isOpenDataSidePanel state */
  setIsDataSidePanelOpen: Dispatch<SetStateAction<boolean>>;

  /** Point cloud selected */
  pointCloudSelected: PointCloud | undefined;

  /** Setter function to update the pointCloudSelected state */
  setPointCloudSelected: Dispatch<SetStateAction<PointCloud | undefined>>;
}

/**
 *  Component for displaying the point cloud data side panel.
 *  This component renders a side panel that shows detailed information
 *  about the selected point cloud, including properties, attributes, and
 *  interaction options.
 */
export function PointCloudSidePanel({
  isDataSidePanelOpen,
  setIsDataSidePanelOpen,
  pointCloudSelected,
  setPointCloudSelected,
}: Props): JSX.Element {
  const theme = useTheme();
  const isScreenXs = useMediaQuery(theme.breakpoints.only("xs"));

  /**
   * Callback function to close the side panel.
   * This function is used to close the point cloud side panel by setting the state to indicate that it is not open
   * and resetting the selected markup to `undefined`.
   */
  const closeSidePanel = useCallback(() => {
    setIsDataSidePanelOpen(false);
    setPointCloudSelected(undefined);
  }, [setIsDataSidePanelOpen, setPointCloudSelected]);

  return (
    <SidePanel
      isOpen={isDataSidePanelOpen}
      anchor={"right"}
      closeSidePanel={closeSidePanel}
      sx={{
        "& .MuiDrawer-paper": {
          boxShadow: "none",
          borderLeft: `1px solid ${sphereColors.gray200}`,
          width: isScreenXs ? "100%" : "430px",
          paddingX: "20px",
          paddingBottom: "16px",
        },
        "& .MuiModal-backdrop": {
          backgroundColor: "transparent",
        },
      }}
    >
      <StickySidePanelContainer
        header={
          pointCloudSelected && (
            <PointCloudSidePanelHeader
              pointCloud={pointCloudSelected}
              closeSidePanel={closeSidePanel}
            />
          )
        }
        defaultContent={
          pointCloudSelected && (
            <PointCloudSidePanelInfo pointCloud={pointCloudSelected} />
          )
        }
      />
    </SidePanel>
  );
}
