import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";

/** All the possible types for a company member */
export type CompanyMemberTypes =
  // Company member type when requested from company level (all members of a company)
  | SphereDashboardAPITypes.ICompanyMemberBase
  // Company member type when requested from member level (member details by providing memberId)
  | SphereDashboardAPITypes.ICompanyMemberDetails;

/** All the possible types for a member */
export type MemberTypes =
  // Types for company members
  | CompanyMemberTypes
  // Project member type when requested from project level (project details by providing projectId)
  | SphereDashboardAPITypes.IProjectMemberBase
  // Group member type when group details are requested by providing a group ID
  | SphereDashboardAPITypes.IGroupMemberDetails;

/** All the possible types for the role of a member in company or project */
export type MemberRoleTypes =
  | CoreAPITypes.EUserCompanyRole
  | CoreAPITypes.EUserProjectRole;

/** Roles to display in readable text */
/* eslint-disable  @typescript-eslint/naming-convention -- The namings are coming from backend */
export enum RoleDisplayNames {
  COMPANY_EXECUTIVE = "Enterprise Admin",
  COMPANY_VIEWER = "Enterprise Viewer",
  COMPANY_MANAGER = "Group Manager",
  PROJECT_MANAGER = "Project Manager",
  MEMBER = "Member",
  RESOURCE_OWNER = "Owner",
  RESOURCE_ADMIN = "Project Admin",
  RESOURCE_EDITOR = "Project Editor",
  RESOURCE_VIEWER = "Project Viewer",
}
/* eslint-enable */

/** Assignable company roles for members when updating the role */
export const AssignableUpdateCompanyMemberRole: {
  [key in string]: SphereDashboardAPITypes.UpdateCompanyMemberRole;
} = {
  companyExecutive: CoreAPITypes.EUserCompanyRole.companyExecutive,
  companyViewer: CoreAPITypes.EUserCompanyRole.companyViewer,
};

/** Assignable company roles for members in group when updating the role */
export const AssignableUpdateGroupMemberRole: {
  [key in string]: SphereDashboardAPITypes.GroupMemberCompanyRole;
} = {
  groupManager: CoreAPITypes.EUserCompanyRole.companyManager,
  projectManager: CoreAPITypes.EUserCompanyRole.projectManager,
};

/** Status of a member */
export enum EMemberStatus {
  /** When user is only invited by email but not created an account yet */
  pending = "Pending",

  /** When user has created an account */
  active = "Active",
}

/** Base props for member */
export interface BaseMemberProps {
  /** The type of member in sphere dashboard */
  member: MemberTypes;
}

export interface BaseMembersProps {
  /** The type of members in sphere dashboard */
  members: MemberTypes[];
}
