import { PageInfoBar } from "@components/common/page-info-bar";
import { useMediaQueryList } from "@hooks/use-media-query";
import { Breakpoint, ThemeProvider, createTheme } from "@mui/material";
import { markupsKPIsSelector } from "@store/markups/markups-selector";
import { useAppSelector } from "@store/store-helper";
import { useMemo } from "react";
import { MarkupsKpi } from "@pages/project-details/project-markups/markups-kpi";
import { sphereColors } from "@styles/common-colors";

/** Custom breakpoints to fit the KPI bar design for all screen sizes */
const theme = createTheme({
  breakpoints: {
    values: {
      // Default value is 0
      xs: 0,
      // Default value is 600
      sm: 650,
      // Default value is 900
      md: 950,
      // Default value is 1200
      lg: 1200,
      // Default value is 1536
      xl: 1536,
    },
  },
});

/** Map of the number of items-per-row to show for each screen width breakpoint */
const customItemsPerRow: { [key in Breakpoint]: number } = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 6,
  xl: 6,
};

/** Default height in pixels of the annotations KPI bar */
const DEFAULT_BAR_HEIGHT = 80;

interface Props {
  /** Whether the component skeleton animation should be shown */
  shouldShowSkeleton?: boolean;
}

/** Renders the KPI bar for the project markups (annotations) tab */
export function ProjectMarkupsDetails({
  shouldShowSkeleton = false,
}: Props): JSX.Element {
  const markupsKPIs = useAppSelector(markupsKPIsSelector);
  const { isExtraSmall, isSmall, isMedium, isScreenLgAndLarger } =
    useMediaQueryList();

  /* eslint-disable @typescript-eslint/no-magic-numbers */
  /** The number of items-per-row that we want to show for each screen width breakpoint */
  const itemsPerRow = useMemo(() => {
    if (isScreenLgAndLarger) {
      return 4;
    }

    if (isMedium) {
      return 2;
    }

    if (isSmall) {
      return 2;
    }

    if (isExtraSmall) {
      return 1;
    }
  }, [isExtraSmall, isMedium, isScreenLgAndLarger, isSmall]);
  /* eslint-enable @typescript-eslint/no-magic-numbers */

  /** Whether the KPI item should be displayed in compact format (one row) */
  const isCompactFormat = useMemo(() => {
    return isMedium || isSmall || isExtraSmall;
  }, [isExtraSmall, isMedium, isSmall]);

  if (shouldShowSkeleton) {
    return <PageInfoBar isLoading={true} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <PageInfoBar
        items={[
          {
            columnSize: itemsPerRow,
            content: (
              <MarkupsKpi
                label="Total annotations"
                value={markupsKPIs.total}
                isCompactFormat={isCompactFormat}
              />
            ),
          },
          {
            columnSize: itemsPerRow,
            content: (
              <MarkupsKpi
                label={"Incompleted"}
                value={
                  markupsKPIs.toDo +
                  markupsKPIs.inProgress +
                  markupsKPIs.inReview
                }
                isCompactFormat={isCompactFormat}
              />
            ),
          },
          {
            columnSize: itemsPerRow,
            content: (
              <MarkupsKpi
                label="Overdue"
                value={markupsKPIs.overdue}
                valueSx={{
                  color: sphereColors.red600,
                }}
                isCompactFormat={isCompactFormat}
              />
            ),
          },
          {
            columnSize: itemsPerRow,
            content: (
              <MarkupsKpi
                label={"Completed"}
                value={markupsKPIs.resolved}
                isCompactFormat={isCompactFormat}
              />
            ),
          },
        ]}
        customItemsPerRow={customItemsPerRow}
        barHeight={isCompactFormat ? undefined : DEFAULT_BAR_HEIGHT}
      />
    </ThemeProvider>
  );
}
