import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";

let CookieManagerInstance;

try {
  CookieManagerInstance = window.HolobuilderCookieManager;
} catch (error) {
  CookieManagerInstance = {};
  throw new Error(getErrorDisplayMarkup(error));
}

export const HolobuilderCookieManager = CookieManagerInstance;

export function openCookieManager(): void {
  if (HolobuilderCookieManager) {
    HolobuilderCookieManager.show();
  }
}

export const YOUTUBE_COOKIE = "youtube";

export const INTERCOM_COOKIE = "intercom";

export const AMPLITUDE_COOKIE = "amplitude";
