import { Box, InputAdornment, Tooltip } from "@mui/material";
import { withEllipsis } from "@styles/common-styles";
import { useEffect, useState } from "react";
import { MarkupDetails } from "@pages/project-details/project-markups/markup-details";
import { sphereColors } from "@styles/common-colors";
import EditIcon from "@assets/icons/Edit.svg?react";
import { FaroSimpleTextField } from "@components/common/faro-text-field/faro-simple-text-field";
import { createMutationSetElementName } from "@faro-lotv/service-wires";
import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { getBottomBorderWithBoxShadow } from "@utils/ui-utils";
import { useAppDispatch } from "@store/store-helper";
import { changeName } from "@faro-lotv/project-source";
import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { FaroButtonSpinner } from "@components/common/button/faro-button-spinner";
import { useMarkupContext } from "@context-providers/markup/markup-context";
import { BaseMarkupColumnProps } from "@custom-types/project-markups-types";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { AnnotationEvents } from "@utils/track-event/track-event-list";
import { cloneDeep, findIndex } from "lodash";

/** Time in ms to wait between hover and open the popover */
const ENTER_DELAY = 200;

/** Maximum length of markup name  */
const MARKUP_NAME_MAX_LENGTH = 200;

/** Class name of annotation name edit icon, Used to control the visibility on hover */
const ANNOTATION_NAME_EDIT_ICON_CLASS = "annotationNameEdit";

/** Component that renders the markup name and an additional popover for the markup details */
export function MarkupNameAndDetails({
  markup,
  isSidePanelOpen = false,
}: BaseMarkupColumnProps): JSX.Element {
  const { handleErrorWithToast } = useErrorContext();
  const dispatch = useAppDispatch();

  const { trackEvent } = useTrackEvent();

  const { projectId, hasPermissionToEditMarkup, updateMarkups, markups } =
    useMarkupContext();
  const projectApiClient = useProjectApiClient({
    projectId,
  });

  const [isOpen, setIsOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [markupName, setMarkupName] = useState<string>(markup.name);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /** Triggers when user clicks on check mark after update */
  async function updateName(): Promise<void> {
    setIsLoading(true);
    setIsEditMode(false);

    // Early return after reset the name if markup name has empty value
    if (!markupName.trim().length) {
      setMarkupName(markup.name);
      setIsLoading(false);
      return;
    }

    // Early return if the markup name is not changed
    if (markupName === markup.name) {
      setIsLoading(false);
      return;
    }

    const mutations = [createMutationSetElementName(markup.id, markupName)];
    try {
      await projectApiClient.applyMutations(mutations);

      trackEvent({
        name: AnnotationEvents.editAnnotation,
        props: { property: "name", isValueEmpty: false },
      });

      // Update local store
      await dispatch(changeName({ id: markup.id, name: markupName.trim() }));
    } catch (error) {
      handleErrorWithToast({
        id: `updateAnnotationName-${Date.now().toString()}`,
        title: "Could not change annotation name. Please try again",
        error: getErrorDisplayMarkup(error),
      });
    } finally {
      const newMarkups = cloneDeep(markups);
      const markupIndex = findIndex(newMarkups, { id: markup.id });

      newMarkups[markupIndex].name = markupName;

      updateMarkups(newMarkups);

      setIsLoading(false);
    }
  }

  let EndIcon: React.ReactNode = null;

  if (isLoading) {
    EndIcon = <FaroButtonSpinner />;
  } else if (!isEditMode && hasPermissionToEditMarkup && !isSidePanelOpen) {
    EndIcon = (
      <FaroIconButton
        component={EditIcon}
        buttonSize="24px"
        iconSize="16px"
        onClick={(event) => {
          event.stopPropagation();
          setIsEditMode(true);
        }}
        color={sphereColors.gray400}
        iconButtonProps={{
          className: ANNOTATION_NAME_EDIT_ICON_CLASS,
          sx: { visibility: "hidden" },
        }}
      />
    );
  }
  /**
   * This hook sets the state variable markupName to the value of markup.name whenever the markup object changes.
   * This ensures that the markupName state is kept in sync with the markup object, allowing updates to
   * be reflected in multiple instances of the component
   */
  useEffect(() => {
    setMarkupName(markup.name);
  }, [markup]);

  return (
    <Box
      sx={{
        width: !isSidePanelOpen || isEditMode ? "100%" : "calc(100% - 106px)",
        height: "92%",
        boxShadow: isEditMode
          ? getBottomBorderWithBoxShadow({
              thickness: "2px",
            })
          : "none",
        ...(!isEditMode && {
          "&:hover": {
            [`& .${ANNOTATION_NAME_EDIT_ICON_CLASS}`]: {
              visibility: "visible",
            },
          },
        }),
      }}
    >
      <Box
        component="div"
        onClick={() => {
          if (!isSidePanelOpen) {
            return;
          }

          setIsEditMode(true);
        }}
        sx={{
          cursor: "pointer",
          height: "100%",
          padding: !isSidePanelOpen ? "8px 10px" : "0",
        }}
      >
        <Box
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            borderBottom: isSidePanelOpen ? "1px solid transparent" : "none",
            ...(!isEditMode && {
              "&:hover": {
                borderBottom: isSidePanelOpen
                  ? `1px solid ${sphereColors.gray800}`
                  : "none",
              },
            }),
          }}
        >
          {isEditMode && (
            <FaroSimpleTextField
              variant="standard"
              value={markupName}
              type={"text"}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  updateName();
                }
                if (event.key === "Escape") {
                  setMarkupName(markup.name);
                  setIsEditMode(false);
                }

                if (!isSidePanelOpen) {
                  event.stopPropagation();
                }
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setMarkupName(event.target.value);
              }}
              onBlur={updateName}
              size="small"
              sx={{
                height: isSidePanelOpen ? "30px" : "auto",
                display: isSidePanelOpen ? "grid" : "inline-flex",
                width: "100%",
                "& .MuiInputBase-root": {
                  fontSize: "12px",
                  fontWeight: "bold",
                },
                "& .MuiInputBase-input": {
                  padding: "0px",
                  color: sphereColors.gray800,
                },
              }}
              inputProps={{ maxLength: MARKUP_NAME_MAX_LENGTH }}
              InputProps={{
                // eslint-disable-next-line @typescript-eslint/naming-convention -- defined in the mui package
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      color: sphereColors.gray600,
                      fontWeight: 400,
                    }}
                  >
                    {markupName.length}/{MARKUP_NAME_MAX_LENGTH}
                  </InputAdornment>
                ),
              }}
            />
          )}

          {!isEditMode &&
            (!isSidePanelOpen ? (
              <Tooltip
                enterDelay={ENTER_DELAY}
                enterNextDelay={ENTER_DELAY}
                PopperProps={{
                  sx: {
                    "& .MuiTooltip-tooltip": {
                      backgroundColor: "white",
                      padding: "0",
                      margin: "0 !important",
                      maxWidth: "none",
                      color: sphereColors.gray800,
                      fontSize: "14px",
                    },
                  },
                }}
                title={<MarkupDetails markup={markup} />}
                onClose={() => setIsOpen(false)}
                onOpen={() => setIsOpen(true)}
              >
                <Box
                  component="var"
                  sx={{
                    ...withEllipsis,
                    fontWeight: "bold",
                    color: isOpen ? sphereColors.blue500 : sphereColors.gray800,
                  }}
                >
                  {markupName ?? "-"}
                </Box>
              </Tooltip>
            ) : (
              <Box
                component="var"
                sx={{
                  ...withEllipsis,
                  fontWeight: "bold",
                  color: isOpen ? sphereColors.blue500 : sphereColors.gray800,
                }}
              >
                {markupName ?? "-"}
              </Box>
            ))}

          <Box sx={{ marginLeft: "auto" }}>{EndIcon}</Box>
        </Box>
      </Box>
    </Box>
  );
}
