import { getProjectApiClient } from "@api/project-api/project-api-utils";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { IElementType } from "@faro-lotv/ielement-types";
import { isCaptureTreeScanEntity } from "@pages/project-details/project-data-management/raw-scans/raw-scans-utils";
import {
  fetchAllRegistrationRevisions,
  fetchCaptureTreeForMainRevision,
} from "@store/capture-tree/capture-tree-thunks";
import { fetchProjectIElements } from "@store/i-elements/i-elements-slice";
import { useAppDispatch } from "@store/store-helper";
import { useCallback } from "react";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { usePolling } from "@hooks/use-polling";

/**
 * Custom hook that polls the capture tree data
 */
export function useCaptureTree({
  projectId,
}: Partial<BaseProjectIdProps>): void {
  const dispatch = useAppDispatch();
  const { handleErrorWithToast } = useErrorContext();

  /**
   * Fetches:
   * - The registration revisions of the current project
   * - The capture tree entities for the main revision of the current project
   * - Additionally it also fetches the scan ielements and their children PointCloudStream ielement from the BI tree.
   * The presence of the PointCloudStream as children of the scan indicates that the scan has been processed.
   *
   * @throws {error} if the requests to fetch capture tree data fail.
   */
  const fetchCaptureTreeData = useCallback(async (): Promise<void> => {
    if (!projectId) {
      return;
    }

    const projectApiClient = getProjectApiClient({ projectId });

    const revisionsPromise = dispatch(
      fetchAllRegistrationRevisions({ projectApiClient })
    ).unwrap();
    const entitiesPromise = dispatch(
      fetchCaptureTreeForMainRevision({ projectApiClient })
    ).unwrap();

    // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
    const [_, entities] = await Promise.all([
      revisionsPromise,
      entitiesPromise,
    ]);

    const scanEntities = entities.filter(isCaptureTreeScanEntity);
    const scanEntitiesIds = scanEntities.map((entity) => entity.id);

    await dispatch(
      fetchProjectIElements({
        fetcher: async () => {
          const rootElementPromise = projectApiClient.getRootIElement();

          const scanElementsPromise = projectApiClient.getAllIElements({
            ids: scanEntitiesIds,
          });

          const pointCloudStreamElementsPromise =
            projectApiClient.getAllIElements({
              types: [IElementType.pointCloudStream],
              ancestorIds: scanEntitiesIds,
            });

          const [rootElement, scanElements, pointCloudStreamElements] =
            await Promise.all([
              rootElementPromise,
              scanElementsPromise,
              pointCloudStreamElementsPromise,
            ]);

          return [rootElement, ...scanElements, ...pointCloudStreamElements];
        },
      })
    ).unwrap();
  }, [dispatch, projectId]);

  /**
   * Error handler callback.
   */
  const errorHandler = useCallback(
    (error: unknown): void => {
      handleErrorWithToast({
        id: `fetchCaptureTreeData-${Date.now().toString()}`,
        title: "Failed to fetch capture tree data of the project",
        error,
      });
    },
    [handleErrorWithToast]
  );

  usePolling({
    callback: fetchCaptureTreeData,
    errorHandler,
    maxAttemptsMsg:
      "Unable to fetch capture tree data after multiple attempts. Please reload the application to try again.",
  });
}
