import { EmptyPage } from "@components/common/empty-page/empty-page";
import {
  rawScansSelector,
  fetchingStatusCaptureTreeForMainRevisionSelector,
  hasFetchedCaptureTreeForMainRevisionSelector,
} from "@store/capture-tree/capture-tree-selectors";
import { useAppSelector } from "@store/store-helper";
import { FetchingStatus } from "@store/store-types";
import { useMemo } from "react";
import NoDataIcon from "@assets/icons/new/no_data.svg?react";
import FailedIcon from "@assets/icons/failed_32px.svg?react";
import { RawScansTable } from "@pages/project-details/project-data-management/raw-scans/raw-scans-table";
import { PrepareDataButton } from "@pages/project-details/project-data-management/raw-scans/prepare-data-button";
import { sphereColors } from "@styles/common-colors";
import { Box } from "@mui/material";
import { PROJECT_DATA_MANAGEMENT_LABELS } from "@src/constants/project-data-management-constants";
import { selectProjectLoadingState } from "@faro-lotv/project-source";

/** Renders the raw scans section of the data management page */
export function RawScans(): JSX.Element {
  const rawScans = useAppSelector(rawScansSelector);

  const fetchingStatus = useAppSelector(
    fetchingStatusCaptureTreeForMainRevisionSelector
  );
  const hasFetchedCaptureTree = useAppSelector(
    hasFetchedCaptureTreeForMainRevisionSelector
  );
  const fetchingStatusIElements = useAppSelector(selectProjectLoadingState);

  const isFetchingForTheFirstTime = useMemo(() => {
    return (
      !hasFetchedCaptureTree &&
      (fetchingStatus === FetchingStatus.pending ||
        fetchingStatusIElements === "loading")
    );
  }, [fetchingStatus, fetchingStatusIElements, hasFetchedCaptureTree]);

  const shouldShowEmptyPage = useMemo(() => {
    return rawScans.length === 0 && !isFetchingForTheFirstTime;
  }, [rawScans.length, isFetchingForTheFirstTime]);

  const hasFailedToFetchCaptureTree = useMemo(() => {
    return (
      fetchingStatus === FetchingStatus.rejected ||
      fetchingStatusIElements === "failed"
    );
  }, [fetchingStatus, fetchingStatusIElements]);

  return (
    <Box
      data-testid="raw-scans-content"
      sx={{
        marginTop: "14px",
        border: `1px solid ${sphereColors.gray200}`,
        borderRadius: 0,
      }}
    >
      {shouldShowEmptyPage ? (
        <EmptyPage
          title={hasFailedToFetchCaptureTree ? "Error" : "No uploaded data"}
          subtitle={
            hasFailedToFetchCaptureTree
              ? "Failed to fetch the uploaded data. Please reload the page and try again."
              : "No uploaded data to show for this project."
          }
          icon={hasFailedToFetchCaptureTree ? FailedIcon : NoDataIcon}
        />
      ) : (
        <Box
          sx={{
            background: "white",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: `1px solid ${sphereColors.gray200}`,
              paddingX: "30px",
              fontWeight: "bold",
              height: "64px",
            }}
          >
            {PROJECT_DATA_MANAGEMENT_LABELS.rawData}
            <PrepareDataButton />
          </Box>
          <Box
            sx={{
              paddingX: "30px",
              paddingBottom: "30px",
            }}
          >
            <RawScansTable
              rawScans={rawScans}
              isFetchingForTheFirstTime={isFetchingForTheFirstTime}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
