import { TilesContainer } from "@components/common/tiles-container";
import { useAddonsContext } from "@context-providers/add-ons/add-ons-context";
import {
  AddOnsExtensionItem,
  featuresData,
  IFeatureItem,
} from "@pages/add-ons/extensions";
import { DateTimeUtils } from "@stellar/web-core";
import { useAppSelector } from "@store/store-helper";
import { selectedCompanyFeaturesSelector } from "@store/sdb-company/sdb-company-selector";

/** Renders the contents for the extensions */
export function AddOnsExtensions(): JSX.Element {
  const features = useAppSelector(selectedCompanyFeaturesSelector);
  const { anchorEl } = useAddonsContext();

  const extensions: IFeatureItem[] = featuresData;

  const extensionsWithActivation = extensions.map((extension) => {
    let isActive: boolean = false;

    if (features) {
      isActive = features.some((feature) => {
        if (feature.identifier === extension.featureId) {
          return feature.state === "enabled";
        }

        return false;
      });
    }

    return {
      ...extension,
      isActive,
    };
  });

  return (
    <TilesContainer>
      {extensionsWithActivation
        .sort((extensionsA, extensionsB) =>
          DateTimeUtils.diffBetweenTwoDatesInDays(
            extensionsB.date,
            extensionsA.date
          )
        )
        .map((extension, index) => (
          <AddOnsExtensionItem
            extension={extension}
            key={index}
            anchorEl={anchorEl}
          />
        ))}
    </TilesContainer>
  );
}
