import { FileUploadTask } from "@custom-types/file-upload-types";
import { BackgroundTaskState } from "@faro-lotv/service-wires";

/** Returns true if the task is in-progress */
export function isTaskInProgress(task: FileUploadTask): boolean {
  return [
    BackgroundTaskState.created,
    BackgroundTaskState.scheduled,
    BackgroundTaskState.started,
  ].includes(task.status);
}

/** Returns true if the task is completed */
export function isTaskCompleted(task: FileUploadTask): boolean {
  return [BackgroundTaskState.succeeded, BackgroundTaskState.aborted].includes(
    task.status
  );
}
