import { Rating, TextareaAutosize, styled } from "@mui/material";
import { sphereColors } from "@styles/common-colors";

/**
 * The code snippet customizes the Material-UI TextareaAutosize component using the styled function
 * from @mui/material/styles, creating a new component named Textarea.
 */
export const Textarea = styled(TextareaAutosize)(
  () => `
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid ${sphereColors.gray200};
    padding: 12px;
    color: ${sphereColors.gray500};
    font-size: 14px;
    resize: none;
    height: 102px;
    margin-top: 12px;

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);

/**
 * The code snippet customizes the Material-UI Rating component using the styled function
 * from @mui/material/styles, creating a new component named StyledRating.
 */
export const StyledRating = styled(Rating)(() => ({
  "&.rating-annotations": {
    gap: "32px",
    display: "flex",
    margin: "14px auto 0 auto",
  },
  "&.rating-annotations > label": {
    position: "relative",
  },
  "&.rating-annotations > label::after": {
    content: "''",
    position: "absolute",
    width: "32px",
    height: "1px",
    backgroundColor: sphereColors.gray200,
    top: "50%",
    transform: "translateY(-50%)",
    left: "100%",
  },
  "&.rating-annotations > label:nth-of-type(n + 5)::after": {
    display: "none",
  },
  "& .MuiRating-iconEmpty": {
    color: sphereColors.gray800,
  },
  "& .MuiRating-iconFilled.MuiRating-iconHover, .MuiRating-iconFilled, .MuiRating-iconEmpty":
    {
      minWidth: "50px",
      height: "50px",
      border: `1px solid ${sphereColors.gray200}`,
      borderRadius: "100%",
      justifyContent: "center",
      alignItems: "center",
      transform: "scale(1)",
    },
  "& .MuiRating-iconFilled.MuiRating-iconHover, .MuiRating-iconFilled": {
    color: "white",
    background: sphereColors.blue500,
    fontSize: "20px",
  },
}));
