import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/store-helper";
import { CompanyMemberGroupsState } from "@store/company-member-groups/company-member-groups-slice";

/**
 * Returns the groups for the provided company member group.
 */
export const companyMemberGroupsSelector: (
  state: RootState
) => CompanyMemberGroupsState["groups"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.companyMemberGroups.groups;
  }
);

/**
 * Returns the fetching status of the company member group slice.
 */
export const companyMemberGroupsStatusSelector: (
  state: RootState
) => CompanyMemberGroupsState["status"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.companyMemberGroups.status;
  }
);
