import {
  BaseCaptureTreeEntity,
  ClusterEntity,
} from "@custom-types/capture-tree-types";
import { getTopLevelClusterEntities } from "@utils/capture-tree-utils";
import { convertToDateString, generateGUID } from "@faro-lotv/foundation";
import {
  CaptureTreeEntityType,
  CaptureTreePointCloudType,
  CreateScanEntitiesParams,
} from "@faro-lotv/service-wires";
import { UploadedFile } from "@custom-types/file-upload-types";
import { DEFAULT_POSE_PARAM } from "@src/constants/capture-tree-constants";

/** Els cluster name prefix */
const ELS_CLUSTER_NAME_PREFIX = "ELS Scans_";

/** Regular expression to validate the name of an ELS cluster. It should have the format "ELS Scans_{mm/dd/yyyy}" */
const ELS_CLUSTER_NAME_REGEX = new RegExp(
  `^${ELS_CLUSTER_NAME_PREFIX}\\d{2}\\/\\d{2}\\/\\d{4}$`
);

/**
 * @param name string to validate
 * @returns Whether the passed string is a valid name for a ELS cluster
 */
export function isValidElsClusterName(name: string): boolean {
  return ELS_CLUSTER_NAME_REGEX.test(name);
}

/**
 * @returns a valid ELS cluster name of format "ELS Scans_{mm/dd/yyyy}"
 */
export function getElsClusterName(): string {
  const date = convertToDateString(new Date().toISOString(), "en-US");
  return `${ELS_CLUSTER_NAME_PREFIX}${date}`;
}

/**
 * @param entities The capture tree entities
 * @returns The first found "ELS" cluster entity or undefined if not found
 * A cluster is considered the "ELS" cluster if:
 * - its a top-level cluster and
 * - it has a valid name for an ELS cluster
 */
export function getElsClusterEntity<T extends BaseCaptureTreeEntity>(
  entities: T[]
): ClusterEntity<T> | undefined {
  const topLevelClusters = getTopLevelClusterEntities(entities);
  return topLevelClusters.find((cluster) =>
    isValidElsClusterName(cluster.name)
  );
}

interface GetScanEntitiesParamsProps {
  /** List of successfully uploaded scans to add */
  uploadedScans: UploadedFile[];

  /** ID of the revision cluster entity where the scans will be added */
  revisionClusterEntityId: string;
}

/**
 * @returns An array of params required to create capture tree scan entities from the passed uploaded scans
 */
export function getCreateScanEntitiesParams({
  uploadedScans,
  revisionClusterEntityId,
}: GetScanEntitiesParamsProps): CreateScanEntitiesParams["requestBody"] {
  return uploadedScans.map((scan) => {
    return {
      parentId: revisionClusterEntityId,
      type: CaptureTreeEntityType.elsScan,
      name: scan.fileName,
      pose: DEFAULT_POSE_PARAM,
      pointClouds: [
        {
          externalId: generateGUID(),
          type: CaptureTreePointCloudType.elsRaw,
          uri: scan.downloadUrl,
          md5Hash: scan.md5,
          fileSize: scan.fileSize,
          fileName: scan.fileName,
        },
      ],
    };
  });
}
