import { faroTableComparator } from "@components/common/faro-table/faro-table-utils";
import { MemberTypes } from "@custom-types/member-types";
import { PointCloud } from "@custom-types/point-cloud-types";
import {
  GridColDef,
  GridRenderCellParams,
  gridDateComparator,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid-pro";
import { PointCloudDate } from "@pages/project-details/project-data/point-clouds/point-cloud-date";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { getMemberNameById } from "@utils/member-utils";
import { Box } from "@mui/material";
import { SHOW_ON_HOVER_CLASS } from "@utils/ui-utils";
import { PointCloudActions } from "@pages/project-details/project-data/point-clouds/point-cloud-actions";
import { MergedPointCloudDetails } from "@pages/project-details/project-data/point-clouds/merged-point-cloud-details";
import { FaroTableTextCell } from "@components/common/faro-table/faro-table-text-cell";
import { FaroTableMemberCell } from "@components/common/faro-table/faro-table-member-cell";

/** Headers for the point clouds table */
export enum PointCloudsTableHeaders {
  /** Point cloud name */
  name = "Name",

  /** Merged point cloud details */
  mergedPointCloudDetails = "mergedPointCloudDetails",

  /** Point cloud creation date */
  creationDate = "Creation date",

  /** Sheet where the point cloud is located */
  sheet = "Sheet",

  /** Point Cloud creator */
  createdBy = "Created by",

  /** Point Cloud options */
  options = "Options",
}

interface Props {
  /** List of company members */
  companyMembers: MemberTypes[];

  /** List of project members */
  projectMembers: SphereDashboardAPITypes.IProjectMemberBase[];
}

/**
 * Returns a an object will all the point cloud columns:
 * - Each property key is the column name
 * - Each property value is a GridColDef object of the MUI data grid
 */
export function getPointCloudsTableColumns({
  companyMembers,
  projectMembers,
}: Props): Record<PointCloudsTableHeaders, GridColDef> {
  return {
    [PointCloudsTableHeaders.name]: {
      field: PointCloudsTableHeaders.name,
      minWidth: 180,
      flex: 1,
      renderCell: (params: GridRenderCellParams<{ entity: PointCloud }>) => {
        const name = params.row.entity.name;
        return (
          <FaroTableTextCell
            text={name}
            className="point-cloud-table-name"
            sx={{
              fontWeight: "bold",
            }}
          />
        );
      },
      valueGetter: (_, row: { entity: PointCloud }) => row.entity.name,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [PointCloudsTableHeaders.mergedPointCloudDetails]: {
      field: PointCloudsTableHeaders.mergedPointCloudDetails,
      type: "actions",
      align: "left",
      width: 60,
      renderCell: (params: GridRenderCellParams<{ entity: PointCloud }>) => {
        const name = params.row.entity.name;
        const sourcePointCloudsInfo = params.row.entity.sourcePointCloudsInfo;
        return (
          <MergedPointCloudDetails
            name={name}
            sourcePointCloudsInfo={sourcePointCloudsInfo}
          />
        );
      },
    },

    [PointCloudsTableHeaders.creationDate]: {
      field: PointCloudsTableHeaders.creationDate,
      minWidth: 110,
      flex: 0.5,
      type: "date",
      renderCell: (params: GridRenderCellParams<{ entity: PointCloud }>) => {
        const date = params.row.entity.createdAt;
        return <PointCloudDate date={date} />;
      },
      valueGetter: (_, row: { entity: PointCloud }) => {
        return new Date(row.entity.createdAt);
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<Date | undefined>(
          v1,
          v2,
          cp1,
          cp2,
          gridDateComparator
        ),
    },

    [PointCloudsTableHeaders.sheet]: {
      field: PointCloudsTableHeaders.sheet,
      minWidth: 180,
      flex: 1,
      renderCell: (params: GridRenderCellParams<{ entity: PointCloud }>) => {
        const sheetName = params.row.entity.sectionArea?.name;
        return <FaroTableTextCell text={sheetName} />;
      },
      valueGetter: (_, row: { entity: PointCloud }) =>
        row.entity.sectionArea?.name,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [PointCloudsTableHeaders.createdBy]: {
      field: PointCloudsTableHeaders.createdBy,
      minWidth: 180,
      flex: 1,
      renderCell: (params: GridRenderCellParams<{ entity: PointCloud }>) => {
        return (
          <FaroTableMemberCell
            memberId={params.row.entity.createdBy}
            companyMembers={companyMembers}
            projectMembers={projectMembers}
          />
        );
      },
      valueGetter: (_, row: { entity: PointCloud }) =>
        getMemberNameById({
          memberId: row.entity.createdBy,
          companyMembers,
          projectMembers,
        }),
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string | undefined>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [PointCloudsTableHeaders.options]: {
      field: PointCloudsTableHeaders.options,
      type: "actions",
      align: "right",
      width: 90,
      renderCell: (params: GridRenderCellParams<{ entity: PointCloud }>) => {
        const sphereViewerUrl = params.row.entity.sphereViewerUrl;
        return sphereViewerUrl ? (
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              // This will be overridden by parent component using SHOW_ON_HOVER_CLASS
              visibility: "hidden",
            }}
            className={SHOW_ON_HOVER_CLASS}
          >
            <PointCloudActions sphereViewerUrl={sphereViewerUrl} />
          </Box>
        ) : null;
      },
    },
  };
}
