import { Box } from "@mui/material";

/** Tooltip title text */
const TOOLTIP_TITLE = "Go back to HoloBuilder Dashboard";

/** Tooltip content text */
const TOOLTIP_CONTENT = `By clicking here you will exit the Sphere XG Dashboard and enter back into HoloBuilder.
  You can switch back anytime!`;

/** Renders the tooltip for the HoloBuilder sidebar item */
export function SidebarHolobuilderTooltip(): JSX.Element {
  return (
    <Box
      sx={{
        width: "310px",
        padding: "32px",
      }}
    >
      <Box
        sx={{
          fontSize: "20px",
          fontWeight: 600,
          mb: "24px",
        }}
      >
        {TOOLTIP_TITLE}
      </Box>
      <Box
        sx={{
          fontWeight: "14px",
        }}
      >
        {TOOLTIP_CONTENT}
      </Box>
    </Box>
  );
}
