import { AutocompleteRenderGetTagProps, Chip } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import Cancel from "@assets/icons/Cancel.svg?react";
import { getSvgColoredIconCss } from "@utils/ui-utils";

type ChipTagProps = {
  /** Internal props for the chip provided by autocomplete component */
  tagProps: AutocompleteRenderGetTagProps;

  /** The index of the current chip being processed in the chip list */
  index: number;

  /** Text displayed in the chip */
  label: string;

  /** True when the chip should be disabled */
  isDisabled?: boolean;

  /** Callback function to validate new inserted value before adding to the chip */
  validateNewItem?(item: string): boolean;
};

/** Renders chip with cross button */
export function ChipTag({
  tagProps,
  index,
  label,
  isDisabled,
}: ChipTagProps): JSX.Element | null {
  return (
    <Chip
      {...tagProps({ index })}
      key={`chip-${index}`}
      label={label}
      sx={{
        color: sphereColors.pureWhite,
        background: sphereColors.gray400,
        height: "25px",
        fontSize: "12px",
        cursor: "default",
        "& .MuiChip-deleteIcon": {
          ...getSvgColoredIconCss(sphereColors.pureWhite),
        },
        "& .MuiChip-deleteIcon:hover": {
          ...getSvgColoredIconCss(sphereColors.pureWhite),
        },
      }}
      disabled={isDisabled}
      // Only show delete icon if the chip is not disabled
      // eslint-disable-next-line react/jsx-no-useless-fragment
      deleteIcon={isDisabled ? <></> : <Cancel key={`chip-delete-${index}`} />}
    />
  );
}
