import { PageSectionProps } from "@components/page/page-section";
import { BaseSettingsPage } from "@pages/settings/base-settings-page";
import { useEffect, useMemo } from "react";
import { SettingsSectionTooltip } from "@pages/settings/access-control-settings/settings-section-tooltip";
import {
  fetchCompanySetting,
  updateCompanySetting,
} from "@store/sdb-company/sdb-company-slice";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  companySettingsSelector,
  fetchingSdbCompanyFlagsSelector,
} from "@store/sdb-company/sdb-company-selector";
import { useCoreApiClient } from "@api/use-core-api-client";
import workspaceUpdateTooltipImg from "@assets/settings/workspace-update-tooltip.svg";
import holobuilderAccessTooltipImg from "@assets/settings/holobuilder-access-tooltip.svg";
import { SettingSwitch } from "@pages/settings/access-control-settings/settings-switch";
import {
  AllowSdbAccess,
  CompanySettings,
  defaultAllowSdbAccess,
} from "@custom-types/company-settings-types";

/**
 * The access control settings page that allows the user to set the access control settings for the workspace.
 */
export function AccessControlSettings(): JSX.Element {
  const { isFetchingCompanySettings, isUpdatingCompanySettings } =
    useAppSelector(fetchingSdbCompanyFlagsSelector);

  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();

  const companySettings = useAppSelector(companySettingsSelector);

  const isCompanySettingLoading = useMemo(
    () => isFetchingCompanySettings || isUpdatingCompanySettings,
    [isFetchingCompanySettings, isUpdatingCompanySettings]
  );

  // Fetch the access control company settings on mount
  useEffect(() => {
    dispatch(
      fetchCompanySetting({
        coreApiClient,
        identifier: CompanySettings.forceWorkspaceUpdate,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- defaultValue can have different types
        defaultValue: false,
      })
    );

    dispatch(
      fetchCompanySetting({
        coreApiClient,
        identifier: CompanySettings.allowSdbAccess,
        defaultValue: defaultAllowSdbAccess,
      })
    );
  }, [coreApiClient, dispatch]);

  async function handleUpdateCompanySetting(
    identifier: CompanySettings,
    value: unknown
  ): Promise<void> {
    await dispatch(
      updateCompanySetting({
        coreApiClient,
        payload: {
          identifier,
          value,
        },
      })
    );
  }

  const pageSectionsProps: PageSectionProps[] = [
    {
      title: "Access via workspace update",
      tooltipInfo: (
        <SettingsSectionTooltip
          // eslint-disable-next-line max-len
          changingText="With this update, the Sphere XG platform will become the designated platform for all workspace members to manage and view the workspace."
          imgSrc={workspaceUpdateTooltipImg}
        />
      ),
      subtitle:
        // eslint-disable-next-line max-len
        "By choosing this option all workspace members will be redirected automatically to Sphere XG. This update is reversible and comes at no cost.",
      items: [
        {
          label: "All workspace members",
          shouldLabelBold: true,
          content: (
            <SettingSwitch
              isLoading={isCompanySettingLoading}
              isChecked={!!companySettings.forceWorkspaceUpdate}
              setIsChecked={(isChecked) =>
                dispatch(
                  updateCompanySetting({
                    coreApiClient,
                    payload: {
                      identifier: CompanySettings.forceWorkspaceUpdate,
                      // eslint-disable-next-line @typescript-eslint/naming-convention -- value can have different types
                      value: isChecked,
                    },
                  })
                )
              }
            />
          ),
        },
      ],
    },
    {
      title: "Access via HoloBuilder",
      tooltipInfo: (
        <SettingsSectionTooltip
          changingText="After access activation, members will see the Sphere XG access button in Holobuilder sidebar."
          imgSrc={holobuilderAccessTooltipImg}
        />
      ),
      subtitle:
        // eslint-disable-next-line max-len
        "This determines the access control levels for members accessing Sphere XG. Manage the provided settings to grant them the necessary access.",
      items: [
        {
          label: "All workspace members",
          shouldLabelBold: true,
          content: (
            <SettingSwitch
              isLoading={isCompanySettingLoading}
              isChecked={
                !!companySettings.allowSdbAccess &&
                Object.values(companySettings.allowSdbAccess).every(Boolean)
              }
              setIsChecked={(isChecked) =>
                handleUpdateCompanySetting(CompanySettings.allowSdbAccess, {
                  canMembersAccess: isChecked,
                  canGroupManagersAccess: isChecked,
                  canProjectManagersAccess: isChecked,
                  canWorkspaceViewersAccess: isChecked,
                })
              }
            />
          ),
        },
        {
          label: "Group managers",
          content: (
            <SettingSwitch
              isLoading={isCompanySettingLoading}
              isChecked={
                !!(
                  companySettings.allowSdbAccess &&
                  (companySettings.allowSdbAccess as AllowSdbAccess)
                    .canGroupManagersAccess
                )
              }
              setIsChecked={(isChecked) =>
                handleUpdateCompanySetting(CompanySettings.allowSdbAccess, {
                  ...((companySettings.allowSdbAccess as AllowSdbAccess) ??
                    defaultAllowSdbAccess),
                  canGroupManagersAccess: isChecked,
                })
              }
            />
          ),
        },
        {
          label: "Project managers",
          content: (
            <SettingSwitch
              isLoading={isCompanySettingLoading}
              isChecked={
                !!(
                  companySettings.allowSdbAccess &&
                  (companySettings.allowSdbAccess as AllowSdbAccess)
                    .canProjectManagersAccess
                )
              }
              setIsChecked={(isChecked) =>
                handleUpdateCompanySetting(CompanySettings.allowSdbAccess, {
                  ...((companySettings.allowSdbAccess as AllowSdbAccess) ??
                    defaultAllowSdbAccess),
                  canProjectManagersAccess: isChecked,
                })
              }
            />
          ),
        },
        {
          label: "Workspace viewers",
          content: (
            <SettingSwitch
              isLoading={isCompanySettingLoading}
              isChecked={
                !!(
                  companySettings.allowSdbAccess &&
                  (companySettings.allowSdbAccess as AllowSdbAccess)
                    .canWorkspaceViewersAccess
                )
              }
              setIsChecked={(isChecked) =>
                handleUpdateCompanySetting(CompanySettings.allowSdbAccess, {
                  ...((companySettings.allowSdbAccess as AllowSdbAccess) ??
                    defaultAllowSdbAccess),
                  canWorkspaceViewersAccess: isChecked,
                })
              }
            />
          ),
        },
      ],
    },
  ];

  return (
    <BaseSettingsPage
      pageSectionProps={pageSectionsProps}
      dataTestId="ACCESS_CONTROL_SETTINGS_CONTAINER"
    />
  );
}
