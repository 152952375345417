import {
  ExternalMarkupIElement,
  IElement,
  IElementImg2d,
  IElementMarkupBim360,
  IElementMarkupProcoreObservation,
  IElementMarkupProcoreRfi,
  IElementType,
  IElementTypeHint,
  isIElementWithTypeAndHint,
} from "@faro-lotv/ielement-types";
import {
  IElementAreaSection,
  IElementAreaSectionWithUrl,
  IElementMarkupWithTypeHint,
} from "@custom-types/i-elements-types";
import { isValidUrl } from "@utils/url-utils";

/**
 * @returns True iff the IElement is a markup image
 * @param element IElement
 */
export function isIElementMarkupImage(
  element: IElement
): element is IElementImg2d {
  return isIElementWithTypeAndHint(
    element,
    IElementType.img2d,
    IElementTypeHint.markupIssueImage
  );
}

/**
 * @returns True iff the IElement is a IElementMarkupBim360
 * @param element IElement
 */
export function isIElementMarkupBim360(
  element: IElement
): element is IElementMarkupBim360 {
  return element.type === IElementType.markupBim360;
}

/**
 * @returns True iff the IElement is a IElementMarkupProcoreObservation
 * @param element IElement
 */
export function isIElementMarkupProcoreObservation(
  element: IElement
): element is IElementMarkupProcoreObservation {
  return element.type === IElementType.markupProcoreObservation;
}

/**
 * @returns True iff the IElement is a IElementMarkupProcoreObservation
 * @param element IElement
 */
export function isIElementMarkupProcoreRfi(
  element: IElement
): element is IElementMarkupProcoreRfi {
  return element.type === IElementType.markupProcoreRfi;
}

/**
 * @returns True iff the IElement is a ExternalMarkupIElement
 * @param element IElement
 */
export function isExternalMarkupIElement(
  element: IElement
): element is ExternalMarkupIElement {
  return (
    isIElementMarkupBim360(element) ||
    isIElementMarkupProcoreObservation(element) ||
    isIElementMarkupProcoreRfi(element)
  );
}

/**
 * @returns True iff the IElement is a Area Section with a valid slideNodesUrl property
 * @param element IElement
 */
export function isIElementAreaSectionWithUrl(
  element: IElementAreaSection
): element is IElementAreaSectionWithUrl {
  const slideNodesUrl = element.metaDataMap?.slideNodesUrl;
  return isValidUrl(slideNodesUrl);
}

/**
 * @returns True if the iElement is a IElementMarkup with TypeHint Markup
 * @param iElement The element to check
 */
export function isIElementMarkupWithTypeHint(
  iElement: IElement
): iElement is IElementMarkupWithTypeHint {
  return isIElementWithTypeAndHint(
    iElement,
    IElementType.markup,
    IElementTypeHint.markup
  );
}
