import {
  MemberHeaders,
  getRoleHeader,
} from "@components/table/members/members-table-utils";
import { UserAvatarWithMenu } from "@components/user-more-info-menu/user-avatar-with-menu";
import { MemberTypes } from "@custom-types/member-types";
import { Box } from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid-pro";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { withEllipsis } from "@styles/common-styles";
import { DASH } from "@utils/ui-utils";
import { isGroupDetailsMember } from "@custom-types/type-guards";
import { MemberTableSubject } from "@components/common/faro-table/faro-table-types";
import { RoleColumn } from "@components/table/members/members-column/role-column/role-column";
import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { OptionsMemberColumn } from "@components/table/members/members-column/options-member-column";
import { StatusColumn } from "@components/table/members/members-column/status-column/status-column";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { SdbProject } from "@custom-types/project-types";
import { faroTableComparator } from "@components/common/faro-table/faro-table-utils";
import { getMemberStatus } from "@utils/user-utils";
import { formatUserRoleType } from "@utils/data-display";
import { FormatDate } from "@hooks/use-date-time";

interface Props extends BaseCompanyIdProps {
  /** The selected project if there is one */
  project?: SdbProject | null;

  /** Recognizes on which subject the table is being used */
  tableSubject: MemberTableSubject;

  /** The selected group if there is one */
  group?: SphereDashboardAPITypes.IGroupDetails | null;

  /** The selected snapshot if there is one */
  snapshot?: CoreAPITypes.IProjectSnapshot;

  /** The function to format date. Needs to be passed since getMembersColumns is not a hook */
  formatDate: FormatDate;

  options: {
    /** Whether the current screen is large and larger */
    isScreenLgAndLarger: boolean;

    /** Whether the current screen is medium */
    isMedium: boolean;
  };
}

/** Minimum column width in px */
const COLS_MIN_WIDTH_IN_PX = 150;

/**
 * Columns width in xs and sm screen
 */
const xsAndSmColumnsWidthPX = 200;

/**
 * like name, email, status and joined date in medium screens
 */
const mdColumnsWidthInPX = 250;

/** Return all the possible columns for members table */
export function getMembersColumns({
  companyId,
  project,
  tableSubject,
  group,
  snapshot,
  formatDate,
  options,
}: Props): Record<MemberHeaders, GridColDef> {
  return {
    [MemberHeaders.avatar]: {
      field: MemberHeaders.avatar,
      headerName: "",
      width: 70,
      renderCell: (params: GridRenderCellParams<{ entity: MemberTypes }>) => {
        return (
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <UserAvatarWithMenu member={params.row.entity} />
          </Box>
        );
      },
    },

    [MemberHeaders.user]: {
      field: MemberHeaders.user,
      flex: options.isScreenLgAndLarger ? 1 : undefined,
      minWidth: COLS_MIN_WIDTH_IN_PX,
      maxWidth: options.isMedium ? mdColumnsWidthInPX : xsAndSmColumnsWidthPX,
      renderCell: (params: GridRenderCellParams<{ entity: MemberTypes }>) => {
        const entityName: string = params.row.entity.name ?? DASH;
        return (
          <SphereTooltip
            title={<var>{entityName}</var>}
            boxProps={{
              sx: {
                ...withEllipsis,
                fontWeight: "bold",
                cursor: "default",
              },
            }}
            component={"var"}
          >
            <var>{entityName}</var>
          </SphereTooltip>
        );
      },
      valueGetter: (_, row: { entity: MemberTypes }) => row.entity.name,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [MemberHeaders.email]: {
      field: MemberHeaders.email,
      flex: options.isScreenLgAndLarger ? 1 : undefined,
      minWidth: COLS_MIN_WIDTH_IN_PX,
      width: options.isMedium ? mdColumnsWidthInPX : xsAndSmColumnsWidthPX,
      renderCell: (params: GridRenderCellParams<{ entity: MemberTypes }>) => {
        return (
          <SphereTooltip
            boxProps={{
              sx: { ...withEllipsis, cursor: "default" },
            }}
            title={params.row.entity.email}
            component={"var"}
          >
            <var>{params.row.entity.email}</var>
          </SphereTooltip>
        );
      },
      valueGetter: (_, row: { entity: MemberTypes }) => row.entity.email,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [MemberHeaders.status]: {
      field: MemberHeaders.status,
      flex: options.isScreenLgAndLarger ? 1 : undefined,
      minWidth: COLS_MIN_WIDTH_IN_PX,
      width: options.isMedium ? mdColumnsWidthInPX : xsAndSmColumnsWidthPX,
      renderCell: (params: GridRenderCellParams<{ entity: MemberTypes }>) => {
        return (
          <StatusColumn
            companyId={companyId}
            project={project}
            subjectType={tableSubject}
            member={params.row.entity}
          />
        );
      },
      valueGetter: (_, row: { entity: MemberTypes }) =>
        getMemberStatus({ member: row.entity }),
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [MemberHeaders.role]: {
      field: MemberHeaders.role,
      flex: options.isScreenLgAndLarger ? 1 : undefined,
      headerName: getRoleHeader(tableSubject),
      minWidth: COLS_MIN_WIDTH_IN_PX,
      maxWidth: options.isMedium ? mdColumnsWidthInPX : xsAndSmColumnsWidthPX,
      renderCell: (params: GridRenderCellParams<{ entity: MemberTypes }>) => {
        return (
          <RoleColumn
            companyId={companyId}
            selectedProject={project}
            subjectType={tableSubject}
            member={params.row.entity}
            group={group}
          />
        );
      },
      valueGetter: (_, row: { entity: MemberTypes }) =>
        formatUserRoleType(row.entity.role),
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [MemberHeaders.joinedDate]: {
      field: MemberHeaders.joinedDate,
      flex: options.isScreenLgAndLarger ? 1 : undefined,
      minWidth: COLS_MIN_WIDTH_IN_PX,
      width: options.isMedium ? mdColumnsWidthInPX : xsAndSmColumnsWidthPX,
      renderCell: (params: GridRenderCellParams<{ entity: MemberTypes }>) => {
        if (isGroupDetailsMember(params.row.entity)) {
          return (
            <SphereTooltip
              boxProps={{ sx: withEllipsis }}
              shouldShowTooltip={false}
              title={params.row.entity.dateJoined}
            >
              <var>{formatDate(params.row.entity.dateJoined)}</var>
            </SphereTooltip>
          );
        }
      },
      valueGetter: (_, row: { entity: MemberTypes }) =>
        isGroupDetailsMember(row.entity) ? row.entity.dateJoined : undefined,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [MemberHeaders.options]: {
      field: MemberHeaders.options,
      type: "actions",
      align: "right",
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- This is needed for the flex to work properly
      flex: options.isScreenLgAndLarger ? 0.5 : undefined,
      width: 50,
      renderCell: (params: GridRenderCellParams<{ entity: MemberTypes }>) => {
        return (
          <OptionsMemberColumn
            companyId={companyId}
            selectedProject={project}
            subjectType={tableSubject}
            member={params.row.entity}
            group={group}
            snapshot={snapshot}
          />
        );
      },
    },
  };
}
