import { useAppSelector } from "@store/store-helper";
import { searchSelector } from "@store/ui/ui-selector";
import { EmptyPage } from "@components/common/empty-page/empty-page";
import BrowseDuoColorIcon from "@assets/icons/browse-duo-color.svg?react";
import GroupsDuoColorIcon from "@assets/icons/groups-duo-color.svg?react";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";

/** Empty page content for groups page */
export function GroupsEmptyPage(): JSX.Element {
  const { searchText } = useAppSelector(searchSelector);
  const { canCreateGroups } = useHasUserValidRoleCompanyLevel();

  if (searchText) {
    return (
      <EmptyPage
        title="No results found"
        subtitle="There are no groups that match this search."
        icon={BrowseDuoColorIcon}
      />
    );
  } else {
    return (
      <EmptyPage
        title="You don't have a group yet"
        subtitle={
          canCreateGroups ? (
            <>
              You haven't added groups to your workspace. Select the "new group"
              button to create one.
            </>
          ) : (
            <>You haven't added groups to your workspace.</>
          )
        }
        icon={GroupsDuoColorIcon}
      />
    );
  }
}
