import { FaroTextButton } from "@components/common/faro-text-button";
import { MemberTypes } from "@custom-types/member-types";
import { setBulkActionName } from "@store/table/table-slice";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { tableTypeSelector } from "@store/table/table-selector";
import { BulkActionEvents } from "@utils/track-event/track-event-list";
import { getMailHref } from "@utils/email-utils";
import { useTrackEvent } from "@utils/track-event/use-track-event";

interface Props {
  /** The entities that are selected */
  selectedEntities: MemberTypes[];
}

/** Button to send emails to users in bulk */
export function BulkSendEmail({ selectedEntities }: Props): JSX.Element {
  const { trackEvent } = useTrackEvent();
  const dispatch = useAppDispatch();
  const tableType = useAppSelector(tableTypeSelector);

  function sendEmail(): void {
    dispatch(setBulkActionName("sendEmail"));

    if (tableType) {
      trackEvent({
        name: BulkActionEvents.bulkSendEmail,
        props: {
          dataType: tableType,
          numberOfEntities: selectedEntities.length,
        },
      });
    }

    window.location.href = getMailHref({
      members: selectedEntities,
    });

    dispatch(setBulkActionName(null));
  }

  return (
    <FaroTextButton
      onClick={sendEmail}
      tooltipText="Send email to selected members"
      sx={{ fontWeight: 600 }}
    >
      Send email
    </FaroTextButton>
  );
}
