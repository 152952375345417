import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityAdapter,
} from "@reduxjs/toolkit";
import {
  APITypes,
  CoreAPITypes,
  SphereDashboardAPITypes,
} from "@stellar/api-logic";
import {
  BaseEntityState,
  CoreApiWithCompanyIdProps,
  CoreApiWithProjectIdProps,
} from "@store/store-types";
import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { RootState } from "@store/store-helper";
import { BaseMemberProps } from "@custom-types/member-types";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";

/**
 * Stores how many snapshots are returned from the backend per request.
 * We use a very high number so that we don't have to implement pagination.
 * Anyway user's normally don't have that many snapshots.
 */
const API_SNAPSHOTS_PER_REQUEST = 5000;

interface ProjectSnapshot extends BaseProjectIdProps {
  /** Next attribute to fetch the next batch of snapshots */
  next: string | null;

  /** List of project snapshots */
  snapshots: CoreAPITypes.IProjectSnapshot[];
}

/**
 * State of the current snapshots.
 */
export interface SnapshotsState extends BaseEntityState<ProjectSnapshot> {
  /**
   * Map of members that belong to each snapshot
   * Key is the snapshot ID
   * Value is the list of members that belong to the snapshot
   */
  snapshotMembers: {
    [
      key: CoreAPITypes.IProjectSnapshot["id"]
    ]: SphereDashboardAPITypes.IProjectMemberBase[];
  };

  /**
   * Collects which snapshots and members have been fetched from the backend,
   * to avoid fetching them again.
   * This avoids checking if the snapshots array is empty to know if we have to fetch them,
   * because either the array can be empty if the user has no snapshots, or it could have
   * snapshots that were manually added to the store by other thunk but it is missing the rest.
   */
  fetched: {
    /**
     * Indicates if the snapshots have been fetched from the backend.
     * Key is the project ID
     * Value is the fetching status
     */
    hasFetchedSnapshots: {
      [key: CoreAPITypes.IProjectSnapshot["id"]]: boolean;
    };

    /**
     * Map of flags that signal whether snapshot members have been fetched from the backend
     * Key is the snapshot ID
     * Value is the fetching status
     */
    hasFetchedSnapshotMembers: {
      [key: CoreAPITypes.IProjectSnapshot["id"]]: boolean;
    };
  };

  /** Collects all the fetching properties for this slice */
  fetching: {
    /** Indicates if snapshots are currently being fetched from the backend for any project */
    isFetchingSnapshots: boolean;

    /**
     * Map of flags that signal whether snapshot members are currently being fetched from the backend
     * Key is the snapshot ID
     * Value is the fetching status
     */
    isFetchingSnapshotMembers: {
      [key: CoreAPITypes.IProjectSnapshot["id"]]: boolean;
    };

    /** Indicates if a snapshot is currently being deleted */
    isDeletingSnapshot: boolean;

    /** Indicates if the current user is inviting members to a snapshot */
    isInvitingSnapshotMembers: boolean;

    /** Whether a snapshot member is currently being removed from the backend */
    isRemovingSnapshotMember: boolean;
  };
}

/** Creates an entity adapter to store a map with all the snapshots that the user has access to. */
export const snapshotsAdapter: EntityAdapter<ProjectSnapshot> =
  createEntityAdapter({
    selectId: (projectSnapshot) => projectSnapshot.projectId,
  });

const initialState: SnapshotsState = {
  ...snapshotsAdapter.getInitialState(),

  snapshotMembers: {},
  fetched: {
    hasFetchedSnapshots: {},
    hasFetchedSnapshotMembers: {},
  },
  fetching: {
    isFetchingSnapshots: false,
    isFetchingSnapshotMembers: {},
    isDeletingSnapshot: false,
    isInvitingSnapshotMembers: false,
    isRemovingSnapshotMember: false,
  },
};

export const fetchProjectSnapshots = createAsyncThunk<
  ProjectSnapshot,
  CoreApiWithProjectIdProps,
  { state: RootState }
>(
  "snapshots/fetchProjectSnapshots",
  async ({ coreApiClient, projectId }) => {
    try {
      const { data, next } = await coreApiClient.V3.SDB.getProjectSnapshots({
        projectId,
        limit: API_SNAPSHOTS_PER_REQUEST,
      });
      return {
        // The backend has a bug that returns next even if there are no more snapshots to fetch
        next: data.length === API_SNAPSHOTS_PER_REQUEST ? next : null,
        snapshots: data,
        projectId,
      };
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  },
  {
    condition: (arg, api) => {
      return !api.getState().snapshots.fetched.hasFetchedSnapshots[
        arg.projectId
      ];
    },
  }
);

interface FetchSnapshotMembersResponse {
  /** The Snapshot ID */
  snapshotId: CoreAPITypes.IProjectSnapshot["id"];

  /** Members that belong to the snapshot */
  members: SphereDashboardAPITypes.IProjectMemberBase[];
}

interface FetchSnapshotMembersProps extends CoreApiWithCompanyIdProps {
  /** ID of the snapshot */
  snapshotId: CoreAPITypes.IProjectSnapshot["id"];
}

export const fetchSnapshotMembers = createAsyncThunk<
  FetchSnapshotMembersResponse,
  FetchSnapshotMembersProps,
  { state: RootState }
>(
  "snapshots/fetchSnapshotMembers",
  async ({ coreApiClient, companyId, snapshotId }) => {
    try {
      const { projectMembers } = await coreApiClient.V3.SDB.getProjectMembers({
        companyId,
        projectId: snapshotId,
      });

      return {
        snapshotId,
        members: projectMembers,
      };
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  },
  {
    condition: (arg, api) => {
      return !api.getState().snapshots.fetched.hasFetchedSnapshotMembers[
        arg.snapshotId
      ];
    },
  }
);

export interface DeleteSnapshotProps extends CoreApiWithProjectIdProps {
  /** Snapshot id to be deleted */
  snapshotId: APITypes.ProjectId;
}

export interface DeleteSnapshotResult extends BaseProjectIdProps {
  /** Snapshot id to be deleted */
  snapshotId: APITypes.ProjectId;
}

export const deleteProjectSnapshot = createAsyncThunk<
  DeleteSnapshotResult,
  DeleteSnapshotProps
>(
  "snapshots/deleteProjectSnapshot",
  async ({ coreApiClient, projectId, snapshotId }) => {
    try {
      await coreApiClient.V3.SDB.deleteProjectSnapshot({
        originalProjectId: projectId,
        snapshotProjectId: snapshotId,
      });
      return {
        projectId,
        snapshotId,
      };
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

export interface InviteMembersToSnapshotProps
  extends CoreApiWithCompanyIdProps {
  /** Snapshot id to be deleted */
  snapshotId: APITypes.ProjectId;

  /** List of user identities to invite, either userIds or emails to invite to workspace */
  userIdentities: string[];

  /** Role to assign to the invited members */
  role: SphereDashboardAPITypes.IAssignmentProjectRole;
}

interface InviteMembersToSnapshotResult
  extends SphereDashboardAPITypes.IBulkMemberInviteResponse<"project"> {
  /** The id of project that the snapshots belong to */
  snapshotId: APITypes.ProjectId;
}

export const inviteMembersToSnapshot = createAsyncThunk<
  InviteMembersToSnapshotResult,
  InviteMembersToSnapshotProps
>(
  "snapshots/inviteMembersToSnapshot",
  async ({ coreApiClient, companyId, snapshotId, userIdentities, role }) => {
    try {
      const payload: SphereDashboardAPITypes.IBulkMemberInvitePayload<"project"> =
        {
          assignments: [
            {
              role,
              identities: userIdentities,
            },
          ],
        };
      const result = await coreApiClient.V3.SDB.addMembersToProject(
        companyId,
        snapshotId,
        payload
      );
      return {
        snapshotId,
        ...result,
      };
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

interface RemoveMemberFromSnapshotResult extends BaseMemberProps {
  /** ID of the snapshot where the member was removed from */
  snapshotId: CoreAPITypes.IProjectSnapshot["id"];
}

interface RemoveMemberFromSnapshotProps
  extends CoreApiWithCompanyIdProps,
    RemoveMemberFromSnapshotResult {}

export const removeMemberFromSnapshot = createAsyncThunk<
  RemoveMemberFromSnapshotResult,
  RemoveMemberFromSnapshotProps
>(
  "snapshots/removeMemberFromSnapshot",
  async ({ coreApiClient, companyId, snapshotId, member }) => {
    try {
      // The same endpoint for removing project members is used
      await coreApiClient.V3.removeMemberFromProject(
        companyId,
        snapshotId,
        member.identity
      );
      return {
        snapshotId,
        member,
      };
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/** Removes locally (from the store) a member from a snapshot */
function removeMemberFromSnapshotsStore(
  snapshotsState: SnapshotsState,
  result: RemoveMemberFromSnapshotResult
): void {
  const snapshotId = result.snapshotId;
  const memberIdentity = result.member.identity;
  const snapshotMembers = snapshotsState.snapshotMembers;

  // Validate that the snapshot ID exists in the map of members.
  const hasMembers = Object.prototype.hasOwnProperty.call(
    snapshotMembers,
    snapshotId
  );

  if (hasMembers) {
    const updatedMembers = snapshotMembers[snapshotId].filter((member) => {
      return member.identity !== memberIdentity;
    });
    snapshotsState.snapshotMembers[snapshotId] = updatedMembers;
  }
}

/**
 * Slice to access state of loaded snapshots
 */
const snapshotsSlice = createSlice({
  name: "snapshots",
  initialState,
  reducers: {
    /**
     * Accepts a single snapshot entity and adds or replaces it.
     *
     * @param state store state
     * @param entity Snapshot to be set or added to the store.
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    setOne: snapshotsAdapter.setOne,
    /**
     * Accepts an array of snapshot entities, and adds or replaces them.
     *
     * @param state store state
     * @param entity SnapShots to be set or added to the store.
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    setMany: snapshotsAdapter.setMany,
    /**
     * Removes all snapshot entities from the store.
     *
     * @param state store state
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    removeAll: snapshotsAdapter.removeAll,
    /**
     * Accepts an array of snapshot IDs, and removes each snapshot entity with those IDs if they exist.
     *
     * @param state store state
     * @param entity Snapshot Ids to be removed from the store.
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    removeMany: snapshotsAdapter.removeMany,
    /**
     * Accepts a single snapshot IDs, and removes the snapshot entity with that ID if it exists.
     *
     * @param state store state
     * @param entity Snapshot Id to be removed from the store.
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    removeOne: snapshotsAdapter.removeOne,

    /**
     * Resets the state of the snapshots slice.
     *
     * @param state store state
     */
    resetSnapshotsState: () => initialState,
  },

  extraReducers(builder) {
    builder
      .addCase(fetchProjectSnapshots.pending, (state, action) => {
        state.fetching.isFetchingSnapshots = true;
      })
      .addCase(fetchProjectSnapshots.fulfilled, (state, action) => {
        snapshotsAdapter.upsertOne(state, action.payload);
        state.fetched.hasFetchedSnapshots[action.payload.projectId] = true;
        state.fetching.isFetchingSnapshots = false;
      })
      .addCase(fetchProjectSnapshots.rejected, (state, action) => {
        state.fetching.isFetchingSnapshots = false;
      })

      .addCase(fetchSnapshotMembers.pending, (state, action) => {
        const snapshotId = action.meta.arg.snapshotId;
        state.fetching.isFetchingSnapshotMembers[snapshotId] = true;
      })
      .addCase(fetchSnapshotMembers.fulfilled, (state, action) => {
        state.snapshotMembers[action.payload.snapshotId] =
          action.payload.members;

        const snapshotId = action.meta.arg.snapshotId;
        state.fetching.isFetchingSnapshotMembers[snapshotId] = false;
      })
      .addCase(fetchSnapshotMembers.rejected, (state, action) => {
        const snapshotId = action.meta.arg.snapshotId;
        state.fetched.hasFetchedSnapshotMembers[snapshotId] = false;
        state.fetching.isFetchingSnapshotMembers[snapshotId] = false;
      })

      .addCase(deleteProjectSnapshot.pending, (state, action) => {
        state.fetching.isDeletingSnapshot = true;
      })
      .addCase(deleteProjectSnapshot.fulfilled, (state, action) => {
        snapshotsAdapter.updateOne(state, {
          id: action.payload.projectId,
          changes: {
            snapshots: state.entities[
              action.payload.projectId
            ]?.snapshots.filter(
              (snapshot) => snapshot.id !== action.payload.snapshotId
            ),
          },
        });
        state.fetching.isDeletingSnapshot = false;
      })
      .addCase(deleteProjectSnapshot.rejected, (state, action) => {
        state.fetching.isDeletingSnapshot = false;
      })

      .addCase(inviteMembersToSnapshot.pending, (state, action) => {
        state.fetching.isInvitingSnapshotMembers = true;
      })
      .addCase(inviteMembersToSnapshot.fulfilled, (state, action) => {
        const members = action.payload.data.members;
        const snapshotId = action.payload.snapshotId;
        state.fetching.isInvitingSnapshotMembers = false;
        if (members) {
          // The response contains the updated list of all members that belong to the snapshot,
          // therefore we replace the current list of members with the new one.
          state.snapshotMembers[snapshotId] = members;
          state.fetched.hasFetchedSnapshotMembers[snapshotId] = true;
        }
      })
      .addCase(inviteMembersToSnapshot.rejected, (state, action) => {
        state.fetching.isInvitingSnapshotMembers = false;
      })

      .addCase(removeMemberFromSnapshot.pending, (state, action) => {
        state.fetching.isRemovingSnapshotMember = true;
      })
      .addCase(removeMemberFromSnapshot.fulfilled, (state, action) => {
        removeMemberFromSnapshotsStore(state, action.payload);
        state.fetching.isRemovingSnapshotMember = false;
      })
      .addCase(removeMemberFromSnapshot.rejected, (state, action) => {
        state.fetching.isRemovingSnapshotMember = false;
      });
  },
});

export const {
  setOne,
  setMany,
  removeAll,
  removeMany,
  removeOne,
  resetSnapshotsState,
} = snapshotsSlice.actions;

export const snapshotsReducer = snapshotsSlice.reducer;
