import { TableType } from "@utils/track-event/track-event-list";
import { useMemo } from "react";
import {
  EntityForTableType,
  HeaderForTableType,
} from "@components/common/faro-table/faro-table-types";
import { FilterMapId } from "@components/common/faro-table/faro-table-filter/faro-table-filter-types";
import { useFilterQueryParams } from "@hooks/table-filters/use-filter-query-params";
import {
  doesColumnPassAnyFilter,
  doesEntityPassQuickFilter,
} from "@hooks/table-filters/use-apply-filter-utils";

interface Props<Entity, Column extends string> {
  /** The entities to apply the filters */
  entities: Entity[];

  /** All available filters for table */
  allTableFilters: Partial<Record<Column, FilterMapId<Entity>>>;

  /** All the columns that are required for filtering */
  requiredColumns: Column[];

  /** The logic to check for quick filter search. It is case insensitive as the text is converted to lowercase */
  quickFilterEntity: (entity: Entity) => string;
}

/** Apply all the filters to the entities and return the ones that pass */
export function useApplyFilters<
  Table extends TableType,
  Entity extends EntityForTableType<Table>,
  Column extends HeaderForTableType<Table>
>({
  entities,
  allTableFilters,
  requiredColumns,
  quickFilterEntity,
}: Props<Entity, Column>): Entity[] {
  const { quickFilter, isAnyFilterApplied, getFilterIdsForColumn } =
    useFilterQueryParams();

  const filteredEntities = useMemo(() => {
    // If no filter is applied, return all the entities
    if (quickFilter === "" && !isAnyFilterApplied) {
      return entities;
    }

    return entities.filter((entity) => {
      // If quick filter doesn't pass, it returns false and there is no need to check other column specific filters
      if (
        !doesEntityPassQuickFilter({
          quickFilter,
          quickFilterEntity: quickFilterEntity(entity),
        })
      ) {
        return false;
      }

      // At this point, isQuickFilter is passed. If no further column filter is applied, return true
      if (!isAnyFilterApplied) {
        return true;
      }

      // Loop over all the columns and check if at least one filter is passed per column (AND between columns)
      return requiredColumns.every((column: Column) => {
        const allPossibleFiltersForColumn = allTableFilters[column];
        // If no filter is applied for a column by the user or no filter exist in our code for a column, return true
        if (
          getFilterIdsForColumn(column).length === 0 ||
          allPossibleFiltersForColumn === undefined
        ) {
          return true;
        }

        // Loop over active filters in a column and check at least one of them pass (OR between filters of a column)
        return doesColumnPassAnyFilter({
          entity,
          activeFilterIds: getFilterIdsForColumn(column),
          allPossibleFiltersForColumn,
        });
      });
    });
  }, [
    allTableFilters,
    entities,
    getFilterIdsForColumn,
    isAnyFilterApplied,
    quickFilter,
    quickFilterEntity,
    requiredColumns,
  ]);

  return filteredEntities;
}
