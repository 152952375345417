import { useCoreApiClient } from "@api/use-core-api-client";
import { useAppNavigation } from "@hooks/use-app-navigation";
import { QueryParams } from "@router/route-params";
import { useAppParams } from "@router/router-helper";
import {
  selectedAnalyticsProjectSelector,
  selectedAnalyticsTimeFrameSelector,
} from "@store/analytics/analytics-selector";
import {
  setSelectedAnalyticsTimeFrame,
  fetchProjectStats,
  setSelectedAnalyticsProjectId,
  DEFAULT_TIME_FRAME,
} from "@store/analytics/analytics-slice";
import { projectIdsSelector } from "@store/projects/projects-selector";
import { fetchProjectDetails } from "@store/projects/projects-slice-thunk";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { AVAILABLE_TIME_FRAMES } from "@utils/time-utils";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/** Specify base number to parse integers from */
const RADIX = 10;

/**
 * Hook that listens to changes in the URL and updates the selected project
 * and selected time frame for the analytics page.
 */
export function useAnalytics(): void {
  const location = useLocation();
  const { getQueryParam } = useAppNavigation();
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const { companyId } = useAppParams();
  const selectedAnalyticsProject = useAppSelector(
    selectedAnalyticsProjectSelector
  );
  const selectedAnalyticsTimeFrame = useAppSelector(
    selectedAnalyticsTimeFrameSelector
  );
  const { setUrlParam } = useAppNavigation();
  const projectIds = useAppSelector(projectIdsSelector);

  // Listen to changes in the url and update the selected time frame
  useEffect(() => {
    const selectedTimeFrameQuery = getQueryParam(QueryParams.timeFrame);
    if (!selectedTimeFrameQuery && selectedAnalyticsTimeFrame) {
      setUrlParam({
        key: QueryParams.timeFrame,
        value: selectedAnalyticsTimeFrame.toString(),
      });
      // Early return because the url will change and this useEffect will be called again
      return;
    }

    // If there's no time frame in the url we don't need to set anything else
    if (!selectedTimeFrameQuery) {
      return;
    }

    const newSelectedTimeFrame = parseInt(selectedTimeFrameQuery, RADIX);
    // Makes sure the user can't set an invalid time frame
    if (!AVAILABLE_TIME_FRAMES.includes(newSelectedTimeFrame)) {
      setUrlParam({
        key: QueryParams.timeFrame,
        value: DEFAULT_TIME_FRAME.toString(),
      });
      // Early exit since changing the url will trigger this useEffect again
      return;
    }
    dispatch(setSelectedAnalyticsTimeFrame(newSelectedTimeFrame));
  }, [selectedAnalyticsTimeFrame, getQueryParam, setUrlParam, dispatch]);

  // Listen to changes in the url and update the selected project
  useEffect(() => {
    /**
     * Resets the selected project to all projects.
     */
    function resetSelectedProject(): void {
      setUrlParam({
        key: QueryParams.projectId,
        value: "all",
      });
    }

    /**
     * Fetches the project details for the selected project if it's not already in the list of projects.
     * If fails to fetch the project details, resets the selected project to all projects.
     */
    async function fetchSelectedProjectDetails(
      projectId: string | null
    ): Promise<void> {
      // If we don't have the project in the list of projects, we need to fetch it,
      // otherwise we can't show the name in the selection.
      if (companyId && projectId && !projectIds.includes(projectId)) {
        try {
          await dispatch(
            fetchProjectDetails({
              coreApiClient,
              companyId,
              projectId,
            })
          ).unwrap();
        } catch (error) {
          resetSelectedProject();
          // Early exit since changing the url will trigger this useEffect again
          return;
        }
      }
    }

    /**
     * Fetches the project stats for the selected project.
     * If fails to fetch the project stats, resets the selected project to all projects.
     */
    async function fetchSelectedProjectStats(
      projectId: string | null
    ): Promise<void> {
      if (projectId && companyId) {
        try {
          await dispatch(
            fetchProjectStats({
              coreApiClient,
              projectId,
              companyId,
            })
          ).unwrap();
        } catch (error) {
          resetSelectedProject();
          // Early exit since changing the url will trigger this useEffect again
          return;
        }
      }
    }

    async function fetchProjectDetailsAndStats(): Promise<void> {
      const queryProjectId = getQueryParam(QueryParams.projectId);
      if (!queryProjectId && selectedAnalyticsProject) {
        setUrlParam({
          key: QueryParams.projectId,
          value: selectedAnalyticsProject.id?.toString() ?? "all",
        });
        // Early return because the url will change and this useEffect will be called again
        return;
      }

      // If there's no project in the url we don't need to set or fetch anything else
      if (!queryProjectId) {
        return;
      }

      const projectId = queryProjectId === "all" ? null : queryProjectId;

      dispatch(setSelectedAnalyticsProjectId(projectId));

      // Since the project details are irrelevant for the project stats, we can fetch them in parallel.
      await Promise.all([
        fetchSelectedProjectDetails(projectId),
        fetchSelectedProjectStats(projectId),
      ]);
    }

    fetchProjectDetailsAndStats();
  }, [
    location,
    companyId,
    coreApiClient,
    projectIds,
    selectedAnalyticsProject,
    dispatch,
    setUrlParam,
    getQueryParam,
  ]);
}
