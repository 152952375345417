import { Grid } from "@mui/material";
import { MemberDetails } from "@pages/project-details/project-overview/member-details";
import { MainProjectDetails } from "@pages/project-details/project-overview/main-project-details";
import { DateDetails } from "@pages/project-details/project-overview/date-details";
import { SecondaryProjectDetails } from "@pages/project-details/project-overview/secondary-project-details";
import { ProjectDescription } from "@pages/project-details/project-overview/project-description";
import { CSSProperties, useMemo } from "react";
import { useAppSelector } from "@store/store-helper";
import { isSelectedProjectEditableSelector } from "@store/projects/projects-selector";
import { SdbProject } from "@custom-types/project-types";
import { ProjectThumbnailImage } from "@pages/project-details/project-overview/project-thumbnail/project-thumbnail-image";
import { CommonStyles } from "@styles/common-styles";
import { ProjectChart } from "@pages/project-details/project-overview/project-chart";
import { RequiredRoleProjectLevelName } from "@utils/access-control/project/project-access-control-types";
import { useHasUserValidRoleProjectLevel } from "@hooks/access-control/use-has-user-valid-role-project-level";

interface Props {
  /** The project to display in the ProjectOverview */
  project?: SdbProject | null;

  /** Whether the project, details and/or project context are being fetched */
  isLoading: boolean;
}

/** Defines the border style for all individual sections */
const borderStyle: CSSProperties = {
  border: CommonStyles.Borders.gray200Divider,
};

/** Defines the default padding inside each individual section */
const defaultPadding: CSSProperties = {
  padding: "20px",
};

/**
 * Defines the separation between individual sections.
 * To keep things centered the separation is applied to both sides,
 * meaning that the actual separation will be two times this value.
 */
const boxesSeparation = "10px";

/**
 * Contains the Overview tab of the project details
 */
export function ProjectOverview({ project, isLoading }: Props): JSX.Element {
  const isProjectEditable = useAppSelector(isSelectedProjectEditableSelector());
  const { hasUserPermissionProjectLevel } = useHasUserValidRoleProjectLevel();

  const shouldShowSkeleton = isLoading || !project;

  const shouldShowStats = useMemo(() => {
    // Early exit with false while the project and its context is being fetched/loaded
    if (isLoading) {
      return false;
    }

    return hasUserPermissionProjectLevel({
      roleName: RequiredRoleProjectLevelName.canViewProjectsStats,
      selectedProject: project,
    });
  }, [hasUserPermissionProjectLevel, isLoading, project]);

  const shouldShowTeamMembers = useMemo(() => {
    // Early exit with false while the project and its context is being fetched/loaded
    if (isLoading) {
      return false;
    }

    return hasUserPermissionProjectLevel({
      roleName: RequiredRoleProjectLevelName.canViewAllProjectMembers,
      selectedProject: project,
    });
  }, [hasUserPermissionProjectLevel, isLoading, project]);

  return (
    <>
      {/* Page info bar for project name, group and project manager */}
      <MainProjectDetails
        project={project}
        isProjectEditable={isProjectEditable}
        isLoading={shouldShowSkeleton}
      />
      {/* Grid for the whole page */}
      <Grid container sx={{ mt: "12px" }} width={"100%"}>
        {/* Left side */}
        <Grid item columns={12} xs={12} md={5}>
          {/* Project details like date, address */}
          <Grid
            sx={{
              ...defaultPadding,
              ...borderStyle,
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* Date details like creation date and last captured */}
            <DateDetails project={project} isLoading={shouldShowSkeleton} />

            {/* Secondary details to show client, address, access level and area */}
            <SecondaryProjectDetails
              project={project}
              isProjectEditable={isProjectEditable}
              isLoading={shouldShowSkeleton}
            />

            {/* Project member avatars */}
            {/* Only show project members if the members property is defined in the project details object */}
            {shouldShowTeamMembers &&
              !shouldShowSkeleton &&
              project.members.length > 0 && (
                <Grid
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                  }}
                >
                  <MemberDetails />
                </Grid>
              )}
          </Grid>
        </Grid>

        {/* Right side */}
        <Grid
          container
          columns={12}
          item
          xs={12}
          md={7}
          sx={{
            paddingTop: {
              xs: boxesSeparation,
              md: 0,
            },
            paddingLeft: {
              md: boxesSeparation,
            },
          }}
        >
          {/* Left part of right side */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            sx={{
              paddingBottom: {
                xs: boxesSeparation,
              },
              paddingRight: {
                lg: boxesSeparation,
              },
              height: "auto",
            }}
          >
            {/* Project description */}
            <Grid
              sx={{
                ...defaultPadding,
                ...borderStyle,
                height: "100%",
              }}
            >
              <ProjectDescription
                project={project}
                isProjectEditable={isProjectEditable}
                isLoading={shouldShowSkeleton}
              />
            </Grid>
          </Grid>
          {/* Right part of right side */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            sx={{
              ...borderStyle,
              mb: boxesSeparation,
              height: "auto",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ProjectThumbnailImage
              project={project}
              isLoading={shouldShowSkeleton}
              isProjectEditable={isProjectEditable}
            />
          </Grid>
          {/* Project stats chart */}
          {shouldShowStats && (
            <Grid
              item
              xs={12}
              sx={{
                ...borderStyle,
                ...defaultPadding,
              }}
            >
              <ProjectChart isLoading={shouldShowSkeleton} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}
