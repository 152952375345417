import { useCoreApiClient } from "@api/use-core-api-client";
import { FaroTextField } from "@components/common/faro-text-field/faro-text-field";
import { PageInfoBar } from "@components/common/page-info-bar";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { selectedProjectManagerSelector } from "@store/projects/projects-selector";
import { updateProjectDetails } from "@store/projects/projects-slice-thunk";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { MIN_PROJECT_NAME_LENGTH } from "@utils/project-utils";
import { GroupAndProjectManagerField } from "@pages/project-details/project-overview/change-management/group-and-project-manager-field";
import { ChangeProjectDetailsEvents } from "@utils/track-event/track-event-list";
import { SdbProject } from "@custom-types/project-types";
import { useTrackEvent } from "@utils/track-event/use-track-event";

interface Props {
  /** Whether the project is editable or not */
  isProjectEditable: boolean;

  /** The project to display in the ProjectOverview */
  project?: SdbProject | null;

  /** Whether the project is still loading and skeleton should be shown instead */
  isLoading: boolean;
}

/**
 * Shows the main details for a project. These include the project name, manager, group and access level
 * They are displayed at the top of the page, inside a gray box
 */
export function MainProjectDetails({
  isProjectEditable,
  project,
  isLoading,
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const selectedProjectManager = useAppSelector(selectedProjectManagerSelector);
  const { handleErrorWithToast } = useErrorContext();
  const { trackEvent } = useTrackEvent();

  /**
   * Updates the project name in the backend and returns the new value if successful.
   * Otherwise, returns the old value.
   *
   * @param value The new project name.
   */
  async function onUpdateName(value: string): Promise<string> {
    trackEvent({
      name: ChangeProjectDetailsEvents.changeProjectName,
      props: { newNameLength: value.length },
    });

    try {
      await dispatch(
        updateProjectDetails({
          coreApiClient,
          payload: { name: value },
        })
      );
      return value;
    } catch (error) {
      handleErrorWithToast({
        id: `changeName-${Date.now().toString()}`,
        title: "Could not change name",
        error,
      });
      return project?.name ?? "";
    }
  }

  if (isLoading || !project) {
    return <PageInfoBar isLoading={true} />;
  }

  return (
    <PageInfoBar
      items={[
        {
          content: (
            <FaroTextField
              label="Project"
              initialValue={project.name}
              faroVariant="main"
              fontSize="18px"
              isReadOnly={!isProjectEditable}
              shouldShowTooltip
              minInputLength={MIN_PROJECT_NAME_LENGTH}
              onConfirmed={onUpdateName}
            />
          ),
        },
        {
          content: (
            <GroupAndProjectManagerField
              project={project}
              projectManager={selectedProjectManager}
            />
          ),
        },
      ]}
    />
  );
}
