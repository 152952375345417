import { ElementType } from "react";
import USIcon from "@assets/icons/new/us_26px.svg?react";
import EUIcon from "@assets/icons/new/eu_26px.svg?react";
import { serverRegion } from "@utils/env-utils";

interface UseAppServer {
  /** Code for the server */
  server: "eu" | "us";

  /** The display name to show to the user about the server */
  serverDisplayName: string;

  /** The icon to show to the user about the server */
  serverIcon: ElementType;
}

/**
 * Hooks that returns everything related to the server the app is running on.
 */
export function useAppServer(): UseAppServer {
  return {
    server: serverRegion,
    serverDisplayName: `${serverRegion.toUpperCase()} Server`,
    serverIcon: serverRegion === "eu" ? EUIcon : USIcon,
  };
}
