import { PropsWithChildren, useEffect, useState } from "react";
import { isInitialNetworkStatusOnline } from "@context-providers/network-connection/network-connection-provider-utils";
import { NetworkConnectionContext } from "@context-providers/network-connection/network-connection-context";
import { useToast } from "@hooks/use-toast";

/** Wrapper component that provides network connection information to the application */
export function NetworkConnectionProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const { showToast } = useToast();

  const [isOnline, setIsOnline] = useState<boolean>(
    isInitialNetworkStatusOnline()
  );

  /**
   * Listens to "online" and "offline" window events to know if the application lost or gained network connection.
   * It then sets the current connection status and shows a toast to inform the user about the status change.
   */
  useEffect(() => {
    function showOnlineToast(): void {
      showToast({
        message: "The application is back online.",
        type: "success",
        shouldAutoHide: true,
      });
    }

    function showOfflineToast(): void {
      showToast({
        message: "Network connection lost.",
        description:
          "The application will not work properly until the network connection is restored.",
        type: "error",
        shouldAutoHide: true,
      });
    }

    function handleConnectionStatusChange(isNewStatusOnline: boolean): void {
      // Early exit if the new status is the same as the current status
      if (isNewStatusOnline === isOnline) {
        return;
      }

      setIsOnline(isNewStatusOnline);
      isNewStatusOnline ? showOnlineToast() : showOfflineToast();
    }

    window.addEventListener("online", () => handleConnectionStatusChange(true));
    window.addEventListener("offline", () =>
      handleConnectionStatusChange(false)
    );

    return () => {
      window.removeEventListener("online", () =>
        handleConnectionStatusChange(true)
      );
      window.removeEventListener("offline", () =>
        handleConnectionStatusChange(false)
      );
    };
  }, [isOnline, showToast]);

  return (
    <NetworkConnectionContext.Provider
      value={{
        isOnline,
      }}
    >
      {children}
    </NetworkConnectionContext.Provider>
  );
}
