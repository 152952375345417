import { splashScreenList } from "@components/splash-screen/splash-screen-data";
import { shouldShowSplashScreen } from "@components/splash-screen/splash-screen-utils";
import { SplashScreen } from "@components/splash-screen/splash-screen";
import { useMemo, useState } from "react";
import { useAppSelector } from "@store/store-helper";
import {
  currentUserSelector,
  splashScreenTopicsByStatusSelector,
} from "@store/user/user-selector";
import {
  selectedCompanyContextSelector,
  selectedSdbCompanySelector,
} from "@store/sdb-company/sdb-company-selector";
import { APITypes } from "@stellar/api-logic";

/** Showing the appropriate splash screen topic to the user */
export function SplashScreenManager(): JSX.Element | null {
  const [isAnyScreenShownInSession, setIsAnyScreenShownInSession] =
    useState<boolean>(false);

  const currentUser = useAppSelector(currentUserSelector);
  const unseenSplashScreenTopics = useAppSelector(
    splashScreenTopicsByStatusSelector("unseen")
  );
  const companyContextSelector = useAppSelector(selectedCompanyContextSelector);
  const selectedCompany = useAppSelector(selectedSdbCompanySelector);

  /** Splash Topic that is showing to the user. It is achieved from the unseen screens that user has the right to see */
  const activeTopic = useMemo(() => {
    // For now, hide the splash screen for trial users
    if (
      currentUser === null ||
      companyContextSelector?.userRoles.includes(
        APITypes.EUserSubscriptionRole.user_role_fm_trial
      )
    ) {
      return null;
    }

    const newSplashScreenTopics = unseenSplashScreenTopics.filter((topic) => {
      return shouldShowSplashScreen({
        topic,
        userRole: currentUser.role,
        company: selectedCompany,
      });
    });

    return newSplashScreenTopics[0] ?? null;
  }, [
    companyContextSelector,
    currentUser,
    unseenSplashScreenTopics,
    selectedCompany,
  ]);

  // Only show one splash screen at any session
  if (activeTopic === null || isAnyScreenShownInSession) {
    return null;
  }

  // Key is used to re-render the splash screen when the active topic changes,
  // preventing the usage of useMemo for same screen number
  return (
    <SplashScreen
      key={activeTopic}
      splashScreen={splashScreenList[activeTopic]}
      onSplashScreenClosed={() => setIsAnyScreenShownInSession(true)}
    />
  );
}
