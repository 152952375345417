import { useAppSelector } from "@store/store-helper";
import { currentUserSelector } from "@store/user/user-selector";
import { hasUserValidRoleGroupLevel } from "@utils/access-control/group/group-access-control";
import {
  HasUserValidRoleGroupLevelProps,
  RequiredRoleGroupLevelName,
} from "@utils/access-control/group/group-access-control-types";

export type ReturnProps = {
  [key in RequiredRoleGroupLevelName]: boolean;
};

/** Checks whether a user has permission for an action on group level or not */
export function useHasUserValidRoleGroupLevel({
  selectedGroup,
}: Pick<
  HasUserValidRoleGroupLevelProps<RequiredRoleGroupLevelName>,
  "selectedGroup"
>): ReturnProps {
  const currentUser = useAppSelector(currentUserSelector);

  const canEditGroupDetails = hasUserValidRoleGroupLevel({
    currentUser,
    roleName: RequiredRoleGroupLevelName.canEditGroupDetails,
    selectedGroup,
  });

  const canInviteUsersToGroup = hasUserValidRoleGroupLevel({
    roleName: RequiredRoleGroupLevelName.canInviteUsersToGroup,
    currentUser,
    selectedGroup,
  });

  const canEditMember = hasUserValidRoleGroupLevel({
    currentUser,
    roleName: RequiredRoleGroupLevelName.canEditMember,
    selectedGroup,
  });

  const canDeleteMemberFromGroup = hasUserValidRoleGroupLevel({
    currentUser,
    roleName: RequiredRoleGroupLevelName.canDeleteMemberFromGroup,
    selectedGroup,
  });

  return {
    canEditGroupDetails,
    canInviteUsersToGroup,
    canEditMember,
    canDeleteMemberFromGroup,
  };
}
