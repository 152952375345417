import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import GenericCheck from "@assets/icons/generic-check_l.svg?react";
import { CompanyAvatar } from "@components/common/company-avatar";
import { isSdbCompanySelectedSelector } from "@store/sdb-company/sdb-company-selector";
import { useAppSelector } from "@store/store-helper";
import { SphereDivider } from "@components/common/sphere-divider";
import { Fragment } from "react";
import { SdbCompany } from "@custom-types/sdb-company-types";
import { withEllipsis } from "@styles/common-styles";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { CoreAPITypes } from "@stellar/api-logic";

interface Props {
  /** Workspace or company to be displayed. */
  sdbCompany: SdbCompany;

  /** Flag whether a divider should be shown after the sdbCompany name */
  shouldShowDivider?: boolean;
}

/**
 * Renders a simple sdbCompany inside the sdbCompanies menu.
 *
 * @returns sdbCompany item react component.
 */
export function SdbCompanyMenuItem({
  sdbCompany,
  shouldShowDivider,
}: Props): JSX.Element {
  const isSdbCompanySelected = useAppSelector(
    isSdbCompanySelectedSelector(sdbCompany.id)
  );

  return (
    <Fragment key={sdbCompany.id}>
      <MenuItem
        component="a"
        data-testid="SIDEBAR_WORKSPACE_ITEM"
        href={sdbCompany.url}
        sx={{
          paddingY: "4px",
          paddingX: "8px",
          opacity: 1,
          "&.Mui-disabled": {
            opacity: 1,
          },
        }}
        disabled={isSdbCompanySelected}
      >
        <Box
          component="div"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          sx={{
            backgroundColor: isSdbCompanySelected
              ? sphereColors.gray100
              : "none",
            color: isSdbCompanySelected
              ? sphereColors.blue500
              : sphereColors.gray800,
            borderRadius: "8px",
            paddingY: "4px",
            paddingX: "8px",
          }}
        >
          <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
            <Box component="div">
              <CompanyAvatar
                name={sdbCompany.name}
                color={
                  sdbCompany.type === CoreAPITypes.EWorkspaceType.admin
                    ? sphereColors.black
                    : sphereColors.blue500
                }
                size="small"
              />
            </Box>
            <SphereTooltip
              title={<var>{sdbCompany.name}</var>}
              shouldSkipWrapper
            >
              <Box
                component="div"
                sx={{
                  ...withEllipsis,
                  marginTop: "2px",
                  marginLeft: "7px",
                  fontSize: "13px",
                  // Ensure that it does not exceed the available screen space.
                  maxWidth: "calc(100vw - 110px)",
                }}
              >
                <var>{sdbCompany.name}</var>
              </Box>
            </SphereTooltip>
          </Box>
          {isSdbCompanySelected && (
            <Box component="div" marginBottom="-5px" marginLeft="2px">
              <GenericCheck
                width="20px"
                height="20px"
                fill={sphereColors.blue500}
              />
            </Box>
          )}
        </Box>
      </MenuItem>
      {/* Adds a divider between the selected sdbCompany and the rest, if there is more than one */}
      {shouldShowDivider && <SphereDivider />}
    </Fragment>
  );
}
