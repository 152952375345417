import { CoreAPITypes, ProjectJson } from "@stellar/api-logic";

interface RequiredRoleSnapshotLevel {
  /**
   * Array filled with snapshot permissions where the user
   * should have one of them in order to have access.
   */
  permissions: ProjectJson.EProjectPermission[];
}

/**
 * Object that determines all different permission rules for users to get
 * access on the snapshot level.
 */
export type RequiredRolesSnapshotLevel<RoleNameT extends string> = {
  [key in RoleNameT]: RequiredRoleSnapshotLevel;
};

/**
 * Signature for the function hasUserValidRoleSnapshotLevel.
 */
export interface HasUserValidRoleSnapshotLevelProps {
  /**
   * The internal name used in the UI to determine certain permissions,
   */
  roleName: RequiredRoleSnapshotLevelName;

  /**
   * The snapshot.
   */
  selectedSnapshot: CoreAPITypes.IProjectSnapshot | null;
}

/** Names for all permissions or roles within the snapshot level. */
export enum RequiredRoleSnapshotLevelName {
  /**
   * Whether the user is an admin in the snapshot, meaning it can delete it,
   * invite and view members.
   */
  isSnapshotAdmin = "isSnapshotAdmin",
}
