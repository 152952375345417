import {
  APITypes,
  CoreAPITypes,
  DashboardAPITypes,
  ProjectJson,
  SphereDashboardAPITypes,
} from "@stellar/api-logic";

/**
 * Enum for general archiving states of projects. This is only used in the dashboard
 * and does not refer to the API response, for that look at ArchivingState.
 */
export enum ProjectArchivingState {
  /** State of projects that will be shown in the active projects tab */
  active = "active",

  /** State of projects that will be shown in the archived projects tab */
  archived = "archived",
}

/** All project attribute names that can be used for sorting the projects list  */
export type ProjectSortByAttrName = Extract<
  keyof SdbProject,
  "name" | "modifiedAt"
>;

/** Base type for project */
export interface BaseProjectProps {
  /** A single project to be passed as a prop */
  project: SdbProject;
}

/** Base type for list of projects */
export interface BaseProjectsProps {
  /** A single project to be passed as a prop */
  projects: SdbProject[];
}

/** Project access level to display in readable text */
export const AccessLevelDisplayNames: {
  [key in SphereDashboardAPITypes.EAccessLevel]: string;
} = {
  [SphereDashboardAPITypes.EAccessLevel.private]: "Private",
  [SphereDashboardAPITypes.EAccessLevel.unlisted]: "Via link",
};

/** Project Settings type */
export type ProjectSettings = {
  /** Whether the markups/annotations should be visible for project viewers */
  shouldDisplayMarkupsForViewers?: boolean;
};

/** Type for the group that the project belongs to */
export type ProjectGroup = {
  /** Group ID */
  id: APITypes.GroupId;

  /** Group name */
  name: string;
};

/**
 * This type covers all project types returned from different routes
 * The purpose of it is to remove the confusion between all these types
 */
export type SdbProject = Pick<
  SphereDashboardAPITypes.ICompanyProject,
  | "accessLevel"
  | "archivingState"
  | "address"
  | "createdAt"
  | "id"
  | "modifiedAt"
  | "name"
  | "permissions"
  | "tags"
> &
  Pick<SphereDashboardAPITypes.ISearchedProject, "area"> & {
    /** The name of the project's client */
    clientName: string | null;

    /** Any description for the project. It will only be shown if available */
    description: string | null;

    /** Evaluation data of the project */
    evaluation: DashboardAPITypes.IEvaluation | null;

    /** List of all the features that can be assigned to a project and whether they are enabled or not */
    features:
      | {
          [T in APITypes.EUserSubscriptionRole]: ProjectJson.IProjectFeatureFlag;
        }
      | null;

    /**
     * All the features that are available based on existing plans with flags showing whether
     * they are enabled and permitted or not
     */
    featuresAvailable: SphereDashboardAPITypes.IFeature[] | null;

    /** Points to the location where the zip file of the projectContainer is stored */
    fileUrl: string | null;

    /** The group in which the project belongs to */
    group: ProjectGroup | null;

    /** Timestamp of last project's capture */
    lastCapturedAt: APITypes.UnixTimestamp | null;

    /** Timestamp of last project download */
    lastDownloadedAt: APITypes.UnixTimestamp | null;

    /** The user and company assigned as managers to the project */
    managers: {
      /** The project manager */
      projectManager: SphereDashboardAPITypes.IUserAsManager | null;

      /** The company manager */
      companyManager: SphereDashboardAPITypes.IUserAsManager | null;
    };

    /**
     * The users added to the project
     * This property is passed to the server response only if the requesting user has the project admin role
     */
    members: SphereDashboardAPITypes.IProjectMemberBase[];

    /** Role of this user on the project */
    role: CoreAPITypes.EUserProjectRole | null;

    /** Whether or not the project is standalone */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    standalone: boolean | null;

    /** Link to the project thumbnail */
    thumbnailUrl: string | null;
  };
