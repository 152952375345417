import { useMarkupContext } from "@context-providers/markup/markup-context";
import { BaseMarkupProps } from "@custom-types/project-markups-types";
import { Box, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import Attachment from "@assets/icons/new/attachment.svg?react";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { EDecimalToHex, sphereColors } from "@styles/common-colors";
import { addTransparency } from "@utils/ui-utils";

/**
 * The MarkupAttachments component shows the number of attachments and displays a different
 * message when hovered.
 * When clicked, it opens a side panel and updates the selected markup.
 *
 * @param {BaseMarkupProps} markup - The markup object containing the attachments.
 * @returns {JSX.Element} The rendered component displaying the number of attachments and an icon.
 */
export function MarkupAttachments({ markup }: BaseMarkupProps): JSX.Element {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const numberOfAttachments = useMemo(
    () => markup.attachments.length,
    [markup.attachments.length]
  );

  const { updateSelectedMarkup, updateIsSidePanelOpen, updateActiveTab } =
    useMarkupContext();

  return (
    <Box
      component="div"
      sx={{ pl: "10px", display: "flex", alignItems: "center" }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={(event) => {
        event.stopPropagation();

        updateIsSidePanelOpen(true);
        updateSelectedMarkup(markup);
        updateActiveTab("Attachments");
      }}
      data-testid="markup-attachments"
    >
      <Box
        component={"div"}
        sx={{ display: "flex", alignItems: "center", gap: "10px" }}
        data-testid="markup-attachments-content"
      >
        <FaroIconButton
          component={Attachment}
          buttonSize="22px"
          iconSize="14px"
          color={isHovered ? sphereColors.blue500 : sphereColors.gray400}
          iconButtonProps={{
            sx: {
              backgroundColor: isHovered
                ? addTransparency({
                    color: sphereColors.gray500,
                    alpha: EDecimalToHex.forty,
                  })
                : "none",
            },
          }}
        />

        {!isHovered && numberOfAttachments}
      </Box>

      {isHovered && (
        <Typography
          component="span"
          sx={{
            pl: "8px",
            fontSize: "12px",
            color: sphereColors.blue500,
            textDecoration: "underline",
          }}
          data-testid="markup-attachments-label"
        >
          {numberOfAttachments ? "View attachments" : "Add attachments"}
        </Typography>
      )}
    </Box>
  );
}
