import { ProjectJson } from "@stellar/api-logic";
import {
  HasUserValidRoleSnapshotLevelProps,
  RequiredRoleSnapshotLevelName,
  RequiredRolesSnapshotLevel,
} from "@utils/access-control/snapshot/snapshot-access-control-types";

/**
 * Object that determines all different permission rules for users to get
 * access on the company level.
 */
const requiredRolesSnapshotLevel: RequiredRolesSnapshotLevel<RequiredRoleSnapshotLevelName> =
  {
    [RequiredRoleSnapshotLevelName.isSnapshotAdmin]: {
      permissions: [
        ProjectJson.EProjectPermission.all,
        ProjectJson.EProjectPermission.admin,
      ],
    },
  };

/**
 * Checks whether a user has permissions within the snapshot level.
 *
 * @returns True if the user has a valid snapshot level role or permission.
 */
export function hasUserValidRoleSnapshotLevel({
  roleName,
  selectedSnapshot,
}: HasUserValidRoleSnapshotLevelProps): boolean {
  if (selectedSnapshot === null) {
    return false;
  }

  const requiredPermissions = requiredRolesSnapshotLevel[roleName].permissions;
  const userPermissions = selectedSnapshot.permissions;

  // Early exit with access if there are no required permissions
  if (requiredPermissions.length === 0) {
    return true;
  }

  // If user does not have permissions then block access
  if (userPermissions.length === 0) {
    return false;
  }

  // User should have access if they have at least one of the required permissions
  return requiredPermissions.some((permission) =>
    userPermissions.includes(permission)
  );
}
