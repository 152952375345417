import { BaseMemberProps, MemberTypes } from "@custom-types/member-types";
import { getPrettyName, isMemberActive } from "@utils/user-utils";

export interface AutoCompleteMenuOption {
  /** Displays in autocomplete dropdown and chips */
  label: string;

  /** Unique value of the list option */
  value: string;

  /** True when the option should be disabled in dropdown */
  isDisabled?: boolean;

  /** Message besides the disabled option explaining the reasoning */
  disabledMessage?: string;

  /**
   * True when the option should be already preselected on initialization.
   * Preselected options will appear in the chips and cannot be deleted.
   */
  isPreselected?: boolean;

  /** If the selected member is an object, we keep the original member object. */
  originalMember: MemberTypes | null;
}

/** Defines the type for a single selected option */
export type SelectedOption = AutoCompleteMenuOption | string;

/** Returns whether the provided option is new to the workspace, meaning that an email was added */
function isOptionNew(option: SelectedOption): option is string {
  return typeof option === "string";
}

/**
 * Gets the label for each member to be provided in the options array.
 * The label is never displayed in the UI but it is used as an Id to filter the options.
 */
export function getMemberLabel({ member }: BaseMemberProps): string {
  return isMemberActive(member)
    ? `${getPrettyName(member)}#${member.email}`
    : member.email;
}

/** Returns either the label for the option, or the option itself */
export function getOptionLabel(option: SelectedOption): string {
  return isOptionNew(option) ? option : option.label;
}

/** Returns the title to be used which is normally the name for existing users and email for new users */
export function getOptionTitle(option: SelectedOption): string {
  return isOptionNew(option) ? option : getPrettyName(option.originalMember);
}

/** Returns the subtitle to be used which is normally the email for existing and active users */
export function getOptionSubtitle(option: SelectedOption): string {
  if (
    isOptionNew(option) ||
    !option.originalMember ||
    !isMemberActive(option.originalMember)
  ) {
    return "";
  }
  return option.originalMember.email;
}

/** Returns the value for the option, which is normally the identity for existing users */
export function getOptionValue(option: SelectedOption): string {
  return isOptionNew(option) ? option : option.value;
}

/**
 * Returns whether the option should be disabled or it is was preselected.
 * For new users they are never disabled.
 */
export function getOptionDisabled(option: SelectedOption): boolean {
  if (isOptionNew(option)) {
    return false;
  }
  // Preselected props are treated as disabled because they can't be removed
  return (option.isDisabled || option.isPreselected) ?? false;
}

/** Returns the disabled message for the option, or an empty string */
export function getOptionDisabledMessage(option: SelectedOption): string {
  return isOptionNew(option) ? "" : option.disabledMessage ?? "";
}

/** Returns the original member object if the selected option is not new */
export function getOptionOriginalOption(
  option: SelectedOption
): AutoCompleteMenuOption["originalMember"] | null {
  return isOptionNew(option) ? null : option.originalMember;
}
