import { Box, CircularProgress, SxProps } from "@mui/material";
import { EDecimalToHex, sphereColors } from "@styles/common-colors";
import { DISABLED_OPACITY } from "@styles/common-styles";
import { addTransparency, cardWidth, cardProperties } from "@utils/ui-utils";
import { PropsWithChildren } from "react";

/** CSS class name for the gray overlay on top on the card on hover */
export const FARO_CARD_IMAGE_OVERLAY_CLASS = "faroCardImgOverlay";

// TODO: This needs to move to another place and can be named as FARO_ACTION_BUTTON
/** CSS class name for the wrapper div for the card/table actions */
export const FARO_CARD_ACTIONS = "faroCardActions";

interface Props {
  /** Whether the card is in loading state */
  isLoading: boolean;

  /** Callback for when the card is clicked */
  onClick: () => void;

  /** Optional sx properties to override the button style. */
  sx?: SxProps;
}

/**
 * A wrapper component for a card that summarizes info of an item (project, group)
 */
export function FaroCard({
  children,
  isLoading,
  sx,
  onClick,
}: PropsWithChildren<Props>): JSX.Element {
  return (
    <Box
      data-testid="faro-card-test-id"
      component="div"
      sx={{
        width: cardWidth,
        height: cardProperties.height,
        marginTop: cardProperties.marginTop,
        boxShadow: cardProperties.boxShadow,
        borderRadius: cardProperties.borderRadius,
        opacity: isLoading ? DISABLED_OPACITY : 1,
        position: "relative",
        "&:hover": {
          cursor: isLoading ? "progress" : "pointer",
          // Do not set a background color if the card is in loading state
          backgroundColor: isLoading
            ? undefined
            : addTransparency({
                color: sphereColors.gray100,
                alpha: EDecimalToHex.twoHundredThree,
              }),
          [`& .${FARO_CARD_IMAGE_OVERLAY_CLASS}`]: {
            // Adds a hover effect on the image, when hovering over the card
            visibility: "visible",
          },
          [`& .${FARO_CARD_ACTIONS}`]: {
            //  Show the action buttons (which is hidden by default), when hovering over the card.
            // otherwise hidden by default
            visibility: isLoading ? "hidden" : "visible",
          },
        },
        "&:active": {
          backgroundColor: isLoading ? undefined : sphereColors.gray100,
          [`& .${FARO_CARD_IMAGE_OVERLAY_CLASS}`]: {
            // Change the background color of the image overlay
            backgroundColor: addTransparency({
              color: sphereColors.gray500,
              alpha: EDecimalToHex.thirtyTwo,
            }),
          },
        },
        ...sx,
      }}
    >
      {/* When the card is loading show an overlay in the card with a spinner */}
      {isLoading && (
        <CircularProgress
          size={"5rem"}
          sx={{
            position: "absolute",
            top: "calc(50% - 2.5rem)",
            left: "calc(50% - 2.5rem)",
            zIndex: "1",
          }}
        />
      )}
      <Box
        component="div"
        sx={{
          height: "100%",
          borderRadius: "4px",
        }}
        onClick={() => !isLoading && onClick()}
      >
        {/* Card content */}
        {children}
      </Box>
    </Box>
  );
}
