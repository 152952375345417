import { SphereAvatar } from "@components/header/sphere-avatar";
import { useAppSelector } from "@store/store-helper";
import { companyMemberSelector } from "@store/members/members-selector";
import {
  getPrettyName,
  getUserInitials,
  isMemberActive,
} from "@utils/user-utils";
import { ListItemText } from "@mui/material";
import { DASH } from "@utils/ui-utils";
import { FetchingItem } from "@store/table/table-slice-helper";

interface Props {
  /** The unique ID of the item that is being requested to do action on */
  item: FetchingItem;
}

/** List item in bulk action dialog for members */
export function MemberListContent({ item }: Props): JSX.Element | null {
  const companyMember = useAppSelector(companyMemberSelector(item.id));

  if (companyMember === null) {
    return null;
  }

  return (
    <>
      <SphereAvatar
        shouldHideWhiteRim
        src={companyMember.thumbnailUrl}
        initials={getUserInitials(companyMember)}
      />
      <ListItemText
        primary={
          isMemberActive(companyMember) ? getPrettyName(companyMember) : DASH
        }
        secondary={companyMember.email}
      />
    </>
  );
}
