import { Box } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { getMarkupStatusColor } from "@pages/project-details/project-markups/status/markup-status-utils";
import { useMemo } from "react";

interface Props {
  /** Status value of markup */
  status: string;

  /** True if the icon should have status colored border */
  shouldHaveBorder?: boolean;
}

/** Renders the icon status icon of corresponding color */
export function MarkupStatusIcon({
  status,
  shouldHaveBorder = false,
}: Props): JSX.Element {
  /** Color of the status */
  const markupStatusColor = useMemo(() => {
    return getMarkupStatusColor(status);
  }, [status]);

  return (
    <Box
      component="div"
      sx={{
        height: "24px",
        width: "24px",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: shouldHaveBorder
          ? `1px solid ${markupStatusColor.iconBorder}`
          : "none",
        backgroundColor: markupStatusColor.iconBackground,
      }}
    >
      <CircleIcon
        sx={{
          color: markupStatusColor.icon,
          width: "8px",
          height: "8px",
        }}
      />
    </Box>
  );
}
