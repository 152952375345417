import { CardSkeleton } from "@components/common/skeleton-loading/card-skeleton";
import { fetchingGroupsFlagsSelector } from "@store/groups/groups-selector";
import { useAppSelector } from "@store/store-helper";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { Box } from "@mui/material";
import { GroupCard } from "@components/group-card/group-card";
import { FaroTilesActionBar } from "@components/common/faro-card/faro-tiles-action-bar";
import { ExportGroupsAsCSV } from "@components/table/groups/bulk-actions/export-groups-as-csv";
import { TilesContainer } from "@components/common/tiles-container";

interface Props {
  /** List of all the available groups in the company */
  groups: SphereDashboardAPITypes.ICompanyGroup[];
}

/**
 * Lists all the available groups in the company as cards.
 */
export function GroupsTiles({ groups }: Props): JSX.Element {
  const { isFetchingGroups } = useAppSelector(fetchingGroupsFlagsSelector);

  return (
    <Box component="div">
      <FaroTilesActionBar
        actionBarButtons={[
          <ExportGroupsAsCSV
            key="ExportGroupsAsCSV"
            selectedGroups={groups}
            /* It is false since entities can't be selected in tiles view */
            areEntitiesSelected={false}
          />,
        ]}
      />
      <TilesContainer>
        {groups.map((group) => (
          <GroupCard key={group.id} group={group} />
        ))}

        {/* If it is loading the content, show the card skeleton */}
        {isFetchingGroups && <CardSkeleton type="group" />}
      </TilesContainer>
    </Box>
  );
}
