import { ProjectBackgroundTasksTable } from "@pages/project-details/project-bg-tasks/project-background-tasks-table";
import { PROJECT_BACKGROUND_TASKS_REQUIRED_COLUMNS } from "@pages/project-details/project-bg-tasks/project-background-tasks-columns";

/**
 * Contains the skeleton to show when loading the backgrounds tasks in the project activity tab.
 */
export function ProjectBackgroundTasksSkeleton(): JSX.Element {
  return (
    // The component takes care of showing skeletons if the data is loading.
    <ProjectBackgroundTasksTable
      sdbBackgroundsTasks={[]}
      isLoading={true}
      requiredColumns={PROJECT_BACKGROUND_TASKS_REQUIRED_COLUMNS}
      projectId=""
    />
  );
}
