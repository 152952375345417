import { SplashScreenSdbTopic } from "@components/splash-screen/splash-screen-data";
import { SplashScreenViewStatus } from "@components/splash-screen/splash-screen-utils";

type SdbSplashScreen = Record<SplashScreenSdbTopic, SplashScreenViewStatus>;

/** All the splash screens including both for SDB and other platforms */
export type AllSplashScreens = {
  /** Splash screen related to SDB */
  sdb: SdbSplashScreen;
} & {
  /** Any unrelated data, i.e. for other platforms like HoloBuilder Dashboard or WebEditor */
  other: Record<string, unknown>;
};

/** Change all the status of SDB splash screens */
export function changeAllSdbSplashScreensStatus(
  status: SplashScreenViewStatus
): SdbSplashScreen {
  const sdbSplashScreens = Object.values(SplashScreenSdbTopic).reduce(
    (splashScreens, topic) => ({ ...splashScreens, [topic]: status }),
    {} as SdbSplashScreen
  );

  return sdbSplashScreens;
}

/**
 * Parse the FueFlags. If parsing is not successful, it returns all the splash screens recognized in SDB as unseen
 * For other Splash Screens, it will keep the status as it is
 */
export function parseFueFlags(
  splashScreens: AllSplashScreens,
  fueFlags: string
): AllSplashScreens {
  try {
    const parsedFueFlags = JSON.parse(fueFlags);

    for (const topic in parsedFueFlags) {
      if (
        Object.values(SplashScreenSdbTopic).includes(
          topic as SplashScreenSdbTopic
        )
      ) {
        splashScreens.sdb[topic as SplashScreenSdbTopic] =
          parsedFueFlags[topic];
      } else {
        splashScreens.other = {
          ...splashScreens.other,
          [topic]: parsedFueFlags[topic],
        };
      }
    }
    return splashScreens;
  } catch (error) {
    // Make the splash screens unseen if the parsing fails
    return {
      sdb: changeAllSdbSplashScreensStatus("unseen"),
      other: splashScreens.other,
    };
  }
}

/** Convert the splashScreens object to a string, to send it as payload to backend */
export function createSplashScreenPayload(
  splashScreens: AllSplashScreens
): string {
  const allSplashScreens = { ...splashScreens.sdb, ...splashScreens.other };
  return JSON.stringify(allSplashScreens);
}
