import { Chip, SxProps } from "@mui/material";
import { sphereColors } from "@styles/common-colors";

interface Props {
  sx?: SxProps;
}

/**
 * A card that summarizes the project info
 */
export function DemoTag({ sx }: Props): JSX.Element {
  return (
    <Chip
      label="DEMO"
      sx={{
        backgroundColor: sphereColors.blue500,
        color: sphereColors.pureWhite,
        height: "22px",
        minWidth: "60px",
        textTransform: "uppercase",
        letterSpacing: "0.4px",
        textAlign: "center",
        fontWeight: "600",
        fontSize: "11px",
        ...sx,
      }}
    />
  );
}
