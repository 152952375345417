import { HolobuilderCookieManager } from "@utils/cookie-manager/cookie-manager-types";
import { runtimeConfigObject } from "@src/app-config";

declare global {
  interface Window {
    /** Our build/server will make sure to inject this runtimeConfig */
    appConfig?: RuntimeConfig;

    /** The Cookie Manager object */
    HolobuilderCookieManager: HolobuilderCookieManager;
  }
}

export type RuntimeConfig = {
  readonly urls: {
    readonly apiBaseUrl: string;
    readonly sphereViewerUrl: string;
    readonly dashboard20Api: string;
    readonly hbWebEditorUrl: string;
    readonly progressApiUrl: string;
    readonly sphereEntryPageUrl: string;
    readonly cookieManagerUrl: string;
    readonly cookieManagerPreflyUrl: string;
    readonly powerBIBaseUrl: string;
    readonly projectApiUrl: string;
    readonly hbDashboardUrl: string;
    readonly registrationApiUrl: string;
  };
  readonly features: {
    readonly muiProKey: string;
    readonly localizeKey: string;
    readonly sentryDSNKey?: string;
    readonly gtm: {
      readonly auth: string;
      readonly preview: string;
      readonly id: string;
    };
    readonly auth0: {
      readonly domain: string;
      readonly clientId: string;
    };
  };
  readonly appEnv: string;
  readonly appVersion: string;
  readonly appTimestamp: string;
  readonly commitId: string;
  readonly commitUrl: string;
  readonly pullRequestId: string;
  readonly pullRequestUrl: string;
  readonly jiraTicketId: string;
  readonly jiraTicketUrl: string;
  readonly mailSupportAddress: string;
  /** Flag whether the current domain is running on a numbered environment */
  readonly isNumberedEnv: boolean;
  /** The numbered environment if the current domain is running on a numbered environment */
  readonly numberedEnv: string | null;
};

class RuntimeConfigReader {
  static getRuntimeEnv(hostUrl: string): string {
    try {
      const matches = hostUrl.match(
        /.*dev\d\.sphere\.(staging|dev)\.holobuilder.(eu|com)/
      );
      if (!matches) {
        if (hostUrl === "localhost") {
          for (const key in runtimeConfigObject) {
            if (
              runtimeConfigObject[key].appEnv === import.meta.env.VITE_APP_ENV
            ) {
              return `${key}Local`;
            }
          }
        }
        if (hostUrl === "sphere.holobuilder.eu") {
          return "prodEu";
        }
        if (hostUrl === "sphere.holobuilder.com") {
          return "prodCom";
        }
        if (hostUrl === "test.sphere.holobuilder.eu") {
          return "prodEu";
        }
        if (hostUrl === "test.sphere.holobuilder.com") {
          return "prodCom";
        }
        if (hostUrl.endsWith("sphere.dev.holobuilder.com")) {
          return "developmentCom";
        }
        if (hostUrl.endsWith("sphere.staging.holobuilder.com")) {
          return "stagingCom";
        }
        if (hostUrl.endsWith("sphere.dev.holobuilder.eu")) {
          return "developmentEu";
        }
        if (hostUrl.endsWith("sphere.staging.holobuilder.eu")) {
          return "stagingEu";
        }
        // Default case if no specific conditions are met
        return "developmentCom";
      }

      const env =
        matches[1] && matches[1] === "dev" ? "development" : "staging";
      const region = matches[2] === "com" ? "Com" : "Eu";
      return `${env}${region}`;
    } catch (err) {
      throw new Error(`Failed to open env file for dev environment ${hostUrl}`);
    }
  }
  static getRuntimeConfig(): RuntimeConfig {
    /* eslint-disable no-restricted-globals */
    const hostUrl = location.hostname;
    /* eslint-enable no-restricted-globals */
    const runtimeEnv = this.getRuntimeEnv(hostUrl);
    return runtimeConfigObject[runtimeEnv];
  }
}

window.appConfig = RuntimeConfigReader.getRuntimeConfig();

// If the runtimeConfig is missing something is broken in the dev server or prod server
if (!window.appConfig) {
  throw Error("Runtime config missing");
}

const runtimeConfig = window.appConfig;
// make it immutable
Object.freeze(runtimeConfig);

export { runtimeConfig };

// all env variables must be provided either in the .env files
// or by the pipelines at build time "SOME_VAR=some-value npm run build"
// https://vitejs.dev/guide/env-and-mode.html
