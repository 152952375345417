/** The Sphere Dashboard company settings that can be stored, fetched and updated */
export enum CompanySettings {
  /** The setting to force all workspace members to use the Sphere Dashboard instead of the legacy one */
  forceWorkspaceUpdate = "sdb.force-access-all-members",

  /** The setting to allow access to Sphere XG for different user groups */
  allowSdbAccess = "sdb.allow-access",
}

/** The access control settings for the workspace */
export interface AllowSdbAccess {
  /** Whether workspace members without specific role can access Sphere XG */
  canMembersAccess: boolean;

  /** Whether group managers can access Sphere XG */
  canGroupManagersAccess: boolean;

  /** Whether project managers can access Sphere XG */
  canProjectManagersAccess: boolean;

  /** Whether workspace viewers can access Sphere XG */
  canWorkspaceViewersAccess: boolean;
}

/** The default access control settings for the workspace */
export const defaultAllowSdbAccess: AllowSdbAccess = {
  canMembersAccess: false,
  canGroupManagersAccess: false,
  canProjectManagersAccess: false,
  canWorkspaceViewersAccess: false,
};
