import { Divider } from "@mui/material";

interface Props {
  /** Whether the divider should be hidden */
  isHidden?: boolean;
}

/** Renders a vertical divider between snapshot actions */
export function SnapshotActionsDivider({
  isHidden = false,
}: Props): JSX.Element | null {
  if (isHidden) {
    return null;
  }
  return (
    <Divider
      orientation="vertical"
      flexItem
      sx={{
        marginX: "3px",
        height: "17px",
        alignSelf: "center",
      }}
    />
  );
}
