import { Box } from "@mui/material";
import defaultThumbnail from "/src/assets/group-thumbnail.png";
import { BaseGroupProps } from "@custom-types/group-types";
import { DemoTag } from "@components/common/demo-tag";
import { EDecimalToHex, sphereColors } from "@styles/common-colors";
import { addTransparency } from "@utils/ui-utils";
import { isGroupDemo } from "@utils/group-utils";
import { FARO_CARD_IMAGE_OVERLAY_CLASS } from "@components/common/faro-card/faro-card";

/** Height for the group card thumbnail image */
const IMAGE_HEIGHT = "110px";

/**
 * Top section of a group card that show the group thumbnail image.
 */
export function GroupCardTop({ group }: BaseGroupProps): JSX.Element {
  return (
    <>
      <Box component="div">
        {/* Overlay on top of the image that is shown on hover
          This makes that images with white background have the exact same gray background color
          as the middle and bottom part of the card  */}
        <Box
          component="div"
          className={FARO_CARD_IMAGE_OVERLAY_CLASS}
          sx={{
            // Hidden by default and it is overridden to visible by the parent component on hover.
            visibility: "hidden",
            height: IMAGE_HEIGHT,
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: addTransparency({
              color: sphereColors.gray100,
              alpha: EDecimalToHex.twentySix,
            }),
          }}
        />
        <Box
          component="img"
          sx={{
            height: IMAGE_HEIGHT,
            width: "100%",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            padding: 0,
            objectFit: "cover",
          }}
          src={group.thumbnailUrl ? group.thumbnailUrl : defaultThumbnail}
        />
      </Box>
      {isGroupDemo(group) && (
        <DemoTag
          sx={{
            position: "absolute",
            top: "15px",
            left: "15px",
          }}
        />
      )}
    </>
  );
}
