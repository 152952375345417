import { ProjectDataManagementType } from "@custom-types/project-data-management-types";

/** Human readable text for each project data management type */
export const PROJECT_DATA_MANAGEMENT_LABELS: {
  [key in ProjectDataManagementType]: string;
} = {
  [ProjectDataManagementType.rawData]: "Uploaded data",
  [ProjectDataManagementType.preparedData]: "Prepared data",
  [ProjectDataManagementType.publishedData]: "Published data",
};
