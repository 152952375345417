import { Divider } from "@mui/material";
import { sphereColors } from "@styles/common-colors";

/** Simple vertical divider between action buttons */
export function SphereActionDivider(): JSX.Element {
  return (
    <Divider
      orientation="vertical"
      flexItem
      sx={{
        height: "17px",
        alignSelf: "center",
        color: sphereColors.gray200,
        marginX: "4px",
        paddingY: "6px",
      }}
    />
  );
}
