import {
  BackgroundTask,
  BackgroundTaskStatus,
  BackgroundTaskProgressUpdate,
  BackgroundTaskStateUpdate,
  BackgroundTaskStates,
  BackgroundTaskStatusProgress,
  ProgressApiLatestStatusResponse,
  BackgroundTaskType,
  BackgroundTaskContext,
  PointCloudTaskType,
  VideoModeTaskType,
  CadTaskType,
  WSMigrationTaskType,
} from "@api/progress-api/progress-api-types";
import {
  BACKGROUND_TASK_STATES,
  BACKGROUND_TASK_STATUS_TYPE,
  CAD_TASK_TYPES,
  POINT_CLOUD_TASK_TYPES,
  VIDEO_MODE_TASK_TYPES,
  WS_MIGRATION_TASK_TYPES,
} from "@api/progress-api/progress-api-constants";

/**
 * @returns true if the object type is ProgressApiLatestStatusResponse
 */
export function isProgressApiLatestStatusResponse(
  response: unknown
): response is ProgressApiLatestStatusResponse {
  if (!response || typeof response !== "object") {
    return false;
  }

  const toCheck: Partial<ProgressApiLatestStatusResponse> = response;

  return (
    ((toCheck.data && toCheck.data.every(isBackgroundTask)) ||
      toCheck.data === null) &&
    typeof toCheck.totalCount === "number" &&
    typeof toCheck.pageSize === "number" &&
    (typeof toCheck.before === "string" || toCheck.before === null) &&
    (typeof toCheck.after === "string" || toCheck.after === null)
  );
}

/**
 * @returns true if the object type is BackgroundTask
 */
export function isBackgroundTask(data: unknown): data is BackgroundTask {
  if (!data || typeof data !== "object") {
    return false;
  }

  const toCheck: Partial<BackgroundTask> = data;

  return (
    typeof toCheck.id === "string" &&
    typeof toCheck.createdAt === "string" &&
    (isBackgroundTaskType(toCheck.taskType) || toCheck.taskType === null) &&
    (isBackgroundTaskContext(toCheck.context) || toCheck.context === null) &&
    (isBackgroundTaskStatus(toCheck.status) || toCheck.status === null) &&
    ((toCheck.tags && toCheck.tags.every((tag) => typeof tag === "string")) ||
      toCheck.tags === null)
  );
}

/**
 * @returns true if the object type is BackgroundTaskType
 */
export function isBackgroundTaskType(
  taskType: unknown
): taskType is BackgroundTaskType {
  if (!taskType || typeof taskType !== "string") {
    return false;
  }

  return true;
}

/**
 * @returns true if the object type is PointCloudTaskType
 */
export function isPointCloudTaskType(
  taskType: unknown
): taskType is PointCloudTaskType {
  if (!taskType || typeof taskType !== "string") {
    return false;
  }

  return Object.values<string>(POINT_CLOUD_TASK_TYPES).includes(taskType);
}

/**
 * @returns true if the object type is VideoModeTaskType
 */
export function isVideoModeTaskType(
  taskType: unknown
): taskType is VideoModeTaskType {
  if (!taskType || typeof taskType !== "string") {
    return false;
  }

  return Object.values<string>(VIDEO_MODE_TASK_TYPES).includes(taskType);
}

/**
 * @returns true if the object type is CadTaskType
 */
export function isCadTaskType(taskType: unknown): taskType is CadTaskType {
  if (!taskType || typeof taskType !== "string") {
    return false;
  }

  return Object.values<string>(CAD_TASK_TYPES).includes(taskType);
}

/**
 * @returns true if the object type is WSMigrationTaskType
 */
export function isWSMigrationTaskType(
  taskType: unknown
): taskType is WSMigrationTaskType {
  if (!taskType || typeof taskType !== "string") {
    return false;
  }

  return Object.values<string>(WS_MIGRATION_TASK_TYPES).includes(taskType);
}

/**
 * @returns true if the object type is BackgroundTaskContext
 */
export function isBackgroundTaskContext(
  context: unknown
): context is BackgroundTaskContext {
  if (!context || typeof context !== "object") {
    return false;
  }

  const toCheck: Partial<BackgroundTaskContext> = context;

  return (
    (typeof toCheck.companyId === "string" || toCheck.companyId === null) &&
    (typeof toCheck.projectId === "string" || toCheck.projectId === null) &&
    (typeof toCheck.userId === "string" || toCheck.userId === null) &&
    (typeof toCheck.correlationId === "string" ||
      toCheck.correlationId === null) &&
    typeof toCheck.jobId === "string" &&
    (typeof toCheck.elementId === "string" || toCheck.elementId === null)
  );
}

/**
 * @returns true if the object type is BackgroundTaskStatus
 */
export function isBackgroundTaskStatus(
  status: unknown
): status is BackgroundTaskStatus {
  return (
    isBackgroundTaskStateUpdate(status) ||
    isBackgroundTaskProgressUpdate(status)
  );
}

export function isBackgroundTaskStatusBase(
  status: unknown
): status is BackgroundTaskStatus {
  if (!status || typeof status !== "object") {
    return false;
  }

  const toCheck: Partial<BackgroundTaskStatus> = status;

  return (
    typeof toCheck.id === "string" &&
    Object.values<unknown>(BACKGROUND_TASK_STATUS_TYPE).includes(
      toCheck.type
    ) &&
    typeof toCheck.changedAt === "string" &&
    (typeof toCheck.devMessage === "string" || toCheck.devMessage === null) &&
    (typeof toCheck.errorCode === "string" || toCheck.errorCode === null) &&
    "result" in toCheck
  );
}

/**
 * @returns true if the object type is BackgroundTaskStateUpdate
 */
export function isBackgroundTaskStateUpdate(
  status: unknown
): status is BackgroundTaskStateUpdate {
  if (!status || typeof status !== "object") {
    return false;
  }

  const toCheck: Partial<BackgroundTaskStateUpdate> = status;

  return (
    isBackgroundTaskStatusBase(status) &&
    status.type === BACKGROUND_TASK_STATUS_TYPE.State &&
    isBackgroundTaskStates(toCheck.state)
  );
}

/**
 * @returns true if the object type is BackgroundTaskProgressUpdate
 */
export function isBackgroundTaskProgressUpdate(
  status: unknown
): status is BackgroundTaskProgressUpdate {
  if (!status || typeof status !== "object") {
    return false;
  }

  const toCheck: Partial<BackgroundTaskProgressUpdate> = status;

  return (
    isBackgroundTaskStatusBase(status) &&
    status.type === BACKGROUND_TASK_STATUS_TYPE.Progress &&
    isBackgroundTaskStatusProgress(toCheck.progress)
  );
}

/**
 * @returns true if the object type is BackgroundTaskStates
 */
export function isBackgroundTaskStates(
  state: unknown
): state is BackgroundTaskStates {
  if (!state || typeof state !== "string") {
    return false;
  }

  return Object.values<unknown>(BACKGROUND_TASK_STATES).includes(state);
}

/**
 * @returns true if the object type is BackgroundTaskStatusProgress
 */
export function isBackgroundTaskStatusProgress(
  progress: unknown
): progress is BackgroundTaskStatusProgress {
  if (!progress || typeof progress !== "object") {
    return false;
  }

  const toCheck: Partial<BackgroundTaskStatusProgress> = progress;

  return (
    typeof toCheck.current === "number" && typeof toCheck.total === "number"
  );
}
