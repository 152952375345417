import {
  Box,
  Paper,
  Popper,
  Typography,
  DialogActions,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { FaroButton, FARO_BUTTON_HEIGHT } from "@components/common/faro-button";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { useRef, useState } from "react";
import { Textarea } from "@pages/project-details/project-markups/project-markups-send-feedback-styled";
import { addTransparency } from "@utils/ui-utils";
import { EDecimalToHex, sphereColors } from "@styles/common-colors";
import { AddOnsEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useCoreApiClient } from "@api/use-core-api-client";
import { useToast } from "@hooks/use-toast";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { CoreAPITypes } from "@stellar/api-logic";
import { useAddonsContext } from "@context-providers/add-ons/add-ons-context";
import { MouseEvent } from "react";

interface AddOnsExtensionSidePanelRequestDropdownProps {
  /** The ID of the company */
  companyId?: string;

  /** The name of the add-on */
  addOnName: string;

  /** Callback function to close the side panel when triggered. */
  closePanel: () => void;
}

/** Renders the extension side panel component */
export function AddOnsExtensionSidePanelRequestDropdown({
  addOnName,
  companyId,
  closePanel,
}: AddOnsExtensionSidePanelRequestDropdownProps): JSX.Element {
  const theme = useTheme();
  const isScreenXs = useMediaQuery(theme.breakpoints.only("xs"));
  const { trackEvent } = useTrackEvent();

  const messageRef = useRef<HTMLTextAreaElement>(null);
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);

  const { showToast } = useToast();
  const { handleErrorWithToast } = useErrorContext();
  const coreApiClient = useCoreApiClient();

  const { anchorEl, updateAnchorState } = useAddonsContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  /**
   * Validate if the request dropdown is opened or not.
   * If is opened the user can't close the dropdown when clicks in the request button again.
   *
   * @param event The mouse event triggering the click action.
   */
  function handleClickRequest(event: MouseEvent<HTMLElement>): void {
    if (!anchorEl) {
      handleClick(event);
    }
  }

  /**
   * Toggles the visibility of the dropdown menu by setting the anchor element.
   * If the dropdown is currently open, it closes the menu. If closed, it opens the menu.
   *
   * @param event The mouse event triggering the click action.
   */
  function handleClick(event: MouseEvent<HTMLElement>): void {
    updateAnchorState(event);
    setIsLoading(false);
  }

  /**
   * Handles the confirmation action when submitting a request for an add-on.
   * This function tracks the event using analytics and sends a feedback notification.
   *
   * @param event The mouse event triggering the confirmation action.
   */
  async function onConfirm(event: MouseEvent<HTMLElement>): Promise<void> {
    trackEvent({
      name: AddOnsEvents.submitRequestAddOns,
      props: {
        didProvideMessage: Boolean(messageRef.current?.value),
        addOnName: addOnName,
      },
    });

    setIsLoading(true);
    await sendRequestNotification(event);
  }

  /**
   * Sends a feedback notification to the product team using the V3 API.
   * Displays a success toast upon successful submission or an error toast if an error occurs.
   *
   * @param event The mouse event triggering the feedback notification.
   */
  async function sendRequestNotification(
    event: MouseEvent<HTMLElement>
  ): Promise<void> {
    try {
      // Uses the V3 API to send the feedback to the product team.
      await coreApiClient.V3.notificationRequest({
        featureName: addOnName,
        notificationType: CoreAPITypes.ENotificationType.featureRequest,
        featureComment: messageRef.current?.value,
        companyId,
      });

      showToast({
        message: "Request sent",
        description: "You will be contacted by our sales team.",
        type: "success",
      });
    } catch (error) {
      handleErrorWithToast({
        id: `sendFeedback-${Date.now().toString()}`,
        title: "Error sending feedback",
        error,
      });
    } finally {
      setIsLoading(false);
      handleClick(event);
      closePanel();
    }
  }

  return (
    <>
      <SphereTooltip
        title={"Send request to the sales team"}
        disableHoverListener={Boolean(anchorEl)}
        dataTestId="add-ons-extension-side-panel-request-dropdown-button-title"
      >
        <FaroButton
          sx={{ width: "100%", background: sphereColors.blue600 }}
          onClick={handleClickRequest}
        >
          Request
        </FaroButton>
      </SphereTooltip>

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement={"bottom-start"}
        sx={{
          zIndex: "9999",
          width: isScreenXs ? "100%" : "560px",
        }}
        data-testid="add-ons-extension-side-panel-request-dropdown-popper"
        modifiers={[
          {
            name: "arrow",
            // eslint-disable-next-line @typescript-eslint/naming-convention
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
      >
        <Box
          component={"span"}
          ref={setArrowRef}
          sx={{
            position: "relative",
            width: "0px",
            height: "0px",
            borderLeft: "14px solid transparent",
            borderRight: "14px solid transparent",
            borderBottom: `14px solid ${sphereColors.gray200}`,
            top: "-8px",

            "&:before": {
              content: "''",
              position: "absolute",
              bottom: "-15px",
              left: "-13px",
              width: "0px",
              height: "0px",
              borderLeft: "13px solid transparent",
              borderRight: "13px solid transparent",
              borderBottom: `13px solid ${sphereColors.pureWhite}`,
            },
          }}
        />
        <Paper
          sx={{
            padding: "32px",
            marginTop: "6px",
            border: `1px solid ${sphereColors.gray200}`,
          }}
        >
          <Typography
            component={"p"}
            sx={{
              color: sphereColors.gray800,
              fontWeight: "bold",
              mb: "24px",
              fontSize: "20px",
            }}
            data-testid="add-ons-extension-side-panel-request-dropdown-popper-title"
          >
            Request feature
          </Typography>

          <Box sx={{ marginTop: "24px" }}>
            <Typography
              sx={{
                fontSize: "14px",
                color: sphereColors.gray800,
                marginBottom: "4px",
              }}
              data-testid="add-ons-extension-side-panel-request-dropdown-popper-sub-title"
            >
              Would you like to leave any additional comments for our sales team
            </Typography>

            <Textarea
              minRows={4}
              placeholder={"Write here your comments"}
              ref={messageRef}
              data-testid="add-ons-extension-side-panel-request-dropdown-popper-textarea"
              sx={{
                marginTop: 0,
                color: sphereColors.gray800,
              }}
            />
          </Box>

          <Box
            sx={{ marginTop: "32px" }}
            data-testid="add-ons-extension-side-panel-request-dropdown-popper-buttons"
          >
            <DialogActions
              sx={{
                padding: "0",
              }}
            >
              <Button
                onClick={handleClick}
                sx={{
                  textTransform: "capitalize",
                  minWidth: "89px",
                  height: FARO_BUTTON_HEIGHT,
                  py: "6px",
                  px: "16px",
                  color: sphereColors.blue500,
                  fontWeight: "600",
                  "&:hover": {
                    backgroundColor: addTransparency({
                      color: sphereColors.gray500,
                      alpha: EDecimalToHex.thirtyEight,
                    }),
                  },
                  "&:active": {
                    backgroundColor: addTransparency({
                      color: sphereColors.gray500,
                      alpha: EDecimalToHex.sixtyFour,
                    }),
                  },
                }}
              >
                Cancel
              </Button>
              <FaroButton onClick={onConfirm} isLoading={isLoading}>
                Submit
              </FaroButton>
            </DialogActions>
          </Box>
        </Paper>
      </Popper>
    </>
  );
}
