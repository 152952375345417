import {
  nounList,
  nounPluralize,
  progressiveVerb,
  progressiveVerbList,
} from "@utils/data-display";
import { useAppSelector } from "@store/store-helper";
import {
  isBulkActionProcessingSelector,
  numberOfUpdatingItemsSelector,
  shouldShowFailedDialogSelector,
} from "@store/table/table-selector";
import { BulkDialogContentProps } from "@components/common/faro-table/bulk-dialog/bulk-dialog-types";

interface DialogTextContent {
  /** The title of the bulk dialog */
  title: string;

  /** The description of the bulk dialog */
  description: string;
}

interface BulkDialogContent {
  /** Initial content of the bulk dialog when user opens it */
  initial: DialogTextContent;

  /** Failed content of the bulk dialog when user opens it */
  failed: DialogTextContent;

  /** The text to show in confirm button */
  confirmButtonText: keyof typeof progressiveVerbList;

  /** The type of entity */
  entityType: keyof typeof nounList;
}

/** Return the bulk dialog content */
export function useGetBulkDialogContent({
  initial,
  failed,
  confirmButtonText,
  entityType,
}: BulkDialogContent): BulkDialogContentProps {
  const shouldShowFailedDialog = useAppSelector(shouldShowFailedDialogSelector);
  const isBulkActionProcessing = useAppSelector(isBulkActionProcessingSelector);
  const numberOfUpdatingItems = useAppSelector(numberOfUpdatingItemsSelector);
  const { title, description } = shouldShowFailedDialog ? failed : initial;

  return {
    title,
    shouldShowFailedIcon: shouldShowFailedDialog,
    description,
    confirmButtonText: progressiveVerb({
      verb: confirmButtonText,
      isProgressive: isBulkActionProcessing,
    }),
    selectedEntitiesReport: `${nounPluralize({
      counter: numberOfUpdatingItems,
      word: entityType,
    })} ${shouldShowFailedDialog ? "failed" : "selected"}`,
  };
}
