import { Box, BoxProps, SvgIcon } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { hoverStyle, clickStyle } from "@utils/ui-utils";

/** The size of the outer button in pixels */
const BTN_SIZE = "64px";

/** The size of the icon in pixels */
const ICON_SIZE = "44px";

/** The class name for the label */
const RATE_LABEL_CLASS = "rateLabel";

/** The class name for the icon wrapper */
const RATE_ICON_WRAPPER_CLASS = "rateIconWrapper";

export interface RateElementProps {
  /** Label to show below the icon */
  label: string;

  /** The value to be returned when the rate element is clicked */
  value: number | null;

  /** Callback when the rate element is clicked */
  onClick: (value: number | null) => void;

  /** The icon component to show */
  iconComponent: React.ElementType;

  /** Optional selected value. If it is the same as value will show the item as selected */
  selectedValue?: number | null;

  /** Optional style to be applied to the root element */
  sx?: BoxProps["sx"];
}

/**
 * Contains a rate element with an icon and a label.
 */
export function RateElement({
  label,
  selectedValue,
  value,
  onClick,
  iconComponent,
  sx,
}: RateElementProps): JSX.Element {
  /** Whether the rate element is selected */
  const isSelected = selectedValue === value;

  /** The properties to apply when the rate has been selected */
  const selectedProps = isSelected
    ? {
        ...hoverStyle,
      }
    : undefined;

  /** Reacts to clicks on the element */
  function onRateElementClick(): void {
    // If the value is the same as the selected value, then we want to deselect it
    if (isSelected) {
      onClick(null);
      return;
    }
    onClick(value);
  }

  return (
    <Box
      component="div"
      onClick={onRateElementClick}
      sx={{
        ...sx,
        cursor: "pointer",
        "&:hover": {
          [`& .${RATE_ICON_WRAPPER_CLASS}`]: {
            ...hoverStyle,
          },
          [`& .${RATE_LABEL_CLASS}`]: {
            visibility: "visible",
          },
        },
        "&:active": {
          [`& .${RATE_ICON_WRAPPER_CLASS}`]: {
            ...clickStyle,
          },
        },
      }}
    >
      <Box
        component="div"
        className={RATE_ICON_WRAPPER_CLASS}
        sx={{
          width: BTN_SIZE,
          height: BTN_SIZE,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          borderRadius: "50%",
          margin: 0,
          padding: 0,
          ...selectedProps,
        }}
      >
        <SvgIcon
          inheritViewBox
          sx={{
            height: ICON_SIZE,
            width: ICON_SIZE,
          }}
          component={iconComponent}
        />
      </Box>
      <Box
        component="div"
        className={RATE_LABEL_CLASS}
        sx={{
          visibility: isSelected ? "visible" : "hidden",
          color: isSelected ? sphereColors.blue500 : sphereColors.gray600,
          fontWeight: "bold",
          textTransform: "uppercase",
          fontSize: "10px",
          marginTop: "8px",
          textAlign: "center",
        }}
      >
        {label}
      </Box>
    </Box>
  );
}
