import FileIcon from "@assets/icons/new/file_50px.svg?react";
import VideoIcon from "@assets/icons/new/video_24px.svg?react";

export const fileIcons: {
  [key: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
} = {
  pdf: FileIcon,
  txt: FileIcon,
  xls: FileIcon,
  xlsx: FileIcon,
  mp4: VideoIcon,
  default: FileIcon,
};
