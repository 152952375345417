import { DashboardAPITypes } from "@stellar/api-logic";
import {
  CumulativeStat,
  DifferentialStat,
  ProjectSheetsStat,
  ProjectSpheresStat,
  ProjectSqftStat,
  ProjectStat,
  StatPair,
  StatPairBase,
} from "@custom-types/analytics-types";
import {
  dataGenerationType,
  statName,
} from "@src/constants/analytics-constants";

/** Type guard that validates a stat to be of type ProjectStat */
export function isProjectStat(
  stat: DashboardAPITypes.ICompanyStatistic
): stat is ProjectStat {
  return (
    stat.name === statName["project-count"] ||
    stat.name === statName["project-count-abs"]
  );
}

/** Type guard that validates a stat to be of type ProjectSqftStat */
export function isProjectSqftStat(
  stat: DashboardAPITypes.ICompanyStatistic
): stat is ProjectSqftStat {
  return (
    stat.name === statName["project-sqft"] ||
    stat.name === statName["project-sqft-abs"]
  );
}

/** Type guard that validates a stat to be of type ProjectSpheresStat */
export function isProjectSpheresStat(
  stat: DashboardAPITypes.ICompanyStatistic
): stat is ProjectSpheresStat {
  return (
    stat.name === statName["project-spheres"] ||
    stat.name === statName["project-spheres-abs"]
  );
}

/** Type guard that validates a stat to be of type ProjectSheetsStat */
export function isProjectSheetsStat(
  stat: DashboardAPITypes.ICompanyStatistic
): stat is ProjectSheetsStat {
  return (
    stat.name === statName["project-sheets"] ||
    stat.name === statName["project-sheets-abs"]
  );
}

/** Type guard that validates a stat to be of type CumulativeStat */
export function isCumulativeStat(
  stat: DashboardAPITypes.ICompanyStatistic
): stat is CumulativeStat {
  return stat["data-generation"] === dataGenerationType.cumulative;
}

/** Type guard that validates a stat to be of type DifferentialStat */
export function isDifferentialStat(
  stat: DashboardAPITypes.ICompanyStatistic
): stat is DifferentialStat {
  return stat["data-generation"] === dataGenerationType.differential;
}

/** Type guard that validates a stat pair to be of type StatPair */
export function isStatPair(statPair: StatPairBase): statPair is StatPair {
  return "cumulative" in statPair && "differential" in statPair;
}
