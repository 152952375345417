import { IElementAttachment } from "@faro-lotv/ielement-types";
import { Box, CircularProgress, Typography } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { DownloadUtils } from "@stellar/web-core";
import { memo, useState } from "react";
import DeleteIcon from "@assets/icons/new/delete_32px.svg?react";
import DownloadIcon from "@assets/icons/new/download_24px.svg?react";
import { SphereActionDivider } from "@components/common/sphere-action-divider";
import {
  ACTION_BTN_SIZE,
  ACTION_ICON_SIZE,
} from "@components/common/project-actions";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { withEllipsis } from "@styles/common-styles";
import { fileIcons } from "@pages/project-details/project-markups/sidepanel/markup-sidepanel-utils";
import { useMarkupContext } from "@context-providers/markup/markup-context";
import { FaroDialog } from "@components/common/dialog/faro-dialog";
import { useProjectMarkupUpdate } from "@hooks/project-markups/use-project-markup-update";
import { cloneDeep, findIndex } from "lodash";
import { useDateTime } from "@hooks/use-date-time";

interface Props {
  /** The attachment item to display */
  attachment: IElementAttachment;
}

const BYTES = 1024;

/**
 * Component representing an attachment item within the markups side panel.
 *
 * @param {MarkupsSidePanelAttachmentItemProps} props - The properties for the component, including the attachment data.
 * @returns {JSX.Element} The rendered component.
 */
function MarkupsSidePanelAttachmentItemComponent({
  attachment,
}: Props): JSX.Element {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const {
    projectId,
    hasPermissionToEditMarkup,
    markups,
    selectedMarkup,
    setIsMarkupUpdating,
    updateMarkups,
  } = useMarkupContext();
  const { deleteAttachmentToMarkup } = useProjectMarkupUpdate({ projectId });

  const { formatDate } = useDateTime();

  /**
   * It will convert the bytes in MB
   *
   * @param bytes
   * @returns the MB with 2 case decimals
   */
  function bytesToMB(bytes: number): string {
    const megabytes = bytes / (BYTES * BYTES);

    return megabytes.toFixed(2);
  }

  /**
   * Downloads a file from a given URL and saves it to the user's device.
   *
   * This function performs the following steps:
   * 1. Initiates the download of the file from the specified URL as a Blob object.
   * 2. Provides optional callback functions to track the download progress, start, and end events.
   * 3. Creates a downloadable link for the Blob object.
   * 4. Triggers the browser to download the file using the created link.
   *
   * @returns {Promise<void>} A promise that resolves when the download and save operation is complete.
   */
  async function downloadAndSaveFile(): Promise<void> {
    const fileBlob = await DownloadUtils.downloadFilePromise(attachment.uri, {
      onFileDownloadStart: () => setIsDownloading(true),
      onFileDownloadEnd: () => setIsDownloading(false),
    });

    const fileUrl = URL.createObjectURL(fileBlob);
    DownloadUtils.downloadFile(attachment.name, fileUrl);
  }

  /**
   * Deletes an attachment from the project and updates the local copy of the project.
   *
   * This function performs the following steps:
   * 1. Creates a mutation to delete the attachment using its ID.
   * 2. Applies the mutation to the project API client to delete the attachment.
   * 3. Fetches the updated sub-tree of elements related to the attachment and updates the local state.
   *
   * @returns {Promise<void>} A promise that resolves when the attachment deletion and state update are complete.
   */
  async function deleteAttachment(): Promise<void> {
    setIsDeleting(true);
    setIsMarkupUpdating(true);

    const mutation = await deleteAttachmentToMarkup(attachment);

    if (!mutation || !selectedMarkup) {
      return;
    }

    const newMarkups = cloneDeep(markups);
    const markupIndex = findIndex(newMarkups, { id: selectedMarkup.id });
    const attachmentList = newMarkups[markupIndex].attachments;

    const updateAttachmentList = attachmentList.filter(
      (attachment) => attachment.id !== mutation.elementId
    );

    newMarkups[markupIndex].attachments = updateAttachmentList;

    updateMarkups(newMarkups);
    setIsDeleting(false);
    setIsMarkupUpdating(false);
  }

  // Helper to get file extension
  function getFileExtension(fileName: string): string {
    return fileName.split(".").pop() || "";
  }

  // Determine if the file is an image
  const fileExtension = getFileExtension(attachment.name);
  const isImage = ["jpg", "jpeg", "png"].includes(fileExtension.toLowerCase());
  const FileIcon = fileIcons[fileExtension.toLowerCase()] || fileIcons.default;

  const editPermissionOffset =
    hasPermissionToEditMarkup && !isDownloading ? "145px" : "106px";
  const widthOffset = isHovered ? editPermissionOffset : "77px";

  return (
    <Box
      data-testid="markup-side-panel-attachment-item-container"
      sx={{
        display: "flex",
        gap: "8px",
        borderBottom: 1,
        borderColor: "divider",
        padding: "8px",
        transition: "background-color 0.3s",
        alignItems: "center",
        "&:hover": {
          backgroundColor: sphereColors.gray100,
          cursor: "pointer",
        },
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isImage ? (
        <Box
          component={"img"}
          src={attachment.uri}
          alt="attachment annotation"
          width={"60px"}
          minWidth={"60px"}
          height={"43px"}
          data-testid="markup-side-panel-attachment-image"
        />
      ) : (
        <Box sx={{ height: "43px", display: "flex", alignItems: "center" }}>
          <FileIcon width="60px" height="32px" />
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: !isDownloading ? `calc(100% - ${widthOffset})` : "100%",
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            color: isHovered ? sphereColors.blue500 : sphereColors.gray800,
            ...withEllipsis,
          }}
        >
          <var>{attachment.name}</var>
        </Typography>
        <Box sx={{ display: "flex", gap: "8px" }}>
          <Typography
            sx={{
              fontSize: "10px",
              color: isHovered ? sphereColors.blue500 : sphereColors.gray800,
            }}
          >
            <var>{formatDate(attachment.createdAt)}</var>
          </Typography>
          {attachment.fileSize && (
            <>
              <Typography
                sx={{
                  fontSize: "10px",
                  color: isHovered
                    ? sphereColors.blue500
                    : sphereColors.gray800,
                }}
              >
                .
              </Typography>
              <Typography
                sx={{
                  fontSize: "10px",
                  color: isHovered
                    ? sphereColors.blue500
                    : sphereColors.gray800,
                }}
              >
                {bytesToMB(attachment.fileSize)} MB
              </Typography>
            </>
          )}
        </Box>
      </Box>
      {isDownloading && <CircularProgress size={"1rem"} />}

      {isHovered && !isDownloading && (
        <Box sx={{ display: "flex" }}>
          <FaroIconButton
            buttonSize={ACTION_BTN_SIZE}
            iconSize={ACTION_ICON_SIZE}
            component={DownloadIcon}
            onClick={downloadAndSaveFile}
            dataTestId="markup-side-panel-attachment-item-download-icon"
          />

          {hasPermissionToEditMarkup && (
            <>
              <SphereActionDivider />

              <FaroIconButton
                buttonSize={ACTION_BTN_SIZE}
                iconSize={ACTION_ICON_SIZE}
                component={DeleteIcon}
                onClick={() => setIsDeleteModalOpen(true)}
                dataTestId="markup-side-panel-attachment-item-delete-icon"
              />
            </>
          )}
        </Box>
      )}

      <FaroDialog
        title="Delete attachment?"
        confirmText="Delete"
        open={isDeleteModalOpen}
        onConfirm={deleteAttachment}
        onClose={() => setIsDeleteModalOpen(false)}
        isConfirmLoading={isDeleting}
        confirmButtonColor="red"
      >
        <Typography
          data-testid="delete-annotation-attachment-dialog-content"
          sx={{ fontSize: "14px", color: sphereColors.gray800 }}
        >
          This will remove the <var>{attachment.name}</var> from your project.
        </Typography>
      </FaroDialog>
    </Box>
  );
}

export const MarkupsSidePanelAttachmentItem = memo(
  MarkupsSidePanelAttachmentItemComponent
);
