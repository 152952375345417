import { Theme } from "@mui/material/styles";
import { sphereColors } from "@styles/common-colors";
import { SIDEBAR_WIDTH_EXPANDED } from "@utils/ui-utils";
import { CSSProperties } from "react";

/** MUI spacing multiplier value for calculating width of sidebar when closed */
const muiSpacingMultiplier = 8;

const commonStyles: CSSProperties = {
  backgroundColor: sphereColors.gray50,
  borderRight: "none",
};

/**
 * Mixins to be used for the sidebar when it is open and closed
 * This is copied from the example in MUI drawer page: https://mui.com/material-ui/react-drawer/#mini-variant-drawer
 */

export const SidebarMixins = {
  openedMixin(theme: Theme): CSSProperties {
    return {
      ...commonStyles,
      justifyContent: "space-between",
      width: SIDEBAR_WIDTH_EXPANDED,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: "hidden",
    };
  },

  closedMixin(theme: Theme): CSSProperties {
    return {
      ...commonStyles,
      justifyContent: "space-between",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: `calc(${theme.spacing(muiSpacingMultiplier)} + 1px)`,
    };
  },
};
