/**
 * Returns whether a number is valid and therefore it can be parsed.
 * For example "12.1" is a valid number but "12,1" is not.
 *
 * @param value The value to analyze can be of any type but only
 * number or string can return true.
 * @returns True if the provided number is valid.
 */
export function isValidNumber(value: unknown): boolean {
  if (typeof value === "number") {
    return true;
  }
  if (typeof value === "string") {
    return /^-?\d+(\.\d+)?$/.test(value);
  }
  return false;
}

interface ValidateNumberProps {
  /**
   * The value to be validated, can be of any format but only
   * numbers or strings can return true.
   */
  value: unknown;

  /**
   * The attribute name to be included in the error message.
   * E.g. "foo" -> "foo is not a valid number".
   */
  attrName: string;

  /**
   * Flag whether the number can have a decimal part,
   * false means that only integers will be considered as valid.
   */
  shouldAllowDecimal?: boolean;

  /** Minimum value for the number to be considered as valid (not inclusive) */
  min?: number;

  /** Minimum value for the number to be considered as valid (inclusive) */
  minOrEqual?: number;

  /** Maximum value for the number to be considered as valid (not inclusive) */
  max?: number;

  /** Maximum value for the number to be considered as valid (inclusive) */
  maxOrEqual?: number;
}

/**
 * Base response for validateNumber.
 */
interface ValidateNumberBaseResponse {
  /**
   * Flag that indicates that the number met all criteria to be considered
   * a valid number. True if the number is valid, false otherwise.
   */
  isValid: boolean;
}

interface ValidateNumberValidResponse extends ValidateNumberBaseResponse {
  /**
   * Flag that indicates that the number met all criteria to be considered
   * a valid number.
   */
  isValid: true;

  /**
   * Returns the numeric version of the provided number, even if it was provided
   * as a string.
   */
  parsedNumber: number;
}

interface ValidateNumberInvalidResponse extends ValidateNumberBaseResponse {
  /**
   * Flag that indicates that the number didn't meet all criteria to be considered
   * a valid number.
   */
  isValid: false;

  /** Message indicating why is the number not valid. */
  errorMessage: string;
}

/**
 * Validates that a number is valid and that meets certain criteria.
 * If it is valid, returns true and the parsed number.
 * If it is invalid returns false and en error message.
 */
export function validateNumber({
  value,
  attrName,
  shouldAllowDecimal = true,
  min,
  minOrEqual,
  max,
  maxOrEqual,
}: ValidateNumberProps):
  | ValidateNumberValidResponse
  | ValidateNumberInvalidResponse {
  let parsedNumber: number;
  const errorMessageInvalidNumber = `${attrName} must be a valid number.`;
  if (typeof value === "number") {
    parsedNumber = value;
  } else if (typeof value === "string") {
    const str = value.toString().replace(",", ".");

    if (!isValidNumber(str)) {
      return {
        isValid: false,
        errorMessage: errorMessageInvalidNumber,
      };
    }
    parsedNumber = parseFloat(str);

    if (isNaN(parsedNumber)) {
      return {
        isValid: false,
        errorMessage: errorMessageInvalidNumber,
      };
    }
  } else {
    return {
      isValid: false,
      errorMessage: errorMessageInvalidNumber,
    };
  }

  if (
    min === undefined &&
    minOrEqual === undefined &&
    max === undefined &&
    maxOrEqual === undefined &&
    shouldAllowDecimal
  ) {
    return {
      isValid: true,
      parsedNumber,
    };
  }

  if (!shouldAllowDecimal && !Number.isInteger(parsedNumber)) {
    return {
      isValid: false,
      errorMessage: `${attrName} cannot be a decimal number.`,
    };
  }

  if (min !== undefined && parsedNumber <= min) {
    return {
      isValid: false,
      errorMessage: `${attrName} must be greater than ${min}.`,
    };
  }

  if (minOrEqual !== undefined && parsedNumber < minOrEqual) {
    const errorMessage =
      parsedNumber < 0 && minOrEqual >= 0
        ? `${attrName} cannot be a negative value.`
        : `${attrName} must be greater or equal than ${minOrEqual}.`;

    return {
      isValid: false,
      errorMessage,
    };
  }

  if (max !== undefined && parsedNumber >= max) {
    return {
      isValid: false,
      errorMessage: `${attrName} must be lower than ${max}.`,
    };
  }

  if (maxOrEqual !== undefined && parsedNumber > maxOrEqual) {
    return {
      isValid: false,
      errorMessage: `${attrName} must be lower or equal than ${maxOrEqual}.`,
    };
  }

  return {
    isValid: true,
    parsedNumber,
  };
}
