import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { ChartType, ProjectChartType } from "@custom-types/analytics-types";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { APITypes, DashboardAPITypes } from "@stellar/api-logic";
import { RootState } from "@store/store-helper";
import {
  CoreApiWithCompanyIdProjectIdProps,
  CoreApiWithCompanyIdProps,
} from "@store/store-types";
import { TIME_FRAMES, TimeFrameItem } from "@utils/time-utils";

/**
 * State of analytics
 */
export interface AnalyticsState {
  /** The Id of the selected project to choose analytics */
  selectedAnalyticsProjectId: APITypes.ProjectId | null;

  /** The selected time frame to limit all analytics */
  selectedAnalyticsTimeFrame: TimeFrameItem["value"];

  /** The stats of the company */
  companyStats: DashboardAPITypes.ICompanyStatistic[] | null;

  /**
   * The stats of a single project
   * The key is the project id
   * The value is the stats belonging to that project.
   */
  projectStats: {
    [key: APITypes.ProjectId]: DashboardAPITypes.ICompanyStatistic[];
  };

  /** Collects all the fetching properties for this slice */
  fetching: {
    /** Indicates if company stats are currently being fetched from the backend */
    isFetchingCompanyStats: boolean;

    /** Indicates if stats for a project are currently being fetched from the backend */
    isFetchingProjectStats: boolean;
  };

  /** Collects properties used in the analytics chart displayed in the project overview page */
  projectOverview: {
    /** The selected time frame to limit the displayed chart */
    selectedTimeFrame: TimeFrameItem["value"];

    /** The selected project chart type to display */
    selectedChartType: ProjectChartType;
  };
}

/** The default time frame to show analytics in months */
export const DEFAULT_TIME_FRAME = 6;

/** Uses DEFAULT_TIME_FRAME to set the default value for the time frame  */
const DEFAULT_TIME_FRAME_VALUE =
  TIME_FRAMES.find((timeFrame) => timeFrame.value === DEFAULT_TIME_FRAME)
    ?.value ?? TIME_FRAMES[0].value;

const initialState: AnalyticsState = {
  selectedAnalyticsProjectId: null,
  // Use the default value if it exists in the list of time frames, otherwise use the first one
  selectedAnalyticsTimeFrame: DEFAULT_TIME_FRAME_VALUE,
  companyStats: null,
  projectStats: {},
  fetching: {
    isFetchingCompanyStats: false,
    isFetchingProjectStats: false,
  },
  projectOverview: {
    selectedTimeFrame: DEFAULT_TIME_FRAME_VALUE,
    selectedChartType: ChartType.projectSpheres,
  },
};

/** Fetches stats from the backend to show analytics */
export const fetchCompanyStats = createAsyncThunk<
  DashboardAPITypes.ICompanyStatistic[],
  CoreApiWithCompanyIdProps,
  { state: RootState }
>(
  "groups/fetchCompanyStats",
  async ({ coreApiClient, companyId }, thunkAPI) => {
    if (!companyId) {
      throw new Error("No companyId was given to fetchCompanyStats");
    }

    const state = thunkAPI.getState() as RootState;
    // Caches the response of the stats not to always fetch them from the backend
    if (state.analytics.companyStats) {
      return state.analytics.companyStats;
    }

    try {
      const response = await coreApiClient.V3.SDB.getCompanyStatistics(
        companyId
      );
      return response;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

interface FetchProjectStatsResponse extends BaseProjectIdProps {
  /** Stats for a particular project */
  stats: DashboardAPITypes.ICompanyStatistic[];
}

/** Fetches stats from the backend for a single project to show analytics */
export const fetchProjectStats = createAsyncThunk<
  FetchProjectStatsResponse,
  CoreApiWithCompanyIdProjectIdProps,
  { state: RootState }
>(
  "groups/fetchProjectStats",
  async ({ coreApiClient, companyId, projectId }, thunkAPI) => {
    if (!companyId) {
      throw new Error("No companyId was given to fetchProjectStats");
    }

    const state = thunkAPI.getState();
    // Caches the response of the stats not to always fetch them from the backend
    if (state.analytics.projectStats[projectId]) {
      return {
        projectId,
        stats: state.analytics.projectStats[projectId],
      };
    }

    try {
      const response = await coreApiClient.V3.SDB.getProjectStatistics({
        companyId,
        projectId,
      });
      return {
        projectId,
        stats: response,
      };
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/**
 * Slice to access state of loaded groups
 */
const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    /**
     * Stores the ID of the selected project.
     */
    setSelectedAnalyticsProjectId(
      state,
      action: PayloadAction<APITypes.ProjectId | null>
    ) {
      state.selectedAnalyticsProjectId = action.payload;
    },

    /**
     * Stores the selected time frame.
     */
    setSelectedAnalyticsTimeFrame(
      state,
      action: PayloadAction<TimeFrameItem["value"] | null>
    ) {
      state.selectedAnalyticsTimeFrame = action.payload ?? DEFAULT_TIME_FRAME;
    },

    /** Sets the selected project chart time frame */
    setSelectedProjectChartTimeFrame(
      state,
      action: PayloadAction<TimeFrameItem["value"]>
    ) {
      state.projectOverview.selectedTimeFrame = action.payload;
    },

    /** Sets the selected project chart type */
    setSelectedProjectChartType(
      state,
      action: PayloadAction<ProjectChartType>
    ) {
      state.projectOverview.selectedChartType = action.payload;
    },

    /** Resets the analytics store to its initial state. */
    resetAnalyticsState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCompanyStats.pending, (state, action) => {
        state.fetching.isFetchingCompanyStats = true;
      })
      .addCase(fetchCompanyStats.fulfilled, (state, action) => {
        state.fetching.isFetchingCompanyStats = false;
        state.companyStats = action.payload;
      })
      .addCase(fetchCompanyStats.rejected, (state, action) => {
        state.fetching.isFetchingCompanyStats = false;
      })

      .addCase(fetchProjectStats.pending, (state, action) => {
        state.fetching.isFetchingProjectStats = true;
      })
      .addCase(fetchProjectStats.fulfilled, (state, action) => {
        state.fetching.isFetchingProjectStats = false;
        state.projectStats[action.payload.projectId] = action.payload.stats;
      })
      .addCase(fetchProjectStats.rejected, (state, action) => {
        state.fetching.isFetchingProjectStats = false;
      });
  },
});

export const {
  setSelectedAnalyticsProjectId,
  setSelectedAnalyticsTimeFrame,
  setSelectedProjectChartTimeFrame,
  setSelectedProjectChartType,
  resetAnalyticsState,
} = analyticsSlice.actions;

export const analyticsReducer = analyticsSlice.reducer;
