import { Box } from "@mui/material";
import {
  BaseProjectsProps,
  ProjectArchivingState,
} from "@custom-types/project-types";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { ProjectsTable } from "@components/table/projects/projects-table";
import { ProjectHeaders } from "@components/table/projects/projects-table-utils";
import { LoadMoreButton } from "@components/common/button/load-more-button";
import { ProjectsPageBulkActions } from "@pages/projects/projects-page-bulk-actions";

interface Props extends BaseCompanyIdProps, BaseProjectsProps {
  /** The archiving status of the project */
  projectStatus: ProjectArchivingState;

  /** Flag whether it is loading some content  */
  isLoading: boolean;

  /** Flag whether the button to load more projects should be hidden */
  shouldHideLoadMoreButton?: boolean;

  /** Callback when the load more button is clicked */
  loadMoreProjects: () => Promise<void>;
}

/**
 * Listing all the projects in a table
 */
export function ProjectsPageTable({
  companyId,
  projects,
  projectStatus,
  isLoading,
  shouldHideLoadMoreButton,
  loadMoreProjects,
}: Props): JSX.Element {
  const buttonComponents = ProjectsPageBulkActions({
    companyId,
    projects,
    projectStatus,
    subjectType: "workspace",
  });

  return (
    <Box component="div">
      <ProjectsTable
        companyId={companyId}
        projects={projects}
        isLoading={isLoading}
        contextType={
          projectStatus === "active" ? "active projects" : "archived projects"
        }
        requiredColumns={[
          ProjectHeaders.thumbnail,
          ProjectHeaders.name,
          ProjectHeaders.projectManager,
          ProjectHeaders.lastUpdated,
          ProjectHeaders.group,
          ProjectHeaders.accessLevel,
          ProjectHeaders.options,
        ]}
        bulkActionButtons={buttonComponents}
      />

      <LoadMoreButton
        isLoading={isLoading}
        buttonText="Load more projects"
        shouldHideLoadMoreButton={shouldHideLoadMoreButton}
        onClick={loadMoreProjects}
      />
    </Box>
  );
}
