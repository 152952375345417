import { Box, Grid } from "@mui/material";
import { sphereColors } from "@styles/common-colors";

interface Props {
  /** The current screen number */
  activeScreenNumber: number;

  /** The total number of slides in a topic */
  totalNumberOfScreens: number;

  /** Handler for clicking on an indicator representing a screen */
  onSlideIndicatorClicked: (selectedScreenNumber: number) => void;
}

/** All indicators at the bottom of slides representing the user progress in viewing of a topic */
export function SlideProgressIndicator({
  activeScreenNumber,
  totalNumberOfScreens,
  onSlideIndicatorClicked,
}: Props): JSX.Element {
  return (
    <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
      {[...Array(totalNumberOfScreens)].map((_, index) => (
        <Grid
          item
          key={index}
          component="div"
          sx={{
            borderRadius: "5px",
            backgroundColor:
              index === activeScreenNumber
                ? sphereColors.blue500
                : sphereColors.gray200,
            width: index === activeScreenNumber ? "40px" : "10px",
            height: "10px",
            mx: "5px",
            cursor: "pointer",
          }}
          onClick={() => onSlideIndicatorClicked(index)}
        />
      ))}
    </Box>
  );
}
