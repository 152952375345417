import { Drawer, SxProps } from "@mui/material";
import { ReactElement } from "react";

type Anchor = "left" | "top" | "right" | "bottom";

export interface SidePanelProps {
  /** Determines whether the side panel is open or closed. */
  isOpen: boolean;

  /** The anchor position of the side panel */
  anchor: Anchor;

  /** The content to be rendered within the side panel */
  children: ReactElement;

  /** Close side panel when we click outside */
  closeSidePanel: () => void;

  /** If any element is opened we shouldn't close the side panel */
  isDropdownOpened?: boolean;

  /** Remove the background */
  shouldRemoveBackground?: boolean;

  /** Customize de side panel */
  sx?: SxProps;
}

/**
 * SidePanel component renders a customizable sidebar.
 *
 * Key Features and Usage:
 * - Visibility Control: The `isOpen` prop determines whether the sidebar is open (`true`) or
 *   closed (`false`).
 *
 * - Flexible Positioning: The `anchor` prop specifies the position of the sidebar relative to
 *   the screen. It can be configured as `"left"` (left side), `"right"` (right side),
 *   `"top"` (top of the screen), or `"bottom"` (bottom of the screen), allowing for versatile
 *   interface layouts.
 *
 * - Customizable Content: The `children` prop defines the content to be displayed inside the
 *   sidebar. This can include any valid React elements such as text, interactive components,
 *   forms, or other custom components.
 */
export function SidePanel({
  isOpen,
  children,
  anchor,
  isDropdownOpened = false,
  shouldRemoveBackground = false,
  sx,
  closeSidePanel,
}: SidePanelProps): JSX.Element {
  /**
   * Safely closes the side panel only if no dropdown is currently open.
   * This function checks whether any dropdown is open before closing the side panel,
   * ensuring that the panel is closed only when no dropdown is active.
   */
  function handleSafeClose(): void {
    if (!isDropdownOpened) {
      closeSidePanel();
    }
  }

  return (
    <Drawer
      hideBackdrop={shouldRemoveBackground}
      anchor={anchor}
      open={isOpen}
      component={"aside"}
      onClose={handleSafeClose}
      data-testid="side-panel-container"
      sx={sx}
    >
      {children}
    </Drawer>
  );
}
