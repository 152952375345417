import { Box, BoxProps, SvgIcon } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { getSvgColoredIconCss } from "@utils/ui-utils";
import { ElementType } from "react";

interface Props {
  /**
   * Defines a component to show as an icon.
   * E.g.
   * import { ReactComponent as FeedbackIcon } from "@assets/icons/Feedback.svg";
   * component={FeedbackIcon}
   */
  component: ElementType;

  /**
   * Triggered when the users clicks on the icon.
   */
  onClick?: BoxProps["onClick"];

  /**
   * Optional size in pixels for the icon.
   * Default "24px".
   */
  iconSize?: string;
}

/**
 * Renders an icon that can be clicked, similar to FaroIconButton but without the button.
 * It is just an icon that changes color when hovered.
 */
export function FaroClickableIcon({
  component,
  onClick,
  iconSize = "24px",
}: Props): JSX.Element {
  return (
    <Box
      component="div"
      sx={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        "&:hover": {
          ...getSvgColoredIconCss(sphereColors.blue500),
        },
      }}
      onClick={onClick}
    >
      <SvgIcon
        inheritViewBox
        component={component}
        sx={{
          height: iconSize,
          width: iconSize,
        }}
      />
    </Box>
  );
}
