import {
  FilterId,
  FilterMapId,
} from "@components/common/faro-table/faro-table-filter/faro-table-filter-types";
import { EntityForTableType } from "@components/common/faro-table/faro-table-types";
import { TableType } from "@utils/track-event/track-event-list";

interface DoesEntityPassQuickFilterProps {
  /** The value of the quick filter text box */
  quickFilter: string;

  /** The value of entity that needs to be compared with the quick filter value */
  quickFilterEntity: string;
}

/** Whether the entity pas the quick filter or not. If quick filter doesn't exist, it returns true */
export function doesEntityPassQuickFilter({
  quickFilter,
  quickFilterEntity,
}: DoesEntityPassQuickFilterProps): boolean {
  return (
    quickFilter === "" || quickFilterEntity.toLowerCase().includes(quickFilter)
  );
}

interface DoesColumnPassAnyFilterProps<
  Entity extends EntityForTableType<TableType>
> {
  /** The entity that its value in a column need to be checked */
  entity: Entity;

  /** All the filters available for column introduced in our code */
  allPossibleFiltersForColumn: FilterMapId<Entity>;

  /** All the filter IDs selected by the user in the column. It is a selection of allPossibleFiltersForColumn */
  activeFilterIds: FilterId[];
}

/** Whether the entity value in a column passes any filter selected by user or not */
export function doesColumnPassAnyFilter<
  Entity extends EntityForTableType<TableType>
>({
  entity,
  allPossibleFiltersForColumn,
  activeFilterIds,
}: DoesColumnPassAnyFilterProps<Entity>): boolean {
  return activeFilterIds.some((filterId) => {
    return allPossibleFiltersForColumn[filterId].hasEntityPassedFilter(entity);
  });
}
