import { GroupCardBottom } from "@components/group-card/group-card-bottom";
import { cardMiddleHeight } from "@components/group-card/group-card-middle";
import { Box, Skeleton } from "@mui/material";
import { cardWidth, cardProperties } from "@utils/ui-utils";
import { useMemo } from "react";

/** Default number of cards to show */
const DEFAULT_NUMBER_OF_CARDS = 6;

interface Props {
  /** Number of skeleton cards to show */
  numberOfCards?: number;

  /** Type of card to show, the layout is different for each entity */
  type: "project" | "group";
}

/**
 * A skeleton loading for a series of cards
 */
export function CardSkeleton({
  numberOfCards = DEFAULT_NUMBER_OF_CARDS,
  type,
}: Props): JSX.Element {
  /** Custom properties depending on the entity type */
  const properties = useMemo(() => {
    if (type === "project") {
      return {
        shouldShowSubtitle1: true,
        shouldShowSubtitle2: true,
        shouldShowSubtitle3: true,
        shouldShowSubtitle4: true,
        subtitle2PaddingTop: cardProperties.creationDatePaddingTop,
        subtitle1Height: "150px",
      };
    }
    if (type === "group") {
      return {
        shouldShowSubtitle1: true,
        shouldShowSubtitle2: true,
        shouldShowSubtitle3: true,
        shouldShowSubtitle4: false,
        shouldShowGroupCardBottom: true,
        subtitle2PaddingTop: cardProperties.creationDatePaddingTop,
        subtitle1Height: "110px",
        cardMiddlePaddingTop: "10px",
        cardMiddleHeight,
      };
    }

    // As default, return an empty object
    return {};
  }, [type]);

  return (
    <>
      {[...Array(numberOfCards)].map((e, index) => (
        <Box
          component="div"
          key={index}
          sx={{
            width: cardWidth,
            height: cardProperties.height,
            boxShadow: cardProperties.boxShadow,
            // We use box-shadow instead
            border: "none",
            borderRadius: cardProperties.borderRadius,
            mt: cardProperties.marginTop,
          }}
        >
          {/* Skeleton for the subtitle1 (thumbnail) */}
          {properties.shouldShowSubtitle1 && (
            <Skeleton
              variant="rectangular"
              sx={{ width: cardWidth, height: properties.subtitle1Height }}
            />
          )}

          <Box
            component="div"
            sx={{
              height: properties.cardMiddleHeight,
              paddingTop: properties.cardMiddlePaddingTop,
            }}
          >
            {/* Skeleton for the subtitle2 (creation date) */}
            {properties.shouldShowSubtitle2 && (
              <Skeleton
                variant="rectangular"
                sx={{
                  width: "50%",
                  height: "14px",
                  marginTop: properties.subtitle2PaddingTop,
                  ml: "14px",
                }}
              />
            )}

            {/* Skeleton for the subtitle3 (name) */}
            {properties.shouldShowSubtitle3 && (
              <Skeleton
                variant="rectangular"
                sx={{ width: "60%", height: "22px", mt: "6px", ml: "14px" }}
              />
            )}

            {/* Skeleton for the subtitle4 (project access level) */}
            {properties.shouldShowSubtitle4 && (
              <Skeleton
                variant="rectangular"
                sx={{ width: "30%", height: "14px", mt: "50px", ml: "14px" }}
              />
            )}
          </Box>

          {properties.shouldShowGroupCardBottom && (
            <GroupCardBottom isLoading={true} />
          )}
        </Box>
      ))}
    </>
  );
}
