import { PointCloud } from "@custom-types/point-cloud-types";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/store-helper";
import { getPointClouds } from "@store/point-clouds/point-clouds-selector-utils";
import { GUID } from "@faro-lotv/foundation";

/**
 * @returns An array of all loaded PointCloud entities
 */
export const pointCloudsSelector: (state: RootState) => PointCloud[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return getPointClouds(state);
    }
  );

/**
 * Gets a point cloud entity by providing the point cloud ID
 */
export function getPointCloudByIdSelector(
  id: GUID
): (state: RootState) => PointCloud | undefined {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      if (!id) {
        return undefined;
      }

      const pointClouds = pointCloudsSelector(state);
      return pointClouds.find((pointCloud) => pointCloud.id === id);
    }
  );
}
