import { BaseMarkupProps } from "@custom-types/project-markups-types";
import { Box } from "@mui/material";
import { Fragment, useMemo } from "react";
import { EDecimalToHex, sphereColors } from "@styles/common-colors";
import { FaroChip } from "@faro-lotv/flat-ui";
import { addTransparency } from "@utils/ui-utils";

/**
 * MarkupsTags Component
 *
 * This component displays tags associated with a markup. If no tags are present, it displays a tag icon.
 * If there are tags, it shows the first tag as a chip and, if more tags are available, it shows an additional
 * chip with a count of the remaining tags.
 *
 * @param {BaseMarkupProps} param0 - The props containing markup data.
 * @returns {JSX.Element} - A component that displays tags or a user icon.
 */
export function MarkupTags({ markup }: BaseMarkupProps): JSX.Element {
  /** receive the tags and sort them alphabetically */
  const sortedTags = useMemo(() => {
    if (!markup.labels) {
      return [];
    }
    const labels = [...markup.labels];
    return labels.sort();
  }, [markup.labels]);

  return (
    <Box component="div" sx={{ pl: "10px" }}>
      {sortedTags.length === 0 ? (
        "-"
      ) : (
        <Box sx={{ display: "flex" }}>
          <FaroChip
            sx={{
              maxWidth: "100px",
            }}
            backgroundColor={sphereColors.gray100}
            color={sphereColors.gray800}
            borderColor={addTransparency({
              color: sphereColors.gray800,
              alpha: EDecimalToHex.thirtyTwo,
            })}
            label={<var>{sortedTags[0].name}</var>}
            size="small"
            textTransform="capitalize"
            variant="outlined"
            tooltip={sortedTags[0].name}
            icon={
              sortedTags[0].color ? (
                <Box
                  sx={{
                    height: "10px",
                    width: "10px",
                    borderRadius: "50%",
                    background: sortedTags[0].color,
                  }}
                />
              ) : undefined
            }
          />

          {sortedTags.length > 1 && (
            <FaroChip
              sx={{ ml: "10px" }}
              backgroundColor={sphereColors.gray100}
              color={sphereColors.gray800}
              borderColor={addTransparency({
                color: sphereColors.gray800,
                alpha: EDecimalToHex.thirtyTwo,
              })}
              label={<var>{`+${sortedTags.length - 1}`}</var>}
              size="small"
              variant="outlined"
              tooltip={
                <Box
                  component={"div"}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  {sortedTags.map((tag, index) => (
                    <Fragment key={index}>
                      {index >= 1 && <var>{tag.name}</var>}
                    </Fragment>
                  ))}
                </Box>
              }
            />
          )}
        </Box>
      )}
    </Box>
  );
}
