import { GridPaginationModel } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { FaroIconButton } from "@components/common/faro-icon-button";
import ArrowLeftIcon from "@assets/icons/new/arrow-left_18px.svg?react";
import ArrowRightIcon from "@assets/icons/new/arrow-right_18px.svg?react";
import { DASH } from "@utils/ui-utils";
import { FaroTableFooterContainer } from "@components/common/faro-table/faro-table-footer-container";
import {
  getEndItems,
  getTotalPages,
  getStartItems,
} from "@utils/pagination-utils";
import { Box } from "@mui/material";
import { SphereTooltip } from "@components/common/sphere-tooltip";

export interface FaroTableFooterProps {
  /** Defines the model for the pagination including the current page and the page size. */
  paginationModel: GridPaginationModel;

  /** The total number of items that are being paginated. */
  totalItems: number;

  /** Callback to change the page. */
  changePage: (page: number) => void;

  /**
   * Whether the next page button should be disabled manually
   * (in addition to it being disabled for the last page)
   */
  shouldDisableNextPage?: boolean;

  /** Tooltip to show for the next page button when it is disabled */
  disabledNextPageTooltip?: string;
}

/**
 * The footer of the table that shows the pagination information.
 */
export function FaroTableFooter({
  paginationModel,
  totalItems,
  changePage,
  shouldDisableNextPage = false,
  disabledNextPageTooltip = "Please load more items first",
}: FaroTableFooterProps): JSX.Element | null {
  /** Defines some properties to compute the pagination information. */
  const paginationProps = useMemo(() => {
    const pageSize = paginationModel.pageSize;
    const page = paginationModel.page;
    const startItems = getStartItems({ pageSize, page: page });
    const totalPages = getTotalPages({ totalItems, pageSize });
    const endItems = getEndItems({ pageSize, page, totalItems, totalPages });

    return {
      startItems,
      endItems,
      totalPages,
    };
  }, [paginationModel, totalItems]);

  // Early exit when we don't have pagination because the number of items are less than the page size.
  // In that case we just show a label stating the total number of items.
  if (totalItems <= paginationModel.pageSize) {
    return (
      <FaroTableFooterContainer>
        Total rows{" "}
        <Box component="strong" sx={{ marginLeft: "4px" }}>
          {totalItems}
        </Box>
      </FaroTableFooterContainer>
    );
  }

  return (
    <FaroTableFooterContainer>
      <Box component="strong">{`${paginationProps.startItems} ${DASH} ${paginationProps.endItems} `}</Box>
      <Box component="span" sx={{ marginX: "4px" }}>
        of {totalItems}
      </Box>

      <FaroIconButton
        component={ArrowLeftIcon}
        isDisabled={paginationModel.page === 0}
        onClick={() => changePage(paginationModel.page - 1)}
        buttonSize="28px"
        iconSize="20px"
      />
      <Box component="span" sx={{ marginRight: "4px" }} />
      <SphereTooltip
        title={shouldDisableNextPage ? disabledNextPageTooltip : ""}
        shouldShowTooltip={shouldDisableNextPage}
      >
        <FaroIconButton
          component={ArrowRightIcon}
          isDisabled={
            shouldDisableNextPage ||
            paginationProps.totalPages === paginationModel.page + 1
          }
          onClick={() => changePage(paginationModel.page + 1)}
          buttonSize="28px"
          iconSize="20px"
        />
      </SphereTooltip>
    </FaroTableFooterContainer>
  );
}
