import {
  RegistrationRevision,
  RegistrationState,
} from "@faro-lotv/service-wires";
import { runtimeConfig } from "@src/runtime-config";
import { APITypes } from "@stellar/api-logic";

/**
 * @returns Whether the registration revision is of type "prepared".
 * A revision is considered prepared when its state value is one of the following:
 * - "CloudRegistrationStarted"
 * - "Registered"
 *
 * @param registrationRevision Registration revision entity
 */
export function isPreparedRegistrationRevision(
  registrationRevision: RegistrationRevision
): boolean {
  return (
    registrationRevision.state === RegistrationState.cloudRegistrationStarted ||
    registrationRevision.state === RegistrationState.registered
  );
}

/**
 * @returns true iff the registration revision entity is still preparing
 *
 * @param registrationRevision Registration revision entity
 */
export function isRegistrationRevisionPreparing(
  registrationRevision: RegistrationRevision
): boolean {
  return (
    registrationRevision.state === RegistrationState.cloudRegistrationStarted
  );
}

/**
 * @returns true iff the registration revision entity has already been registered
 *
 * @param registrationRevision Registration revision entity
 */
export function isRegisteredRegistrationRevision(
  registrationRevision: RegistrationRevision
): boolean {
  return registrationRevision.state === RegistrationState.registered;
}

/**
 * Constructs a URL for publishing a registration revision for a specified project.
 *
 * @param {APITypes.ProjectId} projectId - The ID of the project for which the registration is being published.
 * @param {string} revisionId - The ID of the registration revision to be published.
 * @returns {string} A URL object pointing to the endpoint for publishing the specified registration revision.
 */
export function getInspectAndPublishToolUrl(
  projectId: APITypes.ProjectId,
  revisionId: string
): string {
  const url = new URL(
    `/data-preparation/project/${projectId}`,
    runtimeConfig.urls.sphereViewerUrl
  );

  url.searchParams.append("revisionId", revisionId);

  return url.href;
}
