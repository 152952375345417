import { UserAvatarWithMenu } from "@components/user-more-info-menu/user-avatar-with-menu";
import { Box } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { withEllipsis } from "@styles/common-styles";
import { ProjectHeaders } from "@components/table/projects/projects-table-utils";
import {
  AccessLevelDisplayNames,
  SdbProject,
} from "@custom-types/project-types";
import {
  defaultProjectThumbnail,
  getProjectGroupId,
  getProjectGroupName,
  getProjectManagerName,
  getProjectModifiedElapsed,
  isProjectDemo,
} from "@utils/project-utils";
import { DemoTag } from "@components/common/demo-tag";
import { ProjectActions } from "@components/common/project-actions";
import { ProjectTableContextType } from "@components/table/projects/projects-table";
import { SHOW_ON_HOVER_CLASS } from "@utils/ui-utils";
import {
  generateGroupDetailsRoute,
  generateMemberProfileRoute,
} from "@router/router-helper";
import { Link } from "react-router-dom";
import styles from "@components/table/projects/projects-table.module.css";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";

interface Props extends BaseCompanyIdProps {
  /** Recognizes on which context the table is being used */
  contextType: ProjectTableContextType;

  // TODO: Extract it in separate file or access in in the component: https://faro01.atlassian.net/browse/ST-1348
  options: {
    /** Whether the current screen is large or larger */
    isScreenLgOrLarger: boolean;

    /** Whether the current screen is medium */
    isMedium: boolean;
  };

  /** List of all the permissions that the user has */
  permissions: {
    /** Whether the use has permission to access the groups page and view all company groups */
    canViewAllCompanyGroups: boolean;
  };
}

/** The default padding for the columns in pixels */
const COL_DEFAULT_X_PADDING = 16;

/** The width of the thumbnail image in pixels */
const THUMBNAIL_IMAGE_WIDTH = 70;

/** Minimum column width in px */
const COLS_MIN_WIDTH_IN_PX = 100;

/** Column width in px for extra small and small screen */
const MD_OR_SMALLER_COLUMNS_WIDTH_IN_PX = 200;

/** Column width in px for large and larger screens */
const LG_OR_LARGER_COLUMNS_WIDTH_IN_PX = 250;

/** Return all the possible columns for members table */
export function getProjectsColumns({
  contextType,
  companyId,
  options,
  permissions,
}: Props): Record<ProjectHeaders, GridColDef> {
  const maxTextColumnWidth = options.isScreenLgOrLarger
    ? LG_OR_LARGER_COLUMNS_WIDTH_IN_PX
    : MD_OR_SMALLER_COLUMNS_WIDTH_IN_PX;

  const flexSetting = options.isScreenLgOrLarger ? 1 : undefined;

  return {
    [ProjectHeaders.thumbnail]: {
      field: ProjectHeaders.thumbnail,
      headerName: "",
      // Use two times COL_DEFAULT_X_PADDING, one for each side
      minWidth: THUMBNAIL_IMAGE_WIDTH + 2 * COL_DEFAULT_X_PADDING,
      renderCell: (params: GridRenderCellParams<{ entity: SdbProject }>) => {
        return (
          <img
            src={params.row.entity.thumbnailUrl ?? defaultProjectThumbnail}
            style={{
              height: "50px",
              width: `${THUMBNAIL_IMAGE_WIDTH}px`,
              maxHeight: "100%",
              maxWidth: "100%",
              objectFit: "contain",
              borderRadius: "8px",
            }}
            alt="Project thumbnail"
          />
        );
      },
    },

    [ProjectHeaders.name]: {
      field: ProjectHeaders.name,
      flex: flexSetting,
      minWidth: COLS_MIN_WIDTH_IN_PX,
      maxWidth: maxTextColumnWidth,
      renderCell: (params: GridRenderCellParams<{ entity: SdbProject }>) => {
        return (
          <SphereTooltip
            title={<var>{params.row.entity.name}</var>}
            boxProps={{
              sx: {
                ...withEllipsis,
                fontWeight: "bold",
              },
            }}
          >
            <var>{params.row.entity.name}</var>
          </SphereTooltip>
        );
      },
    },

    [ProjectHeaders.managerAvatar]: {
      field: ProjectHeaders.managerAvatar,
      headerName: "",
      maxWidth: MD_OR_SMALLER_COLUMNS_WIDTH_IN_PX,
      minWidth: 54,
      align: "right",
      renderCell: (params: GridRenderCellParams<{ entity: SdbProject }>) => {
        return (
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              pr: "0px",
            }}
          >
            <UserAvatarWithMenu
              member={params.row.entity.managers?.projectManager}
            />
          </Box>
        );
      },
    },

    [ProjectHeaders.projectManager]: {
      field: ProjectHeaders.projectManager,
      flex: flexSetting,
      maxWidth: maxTextColumnWidth,
      minWidth: COLS_MIN_WIDTH_IN_PX,
      renderCell: (params: GridRenderCellParams<{ entity: SdbProject }>) => {
        const projectManagerId = params.row.entity.managers?.projectManager?.id;
        const projectManagerIdentity =
          params.row.entity.managers?.projectManager?.identity ?? "";

        if (projectManagerId) {
          if (permissions.canViewAllCompanyGroups) {
            return (
              <Link
                to={generateMemberProfileRoute(companyId, projectManagerId)}
                className={styles["removed-underline"]}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <var>{getProjectManagerName(params.row.entity)}</var>
              </Link>
            );
          } else {
            return <var>{getProjectManagerName(params.row.entity)}</var>;
          }
        } else {
          return <var>{projectManagerIdentity}</var>;
        }
      },
    },

    [ProjectHeaders.lastUpdated]: {
      field: ProjectHeaders.lastUpdated,
      flex: flexSetting,
      minWidth: COLS_MIN_WIDTH_IN_PX,
      maxWidth: maxTextColumnWidth,
      renderCell: (params: GridRenderCellParams<{ entity: SdbProject }>) => {
        return <var>{getProjectModifiedElapsed(params.row.entity)}</var>;
      },
    },

    [ProjectHeaders.group]: {
      field: ProjectHeaders.group,
      flex: flexSetting,
      minWidth: COLS_MIN_WIDTH_IN_PX,
      width: maxTextColumnWidth,
      renderCell: (params: GridRenderCellParams<{ entity: SdbProject }>) => {
        const groupId = getProjectGroupId(params.row.entity);

        const contentSphereTooltip = (
          <SphereTooltip
            title={<var>{getProjectGroupName(params.row.entity)}</var>}
            boxProps={{
              sx: {
                ...withEllipsis,
                fontWeight: "bold",
              },
            }}
          >
            <var>{getProjectGroupName(params.row.entity)}</var>
          </SphereTooltip>
        );

        if (groupId && permissions.canViewAllCompanyGroups) {
          return (
            <Link
              to={generateGroupDetailsRoute(companyId, groupId)}
              onClick={(e) => {
                e.stopPropagation();
              }}
              className={styles["removed-underline"]}
            >
              {contentSphereTooltip}
            </Link>
          );
        } else {
          return contentSphereTooltip;
        }
      },
    },

    [ProjectHeaders.accessLevel]: {
      field: ProjectHeaders.accessLevel,
      flex: flexSetting,
      minWidth: COLS_MIN_WIDTH_IN_PX,
      maxWidth: maxTextColumnWidth,
      renderCell: (params: GridRenderCellParams<{ entity: SdbProject }>) => {
        const accessLevel = params.row.entity.accessLevel;
        return AccessLevelDisplayNames[accessLevel];
      },
    },

    [ProjectHeaders.tags]: {
      field: ProjectHeaders.tags,
      flex: flexSetting,
      minWidth: COLS_MIN_WIDTH_IN_PX,
      width: maxTextColumnWidth,
      renderCell: (params: GridRenderCellParams<{ entity: SdbProject }>) => {
        return isProjectDemo(params.row.entity) ? <DemoTag /> : "";
      },
    },

    [ProjectHeaders.options]: {
      field: ProjectHeaders.options,
      type: "actions",
      align: "right",
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- This is needed for the flex to work properly
      flex: options.isScreenLgOrLarger ? 0.5 : undefined,
      maxWidth: MD_OR_SMALLER_COLUMNS_WIDTH_IN_PX,
      minWidth: 50,
      renderCell: (params: GridRenderCellParams<{ entity: SdbProject }>) => {
        return (
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              // This will be overridden by parent component using SHOW_ON_HOVER_CLASS
              visibility: "hidden",
            }}
            className={SHOW_ON_HOVER_CLASS}
          >
            <ProjectActions
              project={params.row.entity}
              contextType={
                contextType === "group projects"
                  ? "group-project-list-view"
                  : "project-list-view"
              }
            />
          </Box>
        );
      },
    },
  };
}
