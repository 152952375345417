import { Box } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { DEFAULT_TEXT_FONT_SIZE, withEllipsis } from "@styles/common-styles";
import { defaultProjectThumbnail } from "@utils/project-utils";
import { ProjectActions } from "@components/common/project-actions";
import { SHOW_ON_HOVER_CLASS } from "@utils/ui-utils";
import { MemberGroupProjectsHeaders } from "@components/table/member-group-projects/member-group-projects-table-utils";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { formatUserRoleType } from "@utils/data-display";

interface Props {
  // TODO: Extract it in separate file or access in in the component: https://faro01.atlassian.net/browse/ST-1348
  options: {
    /** Whether the current screen is large or larger */
    isScreenLgOrLarger: boolean;

    /** Whether the current screen is medium */
    isMedium: boolean;
  };
}

/** The default padding for the columns in pixels */
const COL_DEFAULT_X_PADDING = 16;

/** The width of the thumbnail image in pixels */
const THUMBNAIL_IMAGE_WIDTH = 70;

/** Minimum column width in px */
const COLS_MIN_WIDTH_IN_PX = 100;

/** Column width in px for medium or smaller screen */
const MD_OR_SMALLER_COLUMNS_WIDTH_IN_PX = 200;

/** Return all the possible columns for members table */
export function getMemberGroupProjectsColumns({
  options,
}: Props): Record<MemberGroupProjectsHeaders, GridColDef> {
  return {
    [MemberGroupProjectsHeaders.thumbnail]: {
      field: MemberGroupProjectsHeaders.thumbnail,
      headerName: "",
      width: 170,
      // Use two times COL_DEFAULT_X_PADDING, one for each side
      minWidth: THUMBNAIL_IMAGE_WIDTH + 2 * COL_DEFAULT_X_PADDING,
      renderCell: (
        params: GridRenderCellParams<{
          entity: SphereDashboardAPITypes.GroupDetailsProjectSummary;
        }>
      ) => {
        if (!options.isMedium) {
          return (
            <img
              src={params.row.entity.imageUrl ?? defaultProjectThumbnail}
              style={{
                height: "50px",
                width: `${THUMBNAIL_IMAGE_WIDTH}px`,
                maxHeight: "100%",
                maxWidth: "100%",
                objectFit: "contain",
                borderRadius: "8px",
              }}
              alt="Project thumbnail"
            />
          );
        }
      },
    },

    [MemberGroupProjectsHeaders.name]: {
      field: MemberGroupProjectsHeaders.name,
      minWidth: COLS_MIN_WIDTH_IN_PX,
      width: 440,
      renderCell: (
        params: GridRenderCellParams<{
          entity: SphereDashboardAPITypes.GroupDetailsProjectSummary;
        }>
      ) => {
        return (
          <SphereTooltip
            title={<var>{params.row.entity.name}</var>}
            boxProps={{
              sx: {
                ...withEllipsis,
                fontWeight: "bold",
              },
            }}
          >
            <var>{params.row.entity.name}</var>
          </SphereTooltip>
        );
      },
    },

    [MemberGroupProjectsHeaders.role]: {
      field: MemberGroupProjectsHeaders.role,
      minWidth: COLS_MIN_WIDTH_IN_PX,
      width: 190,
      renderCell: (
        params: GridRenderCellParams<{
          entity: SphereDashboardAPITypes.GroupDetailsProjectSummary;
        }>
      ) => {
        if (params.row.entity.role) {
          return (
            <span style={{ fontSize: DEFAULT_TEXT_FONT_SIZE }}>
              {formatUserRoleType(params.row.entity.role)}
            </span>
          );
        }
      },
    },

    [MemberGroupProjectsHeaders.options]: {
      field: MemberGroupProjectsHeaders.options,
      type: "actions",
      align: "right",
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- This is needed for the flex to work properly
      flex: options.isScreenLgOrLarger ? 0.5 : undefined,
      maxWidth: MD_OR_SMALLER_COLUMNS_WIDTH_IN_PX,
      minWidth: 50,
      renderCell: (
        params: GridRenderCellParams<{
          entity: SphereDashboardAPITypes.GroupDetailsProjectSummary;
        }>
      ) => {
        return (
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              // This will be overridden by parent component using SHOW_ON_HOVER_CLASS
              visibility: "hidden",
            }}
            className={SHOW_ON_HOVER_CLASS}
          >
            <ProjectActions
              project={params.row.entity}
              contextType="member-group-project-list-view"
            />
          </Box>
        );
      },
    },
  };
}
