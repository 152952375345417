import { Box } from "@mui/material";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { SidePanelTabs } from "@components/sidepanel/sidepanel-tabs";
import { SidepanelTabProps } from "@components/sidepanel/sidepanel-types";

export interface Props {
  /** Content displayed at the top of the sticky side panel. */
  header: ReactNode;

  /** Default content displayed when no tabs exists. */
  defaultContent?: ReactNode;

  /** Data needed for each tab */
  tabs?: SidepanelTabProps[];

  /** The tab that is selected */
  selectedTab?: string;
}

/**
 * Component for displaying a sticky side panel with optional tabs and content.
 *
 * This component renders a sticky side panel that includes a header, optional tabs,
 * and either tab content or default content based on the presence of tabs.
 *
 * @returns JSX.Element
 */
export function StickySidePanelContainer({
  header,
  tabs,
  defaultContent,
  selectedTab,
}: Props): JSX.Element {
  const [activeTab, setActiveTab] = useState<string | undefined>(selectedTab);

  /**
   * Handle tab change event.
   * This function is used to update the active tab when a new tab is clicked.
   *
   * @param _ - The synthetic event object (not used in this function).
   * @param newValue - The value (label) of the newly selected tab.
   */
  const handleChange = useCallback(
    (_: React.SyntheticEvent, newValue: string) => {
      setActiveTab(newValue);
    },
    []
  );

  /**
   * Memoized selected tab content.
   * This function retrieves the content associated with the currently active tab.
   * It uses `activeTab` to find and return the content of the selected tab from the `tabs` array.
   *
   * @returns The content of the currently active tab, or undefined if no tab is selected.
   */
  const selectedTabContent = useMemo(
    () => tabs?.find(({ label }) => label === activeTab)?.content(),
    [tabs, activeTab]
  );

  return (
    <>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          background: "white",
          zIndex: 1,
          paddingTop: "16px",
        }}
      >
        {header}

        {tabs && (
          <SidePanelTabs
            tabs={tabs}
            selectedTab={activeTab}
            handleChange={handleChange}
          />
        )}
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            mt: defaultContent ? "12px" : "0",
          }}
        />
      </Box>
      {selectedTabContent || defaultContent}
    </>
  );
}
