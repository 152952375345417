import { Box, Fade, Menu, MenuItem, Stack } from "@mui/material";
import { useMemo, useState } from "react";
import DownArrow from "@assets/icons/new/arrow-down_21px.svg?react";
import GenericCheck from "@assets/icons/generic-check_l.svg?react";
import { sphereColors } from "@styles/common-colors";
import { ValueLabelItem } from "@custom-types/types";

interface Props<T extends string | number> {
  /** List of selectable items */
  items: ValueLabelItem<T>[];

  /** Current value */
  currentValue: T;

  /** Callback that handles the selected value change */
  handleChange(value: T): void;
}

/** Renders a dropdown with selectable items */
export function SphereDropdownSelector<T extends string | number>({
  items,
  currentValue,
  handleChange,
}: Props<T>): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const currentValueLabel = useMemo(() => {
    const currentValueItem = items.find((item) => item.value === currentValue);
    return currentValueItem?.label ?? currentValue;
  }, [currentValue, items]);

  function onValueChange(value: T): void {
    handleChange(value);

    // Close dropdown after handling the change of value
    setAnchorEl(null);
  }

  return (
    <Box>
      <Box
        component="span"
        onClick={(event: React.MouseEvent<HTMLElement>) =>
          setAnchorEl(event.currentTarget)
        }
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "14px",
          cursor: "pointer",
          width: "fit-content",
        }}
      >
        <Box
          sx={{
            marginRight: "6px",
          }}
        >
          {currentValueLabel}
        </Box>
        <DownArrow width="16px" height="16px" />
      </Box>
      <Menu
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={() => setAnchorEl(null)}
        TransitionComponent={Fade}
        slotProps={{
          paper: {
            sx: {
              minWidth: "126px",
            },
          },
        }}
        sx={{
          "& .MuiMenuItem-root": {
            marginX: "8px",
            "&:hover": {
              color: sphereColors.blue500,
            },
          },
          "& .Mui-selected": {
            "&:hover": {
              backgroundColor: "rgba(25, 118, 210, 0.08) !important",
            },
          },
        }}
      >
        {items.map((item, index) => (
          <MenuItem
            key={index}
            value={item.value}
            selected={item.value === currentValue}
            onClick={() => onValueChange(item.value)}
            sx={{
              fontSize: "14px",
              paddingX: "8px",
            }}
          >
            <Stack
              sx={{
                alignItems: "center",
                gap: "20px",
                flexDirection: "row",
                color:
                  item.value === currentValue
                    ? sphereColors.blue500
                    : "inherit",
              }}
            >
              {item.label}

              {item.value === currentValue && (
                <GenericCheck
                  width="20px"
                  height="20px"
                  fill={sphereColors.blue500}
                />
              )}
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
