import { BaseMarkupProps } from "@custom-types/project-markups-types";
import { DASH } from "@utils/ui-utils";

/** Gets the markup location string value to be used for the table sort comparator or exporting as CSV */
export function getMarkupLocationValue({ markup }: BaseMarkupProps): string {
  const areaSectionLabel = markup.areaSection?.name ?? DASH;
  const sectionLabel = markup.section?.name ?? DASH;

  return `${areaSectionLabel} / ${sectionLabel}`;
}
