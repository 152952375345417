import { useEffect } from "react";
import { useCoreApiClient } from "@api/use-core-api-client";
import { useAppParams } from "@router/router-helper";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { companyMembersSelector } from "@store/members/members-selector";
import { fetchCompanyMembers } from "@store/members/members-slice";
import { MemberTypes } from "@custom-types/member-types";

/** Returns all the members in the company */
export function useCompanyMembers(): MemberTypes[] {
  const coreApiClient = useCoreApiClient();
  const { companyId } = useAppParams();
  const dispatch = useAppDispatch();
  const companyMembers = useAppSelector(companyMembersSelector);

  // Fetch all the members in the company
  useEffect(() => {
    async function fetchCompanyMembersViaStore(): Promise<void> {
      if (companyId) {
        await dispatch(
          fetchCompanyMembers({
            coreApiClient,
            companyId,
          })
        );
      }
    }

    fetchCompanyMembersViaStore();
  }, [companyId, coreApiClient, dispatch]);

  return companyMembers;
}
