import { Grid, Stack } from "@mui/material";
import { useMemo, useState } from "react";
import { FaroDialog } from "@components/common/dialog/faro-dialog";
import { useCoreApiClient } from "src/api/use-core-api-client";
import { useAppParams } from "@router/router-helper";
import { FaroButton } from "@components/common/faro-button";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { LabelWithHelp } from "@components/common/label-with-help";
import { FaroSimpleTextField } from "@components/common/faro-text-field/faro-simple-text-field";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { MembersAutocomplete } from "@components/common/members-autocomplete/members-autocomplete";
import {
  getMemberLabel,
  AutoCompleteMenuOption,
} from "@components/common/members-autocomplete/members-autocomplete-utils";
import { useCompanyMembers } from "@hooks/use-company-members";
import { isValidEmail } from "@utils/member-utils";
import { addGroup } from "@store/groups/groups-slice";
import {
  AutoCompleteMessage,
  FaroTextFieldMessage,
} from "@components/common/faro-text-field/faro-text-field-message";
import { currentUserSelector } from "@store/user/user-selector";
import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { STACK_ERROR_SX } from "@styles/common-styles";

interface Props {
  /** List of all the available groups in the company */
  groups: SphereDashboardAPITypes.ICompanyGroup[];
}

/**
 * Renders the create group button and the dialog to be used to create new groups.
 * This component does not take care of the authorization of the user to create groups.
 */
export function CreateGroup({ groups }: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const { handleErrorWithToast } = useErrorContext();

  const coreApiClient = useCoreApiClient();
  const { companyId } = useAppParams();
  const companyMembers = useCompanyMembers();

  const currentUser = useAppSelector(currentUserSelector);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /** Stores the name for the group to be created */
  const [groupName, setGroupName] = useState<string>("");
  /** Stores the ids of the users to be assigned as group managers */
  const [selectedGroupManagerIds, setSelectedGroupManagerIds] = useState<
    string[]
  >([]);

  /** Stores whether the create button should be disabled */
  const isCreateDisabled: boolean = useMemo(() => {
    // The first page is never disabled because the fields are validated
    return !groupName || !selectedGroupManagerIds.length;
  }, [groupName, selectedGroupManagerIds]);

  /** Stores the names of the groups the user has access to. */
  const groupNames = groups.map((group) => group.name);

  /** Stores the members of the company in the format required by the autocomplete */
  const memberOptions: AutoCompleteMenuOption[] = useMemo(() => {
    return companyMembers.map((member) => ({
      label: getMemberLabel({ member }),
      value: member.identity,
      originalMember: member,
      // If the user is a group manager, it should be added to the group by default
      // and it should not be removed, otherwise the creator of the group would not have access to it.
      isPreselected:
        currentUser?.role === CoreAPITypes.EUserCompanyRole.companyManager &&
        currentUser?.identity === member.identity,
    }));
  }, [companyMembers, currentUser]);

  /** Stores the ids of the members of the company */
  const memberIds = useMemo(() => {
    return companyMembers.map(
      (member) => member.id?.toLowerCase() ?? member.email.toLowerCase()
    );
  }, [companyMembers]);

  /** Stores the message to be displayed in the autocomplete */
  const groupManagersMessage: AutoCompleteMessage | undefined = useMemo(() => {
    if (
      selectedGroupManagerIds.some(
        (member) => !memberIds.includes(member.toLowerCase())
      )
    ) {
      return {
        type: "info",
        helperText:
          "Inviting members by email will also invite them to the workspace",
      };
    }
    return undefined;
  }, [selectedGroupManagerIds, memberIds]);

  /** Resets the dialog content, and opens the dialog. */
  async function onOpenDialog(): Promise<void> {
    setGroupName("");
    // Add all the preselected members as group managers by default.
    const groupManagerIds: string[] = memberOptions
      .filter((member) => member.isPreselected)
      .map((member) => member.value);
    setSelectedGroupManagerIds(groupManagerIds);
    setIsDialogOpen(true);
  }

  /** Triggered when the create button is clicked, creates the group and closes the dialog */
  async function handleConfirm(): Promise<void> {
    if (!companyId) {
      throw new Error("Company id is not defined");
    }
    setIsLoading(true);
    try {
      await dispatch(
        addGroup({
          coreApiClient,
          companyId,
          groupName,
          selectedGroupManagerIds,
        })
      );
    } catch (error) {
      handleErrorWithToast({
        id: `createGroup-${Date.now().toString()}`,
        title: "Error creating group",
        error,
      });
    } finally {
      setIsLoading(false);
      setIsDialogOpen(false);
    }
  }

  return (
    <>
      {/* Button to open the dialog */}
      <FaroButton onClick={onOpenDialog}>New group</FaroButton>

      {/* Create group dialog */}
      <FaroDialog
        title="New group"
        open={isDialogOpen}
        confirmText="Create"
        isConfirmDisabled={isCreateDisabled}
        onConfirm={handleConfirm}
        isConfirmLoading={isLoading}
        onClose={() => setIsDialogOpen(false)}
      >
        <Grid maxWidth="100%" width="70vw">
          {/* Group name */}
          <Stack sx={STACK_ERROR_SX}>
            <LabelWithHelp title="Group name" isRequired />
            <FaroSimpleTextField
              placeholder="Enter group name"
              size="small"
              fullWidth
              value={groupName}
              onChange={(event) => setGroupName(event.target.value)}
            />
            <FaroTextFieldMessage
              shouldHide={!groupNames.includes(groupName)}
              message={{
                type: "info",
                helperText: "Group name already exists",
              }}
            />
          </Stack>

          {/* Groups manager */}
          <Stack sx={STACK_ERROR_SX}>
            <MembersAutocomplete
              options={memberOptions}
              handleChange={setSelectedGroupManagerIds}
              placeHolder="Enter name or email"
              message={groupManagersMessage}
              validateNewOption={isValidEmail}
              // If one of the members is preselected, they should be added to the group by default.
              initialValue={memberOptions.filter(
                (memberOption) => memberOption.isPreselected
              )}
              labelTitle="Group manager"
            />
          </Stack>
        </Grid>
      </FaroDialog>
    </>
  );
}
