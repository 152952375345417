import { Link, SxProps } from "@mui/material";
import { sphereColors } from "@styles/common-colors";

interface Props {
  /** Link text */
  text: string;

  /** Callback when link is clicked */
  onClick: () => void;

  /** Optional style to pass to the Link component */
  sx?: SxProps;
}

/** Renders a link */
export function SphereTextLink({ text, onClick, sx }: Props): JSX.Element {
  return (
    <Link
      component="button"
      underline="none"
      onClick={onClick}
      sx={{
        color: sphereColors.blue500,
        ...sx,
      }}
    >
      {text}
    </Link>
  );
}
