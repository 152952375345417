import { UploadFailedFile } from "@custom-types/file-upload-types";
import { Box } from "@mui/material";
import { withEllipsis } from "@styles/common-styles";

interface Props {
  /** List of failed uploaded files */
  failedUploads: UploadFailedFile[];

  /** Name of the project where files attempted to upload */
  projectName?: string;
}

/** Content of the warning toast shown when some files failed to upload */
export function FailedUploadsToastContent({
  failedUploads,
  projectName,
}: Props): JSX.Element | undefined {
  if (!failedUploads.length) {
    return;
  }

  return (
    <Box
      data-testid="failed-uploads-toast-content"
      sx={{
        mt: "12px",
        fontSize: "12px",
      }}
    >
      <Box>These files failed to upload:</Box>
      <Box
        sx={{
          maxHeight: "100px",
          overflowY: "auto",
          scrollbarWidth: "thin",
          mb: "12px",
        }}
      >
        {failedUploads.map((failUpload, index) => (
          <Box
            key={index}
            component={"var"}
            sx={{
              display: "block",
              ...withEllipsis,
            }}
          >
            {"- "}
            {failUpload.fileName}
          </Box>
        ))}
      </Box>
      <Box>
        {projectName
          ? "Please try to import them again to project: "
          : "Please try to import them again."}
      </Box>
      {projectName && <var>{projectName}</var>}
    </Box>
  );
}
