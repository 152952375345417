import { FaroButton, FaroButtonProps } from "@components/common/faro-button";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { Box } from "@mui/material";

type Props = Pick<FaroButtonProps, "onClick">;

/** Button to import ELS scan data */
export function ImportDataButton({ onClick }: Props): JSX.Element {
  return (
    <SphereTooltip
      title={
        <Box>
          <Box
            sx={{
              fontWeight: "bold",
              fontSize: "14px",
            }}
          >
            Upload from your computer
          </Box>
          For now, only .gls files can be uploaded.
        </Box>
      }
    >
      <FaroButton onClick={onClick}>Import data</FaroButton>
    </SphereTooltip>
  );
}
