import { Typography } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { getProjectByIdSelector } from "@store/projects/projects-selector";
import { CardLayout } from "@components/progress-overview/card-layout";
import { FileUploadTask } from "@custom-types/file-upload-types";
import { DEFAULT_TEXT_FONT_SIZE, withEllipsis } from "@styles/common-styles";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { useMemo } from "react";
import { removeOne } from "@store/upload-tasks/upload-tasks-slice";

interface ErrorCardProps<Task extends FileUploadTask = FileUploadTask> {
  /** Id of the task to show an error card for */
  task: Task;
}

/**
 * Renders dismissible card showing an error and the cause, if available
 */
export function ErrorCard({ task }: ErrorCardProps): JSX.Element {
  const project = useAppSelector(
    getProjectByIdSelector(task.context.projectId)
  );
  const dispatch = useAppDispatch();

  const errorMessage = useMemo(() => {
    return task.errorMessage ?? "Unexpected error. Please contact support.";
  }, [task.errorMessage]);

  async function dismissErrorTask(): Promise<void> {
    dispatch(removeOne(task.id));
  }

  return (
    <CardLayout
      name={task.fileName}
      projectName={project?.name}
      menu={[
        {
          name: "Clear",
          callback: dismissErrorTask,
        },
      ]}
    >
      <Typography
        sx={{
          color: sphereColors.red600,
          fontWeight: "600",
          fontSize: DEFAULT_TEXT_FONT_SIZE,
          ...withEllipsis,
        }}
      >
        {errorMessage}
      </Typography>
    </CardLayout>
  );
}
