import { GridPaginationModel } from "@mui/x-data-grid-pro";

interface GetTotalPagesProps extends Pick<GridPaginationModel, "pageSize"> {
  /** The total number of items that are being paginated. */
  totalItems: number;
}

/**
 * Defines the total number of pages based on the total number of items and the page size.
 */
export function getTotalPages({
  totalItems,
  pageSize,
}: GetTotalPagesProps): number {
  return Math.ceil(totalItems / pageSize);
}

/**
 * Defines the start items based on the page size and the current page.
 */
export function getStartItems({ pageSize, page }: GridPaginationModel): number {
  return pageSize * page + 1;
}

interface GetEndItemsProps extends GridPaginationModel {
  /** The total number of items that are being paginated. */
  totalItems: number;

  /** The total number of pages. */
  totalPages: number;
}

/**
 * Defines the end items based on the page size, the current page,
 * the total number of items, and the total number of pages.
 */
export function getEndItems({
  pageSize,
  page,
  totalItems,
  totalPages,
}: GetEndItemsProps): number {
  // If we are in the last page, we show the total of items as end items,
  // otherwise could say 21-30 of 25.
  if (totalPages === page + 1) {
    return totalItems;
  }

  return pageSize * (page + 1);
}
