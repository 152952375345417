import { useMemo } from "react";

import { ApiClient } from "@stellar/api-logic";

import { runtimeConfig } from "@src/runtime-config";

let client: ApiClient;

/**
 * Initializes client to Core.API if not already initialized and returns it
 */
export function useCoreApiClient(): ApiClient {
  // Make sure the client is only created once
  const memoizedClient = useMemo(() => {
    client = new ApiClient({
      url: runtimeConfig.urls.apiBaseUrl,
      powerBiUrl: runtimeConfig.urls.powerBIBaseUrl,
      clientOptions: {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention -- this key is defined by the backend
          "X-Holobuilder-Component": "dashboard",
        },
      },
    });

    return client;
  }, []);

  return memoizedClient;
}
