import { Box, ListItem } from "@mui/material";
import { SidebarList } from "@components/sidebar/sidebar-list";
import { SidebarItems, SidebarHBItem } from "@components/sidebar/sidebar-items";
import FaroSphereLogo from "@assets/icons/faro-sphere-xg-logo-color.svg?react";
import FaroSphereLogoMini from "@assets/icons/faro-sphere-xg-logo-mini.svg?react";
import RightArrow from "@assets/icons/expand-arrow.svg?react";
import LeftArrow from "@assets/icons/collapse-arrow.svg?react";
import { sphereColors } from "@styles/common-colors";
import { useAppDispatch } from "@store/store-helper";
import { updateSidebar } from "@store/ui/ui-slice";
import { NavLink } from "react-router-dom";
import { MainRoutes } from "@router/route-params";
import { SidebarEvents } from "@utils/track-event/track-event-list";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { CommonStyles } from "@styles/common-styles";
import { SidebarHolobuilderItem } from "@components/sidebar/sidebar-holobuilder/sidebar-holobuilder-item";
import { SdbCompanyMenu } from "@components/sidebar/sdb-company/sdb-company-menu";
import { useTrackEvent } from "@utils/track-event/use-track-event";

interface Props {
  /** Flag whether the sidebar should be shown full size or minified */
  isSidebarFullSize: boolean;

  /** Flag whether the sidebar should be shown in mobile (temporary) version */
  isSidebarMobileVersion: boolean;
}

export function SidebarContent({
  isSidebarFullSize,
  isSidebarMobileVersion,
}: Props): JSX.Element {
  const { trackEvent } = useTrackEvent();
  const dispatch = useAppDispatch();
  return (
    <>
      {/* Top components */}
      <div>
        <NavLink to={MainRoutes.projects}>
          <Box
            component="div"
            sx={{
              pt: "14px",
              pl: "8px",
              pb: "16px",
            }}
            onClick={() => {
              trackEvent({ name: SidebarEvents.goHome });
            }}
          >
            {isSidebarFullSize ? <FaroSphereLogo /> : <FaroSphereLogoMini />}
          </Box>
        </NavLink>
        <SdbCompanyMenu />
        <SidebarList
          isSidebarFullSize={isSidebarFullSize}
          sidebarItems={SidebarItems}
        />
      </div>

      {/* Bottom components */}
      <div>
        {/* Switch to HoloBuilder button */}
        {/* This component handles its visibility in the sidebar internally. */}
        <SidebarHolobuilderItem
          isSidebarFullSize={isSidebarFullSize}
          sidebarItem={SidebarHBItem}
        />
        {/* Button to toggle the full size state of the side bar (not available on mobile version) */}
        {!isSidebarMobileVersion && (
          <ListItem
            sx={{
              width: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: isSidebarFullSize ? "flex-end" : "center",
              borderTop: CommonStyles.Borders.gray200Divider,
              color: sphereColors.gray800,
              minHeight: "64px",
            }}
          >
            <FaroIconButton
              dataTestId="SIDEBAR_TOGGLE_BUTTON"
              component={isSidebarFullSize ? LeftArrow : RightArrow}
              onClick={() => {
                trackEvent({
                  name: isSidebarFullSize
                    ? SidebarEvents.collapse
                    : SidebarEvents.expand,
                });
                dispatch(updateSidebar({ isFullSize: !isSidebarFullSize }));
              }}
            />
          </ListItem>
        )}
      </div>
    </>
  );
}
