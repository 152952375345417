import {
  Popper,
  FormControl,
  MenuItem,
  Box,
  Typography,
  ClickAwayListener,
} from "@mui/material";
import { useState, Fragment, Dispatch, SetStateAction } from "react";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { sphereColors } from "@styles/common-colors";
import {
  UserItem,
  UserListItem,
} from "@components/common/members-autocomplete/user-list-item";
import { getUserInitials } from "@utils/user-utils";
import { BaseMarkupProps, Markup } from "@custom-types/project-markups-types";
import { MemberTypes } from "@custom-types/member-types";
import { markupsTemplateIdsSelector } from "@store/markups/markups-selector";
import { useAppSelector } from "@store/store-helper";
import { useMarkupContext } from "@context-providers/markup/markup-context";
import { Overlay } from "@components/overlay/overlay";
import { useProjectMarkupUpdate } from "@hooks/project-markups/use-project-markup-update";
import { cloneDeep, findIndex } from "lodash";
import {
  IElementType,
  IElementUserDirectoryMarkupField,
} from "@faro-lotv/ielement-types";
import { currentUserSelector } from "@store/user/user-selector";
import { FilterChipSearch } from "@components/common/faro-table/faro-table-filter/filter-chip-search";

export interface Props extends BaseMarkupProps {
  /** Used to control the visibility of the Popper */
  anchorEl: HTMLElement | null;

  /** List of project members */
  projectMembers: SphereDashboardAPITypes.IProjectMemberBase[];

  /** The member that was defined for specific markup */
  assigneeMember?: MemberTypes;

  /** Function to update the spinner loading */
  setIsSpinnerLoading: Dispatch<SetStateAction<boolean>>;

  /**
   * Function called to close the assignee dropdown.
   */
  closeAssigneeDropdown: () => void;
}

/**
 * Component responsible for rendering the editable content of the assignee name
 * in the markup.
 *
 * This component displays a TextField with a filterable list of project members,
 * allowing selection of a member as an assignee for a specific markup.
 */
export function MarkupAssigneeEditable({
  closeAssigneeDropdown,
  anchorEl,
  setIsSpinnerLoading,
  projectMembers,
  markup,
  assigneeMember,
}: Props): JSX.Element {
  const { projectId, updateMarkups, setIsMarkupUpdating, markups } =
    useMarkupContext();
  const { updateNewMember } = useProjectMarkupUpdate({ projectId });

  const templateIds = useAppSelector(markupsTemplateIdsSelector);
  const currentUser = useAppSelector(currentUserSelector);

  const [searchText, setSearchText] = useState<string>("");
  const [filteredMembers, setFilteredMembers] =
    useState<SphereDashboardAPITypes.IProjectMemberBase[]>(projectMembers);

  /**
   * Callback called when the search text in the TextField is changed.
   * Filters the list of project members based on the search text.
   */
  function handleSearchChange(text: string): void {
    setSearchText(text);

    if (text.trim() === "") {
      setFilteredMembers(projectMembers);
    } else {
      const filtered = projectMembers.filter(
        (member) =>
          member.name && member.name.toLowerCase().includes(text.toLowerCase())
      );
      setFilteredMembers(filtered);
    }
  }

  /**
   * Maps a project member to a `UserItem` object to display in the suggestion list.
   */
  function mapOptionToUserItem(
    member: SphereDashboardAPITypes.IProjectMemberBase
  ): UserItem {
    return {
      title: member.name || member.email,
      userInitials: getUserInitials(member),
      userAvatarImg: member.thumbnailUrl,
    };
  }

  /** Update markups on context */
  function processMarkupChanges(newMarkups: Markup[]): void {
    updateMarkups(newMarkups);

    setIsSpinnerLoading(false);
    setIsMarkupUpdating(false);
  }

  /**
   * Adds a new member as an assignee for the markup.
   */
  async function updateMember(
    newMember: SphereDashboardAPITypes.IProjectMemberBase
  ): Promise<void> {
    if (!templateIds) {
      throw new Error("Expected project to have an advanced markup template");
    }

    closeAssigneeDropdown();
    setIsSpinnerLoading(true);
    setIsMarkupUpdating(true);

    const mutation = await updateNewMember(
      markup,
      newMember,
      templateIds.assigneeTemplateId
    );

    if (!mutation || !currentUser) {
      return;
    }

    const newMarkups = cloneDeep(markups);
    const markupIndex = findIndex(newMarkups, { id: markup.id });
    const currentAssignee = newMarkups[markupIndex].assignee;

    if ("newElement" in mutation) {
      const newElement = mutation.newElement;

      const newItem: IElementUserDirectoryMarkupField = {
        ...newElement,
        type: IElementType.userDirectoryMarkupField,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        root_Id: newElement.rootId,
        createdBy: currentUser.identity,
        createdAt: mutation.createdAt,
        modifiedBy: currentUser.identity,
        modifiedAt: mutation.createdAt,
      };

      newMarkups[markupIndex].assignee = newItem;

      processMarkupChanges(newMarkups);

      return;
    }

    if (!currentAssignee) {
      throw new Error("Assignee is required but was undefined.");
    }

    const newAssignee: IElementUserDirectoryMarkupField = {
      ...currentAssignee,
      // I can not use the id because when I update and after I try to delete the assignee
      // I will have conflicts between ids
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      id: mutation.elementId!,
      createdAt: mutation.createdAt,
      values: [...mutation.ids],
    };

    newMarkups[markupIndex].assignee = newAssignee;

    processMarkupChanges(newMarkups);
  }

  return (
    <Overlay>
      <ClickAwayListener onClickAway={closeAssigneeDropdown}>
        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement={"bottom-start"}
          disablePortal={false}
          modifiers={[
            {
              name: "flip",
              // eslint-disable-next-line @typescript-eslint/naming-convention
              enabled: true,
            },
          ]}
          sx={{
            width: "280px",
            boxShadow: "0px 3px 3px 0px rgba(133, 146, 173, 0.20)",
            zIndex: "9999",
          }}
        >
          <FormControl sx={{ background: "white", width: "100%" }}>
            <FilterChipSearch
              searchText={searchText}
              setSearchText={(text) => handleSearchChange(text.toString())}
            />
            <Box
              sx={{
                height: "36px",
                display: "flex",
                alignItems: "center",
                padding: "8px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: "700",
                  lineHeight: "14px",
                  color: sphereColors.gray800,
                }}
              >
                ASSIGNEE MEMBER
              </Typography>
            </Box>

            <Box sx={{ maxHeight: "236px", overflowY: "auto", padding: "8px" }}>
              {filteredMembers.length === 0 ? (
                <MenuItem sx={{ height: "36px", pointerEvents: "none" }}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: sphereColors.gray800,
                    }}
                  >
                    No member found!
                  </Typography>
                </MenuItem>
              ) : (
                filteredMembers.map((member, index) => (
                  <Fragment key={index}>
                    <Box
                      onClick={() => {
                        if (member.identity !== assigneeMember?.identity) {
                          updateMember(member);
                        }
                      }}
                    >
                      <UserListItem
                        item={mapOptionToUserItem(member)}
                        inputValue={searchText}
                        isActive={
                          assigneeMember
                            ? member.identity === assigneeMember?.identity
                            : false
                        }
                      />
                    </Box>
                  </Fragment>
                ))
              )}
            </Box>
          </FormControl>
        </Popper>
      </ClickAwayListener>
    </Overlay>
  );
}
