/** All the possible headers for background tasks table. */
export enum BackgroundTasksTableHeaders {
  /** The Background Task icon */
  icon = "Icon",

  /** The type of background task */
  fileName = "File name",

  /** The status of background task */
  status = "Status",

  /** The progress of background task */
  progress = "Progress",

  /** The created date and time of background task */
  created = "Created",

  /** The updated date and time of background task */
  updated = "Updated",

  /** Background task options */
  options = "Options",
}
