import { DataDisplayActions } from "@components/common/data-display-actions";
import { CreateGroup } from "@components/groups/create-group";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";

interface Props {
  /** List of all the available groups in the company */
  groups: SphereDashboardAPITypes.ICompanyGroup[];
}

/**
 * The action buttons to be shown in the groups page
 */
export function GroupsActionButtons({ groups }: Props): JSX.Element {
  const { canCreateGroups } = useHasUserValidRoleCompanyLevel();

  return (
    <>
      <DataDisplayActions subjectType="group" />
      {canCreateGroups && <CreateGroup groups={groups} />}
    </>
  );
}
