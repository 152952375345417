import { ExternalMarkupIElement } from "@faro-lotv/ielement-types";
import { useMemo } from "react";
import { getIntegrationName } from "@pages/project-details/project-markups/markup-integration-utils";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import {
  isIElementMarkupProcoreObservation,
  isIElementMarkupProcoreRfi,
} from "@custom-types/i-elements-type-guards";
import Bim360 from "@assets/icons/new/bim360-icon_25px.svg?react";
import Procore from "@assets/icons/new/procore-icon_25px.svg?react";
import { Box } from "@mui/material";

interface Props {
  /** Associated external markup element (Procore or Bim360) */
  externalMarkup?: ExternalMarkupIElement;
}

/** Render the markup integration icon if there is an integration */
export function MarkupIntegration({ externalMarkup }: Props): JSX.Element {
  const label = useMemo(() => {
    return getIntegrationName(externalMarkup) ?? "-";
  }, [externalMarkup]);

  const isProcoreMarkup = useMemo(() => {
    return (
      externalMarkup &&
      (isIElementMarkupProcoreRfi(externalMarkup) ||
        isIElementMarkupProcoreObservation(externalMarkup))
    );
  }, [externalMarkup]);

  return externalMarkup ? (
    <SphereTooltip title={label}>
      {isProcoreMarkup ? <Procore /> : <Bim360 />}
    </SphereTooltip>
  ) : (
    <Box>{"-"}</Box>
  );
}
