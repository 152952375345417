import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { AnalyticsChartData } from "@custom-types/analytics-types";
import {
  getChartJsData,
  getChartJsOptions,
} from "@components/common/sphere-bar-chart/sphere-bar-chart-utils";
import { Box, Skeleton, SxProps } from "@mui/material";

/**
 * Chart.js is tree-shakeable, so it is necessary to import and register
 * the controllers, elements, scales and plugins you are going to use.
 * https://www.chartjs.org/docs/latest/getting-started/integration.html#bundlers-webpack-rollup-etc
 */
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

/* eslint-disable @typescript-eslint/no-magic-numbers -- aspect ratio value */
/** Default chart aspect ratio */
const DEFAULT_CHART_ASPECT_RATIO = 4 / 3;
/* eslint-enable @typescript-eslint/no-magic-numbers */

export interface SphereBarChartProps {
  /** Data to display as chart. If not defined it will display a skeleton */
  data?: AnalyticsChartData;

  /** Chart aspect ratio value. If not defined it will use the default aspect ratio */
  aspectRatio?: number;

  /** Whether the chart should show an skeleton animation instead of the chart */
  shouldShowSkeleton?: boolean;

  /** Whether the chart title should be hidden. Set to false by default. */
  shouldHideTitle?: boolean;

  /** Optional style props to pass to the chart container */
  sx?: SxProps;
}

/** Renders a single Sphere bar chart */
export function SphereBarChart({
  data,
  aspectRatio = DEFAULT_CHART_ASPECT_RATIO,
  shouldShowSkeleton = false,
  shouldHideTitle = false,
  sx,
}: SphereBarChartProps): JSX.Element {
  return (
    <Box
      component="div"
      sx={{
        width: "100%",
        aspectRatio,
        ...sx,
      }}
    >
      {data && !shouldShowSkeleton ? (
        <Bar
          options={getChartJsOptions({
            data,
            aspectRatio,
            shouldShowTitle: !shouldHideTitle,
          })}
          data={getChartJsData({
            data,
          })}
        />
      ) : (
        <Skeleton
          variant="rectangular"
          sx={{ width: "100%", height: "100%" }}
        />
      )}
    </Box>
  );
}
