import { Box, Grid } from "@mui/material";
import projectThumbnail1 from "@src/assets/sdb-default-project-thumbnail_1.svg";
import projectThumbnail2 from "@src/assets/project-thumbnail_2.svg";
import projectThumbnail3 from "@src/assets/project-thumbnail_3.svg";
import projectThumbnail4 from "@src/assets/project-thumbnail_4.svg";
import projectThumbnail5 from "@src/assets/project-thumbnail_5.svg";
import GenericCheckIcon from "@assets/icons/generic-check_l.svg?react";
import { sphereColors } from "@styles/common-colors";

interface Props {
  /** The selected image from gallery */
  selectedThumbnail: string;

  /** Callback function to enable or disable confirm selection of image from gallery */
  onSelectImage(imageUrl: string): void;
}

/** List of default thumbnail images for project */
const projectThumbnails = [
  projectThumbnail1,
  projectThumbnail2,
  projectThumbnail3,
  projectThumbnail4,
  projectThumbnail5,
];

/** Renders the project thumbnail gallery tab content  */
export function ProjectThumbnailGallery({
  selectedThumbnail,
  onSelectImage,
}: Props): JSX.Element {
  return (
    <Grid container width="100%" marginTop="12px" spacing={1.5}>
      {projectThumbnails.map((projectThumbnail) => (
        <Grid
          item
          key={projectThumbnail}
          xs={6}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          component="div"
          position="relative"
        >
          <Box
            component="img"
            src={projectThumbnail}
            sx={{
              width: "100%",
              objectFit: "contain",
              borderRadius: "8px",
              padding: "5px",
              cursor: "pointer",
              height: "105px",
              border:
                selectedThumbnail === projectThumbnail
                  ? `2px solid ${sphereColors.blue500}`
                  : `1px solid ${sphereColors.gray200}`,
              "&:hover": {
                border: `2px solid ${sphereColors.blue500}`,
              },
            }}
            alt="Project thumbnail"
            onClick={() => onSelectImage(projectThumbnail)}
          />
          {selectedThumbnail === projectThumbnail && (
            <GenericCheckIcon
              style={{
                fill: sphereColors.blue500,
                height: "32px",
                width: "32px",
                position: "absolute",
                left: "calc(50% - 16px)",
                top: "calc(50% - 16px)",
              }}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
}
