import { useFilterQueryParams } from "@hooks/table-filters/use-filter-query-params";
import { TableType } from "@utils/track-event/track-event-list";
import { BaseFilterMapId } from "@components/common/faro-table/faro-table-filter/faro-table-filter-types";
import { HeaderForTableType } from "@components/common/faro-table/faro-table-types";
import { FilterChip } from "@components/common/faro-table/faro-table-filter/filter-chip";

interface Props {
  /** The column in which filtering will apply to */
  filteredColumn: HeaderForTableType<TableType>;

  /** The label of the chip to show in filter bar */
  label: string;

  /** The items to show in the dropdown */
  item: BaseFilterMapId;

  /** The table in which the filter is applied to */
  tableType: TableType;
}

/** A chip for just enabling/disabling a filter. */
export function SingleFilterChip({
  filteredColumn,
  label,
  item,
  tableType,
}: Props): JSX.Element {
  const { updateFilterItem } = useFilterQueryParams();

  function onChipClicked(): void {
    updateFilterItem({
      tableType,
      filteredColumn,
      filterId: Object.values(item)[0].id,
      shouldReplaceFilterInColumn: false,
    });
  }

  return (
    <FilterChip
      filteredColumn={filteredColumn}
      onClick={onChipClicked}
      label={label}
      shouldHaveDropdown={false}
    />
  );
}
