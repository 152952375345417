import { Link, LinkProps } from "@mui/material";

/** The available links */
type LinkName =
  | "createNewProjectHelp"
  | "managingGroupsHelp"
  | "videoModeHelp"
  | "photos2dHelp"
  | "permissionsLevelsHelp";

/** Links to external pages used in the application */
export const AvailableLinks: { [key in LinkName]: string } = {
  /* eslint-disable max-len -- External links can get pretty long */

  /** Links to the create new project help page */
  createNewProjectHelp:
    "https://help.holobuilder.com/en/articles/3569568-how-to-create-a-new-project-in-the-holobuilder-enterprise-dashboard",

  /** Links to the managing groups help page */
  managingGroupsHelp:
    "https://help.holobuilder.com/en/articles/2547401-managing-groups-in-the-enterprise-dashboard",

  /** Links to the video mode help page */
  videoModeHelp:
    "https://help.holobuilder.com/en/articles/6592025-how-to-capture-with-holobuilder-videomode-in-the-jobwalk-app",

  /** Links to the 2d photos help page */
  photos2dHelp:
    "https://help.holobuilder.com/en/articles/3548889-how-to-place-2d-photos-directly-on-your-sheet-in-the-jobwalk-app",

  /** Links to the permissions levels help page */
  permissionsLevelsHelp:
    "https://help.holobuilder.com/en/articles/2437877-permissions-roles-in-the-dashboard",
  /* eslint-enable max-len */
};

interface Props {
  /** The name of the link in which the component should head to */
  link: LinkName;

  /** The text to show as hyperlink */
  text?: string;

  /** Optional style to pass */
  sx?: LinkProps["sx"];
}

/** Navigate to provided link when user clicks on the text */
export function ExternalLinks({
  text = "Learn more",
  sx = {},
  link,
}: Props): JSX.Element {
  return (
    <Link
      href={AvailableLinks[link]}
      target="_blank"
      rel="noopener noreferrer"
      sx={{ ...sx }}
    >
      {text}
    </Link>
  );
}
