import {
  PointCloudBulkButtonComponents,
  PointCloudTableBulkName,
} from "@components/common/faro-table/faro-table-types";
import { useShouldShowBulkAction } from "@hooks/table/use-should-show-bulk-action";
import { selectedEntitiesSelector } from "@store/table/table-selector";
import { useAppSelector } from "@store/store-helper";
import { useMemo } from "react";
import { PairwiseRegistration } from "@pages/project-details/project-data/point-clouds/pairwise-registration";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";

/** Component that renders the bulk action buttons of the point clouds table */
export function PointCloudsBulkActions({
  projectId,
}: BaseProjectIdProps): PointCloudBulkButtonComponents {
  const selectedEntities = useAppSelector(
    selectedEntitiesSelector("pointClouds")
  );

  const allowedButtonsBasedOnRowSelection: PointCloudTableBulkName[] =
    useMemo(() => {
      const allowedBulkActions: PointCloudTableBulkName[] = [];

      if (selectedEntities.length >= 2) {
        allowedBulkActions.push("pairwiseRegistration");
      }

      return allowedBulkActions;
    }, [selectedEntities.length]);

  const { shouldShowBulkButtons } = useShouldShowBulkAction({
    allowedButtonsBasedOnRowSelection,
  });

  return {
    pairwiseRegistration: shouldShowBulkButtons("pairwiseRegistration") ? (
      <PairwiseRegistration
        projectId={projectId}
        selectedEntities={selectedEntities}
      />
    ) : null,
  };
}
