import { ProjectMarkupsTableHeaders } from "@pages/project-details/project-markups/project-markups-table-utils";
import { DropdownFilterChip } from "@components/common/faro-table/faro-table-filter/dropdown-filter-chip";
import { TableType } from "@utils/track-event/track-event-list";
import { useProjectMarkupsFilterLists } from "@pages/project-details/project-markups/project-markups-filters/project-markups-filter-list";

/** Show filters and apply them for project markup status */
export function ProjectMarkupsStatusFilter(): JSX.Element {
  const allFilters = useProjectMarkupsFilterLists();
  return (
    <DropdownFilterChip
      label={ProjectMarkupsTableHeaders.status}
      filteredColumn={ProjectMarkupsTableHeaders.status}
      items={allFilters.Status ?? {}}
      shouldShowSearchBox={false}
      shouldShowCheckbox={true}
      tableType={TableType.projectMarkups}
    />
  );
}
