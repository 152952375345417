/**
 * The progress API client code is mostly copied from the Sphere Viewer app client:
 * https://dev.azure.com/faro-connect/ViewerApp/_git/LotvMonorepo?path=/apps/sphere-web-viewer/src/apis/progress-api
 */

import { TokenManager } from "@faro-lotv/service-wires";
import { ProgressApiLatestStatusResponse } from "@api/progress-api/progress-api-types";
import { isProgressApiLatestStatusResponse } from "@api/progress-api/progress-api-type-guards";

/** Number of task items per page that we will fetch from the ProgressAPI */
const ITEMS_PER_PAGE = 30;

type RequestProgressProps = Pick<ProgressApiLatestStatusResponse, "before">;

/**
 * A class to handle the queries regarding long running background tasks
 */
export class ProgressApiClient {
  /**
   * @param projectId Id of the current project
   * @param tokenManager To get tokens for this project
   * @param endpoint The base url of the progress api
   */
  constructor(
    private projectId: string,
    private tokenManager: TokenManager,
    private endpoint: string
  ) {}

  /**
   * @returns the state of the backend tasks
   */
  async requestProgress({
    before,
  }: RequestProgressProps): Promise<ProgressApiLatestStatusResponse> {
    let route = `${this.endpoint}/v1/status/latest?ProjectId=${this.projectId}&pageSize=${ITEMS_PER_PAGE}`;

    if (before) {
      route += `&before=${before}`;
    } else {
      route += "&last=true";
    }

    // Set query param "last=" to "true" to get the latest tasks first
    const req = await fetch(route, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${await this.tokenManager.getToken()}`,
      },
    });
    if (!req.ok) {
      throw new Error(`Unable to talk to ProgressAPI: ${req.statusText}`);
    }

    const response = await req.json();

    if (!isProgressApiLatestStatusResponse(response)) {
      throw new Error("ProgressAPI returned invalid response", {
        cause: response,
      });
    }

    return response;
  }
}
