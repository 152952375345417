import {
  CaptureTreeEntity,
  CaptureTreeEntityType,
  RegistrationRevision,
  RegistrationState,
} from "@faro-lotv/service-wires";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/store-helper";
import {
  allRegistrationRevisionsAdapter,
  captureTreeForMainRevisionAdapter,
} from "@store/capture-tree/capture-tree-slice";
import { CaptureTreeState } from "@store/capture-tree/capture-tree-slice-types";
import { isCaptureTreeScanEntity } from "@pages/project-details/project-data-management/raw-scans/raw-scans-utils";
import { RawScan } from "@pages/project-details/project-data-management/raw-scans/raw-scans-types";
import {
  isPreparedRegistrationRevision,
  isRegistrationRevisionPreparing,
} from "@pages/project-details/project-data-management/prepared-data/prepared-data-utils";
import { GUID } from "@faro-lotv/foundation";
import {
  selectChildDepthFirst,
  selectIElement,
} from "@faro-lotv/project-source";
import { isIElementPointCloudStream } from "@faro-lotv/ielement-types";

/** Returns all the capture tree entities (for the current main revision of the selected project) */
export const captureTreeForMainRevisionSelector: (
  state: RootState
) => CaptureTreeEntity[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return captureTreeForMainRevisionAdapter
      .getSelectors()
      .selectAll(state.captureTree.captureTreeForMainRevision);
  }
);

/** Returns the fetching status of the capture tree entities (for the current main revision of the selected project) */
export const fetchingStatusCaptureTreeForMainRevisionSelector: (
  state: RootState
) => CaptureTreeState["fetchingStatus"]["captureTreeForMainRevision"] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.captureTree.fetchingStatus.captureTreeForMainRevision;
    }
  );

/** Returns whether capture tree entities have been fetched at least once */
export const hasFetchedCaptureTreeForMainRevisionSelector: (
  state: RootState
) => boolean = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.captureTree.hasFetched.hasFetchedCaptureTreeForMainRevision;
  }
);

/** Returns all the registration revisions of the current project */
export const allRegistrationRevisionsSelector: (
  state: RootState
) => RegistrationRevision[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return allRegistrationRevisionsAdapter
      .getSelectors()
      .selectAll(state.captureTree.allRegistrationRevisions);
  }
);

/** Returns the fetching status of all the registration revisions of the current project */
export const fetchingStatusAllRegistrationRevisionsSelector: (
  state: RootState
) => CaptureTreeState["fetchingStatus"]["allRegistrationRevisions"] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.captureTree.fetchingStatus.allRegistrationRevisions;
    }
  );

/** Returns whether all registration revisions of the current project have been fetched at least once */
export const hasFetchedAllRegistrationRevisionsSelector: (
  state: RootState
) => boolean = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.captureTree.hasFetched.hasFetchedAllRegistrationRevisions;
  }
);

/** Returns all the prepared registration revisions of the current project */
export const preparedRegistrationRevisionsSelector: (
  state: RootState
) => RegistrationRevision[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const registrationRevisions = allRegistrationRevisionsSelector(state);

    return registrationRevisions.filter(isPreparedRegistrationRevision);
  }
);

/** Returns the latest published data */
export const elsLatestPublishedDataSelector: (
  state: RootState
) => RegistrationRevision[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const registrationRevisions = allRegistrationRevisionsSelector(state);

    const mergedRevisions = registrationRevisions.filter(
      (registrationRevision) =>
        registrationRevision.state === RegistrationState.merged
    );

    const latestRevision = mergedRevisions.reduce((latest, current) => {
      if (Object.keys(latest).length === 0) {
        return current;
      }
      return current.modifiedAt > latest.modifiedAt ? current : latest;
    }, {} as RegistrationRevision);

    return Object.keys(latestRevision).length === 0 ? [] : [latestRevision];
  }
);

/** Returns true when there is any prepared registration revision still preparing */
export const hasRegistrationsPreparingSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const preparedRegistrations =
        preparedRegistrationRevisionsSelector(state);

      return preparedRegistrations.some(isRegistrationRevisionPreparing);
    }
  );

/**
 * Gets a capture tree entity by providing its ID
 *
 * * @param id ID of the capture tree entity
 */
export function captureTreeEntityByIdSelector(
  id: GUID
): (state: RootState) => CaptureTreeEntity | undefined {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      if (!id) {
        return undefined;
      }

      const entities = captureTreeForMainRevisionSelector(state);
      return entities.find((entity) => entity.id === id);
    }
  );
}

/** @returns all capture tree entities of type scan */
export const captureTreeScanEntitiesSelector: (
  state: RootState
) => CaptureTreeEntity[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const captureTreeForMainRevision =
      captureTreeForMainRevisionSelector(state);

    return captureTreeForMainRevision.filter(isCaptureTreeScanEntity);
  }
);

/**
 * @returns A string representing the path to the cluster where the capture tree entity is located
 *
 * @param id ID of the capture tree entity
 */
export function captureTreeEntityClusterPathSelector(
  id: GUID
): (state: RootState) => string {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      let entity = captureTreeEntityByIdSelector(id)(state);

      if (!entity) {
        return "";
      }

      const pathArray: string[] = [];

      if (entity.type === CaptureTreeEntityType.cluster) {
        pathArray.unshift(entity.name);
      }

      while (entity && entity.parentId) {
        entity = captureTreeEntityByIdSelector(entity.parentId)(state);

        if (entity && entity.type === CaptureTreeEntityType.cluster) {
          pathArray.unshift(entity.name);
        }
      }

      return pathArray.join("/");
    }
  );
}

/**
 * @returns true iff the capture tree scan entity is processed.
 * To check if a scan entity has been processed we check for the presence of
 * the PointCloudStream ielement as children of the scan ielement.
 *
 * @param id ID of the capture tree scan entity
 */
export function isCaptureTreeScanEntityProcessingSelector(
  id: GUID
): (state: RootState) => boolean {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const scanElement = selectIElement(id)(state);

      if (!scanElement) {
        return false;
      }

      const pointCloudStreamElement = selectChildDepthFirst(
        scanElement,
        isIElementPointCloudStream
      )(state);

      return pointCloudStreamElement ? false : true;
    }
  );
}

/** @returns all raw scans */
export const rawScansSelector: (state: RootState) => RawScan[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const scanEntities = captureTreeScanEntitiesSelector(state);

    return scanEntities.map((entity) => {
      return {
        ...entity,
        clusterPath: captureTreeEntityClusterPathSelector(entity.id)(state),
        isProcessing: isCaptureTreeScanEntityProcessingSelector(entity.id)(
          state
        ),
      };
    });
  }
);

/** Returns true when there is any raw scan still processing */
export const hasRawScansProcessingSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const rawScans = rawScansSelector(state);

      return rawScans.some((rawScan) => rawScan.isProcessing);
    }
  );
