import { Box } from "@mui/material";
import { memo } from "react";
import { useAppSelector } from "@store/store-helper";
import { incompleteMarkupAttachmentUploadTasksSelector } from "@store/upload-tasks/upload-tasks-selector";
import { MarkupUploadingAttachmentItem } from "@pages/project-details/project-markups/sidepanel/attachment/markup-uploading-attachment-item";
import { GUID } from "@faro-lotv/foundation";

interface Props {
  /** The id of the selected markup */
  markupId: GUID;
}

/**
 * Renders markup attachment upload tasks.
 */
function MarkupUploadingAttachmentComponent({
  markupId,
}: Props): JSX.Element | null {
  const tasks = useAppSelector(
    incompleteMarkupAttachmentUploadTasksSelector(markupId)
  );

  // Early return if there is no tasks to show
  if (!tasks.length) {
    return null;
  }

  return (
    <Box>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          mt: "12px",
        }}
      />

      {tasks.map((task, i) => (
        <MarkupUploadingAttachmentItem
          task={task}
          key={i}
          isLastElement={i === tasks.length - 1}
        />
      ))}
    </Box>
  );
}

export const MarkupUploadingAttachment = memo(
  MarkupUploadingAttachmentComponent
);
