import { FaroButtonContained } from "@components/common/faro-button-contained";
import { SphereAvatar } from "@components/header/sphere-avatar";
import { UpdateProfilePictureDialog } from "@components/user-info/update-profile-picture-dialog";
import { BaseMemberProps } from "@custom-types/member-types";
import { useAppSelector } from "@store/store-helper";
import { currentUserSelector } from "@store/user/user-selector";
import { getPendingUserIcon, getUserInitials } from "@utils/user-utils";
import { useState } from "react";

/** Renders profile picture section item in member account page */
export function MemberAccountProfilePicture({
  member,
}: BaseMemberProps): JSX.Element {
  const currentUser = useAppSelector(currentUserSelector);
  const [isProfilePictureDialogOpen, setIsProfilePictureDialogOpen] =
    useState<boolean>(false);

  // Use current user if the member is logged in user
  const currentMemberUser =
    currentUser && currentUser?.id === member.id ? currentUser : member;

  return (
    <>
      <SphereAvatar
        src={currentMemberUser.thumbnailUrl}
        initials={getUserInitials(currentMemberUser)}
        icon={getPendingUserIcon(currentMemberUser)}
        shouldUseSimplifiedAvatar={true}
        shouldHideWhiteRim={true}
        size="large"
      />

      {/* Button to show the update profile picture dialog */}
      <FaroButtonContained onClick={() => setIsProfilePictureDialogOpen(true)}>
        {currentMemberUser.thumbnailUrl ? "Change" : "Add"}
      </FaroButtonContained>

      <UpdateProfilePictureDialog
        isDialogOpen={isProfilePictureDialogOpen}
        setIsDialogOpen={setIsProfilePictureDialogOpen}
      />
    </>
  );
}
