import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@src/store/store-helper";
import { BaseErrorState, errorsAdapter } from "@src/store/errors/errors-slice";

/**
 * Returns all the errors
 */
export const errorFlagsSelector: (state: RootState) => BaseErrorState[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return errorsAdapter.getSelectors().selectAll(state.errors);
    }
  );
