import { IElement } from "@faro-lotv/ielement-types";
import {
  // eslint-disable-next-line no-restricted-imports -- import ProjectApi from @faro-lotv/service-wires only here
  ProjectApi as BaseProjectApi,
  GetIElementsParams,
  PaginatedIndexResponse,
} from "@faro-lotv/service-wires";
import { chunk } from "lodash";

/** Chunk size of fetch requests to the Project API when querying by "ids", "ancestorIds" or "descendantIds" */
const FETCH_CHUNK_SIZE = 50;

export class ProjectApi extends BaseProjectApi {
  /**
   * @returns All the iElements that matches the query parameters, will handle multiple page requests internally
   *
   * We implement "getAllIElements" here to override the implementation from the BaseProjectApi
   * in "@faro-lotv/service-wires". The reason is to add extra logic that separates queries by IDs into chunks.
   * If the query params "ids", "ancestorIds" or "descendantIds" are used then the resulting endpoint URL can
   * potentially exceed the URL char length limit. That's why chunks are introduced to avoid this risk
   *
   * This is how all ielements are fetched:
   * - First we check if the IDs queries are used, if so then we will separate the single query into chunks. For
   * each chunk there is a request. If the IDs queries are not used then there will be one single request.
   * - The requests will be done in parallel to the "getIndex" endpoint. This endpoint returns "pageTokens" which
   * is an array of tokens (encoded queries). Each token can be used to fetch all ielements for that token.
   * - As last step we request in parallel to the "getIElements" endpoint all the tokens.
   */
  async getAllIElements({
    signal,
    ids,
    ancestorIds,
    descendantIds,
    ...rest
  }: GetIElementsParams = {}): Promise<IElement[]> {
    const indexPromises: Promise<PaginatedIndexResponse>[] = [];

    if (!ids && !ancestorIds && !descendantIds) {
      indexPromises.push(this.getIndex({ signal, ...rest }));
    } else {
      if (ids && ids.length > 0) {
        const idsChunks = chunk(ids, FETCH_CHUNK_SIZE);
        const idsPromises = idsChunks.map((chunk) =>
          this.getIndex({ signal, ids: chunk, ...rest })
        );
        indexPromises.push(...idsPromises);
      }

      if (ancestorIds && ancestorIds.length > 0) {
        const ancestorIdsChunks = chunk(ancestorIds, FETCH_CHUNK_SIZE);
        const ancestorIdsPromises = ancestorIdsChunks.map((chunk) =>
          this.getIndex({ signal, ancestorIds: chunk, ...rest })
        );
        indexPromises.push(...ancestorIdsPromises);
      }

      if (descendantIds && descendantIds.length > 0) {
        const descendantIdsChunks = chunk(descendantIds, FETCH_CHUNK_SIZE);
        const descendantIdsPromises = descendantIdsChunks.map((chunk) =>
          this.getIndex({ signal, descendantIds: chunk, ...rest })
        );
        indexPromises.push(...descendantIdsPromises);
      }
    }

    try {
      const paginatedIndexResponses = await Promise.all(indexPromises);
      const pageTokensArray = paginatedIndexResponses.map(
        (res) => res.pageTokens
      );
      const pageTokens = pageTokensArray.flat();
      const pages = await Promise.all(
        pageTokens.map((token) =>
          this.getIElements({ signal, nextPageToken: token }).then(
            (res) => res.page
          )
        )
      );
      return pages.flat();
    } catch (error) {
      if (signal?.aborted) {
        return [];
      }
      throw error;
    }
  }
}
