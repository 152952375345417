import { useCoreApiClient } from "@api/use-core-api-client";
import { SphereRadioButton } from "@components/common/sphere-radio-button";
import { OpenProjectTarget } from "@custom-types/sdb-company-types";
import { ValueLabelItem } from "@custom-types/types";
import { useMediaQueryList } from "@hooks/use-media-query";
import { Grid, RadioGroup } from "@mui/material";
import {
  companyCommunicationSettingsSelector,
  defaultOpenProjectTargetSelector,
  fetchingSdbCompanyFlagsSelector,
} from "@store/sdb-company/sdb-company-selector";
import { updateCompanyCommunicationSettings } from "@store/sdb-company/sdb-company-slice";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { CommonStyles } from "@styles/common-styles";
import { WorkspaceEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";

/** The available default platform items */
const DEFAULT_PLATFORM_ITEMS: Record<
  OpenProjectTarget,
  ValueLabelItem<OpenProjectTarget>
> = {
  [OpenProjectTarget.sphereViewer]: {
    value: OpenProjectTarget.sphereViewer,
    label: "Sphere XG",
  },
  [OpenProjectTarget.webEditor]: {
    value: OpenProjectTarget.webEditor,
    label: "HoloBuilder",
  },
};

/** Renders the company setting that allows EAs to select the default platform to open projects */
export function DefaultPlatformSelector(): JSX.Element {
  const { isScreenXsAndSmall } = useMediaQueryList();
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const { trackEvent } = useTrackEvent();
  const defaultOpenProjectTarget = useAppSelector(
    defaultOpenProjectTargetSelector
  );
  const { isUpdatingCompanyCommunicationSettings } = useAppSelector(
    fetchingSdbCompanyFlagsSelector
  );
  const communicationSettings = useAppSelector(
    companyCommunicationSettingsSelector
  );

  function handlePlatformChange(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    const platform = event.target.value as OpenProjectTarget;

    trackEvent({
      name: WorkspaceEvents.updateSettings,
      props: {
        defaultPlatform: DEFAULT_PLATFORM_ITEMS[platform].label,
      },
    });

    dispatch(
      updateCompanyCommunicationSettings({
        coreApiClient,
        payload: {
          // eslint-disable-next-line @typescript-eslint/naming-convention -- name defined in backend
          sphereViewerIsDefault: platform === OpenProjectTarget.sphereViewer,
        },
      })
    );
  }

  return (
    <RadioGroup
      value={defaultOpenProjectTarget}
      onChange={handlePlatformChange}
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
      }}
    >
      <Grid container>
        {Object.values(DEFAULT_PLATFORM_ITEMS).map((item, index) => (
          <Grid
            item
            key={item.value}
            sx={{
              // Show 2 rows for smaller screens
              width: isScreenXsAndSmall ? "100%" : "50%",
              display: "flex",
              alignItems: "center",
              // Don't show the left border if its the 1st item or if 2 rows are shown
              borderLeft:
                index === 0 || isScreenXsAndSmall
                  ? "none"
                  : CommonStyles.Borders.gray200Divider,
            }}
          >
            <SphereRadioButton
              item={item}
              // Disable the radio buttons if:
              // - The settings is being updated
              // - Fetching the setting failed and is not available in the store
              isDisabled={
                isUpdatingCompanyCommunicationSettings || !communicationSettings
              }
              formControlLabelSx={{
                marginLeft: isScreenXsAndSmall ? "0px" : "20px",
              }}
            />
          </Grid>
        ))}
      </Grid>
    </RadioGroup>
  );
}
