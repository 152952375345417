import { MarkupIntegrationLabel } from "@custom-types/project-markups-types";
import {
  isIElementMarkupBim360,
  isIElementMarkupProcoreObservation,
  isIElementMarkupProcoreRfi,
} from "@custom-types/i-elements-type-guards";
import { ExternalMarkupIElement } from "@faro-lotv/ielement-types";

/** Returns the name of the external integration service of the markup, or undefined if there is no integration */
export function getIntegrationName(
  externalMarkup?: ExternalMarkupIElement
): string | undefined {
  if (!externalMarkup) {
    return;
  }

  if (isIElementMarkupProcoreRfi(externalMarkup)) {
    return MarkupIntegrationLabel.procoreRfi;
  }

  if (isIElementMarkupProcoreObservation(externalMarkup)) {
    return MarkupIntegrationLabel.procoreObservation;
  }

  if (isIElementMarkupBim360(externalMarkup)) {
    return MarkupIntegrationLabel.bim360;
  }
}
