import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { RequiredRoleBase } from "@utils/access-control/access-control-utils";

/** A single description for roles on the group level. */
interface RequiredRoleGroupLevel extends RequiredRoleBase {
  /**
   * Array filled with group roles where the user,
   * should have one of them in order to have permission.
   * If the array is empty, it means that having a group role is irrelevant,
   * and to have permission you need either a group or another custom
   * role like projectManager.
   */
  groupRoles: SphereDashboardAPITypes.IAssignmentGroupRole[];
}

/**
 * Object that determines all different permission rules for users to get
 * access on the group level.
 */
export type RequiredRolesGroupLevel<RoleNameT extends string> = {
  [key in RoleNameT]: RequiredRoleGroupLevel;
};

export interface HasUserValidRoleGroupLevelProps<RoleNameT extends string> {
  /**
   * The internal name used in the UI to determine certain permissions,
   * e.g. "inviteUsersToGroup" is used to determine whether the user can invite a member in a group
   */
  roleName: RoleNameT;

  /**
   * User to get its group access level.
   */
  currentUser: SphereDashboardAPITypes.ICompanyMemberBase | null;

  /**
   * The group to get the user's permission level.
   */
  selectedGroup: SphereDashboardAPITypes.IGroupDetails | null;

  /**
   * You can optionally change the default required roles definition,
   * ! This is only used for unit tests,
   * therefore only use the default value.
   */
  defaultRequiredRolesGroupLevel?: RequiredRolesGroupLevel<RoleNameT>;
}

/** Names for all permissions or roles within the group level. */
export enum RequiredRoleGroupLevelName {
  /** Whether the user can edit group details like name, sqftAssigned and imageUrl */
  canEditGroupDetails = "canEditGroupDetails",

  /** Whether the user can invite a member in a group */
  canInviteUsersToGroup = "canInviteUsersToGroup",

  /** Whether the user has permission to edit member */
  canEditMember = "canEditMember",

  /** Whether the user has permission to delete members from group */
  canDeleteMemberFromGroup = "canDeleteMemberFromGroup",
}
