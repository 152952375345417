import { BaseMemberProps } from "@custom-types/member-types";
import { PartialNull } from "@custom-types/types";
import { APITypes } from "@stellar/api-logic";

interface HasPermissionProps extends PartialNull<BaseMemberProps> {
  /** The name of the permission to check */
  permissionName: APITypes.UserPermissionName;
}

/**
 * Checks if the member has the provided permission.
 *
 * @param member The member to check the permission for.
 * @param permissionName The name of the permission to check.
 * @returns True if the member has the permission, false otherwise.
 */
export function hasPermission({
  member,
  permissionName,
}: HasPermissionProps): boolean {
  if (!member || !("permissions" in member)) {
    return false;
  }
  return (
    member.permissions?.some(
      (permission) => permission.name === permissionName && permission.allowed
    ) ?? false
  );
}

/**
 * Checks if the member has the permission to create a group.
 * Does not check the roles of the member, it only checks for the specific permission.
 * Therefore this only applies to group managers.
 *
 * @param member The member to check the permission for.
 * @returns True if the member has the permission, false otherwise.
 */
export function hasCreateGroupsPermission({
  member,
}: PartialNull<BaseMemberProps>): boolean {
  return hasPermission({
    member,
    permissionName: APITypes.UserPermissionName.CREATE_GROUP,
  });
}
