// eslint-disable-next-line no-restricted-imports -- The only place needed to define FaroSimpleTextField
import { TextField, TextFieldProps } from "@mui/material";
import { DEFAULT_INPUT_FONT_SIZE } from "@styles/common-styles";
import { BlockedClasses } from "@utils/localization";

/**
 * Simple text field that works the same as MUI TextField but adds some styling,
 * to match FARO's design guidelines.
 */
export function FaroSimpleTextField(props: TextFieldProps): JSX.Element {
  return (
    <TextField
      autoFocus
      className={BlockedClasses.noLocalize}
      {...props}
      InputProps={{
        ...props.InputProps,
        sx: {
          ...props.InputProps?.sx,
          fontSize: DEFAULT_INPUT_FONT_SIZE,
        },
      }}
    />
  );
}
