import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { BaseMemberProps, MemberTypes } from "@custom-types/member-types";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityAdapter,
} from "@reduxjs/toolkit";
import { APITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { CoreApiWithCompanyIdProps } from "@store/store-types";
import {
  FetchCompanyMemberDetailsProps,
  MembersState,
  RemoveMemberProps,
  UpdateMemberRoleProps,
  UpdateMemberRoleResult,
} from "@src/store/members/members-slice-helper";
import { RootState } from "@store/store-helper";
import { inviteMembersToSnapshot } from "@store/snapshots/snapshots-slice";

/** Gets the ID for a member to be used in the entities dictionary to identify each member  */
function getMemberId({ member }: BaseMemberProps): string {
  return member.identity ?? member.email;
}

/** Creates an entity adapter to store a map with all the members that belong to the company. */
export const membersAdapter: EntityAdapter<MemberTypes> = createEntityAdapter({
  // TODO: The backend should always return an id for a user.
  selectId: (member) => getMemberId({ member }),
});

const initialState: MembersState = {
  ...membersAdapter.getInitialState(),
  fetched: {
    hasFetchedWorkspaceMembers: false,
  },
  fetching: {
    isFetchingCompanyMembers: false,
    isFetchingCompanyMemberDetails: false,
    isRemovingCompanyMember: false,
  },
};

/** Fetches company members from the backend so they can be put into the store */
export const fetchCompanyMembers = createAsyncThunk<
  SphereDashboardAPITypes.ICompanyMemberBase[],
  CoreApiWithCompanyIdProps,
  { state: RootState }
>(
  "members/fetchCompanyMembers",
  async ({ coreApiClient, companyId }) => {
    if (!companyId) {
      throw new Error("No companyId was given to fetchCompanyMembers");
    }
    try {
      const data = await coreApiClient.V3.SDB.getMembersInCompany(companyId);
      return data;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  },
  {
    condition: (arg, api) => {
      // Skip fetching if members have already been fetched
      return !api.getState().members.fetched.hasFetchedWorkspaceMembers;
    },
  }
);

/** Fetches the details of a company member so it can be put into the members store */
export const fetchCompanyMemberDetails = createAsyncThunk<
  SphereDashboardAPITypes.ICompanyMemberDetails,
  FetchCompanyMemberDetailsProps
>(
  "members/fetchCompanyMemberDetails",
  async ({ coreApiClient, companyId, identity }) => {
    try {
      const data = await coreApiClient.V3.SDB.getMemberDetailsInCompany(
        companyId,
        identity
      );
      return data;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/** Remove a member from the company using the backend and then removes it from the store as well */
export const removeMemberFromCompany = createAsyncThunk<
  MemberTypes,
  RemoveMemberProps
>(
  "members/removeCompanyMember",
  async ({ coreApiClient, companyId, member }) => {
    if (!companyId) {
      throw new Error(
        "No companyId was given to remove member from workspace!"
      );
    }

    if (!member?.email) {
      throw new Error("No email was given to remove member from workspace!");
    }

    try {
      await coreApiClient.V3.SDB.removeMemberFromCompany(
        companyId,
        member.email
      );
      return member;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/**
 * Update a member role in the workspace using the backend,
 * and then update it in the store as well
 */
export const updateMemberRoleInWorkspace = createAsyncThunk<
  UpdateMemberRoleResult,
  UpdateMemberRoleProps
>(
  "members/updateMemberRoleInWorkspace",
  async ({ coreApiClient, companyId, role, identity }) => {
    if (!companyId) {
      throw new Error("No companyId was given to update member role!");
    }

    const memberData = {
      role: role,
      identity: identity,
    };

    try {
      const result = await coreApiClient.V3.SDB.updateCompanyMember(
        companyId,
        identity,
        memberData
      );

      return {
        role: result.role,
        identity: result.identity,
      };
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

interface SetMemberPermissionsInCompanyPayload
  extends SphereDashboardAPITypes.ISetMemberPermissionsInCompanyPayload,
    CoreApiWithCompanyIdProps {
  userIdentity: APITypes.UserIdentity;
}

/**
 * Set the permissions of a member in the company using the backend.
 */
export const setMemberPermissionsInCompany = createAsyncThunk<
  SphereDashboardAPITypes.SetMemberPermissionsInCompanyResponse,
  SetMemberPermissionsInCompanyPayload
>(
  "members/setMemberPermissionsInCompany",
  async ({ coreApiClient, companyId, userIdentity, permissions }) => {
    try {
      if (!companyId) {
        throw new Error("No companyId was given to update member permissions");
      }

      const result = await coreApiClient.V3.SDB.setMemberPermissionsInCompany(
        companyId,
        userIdentity,
        { permissions }
      );
      return result;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/**
 * Update a member role in workspace store.
 */
function updateMemberRoleInMemberStore(
  state: MembersState,
  result: UpdateMemberRoleResult
): void {
  const member = membersAdapter
    .getSelectors()
    .selectById(state, result.identity);

  // If the member is in the store update the member role
  if (member) {
    membersAdapter.updateOne(state, {
      id: getMemberId({ member }),
      changes: {
        role: result.role,
      },
    });
  }
}

/**
 * Update a member in workspace store.
 */
function updateMemberInMemberStore(
  state: MembersState,
  memberId: APITypes.UserId,
  changes: Partial<MemberTypes>
): void {
  const member = membersAdapter.getSelectors().selectById(state, memberId);

  // If the member is in the store update the member role
  if (member) {
    membersAdapter.updateOne(state, {
      id: getMemberId({ member }),
      changes,
    });
  }
}

/**
 * Slice to access state of loaded company members
 */
const membersSlice = createSlice({
  name: "members",
  initialState,
  reducers: {
    /**
     * Accepts a single member entity and adds or replaces it.
     *
     * @param state store state
     * @param entity Project to be set or added to the store.
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    setOne: membersAdapter.setOne,
    /**
     * Accepts an array of member entities, and adds or replaces them.
     *
     * @param state store state
     * @param entity Projects to be set or added to the store.
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    setMany: membersAdapter.setMany,
    /**
     * Removes all member entities from the store.
     *
     * @param state store state
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    removeAll: membersAdapter.removeAll,
    /**
     * Accepts an array of member IDs, and removes each member entity with those IDs if they exist.
     *
     * @param state store state
     * @param entity Project Ids to be removed from the store.
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    removeMany: membersAdapter.removeMany,
    /**
     * Accepts a single member IDs, and removes the member entity with that ID if it exists.
     *
     * @param state store state
     * @param entity Project Id to be removed from the store.
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    removeOne: membersAdapter.removeOne,
    resetMembersState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCompanyMembers.pending, (state, action) => {
        state.fetching.isFetchingCompanyMembers = true;
      })
      .addCase(fetchCompanyMembers.fulfilled, (state, action) => {
        membersAdapter.upsertMany(state, action.payload);

        state.fetching.isFetchingCompanyMembers = false;
        state.fetched.hasFetchedWorkspaceMembers = true;
      })
      .addCase(fetchCompanyMembers.rejected, (state, action) => {
        state.fetching.isFetchingCompanyMembers = false;
      })

      .addCase(fetchCompanyMemberDetails.pending, (state, action) => {
        state.fetching.isFetchingCompanyMemberDetails = true;
      })
      .addCase(fetchCompanyMemberDetails.fulfilled, (state, action) => {
        membersAdapter.upsertOne(state, action.payload);
        state.fetching.isFetchingCompanyMemberDetails = false;
      })
      .addCase(fetchCompanyMemberDetails.rejected, (state, action) => {
        state.fetching.isFetchingCompanyMemberDetails = false;
      })

      .addCase(removeMemberFromCompany.pending, (state, action) => {
        state.fetching.isRemovingCompanyMember = true;
      })
      .addCase(removeMemberFromCompany.fulfilled, (state, action) => {
        state.fetching.isRemovingCompanyMember = false;
        membersAdapter.removeOne(
          state,
          getMemberId({ member: action.payload })
        );
      })
      .addCase(removeMemberFromCompany.rejected, (state, action) => {
        state.fetching.isRemovingCompanyMember = false;
      })

      .addCase(updateMemberRoleInWorkspace.pending, (state, action) => {
        state.fetching.isFetchingCompanyMembers = true;
      })
      .addCase(updateMemberRoleInWorkspace.fulfilled, (state, action) => {
        updateMemberRoleInMemberStore(state, action.payload);
        state.fetching.isFetchingCompanyMembers = false;
      })
      .addCase(updateMemberRoleInWorkspace.rejected, (state, action) => {
        state.fetching.isFetchingCompanyMembers = false;
      })

      .addCase(setMemberPermissionsInCompany.fulfilled, (state, action) => {
        updateMemberInMemberStore(
          state,
          action.payload.identity,
          action.payload
        );
      })
      .addCase(setMemberPermissionsInCompany.rejected, (state, action) => {
        throw new Error(getErrorDisplayMarkup(action.error));
      })

      // Listen to the inviteMembersToSnapshot action to reset the hasFetchedWorkspaceMembers flag
      // This is to make sure that the members are fetched again after inviting members to a snapshot
      // because the members list might have changed
      .addCase(inviteMembersToSnapshot.fulfilled, (state, action) => {
        state.fetched.hasFetchedWorkspaceMembers = false;
      });
  },
});

export const {
  setOne,
  setMany,
  removeAll,
  removeMany,
  removeOne,
  resetMembersState,
} = membersSlice.actions;

export const membersReducer = membersSlice.reducer;
