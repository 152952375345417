import { Box, Tab, Tabs } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { SidepanelTabProps } from "@components/sidepanel/sidepanel-types";

interface Props {
  /**
   * Updates the selected tab when a new tab is clicked.
   *
   * @param event - The synthetic event triggered by the tab click.
   * @param newValue - The value (label) of the newly selected tab.
   */
  handleChange: (event: React.SyntheticEvent, newValue: string) => void;

  /** Data needed for each tab */
  tabs: SidepanelTabProps[];

  /** The tab that is selected */
  selectedTab?: string;
}

/**
 * Component that renders tabs with dynamic content based on the selected tab.
 *
 * This component renders tabs based on the provided `tabs` array, allowing users
 * to switch between different tab content. It utilizes the `handleChange` function
 * to update the selected tab when a tab is clicked.
 *
 * @returns JSX.Element
 */
export function SidePanelTabs({
  tabs,
  selectedTab,
  handleChange,
}: Props): JSX.Element {
  return (
    <Box sx={{ mt: "15px" }}>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        aria-label="Choose a tab"
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: sphereColors.blue500,
          },
        }}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.label}
            value={tab.label}
            disabled={tab.isDisabled ?? false}
            label={tab.label}
            sx={{
              padding: 0,
              fontSize: "14px",
              letterSpacing: "-0.2px",
              marginRight: "20px",
              textTransform: "none",
              minWidth: "30px",
              fontWeight: selectedTab === tab.label ? "bold" : "lighter",
              "&.MuiTab-root": {
                color:
                  selectedTab === tab.label
                    ? sphereColors.blue500
                    : sphereColors.gray800,
              },
              "&:hover": {
                color: sphereColors.blue500,
                borderBottom: `1px solid ${sphereColors.blue500}`,
                paddingTop: "1px",
              },
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
}
