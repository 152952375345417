import { Box } from "@mui/material";
import { ProgressOverviewSectionItem } from "@components/progress-overview/progress-overview-section-item";
import { useUploadTasks } from "@hooks/upload-tasks/use-upload-tasks";

/** Renders the progress section inside the progress overview menu */
export function ProgressOverviewSection(): JSX.Element {
  const { failedTasks, inProgressTasks } = useUploadTasks();

  return (
    <Box sx={{ overflow: "auto" }}>
      {!!failedTasks.length && (
        <ProgressOverviewSectionItem tasks={failedTasks} label="Failed" />
      )}

      {!!inProgressTasks.length && (
        <ProgressOverviewSectionItem
          tasks={inProgressTasks}
          label="In progress"
        />
      )}
    </Box>
  );
}
