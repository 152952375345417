import { SphereTooltip } from "@components/common/sphere-tooltip";
import { UserAvatarWithMenu } from "@components/user-more-info-menu/user-avatar-with-menu";
import { MemberTypes } from "@custom-types/member-types";
import { UserId } from "@faro-lotv/ielement-types";
import { Box } from "@mui/material";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { withEllipsis } from "@styles/common-styles";
import { getMemberById, getMemberNameById } from "@utils/member-utils";
import { useMemo } from "react";

interface Props {
  /** ID of the member */
  memberId?: UserId;

  /** List of company members */
  companyMembers: MemberTypes[];

  /** List of project members */
  projectMembers: SphereDashboardAPITypes.IProjectMemberBase[];
}

/** Renders a faro table cell with the member avatar and name */
export function FaroTableMemberCell({
  memberId,
  companyMembers,
  projectMembers,
}: Props): JSX.Element {
  const member = useMemo(() => {
    return getMemberById({
      memberId,
      companyMembers,
      projectMembers,
    });
  }, [companyMembers, memberId, projectMembers]);

  const name = useMemo(() => {
    return (
      getMemberNameById({
        memberId,
        companyMembers,
        projectMembers,
      }) ?? "-"
    );
  }, [companyMembers, memberId, projectMembers]);

  return (
    <Box
      component="div"
      sx={{
        ...withEllipsis,
        display: "flex",
        alignItems: "center",
      }}
    >
      <UserAvatarWithMenu member={member} />
      <SphereTooltip
        component="var"
        title={<var>{name}</var>}
        boxProps={{
          sx: {
            ...withEllipsis,
            ml: "12px",
          },
        }}
      >
        {name}
      </SphereTooltip>
    </Box>
  );
}
