import { SanitizeUtils } from "@stellar/web-core";

interface Props {
  /** The markup that needs to be sanitized and add to an html */
  markup: string;
}

/**
 * This component wrap the provided markup into a dangerouslySetInnerHTML.
 * A use case of it is when an error is received from backend. They might already be in the shape of html
 * !: It should only be used if there is no other options. *
 */
export function SanitizedHtmlTag({ markup }: Props): JSX.Element {
  return (
    <div
      // eslint-disable-next-line react/no-danger -- the only place that we can use it
      dangerouslySetInnerHTML={{
        // eslint-disable-next-line @typescript-eslint/naming-convention -- from npm package
        __html: SanitizeUtils.sanitizeString(markup),
      }}
    />
  );
}
