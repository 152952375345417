import { EmptyPage } from "@src/components/common/empty-page/empty-page";
import BrowseDuoColorIcon from "@assets/icons/browse-duo-color.svg?react";
import MembersDuoColorIcon from "@assets/icons/members-duo-color.svg?react";
import { InviteMemberToCompany } from "@src/pages/members/invite-member-to-company";
import { useAppSelector } from "@store/store-helper";
import { searchSelector } from "@store/ui/ui-selector";

/** Empty page content for members page */
export function MembersEmptyPage(): JSX.Element {
  const { searchText } = useAppSelector(searchSelector);

  if (searchText) {
    return (
      <EmptyPage
        title="No results found"
        subtitle="There are no members that match this search."
        icon={BrowseDuoColorIcon}
      />
    );
  } else {
    return (
      <EmptyPage
        title="You don't have any members yet"
        // eslint-disable-next-line max-len
        subtitle="All of your workspace members will be found here. You can add members to your workspace by inviting them."
        icon={MembersDuoColorIcon}
        button={<InviteMemberToCompany buttonVariant="filled" />}
      />
    );
  }
}
