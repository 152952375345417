import { IElementAreaSection } from "@custom-types/i-elements-types";
import { IElement, isIElementAreaSection } from "@faro-lotv/ielement-types";

interface SelectAncestorProps<T extends IElement> {
  /** Array of elements to look for the ancestor */
  elements: IElement[];

  /** Element that we want the ancestor of */
  element: IElement;

  /** Function to determine the desired ancestor */
  predicate: (el: IElement) => el is T;
}

/**
 * @returns The first ancestor that matches the given predicate for a given array of elements
 */
export function getAncestor<T extends IElement>({
  elements,
  element,
  predicate,
}: SelectAncestorProps<T>): IElement | undefined {
  // Early return the element if it matches the predicate.
  if (predicate(element)) {
    return element;
  }

  let parent: IElement | undefined = element;
  while (parent?.parentId) {
    parent = elements.find(
      // eslint-disable-next-line no-loop-func
      (el) => el.id === parent?.parentId
    );

    if (parent && predicate(parent)) {
      return parent;
    }
  }
}

/** Gets all the area section elements from an array of elements */
export function getAreaSections(elements: IElement[]): IElementAreaSection[] {
  return elements.filter(isIElementAreaSection);
}
