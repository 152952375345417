import { runtimeConfig } from "@src/runtime-config";
// eslint-disable-next-line no-restricted-imports -- The only place needed to initialize Analytics
import { Analytics, RuntimeUtils } from "@stellar/web-core";
import { selectedSdbCompanyIdSelector } from "@store/sdb-company/sdb-company-selector";
import { useAppSelector } from "@store/store-helper";
import { currentUserSelector } from "@store/user/user-selector";
import {
  HolobuilderCookieManager,
  AMPLITUDE_COOKIE,
} from "@utils/cookie-manager/cookie-manager-utils";
import { shouldPreventTracking } from "@utils/track-event/track-event-list";
import { getBreakpointName } from "@utils/ui-utils";
import { PropsWithChildren, useEffect } from "react";

/**
 * Initializes Analytics module for tracking. Can be used like a context provider and should be used
 * where user and company are already available (i.e. inside the SdbCompanyLoader).
 * Currently we are using Amplitude analytics and this component initialize it.
 */
export function TrackEventInitializerLoader({
  children,
}: PropsWithChildren): JSX.Element {
  const currentUser = useAppSelector(currentUserSelector);
  const selectedSdbCompanyId = useAppSelector(selectedSdbCompanyIdSelector);

  // Initialize amplitude when user and company is defined
  useEffect(() => {
    if (!currentUser || !selectedSdbCompanyId || shouldPreventTracking) {
      return;
    }

    Analytics.init({
      props: {
        userId: currentUser.id,
        defaultProps: {
          companyId: selectedSdbCompanyId,
          appVersion: runtimeConfig.appVersion,
          // For events we only care which breakpoint the user had when the event was triggered
          // For the exact screen size and orientation we track only on page load
          windowWidthBreakpoint: getBreakpointName(window.innerWidth),
        },
      },
      isConsentGiven:
        HolobuilderCookieManager?.isCookieAccepted(AMPLITUDE_COOKIE),
      // We want to prevent tracking, if we are an internal user and are currently on production
      // https://faro01.atlassian.net/browse/HBPROD-220
      shouldPreventTracking:
        RuntimeUtils.isProductionEnvironment(runtimeConfig.appEnv) &&
        Analytics.shouldUserBeExcludedFromTracking(currentUser.email),
    });
  }, [currentUser, selectedSdbCompanyId]);
  // eslint-disable-next-line react/jsx-no-useless-fragment -- Needed to return JSX.Element
  return <>{children}</>;
}
