import { IElementAreaSection } from "@custom-types/i-elements-types";
import {
  IElementSectionDataSession,
  PointCloud,
  SourcePointCloudInfo,
} from "@custom-types/point-cloud-types";
import {
  IElementPointCloudStream,
  IElementPointCloudStreamWebShare,
  IElementTypeHint,
  isIElemLink,
  isIElementAreaSection,
  isIElementPointCloudStream,
  isIElementPointCloudStreamWebShare,
  isIElementSectionDataSession,
  isIElementSectionGeoslam,
  isIElementSectionWithTypeHint,
} from "@faro-lotv/ielement-types";
import {
  selectAllIElementsOfType,
  selectAncestor,
  selectChildDepthFirst,
  selectIElement,
} from "@faro-lotv/project-source";
import { selectRootExternalId } from "@store/i-elements/i-elements-selectors";
import { RootState } from "@store/store-helper";
import { getSphereViewerUrl } from "@utils/project-utils";
import lodash from "lodash";

/**
 * Function that generates PointCloud entities from the ielements available in the app store.
 *
 * If first selects all point cloud elements: ielements of type pointCloudStream or pointCloudStreamWebShare.
 * Then it selects all Section.DataSession elements where a point cloud element is a child.
 * Finally it constructs the PointCloud entities.
 *
 * @param state Store state
 */
export function getPointClouds(state: RootState): PointCloud[] {
  const sectionDataSessions = getPointCloudDataSessions(state);

  const pointClouds: PointCloud[] = sectionDataSessions.map(
    (sectionDataSession) => {
      return {
        ...sectionDataSession,
        sectionArea: getSectionArea(state, sectionDataSession),
        sphereViewerUrl: getPointCloudSphereViewerUrl(
          state,
          sectionDataSession
        ),
        sourcePointCloudsInfo: getSourcePointCloudsInfo(
          state,
          sectionDataSession
        ),
      };
    }
  );

  return pointClouds;
}

/** Gets all point cloud elements: ielements of type pointCloudStream or pointCloudStreamWebShare */
function getPointCloudElements(
  state: RootState
): (IElementPointCloudStream | IElementPointCloudStreamWebShare)[] {
  const pointCloudStreamElements = selectAllIElementsOfType(
    isIElementPointCloudStream
  )(state);

  const pointCloudStreamWebShareElements = selectAllIElementsOfType(
    isIElementPointCloudStreamWebShare
  )(state);

  return [...pointCloudStreamElements, ...pointCloudStreamWebShareElements];
}

/** Gets all Section.DataSession elements that have a point cloud element as child */
function getPointCloudDataSessions(
  state: RootState
): IElementSectionDataSession[] {
  const pointClouds = getPointCloudElements(state);
  const dataSessions: IElementSectionDataSession[] = [];

  pointClouds.forEach((pointCloud) => {
    const dataSession = selectAncestor(
      pointCloud,
      isIElementSectionDataSession
    )(state);
    if (dataSession && !dataSessions.includes(dataSession)) {
      dataSessions.push(dataSession);
    }
  });

  return dataSessions;
}

/** Gets the area section element associated to the data session */
function getSectionArea(
  state: RootState,
  sectionDataSession: IElementSectionDataSession
): IElementAreaSection | undefined {
  return selectAncestor(sectionDataSession, isIElementAreaSection)(state);
}

/** Gets the Sphere Viewer deep link of the point cloud */
function getPointCloudSphereViewerUrl(
  state: RootState,
  sectionDataSession: IElementSectionDataSession
): string | undefined {
  const projectId = selectRootExternalId(state);
  const lookAtId = sectionDataSession.id;

  if (!projectId) {
    return;
  }

  return getSphereViewerUrl({
    projectId,
    lookAtId,
  }).href;
}

/** Returns true iff the point cloud is a merged point cloud: a point cloud generated from other source point clouds */
export function getIsMergedPointCloud(
  sectionDataSession: IElementSectionDataSession
): boolean {
  return lodash.some(sectionDataSession.labels, {
    labelType: "merged-point-cloud",
  });
}

/**
 * Gets the IDs of the point clouds used to generate the current point cloud.
 * For a merged point cloud it should return at least one ID, otherwise it should return an empty array.
 *
 * @param state Store state
 * @param sectionDataSession Section.DataSession ielement of the current point cloud
 * @returns Array of source point cloud IDs
 */
export function getSourcePointCloudIds(
  state: RootState,
  sectionDataSession: IElementSectionDataSession
): string[] {
  // Data sets supported for merged are "GeoSlam" and "PCloudUpload"
  const dataSetGeoSlam = selectChildDepthFirst(
    sectionDataSession,
    isIElementSectionGeoslam
  )(state);
  const dataSetPCloudUpload = selectChildDepthFirst(
    sectionDataSession,
    (element) =>
      isIElementSectionWithTypeHint(
        element,
        IElementTypeHint.dataSetPCloudUpload
      )
  )(state);

  const dataSet = dataSetGeoSlam || dataSetPCloudUpload;

  if (!dataSet) {
    return [];
  }

  // ID of the data set element of the current PC
  const currentPointCloudId = dataSet.id;

  const sourcePointCloudIds: string[] = [];

  // iElemLink is a child element of a PC that contains the ID of a merged PC generated from the PC
  const iElemLinks = selectAllIElementsOfType(isIElemLink)(state);

  iElemLinks.forEach((iElemLink) => {
    // ID of the merged PC
    const mergedPointCloudId = iElemLink.target_Id;

    // ID of the source PC
    const sourcePointCloudId = iElemLink.parentId;

    if (
      mergedPointCloudId === currentPointCloudId &&
      sourcePointCloudId &&
      !sourcePointCloudIds.includes(sourcePointCloudId)
    ) {
      sourcePointCloudIds.push(sourcePointCloudId);
    }
  });

  return sourcePointCloudIds;
}

/**
 * Gets the information of the point clouds used to generate the current point cloud.
 * For a merged point cloud it returns an array of info objects. If it's not a merged point cloud it returns undefined.
 *
 * @param state Store state
 * @param sectionDataSession Section.DataSession ielement of the current point cloud
 * @returns Array of source point cloud info objects or undefined
 */
export function getSourcePointCloudsInfo(
  state: RootState,
  sectionDataSession: IElementSectionDataSession
): SourcePointCloudInfo[] | undefined {
  const isMergedPointCloud = getIsMergedPointCloud(sectionDataSession);

  if (!isMergedPointCloud) {
    return;
  }

  const sourcePointCloudIds = getSourcePointCloudIds(state, sectionDataSession);

  const sourcePointCloudsInfo: SourcePointCloudInfo[] = sourcePointCloudIds.map(
    (sourcePointCloudId) => {
      const element = selectIElement(sourcePointCloudId)(state);
      return {
        id: sourcePointCloudId,
        name: element?.name ?? "",
      };
    }
  );

  return sourcePointCloudsInfo;
}
