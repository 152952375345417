import { IElementMarkupWithTypeHint } from "@custom-types/i-elements-types";
import { Markup } from "@custom-types/project-markups-types";
import { isMarkupSceneObject } from "@custom-types/slide-nodes-type-guards";
import { MarkupSceneObject } from "@custom-types/slide-nodes-types";
import { IElementModel3D } from "@faro-lotv/ielement-types";
import { CoreAPITypes } from "@stellar/api-logic";
import { isDateOverdue } from "@utils/date-utils";

/**
 * @returns An array of Markups sorted like this:
 * - Markups with most recently modified should show first
 *
 * @param markups Array of Markup entities.
 */
export function sortMarkups(markups: Markup[]): Markup[] {
  return markups.sort((a, b) => {
    const aModifiedAt = new Date(a.modifiedAt).getTime();
    const bModifiedAt = new Date(b.modifiedAt).getTime();

    return bModifiedAt - aModifiedAt;
  });
}

interface SlideNodeAndSceneObject {
  /** Slide node associated to the markup */
  slideNode: CoreAPITypes.IArSlideJson;

  /** Scene object associated to the markup */
  sceneObject: MarkupSceneObject;
}

/** Finds the slideNode and scene object associated to the markup */
export function findSlideNodeAndSceneObject(
  slideNodes: CoreAPITypes.IArSlideJson[],
  markup: IElementMarkupWithTypeHint,
  model3d?: IElementModel3D
): SlideNodeAndSceneObject | undefined {
  for (const slideNode of slideNodes) {
    const sceneObject = findSceneObject(slideNode, markup, model3d);
    if (sceneObject) {
      return { slideNode, sceneObject };
    }

    const nestedSlideNodes = slideNode.slideNodes;
    if (nestedSlideNodes) {
      const result = findSlideNodeAndSceneObject(
        nestedSlideNodes,
        markup,
        model3d
      );
      if (result) {
        return result;
      }
    }
  }
}

/** Finds the scene object associated to the markup */
function findSceneObject(
  slideNode: CoreAPITypes.IArSlideJson,
  markup: IElementMarkupWithTypeHint,
  model3d?: IElementModel3D
): MarkupSceneObject | undefined {
  let foundSceneObject: MarkupSceneObject | undefined = undefined;

  const sceneObjects = slideNode.nodes;
  const markupSceneObjects = sceneObjects.filter(isMarkupSceneObject);

  // 1st attempt to find scene object by the md5Hash property
  foundSceneObject = findSceneObjectByMd5Hash(markupSceneObjects, model3d);
  if (foundSceneObject) {
    return foundSceneObject;
  }

  // As last resort attempt to find scene object by name + description
  foundSceneObject = findSceneObjectByNameAndDescription(
    markupSceneObjects,
    markup
  );

  return foundSceneObject;
}

/** Finds an scene object by the "md5Hash" property */
function findSceneObjectByMd5Hash(
  markupSceneObjects: MarkupSceneObject[],
  model3d?: IElementModel3D
): MarkupSceneObject | undefined {
  // Get the "md5Hash" property associated to the markup. This property, if available, has the same value
  // as the "md5hash" property of the scene object associated to the project.
  const md5Hash = model3d?.md5Hash;

  // Early exit if "md5Hash" value is not available
  if (!md5Hash) {
    return;
  }

  return markupSceneObjects.find(
    (sceneObject) => sceneObject.md5hash === md5Hash
  );
}

/** Finds an scene object by the name and description properties */
function findSceneObjectByNameAndDescription(
  markupSceneObjects: MarkupSceneObject[],
  markup: IElementMarkupWithTypeHint
): MarkupSceneObject | undefined {
  const name = markup.name.trim();
  const description = (markup.descr ?? "").trim();
  const nameAndDescription = `${name}${description}`;

  return markupSceneObjects.find((sceneObject) => {
    const sceneObjectName = sceneObject.displayName.trim();
    const sceneObjectDescription = (
      sceneObject.markupData.description ?? ""
    ).trim();
    const sceneObjectNameAndDescription = `${sceneObjectName}${sceneObjectDescription}`;
    return nameAndDescription === sceneObjectNameAndDescription;
  });
}

/** Returns true iff the markup status value is "To Do" */
export function isMarkupToDo(markup: Markup): boolean {
  return markup.status?.value === CoreAPITypes.EIssueStatus.open;
}

/** Returns true iff the markup status value is "In Progress" */
export function isMarkupInProgress(markup: Markup): boolean {
  return markup.status?.value === CoreAPITypes.EIssueStatus.inProgress;
}

/** Returns true iff the markup status value is "In Review" */
export function isMarkupInReview(markup: Markup): boolean {
  return markup.status?.value === CoreAPITypes.EIssueStatus.toReview;
}

/** Returns true iff the markup status value is "Resolved" */
export function isMarkupResolved(markup: Markup): boolean {
  return markup.status?.value === CoreAPITypes.EIssueStatus.resolved;
}

/** Returns true iff the markup status value is not defined */
export function isMarkupUnclassified(markup: Markup): boolean {
  return !markup.status?.value;
}

/** Returns true iff the markup due date value is defined and is overdue */
export function isMarkupOverdue(markup: Markup): boolean {
  const dueDate = markup.dueDate?.value;

  // Markups without due date or with "Resolved" status can't be overdue
  if (!dueDate || isMarkupResolved(markup)) {
    return false;
  }

  return isDateOverdue(dueDate);
}

/** Returns true iff the markup does not have an external integration (Bim360 or Procore) */
export function isInternalMarkup(markup: Markup): boolean {
  return markup.externalMarkup === undefined;
}

interface GetUniqueMarkupPropertiesProps<V extends keyof Markup> {
  /** Array of Markups */
  markups: Markup[];

  /** Property name to extract the unique values */
  uniqueIdKey: V;
}

export function getUniqueMarkupProperties<V extends keyof Markup>({
  markups,
  uniqueIdKey,
}: GetUniqueMarkupPropertiesProps<V>): NonNullable<Markup[V]>[] {
  const allProps = markups
    .flatMap((markup) => markup[uniqueIdKey])
    .filter((prop): prop is Markup[V] => {
      return prop !== null && prop !== undefined;
    });

  const uniqueIds = [
    ...new Set(
      allProps.map((prop) => {
        if (prop && typeof prop === "object" && "id" in prop) {
          return prop.id;
        }
        return undefined;
      })
    ),
  ];

  // All the scenes that are unique
  return uniqueIds.reduce<NonNullable<Markup[V]>[]>((uniqueProps, uniqueId) => {
    const foundProp = allProps.find((prop) => {
      if (prop && typeof prop === "object" && "id" in prop) {
        return prop.id === uniqueId;
      }
      return false;
    });

    if (foundProp) {
      uniqueProps.push(foundProp);
    }
    return uniqueProps;
  }, []);
}
