import { useMemo } from "react";
import { useMediaQueryList } from "src/hooks/use-media-query";

interface UseTableBreakPoint {
  /** Width for all columns in extra and small screens. */
  xsAndSmColumnsWidth?: string;

  /** Width for the columns that present long text */
  mdAndLargerColumnsWidth: string;
}

/** Hook to calculate table column width based on screen size */
export function useTableColumnWidth(): UseTableBreakPoint {
  const { isExtraSmall, isSmall, isMedium, isLarge } = useMediaQueryList();

  /**
   * The width is different than on larger screens because the
   * table is not displayed as a normal table, the column headers
   * are displayed as rows to fit more content.
   */
  const xsAndSmColumnsWidth = useMemo(() => {
    if (isExtraSmall) {
      return "82vw";
    }
    if (isSmall) {
      return "66vw";
    }
    return undefined;
  }, [isExtraSmall, isSmall]);

  /**
   * like name or email, in medium or larger screens
   */
  const mdAndLargerColumnsWidth = useMemo(() => {
    if (isMedium) {
      return "100px";
    }
    if (isLarge) {
      return "180px";
    }
    return "320px";
  }, [isMedium, isLarge]);

  return {
    xsAndSmColumnsWidth,
    mdAndLargerColumnsWidth,
  };
}
