import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import {
  FaroDialog,
  FaroDialogProps,
} from "@components/common/dialog/faro-dialog";

export type CreateDialogProps = Omit<
  FaroDialogProps,
  "open" | "onConfirm" | "onClose"
>;

type DialogData = {
  /**
   * @returns a promise with a boolean value, which is true if the dialog has been confirmed and false otherwise
   * @param content - The content of the dialog
   * @param options - Object which sets the possible customization for the dialog
   */
  createDialog(
    options: CreateDialogProps,
    content?: JSX.Element | string
  ): Promise<boolean>;
};

/** A dialog context which provides the components with the function to create a dialog */
const DialogContext = createContext<DialogData | undefined>(undefined);

/**
 *  @returns A dialog provider which allows the components to create a dialog from anywhere
 */
export function DialogProvider({
  children,
}: PropsWithChildren<unknown>): JSX.Element {
  const confirmPromiseRef = useRef<{
    resolve(value: boolean): void;
  }>();

  const [dialogOptions, setDialogOptions] = useState<CreateDialogProps | null>(
    null
  );
  const [content, setContent] = useState<JSX.Element | string>("");

  /**
   * @returns a promise with a boolean value, which is true if the dialog has been confirmed and false otherwise
   * @param options - object which sets the possible customization for the dialog
   */
  const createDialog = useCallback(
    async (
      options: CreateDialogProps,
      content?: JSX.Element
    ): Promise<boolean> => {
      setDialogOptions(options);
      setContent(content ?? "");
      return new Promise((resolve) => {
        confirmPromiseRef.current = { resolve };
      });
    },
    []
  );

  function handleCancel(): void {
    if (confirmPromiseRef.current) {
      confirmPromiseRef.current.resolve(false);
    }
    setDialogOptions(null);
  }

  function handleConfirm(): void {
    if (confirmPromiseRef.current) {
      confirmPromiseRef.current.resolve(true);
    }
    setDialogOptions(null);
  }

  return (
    <DialogContext.Provider value={{ createDialog }}>
      {dialogOptions && (
        <FaroDialog
          open={!!dialogOptions}
          onConfirm={handleConfirm}
          onClose={handleCancel}
          {...dialogOptions}
        >
          {content}
        </FaroDialog>
      )}
      {children}
    </DialogContext.Provider>
  );
}

/**
 * @returns Hook that returns the utility function to create a dialog
 */
export function useDialog(): DialogData {
  const ctx = useContext(DialogContext);
  if (!ctx) {
    throw Error("useDialog called outside the DialogContext");
  }
  return ctx;
}
