import { Box, BoxProps } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { withEllipsis } from "@styles/common-styles";

interface Props {
  /** Text to display as label of the KPI */
  label: string;

  /** KPI value */
  value: number;

  /** Optional style props to pass the the value component */
  valueSx?: BoxProps;

  /** If true the KPI is shown in compact format (one row) */
  isCompactFormat?: boolean;
}

/** Renders a single markups KPI */
export function MarkupsKpi({
  label,
  value,
  valueSx,
  isCompactFormat = false,
}: Props): JSX.Element {
  return (
    <Box
      sx={{
        display: isCompactFormat ? "flex" : "unset",
        alignItems: isCompactFormat ? "center" : "unset",
        justifyContent: isCompactFormat ? "space-between" : "none",
      }}
    >
      {/* Label */}
      <Box
        sx={{
          ...withEllipsis,
        }}
      >
        <Box
          component="span"
          sx={{
            color: sphereColors.gray500,
            fontSize: "12px",
            ...withEllipsis,
          }}
        >
          {label}
        </Box>
      </Box>

      <Box
        sx={{
          height: "44px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* KPI value */}
        <Box
          sx={{
            color: sphereColors.gray700,
            fontSize: "20px",
            fontWeight: "bold",
            paddingRight: "12px",
            ...valueSx,
          }}
        >
          {value}
        </Box>
      </Box>
    </Box>
  );
}
