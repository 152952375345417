import { PageContainer } from "@components/common/page-container";
import { useGroups } from "@hooks/use-groups";
import {
  fetchingGroupsFlagsSelector,
  filteredCompanyGroupsSelector,
} from "@store/groups/groups-selector";
import { useAppSelector } from "@store/store-helper";
import { dataViewModeSelector } from "@store/ui/ui-selector";
import { GroupsTiles } from "src/pages/groups/groups-tiles";
import { DataViewMode } from "@store/ui/ui-slice";
import { GroupsPageTable } from "src/pages/groups/groups-page-table";
import { useAppParams } from "@router/router-helper";
import { NotFoundPage } from "@pages/not-found-page";
import { GroupsActionButtons } from "@pages/groups/groups-action-buttons";
import { GroupsEmptyPage } from "@components/common/empty-page/groups-empty-page";
import { isGroupDemo } from "@utils/group-utils";
import { GroupsDemoEmptyPage } from "@components/common/empty-page/groups-demo-empty-page";

/** Content in the Groups page */
export function GroupsPage(): JSX.Element {
  const { companyId } = useAppParams();
  const groups = useGroups();
  const filteredCompanyGroups = useAppSelector(filteredCompanyGroupsSelector);
  const { groupsView } = useAppSelector(dataViewModeSelector);
  const { isFetchingGroups } = useAppSelector(fetchingGroupsFlagsSelector);

  if (!companyId) {
    return <NotFoundPage />;
  }

  if (!isFetchingGroups && groups.length === 1 && isGroupDemo(groups[0])) {
    return (
      <GroupsDemoEmptyPage
        companyId={companyId}
        demoGroup={filteredCompanyGroups[0]}
      />
    );
  }

  const shouldShowEmptyPage =
    filteredCompanyGroups.length === 0 && !isFetchingGroups;

  let groupsContent = (
    <GroupsPageTable
      companyId={companyId}
      groups={filteredCompanyGroups}
      isLoading={isFetchingGroups}
    />
  );

  if (groupsView === DataViewMode.cards) {
    groupsContent = <GroupsTiles groups={filteredCompanyGroups} />;
  }

  return (
    <PageContainer
      title="Groups Management"
      dataTestId="GROUPS_CONTAINER"
      actionButtons={<GroupsActionButtons groups={groups} />}
    >
      {/* If empty page exist, show it. If not show the content */}
      {shouldShowEmptyPage ? <GroupsEmptyPage /> : groupsContent}
    </PageContainer>
  );
}
