import { useMemo } from "react";
import { getOpenProjectHref } from "@utils/project-utils";
import { CreateDialogProps } from "@components/common/dialog/dialog-provider";
import { SphereShareLink } from "@components/common/sphere-share-link";
import { useAppSelector } from "@store/store-helper";
import { defaultOpenProjectTargetSelector } from "@store/sdb-company/sdb-company-selector";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";

/**
 * Props to be used when showing this dialog with the createDialog function of the dialog provider.
 */
export const SHARE_PROJECT_DIALOG_PROVIDER_PROPS: CreateDialogProps = {
  title: "Share project",
  maxWidth: "sm",
  shouldHideActions: true,
  // eslint-disable-next-line @typescript-eslint/naming-convention -- external package
  fullWidth: true,
};

/**
 * Shows the content to be shown inside a dialog with the Sphere Viewer Url of the project.
 * It has a text field that shows the url and a button to copy it to the clipboard.
 *
 * @returns React share project component.
 */
export function ShareProject({ projectId }: BaseProjectIdProps): JSX.Element {
  const defaultOpenProjectTarget = useAppSelector(
    defaultOpenProjectTargetSelector
  );

  /** The Sphere Viewer Url to be shown in the dialog */
  const sphereViewerUrl: string = useMemo(() => {
    return getOpenProjectHref({
      projectId,
      openTarget: defaultOpenProjectTarget,
    });
  }, [defaultOpenProjectTarget, projectId]);

  return <SphereShareLink title="Project link" url={sphereViewerUrl} />;
}
