import { useEffect, useState } from "react";

/** @returns True if there is a dragging event happening on the window */
export function useIsUserDragging(): boolean {
  const [isAppDragging, setIsAppDragging] = useState(false);

  // Add event listeners for dragging
  useEffect(() => {
    let timeoutId: number | undefined = undefined;

    function onDragEnterOrOver(event: DragEvent): void {
      if (!event.dataTransfer) {
        return;
      }

      // By default, we forbid any dragging operation on the window
      // if not explicitly managed somewhere else
      event.preventDefault();
      event.dataTransfer.dropEffect = "none";
      event.dataTransfer.effectAllowed = "none";
      event.stopPropagation();

      if (timeoutId !== undefined) {
        clearTimeout(timeoutId);
        timeoutId = undefined;
      }

      setIsAppDragging(true);
    }

    function onDragLeave(event: DragEvent): void {
      if (!event.dataTransfer) {
        return;
      }

      // By default, we forbid any dragging operation on the window
      // if not explicitly managed somewhere else
      event.preventDefault();
      event.dataTransfer.dropEffect = "none";
      event.dataTransfer.effectAllowed = "none";
      event.stopPropagation();

      // This timeout prevents flickering of the UI when
      // a react component not managed by us steals the events
      timeoutId = window.setTimeout(() => setIsAppDragging(false), 100);
    }

    window.addEventListener("dragenter", onDragEnterOrOver);
    window.addEventListener("dragover", onDragEnterOrOver);
    window.addEventListener("dragleave", onDragLeave);

    return () => {
      window.removeEventListener("dragenter", onDragEnterOrOver);
      window.removeEventListener("dragover", onDragEnterOrOver);
      window.removeEventListener("dragleave", onDragLeave);
    };
  }, []);
  return isAppDragging;
}
