import { RootState } from "@store/store-helper";
import { GroupsState, groupsAdapter } from "@store/groups/groups-slice";
import { APITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { createSelector } from "@reduxjs/toolkit";
import { GroupTypes } from "@custom-types/group-types";
import { isGroupDetails } from "@custom-types/type-guards";

/**
 * Returns all the stored groups
 */
export const groupsSelector: (state: RootState) => GroupTypes[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return groupsAdapter.getSelectors().selectAll(state.groups);
    }
  );

/**
 * Returns all the stored groups filtered by search text.
 */
export const filteredCompanyGroupsSelector: (state: RootState) => GroupTypes[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const searchText = state.ui.search.searchText.toLowerCase();
      return groupsSelector(state)
        .filter((group) => {
          return group.name.toLowerCase().includes(searchText);
        })
        .sort((groupA, groupB) => {
          // Sort by creation date, descending
          return groupB.createdAt - groupA.createdAt;
        });
    }
  );

/**
 * Returns the fetching properties of the groups slice.
 */
export const fetchingGroupsFlagsSelector: (
  state: RootState
) => GroupsState["fetching"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.groups.fetching;
  }
);

/**
 * Returns true if a given group is currently ongoing a process.
 *
 * @param groupId
 */
export function isGroupProcessingSelector(
  groupId: APITypes.GroupId
): (state: RootState) => boolean {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.groups.fetching.processingGroups[groupId];
    }
  );
}

/**
 * Gets a group entity by providing the group ID
 */
export function getGroupByIdSelector(
  groupId: APITypes.GroupId | undefined
): (state: RootState) => GroupTypes | undefined {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      if (!groupId) {
        return undefined;
      }
      return (
        groupsAdapter.getSelectors().selectById(state.groups, groupId) ??
        undefined
      );
    }
  );
}

/**
 * Get the selected group using the selected group ID and finding it on the store,
 * returns null if there's no groupId or if the group could not be found in the store.
 */
export const selectedGroupSelector: (state: RootState) => GroupTypes | null =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const selectedGroupId = state.groups.selectedGroupId;
      if (!selectedGroupId) {
        return null;
      }

      return (
        groupsAdapter
          .getSelectors()
          .selectById(state.groups, selectedGroupId) ?? null
      );
    }
  );

/** Returns the members of selected group */
export const selectedGroupMembersSelector: (
  state: RootState
) => SphereDashboardAPITypes.IGroupMemberBase[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    if (!state.groups.selectedGroupId) {
      return [];
    }

    const selectedGroup = selectedGroupSelector(state);

    if (selectedGroup && isGroupDetails(selectedGroup)) {
      return selectedGroup.members;
    } else {
      return [];
    }
  }
);

/** Returns the projects of selected group */
export const selectedGroupProjectsSelector: (
  state: RootState
) => SphereDashboardAPITypes.IProjectSummary[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    if (!state.groups.selectedGroupId) {
      return [];
    }

    const selectedGroup = selectedGroupSelector(state);

    if (selectedGroup && isGroupDetails(selectedGroup)) {
      return selectedGroup.projects;
    } else {
      return [];
    }
  }
);
