import { useCallback } from "react";
import { Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  addProcessingProjects,
  removeProcessingProjects,
} from "@store/projects/projects-slice";
import { updateProjectDetails } from "@store/projects/projects-slice-thunk";
import { APITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { useCoreApiClient } from "src/api/use-core-api-client";
import { BaseProjectProps } from "@custom-types/project-types";
import { useDialog } from "@components/common/dialog/dialog-provider";
import { isProjectActiveSelector } from "@store/projects/projects-selector";
import { ProjectEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";

/** Type of the callback returned by the custom hook */
type ProjectArchiveDialogFn = () => Promise<boolean>;

/**
 * Custom hook that returns a function to archive or unarchive a project.
 */
export function useProjectArchiveDialog({
  project,
}: BaseProjectProps): ProjectArchiveDialogFn {
  const { createDialog } = useDialog();
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const { trackEvent } = useTrackEvent();

  const isProjectActive = useAppSelector(isProjectActiveSelector(project.id));

  const handleDialog = useCallback(async () => {
    // Creates a modal dialog and waits for the user to perform an action
    const hasConfirmed = await createDialog(
      {
        title: isProjectActive ? "Archive Project" : "Unarchive Project",
        confirmText: isProjectActive ? "Archive" : "Unarchive",
        closeText: "Cancel",
        maxWidth: "sm",
        // eslint-disable-next-line @typescript-eslint/naming-convention -- external package
        fullWidth: true,
      },
      <Typography>
        Do you really want to {isProjectActive ? "archive" : "unarchive"} this
        project?
      </Typography>
    );

    if (hasConfirmed) {
      trackEvent({
        name: isProjectActive
          ? ProjectEvents.archiveProject
          : ProjectEvents.unarchiveProject,
      });

      // Add the projectId to processing projects to start the loading spinner in the project
      dispatch(addProcessingProjects([project.id]));

      const payload: SphereDashboardAPITypes.IUpdateProjectPayload = {
        archivingState: isProjectActive
          ? APITypes.ArchivingState.ARCHIVED
          : APITypes.ArchivingState.UNARCHIVED,
      };
      dispatch(updateProjectDetails({ coreApiClient, payload }));

      // TODO: Maybe move adding/removing processing projects to the pending/fulfilled in project-slice
      // Remove projectId from processing project to stop the loading spinner in the project
      dispatch(removeProcessingProjects([project.id]));
      return true;
    }

    return false;
  }, [
    createDialog,
    isProjectActive,
    dispatch,
    project,
    coreApiClient,
    trackEvent,
  ]);

  return handleDialog;
}
