import { FaroTextButton } from "@components/common/faro-text-button";
import { useToast } from "@hooks/use-toast";
import { setBulkActionName } from "@store/table/table-slice";
import { useAppDispatch } from "@store/store-helper";
import { TableType, ExportType } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";

interface Props {
  /** Text to show when user hover over the button */
  buttonTooltipText?: string;

  /** The prop name sent for tracking */
  trackingProps: {
    dataType?: TableType;
    numberOfEntities: number;
    areEntitiesSelected: boolean;
  };

  /** Handler to export data as CSV */
  csvExportHandler: () => void;

  /** Message to show when the export is successful or with error */
  toastMessage: {
    /** Success message shown as toast */
    success: string;

    /** Error message shown as toast */
    error: string;
  };
}

/** Implements logic in respect to showing an export CSV button and related logics to it */
export function ExportAsCSV({
  trackingProps,
  csvExportHandler,
  toastMessage,
  buttonTooltipText = "Export as CSV",
}: Props): JSX.Element {
  const { showToast } = useToast();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();

  function onExportAsCSVHandler(): void {
    try {
      trackEvent({
        name: ExportType.exportAsCsv,
        props: { ...trackingProps },
      });

      dispatch(setBulkActionName("exportCsv"));

      csvExportHandler();

      showToast({
        type: "success",
        message: toastMessage.success,
      });
    } catch (error) {
      showToast({
        type: "error",
        message: toastMessage.error,
      });
    }

    dispatch(setBulkActionName(null));
  }

  return (
    <FaroTextButton
      onClick={onExportAsCSVHandler}
      tooltipText={buttonTooltipText}
      sx={{ fontWeight: 600 }}
    >
      Export CSV
    </FaroTextButton>
  );
}
