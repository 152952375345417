import { useRef, useState } from "react";
import { FaroIconButton, ThreeDotsIcon } from "@faro-lotv/flat-ui";
import { Menu, MenuItem, Typography } from "@mui/material";
import { CardAction } from "@components/progress-overview/card-action-button";

interface Props {
  /** A menu with multiple actions */
  menu: CardAction[];
}

/** Renders a three dot menu for a card */
export function CardActionMenu({ menu }: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const button = useRef<HTMLButtonElement>(null);

  return (
    <>
      <FaroIconButton ref={button} onClick={() => setIsOpen(true)} size="xs">
        <ThreeDotsIcon />
      </FaroIconButton>

      <Menu
        anchorEl={button.current}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        {menu.map(({ name, callback, color }) => (
          <MenuItem key={name} onClick={callback}>
            <Typography
              component="span"
              sx={{ color: color, fontSize: "14px" }}
            >
              {name}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
