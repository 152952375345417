import {
  CaptureTreeEntity,
  CaptureTreeEntityType,
} from "@faro-lotv/service-wires";
import {
  CAPTURE_TREE_ENTITY_SCAN_TYPES,
  DeviceType,
} from "@pages/project-details/project-data-management/raw-scans/raw-scans-types";

/**
 * @returns The device type for a capture tree entity type
 *
 * @param captureTreeEntityType Capture tree entity type
 */
export function getDeviceType(
  captureTreeEntityType: CaptureTreeEntityType
): DeviceType {
  switch (captureTreeEntityType) {
    case CaptureTreeEntityType.elsScan:
      return DeviceType.els;
    case CaptureTreeEntityType.focusScan:
      return DeviceType.focus;
    case CaptureTreeEntityType.orbisScan:
      return DeviceType.orbis;
    default:
      return DeviceType.unknown;
  }
}

/**
 * @returns true iff the capture tree entity is a scan
 *
 * @param captureTreeEntity Capture tree entity
 */
export function isCaptureTreeScanEntity(
  captureTreeEntity: CaptureTreeEntity
): boolean {
  return CAPTURE_TREE_ENTITY_SCAN_TYPES.includes(captureTreeEntity.type);
}
