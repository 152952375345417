import { useMemo } from "react";
import { TokenManager } from "@faro-lotv/service-wires";
import { ProgressApiClient } from "@api/progress-api/progress-api-client";
import { runtimeConfig } from "@src/runtime-config";

interface UseProgressApiClientProps {
  /**
   * The project ID to use for initializing progress API client.
   */
  projectId: string;
}

/**
 * Initializes the progress API client and returns a memoized instance
 */
export function useProgressApiClient({
  projectId,
}: UseProgressApiClientProps): ProgressApiClient {
  const memoizedTokenManager = useMemo(
    () => new TokenManager(new URL(runtimeConfig.urls.apiBaseUrl), projectId),
    [projectId]
  );

  const memoizedProgressApiClient = useMemo(
    () =>
      new ProgressApiClient(
        projectId,
        memoizedTokenManager,
        runtimeConfig.urls.progressApiUrl
      ),
    [memoizedTokenManager, projectId]
  );

  return memoizedProgressApiClient;
}
