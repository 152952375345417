import {
  EntityAdapter,
  EntityId,
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { BaseEntityState } from "@store/store-types";
import { TableType } from "@utils/track-event/track-event-list";
import {
  BulkActionResults,
  FetchingItem,
  FetchingStatus,
} from "@store/table/table-slice-helper";
import { BulkName } from "@components/common/faro-table/faro-table-types";

/** State of the current table logic */
export interface TableState extends BaseEntityState<FetchingItem> {
  /** The table that the user is currently checking */
  tableType: TableType | null;

  /** The name of the bulk action the user has selected */
  bulkActionName: BulkName | null;

  /** Results after bulk actions */
  bulkActionResults: BulkActionResults;

  /** All the bulk action buttons that is allowed in a session based on initial selected entities */
  allowedBulkButtonsInSession: BulkName[];
}

/** Creates an entity adapter to store a map with all the table rows that are going to be updated */
export const TableAdapter: EntityAdapter<FetchingItem> = createEntityAdapter();

const initialState: TableState = {
  ...TableAdapter.getInitialState(),
  tableType: null,
  bulkActionName: null,
  bulkActionResults: { numberOfErrors: 0, numberOfSuccess: 0 },
  allowedBulkButtonsInSession: [],
};

/** Slice to access state of table */
const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    /** Remove single entity based on the provided ID before fetching and manually */
    removeOneManually(state, action: PayloadAction<EntityId>) {
      TableAdapter.removeOne(state, action.payload);

      if (state.ids.length === 0) {
        state.bulkActionName = null;
        state.bulkActionResults = { numberOfErrors: 0, numberOfSuccess: 0 };
      }
    },

    /** Remove single entity based on the provided ID after fetching done */
    removeOneAfterFetching(state, action: PayloadAction<EntityId>) {
      TableAdapter.removeOne(state, action.payload);
    },

    /** Update single entity based on the provided ID and changes */
    updateOne: TableAdapter.updateOne,

    /** Set the initial fetching items */
    setInitialFetchingItems(state, action: PayloadAction<EntityId[]>) {
      TableAdapter.setAll(
        state,
        action.payload.map((id) => {
          return {
            id: id as string,
            status: "idle",
            message: "",
          };
        })
      );
    },

    /**
     * Change the status of all the provided items if the status is not "not-allowed".
     * It will also clear the message if requested.
     */
    changeAllFetchingStatus(
      state,
      action: PayloadAction<{
        status: FetchingStatus;
        shouldClearMessage: boolean;
      }>
    ) {
      TableAdapter.setAll(
        state,
        TableAdapter.getSelectors()
          .selectAll(state)
          .map((item) => {
            return {
              ...item,
              status:
                item.status !== "not-allowed"
                  ? action.payload.status
                  : item.status,
              message: action.payload.shouldClearMessage ? "" : item.message,
            };
          })
      );
    },

    setTableType(state, action: PayloadAction<TableType | null>) {
      state.tableType = action.payload;
    },

    setBulkActionResults(state, action: PayloadAction<BulkActionResults>) {
      state.bulkActionResults = action.payload;
    },

    /** Set the allowed bulk actions */
    setAllowedBulkButtonsInSession(state, action: PayloadAction<BulkName[]>) {
      state.allowedBulkButtonsInSession = action.payload;
    },

    setBulkActionName(state, action: PayloadAction<BulkName | null>) {
      if (action.payload === null) {
        state.bulkActionName = null;
        state.allowedBulkButtonsInSession = [];
        state.bulkActionResults = { numberOfErrors: 0, numberOfSuccess: 0 };
        TableAdapter.setAll(
          state,
          TableAdapter.getSelectors()
            .selectAll(state)
            .map((item) => {
              return { ...item, status: "idle", message: "" };
            })
        );
      } else {
        state.bulkActionName = action.payload;
      }
    },

    /** Reset the whole table slice */
    resetTableState: () => initialState,
  },
});

export const {
  updateOne,
  removeOneManually,
  removeOneAfterFetching,
  setInitialFetchingItems,
  setTableType,
  setBulkActionName,
  setBulkActionResults,
  changeAllFetchingStatus,
  setAllowedBulkButtonsInSession,
  resetTableState,
} = tableSlice.actions;

export const tableReducer = tableSlice.reducer;
