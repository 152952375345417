import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { hasUserValidCompanyRole } from "@utils/access-control/company/company-access-control";

/** Checks if the current user is allowed to change the project manager and group */
export function isAllowedToChangeManagement(
  isProjectArchived: boolean,
  currentUser: SphereDashboardAPITypes.ICompanyMemberBase | null
): boolean {
  if (isProjectArchived) {
    return false;
  }

  return hasUserValidCompanyRole({
    currentUser,
    requiredCompanyRoles: [CoreAPITypes.EUserCompanyRole.companyExecutive],
  });
}
