import { Stack, SxProps, Theme, Typography } from "@mui/material";
import { DEFAULT_TEXT_FONT_SIZE, withEllipsis } from "@styles/common-styles";
import { PropsWithChildren, ReactNode } from "react";
import { CardActionMenu } from "@components/progress-overview/card-action-menu";
import { sphereColors } from "@styles/common-colors";
import { CardAction } from "@components/progress-overview/card-action-button";

export interface CardLayoutProps {
  /** Name of the uploaded file */
  name: string;

  /** Name of the target project for this upload */
  projectName?: string;

  /** A menu with multiple actions */
  menu?: CardAction[];

  /** An icon to show before the name if available */
  icon?: ReactNode;

  /** Optional style to apply the the wrapper of the title */
  titleWrapperSx?: SxProps<Theme>;
}

/** Renders the main layout of a menu card */
export function CardLayout({
  name,
  projectName,
  menu,
  icon,
  titleWrapperSx,
  children,
}: PropsWithChildren<CardLayoutProps>): JSX.Element {
  return (
    <Stack sx={{ p: 0.5, mx: 4 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={0.375}
        sx={titleWrapperSx}
      >
        {icon}
        <Typography
          fontSize="14px"
          color={sphereColors.gray800}
          flex="1"
          sx={{ ...withEllipsis }}
        >
          <var>{name}</var>
        </Typography>

        {menu && <CardActionMenu menu={menu} />}
      </Stack>

      {projectName && (
        <Typography
          fontSize={DEFAULT_TEXT_FONT_SIZE}
          color={sphereColors.gray600}
          sx={{
            pb: 1.25,
            ...withEllipsis,
          }}
        >
          <var>{projectName}</var>
        </Typography>
      )}
      <Stack component="div" sx={{ pb: 1.5 }} gap={1}>
        {children}
      </Stack>
    </Stack>
  );
}
