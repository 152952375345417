/** Default pose value param to create capture tree revision entities */
export const DEFAULT_POSE_PARAM = {
  pos: {
    x: 0,
    y: 0,
    z: 0,
  },
  rot: {
    x: 0,
    y: 0,
    z: 0,
    w: 1,
  },
};
