import { FaroTable } from "@components/common/faro-table/faro-table";
import {
  RawScan,
  RawScansTableHeaders,
} from "@pages/project-details/project-data-management/raw-scans/raw-scans-types";
import { getRawScansTableColumns } from "@pages/project-details/project-data-management/raw-scans/raw-scans-table-utils";
import { useAppSelector } from "@store/store-helper";
import { fetchingMembersFlagsSelector } from "@store/members/members-selector";
import { fetchingProjectsFlagsSelector } from "@store/projects/projects-selector";
import { useMemo } from "react";
import { sphereColors } from "@styles/common-colors";
import { useDataManagementContext } from "@context-providers/data-management/data-management-context";
import { useDateTime } from "@hooks/use-date-time";

/** The columns to display in the table */
const COLUMNS: RawScansTableHeaders[] = [
  RawScansTableHeaders.name,
  RawScansTableHeaders.clusterPath,
  RawScansTableHeaders.createdBy,
  RawScansTableHeaders.createdAt,
  RawScansTableHeaders.deviceType,
  RawScansTableHeaders.actions,
];

interface Props {
  /** List of raw scans */
  rawScans: RawScan[];

  /** Whether the raw scans data is being fetched for the first time */
  isFetchingForTheFirstTime: boolean;
}

/** Renders the table that displays raw scans */
export function RawScansTable({
  rawScans,
  isFetchingForTheFirstTime,
}: Props): JSX.Element {
  const { companyMembers, projectMembers } = useDataManagementContext();

  const { isFetchingCompanyMembers } = useAppSelector(
    fetchingMembersFlagsSelector
  );
  const { isFetchingProjects: isFetchingProjectMembers } = useAppSelector(
    fetchingProjectsFlagsSelector
  );

  const { formatDate } = useDateTime();

  const isLoading = useMemo(() => {
    return (
      isFetchingForTheFirstTime ||
      isFetchingCompanyMembers ||
      isFetchingProjectMembers
    );
  }, [
    isFetchingForTheFirstTime,
    isFetchingCompanyMembers,
    isFetchingProjectMembers,
  ]);

  return (
    <FaroTable
      entities={rawScans}
      uniqueIdKey={"id"}
      isLoading={isLoading}
      columns={COLUMNS.map(
        (column) =>
          getRawScansTableColumns({
            companyMembers,
            projectMembers,
            formatDate,
          })[column]
      )}
      rowHeight={54}
      shouldHideCheckbox={true}
      rowClassName={(rowData) => {
        return rowData.row.entity.isProcessing ? "raw-data-processing" : "";
      }}
      sx={{
        // Set background color of processing rows
        "& .raw-data-processing": {
          background: sphereColors.blue50,
          "&:hover": {
            background: sphereColors.blue50,
          },
        },
        // Set opacity of all cells in processing rows
        "& .raw-data-processing *": {
          opacity: "0.8",
        },
        // Especial opacity for the last column that displays actions/indicator
        "& .raw-data-processing *:last-child *": {
          opacity: "1",
        },
      }}
    />
  );
}
