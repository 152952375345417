import { useState } from "react";
import Menu from "@mui/material/Menu";
import { Box } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import {
  sdbCompaniesSelector,
  selectedSdbCompanySelector,
} from "@store/sdb-company/sdb-company-selector";
import { useAppSelector } from "@store/store-helper";
import { SdbCompanyMenuItem } from "@components/sidebar/sdb-company/sdb-company-menu-item";
import { SdbCompanySidebarItem } from "@components/sidebar/sdb-company/sdb-company-sidebar-item";
import { SdbCompany } from "@custom-types/sdb-company-types";
import { sortWorkspaces } from "@utils/sdb-company-utils";
import { SphereDivider } from "@components/common/sphere-divider";

/**
 * Menu to be used in the side panel that when closed only shows the current company name,
 * and when opened shows all the sdbCompanies (workspaces/companies) that the user has access and provide
 * an easy way to switch between them.
 *
 * @returns React sdb company menu component.
 */
export function SdbCompanyMenu(): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const sdbCompanies = useAppSelector(sdbCompaniesSelector);
  const selectedSdbCompany = useAppSelector(selectedSdbCompanySelector);

  const hasManySdbCompanies = sdbCompanies.length > 1;

  const isOpen = Boolean(anchorEl);

  /** Handles the click event to open the menu. */
  function handleClick(event: React.MouseEvent<HTMLElement>): void {
    setAnchorEl(event.currentTarget);
  }

  /** Handles closing the menu. */
  function handleClose(): void {
    setAnchorEl(null);
  }

  /** Sort the SdbCompanies by name and move the current selected company to the top */
  function sortSdbCompanies(): SdbCompany[] {
    let sortedSdbCompanies = sdbCompanies.sort(sortWorkspaces);

    if (selectedSdbCompany) {
      sortedSdbCompanies = sortedSdbCompanies.filter(
        (sdbCompany) => sdbCompany.id !== selectedSdbCompany.id
      );
      sortedSdbCompanies.unshift(selectedSdbCompany);
    }

    return sortedSdbCompanies;
  }

  return (
    <>
      <SdbCompanySidebarItem
        hasManySdbCompanies={hasManySdbCompanies}
        isOpen={isOpen}
        handleClick={(event: React.MouseEvent<HTMLElement>) =>
          handleClick(event)
        }
      />

      <Menu
        aria-labelledby="workspaces-menu-btn"
        anchorEl={anchorEl}
        open={hasManySdbCompanies && isOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          vertical: 24,
          horizontal: "left",
        }}
      >
        <Box
          component="div"
          sx={{
            color: sphereColors.gray500,
            fontSize: "12px",
            paddingTop: "4px",
            paddingBottom: "10px",
            paddingX: "16px",
            userSelect: "none",
          }}
        >
          Change workspace
        </Box>
        <Box component="div" data-testid="SIDEBAR_WORKSPACE_LIST">
          {sortSdbCompanies().map((sdbCompany, index) => (
            <SdbCompanyMenuItem
              sdbCompany={sdbCompany}
              key={sdbCompany.name}
              shouldShowDivider={index === 0 && hasManySdbCompanies}
            />
          ))}

          <SphereDivider
            sx={{
              marginTop: "34px",
              marginBottom: "9px",
            }}
          />
          <Box
            component="a"
            href="/"
            sx={{
              paddingX: "15px",
              textDecoration: "none",
              fontSize: "12px",
              color: sphereColors.blue500,
              "&:hover": {
                cursor: "pointer",
                textDecoration: "none",
              },
            }}
          >
            Browse All
          </Box>
        </Box>
      </Menu>
    </>
  );
}
