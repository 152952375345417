import { Alert, AlertProps } from "@mui/material";
import InfoIcon from "@assets/icons/new/info-circle-fill-yellow.svg?react";
import { sphereColors } from "@styles/common-colors";

/**
 * Renders a FARO alert.
 *
 * TODO: If a severity other than warning is used, the styling needs to be updated.
 */
export function FaroAlert(
  props: React.PropsWithChildren<AlertProps>
): JSX.Element {
  return (
    <Alert
      {...props}
      icon={props.severity === "warning" ? <InfoIcon /> : undefined}
      sx={{
        ...props.sx,
        fontSize: "14px",
        color: sphereColors.black,
        backgroundColor:
          props.severity === "warning" ? sphereColors.yellow100 : undefined,
      }}
    >
      {props.children}
    </Alert>
  );
}
