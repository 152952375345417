import { Box } from "@mui/material";
import { FARO_CARD_ACTIONS } from "@components/common/faro-card/faro-card";
import { SphereActionDivider } from "@components/common/sphere-action-divider";
import { MarkupActionSphereViewer } from "@pages/project-details/project-markups/markup-action-sphere-viewer";
import { MarkupActionShareMarkup } from "@pages/project-details/project-markups/markup-action-share-markup";
import { BaseMarkupProps } from "@custom-types/project-markups-types";

/** Markup actions buttons displayed in a markups table */
export function MarkupActions({ markup }: BaseMarkupProps): JSX.Element {
  return (
    <Box
      component="div"
      className={FARO_CARD_ACTIONS}
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <MarkupActionShareMarkup markup={markup} />

      <SphereActionDivider />

      <MarkupActionSphereViewer markup={markup} />
    </Box>
  );
}
