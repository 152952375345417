import { RootState } from "@store/store-helper";
import {
  filterProjects,
  getProjectArchivingState,
  getProjectManager,
  getProjectManagerName,
  paginateProjects,
  sortProjects,
} from "@utils/project-utils";
import {
  ProjectArchivingState,
  ProjectSettings,
  SdbProject,
} from "@custom-types/project-types";
import { ProjectsState, projectsAdapter } from "@store/projects/projects-slice";
import {
  APITypes,
  CoreAPITypes,
  SphereDashboardAPITypes,
} from "@stellar/api-logic";
import { searchSelector } from "@store/ui/ui-selector";
import { MemberTypes } from "@custom-types/member-types";
import { createSelector } from "@reduxjs/toolkit";
import { hasUserValidRoleProjectLevel } from "@utils/access-control/project/project-access-control";
import { RequiredRoleProjectLevelName } from "@utils/access-control/project/project-access-control-types";
import { currentUserSelector } from "@store/user/user-selector";
import { selectedGroupProjectsSelector } from "@store/groups/groups-selector";

/**
 * Filters, sorts and paginates the provided project list, based on the user's
 * selected sorting attribute, search text and currently shown page.
 */
function filteredProjectsSelector(
  projects: SdbProject[]
): (state: RootState) => SdbProject[] {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const { searchText } = searchSelector(state);
      const filteredProjects = filterProjects(projects, { searchText });
      const sortedProjects = sortProjects(filteredProjects, {
        sortBy: state.projects.sortBy.attrName,
        shouldSortDesc: state.projects.sortBy.shouldSortDesc,
      });
      return paginateProjects(sortedProjects, {
        page: state.projects.page,
        limit: state.projects.numberOfDisplayedProjects,
      });
    }
  );
}

/**
 * Returns all projects
 */
export const projectsSelector: (state: RootState) => SdbProject[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const allProjects = projectsAdapter
        .getSelectors()
        .selectAll(state.projects);

      return allProjects;
    }
  );

/**
 * Returns the ids of all projects
 */
export const projectIdsSelector: (state: RootState) => APITypes.ProjectId[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.projects.ids;
    }
  );

/**
 * Returns the active projects
 */
export const activeProjectsSelector: (state: RootState) => SdbProject[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const allProjects = projectsAdapter
        .getSelectors()
        .selectAll(state.projects);
      return allProjects.filter((project) =>
        isProjectActiveSelector(project.id)(state)
      );
    }
  );

/** Returns the active projects belong to the selected group */
export const activeProjectsOfSelectedGroupSelector: (
  state: RootState
) => SdbProject[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const allProjects = projectsAdapter
      .getSelectors()
      .selectAll(state.projects);

    const selectedGroupProjectsIds = selectedGroupProjectsSelector(state).map(
      (project) => project.id
    );

    return allProjects.filter(({ id }) => {
      return (
        selectedGroupProjectsIds.includes(id) &&
        isProjectActiveSelector(id)(state)
      );
    });
  }
);

/**
 * Returns the active projects, filtered by search text, sorted by the desired sorting attribute and filtered
 * to only show the projects in the current displayed page.
 */
export const filteredActiveProjectsSelector: (
  state: RootState
) => SdbProject[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const projects = activeProjectsSelector(state);
    return filteredProjectsSelector(projects)(state);
  }
);

/**
 * Returns the archived projects
 */
export const archivedProjectsSelector: (state: RootState) => SdbProject[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const allProjects = projectsAdapter
        .getSelectors()
        .selectAll(state.projects);
      return allProjects.filter((project) =>
        isProjectArchiveSelector(project.id)(state)
      );
    }
  );

/**
 * Returns the archived projects, filtered by search text, sorted by the desired sorting attribute and filtered
 * to only show the projects in the current displayed page.
 */
export const filteredArchivedProjectsSelector: (
  state: RootState
) => SdbProject[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const projects = archivedProjectsSelector(state);
    return filteredProjectsSelector(projects)(state);
  }
);

/**
 * Returns true if a given project can be archived.
 *
 * @param project
 */
export function isProjectArchivingAllowedSelector(
  project: SdbProject
): (state: RootState) => boolean {
  const getIsProjectAllowedToBeArchived = isFeatureEnabledForProjectSelector(
    project.id,
    APITypes.EUserSubscriptionRole.projectArchive
  );

  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return (
        isProjectActiveSelector(project.id)(state) &&
        getIsProjectAllowedToBeArchived(state)
      );
    }
  );
}

/**
 * Returns true if a given project can be unarchived.
 *
 * @param project
 */
export function isProjectUnarchivingAllowedSelector(
  project: SdbProject
): (state: RootState) => boolean {
  const getIsProjectAllowedToBeUnarchived = isFeatureEnabledForProjectSelector(
    project.id,
    APITypes.EUserSubscriptionRole.projectUnarchive
  );

  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return (
        projectArchivingStateSelector(project.id)(state) ===
          ProjectArchivingState.archived &&
        getIsProjectAllowedToBeUnarchived(state) &&
        project.archivingState !== APITypes.ArchivingState.ARCHIVED_DOWNLOADED
      );
    }
  );
}

/**
 * Returns the fetching properties of the projects slice.
 */
export const fetchingProjectsFlagsSelector: (
  state: RootState
) => ProjectsState["fetching"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.projects.fetching;
  }
);

/**
 * Returns the flags for the loading result of projects.
 */
export const loadedProjectsFlagsSelector: (
  state: RootState
) => ProjectsState["loaded"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.projects.loaded;
  }
);

/**
 * Returns the loaded flag depending on the archiving state to be shown.
 */
export function hasLoadedProjectsSelector(
  archivingState: ProjectArchivingState
): (state: RootState) => boolean {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      if (archivingState === ProjectArchivingState.active) {
        return state.projects.loaded.hasLoadedActiveProjects;
      }
      if (archivingState === ProjectArchivingState.archived) {
        return state.projects.loaded.hasLoadedArchivedProjects;
      }
      return false;
    }
  );
}

/**
 * Returns the projects to be displayed based on the archiving state to be shown.
 */
export function displayProjectsSelector(
  archivingState: ProjectArchivingState
): (state: RootState) => SdbProject[] {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return archivingState === ProjectArchivingState.active
        ? filteredActiveProjectsSelector(state)
        : filteredArchivedProjectsSelector(state);
    }
  );
}

/**
 * Returns true if a given a project is currently ongoing a process.
 *
 * @param projectId
 */
export function isProjectProcessingSelector(
  projectId: APITypes.ProjectId
): (state: RootState) => boolean {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.projects.fetching.processingProjects[projectId];
    }
  );
}

/**
 * Get project details by providing the projectId
 */
export function getProjectByIdSelector(
  projectId: APITypes.ProjectId | undefined
): (state: RootState) => SdbProject | null {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      if (!projectId) {
        return null;
      }
      return (
        projectsAdapter.getSelectors().selectById(state.projects, projectId) ??
        null
      );
    }
  );
}

/**
 * Get the selected project using the selected project Id and finding it on the store,
 * returns null if there's no projectId or if the project could not be found in the store.
 */
export const selectedProjectSelector: (state: RootState) => SdbProject | null =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const selectedProjectId = state.projects.selectedProject.id;
      if (!selectedProjectId) {
        return null;
      }

      return (
        projectsAdapter
          .getSelectors()
          .selectById(state.projects, selectedProjectId) ?? null
      );
    }
  );

/**
 * Get the selected project ID
 */
export const selectedProjectIdSelector: (
  state: RootState
) => APITypes.ProjectId | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.projects.selectedProject.id;
  }
);

/**
 * Returns the project members
 */
export const projectMembersSelector: (
  state: RootState
) => SphereDashboardAPITypes.IProjectMemberBase[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    if (!state.projects.selectedProject.id) {
      return [];
    }

    const selectedProject = selectedProjectSelector(state);

    return selectedProject?.members ?? [];
  }
);

/**
 * Get project member with the provided role
 */
export function projectMembersByRoleSelector(
  userRole: CoreAPITypes.EUserProjectRole
): (state: RootState) => SphereDashboardAPITypes.IProjectMemberBase[] {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const selectedMembers = projectMembersSelector(state);

      return selectedMembers.filter(({ role }) => role === userRole);
    }
  );
}

/**
 * Get project member with the provided role
 */
export function isFeatureEnabledForProjectSelector(
  projectId: APITypes.ProjectId,
  feature: APITypes.EUserSubscriptionRole
): (state: RootState) => boolean {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const project = getProjectByIdSelector(projectId)(state);
      if (!project || project.features === null) {
        return false;
      }

      return project.features[feature].enabled;
    }
  );
}

/**
 * Returns the archiving state of a project
 */
export function projectArchivingStateSelector(
  projectId: APITypes.ProjectId
): (state: RootState) => ProjectArchivingState | null {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const project = getProjectByIdSelector(projectId)(state);
      if (!project) {
        return null;
      }

      return project.archivingState === APITypes.ArchivingState.UNARCHIVED
        ? ProjectArchivingState.active
        : ProjectArchivingState.archived;
    }
  );
}

/**
 * Returns whether the project is active or not
 */
export function isProjectActiveSelector(
  projectId: APITypes.ProjectId
): (state: RootState) => boolean {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return (
        projectArchivingStateSelector(projectId)(state) ===
        ProjectArchivingState.active
      );
    }
  );
}

/**
 * Returns whether the project is archived or not
 */
export function isProjectArchiveSelector(
  projectId: APITypes.ProjectId
): (state: RootState) => boolean {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return (
        projectArchivingStateSelector(projectId)(state) ===
        ProjectArchivingState.archived
      );
    }
  );
}

/**
 * Returns true if selected project is editable
 * Projects can be editable if: User is allowed to edit them and they are active
 */
export function isSelectedProjectEditableSelector(): (
  state: RootState
) => boolean {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const selectedProject = selectedProjectSelector(state);

      if (selectedProject) {
        const hasUserPermissionToEditProject = hasUserValidRoleProjectLevel({
          roleName: RequiredRoleProjectLevelName.canEditProjectDetails,
          currentUser: currentUserSelector(state),
          selectedProject: selectedProject,
        });

        const isProjectActive =
          getProjectArchivingState(selectedProject.archivingState) ===
          ProjectArchivingState.active;

        return hasUserPermissionToEditProject && isProjectActive;
      }

      return false;
    }
  );
}

/**
 * Gets the project manager and its name for the selected project.
 *
 * @returns An object including the project manager of the project,
 * and the prettified version of the name.
 */
export const selectedProjectManagerSelector: (state: RootState) => {
  user: MemberTypes | null;
  name: string;
} = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const selectedProject = selectedProjectSelector(state);
    const user = selectedProject ? getProjectManager(selectedProject) : null;
    const name = selectedProject ? getProjectManagerName(selectedProject) : "";
    return { user, name };
  }
);

/**
 * Returns the next project ID if set.
 */
export const nextProjectSelector: (state: RootState) => string | null =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.projects.next;
    }
  );

/**
 * Returns the project context of the selected project.
 */
export const selectedProjectContextSelector: (
  state: RootState
) => SphereDashboardAPITypes.IProjectContextResponse | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.projects.selectedProject.context;
  }
);

/**
 * Returns the settings of the selected project.
 */
export const selectedProjectSettingsSelector: (
  state: RootState
) => ProjectSettings | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.projects.selectedProject.settings;
  }
);
