import { useDateTime } from "@hooks/use-date-time";
import { Box } from "@mui/material";
import { DateTime } from "luxon";
import { useMemo } from "react";

interface Props {
  /** A generic data string */
  date: string;
}

/** Renders a date cell in the the point clouds table */
export function PointCloudDate({ date }: Props): JSX.Element {
  const { formatDate } = useDateTime();

  const dateLabel = useMemo(() => {
    return formatDate(date, DateTime.DATE_MED);
  }, [date, formatDate]);

  return <Box component={"var"}>{dateLabel}</Box>;
}
