import { Typography } from "@mui/material";
import { withEllipsis } from "@styles/common-styles";
import { ReactNode, isValidElement } from "react";

interface Props {
  /** Contains the value to show as title */
  title: ReactNode;
}

/** Renders title for any section */
export function TitleComponent({ title }: Props): JSX.Element {
  // Returns as it is if title is component
  if (isValidElement(title)) {
    return title;
  }

  return (
    <Typography
      sx={{
        fontSize: "16px",
        fontWeight: 700,
        ...withEllipsis,
      }}
    >
      {title}
    </Typography>
  );
}
