import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { setBulkActionName } from "@store/table/table-slice";
import { useEffect, useState } from "react";
import { BulkSuccessDialog } from "@components/common/faro-table/bulk-dialog/bulk-success-dialog";
import {
  bulkActionNameSelector,
  selectedEntitiesSelector,
  shouldShowSuccessDialogSelector,
} from "@store/table/table-selector";
import { BulkActionEvents } from "@utils/track-event/track-event-list";
import { useCoreApiClient } from "@api/use-core-api-client";
import { SdbProject } from "@custom-types/project-types";
import { useChangeProjectManagementBulkDialogContent } from "@components/table/projects/bulk-actions/bulk-change-project-management-helper";
import { updateProjectDetails } from "@store/projects/projects-slice-thunk";
import { FaroTextButton } from "@components/common/faro-text-button";
import { GroupAndProjectManagerSelection } from "@pages/project-details/project-overview/change-management/group-and-project-manager-selection";
import { SelectedMember } from "@components/common/member-autocomplete/member-autocomplete-selection";
import { isObject } from "lodash";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { FaroBulkDialog } from "@components/common/faro-table/bulk-dialog/bulk-dialog";

/** Button allowing to change management of project in company */
export function BulkChangeProjectManagement({
  companyId,
}: BaseCompanyIdProps): JSX.Element {
  const [shouldShowDialog, setShouldShowDialog] = useState<boolean>(false);
  const [payload, setPayload] =
    useState<SphereDashboardAPITypes.IUpdateProjectPayload>({});
  const [selectedGroupId, setSelectedGroupId] = useState<string>("");
  const [selectedProjectManager, setSelectedProjectManager] =
    useState<SelectedMember>(null);

  const bulkActionName = useAppSelector(bulkActionNameSelector);

  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const shouldShowSuccessDialog = useAppSelector(
    shouldShowSuccessDialogSelector
  );
  const selectedEntities = useAppSelector(selectedEntitiesSelector("projects"));

  const { bulkDialogContent, successDialogContent } =
    useChangeProjectManagementBulkDialogContent();

  // Closes the bulk dialog when the bulk action is successful to show the success dialog
  useEffect(() => {
    if (shouldShowSuccessDialog) {
      setShouldShowDialog(false);
    }
  }, [shouldShowSuccessDialog]);

  // Set the payload when the selected group or project manager changes
  useEffect(() => {
    if (!companyId) {
      throw new Error("Company id is required!");
    }

    if (selectedProjectManager === null || selectedGroupId === "") {
      setPayload({});
    } else {
      setPayload({
        projectManagerIdentity: isObject(selectedProjectManager)
          ? selectedProjectManager.identity
          : selectedProjectManager,
        groupId: selectedGroupId,
      });
    }
  }, [companyId, selectedGroupId, selectedProjectManager]);

  function onNewGroupClicked(): void {
    dispatch(setBulkActionName("changeProjectManagement"));

    setShouldShowDialog(true);
  }

  return (
    <>
      <FaroTextButton
        onClick={onNewGroupClicked}
        tooltipText="Change project management"
        sx={{ fontWeight: 600 }}
      >
        Change management
      </FaroTextButton>

      {/* Checking for bulkActionName to make sure the dialog of another bulk action is not opened by mistake */}
      {bulkActionName === "changeProjectManagement" &&
        shouldShowSuccessDialog && (
          <BulkSuccessDialog dialogContent={successDialogContent} />
        )}

      {bulkActionName === "changeProjectManagement" && shouldShowDialog && (
        <FaroBulkDialog
          dialogContent={bulkDialogContent}
          onClose={() => setShouldShowDialog(false)}
          trackingEvent={BulkActionEvents.bulkChangeGroup}
          bulkActionCallback={(project: SdbProject) =>
            dispatch(
              updateProjectDetails({
                coreApiClient,
                projectId: project.id,
                payload,
              })
            ).unwrap()
          }
          shouldDisableConfirmButton={Object.keys(payload).length === 0}
          selectedEntities={selectedEntities}
          uniqueIdKey="id"
          tableDataType="projects"
        >
          <GroupAndProjectManagerSelection
            selectedGroupId={selectedGroupId}
            setSelectedGroupId={setSelectedGroupId}
            setSelectedProjectManager={setSelectedProjectManager}
            selectedProjectManager={selectedProjectManager}
          />
        </FaroBulkDialog>
      )}
    </>
  );
}
