import { APITypes } from "@stellar/api-logic";
import { PageSection, PageSectionProps } from "@components/page/page-section";
import { Grid } from "@mui/material";
import { useMediaQueryList } from "@hooks/use-media-query";
import { Feature2DPhotos } from "@pages/project-details/project-settings/feature-2d-photos";
import { FeatureVideoMode } from "@pages/project-details/project-settings/feature-video-mode";
import { FeatureFaceBlurring } from "@pages/project-details/project-settings/feature-face-blurring";
import { useAppSelector } from "@store/store-helper";
import {
  fetchingProjectsFlagsSelector,
  selectedProjectSelector,
} from "@store/projects/projects-selector";
import { useHasUserValidRoleProjectLevel } from "@hooks/access-control/use-has-user-valid-role-project-level";

interface Props {
  /** The ID of the project to display in the background tasks tab */
  projectId?: APITypes.ProjectId;

  /** Whether the project is still loading and skeleton should be shown instead */
  isLoading: boolean;
}

/** Renders the settings tab in project details page */
export function ProjectSettings({ projectId, isLoading }: Props): JSX.Element {
  const { isScreenXsAndSmall } = useMediaQueryList();
  const selectedProject = useAppSelector(selectedProjectSelector);
  const { isFetchingProjectContext } = useAppSelector(
    fetchingProjectsFlagsSelector
  );
  const {
    canView2dImagesFeature,
    canViewVideoModeFeature,
    canViewFaceBlurringFeature,
  } = useHasUserValidRoleProjectLevel({
    selectedProject,
  });

  const pageSectionsProps: PageSectionProps[] = [
    {
      title: "Project Features",
      subtitle: `These are the available features for your project. 
      Adjust them here to fit your needs by using the toggles.`,
      isLoading: isLoading || isFetchingProjectContext,
      items: [
        {
          shouldContentTakeFullWidth: true,
          shouldLabelBold: true,
          shouldAddRightPadding: true,
          isHidden: !canView2dImagesFeature,
          content: <Feature2DPhotos projectId={projectId ?? ""} />,
        },
        {
          shouldContentTakeFullWidth: true,
          shouldAddRightPadding: true,
          shouldLabelBold: true,
          isHidden: !canViewVideoModeFeature,
          content: <FeatureVideoMode projectId={projectId ?? ""} />,
        },
        {
          shouldContentTakeFullWidth: true,
          shouldLabelBold: true,
          shouldAddRightPadding: true,
          isHidden: !canViewFaceBlurringFeature,
          content: <FeatureFaceBlurring projectId={projectId ?? ""} />,
        },
      ],
    },
  ];

  // Filter out the setting sections that should be hidden
  const filteredPageSectionProps = pageSectionsProps.filter(
    (pageSectionProps) => !pageSectionProps.isHidden
  );

  return (
    <Grid data-testid="WORKSPACE_SETTING_CONTAINER" mt="45px">
      {filteredPageSectionProps.map((sectionProps, index) => (
        <PageSection
          key={`settings-section-wrapper-${index}`}
          isLoading={isLoading}
          isScreenXsAndSmall={isScreenXsAndSmall}
          isTopSection={index === 0}
          isLastSection={index === filteredPageSectionProps.length - 1}
          {...sectionProps}
        />
      ))}
    </Grid>
  );
}
