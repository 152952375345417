import { useAppSelector } from "@store/store-helper";
import {
  isProjectArchiveSelector,
  selectedProjectSelector,
} from "@store/projects/projects-selector";
import { FaroTabs } from "@faro-lotv/flat-ui";
import { ProjectDataManagementType } from "@custom-types/project-data-management-types";
import { useAppNavigation } from "@hooks/use-app-navigation";
import { QueryParams } from "@router/route-params";
import { FaroButtonSpinner } from "@components/common/button/faro-button-spinner";
import { sphereColors } from "@styles/common-colors";
import {
  elsLatestPublishedDataSelector,
  rawScansSelector,
  hasRegistrationsPreparingSelector,
} from "@store/capture-tree/capture-tree-selectors";
import {
  hasRawScansProcessingSelector,
  preparedRegistrationRevisionsSelector,
} from "@store/capture-tree/capture-tree-selectors";
import { PROJECT_DATA_MANAGEMENT_LABELS } from "@src/constants/project-data-management-constants";

interface Props {
  /** Initial data management type to display */
  initialDataType: string;

  /** The set to change the tab to display */
  setSelectedTab: (tab: ProjectDataManagementType) => void;
}

/** Renders project data management tabs */
export function DataManagementTabs({
  initialDataType,
  setSelectedTab,
}: Props): JSX.Element {
  const { setUrlParam } = useAppNavigation();

  const selectedProject = useAppSelector(selectedProjectSelector);
  isProjectArchiveSelector(selectedProject?.id ?? "");

  const rawData = useAppSelector(rawScansSelector);
  const hasRawScansProcessing = useAppSelector(hasRawScansProcessingSelector);
  const hasRegistrationsPreparing = useAppSelector(
    hasRegistrationsPreparingSelector
  );

  const preparedData = useAppSelector(preparedRegistrationRevisionsSelector);
  const publishedData = useAppSelector(elsLatestPublishedDataSelector);

  const items = [
    {
      value: ProjectDataManagementType.rawData,
      title: PROJECT_DATA_MANAGEMENT_LABELS[ProjectDataManagementType.rawData],
      description: rawData.length.toString(),
      icon: hasRawScansProcessing ? (
        <FaroButtonSpinner loadingTrackColor={sphereColors.gray200} />
      ) : undefined,
    },
    {
      value: ProjectDataManagementType.preparedData,
      title:
        PROJECT_DATA_MANAGEMENT_LABELS[ProjectDataManagementType.preparedData],
      description: preparedData.length.toString(),
      icon: hasRegistrationsPreparing ? (
        <FaroButtonSpinner loadingTrackColor={sphereColors.gray200} />
      ) : undefined,
    },
    {
      value: ProjectDataManagementType.publishedData,
      title:
        PROJECT_DATA_MANAGEMENT_LABELS[ProjectDataManagementType.publishedData],
      description: publishedData.length.toString(),
    },
  ];

  /**
   * Sets the current data type.
   * It also sets the "type" query param in order to have a sharable deep link with the currently displayed data type.
   */
  function handleTabChange(newTabSelected: ProjectDataManagementType): void {
    setSelectedTab(newTabSelected);

    setUrlParam({
      key: QueryParams.type,
      value: newTabSelected,
    });
  }

  return (
    <FaroTabs
      items={items}
      initialValue={initialDataType}
      onTabChange={handleTabChange}
      sx={{
        padding: "10px",
        border: `1px solid ${sphereColors.gray200}`,
        borderRadius: 0,
        background: "white",
        "& [role='determinate-progress'] circle, .MuiButtonBase-root:hover [role='determinate-progress'] circle":
          {
            color: "#E0E4EB",
          },
      }}
    />
  );
}
