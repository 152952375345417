import { useCallback } from "react";
import { SidePanel } from "@components/sidepanel/sidepanel";
import { sphereColors } from "@styles/common-colors";
import {
  MarkupsSidePanelHeader,
  MarkupsSidePanelInfo,
  MarkupsSidePanelAttachments,
} from "@pages/project-details/project-markups/sidepanel";
import { useMediaQuery, useTheme } from "@mui/material";
import { StickySidePanelContainer } from "@components/sidepanel/sticky-sidepanel-container";
import { useMarkupContext } from "@context-providers/markup/markup-context";

/**
 * Component representing a side panel for displaying details of a selected markup.
 * It allows users to view information about a specific markup within a project.
 *
 * @returns {JSX.Element} A JSX element representing the markup side panel
 */
export function MarkupSidePanel(): JSX.Element {
  const theme = useTheme();
  const isScreenXs = useMediaQuery(theme.breakpoints.only("xs"));

  const {
    updateSelectedMarkup,
    isOpenMarkupSidePanel,
    updateIsSidePanelOpen,
    selectedMarkup,
    isMarkupUpdateInProgress,
    activeTab,
    updateActiveTab,
  } = useMarkupContext();

  const isUpdateInProgress = isMarkupUpdateInProgress();

  /**
   * Callback function to close the side panel.
   * This function is used to close the markup side panel by setting the state to indicate that it is not open
   * and resetting the selected markup to `undefined`.
   */
  const closeSidePanel = useCallback(() => {
    // Restrict closing the sidebar if there is any update is ongoing
    if (isUpdateInProgress) {
      return;
    }

    updateActiveTab("Info");
    updateIsSidePanelOpen(false);
    updateSelectedMarkup(undefined);
  }, [
    isUpdateInProgress,
    updateActiveTab,
    updateIsSidePanelOpen,
    updateSelectedMarkup,
  ]);

  return (
    <SidePanel
      isOpen={isOpenMarkupSidePanel}
      anchor={"right"}
      closeSidePanel={closeSidePanel}
      sx={{
        "& .MuiDrawer-paper": {
          boxShadow: "none",
          borderLeft: `1px solid ${sphereColors.gray200}`,
          width: isScreenXs ? "100%" : "430px",
          paddingX: "20px",
          paddingBottom: "16px",
        },
        "& .MuiModal-backdrop": {
          backgroundColor: "transparent",
        },
      }}
    >
      <StickySidePanelContainer
        header={
          selectedMarkup && (
            <MarkupsSidePanelHeader
              markup={selectedMarkup}
              closeSidePanel={closeSidePanel}
            />
          )
        }
        selectedTab={activeTab}
        tabs={[
          {
            label: "Info",
            isDisabled: isUpdateInProgress,
            content: () =>
              selectedMarkup && (
                <MarkupsSidePanelInfo markup={selectedMarkup} />
              ),
          },
          {
            label: "Attachments",
            isDisabled: isUpdateInProgress,
            content: () =>
              selectedMarkup && (
                <MarkupsSidePanelAttachments markup={selectedMarkup} />
              ),
          },
        ]}
      />
    </SidePanel>
  );
}
