import { clearStore } from "@faro-lotv/project-source";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

/**
 * SdbIElementsState is an extra store state that expands the IElementsState.
 */
interface SdbIElementsState {
  hasFetchedPointCloudsIElements: boolean;
  hasFetchedMarkupsIElements: boolean;
}

const initialState: SdbIElementsState = Object.freeze({
  hasFetchedPointCloudsIElements: false,
  hasFetchedMarkupsIElements: false,
});

const sdbIElementsSlice = createSlice({
  name: "sdbIElements",
  initialState,
  reducers: {
    setHasFetchedPointCloudsIElements(state, action: PayloadAction<boolean>) {
      state.hasFetchedPointCloudsIElements = action.payload;
    },
    setHasFetchedMarkupsIElements(state, action: PayloadAction<boolean>) {
      state.hasFetchedMarkupsIElements = action.payload;
    },
  },

  extraReducers(builder) {
    builder
      // Use the clearStore action from the lotv package so that upon calling clearStore it wil
      // clear both the IElementsState and the SdbIElementsState
      .addCase(clearStore, () => initialState);
  },
});

export const {
  setHasFetchedPointCloudsIElements,
  setHasFetchedMarkupsIElements,
} = sdbIElementsSlice.actions;

export const sdbIElementsReducer = sdbIElementsSlice.reducer;
