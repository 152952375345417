import { Markup, MarkupsKPIs } from "@custom-types/project-markups-types";
import {
  isExternalMarkupIElement,
  isIElementMarkupImage,
  isIElementMarkupWithTypeHint,
} from "@custom-types/i-elements-type-guards";
import {
  ExternalMarkupIElement,
  IElementAttachment,
  IElementDateTimeMarkupField,
  IElementDropDownMarkupField,
  IElementImageCommand,
  IElementImg2d,
  IElementImg360,
  IElementImgCube,
  IElementModel3D,
  IElementSection,
  IElementUserDirectoryMarkupField,
  isIElementAreaSection,
  isIElementImageCommand,
  isIElementMarkupAssignee,
  isIElementMarkupDueTime,
  isIElementMarkupState,
  isIElementModel3d,
  isIElementPanoramaImage,
  isIElementSection,
  isIElementAttachment,
} from "@faro-lotv/ielement-types";
import {
  selectAllIElementsOfType,
  selectAncestor,
  selectChildDepthFirst,
  selectIElementPanoramaElement,
} from "@faro-lotv/project-source";
import { RootState } from "@store/store-helper";
import {
  findSlideNodeAndSceneObject,
  isMarkupInProgress,
  isMarkupInReview,
  isMarkupOverdue,
  isMarkupResolved,
  isMarkupToDo,
  isMarkupUnclassified,
  sortMarkups,
} from "@utils/markups-utils";
import {
  projectIdSelector,
  slideNodesSelector,
} from "@store/slide-nodes/slide-nodes-selector";
import { getSceneObjectWebEditorUrl } from "@utils/web-editor-utils";
import { selectRootExternalId } from "@store/i-elements/i-elements-selectors";
import { getSphereViewerUrl } from "@utils/project-utils";
import {
  IElementAreaSection,
  IElementMarkupWithTypeHint,
} from "@custom-types/i-elements-types";

export function getMarkups(state: RootState): Markup[] {
  // Get all markup elements
  const markups = selectAllIElementsOfType(isIElementMarkupWithTypeHint)(state);
  const attachments = selectAllIElementsOfType(isIElementAttachment)(state);

  const extendedMarkups: Markup[] = markups.map((markup) => {
    return {
      ...markup,
      assignee: getAssignee(state, markup),
      dueDate: getDueDate(state, markup),
      status: getStatus(state, markup),
      image: getImage(state, markup),
      model3d: getModel3d(state, markup),
      section: getSection(state, markup),
      areaSection: getAreaSection(state, markup),
      pano: getPano(state, markup),
      externalMarkup: getExternalMarkup(state, markup),
      sphereViewerUrl: getMarkupSphereViewerUrl(state, markup),
      webEditorUrl: getMarkupWebEditorUrl(state, markup),
      attachments: getAttachments(markup, attachments),
    };
  });

  // Return markups sorted
  return sortMarkups(extendedMarkups);
}

/** Gets all the attachments for specific markup */
function getAttachments(
  markup: IElementMarkupWithTypeHint,
  attachments: IElementAttachment[]
): IElementAttachment[] {
  return attachments
    .filter(
      (attachment: IElementAttachment) =>
        attachment.parentId && markup.childrenIds?.includes(attachment.parentId)
    )
    .sort(
      (attachmentA, attachmentB) =>
        new Date(attachmentB.createdAt).getTime() -
        new Date(attachmentA.createdAt).getTime()
    );
}

/** Gets the markup assignee element associated to the markup */
function getAssignee(
  state: RootState,
  markup: IElementMarkupWithTypeHint
): IElementUserDirectoryMarkupField | undefined {
  return selectChildDepthFirst(markup, isIElementMarkupAssignee)(state);
}

/** Gets the markup dueDate element associated to the markup */
function getDueDate(
  state: RootState,
  markup: IElementMarkupWithTypeHint
): IElementDateTimeMarkupField | undefined {
  return selectChildDepthFirst(markup, isIElementMarkupDueTime)(state);
}

/** Gets the markup status element associated to the markup */
function getStatus(
  state: RootState,
  markup: IElementMarkupWithTypeHint
): IElementDropDownMarkupField | undefined {
  return selectChildDepthFirst(markup, isIElementMarkupState)(state);
}

/** Gets the 2d image element associated to the markup */
function getImage(
  state: RootState,
  markup: IElementMarkupWithTypeHint
): IElementImg2d | IElementImageCommand | undefined {
  let image: IElementImg2d | IElementImageCommand | undefined =
    selectChildDepthFirst(markup, isIElementMarkupImage)(state);

  // Get external markup associated to markup
  const externalMarkup = getExternalMarkup(state, markup);

  // If the markup image element is undefined and if it's an external markup
  // then attempt to get the image from the associated external markup
  if (!image && externalMarkup) {
    image = selectChildDepthFirst(
      externalMarkup,
      isIElementImageCommand
    )(state);
  }

  return image;
}

/** Gets the Model3d element associated to the markup */
function getModel3d(
  state: RootState,
  markup: IElementMarkupWithTypeHint
): IElementModel3D | undefined {
  return selectAncestor(markup, isIElementModel3d)(state);
}

/** Gets the section element associated to the markup */
function getSection(
  state: RootState,
  markup: IElementMarkupWithTypeHint
): IElementSection | undefined {
  return selectAncestor(markup, isIElementSection)(state);
}

/** Gets the area section element associated to the markup */
function getAreaSection(
  state: RootState,
  markup: IElementMarkupWithTypeHint
): IElementAreaSection | undefined {
  return selectAncestor(markup, isIElementAreaSection)(state);
}

/** Gets the pano element associated to the markup */
function getPano(
  state: RootState,
  markup: IElementMarkupWithTypeHint
): IElementImg360 | IElementImgCube | undefined {
  // Get section associated to markup
  const section = getSection(state, markup);

  if (section) {
    // Get pano associated to section
    const pano = selectIElementPanoramaElement(section.id)(state);

    if (pano && isIElementPanoramaImage(pano)) {
      return pano;
    }
  }
}

/** Gets the external markup element associated to the markup */
function getExternalMarkup(
  state: RootState,
  markup: IElementMarkupWithTypeHint
): ExternalMarkupIElement | undefined {
  // Get associated Model3d element
  const model3d = getModel3d(state, markup);

  // Get external markup element (procore or bim360) associated to the Model3d element
  return selectChildDepthFirst(model3d, isExternalMarkupIElement)(state);
}

/** Gets the WebEditor URL of the markup */
function getMarkupWebEditorUrl(
  state: RootState,
  markup: IElementMarkupWithTypeHint
): string | undefined {
  // Find the slide node and the scene object associated to the markup
  const slideNodes = slideNodesSelector(state);
  const model3d = getModel3d(state, markup);

  const slideNodeAndSceneObject = findSlideNodeAndSceneObject(
    slideNodes,
    markup,
    model3d
  );
  const projectId = projectIdSelector(state);

  if (!slideNodeAndSceneObject || !projectId) {
    return;
  }

  return getSceneObjectWebEditorUrl({
    projectId,
    ...slideNodeAndSceneObject,
  });
}

/** Gets the Sphere Viewer URL of the markup */
function getMarkupSphereViewerUrl(
  state: RootState,
  markup: IElementMarkupWithTypeHint
): string | undefined {
  const projectId = selectRootExternalId(state);
  const lookAtId = markup.id;

  if (!projectId) {
    return;
  }

  return getSphereViewerUrl({
    projectId,
    lookAtId,
  }).href;
}

/** Gets the markups KPIs */
export function getMarkupsKPIs(markups: Markup[]): MarkupsKPIs {
  const markupsSummary = {
    total: markups.length,
    toDo: 0,
    inProgress: 0,
    inReview: 0,
    resolved: 0,
    unclassified: 0,
    overdue: 0,
  };
  markups.forEach((markup) => {
    if (isMarkupToDo(markup)) {
      markupsSummary.toDo++;
    } else if (isMarkupInProgress(markup)) {
      markupsSummary.inProgress++;
    } else if (isMarkupInReview(markup)) {
      markupsSummary.inReview++;
    } else if (isMarkupResolved(markup)) {
      markupsSummary.resolved++;
    } else if (isMarkupUnclassified(markup)) {
      markupsSummary.unclassified++;
    }

    // We do not use else-if because there are cases that a markup can be in multiple states,
    // for example, it can be in review and overdue at the same time.
    if (isMarkupOverdue(markup)) {
      markupsSummary.overdue++;
    }
  });
  return markupsSummary;
}
