import { IElementDateTimeMarkupField } from "@faro-lotv/ielement-types";
import {
  MutationAddMarkupField,
  MutationDateTimeMarkupField,
  MutationDeleteElement,
} from "@faro-lotv/service-wires";

// Type guard for MutationDateTimeMarkupField
export function isMutationDateTimeMarkupField(
  mutation:
    | MutationDeleteElement
    | MutationDateTimeMarkupField
    | MutationAddMarkupField<IElementDateTimeMarkupField>
): mutation is MutationDateTimeMarkupField {
  return (mutation as MutationDateTimeMarkupField).dateTime !== undefined;
}
