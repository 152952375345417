import { RootState } from "@store/store-helper";
import { createSelector } from "@reduxjs/toolkit";
import { AnalyticsState } from "@store/analytics/analytics-slice";
import { SdbProject } from "@custom-types/project-types";
import { APITypes, DashboardAPITypes } from "@stellar/api-logic";
import {
  AnalyticsChartData,
  ProjectChartType,
} from "@custom-types/analytics-types";
import { getChartsData } from "@utils/analytics-utils";
import { TimeFrameItem } from "@utils/time-utils";
import { selectedProjectIdSelector } from "@store/projects/projects-selector";

/**
 * Returns the selected project.
 */
export const selectedAnalyticsProjectSelector: (
  state: RootState
) => SdbProject | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const selectedProjectId = state.analytics.selectedAnalyticsProjectId;
    if (!selectedProjectId) {
      return null;
    }
    return state.projects.entities[selectedProjectId] ?? null;
  }
);

/**
 * Returns the selected time frame.
 */
export const selectedAnalyticsTimeFrameSelector: (
  state: RootState
) => RootState["analytics"]["selectedAnalyticsTimeFrame"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.analytics.selectedAnalyticsTimeFrame;
  }
);

/** Returns the company stats */
export const companyStatsSelector: (
  state: RootState
) => DashboardAPITypes.ICompanyStatistic[] | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.analytics.companyStats;
  }
);

/**
 * Returns true if the current stats are company level (all projects)
 * or false if the current stats are project level
 */
export const isCompanyStatsSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return selectedAnalyticsProjectSelector(state) === null;
    }
  );

/** Returns the charts data that will be rendered as charts*/
export const chartsDataSelector: (state: RootState) => AnalyticsChartData[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const timeFrame = selectedAnalyticsTimeFrameSelector(state);
      let stats: DashboardAPITypes.ICompanyStatistic[] | null;

      // If selected project ID is null then use the company stats
      // If selected project ID is defined then use the selected project stats
      if (state.analytics.selectedAnalyticsProjectId === null) {
        stats = companyStatsSelector(state);
      } else {
        stats = selectedProjectStatsSelector(state);
      }

      return getChartsData({
        timeFrame,
        stats,
      });
    }
  );

/**
 * Returns the stats for the provided project
 */
export function projectStatsSelector(
  projectId: APITypes.ProjectId
): (state: RootState) => DashboardAPITypes.ICompanyStatistic[] | null {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.analytics.projectStats[projectId] ?? null;
    }
  );
}

/**
 * Returns the stats for the selected project
 */
export const selectedProjectStatsSelector: (
  state: RootState
) => DashboardAPITypes.ICompanyStatistic[] | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const projectId = state.analytics.selectedAnalyticsProjectId;
    if (!projectId) {
      return null;
    }
    return projectStatsSelector(projectId)(state);
  }
);

/**
 * Returns the fetching properties of the analytics slice.
 */
export const fetchingAnalyticsFlagsSelector: (
  state: RootState
) => AnalyticsState["fetching"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.analytics.fetching;
  }
);

/**
 * Returns the selected chart for the current selected project
 */
export const projectChartSelector: (
  state: RootState
) => AnalyticsChartData | undefined = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const projectId = selectedProjectIdSelector(state);

    if (!projectId) {
      return undefined;
    }

    const stats = projectStatsSelector(projectId)(state);
    const timeFrame = selectedProjectChartTimeFrameSelector(state);
    const charts = getChartsData({ timeFrame, stats });
    const chartType = selectedProjectChartTypeSelector(state);
    const selectedChart = charts.find((chart) => chart.name === chartType);

    return selectedChart;
  }
);

/**
 * Returns the selected project chart type
 */
export const selectedProjectChartTimeFrameSelector: (
  state: RootState
) => TimeFrameItem["value"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.analytics.projectOverview.selectedTimeFrame;
  }
);

/**
 * Returns the selected project chart type
 */
export const selectedProjectChartTypeSelector: (
  state: RootState
) => ProjectChartType = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.analytics.projectOverview.selectedChartType;
  }
);
