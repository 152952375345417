import { userInfoHeight, userInfoWidth } from "@components/user-info/user-info";
import { Box, Skeleton, useMediaQuery, useTheme } from "@mui/material";
import { useMemo } from "react";

/** Number of rows to show in the left side */
const NUMBER_OF_ROWS = 4;

/**
 * A skeleton loader for the member profile page
 */
export function MemberProfileSkeleton(): JSX.Element {
  const theme = useTheme();
  const isScreenMdAndLarger = useMediaQuery(theme.breakpoints.up("md"));
  const isScreenSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isScreenXs = useMediaQuery(theme.breakpoints.only("xs"));

  const infoBarHeight = useMemo(() => {
    if (isScreenMdAndLarger) {
      return "80px";
    }

    if (isScreenSm) {
      return "120px";
    }

    if (isScreenXs) {
      return "174px";
    }
  }, [isScreenMdAndLarger, isScreenSm, isScreenXs]);

  return (
    <Box component="div" sx={{ mt: "45px" }}>
      {/* Info bar */}
      <Skeleton variant="rectangular" width="100%" height={infoBarHeight} />

      {/* Content */}
      <Box
        component="div"
        sx={{
          display: "flex",
          flexFlow: isScreenMdAndLarger ? "row" : "column-reverse",
          alignItems: isScreenMdAndLarger ? "baseline" : "center",
          pt: isScreenMdAndLarger ? "15px" : "45px",
        }}
      >
        {/* Left side rows */}
        <Box component="div" width="100%">
          {[...Array(NUMBER_OF_ROWS)].map((e, index) => (
            <Box
              component="div"
              key={index}
              sx={{
                pt: "37px",
                pr: "20px",
              }}
            >
              <Skeleton variant="rectangular" height="54px" />
            </Box>
          ))}
        </Box>

        {/* Right side component */}
        <Skeleton
          variant="rectangular"
          width={userInfoWidth}
          height={userInfoHeight}
          sx={{
            minWidth: userInfoWidth,
          }}
        />
      </Box>
    </Box>
  );
}
