import { useCoreApiClient } from "@api/use-core-api-client";
import { Box, Grid } from "@mui/material";
import { APITypes } from "@stellar/api-logic";
import {
  fetchingSnapshotsFlagsSelector,
  projectSnapshotsSelector,
} from "@store/snapshots/snapshots-selector";
import { fetchProjectSnapshots } from "@store/snapshots/snapshots-slice";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { useEffect } from "react";
import { CollapsibleSnapshot } from "@pages/project-details/project-snapshots/collapsible-snapshot";
import { SnapshotSkeleton } from "@pages/project-details/project-snapshots/snapshot-skeleton";
import { EmptyPage } from "@components/common/empty-page/empty-page";
import SnapshotsIcon from "@assets/icons/new/snapshots_61px.svg?react";

interface Props {
  /** The ID of the project to display its snapshots */
  projectId?: APITypes.ProjectId;

  /** Determines if some higher content is being fetched, for example the project context */
  isLoading?: boolean;
}

/** The number of skeletons to show when there are no snapshots. */
const NUMBER_OF_SKELETONS = 4;

/** The margin top for the content. */
const MARGIN_TOP = "12px";

/**
 * Shows all the snapshots that the user has access to.
 */
export function ProjectSnapshots({ projectId, isLoading }: Props): JSX.Element {
  const coreApiClient = useCoreApiClient();
  const dispatch = useAppDispatch();
  const projectSnapshots = useAppSelector(projectSnapshotsSelector(projectId));
  const { isFetchingSnapshots } = useAppSelector(
    fetchingSnapshotsFlagsSelector
  );

  // Fetch snapshots when the project ID changes
  useEffect(() => {
    if (projectId) {
      dispatch(fetchProjectSnapshots({ coreApiClient, projectId }));
    }
  }, [coreApiClient, dispatch, projectId]);

  /**
   * The content to display in the page, either loading skeletons,
   * an empty page or the snapshots.
   */
  let content: JSX.Element[] | JSX.Element | null = null;

  if (isFetchingSnapshots || isLoading) {
    content = (
      <Box sx={{ marginTop: MARGIN_TOP }}>
        {Array.from({ length: NUMBER_OF_SKELETONS }).map((_, index) => (
          <SnapshotSkeleton key={`skeleton-${index}`} />
        ))}
      </Box>
    );
  } else if (!projectSnapshots?.length) {
    content = (
      <EmptyPage
        title="You don’t have any SnapShots yet"
        subtitle="To create a SnapShot, open the project in WebEditor and select SnapShots in the share menu."
        icon={SnapshotsIcon}
      />
    );
  } else {
    content = (
      <Box sx={{ marginTop: MARGIN_TOP }}>
        {projectSnapshots?.map((snapshot) => (
          <CollapsibleSnapshot key={snapshot.id} snapshot={snapshot} />
        ))}
      </Box>
    );
  }

  return (
    <Grid container sx={{ width: "100%" }}>
      <Grid item width="100%">
        {content}
      </Grid>
    </Grid>
  );
}
