import HideIcon from "@assets/icons/new/hide_24px.svg?react";
import ShowIcon from "@assets/icons/new/show_24px.svg?react";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { sphereColors } from "@styles/common-colors";

interface Props {
  /** Whether password is visible or hidden */
  isVisible: boolean;

  /** Callback when button is clicked */
  onClick: () => void;
}

/** Renders the button to show or hide the password input */
export function PasswordInputButton({
  isVisible,
  onClick,
}: Props): JSX.Element {
  return (
    <FaroIconButton
      component={isVisible ? HideIcon : ShowIcon}
      onClick={onClick}
      color={sphereColors.gray400}
      buttonSize="24px"
      iconSize="18px"
    />
  );
}
