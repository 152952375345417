import { CircularProgress, Switch, SwitchProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { sphereColors } from "@styles/common-colors";
import { border } from "@utils/ui-utils";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { useMemo } from "react";

/** Defines the diameter of the thumb (the circle inside the switch) */
const THUMB_DIAMETER = "16px";

const DISABLED_THUMB_BORDER_COLOR = sphereColors.gray400;
const DISABLED_TRACK_BACKGROUND_COLOR = sphereColors.gray100;
const DISABLED_ACTIVE_THUMB_BORDER_COLOR = sphereColors.blue300;
const DISABLED_ACTIVE_TRACK_BACKGROUND_COLOR = sphereColors.blue300;

interface FaroSwitchProps extends SwitchProps {
  /** True if a loader should show for the switch */
  isLoading?: boolean;

  /** Optional text to display when switch is not checked */
  tooltipTextNotChecked?: string;

  /** Optional text to display when switch is checked */
  tooltipTextChecked?: string;
}

/**
 * Custom switch component that uses the MUI Switch component.
 * It is used to show a switch with a custom style.
 */

export const FaroSwitch = styled((props: FaroSwitchProps) => {
  // Remove FaroSwitchProps from props to provide to the Switch component
  // If not removed the SwitchProps component will not recognized those props and throw an error
  const {
    isLoading,
    tooltipTextNotChecked,
    tooltipTextChecked,
    ...switchProps
  } = props;

  // eslint-disable-next-line @typescript-eslint/naming-convention -- MUI naming
  const { disabled, checked } = props;

  const tooltipText = useMemo(() => {
    if (!disabled) {
      if (checked && tooltipTextChecked) {
        return tooltipTextChecked;
      }

      if (!checked && tooltipTextNotChecked) {
        return tooltipTextNotChecked;
      }
    }

    return undefined;
  }, [checked, disabled, tooltipTextChecked, tooltipTextNotChecked]);

  const shouldShowTooltip = useMemo(() => {
    if (!disabled) {
      if (checked && tooltipTextChecked) {
        return true;
      }

      if (!checked && tooltipTextNotChecked) {
        return true;
      }
    }

    return false;
  }, [checked, disabled, tooltipTextChecked, tooltipTextNotChecked]);

  return (
    <>
      <SphereTooltip title={tooltipText} shouldShowTooltip={shouldShowTooltip}>
        <Switch
          focusVisibleClassName=".Mui-focusVisible"
          disableRipple
          {...switchProps}
        />
      </SphereTooltip>
      {props.isLoading && (
        <CircularProgress
          sx={{
            marginLeft: "5px",
          }}
          size={14}
        />
      )}
    </>
  );
})(() => ({
  width: `calc(${THUMB_DIAMETER} * 2)`,
  height: THUMB_DIAMETER,
  padding: 0,
  display: "flex",

  "& .MuiSwitch-switchBase": {
    // Disable padding in the switch so that the circle is inline
    padding: 0,
  },

  // Style for the circle when switch is checked
  "& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked": {
    // Defines how much should the circle move in the track
    transform: `translateX(${THUMB_DIAMETER})`,
  },

  // Style for track (the switch itself without the circle)
  "& .MuiSwitch-track": {
    borderRadius: "30px",
    boxSizing: "border-box",
  },

  // Style for thumb (the circle)
  "& .MuiSwitch-thumb": {
    width: THUMB_DIAMETER,
    height: THUMB_DIAMETER,
    borderRadius: "50%",
    // Remove all extra styles from the thumb
    boxShadow: "none",
  },

  // Styles when switch is not disabled
  "& .MuiSwitch-switchBase:not(.Mui-disabled)": {
    // Style for thumb when switch is not checked and not disabled
    "& .MuiSwitch-thumb": {
      opacity: 1,
      border: border(sphereColors.gray400),
      backgroundColor: sphereColors.pureWhite,
    },

    // Style for track when switch is not checked and not disabled
    "&+.MuiSwitch-track": {
      opacity: 1,
      backgroundColor: sphereColors.gray200,
      border: border(sphereColors.gray300),
    },

    // Style when switch is checked
    "&.Mui-checked": {
      // Style for thumb when switch is checked and not disabled
      "& .MuiSwitch-thumb": {
        opacity: 1,
        border: border(sphereColors.blue700),
        backgroundColor: sphereColors.pureWhite,
      },

      // Style for track when switch is checked and not disabled
      "&+.MuiSwitch-track": {
        opacity: 1,
        backgroundColor: sphereColors.blue500,
        border: border(sphereColors.blue600),
      },
    },
  },

  // Hover style for the track when switch is not disabled and not checked
  "& .MuiSwitch-switchBase:not(.Mui-disabled):hover+.MuiSwitch-track": {
    backgroundColor: sphereColors.gray300,
    border: border(sphereColors.gray300),
  },

  // Hover style for the track when switch is not disabled and checked
  "& .MuiSwitch-switchBase.Mui-checked:not(.Mui-disabled):hover+.MuiSwitch-track":
    {
      backgroundColor: sphereColors.blue600,
      border: border(sphereColors.blue600),
    },

  // Styles when switch is disabled
  "& .MuiSwitch-switchBase.Mui-disabled": {
    // Style for thumb when switch is not checked and disabled
    "& .MuiSwitch-thumb": {
      opacity: 1,
      border: border(DISABLED_THUMB_BORDER_COLOR),
      backgroundColor: sphereColors.pureWhite,
    },

    // Style for track when switch is not checked and disabled
    "&+.MuiSwitch-track": {
      opacity: 1,
      backgroundColor: DISABLED_TRACK_BACKGROUND_COLOR,
      border: border(DISABLED_TRACK_BACKGROUND_COLOR),
    },

    // Style when switch is checked
    "&.Mui-checked": {
      // Style for thumb when switch is checked and disabled
      "& .MuiSwitch-thumb": {
        opacity: 1,
        border: border(DISABLED_ACTIVE_THUMB_BORDER_COLOR),
        backgroundColor: sphereColors.pureWhite,
      },

      // Style for track when switch is checked and disabled
      "&+.MuiSwitch-track": {
        opacity: 1,
        backgroundColor: DISABLED_ACTIVE_TRACK_BACKGROUND_COLOR,
        border: border(DISABLED_ACTIVE_TRACK_BACKGROUND_COLOR),
      },
    },
  },
}));
