import { ProjectMarkupsTableHeaders } from "@pages/project-details/project-markups/project-markups-table-utils";
import { DropdownFilterChip } from "@components/common/faro-table/faro-table-filter/dropdown-filter-chip";
import { useProjectMarkupsFilterLists } from "@pages/project-details/project-markups/project-markups-filters/project-markups-filter-list";
import { TableType } from "@utils/track-event/track-event-list";

/** Show filters and apply them for project markup scenes */
export function ProjectMarkupsScenesFilter(): JSX.Element {
  const allFilters = useProjectMarkupsFilterLists();

  return (
    <DropdownFilterChip
      label={ProjectMarkupsTableHeaders.scene}
      filteredColumn={ProjectMarkupsTableHeaders.scene}
      items={allFilters.Scene ?? {}}
      shouldShowSearchBox={true}
      shouldShowCheckbox={true}
      tableType={TableType.projectMarkups}
      size="large"
    />
  );
}
