import { AdminAPITypes, APITypes } from "@stellar/api-logic";
import { FunctionComponent } from "react";

// Shown as feature's type(Basic or Premium)
export enum EFeatureType {
  premium = "premium",
  basic = "basic",
}

// used to identify a premium feature which are not represented in Admin panel
export enum EPremiumFeatureId {
  singleSignOn = "single_sign_on",
  openAPI = "open_api",

  /**
   * Refers to the feature for the 3D model import.
   * Same feature name as in EUserSubscriptionRole of newer api-logic versions
   */
  threeDModelImport = "cad-import",
}

// used to identify a basic feature which is already included with every subscription
enum EBasicFeatureId {
  timeTravel = "timeTravel",
  splitScreen = "splitScreen",
  markups = "markups",
  categories = "categories",
  modelRenderings = "modelRenderings",
  measurements = "measurements",
  offlineViewing = "offlineViewing",
  pdfReports = "pdfReports",
  jobWalkPlanner = "jobWalkPlanner",
  photos = "photos",
  videoMode = "videoMode",
}

// Identifier for all the features
export type FeatureId =
  | APITypes.EUserSubscriptionRole
  | EBasicFeatureId
  | EPremiumFeatureId;

// Holds a feature id for basic or premium and type of the feature
interface IFeature {
  featureId: FeatureId;
  featureType: EFeatureType;
}

/** Holds content of additional links of the feature */
interface IAdditionalLink {
  /** Shown as additional link text */
  text: string;

  /** Shown as additional link url */
  url: string;
}

export interface IFeatureItem extends IFeature {
  /**
   * Optional feature-bundle to request. If it is not provided, the featureId will be requested instead.
   * If the featureBundleId is provided, the featureId will still be used for status checks.
   * Use when the featureBundle HoloAdmins/Sales and Support interface with differs from the technical feature used
   * for implementation purposes.
   */
  featureBundleId?: AdminAPITypes.EFeatureBundleIdentifier;

  /** Shown a bigger description in the side panel */
  longDescription?: string;

  /**
   * The date when the extension was created or last updated.
   * Should be in a valid date format.
   */
  date: string;

  // Shown as feature's help url
  helpUrl?: IAdditionalLink;

  // Shown as feature's icon on card
  icon: FunctionComponent;

  // Shown as feature's video on side panel
  videoUrl?: string;

  // Shown as feature's image on side panel
  imageUrl?: string;

  // To be used if a feature's name on card and on modal is not same
  name: string;

  /* A brief description of the extension. */
  description: string;

  /** Shown as feature's additional urls */
  additionalLinks?: IAdditionalLink[];

  /** if the feature is activated or not */
  isActive?: boolean;
}
