import { Box, BoxProps, InputBase } from "@mui/material";
import SearchIcon from "@assets/icons/new/search_24px.svg?react";
import GenericClose from "@assets/icons/generic-close_l.svg?react";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { sphereColors } from "@styles/common-colors";

interface Props {
  /** Placeholder to show on search text when no text presents */
  placeholder: string;

  /** The text that is shown in the search field */
  searchText: string;

  /** Handle when user clicks on clear icon */
  onClearClicked(): void;

  /** Handle when user changes the search text */
  onChangeSearch(searchText: string): void;

  /** Optional sx properties to override/add to whole Search textfield */
  sx?: BoxProps["sx"];
}

/** A search text field with search icon and clear icon */
export function SearchTextField({
  placeholder,
  searchText,
  onClearClicked,
  onChangeSearch,
  sx,
}: Props): JSX.Element {
  return (
    <Box
      component="div"
      alignItems="center"
      display="flex"
      border={`1px solid ${sphereColors.gray200}`}
      borderRadius="4px"
      padding="0 5px"
      gap={1}
      sx={{
        cursor: "pointer",
        "&:hover": {
          borderColor: sphereColors.gray800,
          "& .MuiInputBase-input": {
            "&::placeholder": {
              color: sphereColors.gray800,
              opacity: 1,
            },
          },
        },
        "&:focus-within": {
          borderColor: sphereColors.blue500,
          "& .MuiInputBase-input": {
            "&::placeholder": {
              opacity: 0,
            },
          },
        },
        ...sx,
      }}
    >
      {/* For some reason when the IconButton for the clear input is shown it makes this icon smaller,
          that's why we use different width and height in that case.
        */}
      <SearchIcon
        width={searchText.length ? "28px" : "24px"}
        height={searchText.length ? "28px" : "24px"}
      />
      <InputBase
        placeholder={placeholder}
        style={{
          fontStyle: !searchText ? "italic" : undefined,
          fontSize: "14px",
          lineHeight: "19px",
          letterSpacing: "0px",
          opacity: "1",
          padding: "0px",
          width: "100%",
        }}
        inputProps={{ "aria-label": "search" }}
        value={searchText}
        onChange={(event) => onChangeSearch(event.target.value)}
      />
      {/* Only show clear icon if there's some text */}
      {searchText.length > 0 && (
        <FaroIconButton
          component={GenericClose}
          onClick={onClearClicked}
          buttonSize="24px"
          iconSize="18px"
        />
      )}
    </Box>
  );
}
