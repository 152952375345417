import { CommonStyles } from "@styles/common-styles";
import { useMediaQueryList } from "@hooks/use-media-query";
import {
  SphereBarChart,
  SphereBarChartProps,
} from "@components/common/sphere-bar-chart/sphere-bar-chart";
import { useMemo } from "react";
import { ANALYTICS_CHART_MARGIN } from "@components/analytics/analytics-charts";

/** Renders a single analytics bar chart */
export function AnalyticsBarChart({
  data,
  shouldShowSkeleton,
}: SphereBarChartProps): JSX.Element {
  const { isMedium, isExtraSmall, isScreenMdAndLarger } = useMediaQueryList();

  const padding = useMemo(() => {
    if (shouldShowSkeleton) {
      return "0px";
    }

    // Whether the chart size is small:
    const isChartSmall = isMedium || isExtraSmall;

    return isChartSmall ? "10px" : "20px";
  }, [isMedium, isExtraSmall, shouldShowSkeleton]);

  return (
    <SphereBarChart
      data={data}
      shouldShowSkeleton={shouldShowSkeleton}
      sx={{
        width: isScreenMdAndLarger
          ? `calc(50% - ${ANALYTICS_CHART_MARGIN}/2)`
          : "100%",
        border: shouldShowSkeleton
          ? "none"
          : CommonStyles.Borders.gray200Divider,
        padding,
        marginBottom: ANALYTICS_CHART_MARGIN,
      }}
    />
  );
}
