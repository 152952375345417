import { SphereTooltip } from "@components/common/sphere-tooltip";
import { BaseMarkupProps } from "@custom-types/project-markups-types";
import { Grid } from "@mui/material";
import { withEllipsis } from "@styles/common-styles";
import { DASH } from "@utils/ui-utils";

/** Width in pixels of the location separator */
const SEPARATOR_WIDTH = "10px";

/**
 * Renders the markup location.
 * The location label consists of the parent area section (floor) name and the 1st depth section (room) name
 */
export function MarkupLocation({ markup }: BaseMarkupProps): JSX.Element {
  const areaSectionLabel = markup.areaSection?.name;
  const sectionLabel = markup.section?.name;

  return (
    <Grid container sx={{ pl: "10px" }}>
      <SphereTooltip title={<var>{areaSectionLabel}</var>} shouldSkipWrapper>
        <Grid
          item
          sx={{
            ...withEllipsis,
            maxWidth: `calc(50% - ${SEPARATOR_WIDTH})`,
          }}
        >
          <var>{areaSectionLabel || DASH}</var>
        </Grid>
      </SphereTooltip>
      <Grid
        item
        sx={{
          width: SEPARATOR_WIDTH,
          display: "flex",
          justifyContent: "center",
        }}
      >
        {"/"}
      </Grid>
      <SphereTooltip title={<var>{sectionLabel}</var>} shouldSkipWrapper>
        <Grid
          item
          sx={{
            ...withEllipsis,
            maxWidth: `calc(50% - ${SEPARATOR_WIDTH})`,
          }}
        >
          <var>{sectionLabel || DASH}</var>
        </Grid>
      </SphereTooltip>
    </Grid>
  );
}
