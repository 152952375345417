import { FaroToast } from "@context-providers/toast/faro-toast";
import { IReactChildrenOnly } from "@custom-types/types";
import { SnackbarProvider } from "notistack";

export function ToastProvider({ children }: IReactChildrenOnly): JSX.Element {
  return (
    <SnackbarProvider
      preventDuplicate
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      Components={{
        FaroToast,
      }}
    >
      {children}
    </SnackbarProvider>
  );
}
