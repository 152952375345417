import { FaroCard } from "@components/common/faro-card/faro-card";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import {
  IFeatureItem,
  AddOnsExtensionFooter,
  AddOnsExtensionIcon,
  AddOnsExtensionContent,
  AddOnsExtensionSidePanel,
} from "@pages/add-ons/extensions";
import { memo, useMemo, useState } from "react";
import { DateTimeUtils } from "@stellar/web-core";
import { SidePanel } from "@components/sidepanel/sidepanel";
import { useAppSelector } from "@store/store-helper";
import { currentUserSelector } from "@store/user/user-selector";
import { hasUserValidCompanyRole } from "@utils/access-control/company/company-access-control";
import { CoreAPITypes } from "@stellar/api-logic";
import { selectedSdbCompanySelector } from "@store/sdb-company/sdb-company-selector";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { AddOnsEvents } from "@utils/track-event/track-event-list";

/**
 * Number of days used as a threshold to determine the status display.
 * If the difference in days between two dates exceeds this threshold, the new status is displayed.
 */
const DIFFERENCE_DAYS = 90;

interface AddOnsExtensionItemProps {
  /** The object extension from add-ons */
  extension: IFeatureItem;

  /** If there is any dropdown opened in the side panel */
  anchorEl: HTMLElement | null;
}

/** Renders the item from extensions */
export const AddOnsExtensionItem = memo(
  ({ extension, anchorEl }: AddOnsExtensionItemProps) => {
    const theme = useTheme();
    const currentUser = useAppSelector(currentUserSelector);
    const selectedCompany = useAppSelector(selectedSdbCompanySelector);

    const isScreenSmallAndLarger = useMediaQuery(theme.breakpoints.up("sm"));
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { trackEvent } = useTrackEvent();

    const daysDifference = useMemo(
      () => DateTimeUtils.diffBetweenTwoDatesInDays(new Date(), extension.date),
      [extension.date]
    );

    const isAdmin = useMemo(
      () =>
        hasUserValidCompanyRole({
          currentUser,
          requiredCompanyRoles: [
            CoreAPITypes.EUserCompanyRole.companyExecutive,
          ],
        }),
      [currentUser]
    );

    const shouldShowTheNewChip = useMemo(
      () => daysDifference <= DIFFERENCE_DAYS,
      [daysDifference]
    );

    const hasFooter = useMemo(
      () => extension.isActive || shouldShowTheNewChip,
      [extension.isActive, shouldShowTheNewChip]
    );

    /**
     * Opens the side panel.
     */
    function handleOpenSidePanel(): void {
      setIsOpen(true);
      trackEvent({
        name: AddOnsEvents.openAddOnInformation,
        props: {
          feature: extension.name,
        },
      });
    }

    /**
     * Close the side panel.
     */
    function handleCloseSidePanel(): void {
      setIsOpen(false);
    }

    return (
      <>
        <FaroCard
          isLoading={false}
          onClick={handleOpenSidePanel}
          sx={{
            width: isScreenSmallAndLarger ? "317px" : "100%",
            height: "278px",
          }}
        >
          <Box
            data-testid="add-ons-extensions-extension"
            sx={{
              padding: "20px",
              display: "flex",
              height: "100%",
              flexDirection: "column",
            }}
          >
            <AddOnsExtensionIcon
              icon={extension.icon}
              sx={{
                height: "60px",
                width: "60px",
                marginBottom: "24px",
              }}
            />

            <AddOnsExtensionContent
              name={extension.name}
              description={extension.description}
              hasFooter={hasFooter}
            />

            {hasFooter && (
              <AddOnsExtensionFooter
                hasNewChip={shouldShowTheNewChip}
                isActive={extension.isActive}
              />
            )}
          </Box>
        </FaroCard>
        {isOpen && (
          <SidePanel
            isOpen={isOpen}
            anchor="right"
            isDropdownOpened={anchorEl ? true : false}
            closeSidePanel={handleCloseSidePanel}
          >
            <AddOnsExtensionSidePanel
              addOnExtension={extension}
              closePanel={handleCloseSidePanel}
              isAdmin={isAdmin}
              companyId={selectedCompany?.id}
            />
          </SidePanel>
        )}
      </>
    );
  }
);
