import { Box } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import CheckIcon from "@assets/icons/new/check_18px.svg?react";
import { memo } from "react";

interface Props {
  /** Whether the chip should have a dropdown or not */
  shouldHaveDropdown: boolean;

  /** Number of active filters in the column */
  numberOfActiveFilters: number;
}

/** Show the icon of the filter chip */
function FilterChipIconComponent({
  shouldHaveDropdown,
  numberOfActiveFilters,
}: Props): JSX.Element | undefined {
  if (numberOfActiveFilters === 0) {
    return undefined;
  }
  return shouldHaveDropdown ? (
    <Box
      component="div"
      sx={{
        width: "18px",
        height: "18px",
        backgroundColor: sphereColors.blue400,
        color: sphereColors.pureWhite,
        textAlign: "center",
        borderRadius: "50%",
        marginRight: "6px",
      }}
    >
      <var>{numberOfActiveFilters}</var>
    </Box>
  ) : (
    <CheckIcon
      style={{
        fontSize: "13px",
        color: sphereColors.blue500,
        fontWeight: "bold",
      }}
    />
  );
}

export const FilterChipIcon = memo(FilterChipIconComponent);
