import {
  CloudIcon,
  FaroIconButton,
  FaroIconButtonProps,
} from "@faro-lotv/flat-ui";
import { Box, CircularProgress, Stack } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { ForwardedRef, forwardRef } from "react";

type Props = Omit<FaroIconButtonProps, "children"> & {
  /** True if the button should spinner */
  isLoading: boolean;
};

/** Renders the button to open the progress overview menu that can show the progress of the file upload */
export const ProgressOverviewButton = forwardRef(
  (
    { isLoading, ...rest }: Props,
    ref: ForwardedRef<HTMLButtonElement>
  ): JSX.Element => {
    return (
      <FaroIconButton ref={ref} {...rest}>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            transform: "scale(1.25)",
          }}
          data-testid="progress-overview-button"
        >
          <CircularProgress
            variant={isLoading ? "indeterminate" : "determinate"}
            value={rest.disabled ? 0 : undefined}
            size="100%"
            sx={{ color: sphereColors.blue500 }}
          />
          <Box sx={{ position: "absolute" }}>
            {/* TODO: Change the icon https://faro01.atlassian.net/browse/ST-2010 */}
            <CloudIcon sx={{ width: "100%", height: "100%", p: "10%" }} />
          </Box>
        </Stack>
      </FaroIconButton>
    );
  }
);
