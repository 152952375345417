import { Box } from "@mui/material";
import { AnalyticsBarChart } from "@components/analytics/analytics-bar-chart";
import { useAppSelector } from "@store/store-helper";
import {
  chartsDataSelector,
  fetchingAnalyticsFlagsSelector,
  isCompanyStatsSelector,
} from "@store/analytics/analytics-selector";

/** Number of loading skeletons when showing company charts */
const NUM_SKELETONS_COMPANY = 4;

/** Number of loading skeletons when showing project charts */
const NUM_SKELETONS_PROJECT = 2;

/** Margin to have in between charts */
export const ANALYTICS_CHART_MARGIN = "14px";

/** Renders analytics stats data as charts */
export function AnalyticsCharts(): JSX.Element {
  const data = useAppSelector(chartsDataSelector);
  const { isFetchingCompanyStats, isFetchingProjectStats } = useAppSelector(
    fetchingAnalyticsFlagsSelector
  );
  const isLoading = isFetchingCompanyStats || isFetchingProjectStats;

  const isCompanyStats = useAppSelector(isCompanyStatsSelector);
  const numberOfSkeletons = isCompanyStats
    ? NUM_SKELETONS_COMPANY
    : NUM_SKELETONS_PROJECT;

  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        marginTop: ANALYTICS_CHART_MARGIN,
      }}
    >
      {/* Loading Skeleton */}
      {isLoading &&
        [...Array(numberOfSkeletons)].map((_, index) => (
          <AnalyticsBarChart key={index} shouldShowSkeleton={true} />
        ))}

      {/* Charts */}
      {!isLoading &&
        data?.length > 0 &&
        data.map((data, index) => (
          <AnalyticsBarChart key={index} data={data} />
        ))}

      {/* No charts to display */}
      {!isLoading && !data?.length && <>No analytics charts to display</>}
    </Box>
  );
}
