import { Grid } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { CommonStyles } from "@styles/common-styles";

interface Props {
  /** Buttons shown in table action bar */
  actionBarButtons: JSX.Element[];
}

/** Shows an action bar on top of tiles (group of cards) */
export function FaroTilesActionBar({ actionBarButtons }: Props): JSX.Element {
  return (
    <Grid
      container
      justifyContent="flex-end"
      alignItems="center"
      flexWrap="nowrap"
      sx={{
        height: "54px",
        backgroundColor: sphereColors.gray50,
        mt: "13px",
        pl: "20px",
        width: "100%",
      }}
    >
      {actionBarButtons?.map((actionButton, index) => (
        <Grid
          item
          key={index}
          sx={{
            borderLeft:
              index > 0 ? CommonStyles.Borders.gray200Divider : "none",
            px: "18px",
          }}
        >
          {actionButton}
        </Grid>
      ))}
    </Grid>
  );
}
