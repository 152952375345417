import { MemberTypes } from "@custom-types/member-types";
import { IElementUserDirectoryMarkupField } from "@faro-lotv/ielement-types";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { getPrettyName } from "@utils/user-utils";

interface Props {
  /** markup assignee */
  assignee?: IElementUserDirectoryMarkupField;

  /** List of company members */
  companyMembers: MemberTypes[];

  /** List of project members */
  projectMembers: SphereDashboardAPITypes.IProjectMemberBase[];
}

/** Returns the markup assignee member */
export function getMarkupAssigneeMember({
  assignee,
  companyMembers,
  projectMembers,
}: Props):
  | MemberTypes
  | SphereDashboardAPITypes.IProjectMemberBase
  | undefined {
  // There is a list of assignee IDs. Let's take the name of the first assignee.
  const assigneeId = assignee?.values ? assignee.values[0] : undefined;

  const assigneeCompanyMember = companyMembers.find(
    (member) => member.identity === assigneeId
  );

  const assigneeProjectMember = projectMembers.find(
    (member) => member.identity === assigneeId
  );

  // Give priority to the company level member entity
  return assigneeCompanyMember || assigneeProjectMember;
}

/** Returns the markup assignee name */
export function getMarkupAssigneeName({
  assignee,
  companyMembers,
  projectMembers,
}: Props): string | undefined {
  const assigneeMember = getMarkupAssigneeMember({
    assignee,
    companyMembers,
    projectMembers,
  });

  const name = getPrettyName(assigneeMember);

  return name !== "" ? name : undefined;
}
