import { MarkupSceneObject, SlideNodes } from "@custom-types/slide-nodes-types";
import { CoreAPITypes } from "@stellar/api-logic";

/** True iff the passed value is of type SlideNodes */
export function isSlideNodes(value: unknown): value is SlideNodes {
  return typeof value === "object" && value !== null && "slideNodes" in value;
}

/** True iff the scene object is a markup scene object */
export function isMarkupSceneObject(
  sceneObject: CoreAPITypes.ISceneObjectJson
): sceneObject is MarkupSceneObject {
  return sceneObject.markupData?.isMarkup === true;
}
