import { FaroTextButton } from "@components/common/faro-text-button";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { setBulkActionName, updateOne } from "@store/table/table-slice";
import { useEffect, useState } from "react";
import { BulkSuccessDialog } from "@components/common/faro-table/bulk-dialog/bulk-success-dialog";
import {
  bulkActionNameSelector,
  selectedEntitiesSelector,
  shouldShowSuccessDialogSelector,
} from "@store/table/table-selector";
import { updateProjectDetails } from "@store/projects/projects-slice-thunk";
import { useCoreApiClient } from "@api/use-core-api-client";
import { BulkActionEvents } from "@utils/track-event/track-event-list";
import { ProjectArchivingState, SdbProject } from "@custom-types/project-types";
import { RequiredRoleProjectLevelName } from "@utils/access-control/project/project-access-control-types";
import { useChangeProjectStatusBulkDialogContent } from "@components/table/projects/bulk-actions/bulk-change-project-status-helper";
import { APITypes } from "@stellar/api-logic";
import { FaroBulkDialog } from "@components/common/faro-table/bulk-dialog/bulk-dialog";
import { useHasUserValidRoleProjectLevel } from "@hooks/access-control/use-has-user-valid-role-project-level";

interface Props {
  /** The archiving status of the project */
  projectStatus: ProjectArchivingState;
}

/** Show change project status button and dispatch action where the bulk dialog will act accordingly */
export function BulkChangeProjectStatus({ projectStatus }: Props): JSX.Element {
  const [shouldShowDialog, setShouldShowDialog] = useState<boolean>(false);

  const bulkActionName = useAppSelector(bulkActionNameSelector);
  const shouldShowSuccessDialog = useAppSelector(
    shouldShowSuccessDialogSelector
  );
  const selectedEntities = useAppSelector(selectedEntitiesSelector("projects"));
  const { hasUserPermissionProjectLevel } = useHasUserValidRoleProjectLevel();

  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();

  const { bulkDialogContent, successDialogContent } =
    useChangeProjectStatusBulkDialogContent(projectStatus);

  // Closes the dialog when the bulk action is successful to show the success dialog
  useEffect(() => {
    if (shouldShowSuccessDialog) {
      setShouldShowDialog(false);
    }
  }, [shouldShowSuccessDialog]);

  function onChangeProjectStatusClick(): void {
    dispatch(setBulkActionName("changeProjectStatus"));

    for (const entity of selectedEntities) {
      let isChangingProjectStatusAllowed = false;
      let hasUserValidRole = false;

      if (entity.features !== null) {
        let appropriateFeature =
          APITypes.EUserSubscriptionRole.projectUnarchive;
        let appropriateRole = RequiredRoleProjectLevelName.canUnarchiveProjects;

        if (projectStatus === ProjectArchivingState.active) {
          appropriateFeature = APITypes.EUserSubscriptionRole.projectArchive;
          appropriateRole = RequiredRoleProjectLevelName.canArchiveProjects;
        }

        isChangingProjectStatusAllowed =
          entity.features[appropriateFeature].enabled;

        hasUserValidRole = hasUserPermissionProjectLevel({
          roleName: appropriateRole,
          selectedProject: entity,
        });
      }

      if (!hasUserValidRole || !isChangingProjectStatusAllowed) {
        dispatch(
          updateOne({
            id: entity.id,
            changes: {
              status: "not-allowed",
              message: "You are not allowed to change status of this project",
            },
          })
        );
      }
    }

    setShouldShowDialog(true);
  }

  return (
    <>
      <FaroTextButton
        onClick={onChangeProjectStatusClick}
        tooltipText={`${
          projectStatus === ProjectArchivingState.archived
            ? "Unarchive"
            : "Archive"
        } project`}
        sx={{ fontWeight: 600 }}
      >
        {projectStatus === ProjectArchivingState.archived
          ? "Unarchive"
          : "Archive"}
      </FaroTextButton>

      {/* Checking for bulkActionName to make sure the dialog of another bulk action is not opened by mistake */}
      {bulkActionName === "changeProjectStatus" && shouldShowSuccessDialog && (
        <BulkSuccessDialog dialogContent={successDialogContent} />
      )}

      {bulkActionName === "changeProjectStatus" && shouldShowDialog && (
        <FaroBulkDialog
          dialogContent={bulkDialogContent}
          onClose={() => setShouldShowDialog(false)}
          trackingEvent={BulkActionEvents.bulkChangeProjectStatus}
          bulkActionCallback={(project: SdbProject) =>
            dispatch(
              updateProjectDetails({
                coreApiClient,
                projectId: project.id,
                payload: {
                  archivingState:
                    projectStatus === ProjectArchivingState.active
                      ? APITypes.ArchivingState.ARCHIVED
                      : APITypes.ArchivingState.UNARCHIVED,
                },
              })
            ).unwrap()
          }
          selectedEntities={selectedEntities}
          uniqueIdKey="id"
          tableDataType="projects"
        />
      )}
    </>
  );
}
