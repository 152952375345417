import {
  FileUploadTask,
  UploadElementType,
} from "@custom-types/file-upload-types";
import { RootState } from "@store/store-helper";
import { createSelector } from "@reduxjs/toolkit";
import { uploadTasksAdapter } from "@store/upload-tasks/upload-tasks-slice";
import { GUID } from "@faro-lotv/foundation";
import {
  isTaskCompleted,
  isTaskInProgress,
} from "@hooks/upload-tasks/upload-tasks-utils";

/** @returns List of all upload tasks */
export const uploadTasksSelector: (state: RootState) => FileUploadTask[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const tasks = uploadTasksAdapter
        .getSelectors()
        .selectAll(state.uploadTasks);

      return tasks;
    }
  );

/** @returns  An upload task */
export function uploadTaskByIdSelector(
  id: GUID
): (state: RootState) => FileUploadTask | undefined {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const uploadTask = uploadTasksAdapter
        .getSelectors()
        .selectById(state.uploadTasks, id);

      return uploadTask;
    }
  );
}

/** @returns True if any incomplete tasks is available in the store */
export const hasIncompleteUploadTasksSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const incompleteUploadTasks = incompleteUploadTasksSelector(state);

      return !!incompleteUploadTasks.length;
    }
  );

/** @returns True if any in-progress tasks is available in the store */
export const hasInProgressUploadTasksSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const tasks = uploadTasksSelector(state);

      return tasks.some(isTaskInProgress);
    }
  );

/** @returns List of all incomplete upload tasks */
export const incompleteUploadTasksSelector: (
  state: RootState
) => FileUploadTask[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const tasks = uploadTasksSelector(state);

    const incompleteUploadTasks = tasks.filter(
      (task) => !isTaskCompleted(task)
    );

    return incompleteUploadTasks;
  }
);

/** @returns List of incomplete attachment upload tasks for a given markup */
export function incompleteMarkupAttachmentUploadTasksSelector(
  iElementId: GUID
): (state: RootState) => FileUploadTask[] {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const incompleteTasks = incompleteUploadTasksSelector(state);

      const markupAttachmentUploadTasks = incompleteTasks.filter(
        (task) =>
          task.context.uploadElementType === UploadElementType.annotation &&
          task.context.iElementId === iElementId
      );

      return markupAttachmentUploadTasks;
    }
  );
}
