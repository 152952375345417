import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  IFeatureItem,
  AddOnsExtensionIcon,
  AddOnsExtensionSidePanelRequestDropdown,
} from "@pages/add-ons/extensions";
import CloseIcon from "@assets/icons/new/close_24px.svg?react";
import { sphereColors } from "@styles/common-colors";
import { FaroLink } from "@components/common/link/faro-link";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { useAddonsContext } from "@context-providers/add-ons/add-ons-context";
import { MouseEvent } from "react";
import { FaroChip } from "@faro-lotv/flat-ui";

interface AddOnsExtensionSidePanelProps {
  /** The object extension from add-ons */
  addOnExtension: IFeatureItem;

  /** Callback function to close the side panel when triggered. */
  closePanel: () => void;

  /** If the user is admin or not */
  isAdmin: boolean;

  /* Company ID selected */
  companyId?: string;
}

/** Renders the extension side panel component */
export function AddOnsExtensionSidePanel({
  addOnExtension,
  isAdmin,
  companyId,
  closePanel,
}: AddOnsExtensionSidePanelProps): JSX.Element {
  const theme = useTheme();
  const isScreenSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isScreenXs = useMediaQuery(theme.breakpoints.only("xs"));

  const { anchorEl, updateAnchorState } = useAddonsContext();

  /**
   * Close the side panel and clean the anchor state if the dropdown is opened.
   *
   * @param event The mouse event triggering the click action.
   */
  function handleClosePanel(event: MouseEvent<HTMLElement>): void {
    closePanel();

    if (anchorEl) {
      updateAnchorState(event);
    }
  }

  return (
    <Box
      data-testid="add-ons-extension-side-panel"
      sx={{
        width: isScreenSm || isScreenXs ? "100%" : "848px",
        padding: "60px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <FaroIconButton
        onClick={handleClosePanel}
        component={CloseIcon}
        buttonSize={"34px"}
        iconButtonProps={{
          sx: { position: "absolute", top: "20px", right: "20px" },
        }}
        dataTestId="add-ons-extension-side-panel-icon-close-button"
      />

      <Box
        sx={{
          display: "flex",
          gap: "60px",
          flexDirection: isScreenXs ? "column" : "row",
        }}
      >
        <Box>
          <Box
            sx={{
              border: `1px solid ${sphereColors.gray200}`,
              padding: "18px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: "30px",
            }}
            data-testid="add-ons-extension-side-panel-icon"
          >
            <AddOnsExtensionIcon
              icon={addOnExtension.icon}
              sx={{ width: "106px", height: "106px" }}
            />
          </Box>

          {addOnExtension.isActive ? (
            <>
              <FaroChip
                size="medium"
                sx={{ width: "100%" }}
                color={sphereColors.blue500}
                backgroundColor={sphereColors.blue100}
                label="ACTIVE"
              />

              {addOnExtension.additionalLinks && (
                <>
                  <Typography
                    data-testid="add-ons-extension-side-panel-action-title"
                    sx={{
                      fontSize: "12px",
                      color: sphereColors.gray600,
                      fontWeight: 400,
                      mt: "38px",
                    }}
                  >
                    Documentation
                  </Typography>
                  {addOnExtension.additionalLinks.map((link, index) => (
                    <Box
                      key={index}
                      component={"p"}
                      sx={{ margin: 0 }}
                      data-testid="add-ons-extension-side-panel-action-links"
                    >
                      <FaroLink title={link.text} link={link.url} />
                    </Box>
                  ))}
                </>
              )}
            </>
          ) : (
            isAdmin && (
              <AddOnsExtensionSidePanelRequestDropdown
                closePanel={closePanel}
                companyId={companyId}
                addOnName={addOnExtension.name}
              />
            )
          )}
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <Typography
            sx={{
              fontWeight: "600",
              color: sphereColors.gray800,
              fontSize: "24px",
            }}
            data-testid="add-ons-extension-side-panel-name"
          >
            {addOnExtension.name}
          </Typography>

          <Typography
            sx={{ color: sphereColors.gray800 }}
            data-testid="add-ons-extension-side-panel-description"
          >
            {addOnExtension.longDescription || addOnExtension.description}
          </Typography>

          {addOnExtension.helpUrl && (
            <Box
              component={"p"}
              sx={{ margin: 0 }}
              data-testid="add-ons-extension-side-panel-learn-more"
              aria-label="Learn more link"
            >
              <FaroLink
                title={addOnExtension.helpUrl.text}
                link={addOnExtension.helpUrl.url}
              />
            </Box>
          )}
        </Box>
      </Box>

      {(addOnExtension.imageUrl || addOnExtension.videoUrl) && (
        <Box
          sx={{
            mt: "90px",
            height: "487px",
            width: "100%",
          }}
        >
          {addOnExtension.imageUrl && (
            <Box
              data-testid="add-ons-extension-side-panel-image"
              component="img"
              alt="image feature add ons"
              src={addOnExtension.imageUrl}
              sx={{ width: "100%", height: "487px", objectFit: "cover" }}
            />
          )}

          {addOnExtension.videoUrl && (
            <Box
              data-testid="add-ons-extension-side-panel-video"
              component="video"
              autoPlay
              muted
              sx={{ width: "100%" }}
            >
              <Box component="source" src={addOnExtension.videoUrl} />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
