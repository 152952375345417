import { useAppParams } from "@router/router-helper";
import { NotFoundPage } from "@pages/not-found-page";
import { TabsWithRoutes } from "@components/common/tabs-with-routes";
import { CircularProgress } from "@faro-lotv/flat-ui";
import { useAppSelector } from "@store/store-helper";
import { UsersTabs } from "@router/route-params";
import { isAlphaTestingEnabledSelector } from "@store/ui/ui-selector";
import { OpenTabEvents } from "@utils/track-event/track-event-list";
import { UserGroups } from "@pages/members/user-groups/user-groups";
import { MembersPage } from "@pages/members/members-page";
import { currentUserSelector } from "@store/user/user-selector";
import { CoreAPITypes } from "@stellar/api-logic";
import { useMemo } from "react";

/** Content that will have the logic for the users tabs */
export function MembersWorkspace(): JSX.Element {
  const { usersTab } = useAppParams();
  const { companyId } = useAppParams();
  const isAlphaTestingEnabled = useAppSelector(isAlphaTestingEnabledSelector);
  const currentUser = useAppSelector(currentUserSelector);

  const shouldShowAccessControlTab = useMemo(
    () =>
      isAlphaTestingEnabled &&
      currentUser &&
      currentUser.role.includes(CoreAPITypes.EUserCompanyRole.companyExecutive),
    [currentUser, isAlphaTestingEnabled]
  );

  if (!companyId || !usersTab || !Object.values(UsersTabs).includes(usersTab)) {
    return <NotFoundPage />;
  }

  const usersTabs = [
    {
      label: "General",
      route: UsersTabs.general,
      content: () => <MembersPage />,
      loadingContent: <CircularProgress />,
    },
  ];

  if (shouldShowAccessControlTab) {
    usersTabs.push({
      label: "User groups",
      route: UsersTabs.userGroups,
      content: () => <UserGroups />,
      loadingContent: <CircularProgress />,
    });
  }

  return (
    <TabsWithRoutes
      dataTestId="USER_CONTAINER"
      selectedTab={usersTab}
      requiredAttribute={false}
      trackingEventPageName={OpenTabEvents.openWorkspaceUsersTab}
      tabs={usersTabs}
    />
  );
}
