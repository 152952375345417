import { Box, Typography } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { BaseGroupProps } from "@custom-types/group-types";
import { withEllipsisTwoLines } from "@styles/common-styles";
import { cardProperties } from "@utils/ui-utils";
import { useDateTime } from "@hooks/use-date-time";

/** Defines the height for the card */
export const cardMiddleHeight = "110px";

/**
 * Middle section of the group card that shows the group name and creation date.
 */
export function GroupCardMiddle({ group }: BaseGroupProps): JSX.Element {
  const { formatDate } = useDateTime();

  return (
    <Box
      component="div"
      sx={{
        paddingTop: cardProperties.creationDatePaddingTop,
        paddingBottom: 0,
        paddingX: "14px",
        height: cardMiddleHeight,
      }}
    >
      {/* Creation Date */}
      <Typography
        sx={{
          fontSize: "10px",
          color: sphereColors.gray700,
          lineHeight: "24px",
          letterSpacing: "0.1px",
        }}
      >
        Created: <var>{formatDate(group.createdAt)}</var>
      </Typography>

      {/* Group Name */}
      <Typography
        gutterBottom
        component="div"
        sx={{
          fontSize: "16px",
          color: sphereColors.gray800,
          fontWeight: 600,
          ...withEllipsisTwoLines,
        }}
      >
        <var>{group.name}</var>
      </Typography>
    </Box>
  );
}
