import { FaroCheckbox } from "@components/common/faro-checkbox";
import { ReactSetStateFunction } from "@custom-types/types";
import { CoreAPITypes } from "@stellar/api-logic";
import { nounPluralize } from "@utils/data-display";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";

interface Props {
  /** The selected role for the users to be invited. The checkbox is only shown when it is a group manager */
  selectedRole: CoreAPITypes.EUserCompanyRole;

  /**
   * Value for the checkbox indicating that the user should be able to create groups.
   * It should be updated outside this component using the callback function setShouldCreateGroups.
   */
  shouldCreateGroups: boolean;

  /** Count on how many members are selected, used to adapt the label to singular or plural */
  membersCount: number;

  /** Callback function to update the state */
  setShouldCreateGroups: ReactSetStateFunction<boolean>;
}

/**
 * Checkbox to enable the user to create groups.
 * It is only shown if the selected role is a group manager
 * and the current user has the permission edit members.
 */
export function EnableGroupCreationCheckbox({
  selectedRole,
  shouldCreateGroups,
  membersCount,
  setShouldCreateGroups,
}: Props): JSX.Element | null {
  const { canEditMember } = useHasUserValidRoleCompanyLevel();

  if (
    selectedRole !== CoreAPITypes.EUserCompanyRole.companyManager ||
    !canEditMember
  ) {
    // Early exit if the selected role is not a group manager or
    // the current user does not have the permission to edit members
    return null;
  }

  return (
    <FaroCheckbox
      isChecked={shouldCreateGroups}
      onChange={() => setShouldCreateGroups(!shouldCreateGroups)}
      label={`Enable ${nounPluralize({
        shouldShowCounter: false,
        counter: membersCount,
        word: "this user",
      })} to create groups`}
    />
  );
}
