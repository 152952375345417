import { useMemberGroupDetails } from "@hooks/use-member-group-details";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { MemberGroupProjectsSkeleton } from "@components/common/skeleton-loading/member-group-projects-skeleton";
import { Box } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { CommonStyles, DEFAULT_TEXT_FONT_SIZE } from "@styles/common-styles";
import { MemberGroupProjectsTable } from "@components/table/member-group-projects/member-group-projects-table";
import { MemberGroupProjectsHeaders } from "@components/table/member-group-projects/member-group-projects-table-utils";

interface Props {
  /** Group details of a company member */
  group: SphereDashboardAPITypes.CompanyMemberGroup;
}

/**
 * Renders a table with projects of a group that a member belongs to
 */
export function MemberGroupProjects({ group }: Props): JSX.Element | null {
  const { groupDetails, isFetchingGroupDetails } = useMemberGroupDetails({
    groupId: group.id,
  });

  if (isFetchingGroupDetails) {
    return <MemberGroupProjectsSkeleton />;
  }

  // If fetching the group details failed just return null
  if (!groupDetails) {
    return null;
  }

  if (!groupDetails.projects.length) {
    return (
      <Box
        component={"div"}
        sx={{
          height: "50px",
          display: "flex",
          justifyContent: "center",
          padding: "8px 16px",
          borderBottom: CommonStyles.Borders.gray200Divider,
          my: "30px",
          color: sphereColors.gray600,
          fontSize: DEFAULT_TEXT_FONT_SIZE,
        }}
      >
        There are no projects in this group.
      </Box>
    );
  }

  return (
    <MemberGroupProjectsTable
      projects={groupDetails.projects}
      isLoading={isFetchingGroupDetails}
      requiredColumns={[
        MemberGroupProjectsHeaders.thumbnail,
        MemberGroupProjectsHeaders.name,
        MemberGroupProjectsHeaders.role,
        MemberGroupProjectsHeaders.options,
      ]}
    />
  );
}
