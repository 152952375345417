import { useAppSelector } from "@store/store-helper";
import {
  fetchingProjectsFlagsSelector,
  projectMembersSelector,
} from "@store/projects/projects-selector";
import { MemberHeaders } from "@components/table/members/members-table-utils";
import { MembersTable } from "@components/table/members/members-table";
import { TeamTabProjectDetails } from "@pages/project-details/project-team/team-tab-project-details";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { ProjectTeamBulkActions } from "@pages/project-details/project-team/project-team-bulk-actions";
import { SdbProject } from "@custom-types/project-types";
import { APITypes } from "@stellar/api-logic";
import { useMemo } from "react";

interface Props extends Partial<BaseCompanyIdProps> {
  /** The project to display */
  project?: SdbProject;

  /** Whether the project is still loading and skeleton should be shown instead */
  isLoading: boolean;
}

/**
 * Contains the Team tab of the project details
 */
export function ProjectTeam({
  project,
  companyId,
  isLoading,
}: Props): JSX.Element {
  const projectMembers = useAppSelector(projectMembersSelector);
  const { isFetchingProjects } = useAppSelector(fetchingProjectsFlagsSelector);

  const buttonComponents = ProjectTeamBulkActions({
    companyId,
    project,
    members: projectMembers,
  });

  const shouldHideCheckbox = useMemo(
    () => project?.archivingState === APITypes.ArchivingState.ARCHIVED,
    [project?.archivingState]
  );

  return (
    <>
      <TeamTabProjectDetails project={project} isLoading={isLoading} />
      <MembersTable
        companyId={companyId}
        members={projectMembers}
        project={project}
        tableSubject={"project"}
        isLoading={isFetchingProjects || isLoading}
        requiredColumns={[
          MemberHeaders.avatar,
          MemberHeaders.user,
          MemberHeaders.email,
          MemberHeaders.status,
          MemberHeaders.role,
          MemberHeaders.options,
        ]}
        bulkActionButtons={buttonComponents}
        shouldHideCheckbox={shouldHideCheckbox}
      />
    </>
  );
}
