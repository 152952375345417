import { SdbProject } from "@custom-types/project-types";
import { Box, Skeleton } from "@mui/material";
import { defaultProjectThumbnail } from "@utils/project-utils";
import { ChangeThumbnailDialog } from "@pages/project-details/project-overview/project-thumbnail/change-thumbnail-dialog";

/** Width of the default thumbnail image */
const DEFAULT_THUMBNAIL_IMAGE_WIDTH = 250;

/** Height of default thumbnail image */
const DEFAULT_THUMBNAIL_IMAGE_HEIGHT = 150;

/** Aspect ratio of the default thumbnail image */
const DEFAULT_THUMBNAIL_ASPECT_RATIO =
  DEFAULT_THUMBNAIL_IMAGE_WIDTH / DEFAULT_THUMBNAIL_IMAGE_HEIGHT;

interface Props {
  /** The project to display its thumbnail image */
  project?: SdbProject | null;

  /** Whether the project is still loading and skeleton should be shown instead */
  isLoading: boolean;

  /** Whether the project is editable or not */
  isProjectEditable: boolean;
}

/**
 * Shows the thumbnail image of the project.
 * If the project is still loading, a skeleton will be shown instead.
 */
export function ProjectThumbnailImage({
  project,
  isLoading,
  isProjectEditable,
}: Props): JSX.Element {
  if (isLoading || !project) {
    return (
      <Skeleton
        variant="rectangular"
        sx={{
          width: "100%",
          height: "auto",
          aspectRatio: DEFAULT_THUMBNAIL_ASPECT_RATIO,
        }}
      />
    );
  }

  return (
    <ChangeThumbnailDialog
      existingThumbnailUrl={project.thumbnailUrl ?? undefined}
      isAllowedToChange={isProjectEditable}
      projectId={project.id}
    >
      <Box
        component="img"
        src={project?.thumbnailUrl ?? defaultProjectThumbnail}
        sx={{
          width: "100%",
          height: "auto",
          aspectRatio: DEFAULT_THUMBNAIL_ASPECT_RATIO,
          objectFit: "contain",
        }}
        alt="Project thumbnail"
      />
    </ChangeThumbnailDialog>
  );
}
