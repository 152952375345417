import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";

/** Base props for a group */
export interface BaseGroupProps {
  /** Group entity */
  group: SphereDashboardAPITypes.ICompanyGroup;
}

/** Base props for group details */
export interface BaseGroupDetailsProps {
  /** Group details entity */
  group: SphereDashboardAPITypes.IGroupDetails;
}

/** All the possible types for a group */
export type GroupTypes =
  // Group type when requested from company level (all groups of a company)
  | SphereDashboardAPITypes.ICompanyGroup
  // Group type when requested by providing a group ID
  | SphereDashboardAPITypes.IGroupDetails;

/** Assignable company roles in group for inviting members or updating the role */
export const AssignableGroupMemberRole: {
  [key in string]: SphereDashboardAPITypes.IAssignmentGroupRole;
} = {
  companyExecutive: CoreAPITypes.EUserCompanyRole.companyManager,
  companyViewer: CoreAPITypes.EUserCompanyRole.projectManager,
};
