import { CoreAPIUtils } from "@stellar/api-logic";
import { StatusCodes } from "http-status-codes";
import { HandleableError } from "@context-providers/error-boundary/error-types";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  ApiResponseError,
  getMutationErrorProblemsMessages,
} from "@faro-lotv/service-wires";
import {
  isBaseProjectAPIError,
  isProjectApiMutationError,
} from "@api/project-api/project-api-errors";

/**
 * Returns whether a response from as async thunk contains an error.
 */
export function isResponseError<T>(result: PayloadAction<T>): boolean {
  return "error" in result;
}

/**
 * Returns whether a response from as async thunk contains a payload.
 */
export function isSuccessResponse<T>(result: PayloadAction<T>): boolean {
  return !("error" in result);
}

/**
 * Converts errors of unknown shape to a readable markup.
 */
export function getErrorDisplayMarkup(error: HandleableError): string {
  // errorMarkup might be in the form of html tag
  let errorMarkup: string = "";

  if (error) {
    if (error instanceof ApiResponseError) {
      if (isProjectApiMutationError(error)) {
        return getMutationErrorProblemsMessages(error).join("\n");
      }

      if (isBaseProjectAPIError(error)) {
        return (errorMarkup = error.body.error);
      }
    } else if (typeof error === "string") {
      errorMarkup = error;
    } else if ((error as Error).message) {
      // Try to find a message in the error-like object
      if (typeof (error as Error).message === "string") {
        errorMarkup = (error as Error).message;
      } else {
        // Try to display the message stringified with JSON if it is an object.
        try {
          errorMarkup = JSON.stringify((error as Error).message, null, 2);
        } catch (ignoreJsonError) {
          errorMarkup = `${(error as Error).message}`;
        }
      }
    } else if (
      CoreAPIUtils.isResponseError(error) &&
      error.status === StatusCodes.INTERNAL_SERVER_ERROR
    ) {
      errorMarkup = "Something went wrong";
    } else if (
      CoreAPIUtils.isResponseError(error) &&
      error.status === StatusCodes.UNAUTHORIZED
    ) {
      errorMarkup = error.error_v2;
    } else if (CoreAPIUtils.isBaseResponse(error)) {
      errorMarkup = `${errorMarkup} (error status: ${error.status})`;
    }
  }

  if (!errorMarkup) {
    errorMarkup = "No error message";
  }

  return errorMarkup;
}
