import { useCoreApiClient } from "@api/use-core-api-client";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useToast } from "@hooks/use-toast";
import { CoreAPITypes } from "@stellar/api-logic";
import { selectedProjectSelector } from "@store/projects/projects-selector";
import { selectedSdbCompanySelector } from "@store/sdb-company/sdb-company-selector";
import { useAppSelector } from "@store/store-helper";
import { FeedbackModalEvents } from "@utils/track-event/track-event-list";
import { getFeedbackMessage } from "@utils/email-utils";
import { useTrackEvent } from "@utils/track-event/use-track-event";

interface SendFeedbackProps {
  /**
   * The name of the feature that the feedback is about, e.g.
   * General Feedback or Annotations.
   */
  featureName: string;

  /** Optional message that the user inputs to give feedback */
  feedbackMessage?: string;

  /**
   * Optional rating given by the customer.
   * !: It should be standardized to a 1-5 scale, to generate appropriate Amplitude reports
   */
  selectedRating?: number | null;
}

interface UseFeedbackHook {
  /**
   * Function to send feedback to the backend and track the event.
   */
  sendFeedback: (props: SendFeedbackProps) => Promise<void>;
}

/**
 * Hook to send feedback event to the backend and to track them.
 * Use the functions here instead of calling the API directly,
 * since some values are automatically filled in.
 */
export function useFeedback(): UseFeedbackHook {
  const coreApiClient = useCoreApiClient();
  const selectedCompany = useAppSelector(selectedSdbCompanySelector);
  const selectedProject = useAppSelector(selectedProjectSelector);
  const { showToast } = useToast();
  const { handleErrorWithToast } = useErrorContext();
  const { trackEvent } = useTrackEvent();

  /**
   * Sends a request to the backend to send a feedback email, and to track the event in Amplitude.
   * This function handles the error and shows a toast if the request fails, therefore does not
   * have to use try/catch since it will always resolve the promise.
   */
  async function sendFeedback({
    featureName,
    feedbackMessage,
    selectedRating,
  }: SendFeedbackProps): Promise<void> {
    const customMessage = getFeedbackMessage({
      selectedRating,
      feedbackMessage,
    });

    const subject = `SDB Feedback: ${featureName}`;

    trackEvent({
      name: FeedbackModalEvents.submitFeedback,
      props: {
        topic: subject,
        rating: selectedRating?.toString() ?? "",
        didProvideFeedback: Boolean(feedbackMessage),
      },
    });

    try {
      await coreApiClient.V3.notificationRequest({
        featureName: "Sphere-Dashboard",
        notificationSubject: subject,
        featureComment: customMessage,
        notificationType: CoreAPITypes.ENotificationType.productFeedback,
        companyId: selectedCompany?.id,
        projectId: selectedProject?.id?.toString(),
      });
      showToast({
        message: "Your request has been received.",
        description: "Thank you for reaching out, we appreciate your feedback.",
        type: "success",
      });
    } catch (error) {
      handleErrorWithToast({
        id: `sendFeedback-${Date.now().toString()}`,
        title: "Error sending feedback",
        error,
      });
    }
  }

  return {
    sendFeedback,
  };
}
