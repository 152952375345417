import { Avatar, Box } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { BlockedClasses } from "@utils/localization";
import { getSvgColoredIconCss } from "@utils/ui-utils";
import { CSSProperties, ReactNode, useMemo } from "react";

interface Props {
  /**
   * This avatar is generic enough to show 1-2 letters inside an Avatar with the Sphere look and feel.
   * Those letter are called initials since it is mainly intended to be used for user's initials.
   * This property won't be used if either "src" or "icon" are provided.
   */
  initials?: string;

  /**
   * Optional src to an image to be displayed inside the avatar.
   * This is the preferred option to show for users, therefore, if it is not undefined,
   * this property will be used regardless whether "initials" or "icon" were also provided.
   */
  src?: string;

  /**
   * Optional icon to be shown inside the avatar. If provided, this
   * property will be used even if "initials" was provided, but it will be ignored if the "src" attribute
   * was provided.
   * To make sure the icon fits properly in the avatar, please use one of the constants
   * ICON_LARGE_STYLE or ICON_SMALL_STYLE in your icon.
   * e.g., <EmailIcon size="large" style={ICON_LARGE_STYLE} /> or <EmailIcon style={ICON_SMALL_STYLE} />
   */
  icon?: ReactNode;

  /**
   * Optional color for the icon. Default white.
   */
  iconColor?: string;

  /**
   * Optional background color for the avatar. Default gray400.
   */
  backgroundColor?: string;

  /** If true then some specific stylings are not shown such as bordering around avatar */
  shouldUseSimplifiedAvatar?: boolean;

  /**
   * Size of the avatar
   * - 108px for the x-large version
   * - 60px for the large version
   * - 48px for the medium
   * - 36px for the small version
   * - 20px for the x-small version
   */
  size?: "x-small" | "small" | "medium" | "large" | "x-large";

  /** If true removes the white rim between the avatar and the blue wrapper when shouldUseSimplifiedAvatar is false */
  shouldHideWhiteRim?: boolean;
}

/** Defines the style for icons where the avatar has large size. */
export const ICON_LARGE_STYLE: CSSProperties = {
  width: "31px",
  height: "31px",
};

/** Defines the style for icons where the avatar has small size. */
export const ICON_SMALL_STYLE: CSSProperties = {
  width: "16px",
  height: "16px",
};

export function SphereAvatar({
  initials = "",
  src,
  icon,
  iconColor = sphereColors.pureWhite,
  backgroundColor = sphereColors.gray400,
  size = "small",
  shouldUseSimplifiedAvatar = true,
  shouldHideWhiteRim = false,
}: Props): JSX.Element {
  const avatarProps = useMemo(() => {
    if (size === "x-small") {
      return {
        fontSize: initials.length <= 2 ? "8px" : "6px",
        fontWeight: "400",
        avatarSize: shouldHideWhiteRim ? "20px" : "16px",
        avatarMargin: 0,
        innerWrapperSize: shouldHideWhiteRim ? "0" : "16px",
        innerWrapperOffset: "2px",
        wrapperSize: shouldHideWhiteRim ? "20px" : "20px",
      };
    }
    if (size === "small") {
      return {
        fontSize: initials.length <= 2 ? "12px" : "10px",
        fontWeight: "400",
        avatarSize: shouldHideWhiteRim ? "36px" : "28px",
        avatarMargin: shouldHideWhiteRim ? "2px" : "4px",
        innerWrapperSize: shouldHideWhiteRim ? "0px" : "32px",
        innerWrapperOffset: "2px",
        wrapperSize: shouldHideWhiteRim ? "40px" : "36px",
      };
    }
    if (size === "medium") {
      return {
        fontSize: initials.length <= 2 ? "16px" : "14px",
        fontWeight: "400",
        avatarSize: shouldHideWhiteRim ? "44px" : "40px",
        avatarMargin: shouldHideWhiteRim ? "2px" : "4px",
        innerWrapperSize: shouldHideWhiteRim ? "0px" : "44px",
        innerWrapperOffset: "2px",
        wrapperSize: "48px",
      };
    }
    if (size === "x-large") {
      return {
        fontSize: "35px",
        fontWeight: "500",
        avatarSize: shouldHideWhiteRim ? "100px" : "86px",
        avatarMargin: shouldHideWhiteRim ? "4px" : "11px",
        innerWrapperSize: shouldHideWhiteRim ? "0px" : "98px",
        innerWrapperOffset: "5px",
        wrapperSize: "108px",
      };
    }
    return {
      fontSize: initials.length <= 2 ? "22px" : "20px",
      fontWeight: "500",
      avatarSize: shouldHideWhiteRim ? "54px" : "52px",
      avatarMargin: shouldHideWhiteRim ? "3px" : "4px",
      innerWrapperSize: shouldHideWhiteRim ? "0px" : "56px",
      innerWrapperOffset: "2px",
      wrapperSize: "60px",
    };
  }, [size, initials, shouldHideWhiteRim]);

  return (
    /* Wrapper outside component. It holds the size of the avatar but does not show anything */
    <Box
      component="div"
      style={{
        position: "relative",
        width: avatarProps.wrapperSize,
        minWidth: avatarProps.wrapperSize,
        height: avatarProps.wrapperSize,
      }}
    >
      {/* This wrapper it is in charge of showing the blue outer rim */}
      <Box
        component="div"
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          borderRadius: "50%",
          background: shouldUseSimplifiedAvatar
            ? "none"
            : // eslint-disable-next-line max-len -- the logic is simply too large
              "transparent linear-gradient(159deg, #009CDE 0%, #3455DB 38%, #3354D8 52%, #2E53CE 64%, #2650BD 74%, #1C4CA5 84%, #0E4787 93%, #01426A 100%) 0% 0% no-repeat padding-box",
        }}
      />
      {/* This wrapper it is in charge of showing the white inner rim */}
      <Box
        component="div"
        style={{
          position: "absolute",
          top: avatarProps.innerWrapperOffset,
          left: avatarProps.innerWrapperOffset,
          width: avatarProps.innerWrapperSize,
          height: avatarProps.innerWrapperSize,
          borderRadius: "50%",
          background: "white",
        }}
      />
      {/* This wrapper holds the size and position of material UI avatar component */}
      <Box
        component="div"
        style={{
          position: "absolute",
          width: avatarProps.avatarSize,
          height: avatarProps.avatarSize,
          marginLeft: avatarProps.avatarMargin,
          marginTop: avatarProps.avatarMargin,
        }}
      >
        <Avatar
          className={BlockedClasses.noLocalize}
          sx={{
            bgcolor: backgroundColor,
            width: avatarProps.avatarSize,
            height: avatarProps.avatarSize,
            fontSize: avatarProps.fontSize,
            fontWeight: avatarProps.fontWeight,
            letterSpacing: "0.6px",
          }}
          src={src}
          alt={initials}
        >
          {/* Show preferably icon if provided */}
          {icon && (
            <Box
              component="div"
              sx={{
                display: "flex",
                alignItems: "center",
                ...getSvgColoredIconCss(iconColor),
              }}
            >
              {icon}
            </Box>
          )}

          {/* Only show initials if icon is not provided */}
          {!icon && <var>{initials}</var>}
        </Avatar>
      </Box>
    </Box>
  );
}
