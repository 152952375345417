import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { RootState } from "@store/store-helper";
import { projectMembersSelector } from "@store/projects/projects-selector";
import { MemberRoleTypes } from "@custom-types/member-types";
import { UserState } from "@store/user/user-slice";
import { createSelector } from "@reduxjs/toolkit";
import { SplashScreenSdbTopic } from "@components/splash-screen/splash-screen-data";
import { SplashScreenViewStatus } from "@components/splash-screen/splash-screen-utils";

/**
 * Returns the current logged in user.
 *
 * @param state store state
 * @returns current logged in user
 */
export const currentUserSelector: (
  state: RootState
) => SphereDashboardAPITypes.ICompanyMemberBase | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.user.currentUser;
  }
);

/**
 * Returns  extra information about current logged in user.
 * For details like name, email, etc. use currentUser.
 * This is only used for information like login method, SSO provider, etc.
 *
 * @param state store state
 * @returns current logged in user
 */
export const loggedInUserSelector: (
  state: RootState
) => SphereDashboardAPITypes.IUserDetails | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.user.loggedInUser?.data ?? null;
  }
);

/**
 * Returns extra information about current logged in user
 * This includes the response status and code to determine verified email.
 *
 * @param state store state
 * @returns current logged in user with status & code
 */
export const loggedInUserWithStatusSelector: (
  state: RootState
) => SphereDashboardAPITypes.IGetLoggedInUserResponse | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.user.loggedInUser;
  }
);

/**
 * Returns the role of current logged in user in the project
 *
 * @param state store state
 * @returns role of current user
 */
export const currentUserRoleInProjectSelector: (
  state: RootState
) => MemberRoleTypes | undefined = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const projectMembers = projectMembersSelector(state);
    const currentUserAsMember = projectMembers.find(
      (member) => member.identity === state.user.currentUser?.identity
    );
    return currentUserAsMember?.role;
  }
);

/**
 * Returns the fetching properties of the user slice.
 */
export const fetchingUserFlagsSelector: (
  state: RootState
) => UserState["fetching"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.user.fetching;
  }
);

/**
 * Returns true if the provided user identity is related to the current user
 */
export function isCurrentUserSelector(
  userIdentity: string
): (state: RootState) => boolean {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return currentUserSelector(state)?.identity === userIdentity;
    }
  );
}

/**
 * Returns the email of the user that is being updated.
 */
export const updatedEmailSelector: (state: RootState) => string | undefined =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.user.updatedEmail;
    }
  );

/** Returns the SDB splash screens based on view status */
export function splashScreenTopicsByStatusSelector(
  status: SplashScreenViewStatus
): (state: RootState) => SplashScreenSdbTopic[] {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      // If loggedInUser data is not available, then it means that the FUE flags determining
      // the splashScreenStatus is not available yet. In this case, we need to hide the splash screens otherwise,
      // we will show them all every time user refreshes the page.
      // TODO: Make sure loggedInUser is always available: https://faro01.atlassian.net/browse/ST-1623
      if (loggedInUserSelector(state) === null) {
        return [];
      }
      return (
        Object.keys(state.user.splashScreens.sdb) as SplashScreenSdbTopic[]
      ).filter((topic) => state.user.splashScreens.sdb[topic] === status);
    }
  );
}
