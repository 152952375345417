import { styled } from "@mui/material";
import { GridRow, GridRowProps, RowPropsOverrides } from "@mui/x-data-grid-pro";
import { SHOW_ON_HOVER_CLASS } from "@utils/ui-utils";

/**
 * Custom row to show some elements on hover
 */
export const FaroCustomRow = styled(
  (props: GridRowProps & RowPropsOverrides) => <GridRow {...props} />
)({
  [`&:hover .${SHOW_ON_HOVER_CLASS}`]: {
    // Use !important to make sure that the style is applied, since we can't be more specific
    visibility: "visible !important",
  },
});
