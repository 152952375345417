import { GroupDetailsInfoBar } from "@components/group-details/group-details-info-bar";
import { BaseGroupDetailsProps } from "@custom-types/group-types";
import { useAppParams } from "@router/router-helper";
import { NotFoundPage } from "@pages/not-found-page";
import { GroupManagersTable } from "@pages/group-details/group-managers-table";

interface Props extends Partial<BaseGroupDetailsProps> {
  /** Flag whether the content should be shown as skeletons because it is still loading */
  isLoading?: boolean;
}

/**
 * Contains the Managers tab of the group details
 */
export function GroupManagers({
  group,
  isLoading = false,
}: Props): JSX.Element {
  const { companyId } = useAppParams();

  if (!companyId) {
    return <NotFoundPage />;
  }

  return (
    <>
      <GroupDetailsInfoBar group={group} isLoading={isLoading} />
      <GroupManagersTable
        group={group}
        companyId={companyId}
        isLoading={isLoading}
      />
    </>
  );
}
