import { useAppSelector } from "@store/store-helper";
import { useAppNavigation } from "@hooks/use-app-navigation";
import { useAppParams } from "@router/router-helper";
import { selectedProjectSelector } from "@store/projects/projects-selector";
import { PropsWithChildren } from "react";
import { FullPageLayout } from "@components/full-page-layout";
import { sphereColors } from "@styles/common-colors";

/** Renders the layout of the project data management page. */
export function DataManagementLayout({
  children,
}: PropsWithChildren): JSX.Element {
  const { navigateToProjectDetail } = useAppNavigation();
  const { companyId } = useAppParams();
  const project = useAppSelector(selectedProjectSelector);

  /** On close button click redirect users to project details page */
  function onCloseButtonClick(): void {
    if (companyId && project) {
      navigateToProjectDetail({ companyId, projectId: project.id });
    }
  }

  return (
    <FullPageLayout
      title={project?.name}
      onClose={onCloseButtonClick}
      backgroundColor={sphereColors.gray50}
    >
      {children}
    </FullPageLayout>
  );
}
