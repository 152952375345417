import { Box } from "@mui/material";
import { CoreAPITypes } from "@stellar/api-logic";
import { withEllipsisThreeLines } from "@styles/common-styles";
import {
  getSnapshotAccessColors,
  getSnapshotAccessDisplayName,
} from "@utils/project-utils";
import { useMemo } from "react";

interface Props {
  /** The access level for the snapshot */
  accessLevel: CoreAPITypes.IProjectSnapshot["accessLevel"];
}

/**
 * Renders a badge with the access level of a snapshot, depending
 * on the access level it adjusts the background color and the text.
 */
export function SnapshotAccessLevelBadge({ accessLevel }: Props): JSX.Element {
  /** The style is adapted depending on the access level. */
  const badgeStyle = useMemo(() => {
    return {
      ...getSnapshotAccessColors(accessLevel),
      text: getSnapshotAccessDisplayName(accessLevel),
    };
  }, [accessLevel]);

  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        width: "fit-content",
        minHeight: "22px",
        paddingX: "12px",
        paddingY: "2px",
        borderRadius: "12px",
        fontSize: "12px",
        backgroundColor: badgeStyle.backgroundColor,
        color: badgeStyle.textColor,
        ...withEllipsisThreeLines,
      }}
    >
      {badgeStyle.text}
    </Box>
  );
}
