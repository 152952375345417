import { createContext, useContext, PropsWithChildren } from "react";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { MemberTypes } from "@custom-types/member-types";
import { SphereDashboardAPITypes } from "@stellar/api-logic";

interface DataManagementContextProps extends BaseProjectIdProps {
  /** List of company members */
  companyMembers: MemberTypes[];

  /** List of project members */
  projectMembers: SphereDashboardAPITypes.IProjectMemberBase[];
}

/** Create the context for the data management */
const DataManagementContext = createContext<
  DataManagementContextProps | undefined
>(undefined);

/**
 * Component that provides the context for the data management.
 *
 * @param children The child components to be wrapped by the provider.
 * @param projectId The ID of the project.
 * @returns JSX element representing the provider.
 */
export function DataManagementProvider({
  children,
  projectId,
  companyMembers,
  projectMembers,
}: PropsWithChildren<DataManagementContextProps>): JSX.Element | null {
  return (
    <DataManagementContext.Provider
      value={{
        projectId,
        companyMembers,
        projectMembers,
      }}
    >
      {children}
    </DataManagementContext.Provider>
  );
}

/**
 * Hook to use the data management context.
 *
 * @returns The data management context, containing the project ID.
 * @throws An error if the hook is used outside of a DataManagementProvider.
 */
export function useDataManagementContext(): DataManagementContextProps {
  const context = useContext(DataManagementContext);

  if (!context) {
    throw new Error(
      "useDataManagementContext must be used within a DataManagementProvider"
    );
  }

  return context;
}
