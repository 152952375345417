import { isIElementAreaSectionWithUrl } from "@custom-types/i-elements-type-guards";
import { isSlideNodes } from "@custom-types/slide-nodes-type-guards";
import { IElement } from "@faro-lotv/ielement-types";
import { CoreAPITypes } from "@stellar/api-logic";
import { DownloadUtils } from "@stellar/web-core";
import { getAreaSections } from "@utils/i-elements-utils";

/** Gets all the available URLs to download the slides nodes for the given array of elements */
export function getSlideNodesUrls(elements: IElement[]): string[] {
  const areaSections = getAreaSections(elements);
  const areaSectionsWithUrl = areaSections.filter(isIElementAreaSectionWithUrl);
  return areaSectionsWithUrl.map(
    (section) => section.metaDataMap.slideNodesUrl
  );
}

/**
 * Downloads and returns the slide nodes from the passed download URL
 *
 * @returns An array of slide nodes
 * @throws {error} if it fails to download the slide nodes
 */
export async function downloadSlideNodes(
  slideNodesUrl: string
): Promise<CoreAPITypes.IArSlideJson[]> {
  try {
    // Download the raw data (Blob object), get it in JSON string and parse it.
    const blob = await DownloadUtils.downloadFilePromise(slideNodesUrl);
    const dataString = await blob.text();
    const data = JSON.parse(dataString);

    if (!isSlideNodes(data)) {
      throw new Error(
        "The download slide nodes object does not have the expected type"
      );
    }

    return data.slideNodes;
  } catch (error) {
    return [];
  }
}
