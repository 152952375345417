import { Grid, Breakpoint, Skeleton } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { CommonStyles } from "@styles/common-styles";

/**
 * Returns how many items should be shown per row based on the screen size.
 */
const defaultItemsPerRow: { [key in Breakpoint]: number } = {
  xs: 1,
  sm: 2,
  md: 4,
  lg: 4,
  xl: 6,
};

/**
 * Returns the size that the columns should have to fit the provided elements.
 * The key is the amount of elements to fit
 * The value is the size in MUI columns (1-12).
 */
const colsSize = {
  one: 12,
  twoThird: 8,
  two: 6,
  three: 4,
  four: 3,
  six: 2,
  twelve: 1,
};

/**
 * Calculates the size of the columns based on the amount of items to be shown for SM screens.
 * The key is the amount of shown items.
 * The value is the size of the columns.
 */
const colsSizeSm: { [key: number]: number } = {
  /* eslint-disable @typescript-eslint/naming-convention -- The namings use a provided value */
  1: colsSize.one,
  2: colsSize.two,
  /* eslint-enable */
};

/**
 * Calculates the size of the columns based on the amount of items to be shown for MD screens.
 * The key is the amount of shown items.
 * The value is the size of the columns.
 */
const colsSizeMd: { [key: number]: number } = {
  /* eslint-disable @typescript-eslint/naming-convention -- The namings use a provided value */
  1: colsSize.one,
  2: colsSize.two,
  3: colsSize.three,
  4: colsSize.four,
  /* eslint-enable */
};

/**
 * Calculates the size of the columns based on the amount of items to be shown for XL screens.
 * The key is the amount of shown items.
 * The value is the size of the columns.
 */
const colsSizeLg: { [key: number]: number } = {
  /* eslint-disable @typescript-eslint/naming-convention -- The namings use a provided value */
  1: colsSize.one,
  2: colsSize.two,
  3: colsSize.three,
  4: colsSize.four,
  /* eslint-enable */
};

/**
 * Calculates the size of the columns based on the amount of items to be shown for XL screens.
 * The key is the amount of shown items.
 * The value is the size of the columns.
 */
const colsSizeXl: { [key: number]: number } = {
  /* eslint-disable @typescript-eslint/naming-convention -- The namings use a provided value */
  1: colsSize.one,
  2: colsSize.two,
  3: colsSize.three,
  4: colsSize.four,
  5: colsSize.six,
  6: colsSize.six,
  /* eslint-enable */
};

/** Default gray vertical border to show in between items */
const DEFAULT_BORDER = CommonStyles.Borders.gray200Divider;

/** Default height in pixels of the info bar */
const DEFAULT_HEIGHT = 80;

/** Interface for the getItemsHeight function props*/
interface GetItemsHeightProps {
  /** Flag whether the action button is always shown in the same row */
  isActionsInSameRow: boolean;

  /** Number of items to show per row */
  itemsPerRow: number;

  /** Height in pixels for each item */
  itemHeight: number;

  /** Height in pixels to add if there's an action button */
  actionsHeight: number;
}

/** Single item (column) in the information bar. */
interface Item {
  content: React.ReactNode;

  /**
   * Determines how many column should an item take by being a divisor of the number of total column.
   * Eg. columnSize = 3; 12 / 3 = 4; That means the item will take col-4.
   */
  columnSize?: number;
}

interface Props {
  /** All items (columns) to be shown in the information bar */
  items?: Item[];

  /** Extra action button that will be shown in the right side of the info bar. */
  action?: React.ReactNode;

  /** Whether the info bar is loading and skeletons should be shown instead */
  isLoading?: boolean;

  /** Custom items per row values that override the default items per row */
  customItemsPerRow?: { [key in Breakpoint]: number };

  /** Size in px for the minimum height of the bar. Default value is 80px */
  barHeight?: number;
}

/**
 * Component that shows an information bar about the current page.
 */
export function PageInfoBar({
  items = [],
  action,
  isLoading,
  customItemsPerRow,
  barHeight,
}: Props): JSX.Element {
  const itemsPerRow = customItemsPerRow || defaultItemsPerRow;

  /**
   * Gets how many rows are currently shown based on the items to show per row.
   *
   * @param itemsPerRow Number of items to show per row.
   * @returns Numbers of rows that are currently being shown.
   */
  function getRows(itemsPerRow: number): number {
    return Math.ceil(items.length / itemsPerRow);
  }

  /**
   * Gets the size in pixels for the bar component based on the number
   * of rows to be shown and whether it has an action button.
   *
   * @param isActionsInSameRow Flag whether the action button is always shown in the same row
   * @param itemsPerRow Number of items to show per row.
   * @param itemHeight Height in pixels for each item.
   * @param actionsHeight Height in pixels to add if there's an action button.
   * @returns The size in pixels to be used by CSS.
   */
  function getItemsHeight({
    isActionsInSameRow,
    itemsPerRow,
    itemHeight,
    actionsHeight,
  }: GetItemsHeightProps): string {
    const rows = getRows(itemsPerRow);
    /** Size in px for the minimum height of the bar */
    const baseBarHeight = barHeight || DEFAULT_HEIGHT;
    const actualActionsHeight =
      !isActionsInSameRow && action ? actionsHeight : 0;
    return `${baseBarHeight + actualActionsHeight + (rows - 1) * itemHeight}px`;
  }

  /**
   * Gets the size in pixels for the bar component in XS size based on the number
   * of rows to be shown and whether it has an action button.
   *
   * @param itemsPerRow Number of items to show per row.
   * @returns The size in pixels to be used by CSS.
   */
  function getItemsHeightXs(itemsPerRow: number): string {
    return getItemsHeight({
      itemsPerRow,
      isActionsInSameRow: false,
      itemHeight: 47,
      actionsHeight: 50,
    });
  }

  /**
   * Gets the size in pixels for the bar component in SM size based on the number
   * of rows to be shown and whether it has an action button.
   *
   * @param itemsPerRow Number of items to show per row.
   * @returns The size in pixels to be used by CSS.
   */
  function getItemsHeightSm(itemsPerRow: number): string {
    return getItemsHeight({
      itemsPerRow,
      isActionsInSameRow: false,
      itemHeight: 40,
      actionsHeight: 50,
    });
  }

  /**
   * Gets the size in pixels for the bar component in MD size based on the number
   * of rows to be shown and whether it has an action button.
   *
   * @param itemsPerRow Number of items to show per row.
   * @returns The size in pixels to be used by CSS.
   */
  function getItemsHeightMd(itemsPerRow: number): string {
    return getItemsHeight({
      itemsPerRow,
      isActionsInSameRow: false,
      itemHeight: 50,
      actionsHeight: 45,
    });
  }

  /**
   * Gets the size in pixels for the bar component in LG size based on the number
   * of rows to be shown and whether it has an action button.
   *
   * @param itemsPerRow Number of items to show per row.
   * @returns The size in pixels to be used by CSS.
   */
  function getItemsHeightLgAndXL(itemsPerRow: number): string {
    return getItemsHeight({
      itemsPerRow,
      isActionsInSameRow: true,
      itemHeight: 50,
      actionsHeight: 0,
    });
  }

  /**
   * Calculates whether the left border should be shown.
   * Avoids showing the left border in the first element of each row.
   *
   * @param index Item's index starting from 0.
   * @param itemsPerRow How many items are shown per row.
   * @returns True if the left border should be shown.
   */
  function shouldShowLeftBorder(index: number, itemsPerRow: number): boolean {
    return index > 0 && !(index % itemsPerRow === 0);
  }

  /**
   * Calculates whether the index correspond to the last item.
   *
   * @param index Item's index starting from 0.
   * @returns True if it is the last item.
   */
  function isLastItem(index: number): boolean {
    return index === items.length - 1;
  }

  if (isLoading) {
    return <Skeleton variant="rectangular" height="80px" sx={{ mt: "12px" }} />;
  }

  return (
    <Grid
      container
      sx={{
        py: 0,
        mt: "12px",
        backgroundColor: sphereColors.gray50,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: {
          xs: getItemsHeightXs(itemsPerRow.xs),
          sm: getItemsHeightSm(itemsPerRow.sm),
          md: getItemsHeightMd(itemsPerRow.md),
          lg: getItemsHeightLgAndXL(itemsPerRow.lg),
          xl: getItemsHeightLgAndXL(itemsPerRow.xl),
        },
      }}
    >
      <Grid
        item
        xs={colsSize.one}
        lg={action ? colsSize.twoThird : colsSize.one}
        sx={{
          py: 0,
          my: 0,
        }}
      >
        <Grid
          container
          sx={{
            py: 0,
            my: 0,
          }}
        >
          {items.map((item, index) => (
            <Grid
              key={`infoBarItem-${index}`}
              item
              xs={colsSize.one}
              sm={colsSizeSm[item.columnSize ?? items.length] ?? colsSize.two}
              md={colsSizeMd[item.columnSize ?? items.length] ?? colsSize.six}
              lg={colsSizeLg[item.columnSize ?? items.length] ?? colsSize.six}
              xl={colsSizeXl[item.columnSize ?? items.length] ?? colsSize.six}
              sx={{
                px: "20px",
                pt: "5px",
                width: "500px",
                borderBottom: {
                  xs: isLastItem(index) ? "none" : DEFAULT_BORDER,
                  sm: "none",
                },
                borderLeft: {
                  xs: "none",
                  sm: shouldShowLeftBorder(index, itemsPerRow.sm)
                    ? DEFAULT_BORDER
                    : "none",
                  md: shouldShowLeftBorder(index, itemsPerRow.md)
                    ? DEFAULT_BORDER
                    : "none",
                  lg: shouldShowLeftBorder(index, itemsPerRow.lg)
                    ? DEFAULT_BORDER
                    : "none",
                  xl: shouldShowLeftBorder(index, itemsPerRow.xl)
                    ? DEFAULT_BORDER
                    : "none",
                },
              }}
            >
              <Grid component="div">{item.content}</Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      {action && (
        <Grid
          item
          xs={colsSize.one}
          lg={colsSize.three}
          sx={{
            py: 0,
            my: 0,
            mt: {
              xs: 0,
              sm: "10px",
            },
            mb: "10px",
            textAlign: {
              xs: "center",
              lg: "end",
            },
          }}
        >
          <Grid
            item
            sx={{
              px: "20px",
            }}
          >
            {action}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
