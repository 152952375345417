import { SphereToggleButtonGroup } from "@components/common/sphere-toggle-button-group";
import { ProjectDataType } from "@custom-types/project-data-types";
import { ValueLabelItem } from "@custom-types/types";
import { useAppNavigation } from "@hooks/use-app-navigation";
import { Grid } from "@mui/material";
import { QueryParams } from "@router/route-params";
import { sphereColors } from "@styles/common-colors";

/** Available data selector items */
const DATA_SELECTOR_ITEMS: ValueLabelItem<ProjectDataType>[] = [
  {
    value: ProjectDataType.pointClouds,
    label: "Point clouds",
  },
  {
    value: ProjectDataType.sheets,
    label: "Sheets",
  },
  {
    value: ProjectDataType.video,
    label: "Video",
  },
];

export interface ProjectDataBarProps {
  /** Selected project data type */
  selectedDataType: ProjectDataType;

  /** Function to set the selected data type */
  setSelectedDataType: (value: ProjectDataType) => void;
}

/** Component that renders the project data bar: data type selector and filters */
export function ProjectDataBar({
  selectedDataType,
  setSelectedDataType,
}: ProjectDataBarProps): JSX.Element {
  const { setUrlParam } = useAppNavigation();

  /**
   * Sets the current data type.
   * It also sets the "type" query param in order to have a sharable deep link with the currently displayed data type.
   */
  function handleDataTypeChange(value: ProjectDataType): void {
    setSelectedDataType(value);
    setUrlParam({
      key: QueryParams.type,
      value,
    });
  }

  return (
    <Grid
      data-testid="project-data-bar-container"
      container
      sx={{
        justifyContent: "space-between",
        marginBottom: "45px",
        backgroundColor: sphereColors.gray50,
      }}
    >
      {/* Type selector */}
      <Grid
        sx={{
          height: "54px",
          alignContent: "center",
          marginX: "12px",
        }}
      >
        <SphereToggleButtonGroup
          items={DATA_SELECTOR_ITEMS}
          currentValue={selectedDataType}
          handleChange={handleDataTypeChange}
        />
      </Grid>
    </Grid>
  );
}
