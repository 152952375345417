import { CompanyAvatar } from "@components/common/company-avatar";
import { SdbCompany } from "@custom-types/sdb-company-types";
import { Box } from "@mui/system";
import { CoreAPITypes } from "@stellar/api-logic";
import { sphereColors } from "@styles/common-colors";
import { withEllipsis } from "@styles/common-styles";
import { formatUserRoleType } from "@utils/data-display";
import { SphereTooltip } from "@components/common/sphere-tooltip";

interface Props {
  /** The workspace to be displayed, can be the admin panel or a normal workspace */
  sdbCompany: SdbCompany;

  /** Optional function to be called when the item is clicked */
  onClick?: () => void;
}

export const LINK_HEIGHT = "70px";

/**
 * Shows a the link for a workspace to be selected in the workspaces selection page.
 */
export function SelectWorkspaceLink({
  sdbCompany,
  onClick,
}: Props): JSX.Element {
  return (
    <Box
      component="a"
      href={sdbCompany.url}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: sphereColors.pureWhite,
        height: LINK_HEIGHT,
        paddingX: "20px",
        borderRadius: "4px",
        // Needed to avoid the decoration because we are using a link
        textDecoration: "none",
        // Needed to avoid the decoration because we are using a link
        color: "inherit",
        "&:hover": {
          boxShadow: `0 0 0 1px ${sphereColors.blue500}`,
          cursor: "pointer",
        },
      }}
      onClick={onClick}
    >
      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          ...withEllipsis,
        }}
      >
        <Box component="div" width="32px">
          <CompanyAvatar
            name={sdbCompany.name}
            color={
              sdbCompany.type === CoreAPITypes.EWorkspaceType.admin
                ? sphereColors.black
                : sphereColors.blue500
            }
            size="x-large"
          />
        </Box>
        <SphereTooltip
          title={<var>{sdbCompany.name}</var>}
          boxProps={{
            sx: {
              ...withEllipsis,
              marginX: "20px",
              fontSize: "14px",
              fontWeight: "500",
            },
          }}
        >
          <var>{sdbCompany.name}</var>
        </SphereTooltip>
      </Box>
      {sdbCompany.role.company && (
        <Box component="div">
          {formatUserRoleType(sdbCompany.role.company) ?? ""}
        </Box>
      )}
    </Box>
  );
}
