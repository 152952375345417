import { hasUserValidRoleSnapshotLevel } from "@utils/access-control/snapshot/snapshot-access-control";
import {
  HasUserValidRoleSnapshotLevelProps,
  RequiredRoleSnapshotLevelName,
} from "@utils/access-control/snapshot/snapshot-access-control-types";

export type ReturnProps = {
  [key in RequiredRoleSnapshotLevelName]: boolean;
};

/** Checks whether a user has permission for an action on snapshot level or not */
export function useHasUserValidRoleSnapshotLevel({
  selectedSnapshot,
}: Pick<HasUserValidRoleSnapshotLevelProps, "selectedSnapshot">): ReturnProps {
  const isSnapshotAdmin = hasUserValidRoleSnapshotLevel({
    roleName: RequiredRoleSnapshotLevelName.isSnapshotAdmin,
    selectedSnapshot,
  });

  return { isSnapshotAdmin };
}
