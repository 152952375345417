import NoWorkspacesIcon from "@assets/icons/new/no_workspaces_565px.svg";
import { SphereSupportLink } from "@components/common/sphere-support-link";
import { ErrorPageBase } from "@pages/error-page-base";

/**
 * Component to be shown inside the workspace selector page when the user does not have access to any workspace.
 */
export function NoWorkspace(): JSX.Element {
  return (
    <ErrorPageBase
      iconImg={NoWorkspacesIcon}
      title="No workspaces found"
      shouldShowSupportBtn={true}
    >
      You do not have access to a workspace. If you are having trouble accessing
      your workspace or want to buy a subscription, please contact{" "}
      <SphereSupportLink />
    </ErrorPageBase>
  );
}
