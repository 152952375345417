import { ReactNode } from "react";
import { MainRoutes } from "@router/route-params";
import ProjectsIcon from "@assets/icons/projects.svg?react";
import MembersIcon from "@assets/icons/members.svg?react";
import GroupsIcon from "@assets/icons/new/groups_24px.svg?react";
import AnalyticsIcon from "@assets/icons/new/analytics_24px.svg?react";
import SettingsIcon from "@assets/icons/new/settings_24px.svg?react";
import AddOnsIcon from "@assets/icons/new/add-ons.svg?react";
import { commonStrings } from "@assets/common-strings";
import { RequiredRoleCompanyLevelName } from "@utils/access-control/company/company-access-control-types";
import { getHBDashboardUrl } from "@components/sidebar/sidebar-holobuilder/sidebar-holobuilder-utils";
import HoloBuilderIcon from "@assets/icons/new/holobuilder_36px.svg?react";

/**
 * All the items that can be seen in the sidebar.
 * The reason this was changed from an enum to a type is that typescript enums do not support
 * strings from variables (const or not)
 */
export type ESidebarItem =
  | typeof commonStrings.PROJECTS
  | typeof commonStrings.MEMBERS
  | typeof commonStrings.FEEDBACK
  | typeof commonStrings.GROUPS
  | typeof commonStrings.ANALYTICS
  | typeof commonStrings.SETTINGS
  | typeof commonStrings.HOLOBUILDER
  | typeof commonStrings.ADDONS;

export interface SidebarItemProps {
  /** The unique identity of the sidebar item */
  identity: ESidebarItem;

  /** The icon to show next to the sidebar item */
  icon: ReactNode;

  /** The title of the sidebar item */
  title: ESidebarItem;

  /** The route in which the icon should navigate to */
  route: string;
  /**
   * the value used to identify each Nav link of the sideBar by a unique id
   * this value is used for our automation framework playwright
   */
  dataTestId: string;

  /**
   * Defines the required company role that the user should have,
   * if the user matches any of the provided requiredCompanyRoles, the component
   * will show the item, otherwise it will hide it.
   */
  requiredRoleCompanyLevel?: RequiredRoleCompanyLevelName;

  /**
   * Optional date to show a "new" badge on the sidebar item.
   * Once the date is passed, the badge will be removed.
   */
  hideNewBadgeDate?: string;

  /**
   * Whether the sidebar item should show only in beta testing
   */
  shouldShowOnlyInBeta?: boolean;
}

/** The items to show in the top of the sidebar */
export const SidebarItems: SidebarItemProps[] = [
  {
    identity: commonStrings.PROJECTS,
    icon: <ProjectsIcon />,
    title: commonStrings.PROJECTS,
    route: MainRoutes.projects,
    dataTestId: "SIDEBAR_PROJECT_BUTTON",
  },
  {
    identity: commonStrings.MEMBERS,
    icon: <MembersIcon />,
    title: commonStrings.MEMBERS,
    route: MainRoutes.members,
    dataTestId: "SIDEBAR_USER_DIRECTORY_BUTTON",
    requiredRoleCompanyLevel:
      RequiredRoleCompanyLevelName.canViewAllCompanyUsers,
  },
  {
    identity: commonStrings.GROUPS,
    icon: <GroupsIcon />,
    title: commonStrings.GROUPS,
    route: MainRoutes.groups,
    dataTestId: "SIDEBAR_GROUPS_BUTTON",
    requiredRoleCompanyLevel:
      RequiredRoleCompanyLevelName.canViewAllCompanyGroups,
  },
  {
    identity: commonStrings.ANALYTICS,
    icon: <AnalyticsIcon />,
    title: commonStrings.ANALYTICS,
    route: MainRoutes.analytics,
    dataTestId: "SIDEBAR_ANALYTICS_BUTTON",
    requiredRoleCompanyLevel: RequiredRoleCompanyLevelName.canViewAnalytics,
  },
  {
    identity: commonStrings.ADDONS,
    icon: <AddOnsIcon />,
    title: commonStrings.ADDONS,
    route: MainRoutes.addons,
    dataTestId: "SIDEBAR_ADD_ONS_BUTTON",
  },
  {
    identity: commonStrings.SETTINGS,
    icon: <SettingsIcon />,
    title: commonStrings.SETTINGS,
    route: MainRoutes.settings,
    dataTestId: "SIDEBAR_WORKSPACE_SETTINGS_BUTTON",
    requiredRoleCompanyLevel:
      RequiredRoleCompanyLevelName.canViewWorkspaceSettings,
  },
];

/** The items to show in the bottom of the sidebar */
export const SidebarHBItem: SidebarItemProps = {
  identity: commonStrings.HOLOBUILDER,
  icon: <HoloBuilderIcon width="24px" height="24px" />,
  title: commonStrings.HOLOBUILDER,
  route: getHBDashboardUrl().href,
  dataTestId: "SIDEBAR_HOLOBUILDER_BUTTON",
};
