import { Box } from "@mui/material";
import { FaroIconButton } from "@components/common/faro-icon-button";
import CloseIcon from "@assets/icons/new/close_24px.svg?react";
import { BaseMarkupProps } from "@custom-types/project-markups-types";
import { MarkupActionSphereViewer } from "@pages/project-details/project-markups/markup-action-sphere-viewer";
import { MarkupActionShareMarkup } from "@pages/project-details/project-markups/markup-action-share-markup";
import { MarkupNameAndDetails } from "@pages/project-details/project-markups/markup-name-and-details";
import { useState } from "react";
import { useMarkupContext } from "@context-providers/markup/markup-context";
import { useToast } from "@hooks/use-toast";

interface Props extends BaseMarkupProps {
  /** Close side panel when we click in the close button */
  closeSidePanel: () => void;
}

export function MarkupsSidePanelHeader({
  markup,
  closeSidePanel,
}: Props): JSX.Element {
  const { isMarkupUpdateInProgress } = useMarkupContext();
  const { showToast } = useToast();

  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  function onCloseClick(): void {
    // Show a warning that sidebar can't be closed
    if (isMarkupUpdateInProgress()) {
      showToast({
        message: "Cannot close sidebar",
        description:
          "An update is in progress. Please wait until it is complete.",
        type: "warning",
      });

      return;
    }

    closeSidePanel();
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Box
        component={"div"}
        onClick={() => {
          setIsEditMode(true);
        }}
        onBlur={() => {
          setIsEditMode(false);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            setIsEditMode(false);
          }
        }}
        sx={{ display: "contents" }}
      >
        <MarkupNameAndDetails markup={markup} isSidePanelOpen={true} />
      </Box>

      {!isEditMode && (
        <Box sx={{ display: "flex", gap: "8px" }}>
          <MarkupActionShareMarkup markup={markup} />
          <MarkupActionSphereViewer markup={markup} />

          <FaroIconButton
            onClick={onCloseClick}
            component={CloseIcon}
            buttonSize={"30px"}
            dataTestId="markup-side-panel-close-button"
          />
        </Box>
      )}
    </Box>
  );
}
