import { Box, ButtonProps, SvgIcon } from "@mui/material";
import { ComponentType, useMemo, useState } from "react";
import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import {
  FaroIconButton,
  FaroIconButtonProps,
} from "@components/common/faro-icon-button";
import LinkIcon from "@assets/icons/new/link_18px.svg?react";
import EmailIcon from "@assets/icons/new/email_32px.svg?react";
import OpenIcon from "@assets/icons/new/open_32px.svg?react";
import GenericDotsIcon from "@assets/icons/generic-dots-vertical_l.svg?react";
import { useMediaQueryList } from "@hooks/use-media-query";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { SnapshotActionsDivider } from "@pages/project-details/project-snapshots/snapshot-actions-divider";
import { SphereIconButtonMenu } from "@components/common/sphere-icon-button-menu";
import { FaroMenuItem } from "@components/common/faro-select/faro-menu-item";
import { SdbProject } from "@custom-types/project-types";
import { DeleteSnapshotDialog } from "@pages/project-details/project-snapshots/delete-snapshot-dialog";
import { useToast } from "@hooks/use-toast";
import { copyToClipboard } from "@utils/ui-utils";
import { UrlGenerationUtils } from "@stellar/web-core";
import { runtimeConfig } from "@src/runtime-config";
import { getMailHref } from "@utils/email-utils";
import { InviteMemberToSnapshot } from "@pages/project-details/project-snapshots/invite-member-to-snapshot";
import { SnapshotEvents } from "@utils/track-event/track-event-list";
import {
  UseTrackEvent,
  useTrackEvent,
} from "@utils/track-event/use-track-event";
import { useHasUserValidRoleSnapshotLevel } from "@hooks/access-control/use-has-user-valid-role-snapshot-level";

interface ActionIconItem {
  /** The icon component for the button */
  icon: ComponentType;

  /** The text to be shown inside the tooltip */
  tooltip: string;

  /** The function to be called when the button is clicked */
  onClick: () => void;

  /** Tracking event name to be used */
  eventName: SnapshotEvents;
}

interface ActionComponentItem {
  /** The icon component for the button */
  component: JSX.Element;
}

interface ActionLinkItem {
  /** The icon component for the button */
  icon: ComponentType;

  /** The text to be shown inside the tooltip */
  tooltip: string;

  /** The icon component for the button */
  href: ButtonProps["href"];

  /** Tracking event name to be used */
  eventName: SnapshotEvents;
}

interface Props {
  /** The snapshot to be shown */
  snapshot: CoreAPITypes.IProjectSnapshot;

  /** The selected project the snapshot belongs to */
  selectedProject: SdbProject;

  /** Whether the snapshot can be opened. If true an extra divider is shown on the right */
  canBeOpened: boolean;

  /** Callback when the menu open state changes */
  onIsMenuOpenChanged?: (isMenuOpen: boolean) => void;
}

type ActionItem = ActionIconItem | ActionComponentItem | ActionLinkItem;

/** Checks if the action should be rendered as an icon button */
function isActionIconItem(action: ActionItem): action is ActionIconItem {
  return "icon" in action;
}

/** Checks if the action should be rendered as as component */
function isActionComponentItem(
  action: ActionItem
): action is ActionComponentItem {
  return "component" in action;
}

/** Checks if the action should be rendered as as link */
function isLinkItem(action: ActionItem): action is ActionLinkItem {
  return "href" in action;
}

/**
 * If the action shows a link returns the props to be used in the link.
 */
function getLinkProps(
  action: ActionItem,
  snapshot: CoreAPITypes.IProjectSnapshot
): Partial<Pick<FaroIconButtonProps, "href" | "target" | "rel">> {
  if (isLinkItem(action)) {
    return {
      href: action.href,
      target: snapshot.name,
      rel: "noopener noreferrer",
    };
  }
  return {};
}

type GetIconOrComponentProps = Pick<UseTrackEvent, "trackEvent"> & {
  /** The action of snapshot */
  action: ActionItem;

  /** The index number making unique keys when needed */
  index?: number;

  /** The snapshot to be shown */
  snapshot: CoreAPITypes.IProjectSnapshot;
};

/**
 * On larger devices get's either the icon button component to be shown for the action,
 * or the component to be shown, used in cases where a component opens a dialog.
 */
function getIconOrComponent({
  action,
  snapshot,
  trackEvent,
}: GetIconOrComponentProps): JSX.Element {
  const shouldShowLinkItem = isLinkItem(action);
  if (isActionIconItem(action) || shouldShowLinkItem) {
    return (
      <SphereTooltip title={action.tooltip}>
        <FaroIconButton
          component={action.icon}
          onClick={(event) => {
            trackEvent({ name: action.eventName });
            if (shouldShowLinkItem) {
              return;
            }
            action.onClick();
          }}
          iconSize="16px"
          buttonSize="32px"
          {...getLinkProps(action, snapshot)}
        />
      </SphereTooltip>
    );
  }
  if (isActionComponentItem(action)) {
    return action.component;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment -- We need to return something
  return <></>;
}

/**
 * On small devices get's either the menu item to be shown for the action, including the
 * icon and the label, or the component to be shown, used in cases where a component opens a dialog.
 */
function getIconOrComponentMobile({
  action,
  index,
  snapshot,
  trackEvent,
}: GetIconOrComponentProps): JSX.Element {
  const shouldShowLinkItem = isLinkItem(action);
  if (isActionIconItem(action) || shouldShowLinkItem) {
    return (
      <FaroMenuItem
        key={`snapshot-action-${index}`}
        onClick={(event) => {
          trackEvent({ name: action.eventName });
          if (shouldShowLinkItem) {
            return;
          }
          action.onClick();
        }}
        {...getLinkProps(action, snapshot)}
      >
        <Box
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <SvgIcon
            sx={{
              height: "18px",
              width: "18px",
              marginRight: "10px",
            }}
            component={action.icon}
            inheritViewBox
          />
          {action.tooltip}
        </Box>
      </FaroMenuItem>
    );
  }
  if (isActionComponentItem(action)) {
    return action.component;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment -- We need to return something
  return <></>;
}

/**
 * Renders the action buttons for a single snapshot.
 * Takes care of collapsing the buttons in a single menu for small devices,
 * and adjusting the buttons depending on the access level.
 */
export function SnapshotActions({
  snapshot,
  selectedProject,
  canBeOpened,
  onIsMenuOpenChanged,
}: Props): JSX.Element {
  const { isScreenXsAndSmall } = useMediaQueryList();
  const { showToast } = useToast();
  const { trackEvent } = useTrackEvent();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isSnapshotAdmin } = useHasUserValidRoleSnapshotLevel({
    selectedSnapshot: snapshot,
  });

  /**
   * Stores the actions to be shown for the snapshot, like share, delete, add members, etc.
   * Includes the icon, the tooltip and the function to be called when the button is clicked.
   * The actions depend on the access level of the snapshot.
   */
  const snapshotActions: ActionItem[] = useMemo(() => {
    const actions: ActionItem[] = [];

    /** generates the url to open the snapshot */
    const snapshotUrl = UrlGenerationUtils.generateWebEditorUrl({
      baseUrl: runtimeConfig.urls.hbWebEditorUrl,
      projectId: snapshot.id,
      mode: UrlGenerationUtils.EWebEditorMode.player,
    });

    if (
      snapshot.accessLevel === SphereDashboardAPITypes.EAccessLevel.private &&
      isSnapshotAdmin
    ) {
      actions.push({
        component: (
          <InviteMemberToSnapshot
            snapshot={snapshot}
            isMobileVersion={isScreenXsAndSmall}
          />
        ),
      });
    }

    actions.push(
      {
        icon: EmailIcon,
        tooltip: "Send email",
        eventName: SnapshotEvents.sendSnapshotEmail,
        onClick: () => {
          window.location.href = getMailHref({
            subject: snapshot.name,
            body: snapshotUrl,
          });
        },
      },
      {
        icon: LinkIcon,
        tooltip: "Copy link",
        eventName: SnapshotEvents.copySnapshotLink,
        onClick: () => {
          copyToClipboard(snapshotUrl);
          showToast({
            message: "Link copied",
            type: "success",
          });
        },
      },
      {
        icon: OpenIcon,
        tooltip: "Open snapshot",
        href: snapshotUrl,
        eventName: SnapshotEvents.openSnapshot,
      }
    );

    if (isSnapshotAdmin) {
      actions.push({
        component: (
          <DeleteSnapshotDialog
            selectedProject={selectedProject}
            snapshot={snapshot}
            isMobileVersion={isScreenXsAndSmall}
          />
        ),
      });
    }

    // Filters the actions to only show the ones the user has access to.
    return actions;
  }, [
    isScreenXsAndSmall,
    isSnapshotAdmin,
    selectedProject,
    showToast,
    snapshot,
  ]);

  // In small devices, show a single menu with all the actions
  if (isScreenXsAndSmall) {
    return (
      <>
        <SphereIconButtonMenu
          buttonProps={{
            component: GenericDotsIcon,
            iconSize: "16px",
            buttonSize: "32px",
          }}
          onIsMenuOpenChanged={onIsMenuOpenChanged}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
        >
          {snapshotActions.map((action, index) => (
            <Box
              key={`snapshot-action-${index}`}
              component="div"
              onClick={(event) => {
                // Prevent that clicks anywhere in the actions (even dialogs attached to the actions)
                // can propagate to the parent and collapse/un-collapse the snapshot.
                event.stopPropagation();

                // Closes the menu if user clicks on any of the actions
                setIsMenuOpen(false);
              }}
            >
              {getIconOrComponentMobile({
                action,
                index,
                snapshot,
                trackEvent,
              })}
            </Box>
          ))}
        </SphereIconButtonMenu>

        {/* If the snapshot can be opened, show a divider to separate the actions with the open button */}
        {canBeOpened && <SnapshotActionsDivider />}
      </>
    );
  }

  // In larger devices shows an icon button for each element.
  return (
    <>
      {snapshotActions.map((action, index) => (
        <Box
          component="div"
          key={`snapshot-action-${index}`}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
          onClick={(event) => {
            // Prevent that clicks anywhere in the actions (even dialogs attached to the actions)
            // can propagate to the parent and collapse/un-collapse the snapshot.
            event.stopPropagation();
          }}
        >
          {/* Do not show the divider on the left for the first item */}
          {index > 0 && <SnapshotActionsDivider />}
          {getIconOrComponent({ action, snapshot, trackEvent })}

          {/* If the snapshot can be opened, show a divider to separate the actions with the open button */}
          {canBeOpened && index === snapshotActions.length - 1 && (
            <SnapshotActionsDivider />
          )}
        </Box>
      ))}
    </>
  );
}
