import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { SelectRole } from "@components/role/select-role";
import { SelectRoleSubjectProps } from "@components/role/select-role-utils";

/** Renders a dropdown to select company role */
export function SelectCompanyRole<
  T extends SphereDashboardAPITypes.IAssignmentCompanyRole
>({
  selectedRole,
  onChange,
  isTableCell = false,
  disabledRoles = [],
}: SelectRoleSubjectProps<T>): JSX.Element {
  function onSelectRoleChange(role: CoreAPITypes.EUserCompanyRole): void {
    // TODO: Type assertion will be fixed in https://faro01.atlassian.net/browse/ST-1902
    onChange(role as T);
  }

  let roles: CoreAPITypes.EUserCompanyRole[] = [
    CoreAPITypes.EUserCompanyRole.companyExecutive,
    CoreAPITypes.EUserCompanyRole.companyViewer,
  ];

  const allDisabledRoles = disabledRoles;
  if (
    (selectedRole as CoreAPITypes.EUserCompanyRole) ===
    CoreAPITypes.EUserCompanyRole.member
  ) {
    roles = roles.concat([CoreAPITypes.EUserCompanyRole.member]);
    // TODO: Type assertion will be fixed in https://faro01.atlassian.net/browse/ST-1902
    allDisabledRoles.push(CoreAPITypes.EUserCompanyRole.member as T);
  } else {
    roles = roles.concat([
      CoreAPITypes.EUserCompanyRole.companyManager,
      CoreAPITypes.EUserCompanyRole.projectManager,
    ]);
  }

  return (
    <SelectRole
      label="Workspace role"
      roles={roles}
      disabledRoles={allDisabledRoles}
      isTableCell={isTableCell}
      selectedRole={selectedRole as CoreAPITypes.EUserCompanyRole}
      onChange={onSelectRoleChange}
      shouldShowPermissionsLink
    />
  );
}
