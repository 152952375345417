import {
  GroupBulkButtonComponents,
  GroupTableBulkName,
} from "@components/common/faro-table/faro-table-types";
import { useAppSelector } from "@store/store-helper";
import { selectedEntitiesSelector } from "@store/table/table-selector";
import { GroupTypes } from "@custom-types/group-types";
import { ExportGroupsAsCSV } from "@components/table/groups/bulk-actions/export-groups-as-csv";
import { useShouldShowBulkAction } from "@hooks/table/use-should-show-bulk-action";
import { useMemo } from "react";

interface Props {
  /** List of groups to show in table */
  groups: GroupTypes[];
}

/** List all the bulk action components for group table */
export function GroupsPageBulkActions({
  groups,
}: Props): GroupBulkButtonComponents {
  const selectedEntities = useAppSelector(selectedEntitiesSelector("groups"));
  const isAnyRowSelected = selectedEntities.length !== 0;

  const allowedButtonsBasedOnRowSelection: GroupTableBulkName[] =
    useMemo(() => {
      const allowedButtons: GroupTableBulkName[] = ["exportCsv"];

      return allowedButtons;
    }, []);

  const { shouldShowBulkButtons } = useShouldShowBulkAction({
    allowedButtonsBasedOnRowSelection,
  });

  return {
    exportCsv: shouldShowBulkButtons("exportCsv") ? (
      <ExportGroupsAsCSV
        selectedGroups={isAnyRowSelected ? selectedEntities : groups}
        areEntitiesSelected={isAnyRowSelected}
      />
    ) : null,
  };
}
