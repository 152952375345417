import { FileDetails } from "@faro-lotv/flat-ui";
import { generateGUID } from "@faro-lotv/foundation";
import { GUID } from "@faro-lotv/ielement-types";
import {
  MutationAddAttachment,
  createMutationAddAttachment,
} from "@faro-lotv/service-wires";

/**
 * Contains all the sorted file extensions that the mark attachment uploader will accept
 * This list is copy and pasted from the flat-ui which is not exported
 */
// eslint-disable-next-line max-len
// https://dev.azure.com/faro-connect/ViewerApp/_git/LotvMonorepo?path=libs/flat-ui/src/components/annotations/annotation-details-form.tsx&version=GCd61237eaa2c885f8450bd29def91df13f41837a1&line=432&lineStartColumn=7&lineEndColumn=33&_a=contents
export const MARKUP_ATTACHMENT_EXTENSIONS_WHITE_LIST = [
  // Documents
  "csv",
  "docx",
  "pdf",
  "pptx",
  "txt",
  "vcax",
  "xlsx",
  // Images
  "bmp",
  "gif",
  "jfif",
  "jpeg",
  "jpg",
  "png",
  "tif",
  "tiff",
  // Video
  "avi",
  "webm",
  "mov",
  "mp4",
  // 3D Models
  "dwg",
  "fls",
  "ifc",
  "jt",
  "obj",
  "ply",
  "ptx",
  "rvt",
  "sab",
  "sat",
  "step",
  "stp",
  // Point Cloud formats
  "cpe",
  "dxf",
  "e57",
  "igs",
  "las",
  "laz",
  "pod",
  "pts",
  "pxy",
  "rcp",
  "rcs",
  "swp",
  "wrl",
  "xyz",
  "xyb",
  // Archives
  "7z",
  "rar",
  "zip",
  // Misc
  "kmz",
  "woff",
  "wmz",
].sort();

type UploadedFileWithMd5 = {
  /** The unique id of the uploaded file */
  id: string;
  /** The url to download the file from the backend */
  downloadUrl: string;

  /** The md5 hash identifying file integrity */
  md5: string;
};

/** The type of a new attachment for the annotation */
export type NewAttachment = UploadedFileWithMd5 & FileDetails;

// eslint-disable-next-line max-len
// https://dev.azure.com/faro-connect/ViewerApp/_git/LotvMonorepo?path=apps/sphere-web-viewer/src/components/ui/annotations/create-attachments.ts&version=GC818ddcfb9c1d8bfc6e0d047e7458f53856d99a5f&line=17&lineStartColumn=17&lineEndColumn=34&_a=contents
/**
 * Create mutation for adding attachment to the markup
 * TODO: Remove this function because "createAttachments" is copied from lotv-monorepo
 * as "createAttachmentMutation" which is not available in exported functions.
 * https://faro01.atlassian.net/browse/ST-1835
 *
 * @param markupElement to edit
 * @param newStatus to apply
 * @param previousValueId if a value was already present
 * @param statusTemplateId to reference in the new UserDirectoryMarkupField node
 * @returns the proper mutation to update the Markup to the new value
 */
export function createAttachmentMutation(
  rootId: GUID,
  markupId: GUID,
  attachment: NewAttachment,
  maybeGroupId?: GUID
): MutationAddAttachment {
  const mutation = createMutationAddAttachment({
    rootId,
    newAttachmentId: generateGUID(),
    markupId,
    groupId: maybeGroupId,
    name: attachment.fileName,
    fileName: attachment.fileName,
    fileSize: attachment.fileSize,
    md5Hash: attachment.md5,
    uri: attachment.downloadUrl,
    ...(typeof attachment.thumbnailUrl === "string" && {
      thumbnailUri: attachment.thumbnailUrl,
    }),
  });

  return mutation;
}
