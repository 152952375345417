import { Breakpoint } from "@mui/material";
import { CSSProperties } from "react";

/** Height in pixels for the snapshot */
export const SNAPSHOT_HEIGHT = "70px";

/** Margin top in pixels between snapshots */
export const SNAPSHOT_MARGIN_TOP = "4px";

/** Width in pixels for the date section of the snapshot */
export const DATE_WIDTH: { [key in Breakpoint]: CSSProperties["width"] } = {
  xs: "55px",
  sm: "75px",
  md: "100px",
  lg: "210px",
  xl: "250px",
};

/** Width in pixels for the access section of the snapshot */
export const ACCESS_WIDTH: { [key in Breakpoint]: CSSProperties["width"] } = {
  xs: "70px",
  sm: "126px",
  md: "135px",
  lg: "160px",
  xl: "230px",
};

/** Width in pixels for the name section of the snapshot */
export const NAME_WIDTH: { [key in Breakpoint]: CSSProperties["width"] } = {
  xs: `calc(100% - ${DATE_WIDTH.xs} - ${ACCESS_WIDTH.xs})`,
  sm: `calc(100% - ${DATE_WIDTH.sm} - ${ACCESS_WIDTH.sm})`,
  md: `calc(100% - ${DATE_WIDTH.md} - ${ACCESS_WIDTH.md})`,
  lg: `calc(100% - ${DATE_WIDTH.lg} - ${ACCESS_WIDTH.lg})`,
  xl: `calc(100% - ${DATE_WIDTH.xl} - ${ACCESS_WIDTH.xl})`,
};
