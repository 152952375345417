import { SphereTooltip } from "@components/common/sphere-tooltip";
import { SourcePointCloudInfo } from "@custom-types/point-cloud-types";
import { MergedPointCloudDetailsTooltip } from "@pages/project-details/project-data/point-clouds/merged-point-cloud-details-tooltip";
import RegistrationIcon from "@assets/icons/new/point-cloud-registration_18px.svg?react";
import { Box } from "@mui/material";

interface Props {
  /** Display name of the merged point cloud */
  name: string;

  /** Information about the source point clouds used to generate the merged point cloud */
  sourcePointCloudsInfo?: SourcePointCloudInfo[];
}

/** Details of a merged point cloud */
export function MergedPointCloudDetails({
  name,
  sourcePointCloudsInfo,
}: Props): JSX.Element | null {
  return sourcePointCloudsInfo ? (
    <SphereTooltip
      title={
        <MergedPointCloudDetailsTooltip
          name={name}
          sourcePointCloudsInfo={sourcePointCloudsInfo}
        />
      }
      shouldSkipWrapper
    >
      <Box
        component={"div"}
        data-testid="point-cloud-table-merged-details"
        className="point-cloud-table-merged-details"
        sx={{
          display: "flex",
        }}
      >
        <RegistrationIcon />
        <Box
          data-testid="point-cloud-table-merged-details-value"
          sx={{
            marginLeft: "4px",
          }}
        >
          {sourcePointCloudsInfo.length}
        </Box>
      </Box>
    </SphereTooltip>
  ) : null;
}
