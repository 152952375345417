import { MemberTypes } from "@custom-types/member-types";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { getPrettyName } from "@utils/user-utils";

interface Props {
  /** ID of the markup creator */
  creatorId: string;

  /** List of company members */
  companyMembers: MemberTypes[];

  /** List of project members */
  projectMembers: SphereDashboardAPITypes.IProjectMemberBase[];
}

/** Returns the markup creator member */
export function getMarkupCreator({
  creatorId,
  companyMembers,
  projectMembers,
}: Props):
  | MemberTypes
  | SphereDashboardAPITypes.IProjectMemberBase
  | undefined {
  const creatorCompanyMember = companyMembers.find(
    (member) => member.identity === creatorId
  );

  const creatorProjectMember = projectMembers.find(
    (member) => member.identity === creatorId
  );

  // Give priority to the company level member entity
  return creatorCompanyMember || creatorProjectMember;
}

/** Returns the markup creator name */
export function getMarkupCreatorName({
  creatorId,
  companyMembers,
  projectMembers,
}: Props): string | undefined {
  const creatorMember = getMarkupCreator({
    creatorId,
    companyMembers,
    projectMembers,
  });

  const name = getPrettyName(creatorMember);

  return name !== "" ? name : undefined;
}
