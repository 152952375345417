import { Checkbox, CheckboxProps } from "@mui/material";
import { SHOW_ON_HOVER_CLASS } from "@utils/ui-utils";
import React, { ForwardedRef, useMemo } from "react";

/**
 * Custom checkbox to select elements. Applies both to the one in the header and in the other rows
 */
export const FaroCustomCheckbox = React.forwardRef(
  (props: CheckboxProps, ref: ForwardedRef<HTMLButtonElement>) => {
    /**
     * Determines whether the checkbox is shown in the header or in the other rows.
     * Since there is no better way to determine if the checkbox is in the header or not, we use the aria-label.
     * indeterminate it is only set to true when there is at least one item checked.
     */
    const isHeaderRow = useMemo(() => {
      return (
        props.indeterminate ||
        props.inputProps?.["aria-label"]?.includes("rows")
      );
    }, [props]);

    return (
      <Checkbox
        ref={ref}
        {...props}
        className={SHOW_ON_HOVER_CLASS}
        disableRipple
        disableFocusRipple
        disableTouchRipple
        sx={{
          // Always show the checkbox once it is checked, or if it is in the header.
          // Otherwise hide it, and it will be shown on hover using SHOW_ON_HOVER_CLASS.
          visibility: props.checked || isHeaderRow ? "visible" : "hidden",
          // The checkbox in the header and in the other rows are not aligned,
          // therefore we need to add a left margin to the one in the other rows
          marginLeft: isHeaderRow ? undefined : "10px",
        }}
      />
    );
  }
);
