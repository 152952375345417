import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { CoreApiTokenManager } from "@faro-lotv/service-wires";
import { runtimeConfig } from "@src/runtime-config";
import { ProjectApi } from "@api/project-api/project-api";

/** Dashboard identifier to pass the project API */
export const CLIENT_ID = `sphere-dashboard/${runtimeConfig.appVersion}`;

/**
 * Returns an instance of the project API client.
 *
 * @returns ProjectApi client instance
 */
export function getProjectApiClient({
  projectId,
}: BaseProjectIdProps): ProjectApi {
  const projectApiURL = new URL(runtimeConfig.urls.projectApiUrl);
  const coreApiURL = new URL(runtimeConfig.urls.apiBaseUrl);

  const tokenManager = new CoreApiTokenManager(
    coreApiURL,
    projectId.toString(),
    CLIENT_ID
  );
  // Use "bind()" method to create an instance getToken that, when called, uses the passed token manager arguments:
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_objects/Function/bind
  const tokenProvider = tokenManager.getToken.bind(tokenManager);

  return new ProjectApi({
    projectApi: projectApiURL,
    projectId: projectId.toString(),
    tokenProvider,
    clientId: CLIENT_ID,
  });
}
