import { getFileExtension } from "@faro-lotv/foundation";

/** Used to calculate file size in byte, megaByte and gigaByte */
export const FILE_SIZE_MULTIPLIER = 1024;

/**
 * Check the extension of the file if it's valid or not
 *
 * @param {file} file The file to check
 * @returns true if the file has a supported format
 */
export function isValidFileExtension(
  file: File,
  validExtensions: string[]
): boolean {
  const extension = getFileExtension(file.name)?.toLowerCase();
  return !!extension && validExtensions.includes(extension);
}

/** Returns mega bytes after converting from bytes */
export function bytesToMegaBytes(bytes: number): string {
  const megaBytes = bytes / (FILE_SIZE_MULTIPLIER * FILE_SIZE_MULTIPLIER);
  return megaBytes.toFixed(2);
}

/**
 * Sort files by name
 *
 * @param files The array of file
 * @returns Sorted array of the files
 */
export function sortFiles(files: File[]): File[] {
  return files.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
}
