import { Box, InputAdornment, Typography } from "@mui/material";
import EditIcon from "@assets/icons/Edit.svg?react";
import Add from "@assets/icons/Add.svg?react";
import Cancel from "@assets/icons/Cancel.svg?react";
import { sphereColors } from "@styles/common-colors";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { CSSProperties } from "react";
import { DEFAULT_TEXT_FONT_SIZE } from "@styles/common-styles";

interface Props {
  /** Whether the text field is hovered or not */
  isHovered: boolean;

  /** Whether the text field is in edit mode or not */
  isEditMode: boolean;

  /** Whether the confirm button is disabled */
  isConfirmDisabled?: boolean;

  /** The padding right of the text field */
  paddingRight?: CSSProperties["paddingRight"];

  /** The size of the edit button */
  editButtonSize?: CSSProperties["height"];

  /** The size of the edit icon */
  editIconSize?: CSSProperties["height"];

  /** The size of the control buttons */
  controlButtonSize?: CSSProperties["height"];

  /** The size of the control icons */
  controlIconSize?: CSSProperties["height"];

  /** Callback for when the edit button is clicked */
  onEditClicked(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;

  /** Callback for when the approve icon is clicked */
  onCheckClicked(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;

  /** Callback for when the discard icon is clicked */
  onClearClicked(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;

  /** Optional property that place the adornment under the input field */
  isAdornmentOnBottom?: boolean;

  /** Optional property to display the number of characters in the input adornment if available. */
  inputLength?: number;

  /** Optional property to display the maximum characters in the input adornment if available. */
  maxInputLength?: number;

  /** Optional property that controls if a character count on endAdornment should be displayed */
  shouldShowCharacterCount?: boolean;
}

/** The HTML id for the button that saves the input text */
export const SAVE_TEXT_BTN_ID = "saveTextBtn";

/** The HTML id for the button that clears the input text */
export const CLEAR_TEXT_BTN_ID = "clearTextBtn";

/** Shows icons related to editing a text field */
export function TextFieldControls({
  isHovered,
  isEditMode,
  isConfirmDisabled = false,
  paddingRight = "0px",
  editButtonSize = "24px",
  editIconSize = "16px",
  controlButtonSize = "24px",
  controlIconSize = "16px",
  onEditClicked,
  onCheckClicked,
  onClearClicked,
  maxInputLength,
  inputLength = 0,
  isAdornmentOnBottom = false,
  shouldShowCharacterCount = false,
}: Props): JSX.Element {
  return (
    <InputAdornment
      position="end"
      sx={{
        paddingRight,
        ...(isAdornmentOnBottom && {
          position: "absolute",
          right: 0,
          bottom: 0,
          width: "100%",
          height: "30px",
        }),
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", ml: "auto" }}>
        {isHovered && !isEditMode && (
          <FaroIconButton
            component={EditIcon}
            buttonSize={editButtonSize}
            iconSize={editIconSize}
            onClick={onEditClicked}
            color={sphereColors.gray400}
          />
        )}
        {isEditMode && (
          <>
            {shouldShowCharacterCount && maxInputLength && (
              <Typography
                sx={{
                  color: sphereColors.gray600,
                  fontWeight: 400,
                  fontSize: DEFAULT_TEXT_FONT_SIZE,
                  display: "flex",
                  mr: "16px",
                }}
              >
                {inputLength} / {maxInputLength}
              </Typography>
            )}

            <FaroIconButton
              id={SAVE_TEXT_BTN_ID}
              component={Add}
              buttonSize={controlButtonSize}
              iconSize={controlIconSize}
              onClick={onCheckClicked}
              color={sphereColors.blue500}
              isDisabled={isConfirmDisabled}
            />
            <FaroIconButton
              id={CLEAR_TEXT_BTN_ID}
              component={Cancel}
              buttonSize={controlButtonSize}
              iconSize={controlIconSize}
              onClick={onClearClicked}
            />
          </>
        )}
      </Box>
    </InputAdornment>
  );
}
