import { SdbProject } from "@custom-types/project-types";
import { MemberTableSubject } from "@components/common/faro-table/faro-table-types";
import { BaseMemberProps } from "@custom-types/member-types";
import { MemberStatus } from "@components/table/members/members-column/status-column/member-status";
import { ResendInvitation } from "@components/table/members/members-column/status-column/resend-invitation";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";

interface Props extends BaseCompanyIdProps, BaseMemberProps {
  /** The selected project if there is one */
  project?: SdbProject | null;

  /** Recognizes on which table subject this component is being used */
  subjectType: MemberTableSubject;
}

/** Content to show for status member column */
export function StatusColumn({
  companyId,
  project,
  subjectType,
  member,
}: Props): JSX.Element {
  return (
    <>
      <MemberStatus member={member} />
      <ResendInvitation
        companyId={companyId}
        projectId={project?.id}
        member={member}
        key={member.email}
        subjectType={subjectType}
      />
    </>
  );
}
