import { Box, Skeleton } from "@mui/material";
import { useState } from "react";

interface Props
  extends Pick<
    React.DetailedHTMLProps<
      React.ImgHTMLAttributes<HTMLImageElement>,
      HTMLImageElement
    >,
    "src" | "alt"
  > {
  /**
   * Aspect ratio for the image
   *
   * @default 1.8
   */
  aspectRatio?: number;
}

const DEFAULT_ASPECT_RATIO = 1.8;

/**
 * Component for displaying an image with a given aspect ratio. It shows a skeleton while the image loads.
 */
export function SphereImg({
  src,
  aspectRatio = DEFAULT_ASPECT_RATIO,
  alt,
}: Props): JSX.Element | null {
  /** Flag whether the skeleton for the image should be shown */
  const [shouldShowImageSkeleton, setShouldShowImageSkeleton] =
    useState<boolean>(true);

  if (!src) {
    return null;
  }

  return (
    <>
      {shouldShowImageSkeleton && (
        <Skeleton
          variant="rectangular"
          sx={{
            width: "100%",
            height: "auto",
            aspectRatio,
            marginBottom: "8px",
          }}
        />
      )}
      <Box
        component="img"
        src={src}
        sx={{
          width: "100%",
          height: "auto",
          aspectRatio,
          objectFit: "fill",
          marginBottom: "8px",
          // The display should be set to know, otherwise for a short time the image will be shown
          // in combination with the skeleton
          display: shouldShowImageSkeleton ? "none" : "block",
        }}
        alt={alt}
        onLoad={() => setShouldShowImageSkeleton(false)}
      />
    </>
  );
}
