import { SphereDropdownSelector } from "@components/common/sphere-dropdown-selector";
import { Box, Grid } from "@mui/material";
import { selectedProjectChartTimeFrameSelector } from "@store/analytics/analytics-selector";
import { setSelectedProjectChartTimeFrame } from "@store/analytics/analytics-slice";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { sphereColors } from "@styles/common-colors";
import { TIME_FRAMES, TimeFrameItem } from "@utils/time-utils";

/** Renders the controller for the chart in the project overview page */
export function ProjectChartController(): JSX.Element {
  const dispatch = useAppDispatch();
  const selectedTimeFrame = useAppSelector(
    selectedProjectChartTimeFrameSelector
  );

  function handleTimeFrameChange(value: TimeFrameItem["value"]): void {
    dispatch(setSelectedProjectChartTimeFrame(value));
  }

  return (
    <Grid container justifyContent="space-between" marginBottom="24px">
      {/* Chart type selector
          Currently we are only showing one chart type, so we can remove the toggle button group
          If needed, you can use SphereToggleButtonGroup to render the chart type selector */}
      <Grid
        sx={{
          alignItems: "center",
          display: "flex",
          color: sphereColors.gray800,
          fontSize: "14px",
          fontWeight: "700",
          lineHeight: "20px",
        }}
      >
        Total 360° images
      </Grid>

      {/* Chart time frame selector */}
      <Grid>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Box
            sx={{
              fontWeight: "bold",
              marginRight: "10px",
            }}
          >
            Date Range:
          </Box>
          <SphereDropdownSelector
            items={TIME_FRAMES}
            currentValue={selectedTimeFrame}
            handleChange={handleTimeFrameChange}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
