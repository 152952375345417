import { Component } from "react";
// eslint-disable-next-line no-restricted-imports -- The only place needed to initialize Logger
import { Logger } from "@stellar/web-core";
import { ErrorWithTitle } from "@context-providers/error-boundary/error-types";
import { IReactChildrenOnly } from "@custom-types/types";
import { ErrorPage } from "@context-providers/error-boundary/error-page";
import { ErrorDialog } from "@context-providers/error-boundary/error-dialog";
import { ErrorToast } from "@context-providers/error-boundary/error-toast";
import { ErrorHandlingContext } from "@context-providers/error-boundary/error-handling-context";
import { runtimeConfig } from "@src/runtime-config";

interface State {
  /** State related to show error in its specific page */
  errorPage: ErrorWithTitle | null;

  /** State related to show error in a dialog */
  errorDialog: ErrorWithTitle | null;

  /** State related to show error in a toast */
  errorToast: ErrorWithTitle | null;
}

/**
 * Use to create a new ErrorBoundary and ErrorHandlingContext.
 * Needs to be a class component due to componentDidCatch not being available in functional components.
 */
// eslint-disable-next-line react/prefer-es6-class -- Error boundary is not available in functional component
export class ErrorBoundary extends Component<IReactChildrenOnly, State> {
  constructor(props: IReactChildrenOnly) {
    super(props);
    this.state = { errorPage: null, errorDialog: null, errorToast: null };

    if (runtimeConfig.features.sentryDSNKey) {
      Logger.init({
        dsn: runtimeConfig.features.sentryDSNKey,
        environment: runtimeConfig.appEnv,
        release: runtimeConfig.appVersion,
      });
    }
  }

  /**
   * Catches all React-rendering errors within the children.
   * This life-cycle catch any unintentional/unexpected error that is not covered by use-error-handler
   */
  componentDidCatch(error: Error): void {
    this.handleErrorWithPage({
      id: `componentDidCatch-${Date.now().toString()}`,
      title: "An unexpected error occurred: ",
      error,
    });
  }

  /** Log error in logger */
  logError({ title, error }: ErrorWithTitle): void {
    if (typeof error === "string" || error instanceof Error) {
      Logger.logError(title, { error });
    } else {
      Logger.logError(title, { payload: { error } });
    }
  }

  /** Handle the error by showing a page */
  handleErrorWithPage = ({ id, title, error }: ErrorWithTitle): void => {
    this.logError({ id, title, error });
    this.setState({
      errorPage: {
        id,
        title,
        error,
      },
    });
  };

  /** Handle the error by showing a dialog */
  handleErrorWithDialog = ({ id, title, error }: ErrorWithTitle): void => {
    this.logError({ id, title, error });
    this.setState({
      errorDialog: {
        id,
        title,
        error,
      },
    });
  };

  /** Handle the error by showing a toast */
  handleErrorWithToast = ({ id, title, error }: ErrorWithTitle): void => {
    this.logError({ id, title, error });
    this.setState({
      errorToast: {
        id,
        title,
        error,
      },
    });
  };

  handleClosePageError = (): void => {
    this.setState({ errorPage: null });
  };

  handleCloseDialogError = (): void => {
    this.setState({ errorDialog: null });
  };

  handleCloseToastError = (): void => {
    this.setState({ errorToast: null });
  };

  handleOnGoHome = (): void => {
    this.handleClosePageError();

    // React-router is not available in root ErrorBoundary
    window.location.href = "/";
  };

  render(): JSX.Element {
    if (this.state.errorPage) {
      return (
        <ErrorPage
          error={this.state.errorPage}
          onReload={this.handleClosePageError}
          onGoHome={this.handleOnGoHome}
        />
      );
    }

    return (
      <ErrorHandlingContext.Provider
        value={{
          handleErrorWithDialog: this.handleErrorWithDialog,
          handleErrorWithToast: this.handleErrorWithToast,
          handleErrorWithPage: this.handleErrorWithPage,
          handleErrorSilently: this.logError,
        }}
      >
        {this.props.children}
        {this.state.errorDialog && (
          <ErrorDialog
            error={this.state.errorDialog}
            onClose={this.handleCloseDialogError}
          />
        )}
        {this.state.errorToast && (
          <ErrorToast
            error={this.state.errorToast}
            onClose={this.handleCloseToastError}
          />
        )}
      </ErrorHandlingContext.Provider>
    );
  }
}
