import { MainPageLayout } from "@components/main-page-layout";
import { Grid } from "@mui/material";
import { CSSProperties, PropsWithChildren, ReactNode, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { TitleComponent } from "@components/common/title-component";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { sidebarSelector } from "@store/ui/ui-selector";
import { updateSidebar } from "@store/ui/ui-slice";

interface Props {
  /** Title of the page */
  title?: ReactNode;

  /** Triggers when user clicks on the close button */
  onClose(): void;

  /** Background color of the page */
  backgroundColor?: CSSProperties["color"];
}

/** Renders a full page layout without left sidebar and limited top bar options **/
export function FullPageLayout({
  children,
  title,
  onClose,
  backgroundColor,
}: PropsWithChildren<Props>): JSX.Element {
  const sidebar = useAppSelector(sidebarSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updateSidebar({ ...sidebar, isVisible: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Putting sidebar on dependency will create a loop
  }, [dispatch]);

  return (
    <Grid
      container
      sx={{
        backgroundColor,
      }}
    >
      <MainPageLayout
        shouldShowLogo={false}
        shouldHideOpenButton={true}
        shouldShowUserMenu={false}
        shouldShowSidebar={false}
        shouldShowProgressOverviewMenu={true}
        shouldShowImportDataButton={true}
        onCloseButtonClick={onClose}
        titleComponent={<TitleComponent title={title} />}
        shouldShowTopbarBorder={true}
        contentBackgroundColor={backgroundColor}
      >
        <Grid container sx={{ marginTop: "55px" }}>
          {/* <Outlet> is a placeholder for the active child route defined in the routes.ts */}
          {children ? children : <Outlet />}
        </Grid>
      </MainPageLayout>
    </Grid>
  );
}
