import { Grid, Skeleton, Typography } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { BaseGroupProps } from "@custom-types/group-types";

export interface Props extends Partial<BaseGroupProps> {
  /** Flag whether content is loading. If true, the content is replaced with skeletons */
  isLoading?: boolean;
}

/** Stores a skeleton to be shown instead of the projects/managers count */
const skeleton = <Skeleton variant="rectangular" width="50px" height="40px" />;

/**
 * Bottom section of the group card that shows the number of group manager and number of projects.
 */
export function GroupCardBottom({ group, isLoading }: Props): JSX.Element {
  return (
    <Grid
      container
      sx={{
        paddingX: "14px",
        marginBottom: "17px",
      }}
    >
      {/* Show number of group managers on the left side */}
      <Grid
        item
        xs={6}
        sx={{
          boxShadow: `1px 0 0 0 ${sphereColors.gray200}`,
          display: "flex",
          flexFlow: "column",
          alignItems: "center",
        }}
      >
        {isLoading ? (
          skeleton
        ) : (
          <>
            <Typography
              sx={{
                color: sphereColors.gray700,
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "20px",
                paddingBottom: "4px",
              }}
            >
              {group?.numManagers ?? "0"}
            </Typography>

            <Typography
              sx={{
                color: sphereColors.gray700,
                fontSize: "12px",
                lineHeight: "16px",
              }}
            >
              Managers
            </Typography>
          </>
        )}
      </Grid>

      {/* Show number of project on the right side */}
      <Grid
        item
        xs={6}
        sx={{
          display: "flex",
          flexFlow: "column",
          alignItems: "center",
        }}
      >
        {isLoading ? (
          skeleton
        ) : (
          <>
            <Typography
              sx={{
                color: sphereColors.gray700,
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "20px",
                paddingBottom: "4px",
              }}
            >
              {group?.numProjects ?? "0"}
            </Typography>
            <Typography
              sx={{
                color: sphereColors.gray700,
                fontSize: "12px",
                lineHeight: "16px",
              }}
            >
              Projects
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
}
