import { useCoreApiClient } from "@api/use-core-api-client";
import { TabsWithRoutes } from "@components/common/tabs-with-routes";
import { isGroupDetails } from "@custom-types/type-guards";
import { NotFoundPage } from "@pages/not-found-page";
import { GroupTabs } from "@router/route-params";
import { useAppParams } from "@router/router-helper";
import { selectedGroupSelector } from "@store/groups/groups-selector";
import { fetchGroupDetails } from "@store/groups/groups-slice";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { useEffect } from "react";
import { GroupManagers } from "@pages/group-details/group-managers";
import { RequiredRoleCompanyLevelName } from "@utils/access-control/company/company-access-control-types";
import { GroupProjects } from "@pages/group-details/group-projects";
import { bulkActionNameSelector } from "@store/table/table-selector";
import { OpenTabEvents } from "@utils/track-event/track-event-list";

/**
 * Group details page
 */
export function GroupDetails(): JSX.Element {
  const { companyId, groupId, groupTabs } = useAppParams();
  const coreApiClient = useCoreApiClient();
  const dispatch = useAppDispatch();

  const selectedGroup = useAppSelector(selectedGroupSelector);
  const bulkActionName = useAppSelector(bulkActionNameSelector);

  // Fetches the group details. if bulkActionName is null, it means that whether:
  // 1. the page is loaded because user opens it
  // 2. the user has just performed a bulk action on managers or projects which might affect the group details property.
  // So, in both cases, fetching would be encouraged
  // If bulkActionName is not null, it means that user has just started a bulk action and
  // group fetching is not needed as it triggers the whole group table fetching again
  useEffect(() => {
    if (companyId && groupId && bulkActionName === null) {
      dispatch(fetchGroupDetails({ coreApiClient, companyId, groupId }));
    }
  }, [companyId, coreApiClient, dispatch, groupId, bulkActionName]);

  if (!groupTabs || !Object.values(GroupTabs).includes(groupTabs)) {
    return <NotFoundPage />;
  }

  return (
    <TabsWithRoutes
      selectedTab={groupTabs}
      selectedProject={null}
      trackingEventPageName={OpenTabEvents.openGroupTab}
      requiredAttribute={
        selectedGroup &&
        // Not only a selected group is required, but it has to be of group details type
        (isGroupDetails(selectedGroup) ? selectedGroup : null)
      }
      tabs={[
        {
          label: "Managers",
          route: GroupTabs.managers,
          content: (group) => <GroupManagers group={group} />,
          loadingContent: <GroupManagers isLoading={true} />,
          requiredRoleCompanyLevel:
            RequiredRoleCompanyLevelName.canViewAllCompanyGroups,
        },
        {
          label: "Active projects",
          route: GroupTabs.projects,
          content: (group) => <GroupProjects group={group} />,
          loadingContent: <GroupProjects isLoading={true} />,
          requiredRoleCompanyLevel:
            RequiredRoleCompanyLevelName.canViewAllCompanyGroups,
        },
      ]}
    />
  );
}
