import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  displayProjectsSelector,
  fetchingProjectsFlagsSelector,
  hasLoadedProjectsSelector,
  nextProjectSelector,
} from "@store/projects/projects-selector";
import { increaseNumberOfDisplayedProjects } from "@store/projects/projects-slice";
import { useCoreApiClient } from "@api/use-core-api-client";
import { ProjectsPageTable } from "@pages/projects/projects-page-table";
import { dataViewModeSelector, searchSelector } from "@store/ui/ui-selector";
import { DataViewMode } from "@store/ui/ui-slice";
import { NotFoundPage } from "@pages/not-found-page";
import { ProjectArchivingState } from "@custom-types/project-types";
import { useDebounce } from "@utils/time-utils";
import { useAppParams } from "@router/router-helper";
import { ProjectsTiles } from "@pages/projects/projects-tiles";
import { ProjectsOverviewEmptyPage } from "@components/common/empty-page/projects-overview-empty-page";
import {
  SearchbarEvents,
  ButtonEvents,
} from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import {
  fetchProjects,
  fetchSearchedProjects,
} from "@store/projects/projects-slice-thunk";

interface Props {
  /** The archiving-state of the projects that will be shown */
  projectArchivingState: ProjectArchivingState;

  /**
   * Flag whether the content should be shown as loading regardless whether it was loaded.
   * This is useful when the parent component know that the content should be loading,
   * e.g. it is still loading the permissions.
   */
  shouldForceLoading?: boolean;
}

/** Debounce interval in milliseconds */
const DEBOUNCE_TIME = 300;

/**
 * The projects tab listing all the active or archived projects (depending on the props) in the company
 */
export function ProjectsOverview({
  projectArchivingState,
  shouldForceLoading = false,
}: Props): JSX.Element {
  const { companyId } = useAppParams();
  const coreApiClient = useCoreApiClient();
  const dispatch = useAppDispatch();
  const { searchText } = useAppSelector(searchSelector);
  const { trackEvent } = useTrackEvent();

  /**
   * Flag whether it is debouncing (waiting) to start the call to search for projects.
   * If true, it means that the projects will be searched again soon, but an indication
   * could be shown already (e.g. a loading spinner).
   */
  const [isDebouncingSearchingProjects, setIsDebouncingSearchingProjects] =
    useState(false);
  // Uses useDebounce hook to react to changes on the search input text.
  const debouncedSearchText = useDebounce(
    searchText,
    DEBOUNCE_TIME,
    () => {
      setIsDebouncingSearchingProjects(true);
    },
    () => {
      // The time chosen is small enough for the user not to perceive it as a delay,
      // But large enough to wait for 3 repaint cycles in the browser.
      const WAIT_TIME = 50;
      // We do not immediately set isDebouncingSearchingProjects false.
      // That way the spinner is not immediately hidden preventing a flickering effect in the spinner,
      // when soon after its disappearance another process shows it (in this case isFetchingProjects = true).
      // By doing this wait, the spinner animation below the projects looks smoother,
      // otherwise it disappears for an instance and then appears again, looking glitchy.
      setTimeout(() => {
        setIsDebouncingSearchingProjects(false);
      }, WAIT_TIME);
    }
  );

  const { projectsView } = useAppSelector(dataViewModeSelector);

  const { isFetchingProjects } = useAppSelector(fetchingProjectsFlagsSelector);
  const hasLoadedProjects = useAppSelector(
    hasLoadedProjectsSelector(projectArchivingState)
  );
  const displayProjects = useAppSelector(
    displayProjectsSelector(projectArchivingState)
  );

  /** Stores the id of the next project to be fetched */
  const nextProject = useAppSelector(nextProjectSelector);

  /** Computes whether it is still loading to fetch all projects */
  const isLoading = useMemo(() => {
    return (
      isFetchingProjects ||
      isDebouncingSearchingProjects ||
      shouldForceLoading ||
      !hasLoadedProjects
    );
  }, [
    isFetchingProjects,
    isDebouncingSearchingProjects,
    shouldForceLoading,
    hasLoadedProjects,
  ]);

  /** Flag whether the empty page should be shown, because it is no longer loading and does not have projects */
  const shouldShowEmptyPage = useMemo(() => {
    return !isLoading && hasLoadedProjects && displayProjects.length === 0;
  }, [isLoading, hasLoadedProjects, displayProjects]);

  /**
   * Flag whether the load more projects button should be hidden, either because there are no more projects to fetch,
   * or because the user is searching for projects.
   */
  const shouldHideLoadMoreButton = useMemo(() => {
    return !nextProject || !!searchText;
  }, [nextProject, searchText]);

  // Fetches the searched projects
  useEffect(() => {
    async function getSearchedProjects(): Promise<void> {
      if (companyId) {
        await dispatch(
          fetchSearchedProjects({
            coreApiClient,
            projectArchivingState,
            companyId,
            searchText: debouncedSearchText,
          })
        );
      }
    }
    if (searchText) {
      getSearchedProjects();
    }
  }, [
    companyId,
    coreApiClient,
    dispatch,
    projectArchivingState,
    debouncedSearchText,
    searchText,
  ]);

  // Tracks if the user searches for a project
  useEffect(() => {
    if (debouncedSearchText) {
      trackEvent({
        name: SearchbarEvents.searchForProject,
      });
    }
  }, [debouncedSearchText, trackEvent]);

  /**
   * Calls the backend to fetch more projects and increases the number of projects shown on the screen.
   */
  async function loadMoreProjects(): Promise<void> {
    if (companyId && nextProject) {
      trackEvent({
        name: ButtonEvents.loadMore,
        props: {
          data: `${projectArchivingState} projects`,
        },
      });
      await dispatch(
        fetchProjects({
          coreApiClient,
          companyId,
          projectArchivingState,
          next: nextProject,
        })
      );
      dispatch(increaseNumberOfDisplayedProjects());
    }
  }

  if (!companyId) {
    return <NotFoundPage />;
  }

  // If fetching is done and displayProjects is empty, then
  // either no project with selected archive state exist or no project with the searchText is available
  if (shouldShowEmptyPage) {
    return (
      <ProjectsOverviewEmptyPage
        projectArchivingState={projectArchivingState}
      />
    );
  }

  if (projectsView === DataViewMode.list) {
    return (
      <ProjectsPageTable
        projects={displayProjects}
        companyId={companyId}
        projectStatus={projectArchivingState}
        isLoading={isLoading}
        shouldHideLoadMoreButton={shouldHideLoadMoreButton}
        loadMoreProjects={loadMoreProjects}
      />
    );
  } else {
    return (
      <ProjectsTiles
        projects={displayProjects}
        companyId={companyId}
        isLoading={isLoading}
        shouldHideLoadMoreButton={shouldHideLoadMoreButton}
        loadMoreProjects={loadMoreProjects}
        projectStatus={projectArchivingState}
      />
    );
  }
}
