import { ClickAwayListener, Popper } from "@mui/material";
import { FaroDateTextField } from "@components/common/faro-text-button-date-picker/faro-date-text-field";
import { useMemo, useState } from "react";
import { DateTime } from "luxon";
import DateAdapter from "@date-io/luxon";
import { FaroDatePickerHeader } from "@components/common/faro-text-button-date-picker/faro-date-picker-header";
import { DateCalendar } from "@mui/x-date-pickers-pro";
import { Overlay } from "@components/overlay/overlay";

interface Props {
  /** The initial date that is shown in datePicker. Null will show the given format */
  initialDate: Date | null;

  /** Handler when changing the date */
  onDateConfirmed(updatedDate: DateTime<boolean> | null): void;

  /** The anchor element that the date picker is attached to */
  anchorEl: HTMLElement | null;

  /** Handler when date picker will close */
  closeDatePicker: () => void;
}

/** A date picker text and calendar shown in a popper. Needs to be attached to an element */
export function FaroDatePicker({
  initialDate,
  onDateConfirmed,
  anchorEl,
  closeDatePicker,
}: Props): JSX.Element {
  const dateAdapter = useMemo(() => new DateAdapter(), []);
  const [date, setDate] = useState<DateTime | null>(
    initialDate ? dateAdapter.date(initialDate) : null
  );

  return (
    <Overlay>
      <ClickAwayListener onClickAway={closeDatePicker}>
        <Popper
          onKeyDown={(event) => {
            if (event.key === "Escape") {
              closeDatePicker();
              event.stopPropagation();
            }
          }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement={"bottom-start"}
          disablePortal={false}
          sx={{
            backgroundColor: "white",
            boxShadow: "0px 3px 3px 0px rgba(133, 146, 173, 0.20)",
            zIndex: "9999",
          }}
        >
          <FaroDateTextField
            date={date}
            onConfirmed={onDateConfirmed}
            onChange={(value: DateTime | null) => setDate(value)}
          />
          <DateCalendar
            slots={{
              /** The header showing the month and allowing user to move to other months */
              calendarHeader: FaroDatePickerHeader,
            }}
            // Having value is important to show the typed date in toolbar in the calendar view
            value={date}
            // handler for when user selects a date in calendar view
            onChange={onDateConfirmed}
          />
        </Popper>
      </ClickAwayListener>
    </Overlay>
  );
}
