import { AvatarGroup, Grid } from "@mui/material";
import { projectMembersSelector } from "@store/projects/projects-selector";
import { useAppSelector } from "@store/store-helper";
import { getPrettyName } from "@utils/user-utils";
import { TeamMemberAvatar } from "@pages/project-details/project-overview/team-member-avatar";
import { sphereColors } from "@styles/common-colors";
import { LabelWithHelp } from "@components/common/label-with-help";

/**
 * Shows the details regarding team members for a project
 */
export function MemberDetails(): JSX.Element {
  const maxMemberAvatars = 4;

  const projectMembers = useAppSelector(projectMembersSelector);

  // Show the project members sorted by name
  const sortedProjectMembers = [...projectMembers].sort((a, b) =>
    getPrettyName(a).localeCompare(getPrettyName(b))
  );

  return (
    <Grid container alignItems="flex-end" justifyContent="space-between">
      <Grid item>
        <LabelWithHelp
          title="Team Members"
          sx={{
            color: sphereColors.gray500,
            fontWeight: "normal",
            fontSize: "11px",
          }}
        />
        {/* Since we use our own function to show the last element, prevent that the component
            will collapse them by supporting 1 more than the number of elements */}
        <AvatarGroup
          sx={{ justifyContent: "flex-end" }}
          max={maxMemberAvatars + 1}
        >
          {/* Only show the first section of the members */}
          {sortedProjectMembers.slice(0, maxMemberAvatars).map((member) => (
            <TeamMemberAvatar key={member.identity} member={member} />
          ))}
          {sortedProjectMembers.length > maxMemberAvatars && (
            <TeamMemberAvatar
              key="more-members"
              // Show the remaining members
              members={sortedProjectMembers.slice(maxMemberAvatars)}
            />
          )}
        </AvatarGroup>
      </Grid>
    </Grid>
  );
}
