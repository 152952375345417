import { createContext, useContext } from "react";
import { LoginStatus } from "@context-providers/auth/auth-types";

/** Context providing authentication information */
type AuthContextData = {
  /** Shows the session expired dialog */
  showSessionExpiredDialog(): void;

  /** Logs out the user */
  logout(): void;

  /** Login status of the current user */
  loginStatus: LoginStatus;
};

export const AuthContext = createContext<AuthContextData | undefined>(
  undefined
);

/**
 * @returns the current AuthContext or error if not available
 */
export function useAuthContext(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw Error(
      "useAuthContext() must be used inside the AuthProvider component"
    );
  }

  return context;
}
