import { ValueLabelItem } from "@custom-types/types";
import { FormControlLabel, Radio, SxProps, useRadioGroup } from "@mui/material";
import FaroRadioButtonUnchecked from "@assets/icons/new/radio_unchecked_16px.svg?react";
import FaroRadioButtonChecked from "@assets/icons/new/radio_checked_16px.svg?react";
import { sphereColors } from "@styles/common-colors";
import { useMemo } from "react";

interface Props<T extends string | number> {
  /** Item to pass to the radio button */
  item: ValueLabelItem<T>;

  /** If true the radio button is disabled */
  isDisabled?: boolean;

  /** Optional style to pass to the FormControlLabel wrapper component */
  formControlLabelSx?: SxProps;
}

/** Renders a radio button inside a form control label */
export function SphereRadioButton<T extends string | number>({
  item,
  isDisabled,
  formControlLabelSx,
}: Props<T>): JSX.Element {
  const radioGroup = useRadioGroup();

  const isChecked = useMemo(() => {
    return radioGroup?.value === item.value;
  }, [item, radioGroup]);

  return (
    <FormControlLabel
      value={item.value}
      label={item.label}
      key={item.value}
      control={
        <Radio
          size="small"
          icon={
            <FaroRadioButtonUnchecked
              color={isDisabled ? sphereColors.gray400 : sphereColors.gray800}
            />
          }
          checkedIcon={
            <FaroRadioButtonChecked
              color={isDisabled ? sphereColors.gray400 : sphereColors.blue500}
            />
          }
          disabled={isDisabled}
        />
      }
      sx={{
        margin: "0px",

        "& .MuiFormControlLabel-label": {
          fontSize: "14px",
          whiteSpace: "nowrap",
          lineHeight: "34px",
          color: isChecked ? sphereColors.blue500 : sphereColors.gray800,
        },
        ...formControlLabelSx,
      }}
    />
  );
}
