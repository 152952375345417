import { EmptyPage } from "@components/common/empty-page/empty-page";
import { Skeleton } from "@mui/material";
import { NotFoundPage } from "@pages/not-found-page";
import { AddOnsTabs, MemberTabs } from "@router/route-params";
import {
  generateAddOnsRoute,
  generateMemberProfileRoute,
} from "@router/router-helper";
import { selectedSdbCompanyIdSelector } from "@store/sdb-company/sdb-company-selector";
import WarningIcon from "@assets/icons/new/warning_60px.svg?react";
import { useAppSelector } from "@store/store-helper";
import {
  currentUserSelector,
  fetchingUserFlagsSelector,
} from "@store/user/user-selector";
import { Navigate, useLocation } from "react-router-dom";
import { FaroButton } from "@components/common/faro-button";
import { contactSupport } from "@utils/email-utils";
import { ContactSupportLocations } from "@custom-types/types";
import { useTrackEvent } from "@utils/track-event/use-track-event";

/** List of all routes of HDB that are deprecated and not planned yet to implement in SDB */
const deprecatedFeatureRoutes = ["/settings/templates/categories"];

/**
 * Manages redirection of the unknown routes.
 */
export function RedirectUnknownRoutes(): JSX.Element {
  const location = useLocation();
  const { trackEvent } = useTrackEvent();

  const companyId = useAppSelector(selectedSdbCompanyIdSelector);
  const currentUser = useAppSelector(currentUserSelector);
  const { isFetchingCurrentUser } = useAppSelector(fetchingUserFlagsSelector);

  const currentPath = location.pathname;
  const currentPathWithoutCompanyId = currentPath.replace(`/${companyId}`, "");
  const normalizedCurrentPath = currentPathWithoutCompanyId.endsWith("/")
    ? currentPathWithoutCompanyId.slice(0, -1)
    : currentPathWithoutCompanyId;

  /** Showing a loading screen before initiate the redirection */
  if (!companyId || isFetchingCurrentUser) {
    return (
      <Skeleton
        variant="rectangular"
        sx={{ width: "100%", height: "70vh", mt: "50px", ml: "14px" }}
      />
    );
  }

  /** Redirect users from HBD route "/me" to the account page */
  if (companyId && currentUser && normalizedCurrentPath === "/me") {
    return (
      <Navigate
        to={generateMemberProfileRoute(
          companyId,
          currentUser.identity,
          MemberTabs.account
        )}
      />
    );
  }

  if (companyId && normalizedCurrentPath === "/settings/integrations") {
    return (
      <Navigate to={generateAddOnsRoute(companyId, AddOnsTabs.integrations)} />
    );
  }

  if (companyId && normalizedCurrentPath === "/settings/addOns-and-services") {
    return (
      <Navigate to={generateAddOnsRoute(companyId, AddOnsTabs.extensions)} />
    );
  }

  /** Redirect to deprecated feature page if the routes is pointing to deprecated features of HBD*/
  if (deprecatedFeatureRoutes.includes(normalizedCurrentPath)) {
    return (
      <EmptyPage
        title="The feature is no longer supported"
        subtitle="The feature you are looking for is no longer supported in Sphere XG"
        icon={WarningIcon}
        button={
          <FaroButton
            onClick={() =>
              contactSupport({
                location: ContactSupportLocations.settingsDeprecatedPage,
                trackEvent,
              })
            }
          >
            Contact support
          </FaroButton>
        }
      />
    );
  }

  return <NotFoundPage />;
}
