import { useCallback, useEffect, useState } from "react";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useAppSelector } from "@store/store-helper";
import { loggedInUserWithStatusSelector } from "@store/user/user-selector";
import { useCoreApiClient } from "@api/use-core-api-client";
import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { useToast } from "@hooks/use-toast";
import WarningIcon from "@assets/icons/warning_32px.svg?react";
import CloseIcon from "@assets/icons/new/close_24px.svg?react";
import {
  Alert,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { EDecimalToHex, sphereColors } from "@styles/common-colors";
import { FaroTextButton } from "@components/common/faro-text-button";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { addTransparency } from "@utils/ui-utils";

/** Display state of the resend verification banner */
type BannerDisplayState =
  | "hidden"
  | "emailNotConfirmed"
  | "emailConfirmationExpired";

/** Renders the dialog to remind users to verify their email address or to request a new verification email */
export function ResendVerification(): JSX.Element | null {
  const { showToast } = useToast();
  const { handleErrorWithToast } = useErrorContext();
  const coreApiClient = useCoreApiClient();
  const loggedInUser = useAppSelector(loggedInUserWithStatusSelector);

  const theme = useTheme();
  const isScreenLargeAndSmaller = useMediaQuery(theme.breakpoints.down("lg"));
  const isScreenXs = useMediaQuery(theme.breakpoints.only("xs"));

  const [bannerDisplayState, setBannerDisplayState] =
    useState<BannerDisplayState>("hidden");

  /** Determines the banner display sate */
  useEffect(() => {
    if (loggedInUser?.status === CoreAPITypes.CoreAPIResponseStatus.warning) {
      if (
        loggedInUser?.code ===
        SphereDashboardAPITypes.CoreAPIResponseWarningCode.UserEmailNotConfirmed
      ) {
        setBannerDisplayState("emailNotConfirmed");
      } else if (
        loggedInUser?.code ===
        SphereDashboardAPITypes.CoreAPIResponseWarningCode
          .UserEmailConfirmationExpired
      ) {
        setBannerDisplayState("emailConfirmationExpired");
      }
    }
  }, [loggedInUser?.code, loggedInUser?.status]);

  /** Send verification url through email */
  const resendVerificationUrl = useCallback(async () => {
    if (loggedInUser) {
      try {
        await coreApiClient.V3.SDB.resendVerificationMail(
          loggedInUser.data.mailAddress
        );

        showToast({
          message: "New verification mail was sent to your mail address",
          type: "success",
        });
      } catch (error) {
        handleErrorWithToast({
          id: `resendVerificationEmail-${Date.now().toString()}`,
          title: "Error resending verification email",
          error,
        });
      } finally {
        setBannerDisplayState("hidden");
      }
    }
  }, [coreApiClient.V3.SDB, handleErrorWithToast, loggedInUser, showToast]);

  /** Early return if the banner shouldn't show */
  if (bannerDisplayState === "hidden") {
    return null;
  }

  return (
    <Alert
      icon={false}
      severity="warning"
      sx={{
        backgroundColor: sphereColors.yellow100,
        alignItems: "center",
        py: "0px",
        "& .MuiAlert-message": {
          width: "100%",
        },
        // Keep the alert box at the bottom position of small screen
        ...(isScreenXs && {
          position: "fixed",
          bottom: 0,
          zIndex: 1,
        }),
      }}
    >
      <Grid container>
        <Grid
          item
          xs={8}
          sm={8}
          md={8}
          lg={9}
          xl={10}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid container justifyContent="flex-start">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={3}
              xl={6}
              sx={{
                // Keep title and description on the straight alignment in mobile screen
                ...(isScreenXs && {
                  display: "flex",
                  alignItems: "center",
                }),
                // Keep title and description text on the left of the screen in desktop screen
                ...(!isScreenXs && {
                  display: "contents",
                }),
              }}
            >
              <WarningIcon width="20px" height="20px" />
              <Typography
                sx={{
                  fontSize: isScreenXs ? "16px" : "14px",
                  color: sphereColors.gray800,
                  mx: "16px",
                  fontWeight: "700",
                }}
              >
                Unverified email
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={9}
              xl={6}
              sx={{ ml: isScreenLargeAndSmaller ? "36px" : "" }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: sphereColors.gray800,
                  // Add space and adjust the text width in small screen
                  ...(isScreenXs && {
                    marginBottom: "50px",
                    width: "75vw",
                  }),
                }}
              >
                {bannerDisplayState === "emailNotConfirmed" &&
                  "To continue using Sphere XG, verify your email address. Didn’t get an email?"}
                {bannerDisplayState === "emailConfirmationExpired" &&
                  "To continue using Sphere XG, request a new verification email to verify your email address."}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          lg={3}
          xl={2}
          display="flex"
          justifyContent="flex-end"
        >
          <Grid container justifyContent="flex-end">
            <Grid
              item
              xs={12}
              sm={12}
              md={9}
              lg={9}
              xl={9}
              // Change order in small devices to place the the close button at top corner of the card
              order={{ xl: 0, lg: 0, md: 0, sm: 1, xs: 1 }}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <FaroTextButton
                onClick={resendVerificationUrl}
                sx={{
                  mr: !isScreenLargeAndSmaller ? "16px" : "0",
                  "&:hover": {
                    backgroundColor: addTransparency({
                      color: sphereColors.gray500,
                      alpha: EDecimalToHex.thirtyEight,
                    }),
                  },
                  "&:active, &:focus": {
                    backgroundColor: addTransparency({
                      color: sphereColors.gray500,
                      alpha: EDecimalToHex.sixtyFour,
                    }),
                  },
                  // Add space and full width of resend email button in small screen
                  ...(isScreenXs && {
                    marginBottom: "-60px",
                    width: "175px",
                  }),
                }}
              >
                Resend verification email
              </FaroTextButton>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              sx={{
                display: "contents",
              }}
            >
              <FaroIconButton
                component={CloseIcon}
                onClick={() => setBannerDisplayState("hidden")}
                iconButtonProps={{
                  // Adjust alignment of the close icon in small screen
                  sx: { ...(isScreenXs && { marginTop: "-7px" }) },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Alert>
  );
}
