import { useCoreApiClient } from "@api/use-core-api-client";
import { FaroTextField } from "@components/common/faro-text-field/faro-text-field";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { CoreAPITypes } from "@stellar/api-logic";
import { updateGroupDetails } from "@store/groups/groups-slice";
import { useAppDispatch } from "@store/store-helper";
import { MIN_GROUP_NAME_LENGTH } from "@utils/group-utils";
import { ChangeGroupDetailsEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";

interface Props extends Pick<CoreAPITypes.IGroup, "name"> {
  /** True if the group name should be editable */
  isEditable: boolean;
}

/** Renders and updates the group name */
export function EditableGroupName({ name, isEditable }: Props): JSX.Element {
  const { trackEvent } = useTrackEvent();
  const { handleErrorWithToast } = useErrorContext();
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();

  /**
   * Updates the group name in the backend and returns the new value if successful.
   * Otherwise, returns the old value.
   *
   * @param value The new project name.
   */
  async function onUpdateName(value: string): Promise<string> {
    trackEvent({
      name: ChangeGroupDetailsEvents.changeGroupName,
      props: { newNameLength: value.length },
    });

    try {
      await dispatch(
        updateGroupDetails({
          coreApiClient,
          payload: { name: value.trim() },
        })
      );
      return value;
    } catch (error) {
      handleErrorWithToast({
        id: `changeGroupName-${Date.now().toString()}`,
        title: "Could not change group name",
        error,
      });
      return name ?? "";
    }
  }
  return (
    <FaroTextField
      label="Group name"
      initialValue={name}
      faroVariant="main"
      fontSize="18px"
      isReadOnly={!isEditable}
      shouldShowTooltip
      minInputLength={MIN_GROUP_NAME_LENGTH}
      onConfirmed={onUpdateName}
    />
  );
}
