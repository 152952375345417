import { Typography } from "@mui/material";
import InfoIcon from "@assets/icons/new/info_20px.svg?react";
import { sphereColors } from "@styles/common-colors";

export interface AutoCompleteMessage {
  /** Message type of helper text */
  type: "warning" | "error" | "info";

  /** Displayed under the input field as helper text */
  helperText: string;
}

interface Props {
  /** Flag whether the message should be hidden */
  shouldHide?: boolean;

  /** The message to be displayed. */
  message?: AutoCompleteMessage;
}

/** Renders a message to be shown below a text field */
export function FaroTextFieldMessage({
  shouldHide = false,
  message,
}: Props): JSX.Element {
  if (!message || shouldHide) {
    // eslint-disable-next-line react/jsx-no-useless-fragment -- Fragment is needed for return
    return <></>;
  }

  return (
    <Typography
      variant="caption"
      display="flex"
      alignItems="center"
      visibility={message ? "visible" : "hidden"}
      color={message?.type === "error" ? sphereColors.red600 : "inherit"}
    >
      {message?.type === "info" && (
        <InfoIcon height={12} width={12} style={{ marginRight: "5px" }} />
      )}
      {message.helperText}
    </Typography>
  );
}
