import { useEffect } from "react";
import { useCoreApiClient } from "@api/use-core-api-client";
import { useAppParams } from "@router/router-helper";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { groupsSelector } from "@store/groups/groups-selector";
import { fetchGroups } from "@store/groups/groups-slice";
import { SphereDashboardAPITypes } from "@stellar/api-logic";

/** A hook that returns all groups the user has access to */
export function useGroups(): SphereDashboardAPITypes.ICompanyGroup[] {
  const coreApiClient = useCoreApiClient();
  const { companyId } = useAppParams();
  const dispatch = useAppDispatch();
  const groups = useAppSelector(groupsSelector);

  // Fetch all the groups in the company
  useEffect(() => {
    async function fetchGroupsViaStore(): Promise<void> {
      if (companyId) {
        await dispatch(
          fetchGroups({
            coreApiClient,
            companyId,
          })
        );
      }
    }

    fetchGroupsViaStore();
  }, [companyId, coreApiClient, dispatch]);

  return groups;
}
