import { BaseBackgroundTaskProps } from "@custom-types/sdb-background-tasks-types";
import OpenViewerIcon from "@assets/icons/new/open-in-viewer.svg?react";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { Box, Link } from "@mui/material";
import { ProjectActivityEvents } from "@utils/track-event/track-event-list";
import { FaroIconButton } from "@components/common/faro-icon-button";
import {
  ACTION_BTN_SIZE,
  ACTION_ICON_SIZE,
} from "@components/common/project-actions";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { OPEN_OTHERS_TARGET_ATTRIBUTE } from "@utils/project-utils";

/** Renders the actions buttons available for a background task */
export function BackgroundTaskActions({
  task,
}: BaseBackgroundTaskProps): JSX.Element | null {
  const { trackAsyncEvent } = useTrackEvent();
  const url = task.viewerDeepLink;

  async function onClick(
    event: React.MouseEvent<Element, MouseEvent>
  ): Promise<void> {
    // Stop the event so the MUI Link component does not navigate to the passed url immediately.
    // That way we can track the event first and then redirect to the url afterwards.
    event.preventDefault();
    event.stopPropagation();

    if (!url) {
      return;
    }

    await trackAsyncEvent({
      name: ProjectActivityEvents.openVideoModeTrajectory,
      props: {
        openFrom: "projectActivity",
      },
    });

    window.open(url, OPEN_OTHERS_TARGET_ATTRIBUTE);
  }

  // Hide the component if the url is not available or if the task has not succeeded
  if (!url || task.status !== "Succeeded") {
    return null;
  }

  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "right",
      }}
    >
      {/* Open VideoMode capture path (trajectory) */}
      <SphereTooltip title="View capture path">
        <Link href={url} target={OPEN_OTHERS_TARGET_ATTRIBUTE}>
          <FaroIconButton
            buttonSize={ACTION_BTN_SIZE}
            iconSize={ACTION_ICON_SIZE}
            component={OpenViewerIcon}
            onClick={onClick}
          />
        </Link>
      </SphereTooltip>
    </Box>
  );
}
