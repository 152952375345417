import { Box, Typography } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { getProjectModifiedElapsed } from "@utils/project-utils";
import { BaseProjectProps } from "@custom-types/project-types";
import { cardProperties } from "@utils/ui-utils";

/**
 * Middle section of the card that shows the project details like name and last update.
 */
export function CardMiddle({ project }: BaseProjectProps): JSX.Element {
  return (
    <Box
      component="div"
      sx={{
        paddingTop: cardProperties.creationDatePaddingTop,
        paddingBottom: 0,
        paddingX: "14px",
        height: "100px",
      }}
    >
      <Typography
        sx={{
          color: sphereColors.gray700,
          fontSize: "11px",
        }}
      >
        {getProjectModifiedElapsed(project)}
      </Typography>
      <Typography
        gutterBottom
        component="div"
        sx={{
          fontSize: "16px",
          color: sphereColors.gray800,
          fontWeight: 600,
          overflow: "hidden",
          textOverflow: "ellipsis",
          // !: Do not use ...withEllipsis
          // because we don't need whiteSpace: "nowrap" in order to
          // break the line in two rows.
          wordBreak: "break-word",
          lineHeight: "24px",
          // These properties allow to show an ellipsis on two lines
          // Unfortunately it is only allowed in webkit because there's
          // no native support from CSS for this, only for one line.
          display: "-webkit-box",
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
        }}
      >
        <var>{project.name}</var>
      </Typography>
    </Box>
  );
}
