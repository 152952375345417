import {
  FaroDialog,
  SPACE_ELEMENTS_OF_MODAL,
} from "@components/common/dialog/faro-dialog";
import { SphereDivider } from "@components/common/sphere-divider";
import { Box, List, SvgIcon, Typography } from "@mui/material";
import { getSvgColoredIconCss } from "@styles/common-styles";
import FailedIcon from "@assets/icons/failed_32px.svg?react";
import { sphereColors } from "@styles/common-colors";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { setBulkActionName } from "@store/table/table-slice";
import {
  isBulkActionProcessingSelector,
  updatingItemsSelector,
} from "@store/table/table-selector";
import { BulkActionListItem } from "@components/common/faro-table/bulk-dialog/content-list/bulk-action-list-item";
import { BulkDialogContentProps } from "@components/common/faro-table/bulk-dialog/bulk-dialog-types";
import { BulkActionEvents } from "@utils/track-event/track-event-list";
import { PropsWithChildren, useMemo } from "react";
import { useBulkActionCallback } from "@hooks/table/use-bulk-action-callback";
import { TableDataType } from "@components/common/faro-table/faro-table-types";

interface Props<T> {
  /** The content of the dialog */
  dialogContent: BulkDialogContentProps;

  /** Callback when the dialog closes */
  onClose: () => void;

  /** Function to be applied on each selected entity */
  bulkActionCallback: (entity: T) => Promise<unknown>;

  /** The event name sent for tracking */
  trackingEvent: BulkActionEvents;

  /** Whether to disable the confirm button or not */
  shouldDisableConfirmButton?: boolean;

  /** The entities that are selected */
  selectedEntities: T[];

  /** A key from entity that is unique like ID and it should be in the type of number or string */
  uniqueIdKey: keyof T;

  /** The type of entity for bulk actions */
  tableDataType: TableDataType;
}

/** Dialog for showing bulk action confirmation and report */
export function FaroBulkDialog<T>({
  dialogContent,
  onClose,
  bulkActionCallback,
  trackingEvent,
  children,
  shouldDisableConfirmButton = false,
  selectedEntities,
  uniqueIdKey,
  tableDataType,
}: PropsWithChildren<Props<T>>): JSX.Element {
  const dispatch = useAppDispatch();
  const updatingItems = useAppSelector(updatingItemsSelector);
  const isBulkActionProcessing = useAppSelector(isBulkActionProcessingSelector);

  const { onBulkActionConfirmed } = useBulkActionCallback({
    selectedEntities,
    uniqueIdKey,
    bulkActionCallback,
    trackingEvent,
  });

  const isConfirmButtonDisabled = useMemo(() => {
    // Disable confirm button if none of the items are allowed to be updated
    const isAllItemsNotAllowed = updatingItems.every(
      (item) => item.status === "not-allowed"
    );
    return shouldDisableConfirmButton || isAllItemsNotAllowed;
  }, [shouldDisableConfirmButton, updatingItems]);

  return (
    <FaroDialog
      title={dialogContent.title}
      iconInTitle={
        dialogContent.shouldShowFailedIcon ? (
          <SvgIcon
            inheritViewBox
            component={FailedIcon}
            sx={{
              ...getSvgColoredIconCss(sphereColors.red600),
              height: "28px",
              width: "28px",
              mr: "12px",
            }}
          />
        ) : null
      }
      confirmText={dialogContent.confirmButtonText}
      open={true}
      isConfirmDisabled={isConfirmButtonDisabled}
      onConfirm={onBulkActionConfirmed}
      onClose={() => {
        dispatch(setBulkActionName(null));
        onClose();
      }}
      isConfirmLoading={isBulkActionProcessing}
    >
      {/* Description of the dialog */}
      <Typography
        sx={{
          fontSize: "14px",
          mb: SPACE_ELEMENTS_OF_MODAL,
        }}
      >
        {dialogContent.description}
      </Typography>

      {children}

      <SphereDivider sx={{ mb: SPACE_ELEMENTS_OF_MODAL }} />

      <Typography
        sx={{
          fontSize: "14px",
          mb: SPACE_ELEMENTS_OF_MODAL,
        }}
      >
        {dialogContent.selectedEntitiesReport}
      </Typography>

      {/* List of items being updated */}
      <Box component="div" sx={{ maxHeight: "200px", overflow: "auto" }}>
        <List dense sx={{ py: "0px" }}>
          {updatingItems.map((updatingItem) => (
            <BulkActionListItem
              key={updatingItem.id}
              item={updatingItem}
              tableDataType={tableDataType}
            />
          ))}
        </List>
      </Box>
    </FaroDialog>
  );
}
