import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { AuthContext } from "@context-providers/auth/auth-context";
import { useLoadingSpinner } from "@context-providers/loading-spinner-provider";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { resetStore } from "@store/store";
import { runtimeConfig } from "@src/runtime-config";
import { FaroDialog } from "@components/common/dialog/faro-dialog";
import {
  AuthBroadcastChannel,
  useAuthBroadcastChannel,
} from "@context-providers/auth/use-auth-broadcast-channel";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useCoreApiClient } from "@api/use-core-api-client";
import { loggedInUserWithStatusSelector } from "@store/user/user-selector";
import { isLoginProviderFaro } from "@context-providers/auth/auth-utils";
import { CoreAPIUtils } from "@stellar/api-logic";
import { StatusCodes } from "http-status-codes";
import {
  isUlmRedirectMessage,
  UlmRedirectMessage,
} from "@faro-lotv/gate-keepers";
import { getUlmUrl } from "@context-providers/auth/auth-utils";
import { LoginStatus } from "@context-providers/auth/auth-types";
import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";

/**
 * Component that handles authentication actions with the Unified Login Mask (ULM):
 * - Checks the user login status.
 * - Checks if the user sessions has expired.
 * - Handles login and logout actions.
 * - TODO: Checks if the user has confirmed their email after registration: https://faro01.atlassian.net/browse/ST-2258
 * - TODO: Checks if the user information is complete: https://faro01.atlassian.net/browse/ST-1593.
 */
export function AuthProvider({ children }: PropsWithChildren): JSX.Element {
  const [loginStatus, setLoginStatus] = useState<LoginStatus>("unknown");
  const [shouldShowSessionExpiredDialog, setShouldShowSessionExpiredDialog] =
    useState<boolean>(false);
  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);
  const [auth0LogoutUrl, setAuthLogout0Url] = useState<string | undefined>(
    undefined
  );

  const { setLoadingSpinner } = useLoadingSpinner();
  const dispatch = useAppDispatch();
  const { handleErrorWithToast, handleErrorWithPage } = useErrorContext();
  const coreApiClient = useCoreApiClient();
  const loggedInUser = useAppSelector(loggedInUserWithStatusSelector);
  const broadcastChannel = useAuthBroadcastChannel({
    logoutCallback: () => setShouldShowSessionExpiredDialog(true),
  });

  /**
   * Fetches the current user in order to check the login status of the user.
   */
  const checkLogin = useCallback(async (): Promise<void> => {
    setLoadingSpinner(true);

    try {
      await coreApiClient.V3.SDB.getLoggedInUser();
      setLoginStatus("loggedIn");
    } catch (error) {
      if (
        CoreAPIUtils.isResponseError(error) &&
        (error.status === StatusCodes.FORBIDDEN ||
          error.status === StatusCodes.UNAUTHORIZED)
      ) {
        setLoginStatus("loggedOut");
      } else {
        handleErrorWithPage({
          id: `getLoggedInUser-${Date.now().toString()}`,
          title:
            "Failed to check the login status of the user. Please reload the page to try again.",
          error: getErrorDisplayMarkup(error),
        });
      }

      // Only stop the loading spinner if the user is not logged in in order to show either the error page or the ULM.
      // If the user is logged in keep the loading spinner because the app will continue to load data.
      setLoadingSpinner(false);
    }
  }, [coreApiClient, handleErrorWithPage, setLoadingSpinner]);

  /**
   * Logs out the user from the application.
   *
   * For users with login method FARO we also need to invalidate the Auth0 session.
   * There is a non-visible iframe where the Auth0 logout will be silently carried out.
   *
   * After a successful logout:
   * - The login status is set as "loggedOut".
   * - The app store state is cleared.
   * - It broadcasts a logout message to any other SDB tab open.
   */
  const logout = useCallback(async () => {
    setIsLoggingOut(true);
    setLoadingSpinner(true);

    try {
      await coreApiClient.V3.SDB.logoutUser();

      if (isLoginProviderFaro(loggedInUser)) {
        const domain = runtimeConfig.features.auth0.domain;
        const clientId = runtimeConfig.features.auth0.clientId;
        const returnTo = encodeURIComponent(
          runtimeConfig.urls.sphereEntryPageUrl
        );
        const logoutUrl = `https://${domain}/v2/logout?client_id=${clientId}&returnTo=${returnTo}`;
        setAuthLogout0Url(logoutUrl);
      }

      setLoginStatus("loggedOut");
      resetStore(dispatch);
      if (broadcastChannel) {
        broadcastChannel.postMessage(
          AuthBroadcastChannel.messages.logoutMessage
        );
      }
    } catch (error) {
      handleErrorWithToast({
        id: `logoutUser-${Date.now().toString()}`,
        title: "Failed to logout. Please try again or reload the page",
        error,
      });
    }

    setIsLoggingOut(false);
    setLoadingSpinner(false);
  }, [
    broadcastChannel,
    coreApiClient,
    dispatch,
    handleErrorWithToast,
    loggedInUser,
    setLoadingSpinner,
  ]);

  /** Checks the login status of the user if the status is unknown */
  useEffect(() => {
    if (loginStatus === "unknown") {
      checkLogin();
    }
  }, [checkLogin, loginStatus]);

  /**
   * Add event listener for window messages coming from the ULM.
   * Redirect to the received URL if the message is of type UlmRedirectMessage.
   */
  useEffect(() => {
    function onWindowMessage(message: MessageEvent<UlmRedirectMessage>): void {
      if (isUlmRedirectMessage(message.data)) {
        window.location.href = message.data.redirectUrl;
      }
    }

    window.addEventListener("message", onWindowMessage);

    return () => window.removeEventListener("message", onWindowMessage);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        loginStatus,
        showSessionExpiredDialog: () => setShouldShowSessionExpiredDialog(true),
        logout,
      }}
    >
      {/* iframe where the Auth0 session invalidation will be silently carried out */}
      <iframe
        title="auth0-logout-iframe"
        style={{ display: "none" }}
        src={auth0LogoutUrl}
      />

      {loginStatus === "loggedOut" && (
        <iframe
          title="unified-login-mask"
          id="data-testid-ULM-IFRAME"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            border: "none",
          }}
          src={getUlmUrl()}
        />
      )}

      {loginStatus === "loggedIn" && !isLoggingOut && (
        <div data-testid="auth-provider">
          {children}
          <FaroDialog
            open={shouldShowSessionExpiredDialog}
            title="Session expired"
            isSuccessMessage={true}
            onConfirm={() => window.location.reload()}
            confirmText="Login"
          >
            Your session has expired. Please login again to continue.
          </FaroDialog>
        </div>
      )}
    </AuthContext.Provider>
  );
}
