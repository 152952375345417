import { BaseBackgroundTaskProps } from "@custom-types/sdb-background-tasks-types";
import { Box } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { withEllipsis } from "@styles/common-styles";
import { DASH } from "@utils/ui-utils";
import { useMemo } from "react";

/**
 * Component that renders the name of the file associated to the background task.
 * If there is no file name available then it shows the task type.
 */
export function BackgroundTaskFileName({
  task,
}: BaseBackgroundTaskProps): JSX.Element {
  const isFileNameAvailable = useMemo(() => {
    return task.elementName && task.elementName.length > 0;
  }, [task]);

  const text = useMemo(() => {
    return isFileNameAvailable ? task.elementName : task.taskType ?? DASH;
  }, [isFileNameAvailable, task]);

  return (
    <Box
      component="var"
      sx={{
        fontWeight: isFileNameAvailable ? "bold" : "inherit",
        color: isFileNameAvailable ? "inherit" : sphereColors.gray400,
        ...withEllipsis,
      }}
    >
      {text}
    </Box>
  );
}
