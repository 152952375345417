import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { SdbProject } from "@custom-types/project-types";
import { AdminAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import {
  ProjectTypes,
  SdbSearchedProject,
  UpdateMemberRoleInProject,
  UpdateMemberRoleInProjectProps,
} from "@store/projects/projects-slice-types";

/** Stores how many projects are returned from the backend per request. */
export const API_PROJECTS_PER_REQUEST = 30;

/**
 * Stores how many projects should be shown in the UI initially. This number should be equal or smaller
 * than API_PROJECTS_PER_REQUEST.
 */
export const DEFAULT_NUMBER_OF_DISPLAYED_PROJECTS = 30;

/** Converts ProjectTypes to SdbProject */
export function convertProjectTypesToSdbProject(
  project: ProjectTypes
): SdbProject {
  return {
    accessLevel: project.accessLevel,

    address: project.address,

    archivingState:
      "archivingState" in project ? project.archivingState : project.state,

    area:
      "area" in project
        ? project.area
        : {
            amount: project.sqftUsed,
            unit: AdminAPITypes.EAreaUnit.sqft,
          },

    clientName: getProjectClientName(project),

    createdAt: project.createdAt,

    description: project.description ?? null,

    evaluation: "evaluation" in project ? project.evaluation : null,

    features: "features" in project ? project.features : null,

    featuresAvailable:
      "featuresAvailable" in project ? project.featuresAvailable : null,

    fileUrl: "fileUrl" in project ? project.fileUrl : null,

    group: getProjectGroup(project),

    id: project.id,

    lastCapturedAt: project.lastCapturedAt ?? null,

    lastDownloadedAt: project.lastDownloadedAt ?? null,

    managers: {
      projectManager: project.managers?.projectManager ?? null,
      companyManager: project.managers?.companyManager ?? null,
    },

    members: "members" in project && project.members ? project.members : [],

    modifiedAt: project.modifiedAt,

    name: project.name,

    permissions: "permissions" in project ? project.permissions : [],

    role: "role" in project && project.role ? project.role : null,

    // eslint-disable-next-line @typescript-eslint/naming-convention -- coming from backend
    standalone: "standalone" in project ? project.standalone : null,

    tags: project.tags,

    thumbnailUrl: project.thumbnailUrl ?? null,
  };
}

/** Returns the project's client name */
function getProjectClientName(project: ProjectTypes): SdbProject["clientName"] {
  if ("clientName" in project && project.clientName) {
    return project.clientName;
  } else if ("client" in project && project.client) {
    return project.client;
  }
  return null;
}

/** Converts the types of featureAvailable to features */
export function convertFeaturesAvailableToFeatures(
  featuresAvailable: SphereDashboardAPITypes.ISearchedProjectDetails["featuresAvailable"]
): SphereDashboardAPITypes.ICompanyProject["features"] {
  return featuresAvailable.reduce((acc, feature) => {
    /* eslint-disable @typescript-eslint/naming-convention -- naming defined by backend */
    return { [feature.identifier]: { enabled: feature.enabled }, ...acc };
  }, {} as SphereDashboardAPITypes.ICompanyProject["features"]);
}

/**
 * Update a member role in the project using the backend.
 */
export async function updateMemberRoleInProjectBase({
  coreApiClient,
  companyId,
  projectId,
  role,
  identity,
}: UpdateMemberRoleInProjectProps): Promise<UpdateMemberRoleInProject> {
  if (!companyId) {
    throw new Error("No companyId was given to update member role in project!");
  }

  const memberData = {
    role: role,
    identity: identity,
  };

  try {
    await coreApiClient.V3.updateMemberDetailsInProject(
      companyId,
      projectId,
      identity,
      memberData
    );

    return {
      projectId,
      role,
      identity,
    };
  } catch (error) {
    throw new Error(getErrorDisplayMarkup(error));
  }
}

/** Whether the project entity is of type ICompanyProject with the group property defined */
export function isICompanyProject(
  project: ProjectTypes
): project is SphereDashboardAPITypes.ICompanyProject {
  return "group" in project && typeof project.group === "object";
}

/** Whether the project entity is of type SdbSearchedProject with the group property defined */
export function isSdbSearchedProject(
  project: ProjectTypes
): project is SdbSearchedProject {
  return (
    "context" in project &&
    "group" in project.context &&
    typeof project.context.group === "object"
  );
}

/** Returns the group property from the passed ProjectTypes entity. If group is not defined it returns null */
export function getProjectGroup(project: ProjectTypes): SdbProject["group"] {
  if (isSdbSearchedProject(project)) {
    return {
      id: project.context.group.id,
      name: project.context.group.name,
    };
  }

  if (isICompanyProject(project)) {
    return {
      id: project.group.id,
      name: project.group.name,
    };
  }

  return null;
}
