import { InputAdornment } from "@mui/material";
import { DateField } from "@mui/x-date-pickers-pro";
import { DateTime } from "luxon";
import CalendarIcon from "@assets/icons/calendar.svg?react";
import { sphereColors } from "@styles/common-colors";

const HELPER_TEXT_CLASS_NAME = "helper-class";

interface Props {
  /** Callback when the date field is confirmed by pressing the enter button */
  onConfirmed: (date: DateTime | null) => void;

  /** Callback when the date is changed by typing it (not confirming it yet) */
  onChange: (date: DateTime | null) => void;

  /** The date to be shown as the current date */
  date: DateTime | null;
}

/** The date text field allows user to type in a date */
export function FaroDateTextField({
  onChange,
  onConfirmed,
  date,
}: Props): JSX.Element {
  return (
    <DateField
      sx={{
        width: "320px",
        height: "34px",
        borderBottom: `2px solid ${sphereColors.gray100}`,
        [`& .${HELPER_TEXT_CLASS_NAME}`]: {
          visibility: "hidden",
        },
        "&:hover": {
          borderBottom: `2px solid ${sphereColors.black}`,
          [`& .${HELPER_TEXT_CLASS_NAME}`]: {
            visibility: "visible",
          },
        },
        "&:focus-within": {
          borderBottom: `2px solid ${sphereColors.blue500}`,
          [`& .${HELPER_TEXT_CLASS_NAME}`]: {
            visibility: "visible",
          },
        },
        justifyContent: "center",
        m: "12px",
      }}
      variant="standard"
      value={date}
      fullWidth
      autoFocus
      InputProps={{
        // eslint-disable-next-line @typescript-eslint/naming-convention
        disableUnderline: true,

        startAdornment: (
          <InputAdornment position="start">
            <CalendarIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end" className={HELPER_TEXT_CLASS_NAME}>
            <p style={{ fontSize: "10px" }}>Type and press enter</p>
          </InputAdornment>
        ),
      }}
      onChange={onChange}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          onConfirmed(date);
        }
      }}
    />
  );
}
