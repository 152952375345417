import { createSelector } from "@reduxjs/toolkit";
import {
  APITypes,
  CoreAPITypes,
  SphereDashboardAPITypes,
} from "@stellar/api-logic";
import { RootState } from "@store/store-helper";
import {
  SnapshotsState,
  snapshotsAdapter,
} from "@store/snapshots/snapshots-slice";

/**
 * Returns the snapshots for the provided project.
 *
 * @param projectId ID of the project to get its snapshots.
 */
export function projectSnapshotsSelector(
  projectId?: APITypes.ProjectId
): (state: RootState) => CoreAPITypes.IProjectSnapshot[] | null {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      if (!projectId) {
        return null;
      }
      return (
        snapshotsAdapter.getSelectors().selectById(state.snapshots, projectId)
          ?.snapshots ?? null
      );
    }
  );
}

/**
 * Returns the fetching properties of the projects slice.
 */
export const fetchingSnapshotsFlagsSelector: (
  state: RootState
) => SnapshotsState["fetching"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.snapshots.fetching;
  }
);

/**
 * Returns all the members of a snapshot or null if there are no member available
 *
 * @param snapshotId The snapshot ID
 */
export function snapshotsMembersSelector(
  snapshotId: CoreAPITypes.IProjectSnapshot["id"]
): (state: RootState) => SphereDashboardAPITypes.IProjectMemberBase[] {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.snapshots.snapshotMembers[snapshotId] ?? [];
    }
  );
}

/** Returns whether a specific snapshot is currently fetching members */
export function isFetchingSnapshotsMembersSelector(
  snapshotId: CoreAPITypes.IProjectSnapshot["id"]
): (state: RootState) => boolean {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return (
        state.snapshots.fetching.isFetchingSnapshotMembers[snapshotId] ?? false
      );
    }
  );
}
