import {
  ICON_SMALL_STYLE,
  SphereAvatar,
} from "@components/header/sphere-avatar";
import { Box } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { withEllipsis } from "@styles/common-styles";
import {
  getPrettyName,
  getUserInitials,
  isMemberActive,
} from "@utils/user-utils";
import { useMemo } from "react";
import EmailIcon from "@assets/icons/new/email_32px.svg?react";
import { BaseMemberProps } from "@custom-types/member-types";
import { useAppSelector } from "@store/store-helper";
import { currentUserSelector } from "@store/user/user-selector";

/**
 * Used to map the details of the current selection to something
 * that this component can show inside the box.
 */
interface MemberDetails {
  /** The first row of the element, normally containing the member's name */
  title: string;

  /** The second row of the element, normally containing the member's email. */
  subtitle?: string;

  /** The initials of the selected member. */
  initials?: string;

  /** The avatar of the selected member, */
  avatarSrc?: string;

  /** Optional icon to be shown, for example an email icon if a new member will be invited. */
  avatarIcon?: JSX.Element;
}

// The width of the wrapper for the avatar in pixels.
const AVATAR_WIDTH = "60px";

/**
 * This component displays a user's avatar, name and email.
 */
export function UserDisplay({ member }: Partial<BaseMemberProps>): JSX.Element {
  const currentUser = useAppSelector(currentUserSelector);

  /**
   * Defines the text and other elements that will be shown in the text.
   * This is useful so that the JSX part of the component is as generic as possible.
   */
  const memberDetails: MemberDetails = useMemo(() => {
    /** If the member is active show all member details. */
    if (member && isMemberActive(member)) {
      return {
        title: getPrettyName(member),
        subtitle: member.email,
        initials: getUserInitials(member),
        avatarSrc: member.thumbnailUrl,
        avatarIcon: undefined,
      };
    }

    /** Otherwise we only show the email and an avatar icon */
    return {
      title: member?.email ?? "",
      subtitle: undefined,
      initials: undefined,
      avatarSrc: undefined,
      avatarIcon: <EmailIcon style={ICON_SMALL_STYLE} />,
    };
  }, [member]);

  return (
    <Box
      component="div"
      sx={{
        height: "60px",
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        component="div"
        sx={{
          width: AVATAR_WIDTH,
        }}
      >
        <SphereAvatar
          src={memberDetails.avatarSrc}
          initials={memberDetails.initials ?? ""}
          icon={memberDetails.avatarIcon}
          size="medium"
          shouldUseSimplifiedAvatar={currentUser?.id !== member?.id}
        />
      </Box>
      <Box
        component="div"
        sx={{
          fontSize: "14px",
          color: sphereColors.gray800,
          width: `calc(100% - ${AVATAR_WIDTH})`,
        }}
      >
        <Box component="div" sx={{ ...withEllipsis }}>
          <var>{memberDetails.title}</var>
        </Box>

        {memberDetails.subtitle && (
          <Box component="div" sx={{ ...withEllipsis }}>
            <var>{memberDetails.subtitle}</var>
          </Box>
        )}
      </Box>
    </Box>
  );
}
