import { List } from "@mui/material";
import { SidebarItemProps } from "@components/sidebar/sidebar-items";
import { useAppSelector } from "@store/store-helper";
import { SidebarEvents } from "@utils/track-event/track-event-list";
import { isBetaTestingEnabledSelector } from "@store/ui/ui-selector";
import { SidebarListItem } from "@components/sidebar/sidebar-list-item";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";

interface Props {
  /** Flag whether the sidebar should be shown full size or minified */
  isSidebarFullSize: boolean;

  /** A list of items to show in the sidebar. */
  sidebarItems: SidebarItemProps[];
}

/**
 * Map over the list of items and return them as a List component
 * Also handles selection and the selected item in the sidebar
 */
export function SidebarList({
  isSidebarFullSize,
  sidebarItems,
}: Props): JSX.Element {
  const { trackEvent } = useTrackEvent();
  const isBetaTestingEnabled = useAppSelector(isBetaTestingEnabledSelector);
  const { hasUserPermissionCompanyLevel } = useHasUserValidRoleCompanyLevel();

  const filteredSidebarItems = sidebarItems.filter((sidebarItem) => {
    // Don't show a sidebar item if the item is in beta testing and beta testing is not enabled
    if (sidebarItem.shouldShowOnlyInBeta && !isBetaTestingEnabled) {
      return false;
    }

    // Only show the items that the user has access to.
    return hasUserPermissionCompanyLevel({
      roleName: sidebarItem.requiredRoleCompanyLevel,
    });
  });

  return (
    <List>
      {filteredSidebarItems.map((sidebarItem) => (
        <SidebarListItem
          key={sidebarItem.identity}
          isSidebarFullSize={isSidebarFullSize}
          sidebarItem={sidebarItem}
          onClick={() =>
            trackEvent({
              name: SidebarEvents.openTab,
              props: { tab: sidebarItem.identity.toLowerCase() },
            })
          }
        />
      ))}
    </List>
  );
}
