import { EmptyPage } from "@components/common/empty-page/empty-page";
import {
  elsLatestPublishedDataSelector,
  fetchingStatusAllRegistrationRevisionsSelector,
  hasFetchedAllRegistrationRevisionsSelector,
} from "@store/capture-tree/capture-tree-selectors";
import { useAppSelector } from "@store/store-helper";
import { FetchingStatus } from "@store/store-types";
import { useMemo } from "react";
import NoDataIcon from "@assets/icons/new/no_data.svg?react";
import FailedIcon from "@assets/icons/failed_32px.svg?react";
import { sphereColors } from "@styles/common-colors";
import { Box } from "@mui/material";
import { PROJECT_DATA_MANAGEMENT_LABELS } from "@src/constants/project-data-management-constants";
import { PublishedDataTable } from "@pages/project-details/project-data-management/published-data/published-data-table";

/** Renders the published data section of the data management page */
export function PublishedData(): JSX.Element {
  const publishedData = useAppSelector(elsLatestPublishedDataSelector);

  const fetchingStatus = useAppSelector(
    fetchingStatusAllRegistrationRevisionsSelector
  );
  const hasFetchedAllRegistrationRevisions = useAppSelector(
    hasFetchedAllRegistrationRevisionsSelector
  );

  const isFetchingForTheFirstTime = useMemo(() => {
    return (
      !hasFetchedAllRegistrationRevisions &&
      fetchingStatus === FetchingStatus.pending
    );
  }, [fetchingStatus, hasFetchedAllRegistrationRevisions]);

  const shouldShowEmptyPage = useMemo(() => {
    return publishedData.length === 0 && !isFetchingForTheFirstTime;
  }, [isFetchingForTheFirstTime, publishedData.length]);

  const hasFailedToFetchCaptureTree = useMemo(() => {
    return fetchingStatus === FetchingStatus.rejected;
  }, [fetchingStatus]);

  return (
    <Box
      data-testid="published-data-content"
      sx={{
        marginTop: "14px",
        border: `1px solid ${sphereColors.gray200}`,
        borderRadius: 0,
      }}
    >
      {shouldShowEmptyPage ? (
        <EmptyPage
          title={hasFailedToFetchCaptureTree ? "Error" : "No published data"}
          subtitle={
            hasFailedToFetchCaptureTree
              ? "Failed to fetch the published data. Please reload the page and try again."
              : "No published data to show for this project."
          }
          icon={hasFailedToFetchCaptureTree ? FailedIcon : NoDataIcon}
        />
      ) : (
        <Box
          sx={{
            background: "white",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderBottom: `1px solid ${sphereColors.gray200}`,
              paddingX: "30px",
              fontWeight: "bold",
              height: "64px",
            }}
          >
            {PROJECT_DATA_MANAGEMENT_LABELS.publishedData}
          </Box>
          <Box
            sx={{
              paddingX: "30px",
              paddingBottom: "30px",
            }}
          >
            <PublishedDataTable
              publishedData={publishedData}
              isFetchingForTheFirstTime={isFetchingForTheFirstTime}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
