import { FileUploadTask } from "@custom-types/file-upload-types";
import { CloseIcon } from "@faro-lotv/flat-ui";
import { Box, Divider, Stack } from "@mui/material";
import { ProgressCard } from "@components/progress-overview/progress-card";

interface Props {
  /** Title of the section eg. Failed, In Progress */
  label: "Failed" | "In progress";

  /** List of task */
  tasks: FileUploadTask[];
}

/** Renders a failed or in-progress section in project overview */
export function ProgressOverviewSectionItem({
  label,
  tasks,
}: Props): JSX.Element {
  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          textTransform: "uppercase",
          color: "gray850",
          fontWeight: "bold",
          fontSize: "0.8em",
          mx: 4,
          mt: 2,
        }}
      >
        <CloseIcon sx={{ color: "red500" }} /> {label} &nbsp;
        <var style={{ opacity: "0.6" }}>({tasks.length})</var>
      </Stack>

      {tasks.map((task, i) => (
        <ProgressCard task={task} key={i} />
      ))}

      <Divider />
    </Box>
  );
}
