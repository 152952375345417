import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { fetchCompanyMemberGroups } from "@store/company-member-groups/company-member-groups-thunk";
import { FetchingStatus } from "@store/store-types";

/**
 * State company member groups.
 */
export interface CompanyMemberGroupsState {
  /**
   * List of the groups that the user have access
   */
  groups: SphereDashboardAPITypes.CompanyMemberGroup[] | null;

  /** State of the endpoint */
  status: FetchingStatus;
}

const initialState: CompanyMemberGroupsState = {
  groups: null,
  status: FetchingStatus.uninitialized,
};

/**
 * Slice to access state of loaded company member groups
 */
const companyMemberGroupsSlice = createSlice({
  name: "companyMemberGroups",
  initialState,
  reducers: {
    /**
     * Resets the state of the company member groups slice.
     */
    resetCompanyMemberGroupsState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(
        fetchCompanyMemberGroups.pending,
        (state: CompanyMemberGroupsState) => {
          state.status = FetchingStatus.pending;
        }
      )
      .addCase(
        fetchCompanyMemberGroups.fulfilled,
        (
          state: CompanyMemberGroupsState,
          {
            payload,
          }: PayloadAction<SphereDashboardAPITypes.CompanyMemberGroup[]>
        ) => {
          state.groups = payload;
          state.status = FetchingStatus.succeeded;
        }
      )
      .addCase(
        fetchCompanyMemberGroups.rejected,
        (state: CompanyMemberGroupsState) => {
          state.status = FetchingStatus.rejected;
          state.groups = [];
        }
      );
  },
});

export const { resetCompanyMemberGroupsState } =
  companyMemberGroupsSlice.actions;

export const companyMemberGroupsReducer = companyMemberGroupsSlice.reducer;
