import { UserInfo, userInfoWidth } from "@components/user-info/user-info";
import { MemberProfileInfoBar } from "@components/member-profile/member-profile-info-bar";
import { Box, Grid } from "@mui/material";
import { MemberProfileSkeleton } from "@components/common/skeleton-loading/member-profile-skeleton";
import { BaseMemberProps } from "@custom-types/member-types";
import { useMemberGroups } from "@hooks/use-member-groups";
import { NotFoundPage } from "@pages/not-found-page";
import { isCompanyMemberDetails } from "@custom-types/type-guards";
import { MemberGroupsTable } from "@components/common/member/member-groups-table";
import { APITypes } from "@stellar/api-logic";
import { MemberProfileEmptyPage } from "@components/common/empty-page/member-profile-empty-page";
import { PartialNull } from "@custom-types/types";
import { FetchingStatus } from "@store/store-types";

interface Props extends PartialNull<BaseMemberProps> {
  /** Flag to indicate if the content of the member is loading */
  isLoading: boolean;
}

/**
 * Page to show the overview of the profile of the selected user.
 */
export function MemberOverviewPage({ member, isLoading }: Props): JSX.Element {
  const { groups, companyMemberGroupsStatus } = useMemberGroups();
  const isFetching =
    isLoading || companyMemberGroupsStatus === FetchingStatus.pending;

  if (isFetching || !groups) {
    return <MemberProfileSkeleton />;
  }

  // Show not found page if fetching member or groups failed
  // or if the member entity is not of type ICompanyMemberDetails
  if (
    !member ||
    !isCompanyMemberDetails(member) ||
    companyMemberGroupsStatus !== FetchingStatus.succeeded
  ) {
    return <NotFoundPage />;
  }

  // Only show active projects in info bar
  const numProjects = member.projectRoles.filter((project) => {
    return project.archivingState === APITypes.ArchivingState.UNARCHIVED;
  }).length;

  return (
    <Grid data-testid="MEMBER_PROFILE_OVERVIEW_CONTAINER">
      <MemberProfileInfoBar
        role={member.role}
        numGroups={groups.length}
        numProjects={numProjects}
      />
      {/* Top info bar */}

      {/* Container for the projects, groups and user info component */}
      <Box
        component="div"
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
          width: "100%",
          marginTop: "45px",
        }}
      >
        {/* Container for the projects and groups */}
        <Box
          component="div"
          sx={{
            width: {
              // Needed to grow the container to the remaining space
              flexGrow: 1,
              // On Md devices the container appears at the left of the page and needs a width to share with user info.
              md: `calc(100% - ${userInfoWidth})`,
            },
            // On XS devices the container appears at the bottom of the page and needs a margin top
            marginTop: {
              xs: "45px",
              md: "0px",
            },
            // On Md devices the container appears at the left of the page and needs a margin right
            marginRight: {
              xs: "0px",
              md: "20px",
            },
          }}
        >
          {groups.length === 0 ? (
            <MemberProfileEmptyPage />
          ) : (
            <MemberGroupsTable groups={groups} />
          )}
        </Box>

        {/* Container for the user info */}
        <Box
          component="div"
          sx={{
            display: "flex",
            width: {
              // On XS devices the container uses the full width of the page but centers the content
              xs: "100%",
              md: userInfoWidth,
            },
            justifyContent: "center",
          }}
        >
          {/* Container for the user info to limit its size and justify it in the middle */}
          <Box
            component="div"
            sx={{
              display: "flex",
              // The width remains the same on all devices
              width: userInfoWidth,
            }}
          >
            <UserInfo member={member} />
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}
