import { Box, Link } from "@mui/material";
import { runtimeConfig } from "@src/runtime-config";
import { sphereColors } from "@styles/common-colors";

const shouldShowPRLink =
  runtimeConfig.pullRequestId &&
  runtimeConfig.pullRequestId !== "0" &&
  runtimeConfig.pullRequestUrl &&
  runtimeConfig.pullRequestUrl !== "0";

const shouldShowJiraLink =
  runtimeConfig.jiraTicketUrl &&
  runtimeConfig.jiraTicketUrl !== "0" &&
  runtimeConfig.jiraTicketId &&
  runtimeConfig.jiraTicketId !== "0";

const maxCommitHashLength = 8;
export const commitSimplifiedHash = runtimeConfig.commitId.slice(
  0,
  maxCommitHashLength
);

/**
 * Shows additional information in the about dialog for local development, like the commit SHA and the pull request ID.
 */
export function AboutLocalInfo(): JSX.Element | null {
  /**
   * !: We don't want to show this information in production because we would leak information about our repositories.
   * For local we also don't show this information because it would show hardcoded values.
   */
  if (
    runtimeConfig.appEnv.toLowerCase().includes("prod") ||
    window.location.hostname === "localhost"
  ) {
    return null;
  }

  return (
    <Box component="div">
      <Box
        component="div"
        sx={{
          fontSize: "14px",
          color: sphereColors.gray800,
          "& .MuiLink-root": {
            color: sphereColors.blue500,
            textDecoration: "none",

            "&:hover": {
              textDecoration: "underline",
            },
          },
        }}
      >
        Commit SHA{" "}
        <Link href={runtimeConfig.commitUrl} target="_blank">
          <var>{commitSimplifiedHash}</var>
        </Link>
        {shouldShowJiraLink && (
          <>
            <br />
            Jira Ticket{" "}
            <Link href={runtimeConfig.jiraTicketUrl} target="_blank">
              <var>{runtimeConfig.jiraTicketId}</var>
            </Link>
          </>
        )}
        {shouldShowPRLink && (
          <>
            <br />
            Pull Request Id{" "}
            <Link href={runtimeConfig.pullRequestUrl} target="_blank">
              <var>{runtimeConfig.pullRequestId}</var>
            </Link>
          </>
        )}
      </Box>
    </Box>
  );
}
