import { useBackgroundTasksTracker } from "@hooks/background-tasks/use-background-tasks-tracker";
import { ProjectBackgroundTasksTable } from "@pages/project-details/project-bg-tasks/project-background-tasks-table";
import { useAppSelector } from "@store/store-helper";
import { sdbBackgroundTasksSelector } from "@store/sdb-background-tasks/sdb-background-tasks-selector";
import { fetchingProjectsFlagsSelector } from "@store/projects/projects-selector";
import { EmptyPage } from "@components/common/empty-page/empty-page";
import NoCloudActivityIcon from "@assets/icons/new/no_cloud_activity_60px.svg?react";
import { Grid } from "@mui/material";
import { TABS_CONTENT_PADDING } from "@utils/ui-utils";
import { ProjectBackgroundTasksSkeleton } from "@pages/project-details/project-bg-tasks/project-background-tasks-skeleton";
import { PROJECT_BACKGROUND_TASKS_REQUIRED_COLUMNS } from "@pages/project-details/project-bg-tasks/project-background-tasks-columns";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";

/**
 * Contains the backgrounds tasks of the project details
 */
export function ProjectBackgroundTasks({
  projectId,
}: BaseProjectIdProps): JSX.Element {
  const { isFetchingProjects } = useAppSelector(fetchingProjectsFlagsSelector);
  const { isLoading } = useBackgroundTasksTracker({
    projectId: projectId.toString(),
  });
  const sdbBackgroundsTasks = useAppSelector(sdbBackgroundTasksSelector);

  /** Content to be shown in the page, either empty page or cloud activity table */
  let content: JSX.Element;

  if ((isLoading || isFetchingProjects) && sdbBackgroundsTasks.length === 0) {
    content = <ProjectBackgroundTasksSkeleton />;
  } else if (sdbBackgroundsTasks.length === 0) {
    // If the background activity is loaded and has no background tasks, show an empty page.
    content = (
      <EmptyPage
        title="No recorded activity"
        subtitle="There is no recorded activity for this project."
        icon={NoCloudActivityIcon}
      />
    );
  } else {
    content = (
      // If the background activity is loading or has background tasks, show the table.
      // The component takes care of showing skeletons if the data is loading.
      <ProjectBackgroundTasksTable
        sdbBackgroundsTasks={sdbBackgroundsTasks}
        isLoading={
          (isLoading || isFetchingProjects) && sdbBackgroundsTasks.length === 0
        }
        requiredColumns={PROJECT_BACKGROUND_TASKS_REQUIRED_COLUMNS}
        projectId={projectId}
      />
    );
  }

  return (
    <Grid container sx={{ mt: TABS_CONTENT_PADDING, width: "100%" }}>
      <Grid item width="100%">
        {content}
      </Grid>
    </Grid>
  );
}
