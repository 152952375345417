import { useMediaQueryList } from "@hooks/use-media-query";
import { Box, Skeleton } from "@mui/material";

const NUMBER_OF_ROWS = 3;

/** Renders a loading skeleton for the project of a group */
export function MemberGroupProjectsSkeleton(): JSX.Element {
  const { isScreenMdAndLarger } = useMediaQueryList();

  return (
    <Box component="div" marginY="10px">
      {[...Array(NUMBER_OF_ROWS)].map((e, index) => (
        <Skeleton
          variant="rectangular"
          sx={{
            width: "100%",
            height: isScreenMdAndLarger ? "65px" : "145px",
            mb: "5px",
          }}
          key={index}
        />
      ))}
    </Box>
  );
}
