import { FaroTextButton } from "@components/common/faro-text-button";
import { useFilterQueryParams } from "@hooks/table-filters/use-filter-query-params";
import { Divider } from "@mui/material";
import { useAppSelector } from "@store/store-helper";
import { tableTypeSelector } from "@store/table/table-selector";

/** The clear all filter button and its functionality */
export function FilterToolbarClearAll(): JSX.Element | null {
  const { isAnyFilterApplied, clearAllFilters } = useFilterQueryParams();
  const tableType = useAppSelector(tableTypeSelector);

  if (!isAnyFilterApplied) {
    return null;
  }

  function onClearClicked(): void {
    if (tableType !== null) {
      clearAllFilters(tableType);
    }
  }

  return (
    <>
      {isAnyFilterApplied && <Divider orientation="vertical" />}
      <FaroTextButton
        onClick={onClearClicked}
        tooltipText="Clear all filters"
        sx={{ fontWeight: 600 }}
      >
        Clear filters
      </FaroTextButton>
    </>
  );
}
