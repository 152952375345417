import { isGroupDetails, isGroupRole } from "@custom-types/type-guards";
import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import {
  HasUserValidRoleGroupLevelProps,
  RequiredRoleGroupLevelName,
  RequiredRolesGroupLevel,
} from "@utils/access-control/group/group-access-control-types";
import { hasUserValidCompanyRole } from "@utils/access-control/company/company-access-control";

const companyRole = CoreAPITypes.EUserCompanyRole;

/**
 * Object that determines all different permission rules for users to get
 * access on the group level.
 */
const requiredRolesGroupLevel: RequiredRolesGroupLevel<RequiredRoleGroupLevelName> =
  {
    [RequiredRoleGroupLevelName.canEditGroupDetails]: {
      companyRoles: [companyRole.companyExecutive],
      groupRoles: [CoreAPITypes.EUserCompanyRole.companyManager],
    },

    [RequiredRoleGroupLevelName.canInviteUsersToGroup]: {
      companyRoles: [companyRole.companyExecutive],
      groupRoles: [CoreAPITypes.EUserCompanyRole.companyManager],
    },

    [RequiredRoleGroupLevelName.canEditMember]: {
      companyRoles: [companyRole.companyExecutive],
      groupRoles: [CoreAPITypes.EUserCompanyRole.companyManager],
    },

    [RequiredRoleGroupLevelName.canDeleteMemberFromGroup]: {
      companyRoles: [companyRole.companyExecutive],
      groupRoles: [CoreAPITypes.EUserCompanyRole.companyManager],
    },
  };

/**
 * Checks whether a user has at least one of the required group roles.
 * !: Only use this method internally
 * To check for user access control better use hasUserValidRoleGroupLevel.
 * In some cases the user might not have the required group role but could still
 * have access because of an specific company role.
 *
 * @param currentUser The user to get its group role from.
 * @param selectedGroup The selected group in which the user is expected
 * to have one of the required roles
 * @param requiredGroupRoles Array filled with group roles
 * that the user should have one of them
 * @returns True if the user has one of the required group roles.
 */
function hasUserValidGroupRole({
  currentUser,
  selectedGroup,
  requiredGroupRoles,
}: {
  currentUser: SphereDashboardAPITypes.ICompanyMemberBase | null;
  selectedGroup: SphereDashboardAPITypes.IGroupDetails | null;
  requiredGroupRoles: SphereDashboardAPITypes.IAssignmentGroupRole[];
}): boolean {
  // If we don't have a selected group then we assume the user does not have access.
  if (!selectedGroup) {
    return false;
  }

  // If the user has access to the group we know that at least he is a project manager
  if (
    !requiredGroupRoles ||
    requiredGroupRoles.length === 0 ||
    requiredGroupRoles?.includes(CoreAPITypes.EUserCompanyRole.projectManager)
  ) {
    return true;
  }

  // If the selected group includes the role property, use it to determine the user's access level.
  if (
    "role" in selectedGroup &&
    selectedGroup.role &&
    isGroupRole(selectedGroup.role)
  ) {
    return requiredGroupRoles.includes(selectedGroup.role);
  }

  // If we didn't have the "role" property in the group, we can use the
  // members property to look for the member's role.
  // If this property is also not available we just can't determine the member's role.
  if (!isGroupDetails(selectedGroup) || !currentUser) {
    return false;
  }

  // First find the selected member in the group members array.
  const memberInGroup = selectedGroup.members.find(
    (member) => member.identity === currentUser.identity
  );

  if (!memberInGroup) {
    // If the user is not a member in the project
    return false;
  }

  return (
    isGroupRole(memberInGroup.role) &&
    requiredGroupRoles.includes(memberInGroup.role)
  );
}

/**
 * Checks whether a user has permissions within the group level.
 * This check is more complex than just the group role, because in some cases the user
 * might not have the required group role but could still have access because of an specific company role.
 *
 * @returns True if the user has a valid group level role or permission.
 */
export function hasUserValidRoleGroupLevel<
  RoleNameT extends string = RequiredRoleGroupLevelName
>({
  roleName,
  currentUser,
  selectedGroup,
  defaultRequiredRolesGroupLevel = requiredRolesGroupLevel as RequiredRolesGroupLevel<RoleNameT>,
}: HasUserValidRoleGroupLevelProps<RoleNameT>): boolean {
  if (!selectedGroup || !currentUser) {
    return false;
  }

  const requiredRules = defaultRequiredRolesGroupLevel[roleName];
  const requiredCompanyRoles = requiredRules.companyRoles;
  if (
    requiredCompanyRoles.length > 0 &&
    hasUserValidCompanyRole({
      currentUser,
      requiredCompanyRoles,
    })
  ) {
    return true;
  }

  const requiredGroupRoles = requiredRules.groupRoles;
  if (
    requiredGroupRoles.length > 0 &&
    hasUserValidGroupRole({
      currentUser,
      selectedGroup,
      requiredGroupRoles,
    })
  ) {
    return true;
  }

  // If the user didn't match any of the rules above it means it
  // does not have permission.
  return false;
}
