import { useMemo } from "react";
import { CoreAPITypes } from "@stellar/api-logic";
import { useAppSelector } from "@store/store-helper";
import {
  companyMembersByRoleSelector,
  companyMembersSelector,
} from "@store/members/members-selector";
import { PageInfoBar } from "@components/common/page-info-bar";
import { FaroTextField } from "@components/common/faro-text-field/faro-text-field";
import { InviteMemberToCompany } from "@pages/members/invite-member-to-company";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";

/**
 * Shows the main details for members
 */
export function MainMemberDetails(): JSX.Element {
  const adminMembers = useAppSelector(
    companyMembersByRoleSelector(CoreAPITypes.EUserCompanyRole.companyExecutive)
  );
  const groupManagerMembers = useAppSelector(
    companyMembersByRoleSelector(CoreAPITypes.EUserCompanyRole.companyManager)
  );
  const projectManagerMembers = useAppSelector(
    companyMembersByRoleSelector(CoreAPITypes.EUserCompanyRole.projectManager)
  );
  const viewerMembers = useAppSelector(
    companyMembersByRoleSelector(CoreAPITypes.EUserCompanyRole.companyViewer)
  );

  const companyMembers = useAppSelector(companyMembersSelector);
  const { canInviteUsersToCompany } = useHasUserValidRoleCompanyLevel();

  /**
   * Returns the total number of users in the workspace, including all roles,
   * plus members that only have project access.
   * This number is independent of any applied filters.
   */
  const totalUsersInWorkspace = useMemo(() => {
    return companyMembers.length;
  }, [companyMembers]);

  return (
    <PageInfoBar
      items={[
        {
          content: (
            <FaroTextField
              label="Total"
              initialValue={totalUsersInWorkspace}
              faroVariant="main"
              fontSize="18px"
            />
          ),
        },
        {
          content: (
            <FaroTextField
              label="Admins"
              initialValue={adminMembers.length}
              faroVariant="main"
              fontSize="18px"
            />
          ),
        },
        {
          content: (
            <FaroTextField
              label="Group Managers"
              initialValue={groupManagerMembers.length}
              faroVariant="main"
              fontSize="18px"
            />
          ),
        },
        {
          content: (
            <FaroTextField
              label="Project Managers"
              initialValue={projectManagerMembers.length}
              faroVariant="main"
              fontSize="18px"
            />
          ),
        },
        {
          content: (
            <FaroTextField
              label="Viewers"
              initialValue={viewerMembers.length}
              faroVariant="main"
              fontSize="18px"
            />
          ),
        },
      ]}
      action={canInviteUsersToCompany && <InviteMemberToCompany />}
    />
  );
}
