import { Drawer, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { SidebarMixins } from "@components/sidebar/sidebar-helper";
import { sidebarSelector } from "@store/ui/ui-selector";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { updateSidebar } from "@store/ui/ui-slice";
import { SidebarContent } from "@components/sidebar/sidebar-content";

/**
 * Handles the sidebar for mobile and desktop views
 */
export function Sidebar(): JSX.Element {
  const theme = useTheme();
  const isSmallAndLarger = useMediaQuery(theme.breakpoints.up("sm"));

  const dispatch = useAppDispatch();

  const { isFullSize, isOpenOnMobile } = useAppSelector(sidebarSelector);

  // In small and larger screens (not in extra small) the sidebar is shown as permanent
  // which means it will always be visible but it can be use in mini or full size version.
  if (isSmallAndLarger) {
    return (
      <Drawer
        variant="permanent"
        data-testid="SIDEBAR_CONTAINER"
        open={isFullSize}
        sx={{
          flexShrink: 0,
          whiteSpace: "nowrap",
          boxSizing: "border-box",
          ...(isFullSize && {
            ...SidebarMixins.openedMixin(theme),
            "& .MuiDrawer-paper": SidebarMixins.openedMixin(theme),
          }),
          ...(!isFullSize && {
            ...SidebarMixins.closedMixin(theme),
            "& .MuiDrawer-paper": SidebarMixins.closedMixin(theme),
          }),
        }}
      >
        <SidebarContent
          isSidebarFullSize={isFullSize}
          isSidebarMobileVersion={false}
        />
      </Drawer>
    );
  }

  // In extra small screens (mostly on mobiles) the sidebar is shown as temporary
  // which means it can be shown by a button in the top bar
  // and when shown it will use its full size version.
  return (
    <Drawer
      variant="temporary"
      anchor="left"
      open={isOpenOnMobile}
      onClose={() => dispatch(updateSidebar({ isOpenOnMobile: false }))}
      ModalProps={{
        // better performance on mobile https://mui.com/base/react-modal/#performance
        // eslint-disable-next-line @typescript-eslint/naming-convention -- external package
        keepMounted: true,
      }}
      sx={{
        ...{
          ...SidebarMixins.openedMixin(theme),
          "& .MuiDrawer-paper": SidebarMixins.openedMixin(theme),
        },
      }}
    >
      <SidebarContent isSidebarFullSize={true} isSidebarMobileVersion={true} />
    </Drawer>
  );
}
