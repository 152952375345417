import { useMemo } from "react";
import { useAppSelector } from "@store/store-helper";
import { OpenProjectTarget } from "@custom-types/sdb-company-types";
import { BaseMarkupProps } from "@custom-types/project-markups-types";
import { defaultOpenProjectTargetSelector } from "@store/sdb-company/sdb-company-selector";

interface MarkupInteractionActionsInfo {
  /**
   * The target viewer/editor for opening the markup.
   * It will be either `OpenProjectTarget.sphereViewer` or `OpenProjectTarget.webEditor`.
   */
  openTarget: OpenProjectTarget;

  /**
   * The URL of the markup in the specified viewer/editor.
   * If `openTarget` is `OpenProjectTarget.sphereViewer`, this will be `markup.sphereViewerUrl`.
   * If `openTarget` is `OpenProjectTarget.webEditor`, this will be `markup.webEditorUrl`.
   */
  markupHref?: string;
}

/**
 * Custom hook to determine interaction actions (target viewer/editor and URL) for a given markup object.
 *
 * @param {Markup} markup - The markup object for which shared actions are being determined.
 */
export function useSharedMarkupActions({
  markup,
}: BaseMarkupProps): MarkupInteractionActionsInfo {
  const defaultOpenProjectTarget = useAppSelector(
    defaultOpenProjectTargetSelector
  );

  /**
   * It will validate if the markup has information for the webEditor if not it will return sphereViewer
   */
  const openTarget = useMemo(() => {
    // Only set the open target as WebEditor if the WebEditor URL is available
    if (
      defaultOpenProjectTarget === OpenProjectTarget.webEditor &&
      markup?.webEditorUrl
    ) {
      return defaultOpenProjectTarget;
    } else {
      return OpenProjectTarget.sphereViewer;
    }
  }, [defaultOpenProjectTarget, markup]);

  const markupHref = useMemo(() => {
    return openTarget === OpenProjectTarget.sphereViewer
      ? markup.sphereViewerUrl
      : markup.webEditorUrl;
  }, [markup, openTarget]);

  return {
    openTarget,
    markupHref,
  };
}
