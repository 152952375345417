import { sphereColors } from "@styles/common-colors";
import SuccessIcon from "@assets/icons/success_32px.svg?react";
import InfoIcon from "@assets/icons/info_32px.svg?react";
import WarningIcon from "@assets/icons/warning_32px.svg?react";
import FailedIcon from "@assets/icons/failed_32px.svg?react";
import { FaroToastProps } from "@context-providers/toast/faro-toast-types";
import { FaroSvgIcon } from "@custom-types/types";

/** Duration in milliseconds in which the toast will disappear */
const TOAST_AUTO_HIDE_DURATION = 5000;

/** Get the properties for the toast based on the type */
export function getToastProperties(type: FaroToastProps["type"]): {
  /** The icon of the toast */
  ToastIcon: FaroSvgIcon;

  /** The background color of the toast */
  backgroundColor: string;
} {
  switch (type) {
    case "success":
      return {
        ToastIcon: SuccessIcon,
        backgroundColor: sphereColors.green50,
      };

    case "info":
      return {
        ToastIcon: InfoIcon,
        backgroundColor: sphereColors.blue50,
      };

    case "warning":
      return {
        ToastIcon: WarningIcon,
        backgroundColor: sphereColors.yellow100,
      };

    case "error":
      return {
        ToastIcon: FailedIcon,
        backgroundColor: sphereColors.red50,
      };
  }
}

/**
 * @returns the auto hide duration based on the toast type
 *
 * @param type Toast type
 * @param shouldAutoHide If set to true it will auto hide the toast and ignore the toast type.
 */
export function getAutoHideDuration(
  type: FaroToastProps["type"],
  shouldAutoHide?: boolean
): number | null {
  if (shouldAutoHide) {
    return TOAST_AUTO_HIDE_DURATION;
  }

  switch (type) {
    case "success":
    case "info":
      return TOAST_AUTO_HIDE_DURATION;
    case "warning":
    case "error":
      return null;
  }
}
