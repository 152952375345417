import { ErrorPageBase } from "@pages/error-page-base";
import NotFoundIcon from "@assets/icons/new/page-not-found_566px.svg";
import { useEffect } from "react";
import { ErrorEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";

/** Stores whether the event has been tracked */
let isEventTracked = false;

/**
 * Page to show whenever the user access a page that we cannot match
 * to a valid page in the router.
 */
export function NotFoundPage(): JSX.Element {
  const { trackAsyncEvent } = useTrackEvent();

  // Track event every time user's is redirected to a not found page.
  useEffect(() => {
    async function track(): Promise<void> {
      await trackAsyncEvent({
        name: ErrorEvents.openNotFoundPage,
        props: {
          path: window.location.pathname,
        },
        // Since this event is tracked immediately after the page is opened,
        // Tracking event class (currently Amplitude) might not be ready yet so we should wait for it to be initialized.
        shouldWaitForInitialization: true,
      });
    }
    if (!isEventTracked) {
      // Only track once. There is something in the useEffect that it is causing one repaint
      // which ends in tracking the same event twice.
      track();
    }

    return () => {
      isEventTracked = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want to track once
  }, []);

  return (
    <ErrorPageBase title="Page not found" iconImg={NotFoundIcon}>
      We couldn’t find the page you are looking for...
    </ErrorPageBase>
  );
}
