import { createSelector } from "@reduxjs/toolkit";
import { APITypes, CoreAPITypes } from "@stellar/api-logic";
import { RootState } from "@store/store-helper";
import { slideNodesAdapter } from "@store/slide-nodes/slide-nodes-slice";

/**
 * Get the ID of the project that the current slide node belong to
 */
export const projectIdSelector: (
  state: RootState
) => APITypes.ProjectId | undefined = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.slideNodes.projectId;
  }
);

/**
 * Get the store slide nodes
 */
export const slideNodesSelector: (
  state: RootState
) => CoreAPITypes.IArSlideJson[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return slideNodesAdapter.getSelectors().selectAll(state.slideNodes);
  }
);
