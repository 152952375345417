import { Box } from "@mui/material";
import { ReactNode } from "react";
import ReactDOM from "react-dom";

interface Props {
  /** The content to render within the overlay. */
  children: ReactNode;
}

/**
 * Component that creates an overlay to cover the entire screen with a transparent layer.
 * Useful for modalities and modals that require user focus on a single interaction.
 *
 * @param children The content to render within the overlay.
 * @returns A React portal rendering the provided content over the "root" element in the DOM.
 */
export function Overlay({ children }: Props): JSX.Element {
  return ReactDOM.createPortal(
    <Box
      role="overlay"
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "transparent",
        zIndex: 9999,
      }}
    >
      {children}
    </Box>,
    document.body
  );
}
