import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { Markup } from "@custom-types/project-markups-types";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { GUID, generateGUID } from "@faro-lotv/foundation";
import {
  IElementDateTimeMarkupField,
  IElementMarkup,
  IElementType,
  IElementTypeHint,
} from "@faro-lotv/ielement-types";
import {
  MutationAddMarkupField,
  MutationDateTimeMarkupField,
  MutationDeleteElement,
  createMutationAddMarkupField,
  createMutationDateTimeMarkupField,
  createMutationDeleteElement,
} from "@faro-lotv/service-wires";
import { AnnotationEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";

interface Props {
  /** Markup element to edit */
  markupElement: IElementMarkup;

  /** New due date to apply */
  newDueDate?: Date;

  /** Previous value ID if it was already present */
  previousValueId?: GUID;

  /** Due date template ID to reference in the new UserDirectoryMarkupField node */
  dueDateTemplateId: GUID;
}

// TODO: This function is copied from lotv-monorepo as it is not available in the public API.
// We have to remove it and import it from there later: https://faro01.atlassian.net/browse/ST-1784
/** Creates mutation for iElement markup due date */
function createEditDueDateMutation({
  markupElement,
  newDueDate,
  previousValueId,
  dueDateTemplateId,
}: Props):
  | MutationDateTimeMarkupField
  | MutationAddMarkupField<IElementDateTimeMarkupField>
  | MutationDeleteElement
  | undefined {
  if (newDueDate) {
    // Edit previous value if it exists
    if (previousValueId) {
      return createMutationDateTimeMarkupField(previousValueId, newDueDate);
    }

    // Create a new node if it did not exists
    return createMutationAddMarkupField<IElementDateTimeMarkupField>(
      markupElement.id,
      {
        id: generateGUID(),
        childrenIds: null,
        name: "Due Date",
        parentId: markupElement.id,
        rootId: markupElement.rootId,
        templateId: dueDateTemplateId,
        type: IElementType.dateTimeMarkupField,
        typeHint: IElementTypeHint.markupIssueDueDate,
        value: newDueDate.toISOString(),
      }
    );
  } else if (previousValueId) {
    // Remove the value node if not needed anymore
    return createMutationDeleteElement(previousValueId);
  }
}

interface UseUpdateDueDateReturn {
  /** Function to be called when markup due date is removed */
  removeDueDate: (markup: Markup) => Promise<void>;

  /** Function to be called when markup due date is changed */
  onDueDateChange: (
    newDate: Date,
    markup: Markup,
    dueDateTemplateId: string
  ) => Promise<
    | MutationDateTimeMarkupField
    | MutationAddMarkupField<IElementDateTimeMarkupField>
    | MutationDeleteElement
    | undefined
  >;
}

/** A hook that gathers all the logic for updating the due date */
export function useUpdateDueDate({
  projectId,
}: BaseProjectIdProps): UseUpdateDueDateReturn {
  const { handleErrorWithToast } = useErrorContext();
  const projectApiClient = useProjectApiClient({
    projectId,
  });
  const { trackEvent } = useTrackEvent();

  async function removeDueDate(markup: Markup): Promise<void> {
    // Check if the due date ID exists before attempting deletion
    if (markup.dueDate?.id) {
      try {
        trackEvent({
          name: AnnotationEvents.editAnnotation,
          props: { property: "due-date", isValueEmpty: true },
        });

        const mutation = createMutationDeleteElement(markup.dueDate.id);

        // Delete the dueDate element using projectApiClient
        await projectApiClient.applyMutations([mutation]);
      } catch (error) {
        handleErrorWithToast({
          id: `deleteAnnotationDueDate-${Date.now().toString()}`,
          title: "Could not delete the annotation due date. Please try again",
          error: getErrorDisplayMarkup(error),
        });
      }
    }
  }

  /** Handler for when user changes the due date. Can be undefined if the due date is removed */
  async function onDueDateChange(
    newDate: Date,
    markup: Markup,
    dueDateTemplateId: string
  ): Promise<
    | MutationDateTimeMarkupField
    | MutationAddMarkupField<IElementDateTimeMarkupField>
    | MutationDeleteElement
    | undefined
  > {
    try {
      trackEvent({
        name: AnnotationEvents.editAnnotation,
        props: { property: "due-date", isValueEmpty: false },
      });

      const mutation = createEditDueDateMutation({
        markupElement: markup,
        newDueDate: newDate,
        previousValueId: markup.dueDate?.id,
        dueDateTemplateId: dueDateTemplateId,
      });

      if (!mutation) {
        throw Error("There was an error to update the due date");
      }

      await projectApiClient.applyMutations([mutation]);

      return mutation;
    } catch (error) {
      handleErrorWithToast({
        id: `updateAnnotationDueDate-${Date.now().toString()}`,
        title: "Could not change annotation due date. Please try again",
        error: getErrorDisplayMarkup(error),
      });
    }
  }

  return {
    removeDueDate,
    onDueDateChange,
  };
}
