import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { runtimeConfig } from "@src/runtime-config";
import { CoreAPITypes } from "@stellar/api-logic";
import { Convert } from "@stellar/web-core";
import { isValidUrl } from "@utils/url-utils";
import { Spherical, Vector3 } from "three";

interface GetSceneObjectWebEditorUrlProps extends BaseProjectIdProps {
  /** Slide node */
  slideNode: CoreAPITypes.IArSlideJson;

  /** Scene object contained in the slide node */
  sceneObject: CoreAPITypes.ISceneObjectJson;
}

/**
 * Gets the WebEditor URL of an scene object
 *
 * @param projectId ID of the project
 * @param slideNode Slide Node where the markup is contained
 * @param sceneObject Scene object associated to the markup
 */
export function getSceneObjectWebEditorUrl({
  projectId,
  slideNode,
  sceneObject,
}: GetSceneObjectWebEditorUrlProps): string | undefined {
  // WebEditor URL needs only the first part of the scene object modelGUID property
  const partialModelGUID = sceneObject.modelGUID.split("-")[0];
  const direction = getDirection(sceneObject.position);

  const projectParam = `p=${projectId}`;
  const slideParam = `s=${slideNode.sId}`;
  const sceneParam = `u=${partialModelGUID}`;
  const directionParam = `d=${direction}`;
  const optionsParam = "o=2304";
  const params = `${projectParam}&${slideParam}&${sceneParam}&${directionParam}&${optionsParam}`;
  const baseUrl = runtimeConfig.urls.hbWebEditorUrl;

  const url = `${baseUrl}?${params}`;

  if (!isValidUrl(url)) {
    return;
  }

  return url;
}

/** Gets the compressed camera direction value from a vector position */
function getDirection(position: CoreAPITypes.ISerializedVector3): string {
  // Get 3D vector from position
  const vector = new Vector3(position.x, position.y, position.z);

  // Get the spherical coordinates from 3D vector
  const spherical = new Spherical().setFromVector3(vector);

  // Get compressed camera direction from spherical coordinates
  const direction =
    Convert.degToCompressed36(Convert.radToDeg(spherical.phi)) +
    Convert.degToCompressed36(Convert.radToDeg(spherical.theta));

  return direction;
}
