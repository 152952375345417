import { FaroTextButton } from "@components/common/faro-text-button";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { PointCloud } from "@custom-types/point-cloud-types";
import { useToast } from "@hooks/use-toast";
import { runtimeConfig } from "@src/runtime-config";
import { tableTypeSelector } from "@store/table/table-selector";
import { setBulkActionName } from "@store/table/table-slice";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { BulkActionEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";

interface Props extends BaseProjectIdProps {
  /** List of all selected point clouds */
  selectedEntities: PointCloud[];
}

/** A button to start a point cloud pairwise registration */
export function PairwiseRegistration({
  projectId,
  selectedEntities,
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const tableType = useAppSelector(tableTypeSelector);
  const { showToast } = useToast();
  const { trackEvent } = useTrackEvent();

  let url: URL;

  /**
   * When there are more than two scans the link to merge is different
   */
  if (selectedEntities.length === 2) {
    url = new URL(
      `/manual-alignment/project/${projectId}`,
      runtimeConfig.urls.sphereViewerUrl
    );
    url.searchParams.append("refCloudId", selectedEntities[0].id);
    url.searchParams.append("modelCloudId", selectedEntities[1].id);
  } else {
    url = new URL(
      `/multi-registration-result/project/${projectId}`,
      runtimeConfig.urls.sphereViewerUrl
    );
    url.searchParams.append(
      "dataSessionIds",
      selectedEntities.map((entity) => entity.id).join(",")
    );
  }

  const dashboardRedirect = "data?type=pointClouds&enableBeta=true";
  url.searchParams.append("dashboardRedirect", dashboardRedirect);

  function startPairwiseRegistration(): void {
    dispatch(setBulkActionName("pairwiseRegistration"));

    if (tableType) {
      trackEvent({
        name: BulkActionEvents.bulkPairwiseRegistration,
        props: {
          dataType: tableType,
        },
      });
    }

    showToast({
      message: "Opening registration tool...",
      type: "info",
    });

    window.location.href = url.href;

    dispatch(setBulkActionName(null));
  }

  return (
    <FaroTextButton
      onClick={startPairwiseRegistration}
      tooltipText="Start registration"
      sx={{ fontWeight: 600 }}
    >
      Registration
    </FaroTextButton>
  );
}
