import {
  EPremiumFeatureId,
  EFeatureType,
  IFeatureItem,
} from "@pages/add-ons/extensions";
import FaceBlurringModalImage from "@assets/add-ons/faceBlurring.png";
import VideoModeImage from "@assets/add-ons/videoMode.jpg";
import PointCloudManagementImage from "@assets/add-ons/pointCloudManagement.jpg";
import Model3DImage from "@assets/add-ons/3DModelImport.jpg";
import AnalyticsImage from "@assets/add-ons/analytics.jpg";
import { AdminAPITypes, APITypes } from "@stellar/api-logic";
import FaceBlurring from "@assets/icons/add-ons/face-blurring.svg?react";
import Model3D from "@assets/icons/add-ons/3D-model.svg?react";
import VideoMode from "@assets/icons/add-ons/video-mode.svg?react";
import CloudProcessing from "@assets/icons/add-ons/cloud-processing.svg?react";
import PointCloud from "@assets/icons/add-ons/point-cloud.svg?react";
import Analytics from "@assets/icons/add-ons/analytics.svg?react";
import SSO from "@assets/icons/add-ons/SSO.svg?react";
import SnapShots from "@assets/icons/add-ons/snapshots.svg?react";
import ProjectAPI from "@assets/icons/add-ons/project-API.svg?react";
import GPSSheets from "@assets/icons/add-ons/GPS-sheets.svg?react";

export const featuresData: IFeatureItem[] = [
  {
    featureId: EPremiumFeatureId.threeDModelImport,
    featureType: EFeatureType.premium,
    date: "2024-04-10",
    name: "3D Model Import",
    description:
      "Import, view, and manage your reality capture and 3D design data in one unified platform.",
    longDescription: `Bring all your reality capture and 3D design data into one single platform.* 
    Import, view, and manage your 3D models and BIM data directly within FARO Sphere® XG. Your 
    georeferenced models and point clouds will align without extra effort. 
    Use the overlay view of 3D models and point clouds to compare your site progress vs. the plan, 
    visually inspect for possible future clashes, and catch deviations early on with accuracy.
    *For use in Sphere XG only`,
    helpUrl: {
      text: "Learn more here",
      url: `https://www.holobuilder.com/content-type/article/bring-your-3d-models-into-faro-sphere-xg/?utm_source
      =product&utm_medium=web&utm_campaign=2024-bi-3dmodelimport`,
    },
    icon: Model3D,
    imageUrl: Model3DImage,
  },
  {
    featureBundleId: AdminAPITypes.EFeatureBundleIdentifier.cloudProcessing,
    featureId: APITypes.EUserSubscriptionRole.globalOrbisProcessing,
    featureType: EFeatureType.premium,
    date: "2024-01-03",
    name: "FARO Cloud Processing",
    description:
      "Take your FARO ORBIS 3D-captured data to the cloud and share them with your project stakeholders.",
    longDescription: `With FARO’s new ORBIS mobile mapping solution, this add-on will enable you to import, 
      process, view, share and export captured data in the cloud and to all your project stakeholders.
      Using our FARO Stream field app and Orbis combined, you can capture data in real time and then 
      upload and process them directly in the cloud. Further connectivity from desktop solution FARO Connect 
      will enable you to sync, upload and download projects with the cloud.
  
      *Note: Point Cloud Management included in this bundle`,
    helpUrl: {
      text: "Learn more here",
      url: "https://knowledge.faro.com/Hardware/Orbis/Orbis",
    },
    icon: CloudProcessing,
  },
  {
    featureBundleId: AdminAPITypes.EFeatureBundleIdentifier.pointCloud,
    featureId: APITypes.EUserSubscriptionRole.globalPointCloudWrite,
    featureType: EFeatureType.premium,
    date: "2024-01-02",
    name: "Point Cloud Management",
    description:
      "Unlock the entire spectrum of reality capture by uploading point clouds to your HoloBuilder projects.",
    longDescription: `Upload point clouds from any device with standard formats (.e57, .laz, etc.) directly 
    into your HoloBuilder projects, bringing 3D point clouds and 360° photos for the first time into a unified 
    platform. Measure within the point clouds with high accuracy, visualize detailed as-built conditions, and 
    easily share point clouds with the broader team to reduce data silos and unlock a new level of collaboration.
    *For use in Sphere XG only`,
    imageUrl: PointCloudManagementImage,
    helpUrl: {
      text: "Learn more here",
      url: "https://success.holobuilder.com/point-cloud-management",
    },
    icon: PointCloud,
  },
  {
    featureId: APITypes.EUserSubscriptionRole.globalVideoMode,
    featureType: EFeatureType.premium,
    date: "2024-01-04",
    name: "VideoMode",
    description:
      "Simply tap and go: Walk your site to document everything with minimal effort.",
    longDescription: `Combining the highest levels of speed and convenience, HoloBuilder VideoMode allows your 
      team to capture full site coverage in 360° quickly and painlessly – with minimal training necessary. 
      Built directly into the JobWalk app, VideoMode helps you to create a dense net of site documentation 
      that leaves no gaps. Just tap and go, and let VideoMode do the rest. Tag your location once in the 
      JobWalk app, walk the site, tag your end position and be assured that VideoMode is capturing construction 
      progress in 360° along the way.`,
    helpUrl: {
      text: "Learn more here",
      url: `https://www.holobuilder.com/holobuilder-videomode?utm_source=product&utm_medium
      =web&utm_campaign=2023-hb-evergreen`,
    },
    imageUrl: VideoModeImage,
    icon: VideoMode,
    additionalLinks: [
      {
        text: "Video Tech Sheet",
        url: "https://www.holobuilder.com/wp-content/uploads/2023/11/One-Pager-VideoMode2023.pdf",
      },
      {
        text: "Best Practices Guide",
        url: "https://www.holobuilder.com/wp-content/uploads/2023/11/One-Pager-VideoMode_best-practices-2023.pdf",
      },
    ],
  },
  {
    featureId: EPremiumFeatureId.openAPI,
    featureType: EFeatureType.premium,
    date: "2023-12-28",
    name: "Project API",
    description:
      "Make the most of the data that you capture by leveraging the HoloBuilder API!",
    longDescription: `HoloBuilder is ready to scale with you and your organization! The brand new and 
      flexible HoloBuilder API allows you to take your HoloBuilder usage even further. Remove data silos by connecting 
      HoloBuilder to your internal tools and help your team unleash the full potential of your virtual jobsites. 
      With the API, you can access HoloBuilder projects to import 360° photos, 360 Markups, tags, filters, sheets, 
      waypoints, and more into your existing tools.`,
    icon: ProjectAPI,
  },
  {
    featureId: APITypes.EUserSubscriptionRole.globalSnapshotView,
    featureType: EFeatureType.premium,
    date: "2023-12-29",
    name: "SnapShots",
    description: `Share subsets of your project as individual, static projects where your stakeholders can view 
    data that's important for them.`,
    longDescription: `Be confident about what you share and what you don't share!
       Shareable SnapShots allow you to select and share specific photos of a project with selected stakeholders 
       (Owners, Trade Partners, etc.). They will get access to their own project that only contains the specific 
       photos you decided to share—just like a report that is made only for them!`,
    icon: SnapShots,
    videoUrl: "https://uploads.holobuilder.com/static/media/snapshots.mp4",
  },
  {
    featureBundleId: AdminAPITypes.EFeatureBundleIdentifier.premiumInsights,
    featureId: APITypes.EUserSubscriptionRole.companyInsights,
    featureType: EFeatureType.premium,
    date: "2023-12-31",
    name: "Premium Analytics",
    description: `Track product adoption across your projects, users, and groups. Evaluate which projects are most 
      active in progress documentation.`,
    longDescription: `View in-depth analytics on your company, groups, projects or users to help track engagement, 
      trends and adoption of HoloBuilder across your company. Easily track which users capture the 
      most photos or which regions/divisions leverage HoloBuilder the most to see how your organization 
      is improving their workflows.`,
    icon: Analytics,
    imageUrl: AnalyticsImage,
  },
  {
    featureId: APITypes.EUserSubscriptionRole.webEditorSheetGps,
    featureType: EFeatureType.premium,
    date: "2023-12-27",
    name: "GPS Sheets",
    description: `Georeference any sheet and unlock a faster, easier, and more precise capture experience 
      for your teams in the field.`,
    longDescription: `For outdoor projects, this feature allows you to bring GPS positioning to your processes within 
          HoloBuilder by adding longitude and latitude information to your sheets to unlock:
  
          1) Automatic GPS positioning of georeferenced 360° photos on the sheet.
  
          2) Positioning of yourself on the sheet when using the JobWalk app.
  
          Ideal for linear and infrastructure projects or any project before any structure has been put in place.`,
    helpUrl: {
      text: "Learn more here",
      url: `https://help.holobuilder.com/en/articles/5775768-gps-enabled-floor-plans-how-to-add-gps-coordinates-to-
        a-sheet-in-the-web-editor`,
    },
    icon: GPSSheets,
    videoUrl: "https://uploads.holobuilder.com/static/media/gps_sheets.mp4",
  },
  {
    featureId: EPremiumFeatureId.singleSignOn,
    featureType: EFeatureType.premium,
    name: "SSO - single sign on",
    date: "2023-12-30",
    description:
      "Increase security by providing Single Sign On (SSO) for you entire company.",
    longDescription: `Ensure compliance across your organization and easy access to HoloBuilder 
    for your team with Single Sign On (SSO) of your provider.
      HoloBuilder supports SSO based on the OAuth 2.0 and OpenID Connect standards and allows your administrators to 
      enforce certain security and access requirements through their preferred identity provider, such as Microsoft’s 
      Azure Active Directory, Autodesk SSO, Google's G Suite, and more.`,
    helpUrl: {
      text: "Learn more here",
      url: "https://help.holobuilder.com/en/articles/2430507-holobuilder-sso-single-sign-on-company-option",
    },
    icon: SSO,
    imageUrl: "https://dashboard.holobuilder.com/img/custom%20SSO.643d611.png",
  },
  {
    featureId: APITypes.EUserSubscriptionRole.globalFaceBlurring,
    featureType: EFeatureType.premium,
    name: "Face Blurring",
    date: "2024-01-01",
    longDescription:
      "Automatically blur faces across your project with AI-powered face detection.",
    description: `Easily anonymize faces in 360 images with a blur effect. 
    Automatically blur multiple faces in your project.`,
    helpUrl: {
      text: "Learn more here",
      url: `https://help.holobuilder.com/en/articles/9273197-increasing-privacy-through-ai-
      fully-automated-faceblurring`,
    },
    icon: FaceBlurring,
    imageUrl: FaceBlurringModalImage,
  },
];
