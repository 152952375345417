import { Popover, Stack, Typography } from "@mui/material";
import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { ProgressOverviewSection } from "@components/progress-overview/progress-overview-section";
import { ProgressOverviewButton } from "@components/progress-overview/progress-overview-button";
import { InfoIcon } from "@faro-lotv/flat-ui";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { ProgressOverviewEvents } from "@utils/track-event/track-event-list";
import { sphereColors } from "@styles/common-colors";
import {
  DEFAULT_TEXT_FONT_SIZE,
  DEFAULT_TITLE_FONT_SIZE,
} from "@styles/common-styles";
import { useAppSelector } from "@store/store-helper";
import {
  hasIncompleteUploadTasksSelector,
  hasInProgressUploadTasksSelector,
} from "@store/upload-tasks/upload-tasks-selector";

/** Renders a menu to track the upload progress */
export function ProgressOverviewMenu(): JSX.Element {
  const { trackEvent } = useTrackEvent();
  const hasUploadTask = useAppSelector(hasIncompleteUploadTasksSelector);
  const hasInProgressUploadTask = useAppSelector(
    hasInProgressUploadTasksSelector
  );

  const [isOpen, setIsOpen] = useState(false);
  const button = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!hasUploadTask) {
      setIsOpen(false);
    }
  }, [hasUploadTask]);

  /** Opens the progress overview menu */
  const handleClick = useCallback<MouseEventHandler>(
    (ev) => {
      if (!isOpen) {
        trackEvent({ name: ProgressOverviewEvents.openProgressOverview });
        setIsOpen(true);
        ev.stopPropagation();
      }
    },
    [isOpen, trackEvent]
  );

  return (
    <>
      <ProgressOverviewButton
        ref={button}
        onClick={handleClick}
        isLoading={hasInProgressUploadTask}
        disabled={!hasUploadTask}
      />
      <Popover
        open={isOpen}
        anchorEl={button.current}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Stack
          component="div"
          sx={{
            width: "100%",
            maxWidth: "400px",
            maxHeight: "550px",
            borderRadius: "5px",
            p: 0,
            my: 4,
            overflow: "hidden",
          }}
          data-testid="progress-overview-menu"
        >
          <Typography
            sx={{
              fontSize: DEFAULT_TITLE_FONT_SIZE,
              fontWeight: 600,
              mx: "32px",
            }}
          >
            Uploads
          </Typography>

          <ProgressOverviewSection />

          <Stack
            sx={{
              flexDirection: "row",
              pt: 2,
              mx: 4,
              alignItems: "flex-start",
            }}
          >
            <InfoIcon
              sx={{ color: sphereColors.yellow600, pt: 0.3, height: "16px" }}
            />
            <Typography
              sx={{
                color: sphereColors.gray700,
                fontSize: DEFAULT_TEXT_FONT_SIZE,
              }}
            >
              Progress will be lost only if you close the browser while
              uploading your files.
            </Typography>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
}
