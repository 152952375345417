import { FilterId } from "@components/common/faro-table/faro-table-filter/faro-table-filter-types";
import { HeaderForTableType } from "@components/common/faro-table/faro-table-types";
import { ProjectMarkupsTableHeaders } from "@pages/project-details/project-markups/project-markups-table-utils";
import { TableType } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

const QUICK_FILTER = "quickFilter";

interface UpdateFilterItemProps<Column> {
  /** The type of filter that query params relates to */
  tableType: TableType;

  /** The column that its filtering data is going to be updated  */
  filteredColumn: Column;

  /** The id of the new filter */
  filterId: FilterId;

  /** Whether to replace the new filterId with the existing one in the same column or add it next to it */
  shouldReplaceFilterInColumn: boolean;
}

interface ReturnProps<Column> {
  /** Whether any filter is applied or not. It doesn't consider the quickFilter */
  isAnyFilterApplied: boolean;

  /** Returns the active filters for the provided column */
  getFilterIdsForColumn: (filteredColumn: Column) => string[];

  /** Removes all the query params related to filters  */
  clearAllFilters: (tableType: TableType) => void;

  /** Gets the quick filter search text */
  quickFilter: string;

  /** Updates the quick filter search text */
  updateQuickFilter: (filterText: string) => void;

  /** Updates the filter for a specific column */
  updateFilterItem: ({
    filteredColumn,
    filterId,
    shouldReplaceFilterInColumn,
  }: UpdateFilterItemProps<Column>) => void;
}

/** Handles all the logics for filters in query params, including getting and setting the filters */
export function useFilterQueryParams<
  Column extends HeaderForTableType<TableType>
>(): ReturnProps<Column> {
  const [searchParams, setSearchParams] = useSearchParams();
  const { trackEvent } = useTrackEvent();

  const isAnyFilterApplied = useMemo(
    () =>
      Object.values(ProjectMarkupsTableHeaders).some((column) => {
        const queryParam = searchParams.getAll(column);
        return queryParam.length !== 0;
      }),

    [searchParams]
  );

  const clearAllFilters = useCallback(
    (tableType: TableType) => {
      Object.values(ProjectMarkupsTableHeaders).forEach((column) => {
        searchParams.delete(column);
      });

      setSearchParams(searchParams);

      trackEvent({
        name: `Clear ${tableType} filters`,
      });
    },
    [searchParams, setSearchParams, trackEvent]
  );

  const getFilterIdsForColumn = useCallback(
    (filteredColumn: Column) => {
      const activeFilterIds = searchParams.getAll(filteredColumn);
      return activeFilterIds;
    },

    [searchParams]
  );

  const quickFilter = useMemo(
    () => searchParams.get(QUICK_FILTER) ?? "",
    [searchParams]
  );

  const updateQuickFilter = useCallback(
    (filterText: string) => {
      if (filterText === "") {
        searchParams.delete(QUICK_FILTER);
      } else {
        searchParams.set(QUICK_FILTER, filterText.toLowerCase());
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const updateFilterItem = useCallback(
    ({
      tableType,
      filteredColumn,
      filterId,
      shouldReplaceFilterInColumn,
    }: UpdateFilterItemProps<Column>) => {
      const activeFilterIds = getFilterIdsForColumn(filteredColumn);
      let totalFiltersInColumn = activeFilterIds.length;

      if (activeFilterIds.includes(filterId)) {
        searchParams.delete(filteredColumn, filterId);
        totalFiltersInColumn -= 1;
      } else if (shouldReplaceFilterInColumn) {
        totalFiltersInColumn = 1;
        searchParams.set(filteredColumn, filterId);
      } else {
        searchParams.append(filteredColumn, filterId);
        totalFiltersInColumn += 1;
      }

      trackEvent({
        name: `filter ${tableType}`,
        props: {
          filterBy: filteredColumn,
          // The total number of filters in the column after the update
          totalFiltersInColumn,
        },
      });
      setSearchParams(searchParams);
    },

    [getFilterIdsForColumn, searchParams, setSearchParams, trackEvent]
  );

  return {
    isAnyFilterApplied,
    getFilterIdsForColumn,
    quickFilter,
    clearAllFilters,
    updateQuickFilter,
    updateFilterItem,
  };
}
