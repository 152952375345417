import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  allowedBulkButtonsInSessionSelector,
  bulkActionNameSelector,
} from "@store/table/table-selector";
import { useEffect } from "react";
import { BulkName } from "@components/common/faro-table/faro-table-types";
import { setAllowedBulkButtonsInSession } from "@store/table/table-slice";

interface Props<T> {
  /** List of buttons that is allowed based on selected rows in table */
  allowedButtonsBasedOnRowSelection: T[];
}

/** Determines whether the bulk button should be shown for different tables */
export function useShouldShowBulkAction<T extends BulkName>({
  allowedButtonsBasedOnRowSelection,
}: Props<T>): {
  shouldShowBulkButtons(actionButton: T): boolean;
} {
  const bulkActionName = useAppSelector(bulkActionNameSelector);
  const allowedBulkButtonsInSession = useAppSelector(
    allowedBulkButtonsInSessionSelector
  );
  const dispatch = useAppDispatch();

  // Sets the allowedButtonsInSession as soon as user clicks on any bulk action.
  // allowedButtonsBasedOnRowSelection is not set as dependency as changes of it due to row selection removal
  // after performing bulk action should not change allowedButtonsInSession
  useEffect(() => {
    if (bulkActionName) {
      dispatch(
        setAllowedBulkButtonsInSession(allowedButtonsBasedOnRowSelection)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps -- allowedButtonsBasedOnRowSelection should not rerender
  }, [bulkActionName, dispatch]);

  /** A bulk button should be shown whether it is allowed based on row selection or was allowed in session */
  function shouldShowBulkButtons(actionButton: T): boolean {
    return (
      allowedButtonsBasedOnRowSelection.includes(actionButton) ||
      allowedBulkButtonsInSession.includes(actionButton)
    );
  }

  return {
    shouldShowBulkButtons,
  };
}
