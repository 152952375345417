import { isSrcOfTypeString } from "@custom-types/type-guards";
import { Box, SvgIcon, SxProps } from "@mui/material";
import { ElementType } from "react";

interface Props {
  /** The src of the image with its altText */
  image: {
    /** The source of the image */
    src: string | ElementType;

    /** The alt text of the image */
    altText: string;
  };

  /** Optional style to pass */
  sx?: SxProps;
}

/** Shows an SVG or a photo based on the type of provided image */
export function ImageRenderer({ image, sx }: Props): JSX.Element {
  if (isSrcOfTypeString(image.src)) {
    return (
      <Box
        component="img"
        src={image.src}
        alt={image.altText}
        sx={{
          ...sx,
        }}
      />
    );
  }

  return (
    <SvgIcon
      inheritViewBox
      component={image.src}
      alt={image.altText}
      sx={{
        ...sx,
        // This is added to make sure SvgIcon don't change the fill color of the SVG
        fill: "none",
      }}
    />
  );
}
