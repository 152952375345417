import { Box } from "@mui/material";
import { DEFAULT_TEXT_FONT_SIZE, withEllipsis } from "@styles/common-styles";
import { defaultProjectThumbnail } from "@utils/project-utils";
import AutosuggestHighlightMatch from "autosuggest-highlight/match";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";
import { useMemo } from "react";

/** Class name to highlight part of string */
const HIGHLIGHT_CLASS = "text_highlight";

interface GroupAutocompleteItemProps {
  /** The group name */
  title: string;

  /** The URL of the group thumbnail */
  image?: string;

  /** Input value typed on the input field */
  inputValue?: string;
}

/**
 * Shows a single project as an option in the autocomplete menu.
 * When a value is provided means that a project is displayed, and then shows
 * the project name and the thumbnail image.
 * When no value is provided means that the "All projects" option is displayed.
 */
export function GroupAutocompleteItem({
  title,
  image,
  inputValue,
}: GroupAutocompleteItemProps): JSX.Element {
  const highlightedLabel = useMemo(() => {
    /** Early return if there is no input value entered */
    if (!inputValue) {
      return title;
    }

    // Finding the match parts
    const matches = AutosuggestHighlightMatch(title, inputValue);
    const parts = AutosuggestHighlightParse(title, matches);

    return (
      <>
        {parts.map((part, index) => (
          <span className={part.highlight ? HIGHLIGHT_CLASS : ""} key={index}>
            {part.text}
          </span>
        ))}
      </>
    );
  }, [title, inputValue]);

  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        alignItems: "center",
        paddingX: "10px",
        paddingY: "5px",
        width: "100%",
      }}
    >
      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          width: "70px",
        }}
      >
        <img
          src={image ?? defaultProjectThumbnail}
          style={{
            maxHeight: "100%",
            maxWidth: "100%",
            objectFit: "contain",
          }}
          alt="Project thumbnail"
        />
      </Box>

      <Box
        component="var"
        sx={{
          marginLeft: "10px",
          fontSize: DEFAULT_TEXT_FONT_SIZE,
          width: "100%",
          ...withEllipsis,
          [`& .${HIGHLIGHT_CLASS}`]: {
            fontWeight: "bold",
          },
        }}
      >
        {highlightedLabel}
      </Box>
    </Box>
  );
}
