import { Box } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { useMemo } from "react";

interface Props {
  /** Name of the company. only the first letter will be used. */
  name: string;
  /** HEX color of the avatar */
  color: string;
  /**
   * Avatar size.
   *  - small 20px
   *  - large 24px.
   *  - x-large 32px.
   */
  size: "small" | "large" | "x-large";
}

/**
 * Shows a squared avatar that can render only one letter.
 * It is mainly used to show the first letter of a company.
 *
 * @returns React company avatar component.
 */
export function CompanyAvatar({ name, color, size }: Props): JSX.Element {
  /** Defines the css properties of the avatar based on the size. */
  const cssProperties = useMemo(() => {
    switch (size) {
      case "small":
        return {
          width: "20px",
          height: "20px",
          fontSize: "13px",
          fontWeight: "500",
        };
      case "large":
        return {
          width: "24px",
          height: "24px",
          fontSize: "15px",
          fontWeight: "600",
        };
      case "x-large":
        return {
          width: "32px",
          height: "32px",
          fontSize: "20px",
          fontWeight: "600",
        };
    }
  }, [size]);

  return (
    /* Wrapper outside component. It holds the size of the avatar but does not show anything */
    <Box
      component="div"
      style={{
        width: cssProperties.width,
        height: cssProperties.height,
        textAlign: "center",
        display: "flex",
        textAlignLast: "center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: color,
        color: sphereColors.pureWhite,
        fontSize: cssProperties.fontSize,
        fontWeight: cssProperties.fontWeight,
        borderRadius: "4px",
      }}
    >
      <Box component="div">
        <var>{(name[0] ?? "").toLocaleUpperCase()}</var>
      </Box>
    </Box>
  );
}
