import { SphereImg } from "@components/common/sphere-img";
import { Box, Stack, Typography } from "@mui/material";
import { sphereColors } from "@styles/common-colors";

interface Props {
  /** Text that explains what's changing with the settings in the current section */
  changingText: string;

  /**
   * Text that explains who's affected by the settings in the current section
   *
   * @default "All workspace members."
   */
  affectedText?: string;

  /** Optional image source to show */
  imgSrc?: string;
}

/**
 * Tooltip that shows what's changing and who's affected by the related
 * settings with an optional image showing above the text in the tooltip
 */
export function SettingsSectionTooltip({
  changingText,
  affectedText = "All workspace members.",
  imgSrc,
}: Props): JSX.Element {
  return (
    <Stack
      bgcolor={sphereColors.pureWhite}
      color={sphereColors.gray800}
      spacing={2}
    >
      <SphereImg src={imgSrc} alt="Sphere XG Icons" />
      <Box>
        <Typography
          fontSize={"14px"}
          fontWeight={700}
          mb={1}
          lineHeight={"20px"}
        >
          What's changing
        </Typography>
        <Typography fontSize={"14px"} lineHeight={"20px"}>
          {changingText}
        </Typography>
      </Box>
      <Box>
        <Typography
          fontSize={"14px"}
          fontWeight={700}
          mb={1}
          lineHeight={"20px"}
        >
          Who's affected
        </Typography>
        <Typography fontSize={"14px"} lineHeight={"20px"}>
          {affectedText}
        </Typography>
      </Box>
    </Stack>
  );
}
