import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import { CSSProperties, useMemo, useState } from "react";
import DownArrow from "@assets/icons/new/arrow-down_21px.svg?react";
import { sphereColors } from "@styles/common-colors";
import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { SnapshotMembers } from "@pages/project-details/project-snapshots/snapshot-members";
import { SnapshotActions } from "@pages/project-details/project-snapshots/snapshot-actions";
import { withEllipsisThreeLines } from "@styles/common-styles";
import { SnapshotAccessLevelBadge } from "@pages/project-details/project-snapshots/snapshot-access-level-badge";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { FaroIconButton } from "@components/common/faro-icon-button";
import {
  ACCESS_WIDTH,
  DATE_WIDTH,
  NAME_WIDTH,
  SNAPSHOT_HEIGHT,
  SNAPSHOT_MARGIN_TOP,
} from "@pages/project-details/project-snapshots/collapsible-snapshot-utils";
import { useAppSelector } from "@store/store-helper";
import { selectedProjectSelector } from "@store/projects/projects-selector";
import { useMediaQueryList } from "@hooks/use-media-query";
import { SnapshotEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useHasUserValidRoleSnapshotLevel } from "@hooks/access-control/use-has-user-valid-role-snapshot-level";
import { useDateTime } from "@hooks/use-date-time";

interface Props {
  /** The snapshot to be shown */
  snapshot: CoreAPITypes.IProjectSnapshot;
}

/** Class name for the actions container, to show it on hover */
const ACTIONS_CLASS_NAME = "snapshot-actions";

// Width in pixels of the open snapshot icon button
const OPEN_SNAPSHOT_ICON_BUTTON_WIDTH = 32;

// Width in pixels of each action button including the divider
const ACTION_WIDTH = 39;

// Maximum number of actions to show in mobile devices
// add members, email, copy link, open and delete
// We do not care that the snapshot has access to those actions, we always leave the space.
const MAX_ACTIONS_MD = 5;

// Maximum number of actions to show in mobile devices
// only the 3 dots menu
const MAX_ACTIONS_XS = 1;

/** Renders a collapsible snapshot to include project members in the content */
export function CollapsibleSnapshot({ snapshot }: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isActionsMenuOpen, setIsActionsMenuOpen] = useState<boolean>(false);
  const selectedProject = useAppSelector(selectedProjectSelector);
  const { isScreenXsAndSmall } = useMediaQueryList();
  const { trackEvent } = useTrackEvent();

  /** Whether a snapshot access level status is private */
  const isPrivate = useMemo(() => {
    // Only private snapshots can be opened because they have assigned members.
    return (
      snapshot.accessLevel === SphereDashboardAPITypes.EAccessLevel.private
    );
  }, [snapshot]);

  const { isSnapshotAdmin } = useHasUserValidRoleSnapshotLevel({
    selectedSnapshot: snapshot,
  });

  /** Whether the snapshot accordion can be opened to show the members */
  const canBeOpened = useMemo(() => {
    return isPrivate && isSnapshotAdmin;
  }, [isSnapshotAdmin, isPrivate]);

  const { formatDate } = useDateTime();

  /**
   * Calculates the needed width for the actions, depending the snapshot access level and screen size.
   * We calculate it to align all the actions in all snapshots.
   */
  const actionsWidth: CSSProperties["width"] = useMemo(() => {
    // Maximum number of actions to show depending on the screen size
    const maxActions = isScreenXsAndSmall ? MAX_ACTIONS_XS : MAX_ACTIONS_MD;

    // Total width if all actions are shown
    const totalWidth = ACTION_WIDTH * maxActions;

    // If the snapshot can't be opened add to the width the space that would need for the open button.
    return canBeOpened
      ? `${totalWidth}px`
      : `${totalWidth + OPEN_SNAPSHOT_ICON_BUTTON_WIDTH}px`;
  }, [canBeOpened, isScreenXsAndSmall]);

  /**
   * Toggles the state of the collapsible snapshot, to either open or close it.
   */
  function toggleSnapshot(): void {
    if (!canBeOpened) {
      return;
    }
    if (!isOpen) {
      trackEvent({ name: SnapshotEvents.viewSnapshotMembers });
    }
    setIsOpen(!isOpen);
  }

  return (
    <Accordion
      expanded={isOpen}
      onChange={toggleSnapshot}
      sx={{
        boxShadow: "none",
        "&:before": {
          backgroundColor: "transparent",
        },
        "&.MuiPaper-root.MuiPaper-rounded.MuiAccordion-root": {
          marginTop: SNAPSHOT_MARGIN_TOP,
        },
        "& .MuiAccordionSummary-root:hover:not(.Mui-disabled)": {
          cursor: canBeOpened ? "pointer" : "default",
        },
      }}
      slotProps={{ transition: { timeout: 500 } }}
      disableGutters
    >
      <AccordionSummary
        expandIcon={
          <FaroIconButton
            component={DownArrow}
            iconSize="18px"
            buttonSize="32px"
            isHidden={!canBeOpened}
          />
        }
        sx={{
          height: SNAPSHOT_HEIGHT,
          px: "20px",
          "&.MuiAccordionSummary-root": {
            backgroundColor: `${sphereColors.gray100}`,
          },
          [`&:hover .${ACTIONS_CLASS_NAME}`]: {
            visibility: "visible",
          },
        }}
      >
        <Box
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              width: `calc(100% - ${actionsWidth})`,
              justifyContent: "space-between",
            }}
          >
            {/* Show the snapshot name */}
            <Box
              component="div"
              sx={{
                width: NAME_WIDTH,
                fontSize: "14px",
                paddingRight: {
                  xs: "5px",
                  md: "10px",
                },
                ...withEllipsisThreeLines,
              }}
            >
              <SphereTooltip
                title={<var>{snapshot.name}</var>}
                shouldSkipWrapper
              >
                <var>{snapshot.name}</var>
              </SphereTooltip>
            </Box>

            {/* Show the snapshot creation date */}
            <Box
              component="div"
              sx={{
                fontSize: "12px",
                width: DATE_WIDTH,
                paddingRight: {
                  xs: "5px",
                  md: "10px",
                },
                ...withEllipsisThreeLines,
              }}
            >
              <var>{formatDate(snapshot.createdAt)}</var>
            </Box>

            {/* Show the snapshot access level */}
            <Box
              component="div"
              sx={{
                fontSize: "12px",
                width: ACCESS_WIDTH,
              }}
            >
              <SnapshotAccessLevelBadge accessLevel={snapshot.accessLevel} />
            </Box>
          </Box>

          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              width: actionsWidth,
            }}
          >
            {/* Show the snapshot action buttons */}
            <Box
              component="div"
              className={ACTIONS_CLASS_NAME}
              sx={{
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                // Will be replaced by parent when hovered
                // We need to have make sure it is visible when the menu is open, otherwise
                // it would disappear when the user opens the actions menu.
                visibility: isActionsMenuOpen ? "visible" : "hidden",
              }}
            >
              {selectedProject && (
                <SnapshotActions
                  snapshot={snapshot}
                  canBeOpened={canBeOpened}
                  onIsMenuOpenChanged={setIsActionsMenuOpen}
                  selectedProject={selectedProject}
                />
              )}
            </Box>
          </Box>
        </Box>
      </AccordionSummary>

      {/* Show the snapshot members when it is not collapsed */}
      {isOpen && canBeOpened && (
        <AccordionDetails
          sx={{
            // Since the members table already has a top margin of 16px
            // And each accordion item has a bottom margin of 4px
            // Set the padding for the details to have 16px in all sides
            padding: "0px 16px 12px",
          }}
        >
          <SnapshotMembers snapshot={snapshot} />
        </AccordionDetails>
      )}
    </Accordion>
  );
}
