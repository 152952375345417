import { CoreAPITypes } from "@stellar/api-logic";
import { useAppSelector } from "@store/store-helper";
import {
  projectArchivingStateSelector,
  projectMembersByRoleSelector,
  selectedProjectManagerSelector,
} from "@store/projects/projects-selector";
import { InviteMemberToProject } from "@pages/project-details/project-team/invite-member-to-project";
import { currentUserSelector } from "@store/user/user-selector";
import { FaroTextField } from "@components/common/faro-text-field/faro-text-field";
import { PageInfoBar } from "@components/common/page-info-bar";
import { ProjectArchivingState, SdbProject } from "@custom-types/project-types";
import { useMemo } from "react";
import { getUserTooltipDisplayName } from "@utils/user-utils";
import { useHasUserValidRoleProjectLevel } from "@hooks/access-control/use-has-user-valid-role-project-level";

interface Props {
  /** The project to display */
  project?: SdbProject;

  /** Whether the project is still loading and skeleton should be shown instead */
  isLoading: boolean;
}

/** Shows the main details in project team tab */
export function TeamTabProjectDetails({
  project,
  isLoading,
}: Props): JSX.Element {
  const currentUser = useAppSelector(currentUserSelector);
  const projectArchivingState = useAppSelector(
    projectArchivingStateSelector(project?.id ?? "")
  );
  const selectedProjectManager = useAppSelector(selectedProjectManagerSelector);

  const adminMembers = useAppSelector(
    projectMembersByRoleSelector(CoreAPITypes.EUserProjectRole.admin)
  );
  const editorMembers = useAppSelector(
    projectMembersByRoleSelector(CoreAPITypes.EUserProjectRole.editor)
  );
  const viewerMembers = useAppSelector(
    projectMembersByRoleSelector(CoreAPITypes.EUserProjectRole.viewer)
  );
  const { canInviteUsersToPrivateProject } = useHasUserValidRoleProjectLevel({
    selectedProject: project,
  });

  /**
   * Returns whether the user can invite members to project, this means that the project
   * is unarchived, and the user has permissions to invite other users.
   */
  const canInviteUsersToProject: boolean = useMemo(() => {
    if (!project || !currentUser) {
      return false;
    }
    return (
      projectArchivingState === ProjectArchivingState.active &&
      canInviteUsersToPrivateProject
    );
  }, [
    canInviteUsersToPrivateProject,
    currentUser,
    project,
    projectArchivingState,
  ]);

  if (isLoading || !project || project.members.length === 0) {
    return <PageInfoBar isLoading={true} />;
  }

  return (
    <PageInfoBar
      items={[
        {
          columnSize: 3,
          content: (
            <FaroTextField
              label="Project Manager"
              initialValue={selectedProjectManager.name}
              faroVariant="main"
              fontSize="18px"
              shouldShowTooltip
              tooltipProps={{
                title: getUserTooltipDisplayName({
                  member: selectedProjectManager.user,
                }),
              }}
            />
          ),
        },
        {
          columnSize: 6,
          content: (
            <FaroTextField
              label="Admins"
              initialValue={adminMembers.length}
              faroVariant="main"
              fontSize="18px"
            />
          ),
        },
        {
          columnSize: 6,
          content: (
            <FaroTextField
              label="Editors"
              initialValue={editorMembers.length}
              faroVariant="main"
              fontSize="18px"
            />
          ),
        },
        {
          columnSize: 6,
          content: (
            <FaroTextField
              label="Viewers"
              initialValue={viewerMembers.length}
              faroVariant="main"
              fontSize="18px"
            />
          ),
        },
      ]}
      action={
        canInviteUsersToProject && <InviteMemberToProject project={project} />
      }
    />
  );
}
