import { SdbProject } from "@custom-types/project-types";
import { ProjectMemberRole } from "@components/table/members/members-column/role-column/project-member-role";
import { MemberTableSubject } from "@components/common/faro-table/faro-table-types";
import {
  isCompanyMember,
  isGroupDetailsMember,
  isProjectMember,
} from "@custom-types/type-guards";
import { BaseMemberProps } from "@custom-types/member-types";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { withEllipsis } from "@styles/common-styles";
import { formatUserRoleType } from "@utils/data-display";
import { CompanyMemberRole } from "@components/table/members/members-column/role-column/company-member-role";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { GroupMemberRole } from "@components/table/members/members-column/role-column/group-member-role";
import { SphereDashboardAPITypes } from "@stellar/api-logic";

interface Props extends BaseCompanyIdProps, BaseMemberProps {
  /** The selected group if there is one */
  group?: SphereDashboardAPITypes.IGroupDetails | null;

  /** The selected project if there is one */
  selectedProject?: SdbProject | null;

  /** Recognizes on which table subject this component is being used */
  subjectType: MemberTableSubject;
}

/** Content to show for role member column */
export function RoleColumn({
  companyId,
  selectedProject,
  subjectType,
  member,
  group,
}: Props): JSX.Element | null {
  if (selectedProject && subjectType === "project" && isProjectMember(member)) {
    return (
      <ProjectMemberRole
        companyId={companyId}
        projectId={selectedProject?.id}
        member={member}
        key={member.email}
      />
    );
  }

  if (subjectType === "snapshot") {
    return (
      <SphereTooltip
        boxProps={{ sx: withEllipsis }}
        shouldShowTooltip={false}
        title={member.role}
      >
        {formatUserRoleType(member.role, { projectContext: "snapshot" })}
      </SphereTooltip>
    );
  }

  if (isCompanyMember(member) && subjectType === "workspace") {
    return (
      <CompanyMemberRole
        companyId={companyId}
        member={member}
        key={member.email}
      />
    );
  }

  if (group && isGroupDetailsMember(member) && subjectType === "group") {
    return (
      <GroupMemberRole
        companyId={companyId}
        selectedGroup={group}
        member={member}
      />
    );
  }

  return null;
}
