import { Link } from "@mui/material";
import { contactSupport } from "@utils/email-utils";
import { sphereColors } from "@styles/common-colors";
import { ContactSupportLocations } from "@custom-types/types";
import { runtimeConfig } from "@src/runtime-config";
import { useTrackEvent } from "@utils/track-event/use-track-event";

/** Creates a simple component to show a link to contact support */
export function SphereSupportLink(): JSX.Element {
  const { trackEvent } = useTrackEvent();

  return (
    <Link
      fontWeight="600"
      color={sphereColors.blue500}
      onClick={() =>
        contactSupport({
          location: ContactSupportLocations.feedbackDialog,
          trackEvent,
        })
      }
      sx={{ cursor: "pointer" }}
    >
      {runtimeConfig.mailSupportAddress}
    </Link>
  );
}
