import { Box, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import GenericCloseSvg from "@assets/icons/generic-close_l.svg?react";
import { useCoreApiClient } from "@api/use-core-api-client";
import { BaseMemberProps } from "@custom-types/member-types";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import {
  FaroDialog,
  SPACE_ELEMENTS_OF_MODAL,
} from "@components/common/dialog/faro-dialog";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { DEFAULT_FONT_FAMILY_ITALIC } from "@styles/common-styles";
import {
  BaseCompanyIdProps,
  SdbCompany,
} from "@custom-types/sdb-company-types";
import { UserDisplay } from "@components/common/user-display";
import { removeMemberFromProject } from "@store/projects/projects-slice-thunk";
import { fetchingProjectsFlagsSelector } from "@store/projects/projects-selector";
import { getPrettyName } from "@utils/user-utils";
import { useToast } from "@hooks/use-toast";
import { removeMemberFromGroup } from "@store/groups/groups-slice";
import { fetchingGroupsFlagsSelector } from "@store/groups/groups-selector";
import {
  TableType,
  GroupMemberEvents,
  ProjectMemberEvents,
  SnapshotEvents,
  WorkspaceMemberEvents,
} from "@utils/track-event/track-event-list";
import { useAppNavigation } from "@hooks/use-app-navigation";
import { FaroAlert } from "@components/common/faro-alert";
import { fetchingSnapshotsFlagsSelector } from "@store/snapshots/snapshots-selector";
import { removeMemberFromSnapshot } from "@store/snapshots/snapshots-slice";
import { PayloadAction } from "@reduxjs/toolkit";
import { isSuccessResponse } from "@context-providers/error-boundary/error-boundary-utils";
import { fetchingMembersFlagsSelector } from "@store/members/members-selector";
import { removeMemberFromCompany } from "@store/members/members-slice";
import { SHOW_ON_HOVER_CLASS } from "@utils/ui-utils";
import { MemberTableSubject } from "@components/common/faro-table/faro-table-types";
import { SdbProject } from "@custom-types/project-types";
import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { useTrackEvent } from "@utils/track-event/use-track-event";

/** Generic prop for RemoveSingleMemberFromEntity */
interface GenericProps<T extends MemberTableSubject>
  extends BaseCompanyIdProps,
    BaseMemberProps {
  /** The entity name */
  entityName: T;
}

/** Props for removing a single member from project */
interface RemoveSingleMemberFromProjectProps extends GenericProps<"project"> {
  entity: SdbProject;
}

/** Props for removing a single member from group */
interface RemoveSingleMemberFromGroupProps extends GenericProps<"group"> {
  entity: SphereDashboardAPITypes.IGroupDetails;
}

/** Props for removing a single member from snapshot */
interface RemoveSingleMemberFromSnapshotProps extends GenericProps<"snapshot"> {
  entity: CoreAPITypes.IProjectSnapshot;
}

/** Props for removing a single member from company */
interface RemoveSingleMemberFromWorkspaceProps
  extends GenericProps<"workspace"> {
  entity: SdbCompany;
}

type RemoveMemberProps =
  | RemoveSingleMemberFromProjectProps
  | RemoveSingleMemberFromGroupProps
  | RemoveSingleMemberFromSnapshotProps
  | RemoveSingleMemberFromWorkspaceProps;

/**
 * Opens a dialog with the member to be removed from project or group.
 */
export function RemoveSingleMemberFromEntity({
  companyId,
  member,
  entity,
  entityName,
}: RemoveMemberProps): JSX.Element {
  const coreApiClient = useCoreApiClient();
  const dispatch = useAppDispatch();
  const { showToast } = useToast();
  const { navigateToGroups } = useAppNavigation();
  const { trackEvent } = useTrackEvent();

  /* Stores the open status of the dialog. */
  const [isOpen, setIsOpen] = useState(false);

  /* Flags to know if the member is being removed from the project */
  const { isRemovingProjectMember } = useAppSelector(
    fetchingProjectsFlagsSelector
  );
  /* Flags to know if the member is being removed from the group */
  const { isRemovingGroupMember } = useAppSelector(fetchingGroupsFlagsSelector);
  /** Flag to know if a member is being removed from the snapshot */
  const { isRemovingSnapshotMember } = useAppSelector(
    fetchingSnapshotsFlagsSelector
  );
  const { isRemovingCompanyMember } = useAppSelector(
    fetchingMembersFlagsSelector
  );

  /** Whether the entity is currently being removed */
  const isProcessing: boolean = useMemo(() => {
    switch (entityName) {
      case "project":
        return isRemovingProjectMember;
      case "group":
        return isRemovingGroupMember;
      case "snapshot":
        return isRemovingSnapshotMember;
      case "workspace":
        return isRemovingCompanyMember;
    }
  }, [
    entityName,
    isRemovingGroupMember,
    isRemovingProjectMember,
    isRemovingSnapshotMember,
    isRemovingCompanyMember,
  ]);

  /** Dialog title */
  const title = useMemo(() => {
    return `Remove from ${entityName}`;
  }, [entityName]);

  /** Text to show when the member was removed */
  const successDescription = useMemo(() => {
    return (
      <Box
        component="div"
        sx={{
          fontSize: "14px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          component="var"
          sx={{
            fontFamily: DEFAULT_FONT_FAMILY_ITALIC,
            wordWrap: "break-word",
          }}
        >
          {getPrettyName(member)}
        </Box>
        <Box component="div">
          {` has been successfully removed from ${entityName} `}
          <Box
            component="var"
            sx={{
              fontFamily: DEFAULT_FONT_FAMILY_ITALIC,
            }}
          >
            {entity.name}
          </Box>
        </Box>
      </Box>
    );
  }, [entity, entityName, member]);

  /**
   * Navigates to the groups page after the group has been removed
   * and notifies the user.
   */
  function onGroupRemoved(): void {
    navigateToGroups({ companyId });
    showToast({
      message: (
        <>
          Successfully removed group <var>{entity.name}</var>
        </>
      ),
      type: "success",
    });
  }

  /** Handles removing a project member */
  async function removeProjectMember(): Promise<PayloadAction<unknown>> {
    trackEvent({
      name: ProjectMemberEvents.removeMember,
      props: { dataType: TableType.projectMembers },
    });

    const result = await dispatch(
      removeMemberFromProject({
        coreApiClient,
        companyId,
        member,
        projectId: entity.id,
      })
    );

    return result;
  }

  /** Handles removing a company member */
  async function removeCompanyMember(): Promise<PayloadAction<unknown>> {
    trackEvent({
      name: WorkspaceMemberEvents.removeMember,
      props: { dataType: TableType.companyMembers },
    });

    const result = await dispatch(
      removeMemberFromCompany({ coreApiClient, companyId, member })
    );

    return result;
  }

  /** Handles removing a group member */
  async function removeGroupMember(): Promise<PayloadAction<unknown>> {
    trackEvent({
      name: GroupMemberEvents.removeMember,
      props: { dataType: TableType.groupMembers },
    });

    const result = await dispatch(
      removeMemberFromGroup({
        coreApiClient,
        companyId,
        member,
        groupId: entity.id,
        onGroupRemoved,
      })
    );

    return result;
  }

  /** Handles removing a snapshot member */
  async function removeSnapshotMember(): Promise<PayloadAction<unknown>> {
    trackEvent({
      name: SnapshotEvents.removeMember,
      props: { dataType: TableType.snapshotMembers },
    });

    const result = await dispatch(
      removeMemberFromSnapshot({
        coreApiClient,
        companyId,
        snapshotId: entity.id,
        member,
      })
    );

    return result;
  }

  /**
   * Removes the member from the project and notifies the result.
   */
  async function removeMember(): Promise<void> {
    let result: PayloadAction<unknown>;

    // Call the right function based on the entity name
    switch (entityName) {
      case "project":
        result = await removeProjectMember();
        break;
      case "group":
        result = await removeGroupMember();
        break;
      case "snapshot":
        result = await removeSnapshotMember();
        break;
      case "workspace":
        result = await removeCompanyMember();
        break;
    }

    // Errors are already handled by the slice, only notify about a successful result
    if (isSuccessResponse(result)) {
      showToast({
        message: `Member removed from ${entityName}`,
        description: successDescription,
        type: "success",
      });
    }

    setIsOpen(false);
  }

  return (
    <>
      {/* Button to open the dialog that it is only shown on hover on the table row */}
      <SphereTooltip
        title={title}
        dataTestId="REMOVE_USER_BUTTON"
        boxProps={{
          className: SHOW_ON_HOVER_CLASS,
          sx: {
            textAlign: "right",
            // This will be overridden by parent component using SHOW_ON_HOVER_CLASS
            visibility: "hidden",
          },
        }}
      >
        <FaroIconButton
          aria-label="more"
          aria-haspopup="true"
          component={GenericCloseSvg}
          onClick={() => setIsOpen(true)}
        />
      </SphereTooltip>

      {/* Dialog with the details of the member to be removed */}
      <FaroDialog
        title={title}
        confirmText="Remove"
        open={isOpen}
        onConfirm={removeMember}
        onClose={() => setIsOpen(false)}
        isConfirmLoading={isProcessing}
      >
        {entityName === "group" && entity.members.length === 1 ? (
          <FaroAlert
            severity="warning"
            sx={{
              marginBottom: SPACE_ELEMENTS_OF_MODAL,
            }}
          >
            Removing <b>the last group member</b> will also delete the group if
            there are no projects assigned.
          </FaroAlert>
        ) : (
          <Typography
            sx={{
              fontSize: "14px",
              marginTop: "0px",
              marginBottom: SPACE_ELEMENTS_OF_MODAL,
            }}
          >
            When removing member from {entityName} they will no longer be able
            to access this {entityName}. You can always invite this member
            again.
          </Typography>
        )}

        <UserDisplay member={member} />
      </FaroDialog>
    </>
  );
}
