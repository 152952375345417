import { useCoreApiClient } from "@api/use-core-api-client";
import { MemberTypes } from "@custom-types/member-types";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import {
  companyMembersSelector,
  fetchingMembersFlagsSelector,
} from "@store/members/members-selector";
import { fetchCompanyMembers } from "@store/members/members-slice";
import {
  fetchingProjectsFlagsSelector,
  projectMembersSelector,
} from "@store/projects/projects-selector";
import { selectedSdbCompanyIdSelector } from "@store/sdb-company/sdb-company-selector";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { fetchingUserFlagsSelector } from "@store/user/user-selector";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";
import { useEffect, useMemo } from "react";

interface Props {
  /** List of company members */
  companyMembers: MemberTypes[];

  /** Whether the company members are being fetched from the backend */
  isFetchingCompanyMembers: boolean;

  /** List of project members */
  projectMembers: SphereDashboardAPITypes.IProjectMemberBase[];

  /** Whether the project members are being fetched from the backend */
  isFetchingProjectMembers: boolean;
}

/**
 * Custom hook that gets the company and project members used to set the markups assignee and creator name
 *
 * For company members, only if current user has permission to view the company members list the members are returned.
 * If the user does not have permission then it returns an empty array.
 */
export function useMembersUtils(): Props {
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const companyId = useAppSelector(selectedSdbCompanyIdSelector);
  const { isFetchingCurrentUser } = useAppSelector(fetchingUserFlagsSelector);
  const companyMembers = useAppSelector(companyMembersSelector);
  const { isFetchingCompanyMembers } = useAppSelector(
    fetchingMembersFlagsSelector
  );
  const projectMembers = useAppSelector(projectMembersSelector);
  const { isFetchingProjects } = useAppSelector(fetchingProjectsFlagsSelector);
  const { canViewAllCompanyUsers } = useHasUserValidRoleCompanyLevel();

  /** Whether the current user has the permission to view all company members */
  const hasPermissionToViewCompanyMembers = useMemo(() => {
    // Deny access while the currentUser object is being fetched
    // The currentUser is require to check the permission
    if (isFetchingCurrentUser) {
      return false;
    }

    return canViewAllCompanyUsers;
  }, [canViewAllCompanyUsers, isFetchingCurrentUser]);

  const members = useMemo(() => {
    return hasPermissionToViewCompanyMembers ? companyMembers : [];
  }, [companyMembers, hasPermissionToViewCompanyMembers]);

  /**
   * Fetch the company members only if the user has permissions to view the members list
   * If the company members were already fetched the members store sliced will skip the fetching
   */
  useEffect(() => {
    if (hasPermissionToViewCompanyMembers && companyId) {
      dispatch(
        fetchCompanyMembers({
          coreApiClient,
          companyId,
        })
      );
    }
  }, [companyId, coreApiClient, dispatch, hasPermissionToViewCompanyMembers]);

  return {
    companyMembers: members,
    isFetchingCompanyMembers,
    projectMembers,
    isFetchingProjectMembers: isFetchingProjects,
  };
}
