/** All the possible headers for projects table. */
export enum ProjectHeaders {
  /** Project thumbnail image */
  thumbnail = "Thumbnail",

  /** Project name */
  name = "Project Name",

  /** Project Manager avatar */
  managerAvatar = "Avatar",

  /** Project Manager name */
  projectManager = "Project Manager",

  /** Project last update date */
  lastUpdated = "Last updated",

  /** Project visibility */
  accessLevel = "Access Level",

  /** Project group */
  group = "Group",

  /** Projects tags */
  tags = "Tags",

  /** Any other possible options */
  options = "Options",
}
