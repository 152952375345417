import { Box, Typography } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { ReactElement } from "react";

interface Props {
  /** The title to display for this info item. */
  title: string;

  /**
   * The value associated with the info item, which can be a string or a React element.
   * If not provided, a default value of "-" will be displayed.
   */
  value?: string | ReactElement;
}

/**
 * Displays an information item within a point cloud side panel, consisting of a title
 * and an associated value. The title is a descriptive label for the information item,
 * and the value can be a string or a React element. If no value is provided, a default
 * placeholder ("-") will be displayed.
 *
 * @param {Props} props - The props for the PointCloudSidePanelInfoItem component.
 * @returns {JSX.Element} A JSX element representing the PointCloudSidePanelInfoItem component.
 */
export function PointCloudSidePanelInfoItem({
  title,
  value,
}: Props): JSX.Element {
  return (
    <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
      <Typography
        sx={{
          fontWeight: "700",
          fontSize: "12px",
          color: sphereColors.gray700,
          width: "167px",
        }}
        data-testid={"point-cloud-side-panel-info-item-title"}
      >
        {title}
      </Typography>

      <Box
        data-testid={"point-cloud-side-panel-info-item-value"}
        sx={{ fontSize: "12px", color: sphereColors.gray800 }}
      >
        {value || "-"}
      </Box>
    </Box>
  );
}
