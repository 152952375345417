import { Box, SvgIcon } from "@mui/material";
import { EDecimalToHex, sphereColors } from "@styles/common-colors";
import { addTransparency, getSvgColoredIconCss } from "@utils/ui-utils";

interface Props {
  /** Whether the action is active or not. If active the background color and icon color is adapted */
  isActive?: boolean;

  /** The icon component to show */
  component: React.ElementType;
}

/** The color for the box shadow in a single action */
const BOX_SHADOW_COLOR = addTransparency({
  color: sphereColors.pureWhite,
  alpha: EDecimalToHex.twenty,
});

/**
 * Displays a single action that can be performed on a data display, either list or cards view
 */
export function DataDisplayAction({ isActive, component }: Props): JSX.Element {
  return (
    <Box
      component="div"
      sx={{
        backgroundColor: isActive ? sphereColors.pureWhite : undefined,
        borderRadius: "4px",
        width: "32px",
        height: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: isActive ? `0px 1px 2px ${BOX_SHADOW_COLOR}` : undefined,
        "&:hover": {
          cursor: "pointer",
        },
        ...getSvgColoredIconCss(
          isActive ? sphereColors.blue500 : sphereColors.gray800
        ),
      }}
    >
      <SvgIcon
        inheritViewBox={true}
        component={component}
        width="24px"
        height="24px"
      />
    </Box>
  );
}
