import { useEffect, useState } from "react";
import { useAuthContext } from "@context-providers/auth/auth-context";
import { useCoreApiClient } from "@api/use-core-api-client";
import { SecuredRoute, SecuredRouteProps } from "@router/secured-route";
import { CoreAPIUtils } from "@stellar/api-logic";
import { StatusCodes } from "http-status-codes";

type AuthenticatedRouteProps = SecuredRouteProps;

/**
 * Component to add to routes that require authentication, if the user is authenticated displays
 * the content and if not requests a login.
 * It can optionally add a required company role prop to make it also a secured route based
 * on the user company role.
 * The required company role defines the minimum company role that the user can have,
 * and if it doesn't match that criteria it redirects to a forbidden page.
 */
export function AuthenticatedRoute({
  children,
  requiredRoleCompanyLevel,
  requiredRoleProjectLevel,
}: AuthenticatedRouteProps): JSX.Element | null {
  const { loginStatus, showSessionExpiredDialog } = useAuthContext();
  const coreApiClient = useCoreApiClient();

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  // Checks if the user is authenticated before rendering the children component.
  useEffect(() => {
    setIsAuthenticated(false);

    async function checkAuth(): Promise<void> {
      // First check the AuthContext if the user is not logged in and do and early exit in that case.
      if (loginStatus === "loggedOut") {
        showSessionExpiredDialog();
        setIsAuthenticated(false);
        return;
      }

      // Perform the authentication check using the isLoggedIn backend endpoint.
      try {
        await coreApiClient.V1.SDB.isLoggedIn();
        setIsAuthenticated(true);
      } catch (error) {
        // The CoreAPI backend returns an error code 403 "FORBIDDEN" when the user it not authenticated
        // Only set the user as not-authenticated and request the user to login for this error code
        if (
          CoreAPIUtils.isResponseError(error) &&
          error.status === StatusCodes.FORBIDDEN
        ) {
          showSessionExpiredDialog();
          setIsAuthenticated(false);
        }
      }
    }

    checkAuth();

    return () => setIsAuthenticated(false);
  }, [coreApiClient, loginStatus, showSessionExpiredDialog]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  const returnJsx = isAuthenticated ? <>{children}</> : null;

  if (requiredRoleCompanyLevel || requiredRoleProjectLevel) {
    return (
      <SecuredRoute
        requiredRoleCompanyLevel={requiredRoleCompanyLevel}
        requiredRoleProjectLevel={requiredRoleProjectLevel}
      >
        {returnJsx}
      </SecuredRoute>
    );
  }

  return returnJsx;
}
