import { Box, Typography } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { withEllipsis } from "@styles/common-styles";

interface AddOnsExtensionContentProps {
  /** Name from extension add-on */
  name: string;

  /** Description from extension add-on*/
  description: string;

  /** If there is any button on footer */
  hasFooter: boolean;
}

const NUMBER_OF_LINES_WITHOUT_FOOTER = 6;
const NUMBER_OF_LINES_WITH_FOOTER = 3;

/** Renders the extension footer component */
export function AddOnsExtensionContent({
  name,
  description,
  hasFooter,
}: AddOnsExtensionContentProps): JSX.Element {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "12px",
        flexDirection: "column",
        maxHeight: "100%",
        height: "100%",
      }}
    >
      <Typography
        data-testid="add-ons-extensions-name"
        sx={{
          color: sphereColors.gray800,
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "600",
          ...withEllipsis,
        }}
      >
        {name}
      </Typography>
      <Typography
        data-testid="add-ons-extensions-description"
        sx={{
          color: sphereColors.gray600,
          fontSize: "14px",
          lineHeight: "20px",
          fontWeight: "400",
          overflow: "hidden",
          display: "-webkit-box",
          WebkitLineClamp: hasFooter
            ? NUMBER_OF_LINES_WITH_FOOTER
            : NUMBER_OF_LINES_WITHOUT_FOOTER,
          WebkitBoxOrient: "vertical",
        }}
      >
        {description}
      </Typography>
    </Box>
  );
}
