import { LinkProps, MenuItem, MenuItemProps } from "@mui/material";
import { DEFAULT_INPUT_FONT_SIZE } from "@styles/common-styles";
import { useMemo } from "react";
import { Link } from "react-router-dom";

/**
 * Simple menu item that works the same as MUI MenuItem but adds some styling,
 * to match FARO's design guidelines.
 */
export function FaroMenuItem(
  props: MenuItemProps & Pick<LinkProps, "href" | "target">
): JSX.Element {
  /** Props to be added to the icon button when used as a link */
  const linkProps: MenuItemProps & LinkProps = useMemo(() => {
    if (!props.href) {
      return {};
    }
    return {
      // Different than icon component, for a menu item component is needed.
      component: Link,
      // It seems we need to provide "to" for a link component.
      to: props.href,
      // We provide both just in case
      href: props.href,
      target: props.target,
      rel: props.rel,
    };
  }, [props]);

  return (
    <MenuItem
      disableRipple
      {...props}
      {...linkProps}
      sx={{
        ...props.sx,
        fontSize: DEFAULT_INPUT_FONT_SIZE,
      }}
    />
  );
}
