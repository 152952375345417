import { Box, Divider, Grid } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import {
  DEFAULT_FONT_FAMILY,
  withEllipsisThreeLines,
} from "@styles/common-styles";
import { PropsWithChildren } from "react";

interface Props {
  /** Text to show on the left side of the container header */
  title: string;

  /** Content to show next to the title (can be text or links etc.) */
  description?: React.ReactNode;

  /** Action buttons to show on the right side of the container header */
  actionButtons?: JSX.Element;

  /** ID to identify the container. It is used for our automation framework playwright */
  dataTestId?: string;
}

/**
 * A page container/wrapper with a header section where a title and description can be displayed on the left
 * and actions buttons can be displayed on the right.
 */
export function PageContainer({
  children,
  title,
  description,
  actionButtons,
  dataTestId,
}: PropsWithChildren<Props>): JSX.Element {
  return (
    <Box component="div" sx={{ width: "100%" }} data-testid={dataTestId}>
      <Grid
        container
        sx={{
          boxShadow: `0 1px 0 0 ${sphereColors.gray200}`,
          paddingBottom: "5px",
        }}
      >
        <Grid
          flexWrap="nowrap"
          container
          item
          xs={12}
          sm={12}
          /* eslint-disable @typescript-eslint/no-magic-numbers -- these numbers are requited for grid size */
          md={actionButtons ? 6 : 12}
          lg={actionButtons ? 7 : 12}
          xl={actionButtons ? 8 : 12}
          /* eslint-enable @typescript-eslint/no-magic-numbers */
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "14px",
            lineHeight: "19px",
            color: sphereColors.gray800,
          }}
        >
          <Grid
            item
            sx={{
              fontFamily: DEFAULT_FONT_FAMILY,
              fontSize: "14px",
              lineHeight: "19px",
              color: sphereColors.gray800,
            }}
          >
            {title}
          </Grid>

          <Grid item sx={{ ml: "35px", ...withEllipsisThreeLines }}>
            {description}
          </Grid>
        </Grid>

        {actionButtons && (
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={5}
            xl={4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              svg: { height: "24px", width: "24px" },
            }}
          >
            {actionButtons}
          </Grid>
        )}
      </Grid>
      <Divider />
      <Box
        component="div"
        sx={{
          width: "100%",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
