import { useCoreApiClient } from "@api/use-core-api-client";
import {
  FaroDialog,
  SPACE_ELEMENTS_OF_MODAL,
} from "@components/common/dialog/faro-dialog";
import { AutoCompleteMessage } from "@components/common/faro-text-field/faro-text-field-message";
import { MembersAutocomplete } from "@components/common/members-autocomplete/members-autocomplete";
import {
  getMemberLabel,
  AutoCompleteMenuOption,
} from "@components/common/members-autocomplete/members-autocomplete-utils";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { RoleDisplayNames } from "@custom-types/member-types";
import { useCompanyMembers } from "@hooks/use-company-members";
import { useToast } from "@hooks/use-toast";
import { Grid, Stack } from "@mui/material";
import { useAppParams } from "@router/router-helper";
import {
  APITypes,
  CoreAPITypes,
  SphereDashboardAPITypes,
} from "@stellar/api-logic";
import {
  addProcessingGroups,
  removeProcessingGroups,
  setOne,
} from "@store/groups/groups-slice";
import { useAppDispatch } from "@store/store-helper";
import { isValidEmail } from "@utils/member-utils";
import { useState } from "react";
import { EnableGroupCreationCheckbox } from "@components/groups/enable-group-creation-checkbox";
import { createGroupsPermission } from "@utils/user-utils";
import { FaroButtonContained } from "@components/common/faro-button-contained";
import { SelectGroupRole } from "@components/role/select-group-role";

interface Props {
  /** The selected group */
  group: SphereDashboardAPITypes.IGroupDetails;
}

/** Renders the invite member button and the functionality in member page */
export function InviteMemberToGroup({ group }: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const { handleErrorWithToast } = useErrorContext();
  const { groupId, companyId } = useAppParams();
  const { showToast } = useToast();

  const companyMembers = useCompanyMembers();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  const [shouldCreateGroups, setShouldCreateGroups] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] =
    useState<SphereDashboardAPITypes.IAssignmentGroupRole>(
      CoreAPITypes.EUserCompanyRole.projectManager
    );
  const [message, setMessage] = useState<AutoCompleteMessage | undefined>();

  /** Flag whether the input field in the autocomplete component has a valid email */
  const [isMemberInputValid, setIsMemberInputValid] = useState<boolean>(false);

  // Disable the `Send Invite` button if no user has been selected and the input is not a valid email.
  const shouldSendInviteDisable =
    !selectedMembers.length && !isMemberInputValid;

  /** Reformation company members data for faro autocomplete component */
  const memberOptions: AutoCompleteMenuOption[] = companyMembers.map(
    (member, index) => {
      const alreadyMemberInGroup = group.members.find(
        (groupMember) => member.identity === groupMember.identity
      );
      const isAlreadyMemberInGroup = !!alreadyMemberInGroup;

      return {
        label: getMemberLabel({ member }),
        value: member.identity,
        originalMember: member,
        isDisabled: isAlreadyMemberInGroup,
        disabledMessage: isAlreadyMemberInGroup
          ? `Already in group ${
              alreadyMemberInGroup?.role
                ? `as ${RoleDisplayNames[alreadyMemberInGroup?.role]}`
                : ""
            }`
          : "",
      };
    }
  );

  function handleMemberSelect(members: APITypes.UserIdentity[]): void {
    const isMembersContainsEmail = members.some((member) =>
      isValidEmail(member)
    );

    /** Show hints about adding or selecting an email */
    if (isMembersContainsEmail) {
      setMessage({
        type: "info",
        helperText:
          "Invitation to this group will be sent to the new email addresses",
      });
    } else {
      setMessage(undefined);
    }
    setSelectedMembers(members);
  }

  /** Return true if the new email is valid and it doesn't exist in the group */
  function isNewEmailValid(email: string): boolean {
    return isValidEmail(email) && !isAlreadyMemberInCurrentGroup(email);
  }

  /** Return true if member already exist with same email in the group */
  function isAlreadyMemberInCurrentGroup(email: string): boolean {
    return group.members.some(
      (member) => member.email === email || member.identity === email
    );
  }

  /**
   * Function to be called when the input value changes
   * It sets the valid emails to the addedEmails state and removes them from the input value
   */
  function onInputChange(value: string): void {
    const isValueExistOnMemberList = memberOptions.some(({ label }) =>
      label.includes(value)
    );

    if (!value) {
      // Removing message on clearing the search text
      setMessage(undefined);
      setIsMemberInputValid(false);
    } else if (!isValueExistOnMemberList && !isValidEmail(value)) {
      // Showing error message for new and invalid email
      setMessage({ type: "error", helperText: "Enter a valid email" });
      setIsMemberInputValid(false);
    } else if (isAlreadyMemberInCurrentGroup(value)) {
      // Showing info message if member already exist in the group
      setMessage({
        type: "info",
        helperText: "A member with the same email already exists in the group",
      });
      setIsMemberInputValid(false);
    } else {
      if (isValidEmail(value)) {
        setIsMemberInputValid(true);
      }
      setMessage(undefined);
    }
  }

  /** Submit members to be invited */
  async function handleConfirm(): Promise<void> {
    setIsDialogOpen(false);

    try {
      createPayloadAndSendInvite();
    } catch (error) {
      handleErrorWithToast({
        id: `inviteMember-${Date.now().toString()}`,
        title: "Cannot invite member(s)",
        error,
      });
    }
  }

  /** This function creates payload and call the appropriate route */
  async function createPayloadAndSendInvite(): Promise<void> {
    // Additional permission to be added if user should be able to create groups
    const permission: SphereDashboardAPITypes.ICompanyPermission | undefined =
      shouldCreateGroups &&
      selectedRole === CoreAPITypes.EUserCompanyRole.companyManager
        ? createGroupsPermission
        : undefined;

    const payload: SphereDashboardAPITypes.IBulkMemberInvitePayload<"group"> = {
      assignments: [
        {
          identities: selectedMembers,
          permission,
          role: selectedRole,
        },
      ],
    };

    if (companyId && groupId && selectedMembers.length) {
      // Add the groupId to processing groups to start the loading spinner in the group
      dispatch(addProcessingGroups([groupId]));

      const response: SphereDashboardAPITypes.IBulkMemberInviteResponse<"group"> =
        await coreApiClient.V3.SDB.addMembersToGroup(
          companyId,
          groupId,
          payload
        );

      // Add invited member to the store. response.data.members exist when the response is either success or warning
      if (response.data.members) {
        const groupWithUpdatedMembers = {
          ...group,
          members: response.data.members,
        };

        dispatch(setOne(groupWithUpdatedMembers));
      }

      // Remove groupId from processing group to stop the loading spinner in the group
      dispatch(removeProcessingGroups([groupId]));

      respondToUser(response);
    }
  }

  /** This function checks the backend response and show related toast message based on it */
  function respondToUser(
    response: SphereDashboardAPITypes.IBulkMemberInviteResponse<"group">
  ): void {
    // Show response messages
    const responseMessage = response.data.message;
    const additionalMessages: string[] = [];
    if (response.errorData) {
      response.errorData.details.failedInvitations?.forEach((error) => {
        additionalMessages.push(error.message);
      });

      response.errorData.details.failedPermissions?.forEach((error) => {
        additionalMessages.push(error.message);
      });
    }

    if (response.status === "success") {
      showToast({
        type: "success",
        message: "Invitations sent",
        description: additionalMessages,
      });
    } else if (response.status === "warning") {
      showToast({
        type: "warning",
        message: responseMessage,
        description: additionalMessages.map((message, index) => (
          <li key={index}>{message}</li>
        )),
      });
    } else if (response.status === "error") {
      showToast({
        type: "error",
        message: responseMessage,
        description: additionalMessages.map((message, index) => (
          <li key={index}>{message}</li>
        )),
      });
    }
  }

  /** Default setup when invite dialog open */
  function onOpenInviteDialog(): void {
    setMessage(undefined);
    setSelectedMembers([]);
    setIsDialogOpen(true);
  }

  return (
    <>
      <FaroButtonContained onClick={onOpenInviteDialog}>
        Invite members
      </FaroButtonContained>
      <FaroDialog
        title="Invite members to group"
        confirmText="Send Invite"
        open={isDialogOpen}
        onConfirm={handleConfirm}
        isConfirmDisabled={shouldSendInviteDisable}
        onClose={() => setIsDialogOpen(false)}
      >
        <Grid maxWidth="100%" width="70vw">
          <Stack marginBottom={SPACE_ELEMENTS_OF_MODAL}>
            <SelectGroupRole
              selectedRole={selectedRole}
              onChange={setSelectedRole}
              isTableCell={false}
            />
          </Stack>
          <Stack>
            <MembersAutocomplete
              options={memberOptions}
              handleChange={handleMemberSelect}
              onInputChange={onInputChange}
              validateNewOption={isNewEmailValid}
              message={message}
              labelTitle="Members"
              hasAutoFocus={true}
            />
          </Stack>
        </Grid>

        <EnableGroupCreationCheckbox
          selectedRole={selectedRole}
          shouldCreateGroups={shouldCreateGroups}
          membersCount={selectedMembers.length}
          setShouldCreateGroups={setShouldCreateGroups}
        />
      </FaroDialog>
    </>
  );
}
