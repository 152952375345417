import { getHBDashboardUrl } from "@components/sidebar/sidebar-holobuilder/sidebar-holobuilder-utils";
import { SidebarItemProps } from "@components/sidebar/sidebar-items";
import { SidebarListItem } from "@components/sidebar/sidebar-list-item";
import { SidebarHolobuilderTooltip } from "@components/sidebar/sidebar-holobuilder/sidebar-holobuilder-tooltip";
import { List } from "@mui/material";
import { useMemo } from "react";
import { useAppSelector } from "@store/store-helper";
import { isBetaTestingEnabledSelector } from "@store/ui/ui-selector";
import { hasCompanySubscriptionRoles } from "@utils/access-control/company/company-access-control";
import { fetchingUserFlagsSelector } from "@store/user/user-selector";
import {
  fetchingSdbCompanyFlagsSelector,
  selectedCompanyContextSelector,
  selectedSdbCompanySelector,
} from "@store/sdb-company/sdb-company-selector";
import { SidebarEvents } from "@utils/track-event/track-event-list";
import { APITypes } from "@stellar/api-logic";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";

interface Props {
  /** Flag whether the sidebar should be shown full size or minified */
  isSidebarFullSize: boolean;

  /** Item to show in the sidebar. */
  sidebarItem: SidebarItemProps;
}

/** Renders a sidebar item that navigates to the HoloBuilder dashboard */
export function SidebarHolobuilderItem({
  isSidebarFullSize,
  sidebarItem,
}: Props): JSX.Element | null {
  const { isFetchingCurrentUser } = useAppSelector(fetchingUserFlagsSelector);
  const companyContext = useAppSelector(selectedCompanyContextSelector);
  const { isFetchingSelectedCompanyContext } = useAppSelector(
    fetchingSdbCompanyFlagsSelector
  );
  const isBetaTestingEnabled = useAppSelector(isBetaTestingEnabledSelector);
  const selectedSdbCompany = useAppSelector(selectedSdbCompanySelector);
  const { trackAsyncEvent } = useTrackEvent();
  const { hasUserPermissionCompanyLevel } = useHasUserValidRoleCompanyLevel();

  const shouldShowItem = useMemo(() => {
    // Don't show item while the required backend request are being fetched
    if (isFetchingCurrentUser || isFetchingSelectedCompanyContext) {
      return false;
    }

    if (!selectedSdbCompany || selectedSdbCompany.isSphereXGExclusive) {
      return false;
    }

    // Don't show a sidebar item if the item is in beta testing and beta testing is not enabled
    if (sidebarItem.shouldShowOnlyInBeta && !isBetaTestingEnabled) {
      return false;
    }

    // This is a special check for the HoloBuilder button. Don't show it if the user
    // has a Orbis/Connect account, since they should not be aware of HoloBuilder.
    const isOrbisUser = companyContext
      ? hasCompanySubscriptionRoles({
          companyContext,
          requiredCompanySubscriptionRole: [
            APITypes.EUserSubscriptionRole.globalOrbisProcessing,
          ],
        })
      : false;

    if (isOrbisUser) {
      return false;
    }

    // Only show the items that the user has access to.
    return hasUserPermissionCompanyLevel({
      roleName: sidebarItem.requiredRoleCompanyLevel,
    });
  }, [
    companyContext,
    hasUserPermissionCompanyLevel,
    isBetaTestingEnabled,
    isFetchingCurrentUser,
    isFetchingSelectedCompanyContext,
    selectedSdbCompany,
    sidebarItem.requiredRoleCompanyLevel,
    sidebarItem.shouldShowOnlyInBeta,
  ]);

  async function navigateToHBDashboard(
    event: React.MouseEvent<Element, MouseEvent>
  ): Promise<void> {
    // Stop the event so the react-router NavLink component does not navigate to the passed route immediately.
    // That way we can track the event first and then redirect to HB Dashboard manually here.
    event.preventDefault();
    event.stopPropagation();

    await trackAsyncEvent({
      name: SidebarEvents.navigateToHBDashboard,
    });

    const hbUrl = getHBDashboardUrl();
    window.location.assign(hbUrl);
  }

  if (!shouldShowItem) {
    return null;
  }

  return (
    <List
      sx={{
        paddingY: 0,
        mb: "24px",
      }}
    >
      <SidebarListItem
        isSidebarFullSize={isSidebarFullSize}
        sidebarItem={sidebarItem}
        onClick={(event) => navigateToHBDashboard(event)}
        tooltip={<SidebarHolobuilderTooltip />}
      />
    </List>
  );
}
