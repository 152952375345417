import { ValueLabelItem } from "@custom-types/types";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { EDecimalToHex, colorConst, sphereColors } from "@styles/common-colors";
import { withEllipsis } from "@styles/common-styles";
import { addTransparency } from "@utils/ui-utils";

interface Props<T extends string | number> {
  /** Toggle button items */
  items: ValueLabelItem<T>[];

  /** Current value of the toggle */
  currentValue: T;

  /** Callback that handles the button group value change */
  handleChange(value: T): void;
}

/**
 * Renders a toggle button group with custom Sphere design.
 * It behaves like a radio group where only one value can be selected.
 */
export function SphereToggleButtonGroup<T extends string | number>({
  items,
  currentValue,
  handleChange,
}: Props<T>): JSX.Element {
  function onValueChange(
    event: React.MouseEvent<HTMLElement>,
    value: unknown
  ): void {
    // MUI ToggleButtonGroup allows to have no selected value (null). We want to enforce to have a value:
    // https://mui.com/material-ui/react-toggle-button/#enforce-value-set
    if (value !== null) {
      handleChange(value as T);
    }
  }

  return (
    <ToggleButtonGroup
      value={currentValue}
      exclusive
      onChange={onValueChange}
      sx={{
        padding: "3px",
        backgroundColor: sphereColors.gray100,
        borderRadius: "4px",
        gap: "9px",
      }}
    >
      {items.map((item, index) => (
        <ToggleButton
          key={index}
          value={item.value}
          disableFocusRipple
          disableRipple
          sx={{
            display: "block",
            color: colorConst.normalFont,
            textTransform: "none",
            paddingY: "6px",
            paddingX: "16px",
            maxWidth: "120px",
            lineHeight: "normal",
            backgroundColor: sphereColors.gray100,
            border: "none",
            ...withEllipsis,
            "&.Mui-selected": {
              backgroundColor: sphereColors.pureWhite,
              color: sphereColors.blue500,
              fontWeight: 600,
              borderRadius: "2px",
              boxShadow: `0px 1px 2px ${addTransparency({
                color: sphereColors.black,
                alpha: EDecimalToHex.twenty,
              })}`,
              "&:hover": {
                backgroundColor: sphereColors.pureWhite,
              },
            },
            "&:hover:not(.Mui-selected)": {
              backgroundColor: sphereColors.gray50,
              color: sphereColors.blue500,
              borderRadius: "2px",
            },
          }}
        >
          {item.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
