import { SdbCompany } from "@custom-types/sdb-company-types";
import { Grid, Skeleton } from "@mui/material";
import {
  LINK_HEIGHT,
  SelectWorkspaceLink,
} from "@components/common/workspace/select-workspace-link";

interface Props {
  /** The workspace to be displayed, can be the admin panel or a normal workspace */
  sdbCompany?: SdbCompany;

  /** Flag whether the item is shown on an even column. It will add the needed spacing  */
  isEven?: boolean;

  /** Optional function to be called when a workspace item is clicked */
  onWorkspaceClick?: () => void;
}

/**
 * Shows a single workspace to be selected in the workspaces selection page.
 */
export function SelectWorkspaceItem({
  sdbCompany,
  isEven = false,
  onWorkspaceClick,
}: Props): JSX.Element {
  return (
    <Grid
      item
      xs={12}
      md={6}
      sx={{
        paddingRight: {
          xs: "0px",
          // Add spacing to the right of the element if it is the first one in the row.
          md: isEven ? "10px" : "0px",
        },
        paddingLeft: {
          xs: "0px",
          // Add spacing to the left of the element if it is the last one in the row.
          md: isEven ? "0px" : "10px",
        },
        paddingBottom: "20px",
      }}
    >
      {sdbCompany ? (
        <SelectWorkspaceLink
          sdbCompany={sdbCompany}
          onClick={onWorkspaceClick}
        />
      ) : (
        <Skeleton variant="rectangular" height={LINK_HEIGHT} />
      )}
    </Grid>
  );
}
