import { FaroIconButton } from "@components/common/faro-icon-button";
import GenericCloseSvg from "@assets/icons/generic-close_l.svg?react";
import SuccessIcon from "@assets/icons/success_32px.svg?react";
import FailedIcon from "@assets/icons/failed_32px.svg?react";
import { CircularProgress, Grid, SvgIcon } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { getSvgColoredIconCss } from "@utils/ui-utils";
import { FetchingItem } from "@store/table/table-slice-helper";
import { useAppDispatch } from "@store/store-helper";
import { removeOneManually } from "@store/table/table-slice";

interface Props {
  /** The item itself */
  item: FetchingItem;
}

/** Shows appropriate icon based on the fetching status */
export function ListStatusIcon({ item }: Props): React.ReactNode {
  const dispatch = useAppDispatch();
  let statusIcon: React.ReactNode = null;

  switch (item.status) {
    case "idle":
      statusIcon = (
        <SphereTooltip title="Remove from this list">
          <FaroIconButton
            component={GenericCloseSvg}
            onClick={() => dispatch(removeOneManually(item.id))}
            iconSize="20px"
          />
        </SphereTooltip>
      );
      break;

    case "in-queue":
      statusIcon = (
        <CircularProgress sx={{ color: sphereColors.blue200 }} size="20px" />
      );
      break;

    case "updating":
      statusIcon = (
        <CircularProgress sx={{ color: sphereColors.blue500 }} size="20px" />
      );
      break;

    case "success":
      statusIcon = (
        <SvgIcon
          inheritViewBox
          component={SuccessIcon}
          sx={{
            ...getSvgColoredIconCss(sphereColors.green700),
            height: "20px",
            width: "20px",
          }}
        />
      );
      break;

    case "error":
      statusIcon = (
        <SvgIcon
          inheritViewBox
          component={FailedIcon}
          sx={{
            ...getSvgColoredIconCss(sphereColors.red600),
            height: "20px",
            width: "20px",
          }}
        />
      );
  }

  return (
    <Grid container alignItems="center">
      {item.message && (
        <SphereTooltip
          title={item.message}
          boxProps={{
            sx: {
              color: sphereColors.blue500,
              fontSize: "12px",
              mr: "20px",
              cursor: "pointer",
            },
          }}
        >
          View info
        </SphereTooltip>
      )}
      {statusIcon}
    </Grid>
  );
}
