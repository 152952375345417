import { ProjectDataManagementType } from "@custom-types/project-data-management-types";

/** True iff the passed value is of type ProjectDataManagementType */
export function isProjectDataManagementType(
  value: unknown
): value is ProjectDataManagementType {
  return (
    typeof value === "string" &&
    Object.keys(ProjectDataManagementType).includes(value)
  );
}
