import {
  ICON_LARGE_STYLE,
  SphereAvatar,
} from "@components/header/sphere-avatar";
import CameraIcon from "@assets/icons/new/camera_24px.svg?react";
import { Box } from "@mui/material";
import { getPendingUserIcon, getUserInitials } from "@utils/user-utils";
import { useState } from "react";
import { sphereColors } from "@styles/common-colors";
import { BaseMemberProps } from "@custom-types/member-types";
import { useAppSelector } from "@store/store-helper";
import { currentUserSelector } from "@store/user/user-selector";
import { UpdateProfilePictureDialog } from "@components/user-info/update-profile-picture-dialog";

/** Renders SphereAvatar component with ability to update avatar */
export function SphereUpdatableAvatar({
  member,
}: BaseMemberProps): JSX.Element {
  const currentUser = useAppSelector(currentUserSelector);

  const [isAvatarHovering, setIsAvatarHovering] = useState<boolean>(false);
  const [isProfilePictureDialogOpen, setIsProfilePictureDialogOpen] =
    useState<boolean>(false);

  const isCurrentUser = member.identity === currentUser?.identity;

  // Return the normal SphereAvatar if member is not current user
  if (!isCurrentUser) {
    return (
      <SphereAvatar
        size="x-large"
        initials={getUserInitials(member)}
        icon={getPendingUserIcon(member, "large")}
        src={member.thumbnailUrl}
      />
    );
  }

  return (
    <>
      <Box
        component="div"
        onMouseEnter={() => setIsAvatarHovering(true)}
        onMouseLeave={() => setIsAvatarHovering(false)}
        onClick={() => setIsProfilePictureDialogOpen(true)}
        sx={{
          width: "fit-content",
          cursor: "pointer",
        }}
      >
        {isAvatarHovering ? (
          <SphereAvatar
            size="x-large"
            icon={
              <CameraIcon
                style={{ fill: sphereColors.blue500, ...ICON_LARGE_STYLE }}
              />
            }
            iconColor={sphereColors.blue500}
            shouldUseSimplifiedAvatar={!isCurrentUser}
            backgroundColor={sphereColors.gray200}
          />
        ) : (
          <SphereAvatar
            size="x-large"
            initials={getUserInitials(currentUser)}
            iconColor={sphereColors.blue500}
            src={currentUser.thumbnailUrl}
            shouldUseSimplifiedAvatar={!isCurrentUser}
            backgroundColor={sphereColors.gray400}
          />
        )}
      </Box>

      {isProfilePictureDialogOpen && (
        <UpdateProfilePictureDialog
          isDialogOpen={isProfilePictureDialogOpen}
          setIsDialogOpen={setIsProfilePictureDialogOpen}
        />
      )}
    </>
  );
}
