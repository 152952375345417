import { FaroIconButton } from "@components/common/faro-icon-button";
import { FaroMenuItem } from "@components/common/faro-select/faro-menu-item";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { Box, SvgIcon } from "@mui/material";
import { ElementType } from "react";

interface Props {
  /**
   * The title of the button to open the dialog,
   * used either as a label in mobile devices or tooltip on larger devices.
   */
  buttonTitle: string;

  /** The icon to be shown */
  icon: ElementType;

  /** The data test id for the button */
  dataTestId: string;

  /** Whether the snapshot is opened in mobile version, and the button is collapsed in a menu. */
  isMobileVersion?: boolean;

  /** Callback to open the dialog */
  openDialog: () => void;
}

/**
 * Shows either an icon button element or a menu item depending on the screen size.
 * It is intended to be used in the snapshot icons when the button should open a dialog.
 */
export function SnapshotActionButton({
  icon,
  buttonTitle,
  dataTestId,
  isMobileVersion,
  openDialog,
}: Props): JSX.Element {
  /**
   * Triggers the callback to open a dialog and stops propagation to prevent
   * un-collapsing the snapshot
   */
  function triggerOpenDialog(event: React.MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    openDialog();
  }

  if (isMobileVersion) {
    return (
      <FaroMenuItem onClick={triggerOpenDialog}>
        <Box
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <SvgIcon
            sx={{
              height: "18px",
              width: "18px",
              marginRight: "10px",
            }}
            component={icon}
            inheritViewBox
          />
          {buttonTitle}
        </Box>
      </FaroMenuItem>
    );
  }
  return (
    // Button to open the dialog that it is only shown on hover on the table row
    <SphereTooltip title={buttonTitle} dataTestId={dataTestId}>
      <FaroIconButton
        component={icon}
        onClick={triggerOpenDialog}
        iconSize="16px"
        buttonSize="32px"
      />
    </SphereTooltip>
  );
}
