import { BaseMarkupProps } from "@custom-types/project-markups-types";
import { useMemo } from "react";
import { getMarkupStatusChip } from "@pages/project-details/project-markups/status/markup-status-utils";
import { FaroChipStatus } from "@faro-lotv/flat-ui";

/** Renders the status of a project markup as a chip */
export function MarkupStatus({ markup }: BaseMarkupProps): JSX.Element {
  const status = markup.status?.value;

  const statusChip = useMemo(() => getMarkupStatusChip(status), [status]);

  return <FaroChipStatus status={statusChip} size="small" />;
}
