import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store-helper";
import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { CoreApiWithCompanyIdProps } from "@store/store-types";

interface CoreApiWithCompanyIdPropsWithMemberId
  extends CoreApiWithCompanyIdProps {
  memberId: string;
}

export const fetchCompanyMemberGroups = createAsyncThunk<
  SphereDashboardAPITypes.CompanyMemberGroup[],
  CoreApiWithCompanyIdPropsWithMemberId,
  { state: RootState }
>(
  "companyMemberGroups/fetchCompanyMemberGroups",
  async ({ coreApiClient, companyId, memberId }) => {
    try {
      const data = await coreApiClient.V3.SDB.getCompanyMemberGroups(
        companyId,
        memberId
      );

      return data;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  },
  {
    condition: (_, api) => {
      // Skip fetching if groups have already been fetched
      return !api.getState().companyMemberGroups.groups;
    },
  }
);
