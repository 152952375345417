import { Chip } from "@mui/material";
import { EDecimalToHex, sphereColors } from "@styles/common-colors";
import { DEFAULT_TEXT_FONT_SIZE } from "@styles/common-styles";
import { addTransparency, border } from "@utils/ui-utils";
import { CSSProperties, useMemo } from "react";
import { HeaderForTableType } from "@components/common/faro-table/faro-table-types";
import { TableType } from "@utils/track-event/track-event-list";
import { useFilterQueryParams } from "@hooks/table-filters/use-filter-query-params";
import { FilterChipIcon } from "@components/common/faro-table/faro-table-filter/filter-chip-icon";

interface Props {
  /** The column in which filtering will apply to */
  filteredColumn: HeaderForTableType<TableType>;

  /** The label of the chip to show in filter bar */
  label: string;

  /** Whether the chip should have a dropdown or not */
  shouldHaveDropdown: boolean;

  /** Whether the dropdown is open for the chip or not */
  isDropdownOpen?: boolean;

  /** Handler when the chip is clicked */
  onClick: React.MouseEventHandler<HTMLDivElement> | undefined;
}

/** A chip for filtering table's data */
export function FilterChip({
  filteredColumn,
  label,
  onClick,
  shouldHaveDropdown,
  isDropdownOpen = false,
}: Props): JSX.Element {
  const { getFilterIdsForColumn } = useFilterQueryParams();
  const activeFilterIds = getFilterIdsForColumn(filteredColumn);
  const numberOfActiveFilters = activeFilterIds.length;

  /** Adjusts the color, backgroundColor and border of the chip depending on its conditions */
  const styling: CSSProperties = useMemo(() => {
    const baseStyling = {
      color: sphereColors.blue500,
      backgroundColor: sphereColors.pureWhite,
      border: border(sphereColors.gray300),
    };
    if (isDropdownOpen) {
      return {
        ...baseStyling,
        border: border("2px", sphereColors.blue500),
      };
    }
    if (numberOfActiveFilters > 0) {
      return {
        ...baseStyling,
      };
    }
    return {
      ...baseStyling,
      color: sphereColors.gray600,
    };
  }, [numberOfActiveFilters, isDropdownOpen]);

  return (
    <Chip
      onClick={onClick}
      label={label}
      variant="outlined"
      sx={{
        ...styling,
        fontSize: DEFAULT_TEXT_FONT_SIZE,
        cursor: "pointer",
        flexDirection: shouldHaveDropdown ? "row-reverse" : "row",
        mt: "5px",
        "&:hover": {
          border: border(sphereColors.blue500),
          backgroundColor: addTransparency({
            color: sphereColors.gray500,
            alpha: EDecimalToHex.thirtyEight,
          }),
          color: sphereColors.blue500,
        },
      }}
      size="small"
      icon={
        <FilterChipIcon
          shouldHaveDropdown={shouldHaveDropdown}
          numberOfActiveFilters={numberOfActiveFilters}
        />
      }
      // Chip by default doesn't support disableRipple prop. A solution is to use onDelete and deleteIcon props
      // https://github.com/mui/material-ui/pull/29034
      onDelete={() => {
        /* Do nothing */
      }}
      // eslint-disable-next-line react/jsx-no-useless-fragment
      deleteIcon={<></>}
    />
  );
}
