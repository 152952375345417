import {
  createEntityAdapter,
  createSlice,
  EntityAdapter,
  EntityId,
  PayloadAction,
} from "@reduxjs/toolkit";
import { APITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import {
  ProjectArchivingState,
  ProjectSettings,
  ProjectSortByAttrName,
  SdbProject,
} from "@custom-types/project-types";
import {
  RemoveMemberResult,
  UpdateMemberRoleInProject,
} from "@store/projects/projects-slice-types";
import { BaseEntityState } from "@store/store-types";
import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import {
  deleteProject,
  editProjectFeature,
  fetchProjectContext,
  fetchProjectDetails,
  fetchProjects,
  fetchProjectSettings,
  fetchSearchedProjects,
  fetchUserProjects,
  getProjectFeatures,
  removeMemberFromProject,
  updateMemberRoleInProjectSingle,
  updateMemberRoleInProjectBulk,
  updateProjectDetails,
} from "@store/projects/projects-slice-thunk";
import {
  DEFAULT_NUMBER_OF_DISPLAYED_PROJECTS,
  convertProjectTypesToSdbProject,
} from "@store/projects/projects-slice-utils";

/** State of the current projects */
export interface ProjectsState extends BaseEntityState<SdbProject> {
  /** Stores the current display page for projects. */
  page: number;

  /** Stores the id of the next project to be loaded if there are more projects to be loaded. */
  next: string | null;

  /** Stores how many projects should be shown in the UI. */
  numberOfDisplayedProjects: number;

  /** Stores both the attribute name and the order in which projects should be sorted by. */
  sortBy: {
    /** Stores the attribute name to sort projects by. */
    attrName: ProjectSortByAttrName;

    /**
     * Stores the order in which projects should be sorted by.
     * True means they will be sorted in descending order and false in ascending order.
     */
    shouldSortDesc: boolean;
  };

  /** The selected project in which user is viewing */
  selectedProject: {
    /** ID of the selected project */
    id: APITypes.ProjectId | null;

    /** Project context of the selected project */
    context: SphereDashboardAPITypes.IProjectContextResponse | null;

    /** Settings of the selected project */
    settings: ProjectSettings | null;
  };

  /** Flags to indicate whether the projects have been loaded */
  loaded: {
    /** Indicates if the active projects have successfully been loaded */
    hasLoadedActiveProjects: boolean;

    /** Indicates if the archived projects have successfully been loaded */
    hasLoadedArchivedProjects: boolean;
  };

  /** Collects all the fetching properties for this slice */
  fetching: {
    /** Indicates if projects are currently being fetched from the backend */
    isFetchingProjects: boolean;

    /** Indicates if a project is currently being updated to the backend */
    isUpdatingProjects: boolean;

    /** Indicates if a project member is currently being removed from the backend */
    isRemovingProjectMember: boolean;

    /** Indicates if a project context is currently being fetched from the backend */
    isFetchingProjectContext: boolean;

    /** Indicates if the settings of the selected project are currently being fetched from the backend */
    isFetchingProjectSettings: boolean;

    /**
     * Map of project IDs where a process is currently ongoing for them.
     * The value is always true as we remove the entity if the project is not being processed anymore
     */
    processingProjects: { [key: EntityId]: true };
  };
}

/** Creates an entity adapter to store a map with all the projects that the user has access to. */
export const projectsAdapter: EntityAdapter<SdbProject> = createEntityAdapter({
  selectId: (project) => project.id,
});

const initialState: ProjectsState = {
  ...projectsAdapter.getInitialState(),
  page: 0,
  next: null,
  numberOfDisplayedProjects: DEFAULT_NUMBER_OF_DISPLAYED_PROJECTS,
  sortBy: {
    attrName: "modifiedAt",
    shouldSortDesc: true,
  },

  selectedProject: {
    id: null,
    context: null,
    settings: {},
  },

  loaded: {
    hasLoadedActiveProjects: false,
    hasLoadedArchivedProjects: false,
  },

  fetching: {
    isFetchingProjects: false,
    isUpdatingProjects: false,
    isRemovingProjectMember: false,
    isFetchingProjectContext: false,
    isFetchingProjectSettings: false,
    processingProjects: {},
  },
};

/**
 * Removes locally a member from a project, by removing it from members array
 * attribute of the project.
 */
function removeLocalMemberFromProject(
  state: ProjectsState,
  result: RemoveMemberResult
): void {
  const project = projectsAdapter
    .getSelectors()
    .selectById(state, result.projectId);
  if (project) {
    // If the projects is in the store update the members attribute
    // by removing the member that was removed from it.
    projectsAdapter.updateOne(state, {
      id: project.id,
      changes: {
        members: project.members.filter(
          (member) => member.identity !== result.member.identity
        ),
      },
    });
  }
  state.fetching.isRemovingProjectMember = false;
}

/**
 * Update a member role in project store by updating it from members array attribute of the project.
 */
function updateMemberRoleInProjectStore(
  state: ProjectsState,
  result: UpdateMemberRoleInProject
): void {
  const project = projectsAdapter
    .getSelectors()
    .selectById(state, result.projectId);

  // If the projects is in the store update the member role
  if (project) {
    projectsAdapter.updateOne(state, {
      id: project.id,
      changes: {
        members: project.members.map((member) => {
          if (member.identity === result.identity) {
            return { ...member, role: result.role };
          }
          return member;
        }),
      },
    });
  }
}

/** Slice to access state of loaded projects */
const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    // Check the Redux Toolkit documentation to understand the purpose of each reducer function:
    // https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions

    /** Accepts a single project entity and adds or replaces it. */
    setOneProjects: projectsAdapter.setOne,

    /** Accepts an array of project entities, and adds or replaces them. */
    setManyProjects: projectsAdapter.setMany,

    /** Removes all project entities from the store. */
    removeAllProjects: projectsAdapter.removeAll,

    /** Accepts an array of project IDs, and removes each project entity with those IDs if they exist. */
    removeManyProjects: projectsAdapter.removeMany,

    /** Accepts a single project IDs, and removes the project entity with that ID if it exists. */
    removeOneProjects: projectsAdapter.removeOne,

    addProcessingProjects(state, action: PayloadAction<APITypes.ProjectId[]>) {
      action.payload.forEach(
        (projectId) => (state.fetching.processingProjects[projectId] = true)
      );
    },

    /** Stores the project id of the selected project. */
    setSelectedProjectId(state, action: PayloadAction<string | null>) {
      state.selectedProject.id = action.payload;
    },

    removeProcessingProjects(
      state,
      action: PayloadAction<APITypes.ProjectId[]>
    ) {
      action.payload.forEach(
        (projectId) => delete state.fetching.processingProjects[projectId]
      );
    },

    /** Set the fetching of updating projects */
    setFetchingUpdatingProjects(state, action: PayloadAction<boolean>) {
      state.fetching.isUpdatingProjects = action.payload;
    },

    /**
     * Increases the number of projects to be shown in a single page.
     * Uses DEFAULT_NUMBER_OF_DISPLAYED_PROJECTS as the increment value.
     */
    increaseNumberOfDisplayedProjects(state) {
      state.numberOfDisplayedProjects += DEFAULT_NUMBER_OF_DISPLAYED_PROJECTS;
    },

    resetProjectsState: () => initialState,
  },

  extraReducers(builder) {
    builder
      .addCase(fetchProjects.pending, (state) => {
        state.fetching.isFetchingProjects = true;
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        const sdbProjects = action.payload.projects.map(
          convertProjectTypesToSdbProject
        );
        projectsAdapter.upsertMany(state, sdbProjects);
        state.next = action.payload.next;
        state.fetching.isFetchingProjects = false;
        if (
          action.payload.projectArchivingState === ProjectArchivingState.active
        ) {
          state.loaded.hasLoadedActiveProjects = true;
        }
        if (
          action.payload.projectArchivingState ===
          ProjectArchivingState.archived
        ) {
          state.loaded.hasLoadedArchivedProjects = true;
        }
      })
      .addCase(fetchProjects.rejected, (state) => {
        state.fetching.isFetchingProjects = false;
      })

      .addCase(fetchProjectDetails.pending, (state) => {
        state.fetching.isFetchingProjects = true;
      })
      .addCase(fetchProjectDetails.fulfilled, (state, action) => {
        state.fetching.isFetchingProjects = false;
        state.selectedProject.id = action.payload.id;
        projectsAdapter.setOne(
          state,
          convertProjectTypesToSdbProject(action.payload)
        );
      })
      .addCase(fetchProjectDetails.rejected, (state) => {
        state.fetching.isFetchingProjects = false;
      })

      .addCase(fetchSearchedProjects.pending, (state) => {
        state.fetching.isFetchingProjects = true;
      })
      .addCase(fetchSearchedProjects.fulfilled, (state, action) => {
        const sdbProjects = action.payload.searchedProjects.map(
          convertProjectTypesToSdbProject
        );
        projectsAdapter.upsertMany(state, sdbProjects);
        state.fetching.isFetchingProjects = false;
      })
      .addCase(fetchSearchedProjects.rejected, (state) => {
        state.fetching.isFetchingProjects = false;
      })

      .addCase(fetchUserProjects.pending, (state) => {
        projectsAdapter.removeAll(state);
        state.fetching.isFetchingProjects = true;
      })
      .addCase(fetchUserProjects.fulfilled, (state, action) => {
        const sdbProjects = action.payload.map(convertProjectTypesToSdbProject);
        projectsAdapter.upsertMany(state, sdbProjects);
        state.fetching.isFetchingProjects = false;
      })
      .addCase(fetchUserProjects.rejected, (state) => {
        state.fetching.isFetchingProjects = false;
      })

      .addCase(updateProjectDetails.pending, (state) => {
        state.fetching.isUpdatingProjects = true;
      })
      .addCase(updateProjectDetails.fulfilled, (state, action) => {
        state.fetching.isUpdatingProjects = false;
        projectsAdapter.setOne(
          state,
          convertProjectTypesToSdbProject(action.payload)
        );
      })
      .addCase(updateProjectDetails.rejected, (state, action) => {
        state.fetching.isUpdatingProjects = false;
        throw new Error(getErrorDisplayMarkup(action.error));
      })

      .addCase(removeMemberFromProject.pending, (state) => {
        state.fetching.isRemovingProjectMember = true;
      })
      .addCase(removeMemberFromProject.fulfilled, (state, action) => {
        removeLocalMemberFromProject(state, action.payload);
      })
      .addCase(removeMemberFromProject.rejected, (state) => {
        state.fetching.isRemovingProjectMember = false;
      })

      .addCase(updateMemberRoleInProjectSingle.pending, (state) => {
        state.fetching.isUpdatingProjects = true;
      })
      .addCase(updateMemberRoleInProjectSingle.fulfilled, (state, action) => {
        updateMemberRoleInProjectStore(state, action.payload);
        state.fetching.isUpdatingProjects = false;
      })
      .addCase(updateMemberRoleInProjectSingle.rejected, (state) => {
        state.fetching.isUpdatingProjects = false;
      })

      .addCase(updateMemberRoleInProjectBulk.pending, (state) => {
        state.fetching.isUpdatingProjects = true;
      })
      .addCase(updateMemberRoleInProjectBulk.fulfilled, (state, action) => {
        updateMemberRoleInProjectStore(state, action.payload);
        state.fetching.isUpdatingProjects = false;
      })
      .addCase(updateMemberRoleInProjectBulk.rejected, (state) => {
        state.fetching.isUpdatingProjects = false;
      })

      .addCase(fetchProjectContext.pending, (state) => {
        state.fetching.isFetchingProjectContext = true;
      })
      .addCase(fetchProjectContext.fulfilled, (state, action) => {
        state.selectedProject.context = action.payload;
        state.fetching.isFetchingProjectContext = false;
      })
      .addCase(fetchProjectContext.rejected, (state) => {
        state.fetching.isFetchingProjectContext = false;
      })

      .addCase(getProjectFeatures.pending, (state) => {
        state.fetching.isFetchingProjectContext = true;
      })
      .addCase(getProjectFeatures.fulfilled, (state, action) => {
        projectsAdapter.updateOne(state, {
          id: action.payload.projectId,
          changes: {
            features: { ...action.payload.features },
          },
        });
        state.fetching.isFetchingProjectContext = false;
      })
      .addCase(getProjectFeatures.rejected, (state) => {
        state.fetching.isFetchingProjectContext = false;
      })

      .addCase(editProjectFeature.fulfilled, (state, action) => {
        projectsAdapter.updateOne(state, {
          id: action.payload.selectedProject.id,
          changes: {
            features: {
              ...action.payload.selectedProject.features,
              ...action.payload.newFeatures,
            },
          },
        });
      })
      .addCase(editProjectFeature.rejected, (state, action) => {
        throw new Error(getErrorDisplayMarkup(action.error));
      })

      .addCase(deleteProject.pending, (state, action) => {
        state.fetching.processingProjects[action.meta.arg.projectId] = true;
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        projectsAdapter.removeOne(state, action.payload);
        delete state.fetching.processingProjects[action.payload];
      })
      .addCase(deleteProject.rejected, (state, action) => {
        delete state.fetching.processingProjects[action.meta.arg.projectId];
      })

      .addCase(fetchProjectSettings.pending, (state) => {
        state.fetching.isFetchingProjectSettings = true;
      })
      .addCase(fetchProjectSettings.fulfilled, (state, action) => {
        state.selectedProject.settings = action.payload;
        state.fetching.isFetchingProjectSettings = false;
      })
      .addCase(fetchProjectSettings.rejected, (state) => {
        state.fetching.isFetchingProjectSettings = false;
      });
  },
});

export const {
  setOneProjects,
  setManyProjects,
  removeAllProjects,
  removeManyProjects,
  removeOneProjects,
  addProcessingProjects,
  setSelectedProjectId,
  removeProcessingProjects,
  increaseNumberOfDisplayedProjects,
  resetProjectsState,
  setFetchingUpdatingProjects,
} = projectsSlice.actions;

export const projectsReducer = projectsSlice.reducer;
