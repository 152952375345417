import { useGetBulkDialogContent } from "@components/common/faro-table/bulk-dialog/bulk-dialog-helper";
import { BulkDialogs } from "@components/common/faro-table/bulk-dialog/bulk-dialog-types";
import {
  bulkActionResultsSelector,
  numberOfUpdatingItemsSelector,
} from "@store/table/table-selector";
import { useAppSelector } from "@store/store-helper";
import { nounPluralize } from "@utils/data-display";

/** Determines the content of bulk dialog and bulk success dialog for deleting project */
export function useDeleteProjectBulkDialogContent(): BulkDialogs {
  const numberOfUpdatingItems = useAppSelector(numberOfUpdatingItemsSelector);
  const { numberOfSuccess, numberOfErrors } = useAppSelector(
    bulkActionResultsSelector
  );

  const bulkDialogContent = useGetBulkDialogContent({
    initial: {
      title: "Delete these projects from workspace?",
      description:
        "When you confirm this action, all the data of these projects will be lost and cannot be recovered",
    },

    failed: {
      title: `Couldn't delete ${numberOfErrors} of ${numberOfUpdatingItems} projects`,
      description:
        "These projects couldn't be deleted from workspace, check the details and try again.",
    },
    confirmButtonText: "delete",
    entityType: "project",
  });

  const successDialogContent = {
    title: `${nounPluralize({
      counter: numberOfSuccess,
      word: "project",
    })} deleted`,
    description: "All selected projects have been permanently deleted",
  };

  return { bulkDialogContent, successDialogContent };
}
