import { MemberTypes } from "@custom-types/member-types";
import { Box, Divider } from "@mui/material";
import { useAppSelector } from "@store/store-helper";
import { currentUserSelector } from "@store/user/user-selector";
import { UserMoreInfoMenuItem } from "@components/user-more-info-menu/user-more-info-menu-item";
import RoleIcon from "@assets/icons/new/role_18px.svg?react";
import { formatUserRoleType } from "@utils/data-display";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { UserMoreInfoMenuTitle } from "@components/user-more-info-menu/user-more-info-menu-title";
import { UserMoreInfoMenuProfileButton } from "@components/user-more-info-menu/user-more-info-menu-profile-button";
import { CommonStyles } from "@styles/common-styles";
import { sphereColors } from "@styles/common-colors";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";

interface Props {
  /** The member to show the details in the dialog */
  member?: MemberTypes | SphereDashboardAPITypes.IUserAsManager | null;
}

/** Width in pixels for the menu */
export const MENU_WIDTH = "262px";

/**
 * Component that shows the menu when the user hovers over a member to see a quick
 * overlook about the user and open the profile page.
 */
export function UserMoreInfoMenu({ member }: Props): JSX.Element {
  const currentUser = useAppSelector(currentUserSelector);
  const isCurrentUser = member?.identity === currentUser?.identity;
  const hasRoleProperty = member && "role" in member;
  const { canViewCompanyMemberProfile } = useHasUserValidRoleCompanyLevel({
    memberId: member?.id,
  });

  if (!member) {
    // eslint-disable-next-line react/jsx-no-useless-fragment -- we need as empty component.
    return <></>;
  }

  return (
    <Box
      component="div"
      sx={{
        width: MENU_WIDTH,
        border: CommonStyles.Borders.gray200Divider,
      }}
    >
      {/* Wrapper for avatar, name and email */}
      <UserMoreInfoMenuTitle member={member} isCurrentUser={isCurrentUser} />

      {/* Wrapper for user info items, like role, projects, etc. */}
      {
        // Only show the role if the member has the role property.
        hasRoleProperty && (
          <Box component="div">
            <UserMoreInfoMenuItem
              text={formatUserRoleType(member.role)}
              icon={RoleIcon}
            />
          </Box>
        )
      }

      {/* Wrapper for button to open profile page */}
      {canViewCompanyMemberProfile && (
        <>
          <Divider
            sx={{
              marginX: "8px",
              backgroundColor: sphereColors.gray50,
            }}
          />
          <UserMoreInfoMenuProfileButton member={member} />
        </>
      )}
    </Box>
  );
}
