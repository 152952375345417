import { Box } from "@mui/material";
import { ProjectCard } from "@components/common/project-card/project-card";
import {
  BaseProjectsProps,
  ProjectArchivingState,
} from "@custom-types/project-types";
import { CardSkeleton } from "@components/common/skeleton-loading/card-skeleton";
import { LoadMoreButton } from "@components/common/button/load-more-button";
import { FaroTilesActionBar } from "@components/common/faro-card/faro-tiles-action-bar";
import { ExportProjectsAsCSV } from "@components/table/projects/bulk-actions/export-projects-as-csv";
import { useAppSelector } from "@store/store-helper";
import { selectedSdbCompanyNameSelector } from "@store/sdb-company/sdb-company-selector";
import { TilesContainer } from "@components/common/tiles-container";
import { ProjectEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { getTrackProjectDateProps } from "@utils/project-utils";

interface Props extends BaseProjectsProps {
  /** The ID of the company */
  companyId: string;

  /** Flag whether it is loading some content  */
  isLoading: boolean;

  /** Flag whether the button to load more projects should be hidden */
  shouldHideLoadMoreButton?: boolean;

  /** Callback when the load more button is clicked */
  loadMoreProjects: () => Promise<void>;

  /** The archiving status of the project */
  projectStatus: ProjectArchivingState;
}

/**
 * The projects tab listing all the active or archived projects (depending on the props) in the company
 */
export function ProjectsTiles({
  isLoading,
  shouldHideLoadMoreButton,
  projects,
  companyId,
  loadMoreProjects,
  projectStatus,
}: Props): JSX.Element {
  const selectedSdbCompanyName = useAppSelector(selectedSdbCompanyNameSelector);
  const { trackEvent } = useTrackEvent();

  return (
    <Box component="div">
      <FaroTilesActionBar
        actionBarButtons={[
          <ExportProjectsAsCSV
            key="ExportMembersAsCSV"
            selectedProjects={projects}
            subjectType="workspace"
            entityName={selectedSdbCompanyName ?? ""}
            projectStatus={projectStatus}
            /* It is false since entities can't be selected in tiles view */
            areEntitiesSelected={false}
          />,
        ]}
      />
      <TilesContainer>
        {projects.map((project) => (
          <ProjectCard
            key={project.id}
            project={project}
            companyId={companyId}
            onClick={() =>
              trackEvent({
                name: ProjectEvents.openProjectOverview,
                props: {
                  numberOfProjects: projects.length,
                  ...getTrackProjectDateProps(project),
                },
              })
            }
          />
        ))}

        {/* If it is loading the content, show the card skeleton */}
        {isLoading && <CardSkeleton type="project" />}
      </TilesContainer>

      <LoadMoreButton
        isLoading={isLoading}
        buttonText="Load more projects"
        shouldHideLoadMoreButton={shouldHideLoadMoreButton}
        onClick={loadMoreProjects}
      />
    </Box>
  );
}
