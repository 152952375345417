import { Skeleton } from "@mui/material";
import {
  SNAPSHOT_HEIGHT,
  SNAPSHOT_MARGIN_TOP,
} from "@pages/project-details/project-snapshots/collapsible-snapshot-utils";

/**
 * Shows a skeleton in the same style than a snapshot.
 */
export function SnapshotSkeleton(): JSX.Element {
  return (
    <Skeleton
      variant="rectangular"
      height={SNAPSHOT_HEIGHT}
      sx={{
        marginTop: SNAPSHOT_MARGIN_TOP,
      }}
    />
  );
}
